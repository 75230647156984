import { cLvl } from "#constants/confirmationLevel";
import { roles } from "#constants/roles";
import { postPlatformActivity } from "#core/modules/activity/actions/post";
import {
  updateAppNotif,
  updateNotifsList
} from "#core/modules/notifications/actions";
import { triggerSnackbar } from "#core/modules/snackbar/actions";
import { getCurrentUser } from "#core/modules/users/actions/get";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    _data: {
      confirmationLevel,
      twoStepAuthActive,
      token,
      tokenAuth,
      kyc = {},
      aml,
      cuid: userId = -1
    } = {}
  } = state.users || {};

  const { _data: { entity: { userRole } = {} } = {} } = state.entities;

  const kycFinished =
    !!kyc.reviewResult &&
    !!kyc.kycResults &&
    confirmationLevel > cLvl.ONBOARDED;

  const authCompleted =
    (confirmationLevel > 0 && !twoStepAuthActive) ||
    (twoStepAuthActive && token && tokenAuth);

  const roleNoKyc =
    userRole === roles.ASSET_MANAGER ||
    userRole === roles.INVESTOR ||
    userRole === roles.FOUNDER;

  return {
    userId,
    authCompleted,
    kycFinished,
    confirmationLevel,
    roleNoKyc
  };
};

const mapDispatchToProps = dispatch => ({
  postUserActivity: activity => dispatch(postPlatformActivity(activity)),
  updateNotifsList: data => dispatch(updateNotifsList(data)),
  handleUpdateAppNotif: registration => dispatch(updateAppNotif(registration)),
  triggerSnackbar: data => triggerSnackbar(dispatch, data),
  getCurrentUser: () => dispatch(getCurrentUser())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Presentational)
);
