import { basicOption } from "#helpers/general";
import { equityTs } from "#constants/io/equityPool";
import {
  cantonsSortedByName,
  zurichCantonsSortedByName
} from "../../../../Shared/Settings/cantons";

const investmentInfo = values => {
  // const finalCanton = values.entityCanton || "registered_seat";

  return [
    {
      title:
        "Shall the Company and the Investors pay for their own taxes? Or as an alternative: shall each party bear its own costs (including the costs of advisors and taxes)?",
      details:
        "This is only relevant, if an Investor is in an employment or employment-like relationship with the company",
      input: {
        type: "select_btn",
        name: equityTs.SOCIAL_SECURITY,
        options: [basicOption("yes", "Yes"), basicOption("no", "No")]
      }
    },
    {
      title:
        "What jurisdiction of the ordinary courts shall provide for this Term Sheet?",
      input: {
        type: "select_btn",
        name: equityTs.GOVERNING_LAW,
        options: [
          basicOption("registered_seat", "Registered seat"),
          basicOption("zurich", "Zürich"),
          basicOption("other", "Other")
        ]
      }
    },
    (values[equityTs.GOVERNING_LAW] === "other" ||
      values[equityTs.GOVERNING_LAW] === "zurich") && {
      input: {
        type: "autocomplete",
        name: equityTs.GOVERNING_LAW_OTHER,
        dataSource:
          values[equityTs.GOVERNING_LAW] === "zurich"
            ? zurichCantonsSortedByName
            : cantonsSortedByName,
        filterOption: true
      }
    }
  ];
};
export default investmentInfo;
