import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './action_types';
import actions from '../../constants/action_type';
import { updateObjectInArray } from '../../utils/mutators';

const initState = {
  ...initialState
};

const fundraiserReducer = (state = initState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.POST_FUNDRAISER: {
      return baseReducer(action, state, actions.CREATE, {});
    }
    default:
      return state;
  }
};

export default fundraiserReducer;
