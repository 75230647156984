import * as convertibleService from "../../../../api/convertibleService";
import types from "../action_types";
import { start, fail, success } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const postConvertibleTypes = data => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_CONVERTIBLE_TYPES);
      const response = await convertibleService.postConvertibleTypes(data);

      success(dispatch, types.POST_CONVERTIBLE_TYPES, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_CONVERTIBLE_TYPES, {
        error: errorData
      });
    }
  };
};
