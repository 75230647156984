import { NavLink } from "react-router-dom";
import { basicOption } from "#helpers/general";
import { claTsKeys, keys } from "#constants/cla";
import { formatNumber } from "#helpers/number_format";
import { generalInformationsCols } from "./tableCols";
import { confirmationPrompt } from "../../../common/prompts";

const generalFields = (
  values,
  shares,
  handlers,
  governingLaw,
  handleFormSave,
  currPath
) => {
  values.sharesNumber = shares;
  values.registeredSeat = "registered_seat";
  values[claTsKeys.GOVERNING_LAW] = "Swiss Law";
  return [
    {
      title: "How many shares issued does your company have?",
      input: {
        type: "number",
        name: claTsKeys.SHARES_OUTSTANDING,
        formatter: value => formatNumber(value),
        value: shares,
        disabled: true
      }
    },
    {
      title:
        "What is the name of the financing round the company intends to raise next?",
      input: {
        type: "select_btn",
        name: claTsKeys.SERIES_ROUND,
        options: [
          basicOption("Pre-Seed"),
          basicOption("Seed"),
          basicOption("Series A"),
          basicOption("Series B")
        ]
      }
    },
    {
      title:
        "What is the  name of this convertible loan (the name should be different from the existing convertible loan(s) already contained in the convertible loan management section)?",
      input: {
        type: "text",
        name: claTsKeys.NAME,
        placeholder: "Convertible Loan A"
      }
    },
    {
      title:
        "What governing law should be applicable to the convertible loan agreement?",
      input: {
        type: "text",
        name: claTsKeys.GOVERNING_LAW,
        value: "Swiss Law",
        disabled: true
      }
    },
    {
      title:
        "What jurisdiction should be applicable to the convertible loan agreement?",
      input: {
        type: "text",
        name: "registeredSeat",
        disabled: true,
        value: governingLaw
      }
    },
    {
      title: "What is the term (in months) of the convertible loan?",
      input: {
        type: "text",
        name: claTsKeys.VALIDITY,
        addonAfter: "month(s)",
        placeholder: "123"
        // formatter: value => `${value}${value ? " months" : ""}`,
        // parser: value => value.replace(" months", "")
      }
    },
    {
      title: (
        <span>
          Is the convertible loan and accrued interest thereon (if any)
          subordinated in the sense of{" "}
          <NavLink to="/doc_viewer?doc=/docs/Art725.pdf">
            art. 725 para. 2 CO?
          </NavLink>
        </span>
      ),
      input: {
        name: claTsKeys.CL_SUBORDINATED,
        type: "select_btn",
        options: [
          basicOption("yes", "Yes", {
            tooltip:
              "If you choose to subordinate your convertible loan, it allows your company’s board to abstain from informing the judge in the sense of art. 725 para 2 CO in case of over-indebtedness of your company (which may trigger bankruptcy). However, if you choose this option, the convertible loan will still be displayed as debt on your balance sheet. According to market standards, subordination of a convertible loan is deemed founder-friendly."
          }),
          basicOption("no", "No", {
            tooltip:
              "If you choose not to subordinate your convertible loan, the convertible loan counts legally as normal debt. In case your company is not able to cover the debts with your company assets, it is legally required by the board to inform the judge about the over-indebtedness (which may lead to bankruptcy)."
          })
        ]
      }
    },
    values[claTsKeys.CL_SUBORDINATED] === "yes" && {
      title:
        "Shall this loan be equally managed (without discrimination or preference) with other unsecured subordinated obligations of the company?",
      input: {
        name: claTsKeys.EQUALLY_MANAGED,
        ...confirmationPrompt
      }
    },
    //from cla questionnaire

    {
      title:
        "Does the company have a pre-existing shareholders' agreement in place?",
      input: {
        name: keys.preExistingAgreement,
        ...confirmationPrompt
      }
    },

    values.preExistingAgreement === "yes" && {
      title: "Please insert the date of the current shareholders' agreement",
      input: {
        type: "date",
        name: keys.preExistingDate
      }
    },
    values.preExistingAgreement === "yes" && {
      title: "Do you want to upload the current valid shareholders' agreement?",
      input: {
        name: keys.uploadAgreementConfirmation,
        ...confirmationPrompt
      }
    },
    values.preExistingAgreement === "yes" &&
      values.uploadAgreementConfirmation === "yes" && {
        input: {
          type: "upload",
          name: "sha_upload"
        }
      },
    {
      title: "For which purpose shall the convertible loan be used?",
      input: {
        type: "select_btn",
        name: keys.claPurpose,
        options: [
          basicOption(
            "ordinary_business_activity",
            "Ordinary business activity"
          ),
          basicOption("customize", "Customize")
        ]
      }
    },
    values.claPurpose === "customize" && {
      input: {
        type: "textarea",
        rows: 4,
        name: keys.claPurposeArea,
        placeholder: "The Loan shall be used exclusively for "
      }
    },
    {
      title: "Who shall sign this convertible loan?",
      input: {
        type: "select_btn",
        name: claTsKeys.CLA_SIGNED_BY,
        options: [
          basicOption("board_of_directors", "Board of directors"),
          basicOption(
            "board_of_directors_and_founders",
            "Board of directors and founders"
          ),
          basicOption(
            "board_of_directors_and_shareholders",
            "Board of directors and all existing shareholders"
          )
        ]
      }
    },
    values[claTsKeys.CLA_SIGNED_BY] && {
      title:
        "Which member(s) of the company's board of directors shall sign the convertible loan agreement?",
      input: {
        type: "table_list",
        name: "signingDirector",
        cols: generalInformationsCols(values, handlers)
      }
    },
    values[claTsKeys.CLA_SIGNED_BY] && {
      showId: false,
      title: (
        <span>
          If you want to add a board member{" "}
          <NavLink
            to={`/stakeholders?redirect=${currPath}?step=0`}
            onClick={handleFormSave(values)}
          >
            click here
          </NavLink>
        </span>
      ),
      className: "_hidden",
      input: {}
    },
    {
      title:
        "Within how many days after the execution of the convertible loan agreement is the investor required to pay the loan to the company?",
      input: {
        type: "select_btn",
        name: keys.investorLoanPayout,
        options: [basicOption("5", "5 days"), basicOption("Other")]
      }
    },
    values[keys.investorLoanPayout] === "Other" && {
      input: {
        type: "text",
        name: keys.investorLoanPayoutOther,
        addonAfter: "day(s)",
        placeholder: "123"
        // formatter: value => (value ? `${value} days` : ""),
        // parser: value => value.replace(new RegExp(` days\\s?|(\\'*)`, "g"), "")
      }
    },
    {
      title:
        "Shall the lenders be entitled to assign their rights and obligations under this Convertible loan Agreement in whole to an affiliate without the prior written consent of the founders and the existing shareholders?",
      input: {
        name: claTsKeys.LENDER_AFFILIATE_WITHOUT_CONSENT,
        ...confirmationPrompt
      }
    },
    {
      title:
        "Does the board have to consent to disclosure of the terms of the Convertible loan Agreement to third parties other than competitors of the Company?",
      input: {
        name: claTsKeys.BOARD_DISCLOSURE_THIRD_PARTIES,
        ...confirmationPrompt
      }
    }
  ];
};

export default generalFields;
