import * as membershipsService from "../../../../api/ioMembershipsService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData } from "../../../utils/helper_func";

export const deleteMembership = cuid => {
  return async dispatch => {
    try {
      start(dispatch, types.DELETE_MEMBERSHIP);
      const response = await membershipsService.deleteMembership(cuid);

      success(dispatch, types.DELETE_MEMBERSHIP, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      fail(dispatch, types.DELETE_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};
