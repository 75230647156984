import { Icon as BaseIcon } from "antd";
import { cLvl } from "#constants/confirmationLevel";
import {
  ApprovedIcon,
  InReviewIcon,
  InProgressIcon,
  InProgressOpaqueIcon,
  CommitedConfirmed,
  InvitationAccepted,
  Invited,
  SoftCommited,
  MoneyReceived,
  MoneyArrived
} from "../../components/Shared/Icons/Investors";
import TooltipWrapper from "../../components/Shared/Tooltip";

export const mapMembershipData = ({
  user: { cuid: value, firstName, lastName } = {}
}) => {
  return {
    value,
    investor: `${firstName} ${lastName}`,
    checked: true
  };
};

export const mapDealMembershipData = values => ({
  ioId: values.ioId,
  userId: values.userId,
  permissionLevel: values.permissionLevel || 3,
  redirectUrl:
    values.redirectUrl ||
    `${process.env.HOST}/login?redirectTo=/dashboard/deal_management/${values.ioId}`
});

export const mapDealMembershipStatus = value => {
  let Icon = Invited;
  let tooltip = "Invitation not yet accepted by investor";

  if (value === 1) {
    Icon = InvitationAccepted;
    tooltip = "Investor has accepted the invitation";
  } else if (value === 2) {
    Icon = SoftCommited;
    tooltip = "Investor has soft committed";
  } else if (value === 3) {
    Icon = CommitedConfirmed;
    tooltip = "Investor has signed the Terms of Syndicate";
  } else if (value === 4) {
    Icon = MoneyReceived;
    tooltip = "Investor has wired the funds";
  } else if (value === 6) {
    Icon = MoneyArrived;
    tooltip = "Investor's funds have arrived at Leva";
  }

  return (
    <TooltipWrapper title={tooltip}>
      <span>
        <Icon />
      </span>
    </TooltipWrapper>
  );
};
export const mapClvl = value => {
  let Icon = InProgressOpaqueIcon;
  let tooltip = "Invitation not yet accepted by investor.";

  if (value === cLvl.ONBOARDED) {
    Icon = InProgressIcon;
    tooltip = "Investor created a profile.";
  } else if (value === cLvl.KYC_COMPLETED) {
    Icon = InReviewIcon;
    tooltip =
      "The investor has submitted KYC documents and are being checked by Leva.";
  } else if (value === cLvl.AML_COMPLETED) {
    Icon = ApprovedIcon;
    tooltip = "Investor is fully onboarded.";
  }

  return (
    <TooltipWrapper title={tooltip}>
      <span>
        <Icon />
      </span>
    </TooltipWrapper>
  );
};
