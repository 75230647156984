import { basicOption } from "#helpers/general";
import { shaKeys, iaKeys } from "#constants/io/equityPool";
import {
  confirmationPrompt,
  renderOtherInputWithoutId
} from "../../../common/prompts";
import { countriesSorted } from "../../../../Shared/Settings/countries";

const generalInformation = (
  values,
  currency,
  subscriptionCompleted,
  subscriptionFinished
) => [
  !subscriptionFinished
    ? {
        title:
          "Shall the shareholders agreement have the same confidentiality provisions as in the Investment & Subscription Agreement?",
        input: {
          ...confirmationPrompt,
          name: shaKeys.IA_CONFIDENTIALITY
        }
      }
    : null,
  {
    title: `What shall be the contractual penalty amount for a shareholder in case of a material breach of the shareholders' agreement?`,
    input: {
      type: "select_btn",
      name: shaKeys.LIQUIDATED_DAMANGES_AMOUNT,
      options: [
        basicOption("20000", "20k"),
        basicOption("50000", "50k"),
        basicOption("100000", "100k"),
        basicOption("Other")
      ]
    }
  },
  values[shaKeys.LIQUIDATED_DAMANGES_AMOUNT] === "Other" &&
    renderOtherInputWithoutId(
      shaKeys.LIQUIDATED_DAMANGES_AMOUNT_OTHER,
      "number"
    ),

  {
    title: "Do you want non-competition / non-solicitation clause?",
    input: {
      name: shaKeys.NON_COMPETITION_NON_SOLICITATION_CALUSE,
      ...confirmationPrompt
    }
  },
  values[shaKeys.NON_COMPETITION_NON_SOLICITATION_CALUSE] === "yes" && {
    title:
      "For how long after termination of the Shareholders’ Agreement shall the founders of the Company be bound by a non-competition / non-solicitation clause?",
    input: {
      type: "select_btn",
      name: shaKeys.NON_COMPETITION_SOLICITATION_TERM,
      options: [
        basicOption("6", "6 months"),
        basicOption("12", "12 months"),
        basicOption("Other")
      ]
    }
  },
  values[shaKeys.NON_COMPETITION_NON_SOLICITATION_CALUSE] === "yes" &&
    values[shaKeys.NON_COMPETITION_SOLICITATION_TERM] === "Other" &&
    renderOtherInputWithoutId(
      shaKeys.NON_COMPETITION_SOLICITATION_TERM_OTHER,
      "number"
    ),
  !subscriptionFinished
    ? {
        title:
          "Shall the shareholders agreement have the same notification provisions as in the Investment & Subscription agreement?",
        input: {
          ...confirmationPrompt,
          name: shaKeys.IA_NOTICES
        }
      }
    : null,
  {
    title:
      "What threshold is required to approve amendments to the shareholders'agreement?",
    input: {
      type: "number",
      name: shaKeys.ALL_PARTIES,
      placeholder: "15%",
      max: 100,
      min: 0,
      formatter: value => (value ? `${value}%` : value),
      parser: value => (value ? value.replace("%", "") : value)
    }
  },
  {
    title:
      "Shall the investors be entitled to assign or transfer the shareholders’ agreement or any of the rights or obligations thereunder to any other party without the prior written consent of the founders and the existing shareholders?",
    input: {
      name: shaKeys.TRANSFER_CONSENT,
      type: "select_btn",
      options: [
        basicOption("yes", "Yes", {
          tooltip:
            "The investors will still need the prior written consent of each investors"
        }),
        basicOption("no", "No")
      ]
    }
  },
  subscriptionCompleted
    ? {
        title: "Is a new company auditor appointed with this financing round?",
        input: {
          name: iaKeys.NEW_COMPANY_AUDITOR,
          ...confirmationPrompt
        }
      }
    : null,
  ...(subscriptionCompleted && values[iaKeys.NEW_COMPANY_AUDITOR] === "yes"
    ? [
        {
          title: "Who shall the auditor expert be?",
          input: {
            type: "select_btn",
            name: shaKeys.AUDITOR_EXPERT_STATUS,
            options: [
              basicOption("natural", "Natural person"),
              basicOption("legal", "Legal entity")
            ]
          }
        },
        {
          title:
            values[shaKeys.AUDITOR_EXPERT_STATUS] === "legal"
              ? "Company"
              : "First name",
          className: "_width-50",
          input: {
            type: "text",
            name: shaKeys.AUDITOR_EXPERT_NAME
          }
        },
        {
          title:
            values[shaKeys.AUDITOR_EXPERT_STATUS] === "legal"
              ? "UID"
              : "Last name",
          className: "_width-50",
          input: {
            type: "text",
            name: shaKeys.AUDITOR_EXPERT_LAST_NAME
          }
        },
        {
          title: "Street",
          input: {
            type: "text",
            name: shaKeys.AUDITOR_EXPERT_STREET
          }
        },
        {
          title: "Postal code",
          className: "_width-50",
          input: {
            type: "text",
            name: shaKeys.AUDITOR_EXPERT_ZIP
          }
        },
        {
          title: "Place",
          className: "_width-50",
          input: {
            type: "text",
            name: shaKeys.AUDITOR_EXPERT_PLACE
          }
        },
        {
          title: "Country",
          input: {
            type: "select",
            name: shaKeys.AUDITOR_EXPERT_COUNTRY,
            options: countriesSorted
          }
        },
        {
          title: "Email",
          input: {
            type: "email",
            name: shaKeys.AUDITOR_EXPERT_EMAIL
          }
        }
      ]
    : [])
];

export default generalInformation;
