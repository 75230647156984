import { connect } from "react-redux";
import {
  postVotingAssembly,
  putGenerateVotingCodeDM,
  postPublicVoting,
  postVotingAttachment
} from "#core/modules/voting/actions";
import { clearState, clearStateData } from "#core/utils/helper_actions";
import { getCurrentUser } from "#core/modules/users/actions/get";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { didCreate, isCreating } = state.voting;
  const { entity: { cuid: entityId } = {} } = state.entities._data || {};
  const { assembly, didVerify = false } = state.voting._data || {};
  const {
    _data: {
      kyc: {
        kycResults: { generalQuestions: { phoneNumber = "" } = {} } = {}
      } = {}
    } = {}
  } = state.users || {};

  return {
    entityId,
    buttonStates: {
      loading: isCreating,
      success: didCreate
    },
    phoneNumber,
    assembly,
    didVerify
  };
};

const mapDispatchToProps = dispatch => ({
  postVotingAssembly: data => dispatch(postVotingAssembly(data)),
  clearState: key => clearState(dispatch, key),
  clearStateData: state => clearStateData(dispatch, state),
  getCurrentUser: () => dispatch(getCurrentUser()),
  putGenerateVotingCodeDM: data => dispatch(putGenerateVotingCodeDM(data)),
  postPublicVoting: data => dispatch(postPublicVoting(data)),
  postVotingAttachment: data => dispatch(postVotingAttachment(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
