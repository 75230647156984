import { NavLink } from "react-router-dom";
import { Button } from "antd";

export default function Presentational(props) {
  console.log(props);
  return (
    <div className="feature-buttons-wrapper">
      {props.buttons.map(button => (
        <Button
          className={`${
            button.left ? "feature-buttons-left" : ""
          } ${button.className || ""}`}
          size="large"
          type={button.left ? "primary" : "default"}
          onClick={button.onClick}
        >
          {button.to ? (
            <NavLink to={button.to}>{button.name}</NavLink>
          ) : (
            <a target="_blank" href={button.href}>
              {button.name}
            </a>
          )}
        </Button>
      ))}
    </div>
  );
}
