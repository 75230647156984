import * as ndaService from "../../../../api/ndaService";
import types from "../action_types";
import { triggerSnackbar } from "../../snackbar/actions";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const putNdaSignature = data => {
  return async dispatch => {
    try {
      start(dispatch, types.PUT_NDA_SIGNATURE);

      await ndaService.putNdaSignature(data);

      success(dispatch, types.PUT_NDA_SIGNATURE, {
        data
      });
      triggerSnackbar(dispatch, {
        open: true,
        variant: "success",
        message:
          "You successfully signed the Confidentiality & Non-Circumvent Agreement."
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.PUT_NDA_SIGNATURE, {
        error: errorData
      });
    }
  };
};
