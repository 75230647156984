import { connect } from "react-redux";
import {
  postConvertibleTypes,
  getConvertibleTypes,
  updateCla
} from "#core/modules/convertible/actions";
import { triggerSnackbar } from "#core/modules/snackbar/actions";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { convertibles = [] } = state.convertible._data || {};
  const { didCreate, didUpdate } = state.convertible || {};
  const {
    entity: {
      governingLaw: registeredSeat = "",
      cuid: entityId,
      employeeParticipationPlan: { esopShares = 0, psopShares = 0 } = {}
    } = {}
  } = state.entities._data || {};

  return {
    convertibles,
    entityId,
    buttonStates: {
      loading: state.convertible.isCreating,
      success: state.convertible.didCreate || state.convertible.didUpdate
    },
    esopShares,
    psopShares,
    didCreate,
    didUpdate,
    registeredSeat
  };
};

const mapDispatchToProps = dispatch => ({
  triggerSnackbar: snackbar => triggerSnackbar(dispatch, snackbar),
  postConvertibleTypes: data => dispatch(postConvertibleTypes(data)),
  clearState: key => clearState(dispatch, key),
  updateCla: (cuid, data) => dispatch(updateCla(cuid, data)),
  getConvertibleTypes: data => dispatch(getConvertibleTypes(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
