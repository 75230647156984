const News = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5678 21.5339C11.4915 21.5339 11.4152 21.5339 11.339 21.5085L4.01698 19.2204C3.81357 19.1696 3.66106 19.017 3.55935 18.839C3.45765 18.6611 3.45765 18.4577 3.5085 18.2543L4.60171 14.7712L6.05088 15.2289L5.18648 17.9746L11.0594 19.8051L11.9238 17.0594L13.3729 17.517L12.2797 21C12.2034 21.3306 11.8983 21.5339 11.5678 21.5339Z"
        fill="#597EF7"
      />
      <path
        d="M23.2373 8.72034H21.5084V3.22881C21.5084 2.79659 21.1779 2.46613 20.7457 2.46613H17.339C16.9067 2.46613 16.5763 2.79664 16.5763 3.22881V3.9915L3.22881 8.21186H1.52543C0.661033 8.21186 0 8.8729 0 9.73729V13.5254C0 14.3898 0.661033 15.0509 1.52543 15.0509H3.22881L16.5763 19.2712V20.0339C16.5763 20.4661 16.9068 20.7966 17.339 20.7966H20.7457C21.178 20.7966 21.5084 20.4661 21.5084 20.0339V14.5424H23.2373C23.6695 14.5424 24 14.2119 24 13.7797V9.48308C24 9.05085 23.6695 8.72034 23.2373 8.72034ZM1.52543 9.73729H2.61863V13.5254H1.52543V9.73729ZM4.14406 13.7288V9.53388L16.5763 5.59318V17.6694L4.14406 13.7288ZM19.983 19.2712H18.1017V3.9915H19.983V19.2712ZM22.4746 13.017H21.5085V10.2458H22.4746V13.017Z"
        fill="#18DC9B"
      />
    </svg>
  );
};

export default News;
