import { connect } from 'react-redux';
import { clearStateDataByKey } from '#core/utils/helper_actions';
import Presentational from './Presentational';

const mapStateToProps = ({ entities }) => ({
  // detailsFetching: entities._data && entities._data.detailsFetching,
  detailsFetching: entities.isFetching,
  // detailsFetched: entities._data && entities._data.detailsFetched,
  detailsFetched: entities.didFetch,
  companyDetails: (entities._data && entities._data.entity) || [],
});

const mapDispatchToProps = (dispatch) => ({
  clearStateDataByKey: (givenState, ...keys) =>
    clearStateDataByKey(dispatch, givenState, ...keys),
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
