import { Icon, Dropdown, Menu } from "antd";
import { entityTypes } from "#constants/entity";
import { entitySubmenu } from "../MenuItems";
import AvatarWrapper from "../../Avatar";

const AppDrawerHeader = ({
  entity,
  history,
  setEntity,
  entitiesList,
  toggleModal
}) => {
  const handleSetEntity = cuid => () => {
    history.push("/dashboard/investments");
    setEntity(cuid);
  };

  const menu = (
    <Menu className="app-drawer-menu">
      {entitiesList
        .filter(({ membership: { status } = {} }) => status === 1)
        .map(({ name, cuid }) => (
          <Menu.Item
            key={cuid}
            className={`drawer-menu-item ${
              entity.cuid === cuid ? "selected" : ""
            }`}
            onClick={handleSetEntity(cuid)}
          >
            <span className="menu-item-name">{name}</span>
          </Menu.Item>
        ))}
      <Menu.Divider />
      {entitySubmenu({ toggleModal })
        .filter(
          ({ roles, type }) =>
            roles.includes(entity.userRole) &&
            type !== entityTypes.ASSET_MANAGER_ENTITY
        )
        .map(({ title, id, icon, onClick }) => {
          return (
            <Menu.Item key={id} className="drawer-menu-item" onClick={onClick}>
              <div className="menu-item-container">
                <Icon type={icon} className="menu-item-icon" />
                <span className="menu-item-name">{title}</span>
              </div>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
      <div className="appDrawer-header">
        <div className="entity-picture">
          <AvatarWrapper src={entity.previewImage} />
        </div>
        <div className="entity-owner">
          <span className="entity-name">{entity.name}</span>
          <Icon type="caret-down" />
        </div>
      </div>
    </Dropdown>
  );
};

export default AppDrawerHeader;
