import * as Yup from "yup";
import { returnError } from "./common";

const getEntitySchema = () => {
  const schema = {
    commonName: Yup.string().required(returnError("required")),
    investmentType: Yup.string().required(returnError("required")),
    name: Yup.string().required(returnError("required")),
    previewImage: Yup.boolean().required(returnError("required")),
    websiteUrl: Yup.string().required(returnError("required")),
    description: Yup.string().required(returnError("required")),
    address: Yup.string().required(returnError("required")),
    postalCode: Yup.string().required(returnError("required")),
    city: Yup.string().required(returnError("required")),
    country: Yup.string().required(returnError("required")),
    canton: Yup.string(),
    vat: Yup.string(),
    entityShares: Yup.number(),
    sharePrice: Yup.number(),
    industries: Yup.array().required(returnError("required")),
    companyStatus: Yup.string().required(returnError("required")),
    idNumber: Yup.string()
  };
  return Yup.object().shape(schema);
};

const getEntityUpdateSchema = () => {
  const schema = {
    name: Yup.string().required(returnError("required")),
    governingLaw: Yup.string().required(returnError("required")),
    websiteUrl: Yup.string().required(returnError("required")),
    description: Yup.string().required(returnError("required")),
    address: Yup.string().required(returnError("required")),
    postalCode: Yup.string().required(returnError("required")),
    city: Yup.string().required(returnError("required")),
    country: Yup.string().required(returnError("required")),
    canton: Yup.string().required(returnError("required")),
    // vat: Yup.string().required(returnError('required')),
    idNumber: Yup.string().required(returnError("required")),
    industries: Yup.array().required(returnError("required")),
    companyStatus: Yup.string().required(returnError("required")),
    publicFoundingDate: Yup.date().required(returnError("required")),
    shares: Yup.number().required(returnError("required")),
    esopShares: Yup.number().required(returnError("required")),
    psopShares: Yup.number().required(returnError("required")),
    bank: Yup.string().required(returnError("required")),
    bankAddress: Yup.string().required(returnError("required")),
    bankCity: Yup.string().required(returnError("required")),
    bankCanton: Yup.string().required(returnError("required")),
    bankCountry: Yup.string().required(returnError("required")),
    iban: Yup.string().required(returnError("required")),
    accountNumber: Yup.string().required(returnError("required")),
    clearingNumber: Yup.string().required(returnError("required")),
    aoaDate: Yup.date().required(returnError("required")),
    publicShareCapital: Yup.number().required(returnError("required")),
    descriptionEnglish: Yup.string().required(returnError("required"))
  };
  return Yup.object().shape(schema);
};

export default getEntitySchema;
export { getEntityUpdateSchema };
