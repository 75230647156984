import { connect } from "react-redux";
import { getEntityShareholders } from "#core/modules/entities/actions";
import { getShareClasses } from "#core/modules/shares/actions";
import { getConvertibleTypes } from "#core/modules/convertible/actions";
import { clearState } from "#core/utils/helper_actions";

import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    entity: { cuid: entityId, shares: entitySharesNo } = {},
    shareholders = []
  } = state.entities._data || {};
  const {
    _data: { captable: { shares: sharesList = [] } = {} } = {},
    didFetch: sharesDidFetch
  } = state.shares;
  const { convertibles = [] } = state.convertible._data || {};

  return {
    entityId,
    entitySharesNo,
    shareholders,
    sharesList,
    sharesDidFetch,
    convertibles
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  getEntityShareholders: data => dispatch(getEntityShareholders(data)),
  getShareClasses: params => dispatch(getShareClasses(params)),
  getConvertibleTypes: data => dispatch(getConvertibleTypes(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
