import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { triggerSnackbar } from "#core/modules/snackbar/actions";
import { getCurrentUser } from "#core/modules/users/actions/get";
import { getEntity, updateEntity } from "#core/modules/entities/actions";
import { updateUserSettings } from "#core/modules/users/actions/update";
import { clearState } from "#core/utils/helper_actions";
import { readCurrencies } from "#core/modules/currency/actions/read";
import Presentational from "./Presentational";

const mapStateToProps = ({ entities, currency, users }) => {
  const {
    isUpdating: userIsUpdating,
    didFetch: userDidFetch,
    didUpdate: userDidUpdate,
    _data = {}
  } = users;
  const {
    isUpdating: entityIsUpdating,
    didUpdate: entityDidUpdate,
    didFetch: entityDidFetch
  } = entities;
  const { am_entity: { previewImage, ...entityData } = {}, entitiesList = [] } =
    (entities._data && entities._data) || {};
  const currencies = (currency?._data && currency?._data?.currencies) || [];
  const firstEntity = entitiesList[entitiesList.length - 1] || {};

  return {
    buttonStates: {
      loading: entityIsUpdating || userIsUpdating,
      success: entityDidUpdate || userDidUpdate,
      didFetch: entityDidFetch || userDidFetch
    },
    user: _data,
    currencies,
    currenciesFetched: currency.didFetch,
    previewImage,
    entity: entityData,
    entityId: firstEntity?.cuid
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    getEntity: data => dispatch(getEntity(data)),
    getCurrentUser: () => dispatch(getCurrentUser()),
    updateUserSettings: data => dispatch(updateUserSettings(data)),
    updateEntity: data => dispatch(updateEntity(data)),
    clearState: key => clearState(dispatch, key),
    triggerSnackbar: snackbar => triggerSnackbar(dispatch, snackbar),
    readCurrencies: () => dispatch(readCurrencies())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
