import { triggerSnackbar } from "#core/modules/snackbar/actions";
import { connect } from "react-redux";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { entity: { userRole } = {} } = state.entities._data || {};
  const ndaList = state?.nda?._data?.list;
  return {
    userRole,
    ndaList
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: "LOGOUT" })
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
