import { basicOption } from "#helpers/general";
import InputContainer from "../../../../Shared/Input";
import { confirmationPrompt } from "../../../common/prompts";
import Checkbox from "../../../../Shared/Checkbox";
import { Button } from "../../../../Shared/Button";

const investorsTableCols = ({ checkboxHandler, inputHandler }) => [
  {
    title: "Member",
    dataIndex: "member",
    key: "member",
    render: (value, { cuid }) => (
      <Checkbox
        checked={value}
        name="member"
        onChange={checkboxHandler("investors", cuid)}
      />
    )
  },
  {
    title: "First name",
    dataIndex: "firstName",
    key: "firstName"
  },
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName"
  },
  {
    title: "Amount to be invested",
    dataIndex: "amount",
    key: "amountForInvestment",
    render: (value, { cuid }) => (
      <InputContainer
        type="number"
        name="amount"
        value={value}
        onChange={inputHandler("investors", cuid)}
        formatter={val =>
          val && `CHF ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
        }
        parser={val => val.replace(new RegExp(`CHF\\s?|(\\'*)`, "g"), "")}
      />
    )
  },
  {
    title: "Shares receives",
    dataIndex: "shares",
    key: "newShares",
    render: (value, { cuid }) => (
      <InputContainer
        name="shares"
        value={value}
        onChange={inputHandler("investors", cuid)}
      />
    )
  },
  {
    title: "Share price",
    dataIndex: "sharePrice",
    key: "sharePrice",
    render: (value, { cuid }) => (
      <InputContainer
        name="sharesPrice"
        value={value}
        onChange={inputHandler("investors", cuid)}
      />
    )
  }
  // {
  //   title: "Investment",
  //   dataIndex: "investment",
  //   key: "investment",
  //   render: value => <Button label={value} />
  // }
];

const investorsItems = (values, handlers) => [
  {
    title:
      "Do you already have any investor, who has committed to a certain amount of your fundraising?",
    input: {
      name: "hasCommittedInvestors",
      type: "select_btn",
      options: [basicOption("yes", "Yes"), basicOption("no", "No")]
    }
  },
  values.hasCommittedInvestors === "yes"
    ? {
        input: {
          type: "table_list",
          name: "investors",
          cols: investorsTableCols(handlers)
        }
      }
    : null
];

export default investorsItems;
