import { investmentOpportunityTypes } from "#constants/deal";
import * as Yup from "yup";
import { returnError } from "./common";

const equitySchema = Yup.object().shape({
  capitalIncreaseDate: Yup.date().required(returnError("required")),
  totalOutstandingShares: Yup.number().required(returnError("required")),
  totalOutstandingSharesAreDiluted: Yup.string().required(
    returnError("required")
  ),
  sharesNominalValue: Yup.number().required(returnError("required"))
});

const fundSchema = Yup.object().shape({
  capitalIncreaseDate: Yup.date().required(returnError("required")),
  sharesNominalValue: Yup.number().required(returnError("required"))
});

const claSchema = Yup.object().shape({
  claClosingDate: Yup.date().required(returnError("required")),
  claTerm: Yup.date().required(returnError("required"))
});

const getPostDealClosureSchema = type => {
  let validationSchema = claSchema;
  if (type === investmentOpportunityTypes.POOLED_EQUITY)
    validationSchema = equitySchema;
  else if (type === investmentOpportunityTypes.POOLED_FUND)
    validationSchema = fundSchema;

  return validationSchema;
};

export { equitySchema, fundSchema, claSchema, getPostDealClosureSchema };
