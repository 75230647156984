import Checkbox from "../../../../Checkbox";

const SectionItem = ({
  title,
  subSections = [],
  current,
  completed,
  onClick,
  subSectionId,
  clickHandler,
  type,
  link,
  details
}) => {
  const finalClassname = `item-wrapper ${completed ? "completed" : ""} ${
    current ? "current" : ""
  } ${onClick ? "clickable" : ""} ${type === "section" ? "section" : "helper"}`;

  return (
    <div
      className={finalClassname}
      onClick={subSections.length === 0 ? onClick : null}
    >
      <h4>
        <div className="item-title-wrapper">
          <span className="checkbox">
            <Checkbox
              indeterminate={current}
              checked={completed}
              name={title}
            />
          </span>
          <div className="text-wrapper">
            {link ? (
              <a
                href={link}
                className={`${current ? "bold" : ""}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {title}
              </a>
            ) : (
              <span className={`${current ? "bold" : ""}`}>{title}</span>
            )}
            {details && current && <small className="details">{details}</small>}
          </div>
        </div>
      </h4>
      <div className="sub-sections">
        {subSections.map(({ title, id }) => {
          const classname = `sub-item ${id === subSectionId ? "current" : ""}`;

          return (
            <div className={classname} key={id} onClick={clickHandler(id - 1)}>
              <h5>{title}</h5>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionItem;
