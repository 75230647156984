/* eslint-disable no-nested-ternary */
import { basicOption, checkPasswordRequirements } from "#helpers/general";
import { passwordRequirementsSpan } from "#helpers/general";
import { List } from "antd";

const registrationFields = founderContext => ({ values = {} }) => {
  const passwordRequirements = checkPasswordRequirements(values.password);
  return [
    !founderContext
      ? {
          className: "_fullwidth",
          input: {
            type: "select_btn",
            name: "type",
            options: [
              basicOption("natural", "Natural person"),
              basicOption("legal", "Legal entity")
            ]
          }
        }
      : null,
    ...(values.type === "natural" || founderContext
      ? [
          {
            title: "First name",
            className: "_fullwidth",
            input: { type: "text", name: "firstName" }
          },
          {
            title: "Last name",
            className: "_fullwidth",
            input: { type: "text", name: "lastName" }
          }
        ]
      : []),
    ...(values.type === "legal"
      ? [
          {
            title:
              "Please upload your commercial register certificate in PDF you can extract it from here. Link:",
            className: "_fullwidth",
            input: { type: "upload", name: "c_reg_cert" }
          }
        ]
      : []),
    {
      title: "Email",
      className: "_fullwidth",
      input: { type: "text", name: "email" }
    },

    values.type === "legal"
      ? {
          title: "Add representatives",
          className: "_fullwidth",
          input: {
            type: "table_list",
            name: "representatives",
            fields: [
              {
                title: "First name",
                input: { type: "text", name: "firstName" }
              },
              { title: "Last name", input: { type: "text", name: "lastName" } }
            ],
            modalTitle: "Add representatives",
            modalProps: {
              submitHandler: ({ setFieldValue, name, value, item }) => {
                setFieldValue(name, [
                  ...value,
                  { id: value.length + 1, ...item }
                ]);
              }
            },
            itemRenderer: ({ firstName, lastName }) => (
              <List.Item.Meta title={`${firstName} ${lastName}`} />
            )
          }
        }
      : null,
    {
      title: (
        <div className="registration-password-wrapper">
          <span className="password-requirements">
            {values.password &&
            values.password.length > 0 &&
            passwordRequirements.length === 0 ? (
              <span>Great! Your password matches the requirements!</span>
            ) : values.password && values.password.length > 0 ? (
              <div>
                Password requirements: <br />
                {passwordRequirements.map(requirement => (
                  <div>
                    <span>{requirement}</span>
                    <br />
                  </div>
                ))}
                <br />
              </div>
            ) : (
              passwordRequirementsSpan
            )}
          </span>
          <span>Password: </span>
        </div>
      ),
      colon: false,
      className: "_fullwidth",
      input: { type: "password", name: "password" }
    },
    {
      title: "Re-enter your password",
      className: "_fullwidth",
      input: { type: "password", name: "passwordMatch" }
    },
    {
      className: "_fullwidth",
      input: {
        type: "switch",
        green: true,
        label: (
          <span>
            I hereby accept the{" "}
            <a
              href="/doc_viewer?doc=/docs/tos.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Services
            </a>{" "}
            ,the{" "}
            <a
              href="/doc_viewer?doc=/docs/privacypolicy.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>{" "}
            and the{" "}
            <a
              href="/doc_viewer?doc=/docs/riskwarning.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              Risk Warning
            </a>
            .
          </span>
        ),
        name: "tosAccepted"
      }
    },
    {
      className: "_fullwidth",
      input: {
        type: "switch",
        green: true,
        label:
          "I want to receive Leva's newsletter with articles, updates and interviews.",
        name: "newsletter_accept"
      }
    }
  ];
};

export default registrationFields;
