import { handleColor } from "#helpers/general";
import { formatNumber } from "#helpers/number_format";
import TagWrapper from "../../../../Shared/Tag";
import Checkbox from "../../../../Shared/Checkbox";

export const generalInformationsCols = (values, { checkboxHandler }) => [
  {
    title: "Select",
    dataIndex: "signingDirector",
    key: "signingDirector",
    render: (value, { cuid, signingAuthority }) => {
      return (
        <Checkbox
          checked={value}
          name="signingDirector"
          onChange={checkboxHandler("signingDirector", cuid)}
          disabled={signingAuthority === "none"}
        />
      );
    }
  },
  {
    title: "First name",
    dataIndex: "firstName",
    key: "firstName"
  },
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName"
  },
  {
    title: "President of the board",
    dataIndex: "presidentOfTheBoard",
    key: "presidentOfTheBoard",
    render: presidentOfTheBoard => (presidentOfTheBoard ? "Yes" : "No")
  },
  {
    title: "Representing",
    dataIndex: "representativeOf",
    key: "representativeOf",
    render: text => <TagWrapper color={handleColor(text)}>{text}</TagWrapper>
  },
  {
    title: "Signature",
    dataIndex: "signingAuthority",
    key: "signingAuthority",
    render: text => <TagWrapper color={handleColor(text)}>{text}</TagWrapper>
  }
];

export const convertibleCols = (values, { checkboxHandler }, name) => {
  const array = values[name] || [];
  const selected =
    Array.isArray(array) &&
    array.length > 0 &&
    array.filter(({ select }) => select).map(({ cuid }) => cuid);
  return [
    {
      title: "Select",
      dataIndex: "select",
      key: "select",
      render: (value, { cuid }) => {
        return (
          <Checkbox
            checked={value}
            name="select"
            onChange={checkboxHandler(name, cuid)}
          />
        );
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Fully-diluted",
      dataIndex: "fullyDilutedConvertible",
      key: "fullyDilutedConvertible",
      render: (value, { cuid }) => {
        return (
          <Checkbox
            checked={value}
            name="fullyDilutedConvertible"
            onChange={checkboxHandler(name, cuid)}
            disabled={!selected.includes(cuid)}
          />
        );
      }
    }
  ];
};

export const investorCols = ({ checkboxHandler }, name, array = []) => {
  const hasSelected = array.filter(({ selection }) => selection) || [];
  return [
    {
      title: "Select",
      dataIndex: "selection",
      key: "selection",
      render: (value, { cuid }) => {
        return (
          <Checkbox
            checked={value}
            name="selection"
            onChange={checkboxHandler(name, cuid)}
            disabled={
              hasSelected.length > 0 ? hasSelected[0].cuid !== cuid : false
            }
          />
        );
      }
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName"
    }
  ];
};

export const claConvertingCols = ({ checkboxHandler }) => [
  {
    title: "Selection",
    dataIndex: "selection",
    key: "selection",
    render: (val, { cuid }) => (
      <Checkbox
        name="selection"
        checked={val}
        onChange={checkboxHandler("convertingInvestors", cuid)}
      />
    )
  },
  {
    title: "First name",
    dataIndex: "firstName",
    key: "firstName"
  },
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName"
  },
  {
    title: "Amount to be converted",
    dataIndex: "totalAmount",
    key: "totalAmount",
    render: val => `CHF ${formatNumber(val)}`
  },
  {
    title: "Interest to be converted",
    dataIndex: "totalInterest",
    key: "totalInterest",
    render: val => `CHF ${formatNumber(val)}`
  },
  {
    title: "Share price",
    dataIndex: "sharePrice",
    key: "sharePrice"
  },
  {
    title: "Share amount",
    dataIndex: "shareAmount",
    key: "shareAmount"
  },
  {
    title: "Investor type",
    dataIndex: "type",
    key: "type",
    render: val =>
      val === "Shareholder Lender" ? (
        <div>
          <TagWrapper color="custom-green">Shareholder</TagWrapper>
          <br />
          <TagWrapper color="blue">Lender</TagWrapper>
        </div>
      ) : (
        <TagWrapper color={val === "Shareholder" ? "custom-green" : "blue"}>
          {val}
        </TagWrapper>
      )
  }
];
