import { useEffect } from "react";
import { Typography, Icon } from "antd";
import useWindowSize from "#helpers/hooks/windowSize";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

import Logo from "../../../assets/illustrations/Logo.png";

const { Title } = Typography;
export default function Presentational() {
  const { width } = useWindowSize();
  const history = useHistory();

  useEffect(() => {
    if (width > 600) {
      history.push("/");
    }
  }, [width]);

  return (
    <div className="phone-wrapper">
      <Title level={2}>
        Leva is optimized for desktop and laptop use only. Please login from a
        home device to continue. Thanks for your efforts, Leva will continue to
        expand to new platforms in the future!
      </Title>
      <img width="120" src={Logo} className="phone-logo" alt="logo" />
      <NavLink to="/">
        <div className="phone-logout-wrapper">
          <span className="menu-item-name phone-logout-text">Home</span>
        </div>
      </NavLink>
    </div>
  );
}
