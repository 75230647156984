/**
 * Function that reduces an array to an object that groups items based on the distinct values of the passed key.
 * @param {Object} arguments - Passed parameters
 * @param {Array}  arguments.array - Array to be reduced
 * @param {String} arguments.key - Key to query the values in the object by
 * @param {Function} arguments.keyParser - Function that returns a custom string for a given key value. If this is not passed, items will be grouped in raw key values.
 * @returns {Object}
 */
export const reduceArrayByKeyToObject = ({ array, key, keyParser }) =>
  array.reduce((acc, item) => {
    {
      const currKey = keyParser ? keyParser(item[key]) : item[key];
      const prevArray = acc[currKey] || [];

      return {
        ...acc,
        [currKey]: [...prevArray, item]
      };
    }
  }, {});

export const reduceArrayByKey = ({ array, key, acc = 0 }) =>
  array.reduce((accumulator, { [key]: keyVal }) => accumulator + keyVal, acc);
