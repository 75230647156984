export default () => {
  return (
    <svg
      width="346"
      height="447"
      viewBox="0 0 346 447"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 447H322C327.523 447 332 442.523 332 437V80.3263C332 77.5658 330.859 74.928 328.847 73.0379L268.886 16.7115C267.032 14.9697 264.584 14 262.04 14H10C4.47715 14 0 18.4772 0 24V437C0 442.523 4.47716 447 10 447Z"
        fill="#34A72A"
      />
      <path
        d="M269 88H322.052C330.933 88 335.407 77.2854 329.163 70.9694L276.111 17.308C269.828 10.9529 259 15.402 259 24.3386V78C259 83.5229 263.477 88 269 88Z"
        fill="#426E20"
      />
      <path
        d="M83.6963 218.285L94.1553 197.016H111.777L93.8916 228.744L112.261 261H94.4629L83.6963 239.335L72.9297 261H55.1758L73.501 228.744L55.6592 197.016H73.2373L83.6963 218.285Z"
        fill="white"
      />
      <path
        d="M132.739 249.135H159.59V261H117.314V197.016H132.739V249.135Z"
        fill="white"
      />
      <path
        d="M199.097 243.993C199.097 241.737 198.291 239.979 196.68 238.72C195.098 237.46 192.3 236.156 188.286 234.809C184.272 233.461 180.991 232.157 178.442 230.897C170.151 226.825 166.006 221.229 166.006 214.11C166.006 210.565 167.031 207.445 169.082 204.75C171.162 202.025 174.092 199.916 177.871 198.422C181.65 196.898 185.898 196.137 190.615 196.137C195.215 196.137 199.331 196.957 202.964 198.598C206.626 200.238 209.468 202.582 211.489 205.629C213.511 208.646 214.521 212.104 214.521 216H199.141C199.141 213.393 198.335 211.371 196.724 209.936C195.142 208.5 192.988 207.782 190.264 207.782C187.51 207.782 185.327 208.397 183.716 209.628C182.134 210.829 181.343 212.367 181.343 214.242C181.343 215.883 182.222 217.377 183.979 218.725C185.737 220.043 188.828 221.42 193.252 222.855C197.676 224.262 201.309 225.785 204.15 227.426C211.064 231.41 214.521 236.903 214.521 243.905C214.521 249.501 212.412 253.896 208.193 257.089C203.975 260.282 198.188 261.879 190.835 261.879C185.649 261.879 180.947 260.956 176.729 259.11C172.539 257.235 169.375 254.687 167.236 251.464C165.127 248.212 164.072 244.477 164.072 240.258H179.541C179.541 243.686 180.42 246.22 182.178 247.86C183.965 249.472 186.851 250.277 190.835 250.277C193.384 250.277 195.391 249.735 196.855 248.651C198.35 247.538 199.097 245.985 199.097 243.993Z"
        fill="white"
      />
      <path
        d="M246.294 218.285L256.753 197.016H274.375L256.489 228.744L274.858 261H257.061L246.294 239.335L235.527 261H217.773L236.099 228.744L218.257 197.016H235.835L246.294 218.285Z"
        fill="white"
      />
    </svg>
  );
};
