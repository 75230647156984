import { useEffect, useState } from "react";
import {
  mapVotingAssemblyCreationValues,
  mapVotingAttachment
} from "#helpers/mappers/voting";
import { BasicFormWrapper, FormStepper } from "../../../Shared/Form";
import {
  votingFormList,
  dmReplacement,
  secondarySelling,
  callForMeeting
} from "./list";
import sectionsList from "./sections";
import CustomInputRenderer from "./CustomInputRenderer";
import { handleInputChange } from "../../common/formHandler";

const VotingRightsForm = ({
  values = {},
  setFieldValue,
  handleChange,
  buttonStates,
  phoneNumber,
  entityId,
  ioId,
  assembly: { cuid: votingId } = {},
  didVerify,
  postVotingAssembly,
  putGenerateVotingCodeDM,
  postPublicVoting,
  postVotingAttachment
}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (votingId && buttonStates.success && !didVerify && values.phone_number) {
      putGenerateVotingCodeDM({
        cuid: votingId,
        phoneNumber: values.phone_number
      });
      if (fileList.length > 0) {
        fileList.forEach(async file => {
          const attchData = await mapVotingAttachment(file);
          postVotingAttachment({ cuid: votingId, ...attchData });
        });
      }
    }
  }, [votingId, buttonStates.success, values.phone_number]);

  useEffect(() => {
    if (values?.pre_compiled_questionnaire === "dm_replacement")
      setFieldValue("assembly_questions", dmReplacement);
    if (values?.pre_compiled_questionnaire === "secondary_selling")
      setFieldValue("assembly_questions", secondarySelling);
    if (values?.pre_compiled_questionnaire === "call_for_a_meeting")
      setFieldValue("assembly_questions", callForMeeting);
  }, [values?.pre_compiled_questionnaire]);

  const handleUpload = (name, info = {}, opts = {}) => {
    const { file, fileList } = info;
    let newState = {};
    if (opts.multiple) {
      newState = {
        [name]: fileList.map(item => item.originFileObj)
      };
    } else if (file?.status === "done") {
      newState = {
        [name]: file.originFileObj
      };
    }

    const localFileList = fileList.map(localFile => {
      return {
        ...localFile,
        status: "done"
      };
    });

    setFileList(localFileList);
  };

  const handleSendCodeClick = phoneNumber => () => {
    setFieldValue("send_sms_code", "sent");
    setFieldValue("phone_number", phoneNumber);
    const mappedValues = mapVotingAssemblyCreationValues(
      values,
      entityId,
      ioId
    );
    postVotingAssembly(mappedValues);
  };

  const handleVerifyClick = token => () => {
    postPublicVoting({
      cuid: votingId,
      token,
      votingId
    });
  };

  let localSectionsList = sectionsList;
  let localFormList = votingFormList(values);

  if (
    values?.voting_objective &&
    values?.voting_objective !== "internal_syndicate_decision"
  ) {
    localSectionsList = localSectionsList.filter(section => section.id !== 3);
    localFormList = localFormList.filter(item => item?.sectionId !== 3);
  }

  const formArgs = {
    list: localFormList,
    sections: localSectionsList,
    values,
    inputEvents: {
      values,
      onUpload: handleUpload,
      onChange: type =>
        handleInputChange({ handleChange, setFieldValue, values })(type),
      showUploadList: true,
      multiple: true,
      fileList
    },
    CustomInputRenderer: CustomInputRenderer({
      setFieldValue,
      values,
      handleSendCodeClick,
      handleVerifyClick,
      phoneNumber,
      success: buttonStates.success
    }),
    success: didVerify,
    successTitle: "You have successfully created a voting assembly.",
    finish: false,
    skippable: false
  };

  return <FormStepper {...formArgs} />;
};

export default BasicFormWrapper(VotingRightsForm);
