import { List } from "antd";
import filters from "../../../Shared/FilterSidebar/filters";
import {
  addressFields,
  bankAccountDetailsFields,
  listAddsSubmitHandler,
  listAddsEditHandler
} from "./common";

const companyFields = ({
  handleBlur,
  zefixHandlers,
  loading,
  previewImage,
  currencies
} = {}) => {
  const {
    industry: { array: industryFields }
  } = filters();
  return [
    {
      id: 1,
      title: "Company Logo",
      className: "_fullwidth",
      input: {
        label: " ",
        type: "avatar_uploader",
        name: "previewImage",
        accept: ".jpg,.png,.jpeg",
        required: true,
        profilePic: previewImage
      }
    },

    {
      id: 2,
      title: "Company name",
      className: "_width-50",
      input: {
        type: "autocomplete",
        name: "name",
        details: "Super Growth AG",
        // onBlur: handleBlur || null,
        // suffix: loading ? <Spin /> : null,
        required: true,
        disabled: true,
        ...zefixHandlers
      }
    },

    {
      id: 3,
      title: "UID",
      className: "_width-50",
      input: {
        type: "text",
        name: "idNumber"
      }
    },
    {
      title: "Legal representatives",
      className: "_fullwidth",
      input: {
        type: "table_list",
        name: "legalReps",
        modalProps: {
          submitHandler: listAddsSubmitHandler,
          editHandler: listAddsEditHandler
        },
        itemRenderer: ({ firstName, lastName }) => (
          <List.Item.Meta title={`${firstName} ${lastName}`} />
        ),
        fields: [
          {
            title: "First name",
            className: "_width-50",
            input: {
              name: "firstName",
              type: "text"
            }
          },
          {
            title: "Last name",
            className: "_width-50",
            input: {
              name: "lastName",
              type: "text"
            }
          }
        ]
      }
    },
    ...addressFields,
    {
      id: 11,
      title: "Description of your business activity",
      className: "_fullwidth",
      input: {
        type: "textarea",
        name: "description",
        details: "Business' description in English"
      }
    },
    ...bankAccountDetailsFields({ currencies }),

    {
      id: 7,
      title: "Website",
      className: "_width-50",
      input: {
        type: "text",
        name: "websiteUrl",
        details: "www.supergrowth.com"
      }
    },
    {
      title: "Linkedin profile url",
      className: "_width-50",
      input: {
        type: "text",
        name: "linkedInUrl",
        details: "www.linkedin.com/in/:username"
      }
    },
    {
      title: "Twitter profile url",
      className: "_width-50",
      input: {
        type: "text",
        name: "twitterUrl",
        details: "www.twitter.com/:username"
      }
    }
  ];
};

export { companyFields };
