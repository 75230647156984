import * as commitService from "../../../../api/commitmentService";
import { getSingleIos } from "../../../../api/ioService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const putCommit = data => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_COMMIT_STATUS);
      await commitService.putCommitment(data);
      success(dispatch, types.UPDATE_COMMIT_STATUS, {
        data: {
          cuid: data.cuid,
          status: data.requestType === "complete" ? 1 : 2
        }
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_COMMIT_STATUS, {
        error: errorData
      });
    }
  };
};

export const patchCommit = ({ moneyCommitted, ioId, ...data }) => {
  return async dispatch => {
    try {
      start(dispatch, types.PATCH_COMMITMENT);
      await commitService.patchCommitment(data);
      success(dispatch, types.PATCH_COMMITMENT, {
        data
      });

      const io_res = (await getSingleIos(ioId)) || {};

      success(dispatch, types.UPDATE_IO, {
        data: io_res.data || {}
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.PATCH_COMMITMENT, {
        error: errorData
      });
    }
  };
};

export const updateCommitDataStatus = ({
  cuid,
  requestType,
  moneyCommitted,
  ioId,
  ...data
}) => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_COMMIT_DATA_STATUS);
      await commitService.patchCommitment({ cuid, ...data });
      const response = await commitService.putCommitment({ cuid, requestType });

      success(dispatch, types.UPDATE_COMMIT_DATA_STATUS, {
        data: {
          cuid,
          requestType,
          ...data,
          ...response.data
        }
      });

      const { data: ioData = {} } = (await getSingleIos(ioId)) || {};

      success(dispatch, types.UPDATE_IO, {
        data: ioData
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_COMMIT_DATA_STATUS, {
        error: errorData
      });
    }
  };
};
