export const mapInvestmentDetails = (values) => {
  let clientType = '';

  if (values.actingOnBehalf === 'yes') {
    if (['a', 'b', 'c', 'd'].includes(values.occupation)) {
      clientType = 'Institutional client';
    } else {
      clientType = 'Professional client';
    }
  } else {
    clientType = 'Retail client';
  }

  const investmentDetails = {
    preferredIndustries: values.investmentsIndustries,
    investmentsPerYear: values.investmentsPerYear || 0,
    clientType,
    actingOnBehalf: values.actingOnBehalf === 'true',
    occupation: values.occupation
  };

  if (values.investedAmountPerYear === 'other') {
    investmentDetails.investedAmountPerYear = Number(
      values.investmentsAmountPerYear_text
    );
  } else {
    investmentDetails.investedAmountPerYear = Number(
      values.investedAmountPerYear || 0
    );
  }

  return {
    investmentDetails
  };
};

export const calculateSegmentationOutcomes = (values) => {
  const outcomeA =
    values.professionalClientBool === 'yes' ||
    values.professionalClientExtra === 'no' ||
    values.institutionalClientExtra === 'yes';
  const outcomeB = values.retailClient === 'A';
  const outcomeC = values.retailClientAssets === 'yes';
  const outcomeD = values.authPersonBackground === 'yes';
  const outcomeX = values.professionalClientExtra === 'yes';
  const outcomeY =
    values.institutionalClientBool === 'yes' ||
    values.institutionalClientExtra === 'no';

  return {
    outcomeA,
    outcomeB,
    outcomeC,
    outcomeD,
    outcomeX,
    outcomeY
  };
};

export const mapPersonalDetails = (values) => {
  const payload = {
    firstName: values.firstName,
    lastName: values.lastName,
    biography: values.biography,
    address: values.address,
    zipCode: values.postalCode,
    city: values.city,
    country: values.country,
    mobilePhone: values.mobilePhone,
    primarySocialNetwork: values.linkedIn
  };

  return payload;
};
