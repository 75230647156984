import { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { mapClTsValues } from "#helpers/mappers/convertibles";
import ioTypes from "#constants/io/ioTypes";
import { closedValues } from "#constants/io/closed";
import Form from "./form/Presentational";

const ClaTermsheetPresentational = ({
  entityId,
  getEntityShareholders,
  readCurrencies,
  userId,
  clearState,
  fetchSingleIo,
  getEntityMemberships,
  createDealAndBulkMembers,
  updateDealAndBulkMembers,
  getIoMemberships,
  ioMemberships,
  deleteMembership,
  getCommits,
  getFormData,
  location,
  esopShares,
  psopShares,
  ...props
}) => {
  const { id } = useParams();

  useEffect(() => {
    clearState("io");
    readCurrencies();
    getEntityShareholders({ entity_id: entityId });
    getEntityMemberships({ id: entityId });
    getFormData();
    if (id) {
      fetchSingleIo(id);
      getIoMemberships({ io_id: id });
      getCommits({ entity_id: entityId });
    }
  }, []);

  const handleSubmit = values => {
    const { ioColl: { data: { closed } = {} } = {} } = props;
    const finalClosed =
      closed < closedValues.TS_DONE || !closed ? closedValues.TS_DONE : closed;
    const defaults = {
      type: ioTypes.UNPOOLED_DEBT,
      entityId,
      leadInvestor: userId,
      fundingGoal: values.moneyRaised,
      minimal: 1000,
      acceptsCommitments: false
    };
    if (id) {
      const updating = true;
      const cla = mapClTsValues(
        values,
        updating,
        false,
        esopShares,
        psopShares
      );
      const { investorsTable: memberships = [] } = values;
      updateDealAndBulkMembers({
        id,
        dealData: { ...cla, fundingGoal: values.moneyRaised },
        memberships,
        ioMemberships
      });
    } else {
      const cla = mapClTsValues(values, false, false, esopShares, psopShares);
      createDealAndBulkMembers({
        dealData: { ...defaults, ...cla, closed: finalClosed },
        memberships: values.investorsTable
      });
    }
  };

  const { step } = queryString.parse(location.search);
  const { pathname: currPath } = location;

  return (
    <Form
      handleSubmit={handleSubmit}
      {...props}
      ioMemberships={ioMemberships}
      step={step}
      currPath={currPath}
      esopShares={esopShares}
      psopShares={psopShares}
    />
  );
};

ClaTermsheetPresentational.propTypes = {
  entityId: PropTypes.string,
  getEntityShareholders: PropTypes.func,
  readCurrencies: PropTypes.func,
  userId: PropTypes.string,
  clearState: PropTypes.func,
  fetchSingleIo: PropTypes.func
};

export default ClaTermsheetPresentational;
