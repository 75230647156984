export default {
  FETCH_MEMBERSHIPS: "FETCH_MEMBERSHIPS",
  FETCH_PORTFOLIO_MEMBERSHIPS: "FETCH_PORTFOLIO_MEMBERSHIPS",
  CREATE_MEMBERSHIP: "CREATE_MEMBERSHIP",
  BULK_CREATE_MEMBERSHIP: "BULK_CREATE_MEMBERSHIP",
  RESEND_INVITATION_EMAIL: "RESEND_INVITATION_EMAIL",
  PUT_IO_MEMBERSHIP_STATUS: "PUT_IO_MEMBERSHIP_STATUS",
  PATCH_IO_MEMBERSHIP: "PATCH_IO_MEMBERSHIP",
  DELETE_MEMBERSHIP: "DELETE_MEMBERSHIP"
};
