export const injectScript = () => {
  const script = document.createElement('script');

  script.src = '//js.hs-scripts.com/5878647.js';
  script.async = true;
  script.defer = true;
  script.id = 'hs-script-loader';
  script.type = 'text/javascript';

  document.body.appendChild(script);
};

export const ejectScript = () => {
  const scripted = document.getElementById('hs-script-loader');
  const elem = document.getElementById('hubspot-messages-iframe-container');
  if (scripted) {
    scripted.parentNode.removeChild(scripted);
  }
  if (!document.getElementById('hs-script-loader') && elem) {
    elem.parentNode.removeChild(elem);
  }
};
