import { useEffect } from "react";
import PropTypes from "prop-types";
import { FormStepper, BasicFormWrapper } from "../../../Shared/Form";
import generateList from "./list";
import sectionsList from "./sections";
import {
  handleInputChange,
  handleCheckboxInArray
} from "../../common/formHandler";
import CustomInputRenderer from "../../common/Inputs/CustomInputRenderer";
import { GeneratorSuccess } from "../../common/successViews";
import { mapEquityDataToReadableFormVals } from "#helpers/mappers/equity";

const NotaryForm = ({
  shareholdersColl,
  membershipsColl,
  values,
  handleChange,
  setFieldValue,
  handleSubmit,
  buttonStates,
  shareTypes,
  setValues,
  entity,
  ioColl,
  commitsColl,
  fullyDiluted,
  preValue
}) => {
  useEffect(() => {
    if (
      shareholdersColl.didFetch &&
      membershipsColl.didFetch &&
      commitsColl.didFetch
    ) {
      const { data = [] } = shareholdersColl;
      const formatted = data
        .filter(({ shares }) => shares.length > 0)
        .map(({ entityMembershipId, user, shares }) => {
          const sharesCap = (number, type) => number * shareTypes[type];

          const totalShares = shares.reduce(
            (total, { number, type } = {}) => total + sharesCap(number, type),
            0
          );
          return {
            key: entityMembershipId,
            membershipId: entityMembershipId,
            shareCapital: Number(totalShares).toFixed(2),
            ...user
          };
        });

      //membership
      const formattedMemberships = membershipsColl.data
        .filter(
          ({ isDirector, role, representativeOf }) =>
            representativeOf !== "board_observer" &&
            (role === "director" || isDirector)
        )
        .map(
          ({
            signingAuthority,
            cuid,
            user,
            representativeOf,
            presidentOfTheBoard
          }) => ({
            key: cuid,
            representativeOf,
            presidentOfTheBoard,
            signingAuthority,
            cuid,
            ...user
          })
        );
      //commits

      const reducedData = commitsColl.data.reduce((currData, item) => {
        const { amount } = item;

        const { totalAmount } = currData;

        return {
          totalAmount: totalAmount + amount
        };
      }, {});
      const { data: io } = ioColl;

      const { totalAmount = 0 } = reducedData;
      const { termSheet: { issuePrice = 0 } = {} } = io;
      const {
        shares = 0,
        sharesNominalValue = 1,
        bank: { blockedAccount = " ", accountNumber = " " } = {},
        shareCapital
      } = entity;

      const newSharesNumber = totalAmount / issuePrice;
      const newSharesCapital = (shares + newSharesNumber) * sharesNominalValue;

      setValues({
        shareholders: formatted,
        shareCapital: formatted,
        //memberships
        boardOfDirectors: formattedMemberships,
        //commits
        newSharesNumber,
        newSharesCapital,
        sharesNumber: shares,
        capDepAcc: accountNumber,
        capDepAccNo: blockedAccount,
        newSharePrice: issuePrice,
        sharesCapital: shareCapital,
        totalAmount
      });
    }
  }, [
    shareholdersColl.didFetch,
    membershipsColl.didFetch,
    commitsColl.didFetch
  ]);

  let calculatedSharePrice = 0;
  const { employeeParticipationPlan: { esopShares = 0 } = {}, shares } = entity;
  if (fullyDiluted) {
    calculatedSharePrice = preValue / (esopShares + shares);
  } else {
    calculatedSharePrice = preValue / shares;
  }

  useEffect(() => {
    const { didFetch, data } = ioColl;
    const { notary = {} } = mapEquityDataToReadableFormVals(data, {
      notary: true
    });

    if (didFetch) {
      setValues(notary);
    }
  }, [ioColl.didFetch]);
  const composedInputHandlers = {
    checkboxHandler: handleCheckboxInArray(setFieldValue, values),
    radioHandler: handleChange
  };

  const generatedList = generateList({
    composedInputHandlers,
    values,
    shareCapital: entity.shareCapital,
    calculatedSharePrice
  });

  const formArgs = {
    sections: sectionsList,
    list: generatedList,
    values,
    inputEvents: {
      onChange: type =>
        handleInputChange({ handleChange, setFieldValue, values })(type)
    },
    CustomInputRenderer,
    clickableSection: true,
    handleSubmit,
    buttonStates,
    success: buttonStates.success,
    CustomSuccess: GeneratorSuccess({
      headline:
        "Congrats, you just finished to set-up the notary documents for your next fundraising round.",
      subContent: [
        "You can come back and edit your notary documents at any time.",
        "If you wish to add further terms, which are not covered in the questionnaire we recommend to reach out to X’s Startup Desk."
      ]
    })
  };

  return <FormStepper {...formArgs} />;
};

NotaryForm.propTypes = {
  shareholdersColl: PropTypes.object,
  membershipsColl: PropTypes.object,
  values: PropTypes.object,
  buttonStates: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  shareTypes: PropTypes.array,
  setValues: PropTypes.func,
  entity: PropTypes.object,
  ioColl: PropTypes.object,
  io: PropTypes.object,
  commitsColl: PropTypes.object,
  setFieldError: PropTypes.func,
  errors: PropTypes.object
};

export default BasicFormWrapper(NotaryForm);
