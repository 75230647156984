import { useEffect } from "react";
import propTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import sharesSchema from "#validators/management/shares";
import { goBack } from "#helpers/general";
import {
  mapSharesFormValues,
  mapUpdateSharesFormValues,
  mapEditSharesFromValues
} from "#helpers/mappers/shares";
import { BasicForm } from "../Shared/Form";
import { sharesClassFields } from "./components/fields";
import { FormBtnContainer } from "../Shared/Button";
import BasicWrapper from "../Shared/BasicWrapper";
import { withPageTitle } from "../Shared/hocs";

const SharesClassPresentational = ({
  onCancel,
  postShareClasses,
  loading,
  success,
  entityId,
  cleanup,
  list = [],
  updateShareClassesById,
  triggerSnackbar,
  didCreate,
  didUpdate,
  readCurrencies,
  currencies = [],
  ...modalProps
}) => {
  const history = useHistory();
  useEffect(() => {
    readCurrencies();
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        onCancel();
        cleanup("shares");
      }, 3000);
      setTimeout(() => {
        history.goBack();
      }, 3001);
    }
  }, [success]);

  useEffect(() => {
    if (didCreate) {
      triggerSnackbar({
        variant: "success",
        message: "Successfully added a new share",
        placement: "bottomLeft",
        open: true
      });
    }
    if (didUpdate) {
      triggerSnackbar({
        variant: "success",
        message: "Successfully updated a share",
        placement: "bottomLeft",
        open: true
      });
    }
  }, [didCreate, didUpdate]);

  let share;
  const params = useParams();

  if (params.id) {
    share = list.find(({ cuid }) => cuid === params.id) || {};
  }

  let formValues = {
    votesNumber: "1"
  };

  if (share) {
    formValues = {
      ...formValues,
      ...mapEditSharesFromValues(share)
    };
  }

  const handleSubmit = values => {
    if (params.id && share) {
      updateShareClassesById(
        share.cuid,
        mapUpdateSharesFormValues({ ...values })
      );
    } else {
      postShareClasses(mapSharesFormValues({ entityId, ...values }));
    }
  };
  const customFormBtn = ({ onClick }) => (
    <div className="custom-btn-container">
      <h3>Save your changes</h3>
      <span>
        Please note, this information must be accurate as it will automatically
        be inputted into all legal documents.
      </span>
      <FormBtnContainer
        onOk={onClick}
        onCancel={goBack(history)}
        buttonStates={{ loading, success }}
      />
    </div>
  );
  customFormBtn.propTypes = {
    onClick: propTypes.func
  };

  const formArgs = {
    useCustomHook: ({ values, setFieldValue }) => (
      useEffect(() => {
        if (share) {
          setFieldValue("isUpdating", true);
        } else {
          setFieldValue("isUpdating", false);
        }
      }, [share]),
      useEffect(() => {
        const shareNameExists = list
          .map(({ name: shareName }) => shareName)
          .includes(values.shareCategoryName);
        setFieldValue("shareNameExists", shareNameExists);
      }, [values.shareCategoryName])
    )
  };
  const shareNames = list.map(({ name }) => name);
  return (
    <BasicWrapper className="cla-mgmt-container">
      <div className="shares-class-container">
        <h1>
          To set-up the existing shareholder structure, please create the
          current share categories your company has. Please use the same wording
          as in your current shareholder agremeent. Please note, this
          information must be accurate as it will automatically be inputted into
          all legal documents.
        </h1>
        <BasicForm
          rawInitValues
          values={formValues}
          fieldList={sharesClassFields(shareNames, currencies)}
          CustomFooter={customFormBtn}
          handleSubmit={handleSubmit}
          validationSchema={sharesSchema}
          {...formArgs}
        />
      </div>
    </BasicWrapper>
  );
};

SharesClassPresentational.propTypes = {
  onCancel: propTypes.func,
  postShareClasses: propTypes.func,
  cleanup: propTypes.func,
  loading: propTypes.bool,
  success: propTypes.bool,
  entityId: propTypes.string,
  shareId: propTypes.string
};

export default withPageTitle("Share management")(SharesClassPresentational);
