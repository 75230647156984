import { getMaxArray } from "#helpers/arrayFuncs";
import { mapEquityInvestorValues } from "#helpers/mappers/equity";
import { postMembership } from "../../../../api/ioMembershipsService";

import * as IoService from "../../../../api/ioService";
import * as activityService from "../../../../api/activityService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import { triggerSnackbar } from "#core/modules/snackbar/actions";

export const createIo = ({ attachments, ...ios }) => {
  return async dispatch => {
    try {
      start(dispatch, types.ADD_IO);

      const response = await IoService.addIosService(ios);
      if (response.data && response.data.cuid && attachments) {
        await Promise.all(
          attachments.map(item =>
            IoService.postIoAttachment({
              ioId: response.data.cuid,
              attachment: item
            })
          )
        );
      }

      success(dispatch, types.ADD_IO, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.ADD_IO, {
        error: errorData
      });
    }
  };
};

export const postIoDocs = docs => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_IO_DOCS);
      const response = await IoService.postIoDocs(docs);

      const responseValue = () => {
        return (
          response.data &&
          response.data.createdSuccessfully &&
          response.data.createdSuccessfully.map(item => {
            return item.output;
          })
        );
      };
      success(dispatch, types.POST_IO_DOCS, {
        data: responseValue()
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_IO_DOCS, {
        error: errorData
      });
    }
  };
};

export const postDocVisit = data => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_DOC_VISIT);
      const response = await activityService.postDocVisit(data);
      success(dispatch, types.POST_DOC_VISIT, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_DOC_VISIT, {
        error: errorData
      });
    }
  };
};

export const postAttachment = ({ ioId, attachment }) => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_ATTACHMENT);
      const response = await IoService.postIoAttachment({
        ioId,
        attachment
      });
      success(dispatch, types.POST_ATTACHMENT, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_ATTACHMENT, {
        error: errorData
      });
    }
  };
};

export const bulkPostAttachments = ({ ioId, attachments }) => {
  return async dispatch => {
    try {
      start(dispatch, types.BULK_POST_ATTACHMENTS);
      const responses = await Promise.allSettled(
        attachments.map(attachment =>
          IoService.postIoAttachment({
            ioId,
            attachment
          })
        )
      );

      const filteredResponses = responses
        .filter(_ => _.status === "fulfilled")
        .map(({ value }) => value.data);

      success(dispatch, types.BULK_POST_ATTACHMENTS, {
        data: filteredResponses
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.BULK_POST_ATTACHMENTS, {
        error: errorData
      });
    }
  };
};

export const createDealAndBulkMembers = ({ dealData, memberships }) => {
  return async dispatch => {
    try {
      start(dispatch, types.CREATE_DEAL_BULK_MEMBERS);

      const response = await IoService.addIosService(dealData);

      const { cuid: ioCuid } = response.data;
      const formattedMemberships = mapEquityInvestorValues(ioCuid, memberships);

      if (formattedMemberships.length > 0) {
        await Promise.all(
          formattedMemberships.map(item => postMembership(item))
        );
      }

      success(dispatch, types.CREATE_DEAL_BULK_MEMBERS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.BULK_POST_ATTACHMENTS, {
        error: errorData
      });
    }
  };
};

export const notifyDmForIoFinalization = params => {
  return async dispatch => {
    try {
      start(dispatch, types.NOTIFY_DM_FOR_IO_FINALIZATION);
      const response = await IoService.notifyDmForIoFinalization(params);
      success(dispatch, types.NOTIFY_DM_FOR_IO_FINALIZATION, {
        data: response.data
      });
      triggerSnackbar(dispatch, {
        open: true,
        message: "Deal manager has been successfully reminded.",
        variant: "info"
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.NOTIFY_DM_FOR_IO_FINALIZATION, {
        error: errorData
      });
    }
  };
};

export const confirmIoTransfer = payload => {
  return async dispatch => {
    try {
      start(dispatch, types.CONFIRM_TRANSFER);
      const response = await IoService.submitIoConfirmTransfer(payload);
      success(dispatch, types.CONFIRM_TRANSFER, {
        data: response.data
      });
      triggerSnackbar(dispatch, {
        open: true,
        message: "The transfer has been requested.",
        description:
          "Please inform the target company that the funds will be received from Leva Capital Partners AG as your pooling provider and authorized financial intermediary",
        variant: "info",
        duration: 12
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.CONFIRM_TRANSFER, {
        error: errorData
      });
    }
  };
};
