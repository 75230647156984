import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_MEMBERSHIPS:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            memberships: action.payload.data
          };
        }
      });
    case types.FETCH_PORTFOLIO_MEMBERSHIPS:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            portfolioMemberships: action.payload.data
          };
        }
      });
    case types.CREATE_MEMBERSHIP:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });
    case types.BULK_CREATE_MEMBERSHIP:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });
    case types.PUT_IO_MEMBERSHIP_STATUS:
      return baseReducer(action, state, actions.UPDATE, {
        start: () => {
          const { cuid } = action.payload.data || {};

          return {
            ...state._data,
            objectRef: cuid
          };
        },
        success: () => {
          const { memberships = [] } = state._data || {};
          const { cuid } = action.payload.data || {};

          return {
            ...state._data,
            memberships: memberships.map(({ cuid: localCuid, ...item }) =>
              localCuid === cuid
                ? {
                    cuid,
                    ...item,
                    status: 1,
                    fresh: true
                  }
                : { cuid: localCuid, ...item }
            ) // CURRENTLY WE ONLY SUPPORT APPROVING THE INVITATION SO WE CAN IMMEDIATELY SET THE STATUS TO 1
          };
        },
        fail: () => {
          return {
            ...state._data,
            objectRef: null
          };
        }
      });

    case types.PATCH_IO_MEMBERSHIP:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => ({ ...state._data })
      });

    case types.DELETE_MEMBERSHIP:
      return baseReducer(action, state, actions.DELETE, {
        success: () => ({ ...state._data })
      });

    default:
      return state;
  }
};

export default reducer;
