import * as Yup from "yup";
import { returnError } from "../common";

const convertibleMgmtSchema = Yup.object().shape({
  oclEffectsInclusionArray: Yup.array().test({
    name: "oclEffectsInclusionArray",
    message() {
      return `You previously stated that the convertibles are included in fully diluted valuation. Please select which convertibles are included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.oclEffectsInclusion === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),

  outstandingClaEffectsIncludedPreMoneyMDArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedPreMoneyMDArray",
    message() {
      return `You previously stated that the convertibles are included in fully diluted valuation. Please select which convertibles are included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedPreMoneyMD === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),

  minOclEffectsInclusionArray: Yup.array().test({
    name: "minOclEffectsInclusionArray",
    message() {
      return `You previously stated that the convertibles are included in fully diluted valuation. Please select which convertibles are included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.minOclEffectsInclusion === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),

  saleTransactionOclEffectsInclusionArray: Yup.array().test({
    name: "saleTransactionOclEffectsInclusionArray",
    message() {
      return `You previously stated that the convertibles are included in fully diluted valuation. Please select which convertibles are included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.saleTransactionOclEffectsInclusion === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),

  outstandingClaEffectsIncludedPostMoneyMDArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedPostMoneyMDArray",
    message() {
      return `You previously stated that the convertibles are included in fully diluted valuation. Please select which convertibles are included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedPostMoneyMD === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),

  outstandingClaEffectsIncludedFloorNFRArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedFloorNFRArray",
    message() {
      return `You previously stated that the convertibles are included in fully diluted valuation. Please select which convertibles are included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedFloorNFR === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),

  outstandingClaEffectsIncludedFloorSTArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedFloorSTArray",
    message() {
      return `You previously stated that the convertibles are included in fully diluted valuation. Please select which convertibles are included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedFloorST === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),

  outstandingClaEffectsIncludedFairMarketMDArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedFairMarketMDArray",
    message() {
      return `You previously stated that the convertibles are included in fully diluted valuation. Please select which convertibles are included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedFairMarketMD === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),
  issueDate: Yup.string().required(returnError("required")),
  maturityDate: Yup.string().required(returnError("required")),
  currency: Yup.string().required(returnError("required")),
  convertibleMaxAmount: Yup.number().required(returnError("required")),
  mandatoryConversion: Yup.string(),
  triggerEvent: Yup.array(),
  min_amountPrompt: Yup.string(),
  preMoneyValMinAmountPrompt: Yup.string(),
  min_amount: Yup.number(),
  preMoneyValMinAmount: Yup.string(),
  name: Yup.string().test({
    name: "name",
    message({ value = "" }) {
      return `You already have a convertible loan named "${value}" in the Convertible loan management on your Dashboard. Please change it here.`;
    },
    test(value) {
      if (this.parent.isUpdating) {
        return true;
      }
      return !value || !this.parent.convertibleNameExists;
    }
  }),
  minPreMoneyValDiluted: Yup.string(),
  minOclEffectsInclusion: Yup.string(),
  minEsopEffectsInclusion: Yup.string(),
  valuationCapBool: Yup.string(),
  preMoneyVal: Yup.number(),
  investorHasCommited: Yup.string(),
  preMoneyValDiluted: Yup.string(),
  oclEffectsInclusion: Yup.string(),
  annualInterestCalculation: Yup.string(),
  annualInterest: Yup.number(),
  interestCompensationAtConversion: Yup.string(),
  triggeringEvent: Yup.array(),
  minNextFinancingAmount: Yup.number(),
  valuationCap: Yup.number(),
  fullyDiluted: Yup.string(),
  fullyDilutedSelect: Yup.boolean(),
  outstandingClaEffectsIncluded: Yup.string(),
  esopEffectsInclusion: Yup.string(),
  discountRateBool: Yup.string(),
  discountRate: Yup.number(),
  shareTypeConversion: Yup.string(),
  saleTransactionDiscountRateBool: Yup.string(),
  saleTransactionDiscountRate: Yup.number(),
  saleTransactionValuationCapBool: Yup.string(),
  saleTransactionPreMoneyVal: Yup.string(),
  saleTransactionPreMoneyValDiluted: Yup.string(),
  saleTransactionOclEffectsInclusion: Yup.string(),
  saleTransactionEsopEffectsInclusion: Yup.string(),
  saleTransactionLessPercentage: Yup.number(),
  maturityDatePreMoneyValPrompt: Yup.string(),
  maturityDatePreMoneyVal: Yup.string(),
  maturityDatePreMoneyValDiluted: Yup.string(),
  maturityDateOclEffectsInclusion: Yup.string(),
  maturityDateEsopEffectsInclusion: Yup.string(),
  maturityDateShareTypeConversion: Yup.string(),
  interest: Yup.string(),
  interestRate: Yup.number(),
  interestCalculation: Yup.string(),
  subordinated: Yup.string(),
  claAddition: Yup.string()
});

export default convertibleMgmtSchema;
