import { useEffect } from "react";
import { handleNav } from "#helpers/general";
import { InvestmentCard } from "../../../Shared/Cards";
import { withPageTitle, withContentFallback } from "../../../Shared/hocs";

const PortofolioPresentational = ({
  loading,
  history,
  industries,
  syndicatesWhereUserIsAM = [],
  getPortfolioSyndicates,
  clearStateData,
  clearState
}) => {
  useEffect(() => {
    clearState("syndicate");
    clearStateData("syndicate");
    getPortfolioSyndicates({ io_status: [1, 2] });
  }, []);

  const MainView = () => (
    <div className="portfolio-wrapper">
      {Array.isArray(syndicatesWhereUserIsAM) &&
        syndicatesWhereUserIsAM.map(({ investments, ...syndicate }) => {
          return investments.map(({ entity = {}, ...item }) => {
            return (
              <InvestmentCard
                {...item}
                syndicateName={syndicate.name}
                description={entity.description}
                previewImage={entity.previewImage}
                investmentType={entity.investmentType}
                commonName={entity?.commonName}
                portfolioDisplay
                onClickHandler={handleNav(
                  history,
                  `/dashboard/deal_management/${item.cuid}`
                )}
                industries={industries}
              />
            );
          });
        })}
    </div>
  );

  const MainWrapper = withContentFallback({
    customizedIcon: <div></div>,
    loading,
    empty: syndicatesWhereUserIsAM.length === 0,
    customizedEmptyMsg: (
      <>
        <h3>Here you will find all your closed deals</h3>
        <p>
          You don't have any closed deal at the moment, please come back later
        </p>
      </>
    )
  })(MainView);

  return <MainWrapper />;
};

export default PortofolioPresentational;
