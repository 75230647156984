import { urlBase64ToUint8Array } from "#helpers/convertBase64";
import { postNotifsSubs } from "../../../../api/notificationsService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import serviceWorker from "../../../../public/custom-worker";

export const postNotifsSubscription = () => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_NOTIFS_SUBS);

      const register = await navigator.serviceWorker.ready;

      const publicVapidKey = process.env.PUBLIC_VAPID_KEY;

      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
      });

      const response = await postNotifsSubs(subscription);
      success(dispatch, types.POST_NOTIFS_SUBS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_NOTIFS_SUBS, {
        error: errorData
      });
    }
  };
};
