import { baseSelector } from "./utils";

const selectIoDataFunc = state => {
  const data = (state._data && state._data) || {};

  return data;
};

const selectIoData = baseSelector(selectIoDataFunc);

export { selectIoData };
