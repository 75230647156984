import Modal from "../../Modal";
import { CapTable } from "../../CapTable/";

const ModalWrapper = ({ visible, handleClose, ...props }) => {
  return (
    <Modal
      title="Cap Table"
      visible={visible}
      onCancel={handleClose}
      footer={null}
      width={600}
    >
      <CapTable {...props} />
    </Modal>
  );
};

export default ModalWrapper;
