import * as IoService from "../../../../api/ioService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const fetchAllIos = id => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_ALL_IOS);
      const response = await IoService.getAllIos(id);
      success(dispatch, types.FETCH_ALL_IOS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_ALL_IOS, {
        error: errorData
      });
    }
  };
};

export const fetchSingleIo = ioId => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_SINGLE_IO);
      const response = await IoService.getSingleIos(ioId);
      success(dispatch, types.FETCH_SINGLE_IO, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_SINGLE_IO, {
        error: errorData
      });
    }
  };
};

export const getPortfolio = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_PORTFOLIO);
      const response = await IoService.getPortfolio(params);
      success(dispatch, types.FETCH_PORTFOLIO, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_PORTFOLIO, {
        error: errorData
      });
    }
  };
};

export const fetchCaptable = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_CAPTABLE);
      const response = await IoService.getCapTable(params);
      success(dispatch, types.FETCH_CAPTABLE, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_CAPTABLE, {
        error: errorData
      });
    }
  };
};

export const getRoundType = data => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_ROUND_TYPE);
      const response = await IoService.getRoundType(data);
      success(dispatch, types.FETCH_ROUND_TYPE, {
        data: response.data
      });
    } catch (error) {
      const { message } = errorData;
      catchError(dispatch, message);
      const errorData = getErrorData(error);
      fail(dispatch, types.FETCH_ROUND_TYPE, {
        error: errorData
      });
    }
  };
};
