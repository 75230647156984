import { Progress } from 'antd';

const ProgressWrapper = ({ title, percentage, value, indicator = '' }) => {
  const finalClassname = `progress-wrapper ${
    indicator ? `indicator-${indicator}` : ''
  }`;

  return (
    <div className={finalClassname}>
      <div className="info">
        <span className="title">{title}</span>
        <span className="value">{value}</span>
      </div>
      <Progress
        percent={percentage}
        showInfo={false}
        className="custom-progress"
      />
    </div>
  );
};

export default ProgressWrapper;
