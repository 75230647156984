import { roles } from "#constants/roles";
import honkerIcon from "../../../../../assets/illustrations/honker.png";
import { dmUpdates, investorUpdates, privacyPolicyUpdates } from "../updates";
import { Button } from "../../../Button";
import Switch from "../../../Switch";

const platformUpdates = ({ userRole, handleSwitch, signed, handleClick }) => {
  const updates =
    userRole === roles.ASSET_MANAGER ? dmUpdates : investorUpdates;

  return (
    <>
      <img src={honkerIcon} className="honker-icon" />
      <div className="right-pane">
        <h1>We have some exciting updates!</h1>
        <h3>
          We have these new features for you. Thank you for always sharing your
          valuable feedback.
        </h3>
        <ul className="list">
          {updates.map(update => (
            <li>{`- ${update}`}</li>
          ))}
        </ul>
        <h3>
          We also updated our{" "}
          <a
            href="/doc_viewer?doc=/docs/privacypolicy.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            privacy policy.
          </a>{" "}
          These are the updates:
        </h3>
        <ul className="list">
          {privacyPolicyUpdates.map(update => (
            <li>{`- ${update}`}</li>
          ))}
        </ul>
        <h3>
          Finally, we have a new address: Bernapark 1 | 3066 Stettlen |
          Switzerland
        </h3>
        <Switch
          label="I have read, understood, and I accept the agreement(s) above"
          onChange={handleSwitch}
          value={signed}
          name="sign"
        />
        <Button
          onClick={handleClick}
          label="Thanks, got it!"
          className="modal-button"
          disabled={!signed}
        />
      </div>
    </>
  );
};

export default platformUpdates;
