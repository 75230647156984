import { start, success, fail } from "../../../utils/helper_actions";
import types from "../action_types";
import * as votingService from "../../../../api/votingService";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const putVoting = data => {
  return async dispatch => {
    try {
      start(dispatch, types.PUT_VOTING);
      const response = await votingService.putVoting(data);
      success(dispatch, types.PUT_VOTING, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.PUT_VOTING, {
        error: errorData
      });
    }
  };
};

export const putGenerateVotingCodeDM = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GENERATE_VOTING_CODE_DM);
      const response = await votingService.putGenerateVotingCodeDM(data);
      console.log(response);
      success(dispatch, types.GENERATE_VOTING_CODE_DM, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GENERATE_VOTING_CODE_DM, {
        error: errorData
      });
    }
  };
};

export const putGenerateVotingCodeInv = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GENERATE_VOTING_CODE_INV);
      const response = await votingService.putGenerateVotingCodeInv(data);
      console.log(response);
      success(dispatch, types.GENERATE_VOTING_CODE_INV, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GENERATE_VOTING_CODE_INV, {
        error: errorData
      });
    }
  };
};

export const patchVotingAssembly = data => {
  return async dispatch => {
    try {
      start(dispatch, types.PATCH_VOTING);
      const response = await votingService.patchVoting(data);
      success(dispatch, types.PATCH_VOTING, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.PATCH_VOTING, {
        error: errorData
      });
    }
  };
};
