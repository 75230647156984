import { basicOption, inputNumberFormatter } from "#helpers/general";
import { claTriggerEvents, claTsKeys } from "#constants/cla";
import { confirmationPrompt } from "../../../common/prompts";
import { convertibleCols } from "../../../CLA/form/fields/tableCols";
import { cantonsSortedByName } from "../../../../Shared/Settings/cantons";

const optionalFields = (
  { triggeringEvent = [], conversionPriceBasisMD = [], ...values },
  currency,
  handlers,
  esopShares,
  psopShares,
  registeredSeat
) => {
  const nextFinancingRound = triggeringEvent.includes(
    claTriggerEvents.NEXT_FINANCING_ROUND
  );
  const saleTransaction = triggeringEvent.includes(
    claTriggerEvents.SALE_TRANSACTION
  );
  const maturityDate = triggeringEvent.includes(claTriggerEvents.MATURITY_DATE);
  const basicConversionPriceCalcDisabled = name =>
    Array.isArray(conversionPriceBasisMD) &&
    !conversionPriceBasisMD.includes(name) &&
    conversionPriceBasisMD.length >= 2;
  const fields = [
    {
      showId: false,
      title: "Next financing round",
      className: "_fullwidth _bold-15 _hidden",
      input: {},
      display: !!nextFinancingRound
    },
    {
      title: "Do you want to qualify your next financing round event?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.QUALIFY_EVENT_NFR,
        ...confirmationPrompt
      },
      display: !!nextFinancingRound
    },

    {
      title:
        "Is there a minimum investment amount required in the next equity financing round in order for the conversion to be triggered?",
      className: "_fullwidth",
      input: {
        name: "minNextFinancingAmountNFRPrompt",
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound && values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
    },
    {
      title:
        "What is the minimum amount for the next equity financing round that will trigger a conversion?",
      className: "_fullwidth",
      input: {
        type: "number",
        name: claTsKeys.MIN_AMOUNT_TRIGGER_CONVERSION,
        formatter: value =>
          value
            ? `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            : "",
        parser: value =>
          value.replace(new RegExp(`${currency}\\s?|(\\'*)`, "g"), "")
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" &&
        values.minNextFinancingAmountNFRPrompt === "yes"
    },
    {
      title:
        "Is there a minimum pre-money valuation for the next equity financing round required in order for the conversion to be triggered?",
      className: "_fullwidth",
      input: {
        name: "minValuationNFRPrompt",
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound && values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
    },
    {
      title:
        "What is the minimum pre-money valuation of the next equity financing round that will trigger a conversion?",
      className: "_fullwidth",
      input: {
        type: "number",
        name: claTsKeys.MIN_VALUATION_NFR,
        formatter: value =>
          value
            ? `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            : "",
        parser: value =>
          value.replace(new RegExp(`${currency}\\s?|(\\'*)`, "g"), "")
      },
      display:
        !!nextFinancingRound &&
        values.minValuationNFRPrompt === "yes" &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
    },
    {
      title: "Is the pre money valuation fully diluted?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED,
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound &&
        values.minValuationNFRPrompt === "yes" &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
    },
    {
      title:
        "Are the effects of potentially outstanding convertible loans included?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE,
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED] === "yes" &&
        values.minValuationNFRPrompt === "yes" &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
    },

    {
      className: "_fullwidth",
      title:
        "Please select the convertibles you want to include in the fully diluted valuation.",
      input: {
        name: claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY,
        type: "table_list",
        cols: convertibleCols(
          values,
          handlers,
          claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
        )
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED] === "yes" &&
        values.minValuationNFRPrompt === "yes" &&
        values[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE] === "yes" &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
    },
    {
      title: "Are the effects of a potentially outstanding EPP included? ",
      className: "_fullwidth",
      input: {
        name: claTsKeys.MIN_POTENTIALLY_OUTSTANDING_EPP,
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED] === "yes" &&
        values.minValuationNFRPrompt === "yes" &&
        (esopShares > 0 || psopShares > 0) &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
    },

    {
      title:
        "Select which option plan shall be included in the fully diluted valuation",
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "minEffectsIncludedNFREsopPsop",
        options: [
          basicOption(`esop`, `ESOP shares ${esopShares}`, {
            disabled: esopShares === 0
          }),
          basicOption(`psop`, `PSOP shares ${psopShares}`, {
            disabled: psopShares === 0
          })
        ]
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED] === "yes" &&
        values.minValuationNFRPrompt === "yes" &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" &&
        values[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_EPP] === "yes"
    },
    {
      title: `Shall there be a valuation floor defined in the convertible loan agreement for the purpose of the conversion in Next Financing Round?`,
      details: `A “floor” is the minimum valuation of a company for the automatic
      conversion of the loan. In most cases, qualified equity financing
      will not trigger an automatic conversion of the loan to equity
      unless a minimum valuation is achieved, hence the “floor.” The floor
      is an investor-friendly provision. As a founder, however, it is
      reasonable to agree to a floor and will enable you to attract
      angels.`,
      className: "_fullwidth",
      input: {
        name: "valuationFloorDefined",
        ...confirmationPrompt
      },
      display: !!nextFinancingRound
    },

    {
      title: "What is the valuation floor?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.VALUATION_FLOOR_NFR,
        type: "number",
        ...inputNumberFormatter(currency)
      },
      display: !!nextFinancingRound && values.valuationFloorDefined === "yes"
    },
    // fully diluted - esop
    {
      title: "Is the floor valuation fully diluted?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR,
        ...confirmationPrompt
      },
      display: !!nextFinancingRound && values.valuationFloorDefined === "yes"
    },
    {
      title:
        "Are the effects of potentially outstanding convertible loans included?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR,
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound &&
        values.valuationFloorDefined === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes"
    },
    {
      className: "_fullwidth",
      title:
        "Please select the convertibles you want to include in the fully diluted valuation.",
      input: {
        name:
          claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR,
        type: "table_list",
        cols: convertibleCols(
          values,
          handlers,
          claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR
        )
      },
      display:
        !!nextFinancingRound &&
        values.valuationFloorDefined === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes" &&
        values[
          claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR
        ] === "yes"
    },
    {
      title: "Are the effects of a potentially outstanding EPP included? ",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR,
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound &&
        values.valuationFloorDefined === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes" &&
        (esopShares > 0 || psopShares > 0)
    },

    {
      title:
        "Select which option plan shall be included in the fully diluted valuation",
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "esopPsopEffectsIncludedFloorNFR",
        options: [
          basicOption(`esop`, `ESOP shares ${esopShares}`, {
            disabled: esopShares === 0
          }),
          basicOption(`psop`, `PSOP shares ${psopShares}`, {
            disabled: psopShares === 0
          })
        ]
      },
      display:
        !!nextFinancingRound &&
        values.valuationFloorDefined === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR] ===
          "yes"
    },

    //
    {
      title:
        "Shall the Investor be allowed to request a conversion of the loan, if the qualification(s) of the next financing are not met?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.REQUEST_LOAN_CONVERSION_NFR,
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound && values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
    },
    {
      title:
        "Shall there be a discount rate defined in the convertible loan agreement upon conversion in case the Investor request a conversion?",
      className: "_fullwidth",
      input: {
        name: "requestLoanDiscountNFRPrompt",
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" &&
        values[claTsKeys.REQUEST_LOAN_CONVERSION_NFR] === "yes"
    },
    {
      title: "What is the discount",
      className: "_fullwidth",
      input: {
        name: claTsKeys.REQUEST_LOAN_DISCOUNT_NFR,
        type: "number",
        formatter: value => (value && value <= 100 ? `${value}%` : ""),
        parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), "")
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" &&
        values[claTsKeys.REQUEST_LOAN_CONVERSION_NFR] === "yes" &&
        values.requestLoanDiscountNFRPrompt === "yes"
    },
    {
      title:
        "Into which share class will the convertible loan be converted upon Conversion in case the Investor request a conversion?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.REQUEST_LOAN_SHARE_TYPE_NFR,
        type: "select_btn",
        options: [
          basicOption("common", "Common Shares"),
          basicOption("preferential_common", "Preferential Common Shares"),
          basicOption("preferred", "Preferred Shares"),
          basicOption(
            "next_financing_round",
            `According to next ${
              values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
                ? "qualified"
                : values[claTsKeys.QUALIFY_EVENT_NFR] === "no"
                ? "non-qualified"
                : ""
            } financing round`
          )
        ]
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" &&
        values[claTsKeys.REQUEST_LOAN_CONVERSION_NFR] === "yes"
    },

    {
      title:
        "Shall there be a valuation cap defined in the convertible loan agreement for the purpose of the conversion?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.VALUATION_CAP,
        ...confirmationPrompt
      },
      display: !!nextFinancingRound
    },

    {
      title: "What is the pre-money valuation cap?",
      className: "_fullwidth",
      input: {
        type: "number",
        name: claTsKeys.PRE_MONEY_VAL,
        formatter: value =>
          value
            ? `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            : "",
        parser: value =>
          value.replace(new RegExp(`${currency}\\s?|(\\'*)`, "g"), "")
      },
      display: !!nextFinancingRound && values[claTsKeys.VALUATION_CAP] === "yes"
    },
    {
      title: "Is the pre money valuation fully diluted?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.NEXT_FINANCING_FULLY_DILUTED,
        ...confirmationPrompt
      },
      display: !!nextFinancingRound && values[claTsKeys.VALUATION_CAP] === "yes"
    },
    {
      title:
        "Are the effects of potentially outstanding convertible loans included?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.CL_EFFECTS,
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.NEXT_FINANCING_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.VALUATION_CAP] === "yes"
    },
    {
      className: "_fullwidth",
      title:
        "Please select the convertibles you want to include in the fully diluted valuation.",
      input: {
        name: claTsKeys.CL_EFFECTS_ARRAY,
        type: "table_list",
        cols: convertibleCols(values, handlers, claTsKeys.CL_EFFECTS_ARRAY)
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.NEXT_FINANCING_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.VALUATION_CAP] === "yes" &&
        values[claTsKeys.CL_EFFECTS] === "yes"
    },
    {
      title: "Are the effects of a potentially outstanding EPP included? ",
      className: "_fullwidth",
      input: {
        name: claTsKeys.POTENTIALLY_OUTSTANDING_EPP,
        ...confirmationPrompt
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.NEXT_FINANCING_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.VALUATION_CAP] === "yes" &&
        (esopShares > 0 || psopShares > 0)
    },

    {
      title:
        "Select which option plan shall be included in the fully diluted valuation",
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "esopPsopEffectsIncludedNFR",
        options: [
          basicOption(`esop`, `ESOP shares ${esopShares}`, {
            disabled: esopShares === 0
          }),
          basicOption(`psop`, `PSOP shares ${psopShares}`, {
            disabled: psopShares === 0
          })
        ]
      },
      display:
        !!nextFinancingRound &&
        values[claTsKeys.NEXT_FINANCING_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.VALUATION_CAP] === "yes" &&
        values[claTsKeys.POTENTIALLY_OUTSTANDING_EPP] === "yes"
    },

    {
      title:
        "Shall there be a discount rate defined in the convertible loan agreement upon conversion?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.DISCOUNT_RATE_BOOL,
        ...confirmationPrompt
      },
      display: !!nextFinancingRound
    },

    {
      title: "What is the discount rate?",
      className: "_fullwidth",
      details: "Discount should not be bigger than 100%",
      input: {
        type: "number",
        name: claTsKeys.DISCOUNT_RATE,
        formatter: value => (value && value <= 100 ? `${value}%` : ""),
        parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), "")
      },
      display:
        !!nextFinancingRound && values[claTsKeys.DISCOUNT_RATE_BOOL] === "yes"
    },
    {
      title:
        "Into which share class will the convertible loan be converted upon Conversion?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: claTsKeys.SHARE_TYPE_CONVERSION,
        options: [
          basicOption("common", "Common Shares", {
            tooltip:
              "If investors will receive common shares with preferential rights or preferred shares in the next financing round, the investors allocated to the convertible loans will receive the same category."
          }),
          basicOption(
            "common_with_preferential_rights",
            "Preferential Common Shares",
            {
              tooltip:
                "Contractual preferential rights are a frequent solution used in early financing rounds"
            }
          ),
          basicOption("preferred", "Preferred Shares"),
          basicOption(
            "next_financing_round",
            `According to next ${
              values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
                ? "qualified"
                : values[claTsKeys.QUALIFY_EVENT_NFR] === "no"
                ? "non-qualified"
                : ""
            } financing round`
          )
        ]
      },
      display: !!nextFinancingRound
    },

    {
      className: "_fullwidth _border-bottom _hidden",
      input: {},
      display: !!nextFinancingRound
    },

    {
      showId: false,
      title: "Sale transaction",
      className: "_fullwidth _bold-15 _hidden",
      input: {},
      display: !!saleTransaction
    },
    {
      title: "Shall there be a discount rate in case of a Sale Transaction?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.SALE_TRANSACTION_DISCOUNT_BOOL,
        ...confirmationPrompt
      },
      display: !!saleTransaction
    },

    {
      title: "What is the discount rate?",
      className: "_fullwidth",
      details: "Discount should not be bigger than 100%",
      input: {
        type: "number",
        name: claTsKeys.SALE_TRANSACTION_DISCOUNT_RATE,
        formatter: value => (value && value <= 100 ? `${value}%` : ""),
        parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), "")
      },
      display:
        !!saleTransaction &&
        values[claTsKeys.SALE_TRANSACTION_DISCOUNT_BOOL] === "yes"
    },
    {
      title:
        "Shall there be a valuation cap defined in the convertible loan agreement for the purpose of the conversion?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.SALE_TRANSACTION_VALUATION_CAP_BOOL,
        ...confirmationPrompt
      },
      display: !!saleTransaction
    },

    {
      title: "What is the pre-money valuation cap?",
      className: "_fullwidth",
      input: {
        type: "number",
        name: claTsKeys.SALE_TRANSACTION_VALUATION_CAP,
        formatter: value =>
          value
            ? `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            : "",
        parser: value =>
          value.replace(new RegExp(`${currency}\\s?|(\\'*)`, "g"), "")
      },
      display:
        !!saleTransaction &&
        values[claTsKeys.SALE_TRANSACTION_VALUATION_CAP_BOOL] === "yes"
    },

    {
      title: "Is the pre money valuation fully diluted?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.SALE_TRANSACTION_FULLY_DILUTED,
        ...confirmationPrompt
      },
      display:
        !!saleTransaction &&
        values[claTsKeys.SALE_TRANSACTION_VALUATION_CAP_BOOL] === "yes"
    },
    {
      title:
        "Are the effects of potentially outstanding convertible loans included?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE,
        ...confirmationPrompt
      },
      display:
        !!saleTransaction &&
        values[claTsKeys.SALE_TRANSACTION_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.SALE_TRANSACTION_VALUATION_CAP_BOOL] === "yes"
    },
    {
      className: "_fullwidth",
      title:
        "Please select the convertibles you want to include in the fully diluted valuation.",
      input: {
        name:
          claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY,
        type: "table_list",
        cols: convertibleCols(
          values,
          handlers,
          claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
        )
      },
      display:
        !!saleTransaction &&
        values[claTsKeys.SALE_TRANSACTION_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.SALE_TRANSACTION_VALUATION_CAP_BOOL] === "yes" &&
        values[
          claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE
        ] === "yes"
    },
    {
      title: "Are the effects of a potentially outstanding EPP included? ",
      className: "_fullwidth",
      input: {
        name: claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_EPP,
        ...confirmationPrompt
      },
      display:
        !!saleTransaction &&
        values[claTsKeys.SALE_TRANSACTION_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.SALE_TRANSACTION_VALUATION_CAP_BOOL] === "yes" &&
        (esopShares > 0 || psopShares > 0)
    },

    {
      title:
        "Select which option plan shall be included in the fully diluted valuation",
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "esopPsopEffectsIncludedST",
        options: [
          basicOption(`esop`, `ESOP shares ${esopShares}`, {
            disabled: esopShares === 0
          }),
          basicOption(`psop`, `PSOP shares ${psopShares}`, {
            disabled: psopShares === 0
          })
        ]
      },
      display:
        !!saleTransaction &&
        values[claTsKeys.SALE_TRANSACTION_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.SALE_TRANSACTION_VALUATION_CAP_BOOL] === "yes" &&
        values[claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_EPP] === "yes"
    },

    {
      title:
        "Shall there be a valuation floor defined in the convertible loan agreement for the purpose of the conversion in Sale Transaction?",
      details: `A “floor” is the minimum valuation of a company for the automatic
      conversion of the loan. In most cases, qualified equity financing
      will not trigger an automatic conversion of the loan to equity
      unless a minimum valuation is achieved, hence the “floor.” The floor
      is an investor-friendly provision. As a founder, however, it is
      reasonable to agree to a floor and will enable you to attract
      angels.`,
      className: "_fullwidth",
      input: {
        name: "valuationFloorDefinedST",
        ...confirmationPrompt
      },
      display: !!saleTransaction
    },

    {
      title: "What is the valuation floor?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.VALUATION_FLOOR_ST,
        type: "number",
        ...inputNumberFormatter(currency)
      },
      display: !!saleTransaction && values.valuationFloorDefinedST === "yes"
    },

    // fully diluted - esop
    {
      title: "Is the floor valuation fully diluted?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST,
        ...confirmationPrompt
      },
      display: !!saleTransaction && values.valuationFloorDefinedST === "yes"
    },
    {
      title:
        "Are the effects of potentially outstanding convertible loans included?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ST,
        ...confirmationPrompt
      },
      display:
        !!saleTransaction &&
        values.valuationFloorDefinedST === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes"
    },
    {
      className: "_fullwidth",
      title:
        "Please select the convertibles you want to include in the fully diluted valuation.",
      input: {
        name:
          claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST,
        type: "table_list",
        cols: convertibleCols(
          values,
          handlers,
          claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST
        )
      },
      display:
        !!saleTransaction &&
        values.valuationFloorDefinedST === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes" &&
        values[
          claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ST
        ] === "yes"
    },
    {
      title: "Are the effects of a potentially outstanding EPP included? ",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST,
        ...confirmationPrompt
      },
      display:
        !!saleTransaction &&
        values.valuationFloorDefinedST === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes" &&
        (esopShares > 0 || psopShares > 0)
    },

    {
      title:
        "Select which option plan shall be included in the fully diluted valuation",
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "esopPsopEffectsIncludedFloorST",
        options: [
          basicOption(`esop`, `ESOP shares ${esopShares}`, {
            disabled: esopShares === 0
          }),
          basicOption(`psop`, `PSOP shares ${psopShares}`, {
            disabled: psopShares === 0
          })
        ]
      },
      display:
        !!saleTransaction &&
        values.valuationFloorDefinedST === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST] ===
          "yes"
    },

    //

    {
      title: (
        <span>
          The sale transaction triggers the conversion when the shareholders end
          up owning less than{" "}
          <b>
            {" "}
            <i>
              {values[claTsKeys.SALE_TRANSACTION_SHAREHOLDERS_OWNING_LESS]
                ? `${
                    values[claTsKeys.SALE_TRANSACTION_SHAREHOLDERS_OWNING_LESS]
                  }%`
                : "___%"}
            </i>
          </b>{" "}
          of the voting power due to an exit, merger or sale event. Define the
          percentage:
        </span>
      ),
      input: {
        type: "number",
        name: claTsKeys.SALE_TRANSACTION_SHAREHOLDERS_OWNING_LESS,
        formatter: value => (value && value <= 100 ? `${value}%` : ""),
        parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), "")
      },
      display: !!saleTransaction
    },
    {
      title:
        "Into which share class will the convertible loan be converted upon Conversion?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: claTsKeys.SHARE_TYPE_CONVERSION_ST,
        options: [
          basicOption("common", "Common Shares"),
          basicOption(
            "most_senior_preferential_common",
            "Most senior Preferential Common Shares"
          ),
          basicOption("most_senior_preferred", "Most senior Preferred Shares")
        ]
      },
      display: !!saleTransaction
    },
    {
      className: "_fullwidth _border-bottom _hidden",
      input: {},
      display: !!saleTransaction
    },
    {
      showId: false,
      title: "Maturity date",
      className: "_fullwidth _bold-15 _hidden",
      input: {},
      display: maturityDate
    },
    {
      title: `You selected a ${values.mandatoryConversion ||
        ""} conversion as general conversion rule for your convertible loan agreement. Should the conversion on Maturity Date follow the same conversion rule?`,
      className: "_fullwidth",
      input: {
        name: claTsKeys.FOLLOW_CONVERSION_RULE_MD,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        triggeringEvent.includes("maturity_date") &&
        (triggeringEvent.includes("sale_transaction") ||
          triggeringEvent.includes("next_financing_round"))
    },

    {
      title: `Upon the Maturity Date the loan shall be converted ${
        values.mandatoryConversion === "voluntary" &&
        values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "yes"
          ? "voluntary"
          : values.mandatoryConversion === "voluntary" &&
            values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "no"
          ? "mandatory"
          : values.mandatoryConversion === "mandatory" &&
            values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "yes"
          ? "mandatory"
          : values.mandatoryConversion === "mandatory" &&
            values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "no"
          ? "voluntary"
          : ""
      }`,
      className: "_fullwidth _hidden",
      showId: false,
      input: {},
      display: !!maturityDate && values[claTsKeys.FOLLOW_CONVERSION_RULE_MD]
    },
    {
      title:
        "What shall be the basis for the conversion price calculation on Maturity Date?",
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "conversionPriceBasisMD",
        options: [
          basicOption("fix_valuation", "Fix pre-money valuation", {
            disabled: basicConversionPriceCalcDisabled("fix_valuation")
          }),
          basicOption("fair_market_value", "Fair Market Value", {
            disabled: basicConversionPriceCalcDisabled("fair_market_value")
          }),
          basicOption(
            "post_money_valuation",
            "Post-money valuation of the last financing round",
            {
              disabled: basicConversionPriceCalcDisabled("post_money_valuation")
            }
          )
        ]
      },
      display: !!maturityDate
    },

    {
      showId: false,
      title: "Fix pre-money valuation:",
      className: "_fullwidth _hidden _bold-15",
      input: {},
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation")
    },

    {
      title:
        "What is the pre-money valuation cap for a conversion at the maturity date?",
      className: "_fullwidth",
      input: {
        type: "number",
        name: claTsKeys.MATURITY_DATE_PRE_MONEY_VAL,
        formatter: value =>
          value
            ? `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            : "",
        parser: value =>
          value.replace(new RegExp(`${currency}\\s?|(\\'*)`, "g"), "")
      },
      display:
        !!maturityDate &&
        values.valuationCapMDPrompt === "yes" &&
        conversionPriceBasisMD.includes("fix_valuation")
    },

    {
      title: "Is the pre money valuation fully diluted?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.MATURITY_DATE_FULLY_DILUTED,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        values.valuationCapMDPrompt === "yes" &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation")
    },
    {
      title:
        "Are the effects of potentially outstanding convertible loans included?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_CONVERTIBLE,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes" &&
        values.valuationCapMDPrompt === "yes" &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation")
    },
    {
      className: "_fullwidth",
      title:
        "Please select the convertibles you want to include in the fully diluted valuation.",
      input: {
        name: claTsKeys.CL_EFFECTS_MD_ARRAY,
        type: "table_list",
        cols: convertibleCols(values, handlers, claTsKeys.CL_EFFECTS_MD_ARRAY)
      },
      display:
        !!maturityDate &&
        values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes" &&
        values.valuationCapMDPrompt === "yes" &&
        values[claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_CONVERTIBLE] ===
          "yes" &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation")
    },
    {
      title: "Are the effects of a potentially outstanding EPP included? ",
      className: "_fullwidth",
      input: {
        name: claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_EPP,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes" &&
        values.valuationCapMDPrompt === "yes" &&
        (esopShares > 0 || psopShares > 0) &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation")
    },

    {
      title:
        "Select which option plan shall be included in the fully diluted valuation",
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "esopPsopEffectsIncludedMD",
        options: [
          basicOption(`esop`, `ESOP shares ${esopShares}`, {
            disabled: esopShares === 0
          }),
          basicOption(`psop`, `PSOP shares ${psopShares}`, {
            disabled: psopShares === 0
          })
        ]
      },
      display:
        !!maturityDate &&
        values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes" &&
        values.valuationCapMDPrompt === "yes" &&
        values[claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_EPP] === "yes" &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation")
    },
    {
      className: "_fullwidth _border-bottom _hidden",
      input: {},
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation")
    },
    //includes "fair_market_value"
    {
      showId: false,
      title: "Fair Market Value:",
      className: "_fullwidth _hidden _bold-15",
      input: {},
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value")
    },
    {
      title:
        "You selected a fair market value for the basis of the conversion price calculation. Sometimes a fair market value of the conversion price is hard to determine. Therefore, an independent expert or if such is missing / or not able to act, or the parties cannot agree on the fair market value, an experiences accounting firm is being appointed by the president of a cantonal chamber of commerce. Please define which chamber of commerce shall be referred to.",
      className: "_fullwidth",
      input: {
        name: claTsKeys.CHAMBER_OF_COMMERCE_MD,
        type: "select_btn",
        options: [
          basicOption(registeredSeat, "Registered seat"),
          basicOption("Other")
        ]
      },

      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value")
    },
    {
      className: "_fullwidth",
      input: {
        type: "autocomplete",
        name: claTsKeys.CHAMBER_OF_COMMERCE_MD_OTHER,
        dataSource: cantonsSortedByName,
        filterOption: true
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.CHAMBER_OF_COMMERCE_MD] === "Other"
    },

    // fully diluted - esop

    // {
    //   title: "By what valuation shall the Fair Market Value be divided?",
    //   className: "_fullwidth",
    //   input: {
    //     type: "number",
    //     name: claTsKeys.DEVIDE_VALUATION,
    //     ...inputNumberFormatter(currency)
    //   },
    //   display:
    //     !!maturityDate &&
    //     Array.isArray(conversionPriceBasisMD) &&
    //     conversionPriceBasisMD.includes("fair_market_value")
    // },

    {
      title: "Shall the Fair Market Value be fully diluted?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value")
    },
    {
      title:
        "Shall the effects of potentially outstanding convertible loans be included?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes"
    },
    {
      className: "_fullwidth",
      title:
        "Please select the convertibles you want to include in the fully diluted valuation.",
      input: {
        name:
          claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY,
        type: "table_list",
        cols: convertibleCols(
          values,
          handlers,
          claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
        )
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes" &&
        values[
          claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE
        ] === "yes"
    },
    {
      title: "Shall the effects of a potentially outstanding EPP be included? ",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes" &&
        (esopShares > 0 || psopShares > 0)
    },

    {
      title:
        "Select which option plan shall be included in the fully diluted valuation",
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "esopPsopFairMarketValue",
        options: [
          basicOption(`esop`, `ESOP shares ${esopShares}`, {
            disabled: esopShares === 0
          }),
          basicOption(`psop`, `PSOP shares ${psopShares}`, {
            disabled: psopShares === 0
          })
        ]
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP] ===
          "yes"
    },
    {
      className: "_fullwidth _border-bottom _hidden",
      input: {},
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value")
    },
    //

    //includes "post_money_valuation"
    {
      showId: false,
      title: "Post-money valuation of the last financing round:",
      className: "_fullwidth _hidden _bold-15",
      input: {},
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation")
    },
    {
      title: "What was the post-money valuation of the last financing round?",
      className: "_fullwidth",
      input: {
        type: "number",
        name: claTsKeys.POST_MONEY_VALUATION_LAST_ROUND,
        ...inputNumberFormatter(currency)
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation")
    },
    {
      title: "Was the post money valuation fully diluted?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.FULLY_DILUTED_POST_MONEY_MD,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation")
    },
    {
      title:
        "Are the effects of potentially outstanding convertible loans included?",
      className: "_fullwidth",
      input: {
        name: claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") &&
        values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes"
    },

    {
      className: "_fullwidth",
      title:
        "Please select the convertibles you want to include in the fully diluted valuation.",
      input: {
        name: claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY,
        type: "table_list",
        cols: convertibleCols(
          values,
          handlers,
          claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY
        )
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") &&
        values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes" &&
        values[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD] === "yes"
    },

    {
      title: "Are the effects of a potentially outstanding EPP included? ",
      className: "_fullwidth",
      input: {
        name: claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD,
        ...confirmationPrompt
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") &&
        values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes" &&
        (esopShares > 0 || psopShares > 0)
    },

    {
      title:
        "Select which option plan shall be included in the fully diluted valuation",
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "esopPsopEffectsIncludedPostValuationMD",
        options: [
          basicOption(`esop`, `ESOP shares ${esopShares}`, {
            disabled: esopShares === 0
          }),
          basicOption(`psop`, `PSOP shares ${psopShares}`, {
            disabled: psopShares === 0
          })
        ]
      },
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") &&
        values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes" &&
        values[claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD] === "yes"
    },
    {
      className: "_fullwidth _border-bottom _hidden",
      input: {},
      display:
        !!maturityDate &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation")
    },
    //
    {
      title:
        "Into which share class will the convertible loan be converted upon conversion",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: claTsKeys.SHARE_TYPE_MD,
        options: [
          basicOption("common", "Common Shares", {
            tooltip:
              "If investors will receive common shares with preferential rights or preferred shares in the next financing round, the investors allocated to the convertible loans will receive the same category."
          }),
          basicOption(
            "most_senior_preferential_common",
            "Most senior Preferential Common Shares",
            {
              tooltip:
                "Contractual preferential rights are a frequent solution used in early financing rounds"
            }
          ),
          basicOption("most_senior_preferred", "Most senior Preferred Shares")
          // basicOption(
          //   'next_financing_round',
          //   'According to next financing round'
          // ),
        ]
      },
      display: !!maturityDate
    },
    {
      className: "_fullwidth _border-bottom _hidden",
      input: {},
      display: !!maturityDate
    }
  ];

  return fields;
};

const information = (
  values,
  currency,
  handlers,
  esopShares,
  psopShares,
  registeredSeat
) => [
  {
    title:
      values[claTsKeys.CONVERSION_PERFORM] === "voluntary"
        ? "Please select which event(s) may trigger(s) the voluntary conversion according to the convertible loan agreement?"
        : "Please select which event(s) (automatically) trigger(s) the conversion according to the convertible loan agreement?",
    className: "_fullwidth",
    input: {
      type: "checkbox",
      name: claTsKeys.TRIGGER_EVENT,
      options: [
        basicOption(
          claTriggerEvents.NEXT_FINANCING_ROUND,
          "Next financing round"
        ),
        basicOption(claTriggerEvents.SALE_TRANSACTION, "Sale transaction"),
        basicOption(claTriggerEvents.MATURITY_DATE, "Maturity date", {
          changeDisabled: true
        })
      ]
    }
  },
  ...optionalFields(
    values,
    currency,
    handlers,
    esopShares,
    psopShares,
    registeredSeat
  ).filter(({ display }) => !!display)
];

export default [information];
