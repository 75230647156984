import { useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../Shared/Button";
import Modal from "../Shared/Modal";
import Alert from "../Shared/Alert";
import SessionWrapper from "./Session/Presentational";
import { PdfViewer } from "../PDFViewer/Presentational";

const KycWrapper = props => {
  const [status, setStatus] = useState("");
  const history = useHistory();

  const handleStatusUpdate = status => () => setStatus(status);

  const handleClose = () => {
    history.goBack();
  };

  return (
    <div className="kyc-wrapper">
      <Modal
        visible={!status}
        title="Choose your status:"
        closable
        footer={null}
        width={600}
        wrapClassName="kyc-status-modal"
        onCancel={handleClose}
      >
        <div className="kyc-status-wrapper">
          <div className="btn-container">
            <Button
              label="Natural person"
              onClick={handleStatusUpdate("natural")}
            />
            <Button
              label="Legal entity"
              onClick={handleStatusUpdate("legal")}
            />
          </div>
          <PdfViewer
            withNavigation={false}
            location={{
              search: "doc=/docs/Leva_Pooling_OnePager_INVESTOR_vENGLISH.pdf"
            }}
          />
        </div>
      </Modal>
      {status === "natural" && <SessionWrapper status={status} {...props} />}
      {status === "legal" && (
        <Alert
          message={
            <span>
              To initiate the onboarding process for legal entities please reach
              out to our compliance office via email{" "}
              <a href="mailto:compliance@levamail.com">compliance@levamail.com </a>
              and provide the following information:
              <ul className="information-list">
                <li>Name of the legal entity</li>
                <li>Country of domicile of the legal entity</li>
                <li>Number of beneficial owners</li>
              </ul>{" "}
              Thank you!
            </span>
          }
          visible
          type="info"
        />
      )}
    </div>
  );
};

export default KycWrapper;
