import { connect } from "react-redux";
import { clearState, clearStateDataByKey } from "#core/utils/helper_actions";
import { getUserVoting, getVotingDoc } from "#core/modules/voting/actions";
import { roles } from "#constants/roles";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { cuid: userId } = state.users._data || {};
  const {
    _data: { list, doc = {} } = {},
    isFetching,
    didFetch,
    didCreate
  } = state.voting;
  const { entity: { cuid: entityId, userRole } = {} } =
    state.entities._data || {};
  const isAm = userRole === roles.ASSET_MANAGER;
  const isInvestor =
    userRole === roles.INVESTOR || userRole === roles.AM_INVESTOR;

  return {
    loading: false,
    isAm,
    userId,
    archiveColl: {
      data: list ?? [],
      loading: isFetching,
      success: didFetch,
      docGenerated: didCreate
    },
    doc,
    entityId,
    isInvestor
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  getVotings: params => dispatch(getUserVoting(params)),
  getVotingDoc: params => dispatch(getVotingDoc(params)),
  clearStateDataByKey: (state, ...keys) =>
    clearStateDataByKey(dispatch, state, ...keys)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
