import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};
const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.FETCH_VOTING:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            list: action.payload.data
          };
        }
      });

    case types.FETCH_VOTING_DOC:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data,
            doc: action.payload?.data
          };
        }
      });

    case types.FETCH_SINGLE_VOTING:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            assembly: action.payload.data
          };
        }
      });

    case types.POST_VOTING_ASSEMBLY:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data,
            assembly: action.payload.data
          };
        }
      });

    case types.POST_PUBLIC_VOTING:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data,
            didVerify: action.payload.data?.status === 1
          };
        }
      });

    case types.POST_VOTING_RESULT:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });

    case types.PATCH_VOTING:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });

    case types.DELETE_VOTING:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });
    default:
      return state;
  }
};
export default reducer;
