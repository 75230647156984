import { useEffect } from "react";
import { FormStepper, BasicFormWrapper } from "../../../Shared/Form";
import fieldsList from "./list";
import sections from "./sections";
import CustomInputRenderer from "../../common/Inputs/CustomInputRenderer";
import {
  handleCheckboxInArray,
  handleInputInArray
} from "../../common/formHandler";
import { SubscriptionCreationSuccess } from "../../common/successViews";

const SubscriptionForm = ({
  setFieldValue,
  values,
  shareholders,
  sharesFetched,
  buttonStates,
  handleSubmit,
  ioMemberships
}) => {
  useEffect(() => {
    if (sharesFetched) {
      const readableInvestors = shareholders.map(({ user, ...item }) => {
        const existingInvestor = ioMemberships.find(
          ({ investor: { cuid = "" } = {} }) => cuid === user.cuid
        );
        if (existingInvestor) {
          return {
            ...user,
            ...item,
            newShares: 0,
            investedAmount: 0,
            fullyPaidUp: true,
            selected: true
          };
        }
        return {
          ...user,
          ...item,
          newShares: 0,
          investedAmount: 0,
          fullyPaidUp: true
        };
      });

      setFieldValue("investorOverview", readableInvestors);
    }
  }, [sharesFetched]);

  const composedInputHandlers = {
    checkboxHandler: handleCheckboxInArray(setFieldValue, values),
    inputHandler: handleInputInArray(setFieldValue, values)
  };

  const formArgs = {
    list: fieldsList({ composedInputHandlers }, values),
    sections,
    values,
    inputEvents: {
      onChange: () => {}
    },
    CustomInputRenderer,
    buttonStates,
    handleSubmit,
    success: buttonStates.success,
    CustomSuccess: SubscriptionCreationSuccess
  };

  return <FormStepper {...formArgs} />;
};

export default BasicFormWrapper(SubscriptionForm);
