import { equityTs } from "#constants/io/equityPool";
import { basicOption } from "#helpers/general";
import { confirmationPrompt, renderOtherInput } from "../../../common/prompts";
import { NavLink } from "react-router-dom";

const vestingFields = (values, reverseFounderVesting, equityCuid) => {
  const localReverseFounderVesting =
    values.reverseFounderVesting && values.reverseFounderVesting === "yes";
  return [
    {
      title:
        "Shall the Founder’s shares be subject to a reverse-vesting (Leaver Shares)?",
      details: (
        <span>
          {localReverseFounderVesting !== undefined &&
            reverseFounderVesting !== undefined &&
            localReverseFounderVesting !== reverseFounderVesting && (
              <span>
                You changed Leaver Shares here so you must change the questions
                on{" "}
                <NavLink to={`/create/sha/${equityCuid}`}>
                  Shareholder Agreement
                </NavLink>
              </span>
            )}
        </span>
      ),
      input: {
        name: equityTs.LEAVER_SHARES,
        ...confirmationPrompt
      }
    },
    ...(values[equityTs.LEAVER_SHARES] === "yes"
      ? [
          {
            title:
              "How many Leaver Shares shall be subject to a reverse-vesting?",
            input: {
              name: equityTs.LEAVER_SHARES_VAL,
              type: "select_btn",
              options: [
                basicOption("50%"),
                basicOption("75%"),
                basicOption("100%"),
                basicOption("Other")
              ]
            }
          },
          values[equityTs.LEAVER_SHARES_VAL] === "Other"
            ? renderOtherInput(12, equityTs.LEAVER_SHARES_VAL_OTHER, "text")
            : null,
          {
            title:
              "For how many months shall the Leaver Shares be subject to a reverse-vesting?",
            input: {
              name: equityTs.LEAVER_SHARES_DUR,
              type: "select_btn",
              options: [
                basicOption("36", "36 months"),
                basicOption("48", "48 months"),
                basicOption("Other")
              ]
            }
          },
          values[equityTs.LEAVER_SHARES_DUR] === "Other"
            ? renderOtherInput(14, equityTs.LEAVER_SHARES_DUR_OTHER, "text", {
                addonAfter: "months"
              })
            : null,
          {
            title: "Define the Cliff Period for the Leaver Shares:",
            input: {
              name: equityTs.LEAVER_SHARES_CLIFF_PERIOD,
              type: "select_btn",
              options: [
                basicOption("6", "6 months"),
                basicOption("12", "12 months"),
                basicOption("Other")
              ]
            }
          },
          values[equityTs.LEAVER_SHARES_CLIFF_PERIOD] === "Other"
            ? renderOtherInput(
                13,
                equityTs.LEAVER_SHARES_CLIFF_PERIOD_OTHER,
                "number",
                {
                  addonAfter: "months"
                }
              )
            : null,
          {
            title:
              "How many Leaver Shares shall be reduced after the Cliff Period?",
            input: {
              name: equityTs.LEAVER_SHARES_REDUCTION,
              type: "select_btn",
              options: [
                basicOption("25.00%"),
                basicOption("33.33%"),
                basicOption("50.00%"),
                basicOption("Other")
              ]
            }
          },
          values[equityTs.LEAVER_SHARES_REDUCTION] === "Other"
            ? renderOtherInput(
                15,
                equityTs.LEAVER_SHARES_REDUCTION_OTHER,
                "text"
              )
            : null
        ]
      : [])
  ];
};

export default vestingFields;
