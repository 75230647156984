import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { closedValues } from "#constants/io/closed";
import { roles } from "#constants/roles";
import Form from "./form/Presentational";

const SubscriptionWrapper = ({
  clearState,
  getEntityShareholders,
  entityId,
  putIoInfo,
  bulkCreateIoMemberships,
  ioClosed,
  ioMemberships,
  createDealAndBulkMembers,
  getIoMemberships,
  updateDealAndBulkMembers,
  ...props
}) => {
  const { id } = useParams();

  useEffect(() => {
    clearState("shares");
    clearState("io");
    getEntityShareholders({ entity_id: entityId, role: "investor" });
    if (id) {
      getIoMemberships({ io_id: id });
    }
  }, []);

  const handleSubmit = values => {
    const { investorOverview } = values;
    const finalClosed =
      ioClosed < closedValues.SUBSCRIPTION_DONE
        ? closedValues.SUBSCRIPTION_DONE
        : ioClosed;

    const selectedInvestors = investorOverview
      .filter(({ selected }) => selected)
      .map(
        ({
          selected,
          cuid,
          newShares,
          firstName,
          lastName,
          email,
          investedAmount
        }) => ({
          selected,
          cuid,
          firstName,
          lastName,
          email,
          shares: newShares,
          amount: investedAmount
        })
      );

    if (id) {
      updateDealAndBulkMembers({
        id,
        dealData: {
          subscriptionFinished: true
        },
        memberships: selectedInvestors,
        ioMemberships,
        closed: finalClosed
      });
    } else {
      createDealAndBulkMembers({
        dealData: {
          closed: finalClosed,
          subscriptionFinished: true
        },
        memberships: selectedInvestors
      });
    }
    // bulkCreateIoMemberships(selectedInvestors);

    // putIoInfo({
    //   ioId: id,
    //   closed: finalClosed,
    //   subscriptionFinished: true
    // });
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      {...props}
      ioMemberships={ioMemberships}
    />
  );
};

export default SubscriptionWrapper;
