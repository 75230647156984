import { useEffect } from "react";
import PropTypes from "prop-types";
import { formatNumber } from "#helpers/number_format";
import { useHistory } from "react-router-dom";
import Title from "../../../../../Shared/Title";
import SharedTable from "../../../../../Shared/SharedTable/Presentational";
import { mgmtCols } from "./cols";
import { Button } from "../../../../../Shared/Button";
import Tooltip from "../../../../../Shared/Tooltip";
import Tick from "../../../../../../assets/illustrations/tick.svg";

const ClaMgmtTable = ({
  deleteCLA,
  convertibles,
  getConvertibleTypes,
  entityId,
  clearState
}) => {
  useEffect(() => {
    clearState("convertible");
    getConvertibleTypes({ entity_id: entityId });
  }, []);

  const manageClaAction = (
    <Button
      className="plus-btn"
      icon="plus"
      href="/cla/management"
      type="circle"
    />
  );
  const history = useHistory();

  const handleEdit = data => () => {
    history.push("/cla/management", { data });
  };

  const handleDelete = id => () => {
    deleteCLA(id);
  };

  const [totalAmount = 0, totalRaised = 0] = convertibles.reduce(
    (acc, convertible) => {
      let [accTotalAmount = 0, accTotalRaised = 0] = acc;
      if (convertible.currentRaisedTotal) {
        accTotalAmount += convertible.currentRaisedTotal;
      }

      if (convertible.raisedAmount) {
        accTotalRaised += convertible.raisedAmount;
      }
      return [accTotalAmount, accTotalRaised];
    },
    []
  );
  return (
    <div id="cla-mgmt" className="cla-mgmt">
      <Title title="Convertible loan management" action={manageClaAction} />
      <SharedTable
        columns={mgmtCols(handleDelete, handleEdit)}
        dataSource={convertibles}
        footer={() => (
          <span>
            Total amount: {`${formatNumber(totalAmount)}`}
            {totalAmount === totalRaised && totalAmount !== 0 && (
              <Tooltip title="The total convertible amount equals your outstanding convertible amount">
                <img
                  style={{ margin: "0 0 6px 1%" }}
                  src={Tick}
                  alt="tick"
                  height="20px"
                />
              </Tooltip>
            )}
          </span>
        )}
      />
    </div>
  );
};

ClaMgmtTable.propTypes = {
  deleteCLA: PropTypes.func,
  convertibles: PropTypes.array,
  getConvertibleTypes: PropTypes.func,
  entityId: PropTypes.string,
  clearState: PropTypes.func
};

export default ClaMgmtTable;
