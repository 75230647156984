export default {
  ADD_IO: "ADD_IO",
  FETCH_ALL_IOS: "FETCH_ALL_IOS",
  FETCH_SINGLE_IO: "FETCH_SINGLE_IO",
  FETCH_PORTFOLIO: "FETCH_PORTFOLIO",
  FETCH_CAPTABLE: "FETCH_CAPTABLE",
  FETCH_ROUND_TYPE: "FETCH_ROUND_TYPE",
  POST_IO_DOCS: "POST_IO_DOCS",
  UPDATE_IO: "UPDATE_IO",
  POST_DOC_VISIT: "POST_DOC_VISIT",
  DELETE_IO: "DELETE_IO",
  POST_ATTACHMENT: "POST_ATTACHMENT",
  CREATE_DEAL_BULK_MEMBERS: "CREATE_DEAL_BULK_MEMBERS",
  UPDATE_DEAL_BULK_MEMBERS: "UPDATE_DEAL_BULK_MEMBERS",
  DELETE_IO_ATTACHMENT: "DELETE_IO_ATTACHMENT",
  UPDATE_ATTACHMENT: "UPDATE_ATTACHMENT",
  BULK_POST_ATTACHMENTS: "BULK_POST_ATTACHMENTS",
  UPDATE_IO_CLOSING_STATUS: "UPDATE_IO_CLOSING_STATUS",
  UPDATE_AND_CLOSE_IO: "UPDATE_AND_CLOSE_IO",
  PATCH_ATTACHMENT: "PATCH_ATTACHMENT",
  RESET_IA_AND_SHA: "RESET_IA_AND_SHA",
  NOTIFY_DM_FOR_IO_FINALIZATION: "NOTIFY_DM_FOR_IO_FINALIZATION",
  CONFIRM_TRANSFER: "CONFIRM_TRANSFER"
};
