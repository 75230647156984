import { countriesSortedByName } from "../../Shared/Settings/countries";

export const profileFields = () => [
  {
    title: "First name",
    className: "_fullwidth",
    colon: false,
    input: {
      type: "text",
      name: "firstName"
    }
  },
  {
    title: "Last name",
    colon: false,
    className: "_fullwidth",
    input: {
      type: "text",
      name: "lastName"
    }
  },
  {
    title: "Gender",
    colon: false,
    className: "_fullwidth",
    input: {
      type: "select",
      name: "sex",
      options: [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" },
        { value: "not_disclosed", label: "Choose not to disclose" }
      ]
    }
  },
  {
    title: "E-mail address",
    className: "_fullwidth",
    colon: false,
    input: {
      type: "text",
      name: "email",
      disabled: true
    }
  },
  {
    title: "Street address",
    colon: false,
    className: "_fullwidth",
    input: {
      type: "text",
      name: "street"
    }
  },
  {
    title: "Postal Code",
    colon: false,
    className: "_fullwidth",
    input: {
      type: "text",
      name: "zipCode"
    }
  },
  {
    title: "City",
    colon: false,
    className: "_fullwidth",
    input: {
      type: "text",
      name: "city"
    }
  },
  {
    title: "Country",
    colon: false,
    className: "_fullwidth",
    input: {
      type: "autocomplete",
      name: "country",
      required: true,
      dataSource: countriesSortedByName,
      filterOption: true
    }
  }
];

export const settingsFields = profilePic => [
  {
    className: "_fullwidth",
    colon: false,
    input: {
      type: "avatar_uploader",
      name: "profilePicture",
      profilePic,
      accept: ".jpg,.png,.jpeg"
    }
  }
];
