import { Component } from "react";
import { mapRegistrationValues } from "#helpers/mappers/auth";
import { registrationSchema } from "#validators/registrationSchema";
import { BasicForm } from "../../Shared/Form";
import registrationFields from "./components/list";
import Layout from "../common/Layout";
import BtnContainer from "../common/btnContainer";

class Registration extends Component {
  componentDidMount() {
    this.props.clearState("auth");
  }

  handleSubmit = values => {
    const payload = mapRegistrationValues(values);

    this.props.register(payload);
  };

  render() {
    const { buttonStates } = this.props;
    const formArgs = {
      fieldList: registrationFields(false),
      CustomFooter: BtnContainer({ label: "Sign up" }),
      wrapperClassname: "registration-form",
      buttonStates,
      handleSubmit: this.handleSubmit,
      validationSchema: registrationSchema
    };

    return (
      <Layout
        prompt={{
          title: "Already a member?",
          linkLabel: "Log in",
          link: "/login"
        }}
      >
        <div className="registration-container">
          <h1>Register to Leva</h1>
          <BasicForm {...formArgs} />
        </div>
      </Layout>
    );
  }
}

export default Registration;
