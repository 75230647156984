import React from "react";
import { FormBtnContainer } from "../../Button";
import { withModalWrapper } from "../../hocs";

const KycStatusAlert = ({ onOk, onCancel, kycFinished }) => {
  const [title, subtitle, subtitle2, extra, cancelBtnLabel] = kycFinished
    ? [
        "KYC Pending",
        "Thank you for completing the onboarding process!",
        "Your Leva account is under verification at the moment. We’ll notify you via email as soon as your profile gets verified. The verification process usually takes less than 24 hours.",
        "",
        "Ok, got it!"
      ]
    : [
        "Attention: KYC needed!",
        "We noticed that you haven’t finalized the onboarding. You must complete the onboarding process to invest in the deal. Please do it as soon as possible to make sure not to miss this investment opportunity!",
        "You will need to go through this process only one.",
        "[Estimated time: 10 minutes]",
        "I'll do it later"
      ];

  return (
    <div className="kyc-status-alert-wrapper">
      <>
        <h1>{title}</h1>
        <h3>{subtitle}</h3>
        <h3>{subtitle2}</h3>
        <h3>{extra}</h3>
      </>

      <FormBtnContainer
        okButtonLabel="Let's do it now"
        onOk={onOk}
        cancelButtonLabel={cancelBtnLabel}
        onCancel={onCancel}
        showOk={!kycFinished}
      />
    </div>
  );
};
const KycStatusModal = withModalWrapper(KycStatusAlert);
export { KycStatusAlert as KycStatusAlertContent, KycStatusModal };
