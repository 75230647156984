import { roles } from "#constants/roles";
import { basicOption } from "#helpers/general";
import { formatNumber } from "#helpers/number_format";
import { sortArrayByName } from "#helpers/arrayFuncs";
import SpinContainer from "../../../Shared/SpinContainer";
import filters from "../../../Shared/FilterSidebar/filters";
import { countriesSortedByName } from "../../../Shared/Settings/countries";
import { cantonsSortedByName } from "../../../Shared/Settings/cantons";

const fieldList = ({
  detailsFetched,
  userRole,
  values,
  loading,
  handleBlur,
  localFileList,
  onFileRemove,
  ...props
}) => {
  const entityFilter = ({ roles: rolesAllowed }) =>
    !rolesAllowed || (rolesAllowed && rolesAllowed.includes(userRole));
  const entityMap = (item, index) => ({
    ...item,
    id: index + 1
  });
  const {
    industry: { array: industryFields }
  } = filters();

  const nameAndType = [
    {
      title: "What is the common name of the company",
      details:
        "This name will be used for all marketing messages and notifications on the Leva platform. Why is this name important? For example, some legal entities have completely different names than the company is know to the investors. Selecting a commonly known name will help the investor to assign information to the company.",
      className: "_width-70",
      input: {
        type: "text",
        name: "commonName",
        details: "Super Growth",
        required: true
      }
    },
    {
      title:
        "Are you investing directly into the company or via a structuring vehicle like an SPV?",
      className: "_fullwidth",
      optional: true,
      input: {
        type: "select_btn",
        name: "investmentType",
        required: true,
        options: [
          basicOption("direct_investment", "Direct investment"),
          basicOption("structuring_vehicle", "Structuring vehicle (SPV)")
        ]
      }
    },
    {
      title: "What is the legal name of the target company",
      details:
        "The full legal name of the target company the syndicate will invest in.",
      className: "_width-70",

      input: {
        type: "text",
        name: "name",
        details: "Super Growth AG",
        onBlur: handleBlur || null,
        suffix: loading ? <SpinContainer /> : null,
        required: true,
        autocomplete: "off"
      }
    },
    {
      title: `Target company: ${values.name}`,
      showId: false,
      className: "_fullwidth _hidden",
      input: {}
    },
    {
      title: "Street address and number",
      input: {
        type: "text",
        name: "address",
        suffix: loading ? <SpinContainer /> : null,
        required: true,
        autocomplete: "entity-street-number"
      }
    },
    {
      title: "Postal code",
      className: "_width-70",
      input: {
        type: "text",
        name: "postalCode",
        suffix: loading ? <SpinContainer /> : null,
        required: true,
        autocomplete: "entity-postal-code"
      }
    },
    {
      title: "City",
      input: {
        type: "text",
        name: "city",
        suffix: loading ? <SpinContainer /> : null,
        required: true,
        autocomplete: "entity-city"
      }
    },
    {
      title: "Country",
      input: {
        type: "autocomplete",
        name: "country",
        required: true,
        dataSource: countriesSortedByName,
        filterOption: true,
        suffix: loading ? <SpinContainer /> : null,
        autocomplete: "entity-country"
      }
    },
    {
      title: "Unique Identifier (UID)",
      optional: true,
      input: {
        type: "text",
        name: "idNumber"
      }
    },
    {
      title: "Current shares outstanding",
      details:
        "How many shares outstanding does the company have according the public register (excl. Options, Phantom shares etc.)",
      optional: true,
      roles: [],
      input: {
        type: "number",
        name: "entityShares",
        formatter: value => formatNumber(value),
        min: 0
      }
    },
    {
      title: "ESOP shares",
      className: "_fullwidth",
      optional: true,
      roles: [],
      input: {
        type: "number",
        name: "esopShares",
        formatter: value => formatNumber(value),
        min: 0
      }
    }
  ];

  if (values?.country === "Switzerland") {
    nameAndType.splice(8, 0, {
      title: "Canton",
      input: {
        placeholder: "Canton",
        type: "autocomplete",
        name: "canton",
        required: true,
        dataSource: cantonsSortedByName,
        filterOption: true
      }
    });
  }

  const industriesAndStage = [
    {
      id: 11,
      title: "Select the industrie(s) which describe the company best",
      className: "_fullwidth",
      optional: true,
      input: {
        type: "checkbox",
        name: "industries",
        options: industryFields.sort(sortArrayByName),
        required: true
      }
    },
    {
      id: 12,
      title: "Select the stage which describes the target company best",
      className: "_fullwidth",
      optional: true,
      input: {
        type: "select_btn",
        name: "companyStatus",
        options: [
          basicOption("pre-revenue", "Pre-revenue"),
          basicOption("early-stage", "Early stage"),
          basicOption("growth-stage", "Growth stage"),
          basicOption("Pre-ipo", "Pre-IPO"),
          basicOption("project-financing", "Project financing"),
          basicOption("buyout", "Buyout")
        ],
        required: true
      }
    }
  ];

  const logoAndDescription = [
    {
      id: 13,
      title: "Company logo",
      details:
        "For a better resolution, please upload a minimum 50px wide logo",
      input: {
        type: "upload",
        name: "previewImage",
        accept: ".jpg,.png,.jpeg",
        required: true,
        multiple: false,
        fileList: localFileList,
        onRemove: onFileRemove
      }
    },
    {
      id: 14,
      title: "Website",
      input: {
        type: "text",
        name: "websiteUrl",
        details: "www.supergrowth.com",
        required: true
      }
    },
    {
      id: 15,
      title: "Description",
      details: "Describe the target company's business for your co-investors",
      className: "_fullwidth",
      input: {
        type: "textarea",
        rows: 8,
        name: "description",
        details: "Write your test here",
        required: true
      }
    }
  ];

  const companyFields = nameAndType.filter(entityFilter).map(entityMap);

  const slicingPoints = [0, 3, companyFields.length === 9 ? 9 : 10]; // slice the form items into steps

  const companyInformationFields = slicingPoints
    .map((point, index, array) => {
      if (array[index + 1]) {
        return {
          id: index + 1,
          sectionId: 1,
          items: companyFields.slice(point, array[index + 1])
        };
      }

      return null;
    })
    .filter(Boolean);

  const fields = [
    ...companyInformationFields,
    {
      sectionId: 2,
      items: industriesAndStage
    },
    {
      sectionId: 3,
      items: logoAndDescription
    }
  ];

  return fields;
};

const formSections = [
  {
    id: 1,
    title: "Name and type of your company",
    stepLabel: "Please pick one of the following options",
    details: "Data to prepare your agreements"
  },
  {
    id: 2,
    title: "Company industries and stage",
    details: "To present it to your investors"
  },
  {
    id: 3,
    title: "Company Logo and description",
    details: "How your opportunity will showcase"
  }
];

export { fieldList, formSections };
