import * as Yup from "yup";
import { keys } from "#constants/io/equityPool";
import { legalDocsType } from "#constants/deal";
import { returnError } from "./common";

const dealSchema = Yup.object().shape({
  syndicateName: Yup.string(),
  syndicateCapped: Yup.number(),
  syndicatePublic: Yup.string(),
  deal_name: Yup.string().required(returnError("required")),
  previewImage: Yup.boolean(),
  qualifiedInvestorsOnly: Yup.string().required(returnError("required")),
  investmentLevel: Yup.string().required(returnError("required")),
  investmentLevel_desc: Yup.string().when("investmentLevel", {
    is: "other",
    then: Yup.string().required(returnError("required"))
  }),
  dealType: Yup.string().required(returnError("required")),
  currency: Yup.string().required(returnError("required")),
  companyValuation: Yup.string(),
  min_val: Yup.string().when("companyValuation", {
    is: "val_range",
    then: Yup.string()
  }),
  max_val: Yup.string().when("companyValuation", {
    is: "val_range",
    then: Yup.string()
  }),
  companyValuation_num: Yup.string().when("companyValuation", {
    is: "fixed_val",
    then: Yup.string()
  }),
  minimal: Yup.number(returnError("required"))
    .min(1, returnError("min_number", { number: 1 }))
    .required(returnError("required")),
  fundingGoal: Yup.number(returnError("required"))
    .min(1, returnError("min_number", { number: 1 }))
    .moreThan(
      Yup.ref("minimal"),
      "Funding goal must be higher than the minimal ticket amount."
    )
    .required(returnError("required")),
  sharesPrice: Yup.number().required(),
  [legalDocsType.SHAREHOLDER]: Yup.boolean(),
  [legalDocsType.LOAN]: Yup.boolean(),
  [legalDocsType.CLA]: Yup.boolean(),
  attachments: Yup.boolean(),
  tos_agreement: Yup.boolean()
    .oneOf([true], "Please accept the terms.")
    .required("Please accept the terms."),
  guidelines_agreement: Yup.boolean()
    .oneOf([true], "Please accept the terms.")
    .required("Please accept the terms."),
  provider_agreement: Yup.boolean()
    .oneOf([true], "Please accept the terms.")
    .required("Please accept the terms."),
  fees: Yup.object().shape({
    setup: Yup.number()
      .max(50, returnError("percentage_max", { number: 50 }))
      .min(0, returnError("percentage_min", { number: 0 })),
    administration: Yup.object().shape({
      value: Yup.number().when("administrationFeeBool", {
        is: true,
        then: Yup.number()
          .max(50, returnError("percentage_max", { number: 50 }))
          .min(0, returnError("percentage_min", { number: 0 }))
          .required(returnError("required"))
      }),
      year: Yup.number().when("administrationFeeBool", {
        is: true,
        then: Yup.number().required(returnError("required"))
      })
    }),
    performance: Yup.object().when("performanceFeeBool", {
      is: true,
      then: Yup.object().shape({
        simple: Yup.object().when("multiply", {
          is: false,
          then: Yup.object().shape({
            value: Yup.number()
              .max(50, returnError("percentage_max", { number: 50 }))
              .min(0, returnError("percentage_min", { number: 0 }))
              .required(returnError("required"))
          })
        }),
        multiple: Yup.object().when("multiply", {
          is: true,
          then: Yup.array()
            .of(
              Yup.object().shape({
                breakpoint: Yup.number(),
                value: Yup.number()
              })
            )
            .required()
        })
      })
    })
  })
});

const generalDetails = Yup.object().shape({
  name: Yup.string().required(returnError("required")),
  description: Yup.string().required(returnError("required")),
  previewImage: Yup.boolean().required(returnError("required")),
  shareholderAgreement: Yup.boolean().required(returnError("required")),
  termsheet: Yup.boolean().required(returnError("required")),
  partnershipAgreement: Yup.string().required(returnError("required")),
  partnershipAgreementFile: Yup.boolean(),
  fundingGoal: Yup.string().required(returnError("required")),
  minimal: Yup.string().required(returnError("required")),
  currency: Yup.string().required(returnError("required")),
  leadInvestor: Yup.string().required(returnError("required")),
  [keys.SERIES_ROUND]: Yup.string(),
  [keys.USE_PROCEEDS]: Yup.string(),
  [keys.PRE_MONEY_VAL]: Yup.string(),
  [keys.PRE_MONEY_VAL_DILUTED]: Yup.string(),
  [keys.NOMINAL_VAL]: Yup.string(),
  [keys.ISSUE_PRICE]: Yup.string(),
  [keys.VOTING_RIGHTS]: Yup.string(),
  [keys.AMOUNT_INTEREST]: Yup.string(),
  [keys.AGREED_AMOUNT_BOOL]: Yup.boolean(),
  [keys.AGREED_AMOUNT]: Yup.string(),
  [keys.EMPLOYEE_PART_PLAN_BOOL]: Yup.boolean(),
  [keys.EMPLOYEE_PART_PLAN]: Yup.string(),
  [keys.PREFERENCE_RIGHTS]: Yup.string(),
  [keys.DIVIDEND_PREFERENCE]: Yup.string(),
  [keys.LIQUDIATION_PREFERENCE]: Yup.string(),
  [keys.LIQUDIATION_PREFERENCE_AMOUNT_BOOL]: Yup.boolean(),
  [keys.LIQUDIATION_PREFERENCE_AMOUNT]: Yup.string(),
  [keys.ANTI_DILUTION_PROTECTION]: Yup.string(),
  [keys.ANTI_DILUTION_PROTECTION_FORMULA]: Yup.string(),
  [keys.ADJUST_IMPORTANT_SHAREHOLDERS]: Yup.string(),
  [keys.MAX_BOARD_DIRECTORS]: Yup.string(),
  [keys.DIRECTOR_APPOINT_RIGHTS]: Yup.string(),
  [keys.DIRECTOR_APPOINT_NUM]: Yup.string(),
  [keys.INDEPENDENT_DIRECTOR]: Yup.string(),
  [keys.MINIMUM_PERCENTAGE]: Yup.string(),

  /* STEP 9 */

  [keys.SHARE_TRANSFER_RESTRICTION]: Yup.string(),
  [keys.RIGHT_OF_REFUSAL]: Yup.string(),
  [keys.TAG_ALONG_RIGHT]: Yup.string(),
  [keys.DRAG_ALONG_RIGHT]: Yup.string(),
  [keys.DRAG_ALONG_PERCENTAGE]: Yup.string(),
  [keys.DRAG_ALONG_VALUATION]: Yup.string(),
  [keys.PURCHASING_OPTION_SHAREHOLDER]: Yup.string(),

  /* STEP 10 */

  [keys.TERM_SHEET_EXCLUSIVITY]: Yup.string(),
  [keys.TERM_SHEET_EXCLUSIVITY_TERM]: Yup.string(),
  [keys.PURSUANT_DOCUMENTS]: Yup.array(),
  [keys.EXPENSES_COVERAGE]: Yup.string(),
  [keys.EXPENSES_COVERAGE_FOUNDER]: Yup.string(),
  [keys.GOVERNING_LAW]: Yup.string(),
  [keys.APPENDICES]: Yup.string(),

  /* ENTITY */
  [keys.COMPANY_NAME]: Yup.string(),
  [keys.REGISTERED_SEAT]: Yup.string(),
  [keys.CURR_NUM_SHARES]: Yup.string(),
  [keys.SHAREHOLDER_STRUCTURE]: Yup.string()
});

const financialDetails = Yup.object().shape({
  currency: Yup.string().required(returnError("required")),
  administrationFeeBool: Yup.boolean(),
  closingFeeBool: Yup.boolean(),
  performanceFeeBool: Yup.boolean(),
  fees: Yup.object().shape({
    administration: Yup.number().when("administrationFeeBool", {
      is: true,
      then: Yup.number()
        .max(50, returnError("percentage_max", { number: 50 }))
        .min(0, returnError("percentage_min", { number: 0 }))
        .required(returnError("required"))
    }),
    management: Yup.object().shape({
      value: Yup.number().when("closingFeeBool", {
        is: true,
        then: Yup.number()
          .max(50, returnError("percentage_max", { number: 50 }))
          .min(0, returnError("percentage_min", { number: 0 }))
          .required(returnError("required"))
      }),
      year: Yup.number().when("closingFeeBool", {
        is: true,
        then: Yup.number().required(returnError("required"))
      })
    }),
    carry: Yup.object().when("performanceFeeBool", {
      is: true,
      then: Yup.object().shape({
        simple: Yup.object().when("multiply", {
          is: false,
          then: Yup.object().shape({
            value: Yup.number()
              .max(50, returnError("percentage_max", { number: 50 }))
              .min(0, returnError("percentage_min", { number: 0 }))
              .required(returnError("required"))
          })
        }),
        multiple: Yup.object().when("multiply", {
          is: true,
          then: Yup.array()
            .of(
              Yup.object().shape({
                breakpoint: Yup.number(),
                value: Yup.number()
              })
            )
            .required()
        })
      })
    })
  })
});

export { dealSchema, generalDetails, financialDetails };
