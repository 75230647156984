import { connect } from 'react-redux';
import { updateUserSettings } from '#core/modules/users/actions/update';
import { clearState } from '#core/utils/helper_actions';

import Presentational from './Presentational';

const mapStateToProps = ({ users }) => {
  return {
    buttonStates: { loading: users.isUpdating, success: users.didUpdate }
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearState: (key) => clearState(dispatch, key),
  updateUserSettings: (data) => dispatch(updateUserSettings(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
