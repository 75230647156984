import { Upload, Icon } from "antd";
import { Button } from "../../Button";

const SimpleUploader = ({
  name,
  onUpload,
  label = "Upload",
  accept,
  value,
  ...events
}) => {
  const dummyRequest = ({ file, onSuccess }) => {
    // WORKAROUND TO DISABLE UPLOADER'S DEFAULT ACTION ON FILE UPLOAD WHICH WILL FAIL SINCE WE HAVENT SET IT UP THE WAY IT EXPECTS
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name,
    ...events,
    onChange: info => onUpload(name, info),
    accept
  };

  return (
    <Upload {...props} customRequest={dummyRequest}>
      <Button className="ant-btn-upload" icon="upload" label={label} />
    </Upload>
  );
};

export default SimpleUploader;
