import * as commitService from "../../../../api/commitmentService";
import { getSingleIos } from "../../../../api/ioService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const postCommit = ({ commit, defaults = {} }) => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_COMMITMENT);
      const response = await commitService.postCommitment(commit);
      success(dispatch, types.POST_COMMITMENT, {
        data: { ...response.data, ...defaults }
      });

      const { data = {} } = (await getSingleIos(commit.ioId)) || {};

      success(dispatch, types.UPDATE_IO, {
        data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { code, message } = errorData;

      catchError(dispatch, message);
      fail(dispatch, types.POST_COMMITMENT, {
        error: errorData
      });
    }
  };
};
