import { Component } from "react";
import queryString from "query-string";
import { Typography } from "antd";
import { Redirect } from "react-router-dom";
import AuthLayout from "../../components/Authorization/common/Layout";

import i18n from "../../i18n";

import Input from "../Shared/Input";
import { LoadingButton } from "../Shared/Button/components/BtnWrapper/Presentational";
import Form from "./components/ResetPasswordForm";

const { Title } = Typography;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }

  componentDidMount() {
    this.props.clearState("auth");

    const queries = queryString.parse(this.props.location.search);
    if (queries.token) {
      this.setState({
        updatePswMode: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.didUpdate && prevProps.didUpdate !== this.props.didUpdate) {
      setTimeout(() => this.props.history.push("/login"), 3000);
    }
  }

  handleInputChange = ({ target: { value } }) => {
    this.setState({
      email: value
    });
  };

  handleClick = () => {
    this.props.getNewPassword({
      email: this.state.email,
      redirectUrl: `${process.env.HOST}/reset_password`
    });
  };

  handleSubmit = values => {
    const queries = queryString.parse(this.props.location.search);
    this.props.resetPassword({
      password: values.new_password,
      token: queries.token
    });
  };

  render() {
    const { updatePswMode } = this.state;
    const {
      isFetching,
      didFetch,
      isUpdating,
      didUpdate,
      isAuthCompleted
    } = this.props;

    return !isAuthCompleted ? (
      <AuthLayout customWrapperClassname="reset-password-wrapper" showLeftPane>
        {updatePswMode ? (
          <Form
            handleSubmit={this.handleSubmit}
            isUpdating={isUpdating}
            didUpdate={didUpdate}
          />
        ) : (
          <div className="reset-password-container">
            <div className="form-wrapper">
              <Title level={1}>Reset password</Title>
              <span>{i18n.t("reset_psw.forgot_placeholder")}</span>
              <Input
                className="reset-password-input"
                name="email"
                details={i18n.t("reset_psw.email")}
                value={this.state.email}
                onChange={this.handleInputChange}
              />
              <div className="btn-container reset-pasword-btn-container">
                <LoadingButton
                  label={i18n.t("reset_psw.reset")}
                  onClick={this.handleClick}
                  loading={isFetching}
                  success={didFetch}
                  disabled={didFetch || !this.state.email}
                />
              </div>
              {didFetch && <h4>{i18n.t("reset_psw.email_success")}</h4>}
            </div>
          </div>
        )}
      </AuthLayout>
    ) : (
      <Redirect to="/" />
    );
  }
}
export default ResetPassword;
