export default {
  LOGIN: "LOGIN",
  UPLOAD_PROFILE_PIC: "UPLOAD_PROFILE_PIC",
  UPDATE_USER: "UPDATE_USER",
  SYNC_USER_DETAILS: "SYNC_USER_DETAILS",
  FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
  POST_USER_APPLICATION: "POST_USER_APPLICATION",
  UPDATE_KYC_STATUS: "UPDATE_KYC_STATUS",
  GET_SMS_VERIFICATION: "GET_SMS_VERIFICATION",
  GET_SMS_VERIFICATION_NUMBER: "GET_SMS_VERIFICATION_NUMBER",
  ONBOARD_USER: "ONBOARD_USER",
};
