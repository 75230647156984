import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { handleUpload } from "#helpers/inputHandlers";
import { convertToBase64 } from "#helpers/convertBase64";
import { mapCLAQuestionnaireValues } from "#helpers/mappers/convertibles";
import { closedValues } from "#constants/io/closed";
import Form from "./form/Presentational";
import list from "./form/list";

const CLAQuestionnaire = ({
  getEntity,
  entityId,
  entityData,
  putIoInfo,
  createIo,
  clearState,
  getEntityMemberships,
  getEntityShareholders,
  fetchSingleIo,
  getFormData,
  location,
  ...props
}) => {
  const { id } = useParams();
  const { step } = queryString.parse(location.search);

  useEffect(() => {
    clearState("io");
    clearState("entities");
    clearState("shares");
    getEntityMemberships({ id: entityId });
    getEntityShareholders({ entity_id: entityId });
    getFormData();
    fetchSingleIo(id);
  }, []);
  const [state, setState] = useState({});

  const uploadHandler = handleUpload(setState, state);
  const fieldList = list({}).flatMap(section => section.items);

  const handleSubmit = async values => {
    const updating = true;
    let shaBase64 = "";

    if (Object.keys(state).length > 0) {
      shaBase64 = await convertToBase64(state.sha_upload);
    }

    const {
      ioColl: {
        data: {
          closed,
          cla: { claUpdateCounter = 0, tsUpdateCounter = 0, ...otherClaData }
        } = {}
      } = {}
    } = props;

    const { cla: claData = {} } = mapCLAQuestionnaireValues(values, updating);

    if (shaBase64.length > 0) {
      claData.shaBase64 = shaBase64;
    }

    const finalClosed =
      closed < closedValues.CLA_DONE ? closedValues.CLA_DONE : closed;
    putIoInfo({
      ioId: id,
      cla: {
        ...otherClaData,
        ...claData
      },
      closed: finalClosed
    });
  };

  const { pathname: currPath } = location;

  return (
    <Form
      handleSubmit={handleSubmit}
      fieldList={fieldList}
      values={entityData}
      handleUpload={uploadHandler}
      step={step}
      currPath={currPath}
      {...props}
    />
  );
};

export default CLAQuestionnaire;
