import { equityTs } from "#constants/io/equityPool";
import { basicOption } from "#helpers/general";
import { formatNumber } from "#helpers/number_format";
import { renderOtherInput, confirmationPrompt } from "../../../common/prompts";

const shareInformation = (values, shareNames = []) => [
  {
    title: "Is the company issuing common shares or preferred shares?",
    input: {
      type: "select_btn",
      name: equityTs.CONTRACTUAL_PREFERRED,
      options: [
        basicOption("common", "Common Shares"),
        basicOption(
          "common_with_preferential_rights",
          "Preferential Common Shares"
        ),
        basicOption("preferred", "Preferred Shares", { disabled: true })
      ]
    }
  },
  ...(values[equityTs.CONTRACTUAL_PREFERRED]
    ? [
        {
          title:
            "Choose your own name for this share category (e.g. Seed Preferred Shares)",
          details: `${
            shareNames.includes(values[equityTs.CUSTOM_SHARE_CATEGORY])
              ? `You already have a share named 
              ${values[equityTs.CUSTOM_SHARE_CATEGORY]}
               please change it here`
              : `The name should be different from those on the shares  management`
          }`,
          redDetails: shareNames.includes(
            values[equityTs.CUSTOM_SHARE_CATEGORY]
          ),
          input: {
            type: "text",
            name: equityTs.CUSTOM_SHARE_CATEGORY
          }
        },
        {
          title:
            "Do the articles of association restrict the transfer of shares?",
          input: {
            name: equityTs.AOA_RESTRICT,
            ...confirmationPrompt
          }
        },
        {
          title: "What is the nominal value of the shares to be issued?",
          input: {
            type: "select_btn",
            name: equityTs.COMMON_SHARE_NOMINAL,
            options: [basicOption("CHF 1"), basicOption("Other", "Other")]
          }
        },
        values[equityTs.COMMON_SHARE_NOMINAL] === "Other"
          ? renderOtherInput(
              23,
              equityTs.COMMON_SHARE_NOMINAL_OTHER,
              "number",
              {
                min: 2
              }
            )
          : null,
        {
          title: "How many votes shall the investors have?",
          input: {
            type: "select_btn",
            name: equityTs.VOTING_RIGHTS,
            options: [
              basicOption("1", "One vote per share"),
              basicOption("Other", "Other", {
                disabled: true,
                tooltip:
                  "We currently can’t offer this option. Please reach out to X, our partner law firm if you wish to have tailor made voting rights. \n\n For more information reach out to hi@leva.pe"
              })
            ]
          }
        },
        values[equityTs.CONTRACTUAL_PREFERRED] !== "common" && {
          title:
            "What contractual preference rights shall the holders of preferred shares receive?",
          details:
            "A liquidation preference is a clause that dictates the payout order in case of a company’s liquidation event",
          input: {
            type: "switch",
            label: "Liquidation preference",
            name: equityTs.LIQUIDATION_PREFERENTIAL
          }
        },
        values[equityTs.LIQUIDATION_PREFERENTIAL] &&
          values[equityTs.CONTRACTUAL_PREFERRED] !== "common" && {
            showId: false,
            title:
              "Do you want a participating or a non-participating liquidation preference?",
            input: {
              type: "select_btn",
              name: equityTs.LIQUDIATION_PREFERENCE_PARTICIPATING,
              options: [
                basicOption("participating", "Participating"),
                basicOption("non-participating", "Non-Participating")
              ]
            }
          },
        values[equityTs.LIQUIDATION_PREFERENTIAL] &&
          values[equityTs.CONTRACTUAL_PREFERRED] !== "common" &&
          values[equityTs.LIQUDIATION_PREFERENCE_PARTICIPATING] ===
            "participating" && {
            showId: false,
            title:
              "Do you want a common catch up clause or a liquidation preference cap?",
            input: {
              type: "select_btn",
              name: equityTs.LIQUDIATION_PREFERENCE_CLAUSE,
              options: [
                basicOption("catch_up", "Catch Up"),
                basicOption("cap", "Cap")
              ]
            }
          },

        values[equityTs.LIQUIDATION_PREFERENTIAL] &&
          values[equityTs.CONTRACTUAL_PREFERRED] !== "common" &&
          values[equityTs.LIQUDIATION_PREFERENCE_PARTICIPATING] ===
            "participating" &&
          values[equityTs.LIQUDIATION_PREFERENCE_CLAUSE] === "cap" && {
            showId: false,
            title: "How high should the liquidation cap be?",
            input: {
              type: "number",
              name: equityTs.LIQUIDATION_PREFERENTIAL_CAP,
              formatter: value =>
                isNaN(value)
                  ? formatNumber(parseInt(value))
                  : formatNumber(value)
            }
          },
        values[equityTs.CONTRACTUAL_PREFERRED] !== "common" && {
          details:
            "A safety feature of shares, whereby holders of such shares are entitled to receive dividends before normal shareholders",
          input: {
            type: "switch",
            label: "Dividend preference",
            name: equityTs.DIVIDEND_PREFERENTIAL
          }
        },
        values[equityTs.DIVIDEND_PREFERENTIAL] &&
          values[equityTs.CONTRACTUAL_PREFERRED] !== "common" && {
            showId: false,
            title:
              "Do you want a participating or a non-participating dividend preference?",
            input: {
              type: "select_btn",
              name: equityTs.DIVIDEND_PREFERENCE_PARTICIPATING,
              options: [
                basicOption("participating", "Participating", {
                  tooltip: values[equityTs.LIQUIDATION_PREFERENTIAL]
                    ? "In Pre-Seed and Seed financing rounds it is common that the dividend preference follows the liquidation preference. If you selected different dividend preference then liquidation preference please consult your lawyer."
                    : ""
                }),
                basicOption("non-participating", "Non-Participating", {
                  tooltip: values[equityTs.LIQUIDATION_PREFERENTIAL]
                    ? "In Pre-Seed and Seed financing rounds it is common that the dividend preference follows the liquidation preference. If you selected different dividend preference then liquidation preference please consult your lawyer."
                    : ""
                })
              ]
            }
          },
        values[equityTs.DIVIDEND_PREFERENTIAL] &&
          values[equityTs.CONTRACTUAL_PREFERRED] !== "common" &&
          values[equityTs.DIVIDEND_PREFERENCE_PARTICIPATING] ===
            "participating" && {
            showId: false,
            title:
              "Do you want a common catch up clause or a dividend preference cap?",
            input: {
              type: "select_btn",
              name: equityTs.DIVIDEND_PREFERENCE_CLAUSE,
              options: [
                basicOption("catch_up", "Catch Up", {
                  tooltip: values[equityTs.LIQUIDATION_PREFERENTIAL]
                    ? "In Pre-Seed and Seed financing rounds it is common that the dividend preference follows the liquidation preference. If you selected different dividend preference then liquidation preference please consult your lawyer."
                    : ""
                }),
                basicOption("cap", "Cap", {
                  tooltip: values[equityTs.LIQUIDATION_PREFERENTIAL]
                    ? "In Pre-Seed and Seed financing rounds it is common that the dividend preference follows the liquidation preference. If you selected different dividend preference then liquidation preference please consult your lawyer."
                    : ""
                })
              ]
            }
          },

        values[equityTs.DIVIDEND_PREFERENTIAL] &&
          values[equityTs.DIVIDEND_PREFERENCE_PARTICIPATING] ===
            "participating" &&
          values[equityTs.DIVIDEND_PREFERENCE_CLAUSE] === "cap" && {
            showId: false,
            title: "How high should the dividend cap be?",
            details: values[equityTs.LIQUIDATION_PREFERENTIAL]
              ? "In Pre-Seed and Seed financing rounds it is common that the dividend preference follows the liquidation preference. If you selected different dividend preference then liquidation preference please consult your lawyer."
              : "",

            input: {
              type: "number",
              name: equityTs.DIVIDEND_PREFERENTIAL_CAP,
              formatter: value =>
                isNaN(value)
                  ? formatNumber(parseInt(value))
                  : formatNumber(value)
            }
          },
        values[equityTs.CONTRACTUAL_PREFERRED] !== "common" && {
          details:
            "Anti-dilution provisions are clauses built into shares to help shield investors from their investment potentially losing value. ",
          input: {
            type: "switch",
            label: "Anti-dilution protection",
            name: equityTs.ANTI_DILUTION
          }
        },
        values[equityTs.ANTI_DILUTION] &&
        values[equityTs.CONTRACTUAL_PREFERRED] !== "common"
          ? {
              showId: false,
              title:
                "What type of anti-dilution protection are you granting the holders of preferred shares?",
              input: {
                type: "select_btn",
                name: equityTs.ANTI_DILUTION_PROTECTION_FORMULA,
                options: [
                  basicOption("Narrow based", "Narrow based", {
                    tooltip:
                      "This anti-dilution provision takes into account only the total number of outstanding preferred shares when calculating the new weighted average price for existing shares. "
                  }),
                  basicOption("Broad based", "Broad based", {
                    tooltip:
                      "This anti-dilution provision takes into account all equity previously issued and currently undergoing issuing, including convertible securities such as options and warrants. "
                  }),
                  basicOption("Full ratched", "Full ratched", {
                    tooltip:
                      "This anti-dilution provision takes into account any shares of common stock sold by a company after the issuing of an option (or convertible security), applies the lowest sale price as being the adjusted option price or conversion ratio for existing shareholders"
                  })
                ]
              }
            }
          : null,
        values[equityTs.VOTING_RIGHTS] === "Other"
          ? renderOtherInput(2, equityTs.VOTING_RIGHTS_OTHER, "number")
          : null
      ]
    : [])
];

export default [shareInformation];
