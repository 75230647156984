import * as Yup from "yup";
import { returnError } from "./common";

export const bankAccSchema = Yup.object().shape({
  beneficialOwner: Yup.string().required(returnError("required")),
  bank: Yup.string().required(returnError("required")),
  bankAddress: Yup.string().required(returnError("required")),
  bankZip: Yup.string().required(returnError("required")),
  bankCity: Yup.string().required(returnError("required")),
  bankCountry: Yup.string().required(returnError("required")),
  bankAccNo: Yup.string().required(returnError("required")),
  iban: Yup.string().required(returnError("required")),
  bankSwift: Yup.string().required(returnError("required")),
  bankAccCurrency: Yup.string().required(returnError("required"))
});
