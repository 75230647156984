const Investment = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9184 21.3992H12.618V6H11.0729V21.3991H6.77251C6.33472 21.3991 6 21.7339 6 22.1716C6 22.6094 6.33477 22.9442 6.77251 22.9442H16.9184C17.3562 22.9442 17.691 22.6094 17.691 22.1716C17.691 21.7339 17.3562 21.3992 16.9184 21.3992Z"
        fill="#597EF7"
      />
      <path
        d="M12.0558 8.82831H10.8455C7.62662 8.82831 5 6.20169 5 2.98281V1.77251C5 1.33477 5.33477 1 5.77256 1H6.98286C10.2017 1 12.8284 3.62662 12.8284 6.8455V8.0558C12.8284 8.49359 12.4936 8.82831 12.0558 8.82831ZM6.54507 2.54507V2.98286C6.54507 5.35194 8.47642 7.28329 10.8455 7.28329H11.2833V6.8455C11.2833 4.47642 9.35194 2.54507 6.98286 2.54507H6.54507Z"
        fill="#18DC9B"
      />
      <path
        d="M12.0558 21.2146H10.8455C7.62662 21.2146 5 18.588 5 15.3691V14.1588C5 13.721 5.33477 13.3863 5.77251 13.3863H6.98281C7.00853 13.3863 7.00853 13.3863 7.03431 13.3863C8.55365 13.3863 9.99571 13.9785 11.0773 15.0601C12.2103 16.1674 12.8284 17.6609 12.8284 19.2318V20.4421C12.8284 20.8799 12.4936 21.2146 12.0558 21.2146ZM6.54507 14.9314V15.3691C6.54507 17.7382 8.47642 19.6696 10.8455 19.6696H11.2833V19.2318C11.2833 18.073 10.8198 16.9915 9.99576 16.1674C9.17174 15.3691 8.11592 14.9056 6.98286 14.9314H6.54507Z"
        fill="#18DC9B"
      />
      <path
        d="M13.266 15.5236H12.0557C11.6179 15.5236 11.2832 15.1888 11.2832 14.7511V13.5408C11.2832 10.3219 13.9098 7.69525 17.1287 7.69525H18.339C18.7768 7.69525 19.1115 8.03002 19.1115 8.46776V9.67806C19.1115 12.8969 16.4849 15.5236 13.266 15.5236ZM12.8283 13.9785H13.2661C15.6351 13.9785 17.5665 12.0471 17.5665 9.67806V9.24027H17.1287C14.7596 9.24027 12.8283 11.1716 12.8283 13.5407V13.9785Z"
        fill="#18DC9B"
      />
    </svg>
  );
};

export default Investment;
