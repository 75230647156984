const MinorContract = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1865 0.881348H5.15253C4.69149 0.881348 4.33899 1.2339 4.33899 1.69488V5.76266H0.813536C0.352551 5.76266 0 6.11522 0 6.57625V20.1356C0 21.7898 1.32881 23.1186 2.98303 23.1186H20.4746C22.4271 23.1186 24 21.5457 24 19.5932V1.69488C24 1.23385 23.6475 0.881348 23.1865 0.881348ZM4.33899 20.1356C4.33899 20.8949 3.74238 21.4915 2.98308 21.4915C2.22379 21.4915 1.62717 20.8949 1.62717 20.1356V7.38979H4.33905V20.1356H4.33899ZM22.3729 19.5932C22.3729 20.6508 21.5322 21.4915 20.4746 21.4915H5.64066C5.85763 21.0847 5.96607 20.6237 5.96607 20.1356V2.50847H22.3729V19.5932Z"
        fill="#597EF7"
      />
      <path
        d="M19.7154 11.3763H8.59674C8.1357 11.3763 7.7832 11.0237 7.7832 10.5627V5.13897C7.7832 4.67794 8.13575 4.32544 8.59674 4.32544H19.7154C20.1764 4.32544 20.5289 4.67799 20.5289 5.13897V10.5627C20.529 11.0238 20.1764 11.3763 19.7154 11.3763ZM9.41033 9.74913H18.9019V5.95251H9.41033V9.74913Z"
        fill="#18DC9B"
      />
      <path
        d="M13.7492 15.7153H8.73224C8.2712 15.7153 7.9187 15.3627 7.9187 14.9017C7.9187 14.4407 8.27125 14.0882 8.73224 14.0882H13.7492C14.2102 14.0882 14.5627 14.4407 14.5627 14.9017C14.5627 15.3627 14.2102 15.7153 13.7492 15.7153Z"
        fill="#18DC9B"
      />
      <path
        d="M19.1729 19.2407H8.73224C8.2712 19.2407 7.9187 18.8881 7.9187 18.4271C7.9187 17.9661 8.27125 17.6136 8.73224 17.6136H19.1729C19.634 17.6136 19.9865 17.9661 19.9865 18.4271C19.9865 18.8881 19.6339 19.2407 19.1729 19.2407Z"
        fill="#18DC9B"
      />
    </svg>
  );
};

export default MinorContract;
