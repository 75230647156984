export const claTriggerEvents = {
  NEXT_FINANCING_ROUND: "next_financing_round",
  SALE_TRANSACTION: "sale_transaction",
  MATURITY_DATE: "maturity_date"
};

export const keys = {
  preMoneyValuation: "preMoneyValuation",
  fullyDelutedPreMoney: "fullyDelutedPreMoney",
  includedOutstandingLoans: "includedOutstandingLoans",
  includedOutstandingESOP: "includedOutstandingESOP",
  salePercentage: "salePercentage",
  investorLoanPayout: "investorLoanPayout",
  signingInvestor: "signingInvestor",
  fixedAnnualInterestRate: "fixedAnnualInterestRate",
  interestRate: "interestRate",
  annualInterestRateCalculated: "annualInterestRateCalculated",
  convertedInterest: "convertedInterest",
  fixedAnnualInterest: "fixedAnnualInterest",
  preExistingAgreement: "preExistingAgreement",
  preExistingDate: "preExistingDate",
  uploadAgreementConfirmation: "uploadAgreementConfirmation",
  claPurpose: "claPurpose",
  claPurposeArea: "claPurposeArea",
  maximumInterest: "maximumInterest",
  investorLoanPayoutOther: "investorLoanPayoutOther"
};

export const claTsKeys = {
  /* general information */

  SHARES_OUTSTANDING: "sharesNumber",
  SERIES_ROUND: "nextFinancingRound",
  NAME: "name",
  GOVERNING_LAW: "governingLaw",
  GOVERNING_LAW_OTHER: "governingLawOther",
  VALIDITY: "maturityMonths",
  TS_DIRECTORS: "tsDirectors",
  CLA_DIRECTORS: "claDirectors",
  TS_SIGNED_BY: "tsSignedBy",
  CLA_SIGNED_BY: "claSignedBy",
  CL_SUBORDINATED: "subordination",
  EQUALLY_MANAGED: "pariPassu",
  LENDER_AFFILIATE_WITHOUT_CONSENT: "affiliateAssign",
  BOARD_DISCLOSURE_THIRD_PARTIES: "boardConsentConfidentiality",
  /* financial information */
  CURRENCY: "currency",
  MONEY_RAISED: "moneyRaised",

  /* financial investor */

  INVESTOR_HAS_COMMITTED: "investorHasCommited",
  INVESTORS_TABLE: "investorsTable",
  REDUCE_LOAN_PRIOR_MATURITY_DATE: "reduceLoanWhenOversubscription",
  REPAY_LOAN_PRIOR_MATURITY_DATE: "entitledToRepayLoan",
  LENDER_REPAY_TERM: "lendersReplyToPrepaymentOfferInDays",
  LENDER_REPAY_TERM_OTHER: "lendersReplyToPrepaymentOfferInDaysOther",

  /* financial interest */
  ANNUAL_INTEREST: "interest",
  INTEREST_RATE: "interestRate",
  ANNUAL_INTEREST_CALCULATION: "annualInterestCalculation",
  YEARLY_INTEREST_RATE: "annualInterest",
  INTEREST_CONVERSION: "interestCompensationAtConversion",
  SAFE_HARBOR: "safeHarborRule",
  MAXIMUM_INTEREST: "maximumInterest",

  /* conversion information */
  TRIGGER_EVENT: "triggeringEvent",
  CONVERSION_PERFORM: "mandatoryConversion",
  CL_EFFECTS: "outstandingClaEffectsIncludedNFR",
  CL_EFFECTS_ARRAY: "outstandingClaEffectsIncludedNFRArray",
  CL_EFFECTS_MD: "outstandingClaEffectsIncludedPreMoneyMD",
  CL_EFFECTS_MD_ARRAY: "outstandingClaEffectsIncludedPreMoneyMDArray",
  ESOP_EFFECTS: "esopEffectsIncludedNFR",
  PRE_MONEY_VAL_DILUTED: "fullyDilutedNFR",
  MIN_AMOUNT_TRIGGER_CONVERSION: "minNextFinancingAmountNFR",
  VALUATION_CAP: "valuationCapBoolNFR",
  PRE_MONEY_VAL: "valuationCapNFR",
  DISCOUNT_RATE_BOOL: "discountBoolNFR",
  DISCOUNT_RATE: "discountNFR",
  SHARE_TYPE_CONVERSION: "shareTypeNFR",
  SHARE_TYPE_CONVERSION_ST: "claConversionShareClassST",
  SHARE_TYPE_MD: "shareTypeMD",
  AOA_RESTRICTION: "shareTransferRestrictionNFR",
  SALE_TRANSACTION_DISCOUNT_BOOL: "discountBoolST",
  MIN_VALUATION_NFR: "minValuationNFR",
  SALE_TRANSACTION_VALUATION_CAP: "valuationCapST",
  SALE_TRANSACTION_VALUATION_CAP_BOOL: "valuationCapBoolST",
  MATURITY_DATE_SHARE_TYPE_CONVERSION: "maturityDateShareTypeConversion",
  SALE_TRANSACTION_DISCOUNT_RATE: "discountST",
  SALE_TRANSACTION_PRE_MONEY_VAL: "valuationCapST",
  MATURITY_DATE_PRE_MONEY_VAL: "valuationCapMD",
  NEXT_FINANCING_FULLY_DILUTED: "fullyDilutedNFR",
  MIN_NEXT_FINANCING_FULLY_DILUTED: "minFullyDilutedNFR",
  SALE_TRANSACTION_SHAREHOLDERS_OWNING_LESS: "saleTransactionPercentage",
  EMPLOYE_PARTICIPATION_PLAN_MD: "employeeParticipationPlanPreMoneyMD",
  EMPLOYEE_PARTICIPATION_PLAN_NFR: "employeeParticipationPlanNFR",
  POTENTIALLY_OUTSTANDING_CONVERTIBLE: "outstandingClaEffectsIncludedNFR",
  MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE:
    "minOutstandingClaEffectsIncludedNFR",
  MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY:
    "minOutstandingClaEffectsIncludedNFRArray",
  MIN_POTENTIALLY_OUTSTANDING_ESOP: "minEsopEffectsIncludedNFR",
  MIN_POTENTIALLY_OUTSTANDING_EPP: "minEppEffectsIncludedNFR",
  MIN_POTENTIALLY_OUTSTANDING_PSOP: "minPsopEffectsIncludedNFR",
  EMPLOYEE_PARTICITION_PLAN_MIN_NFR: "employeeParticipationPlanMinNFR",
  POTENTIALLY_OUTSTANDING_ESOP: "esopEffectsIncludedNFR",
  POTENTIALLY_OUTSTANDING_PSOP: "psopEffectsIncludedNFR",
  POTENTIALLY_OUTSTANDING_EPP: "eppEffectsIncludedNFR",
  ARTICLE_OF_ASSOCIATION: "articleOfAssociation",
  SALE_TRANSACTION_FULLY_DILUTED: "fullyDilutedST",
  SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE:
    "outstandingClaEffectsIncludedST",
  SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY:
    "outstandingClaEffectsIncludedSTArray",
  SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_ESOP: "esopEffectsIncludedST",
  SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_PSOP: "psopEffectsIncludedST",
  SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_EPP: "eppEffectsIncludedST",
  EMPLOYEE_PARTICIPATION_PLAN_ST: "employeeParticipationPlanST",
  MATURITY_DATE_FULLY_DILUTED: "fullyDilutedPreMoneyMD",
  MATURITY_DATE_POTENTIALLY_OUTSTANDING_CONVERTIBLE:
    "outstandingClaEffectsIncludedPreMoneyMD",
  MATURITY_DATE_POTENTIALLY_OUTSTANDING_ESOP: "esopEffectsIncludedMD",
  MATURITY_DATE_POTENTIALLY_OUTSTANDING_EPP: "eppEffectsIncludedMD",
  MATURITY_DATE_POTENTIALLY_OUTSTANDING_PSOP: "psopEffectsIncludedMD",
  MATURITY_DATE_ARTICLE_OF_ASSOCIATION:
    "outstandingClaEffectsIncludedFairMarketMD",

  QUALIFY_EVENT_NFR: "qualifyEventNFR",
  VALUATION_FLOOR_NFR: "valuationFloorNFR",
  REQUEST_LOAN_CONVERSION_NFR: "requestLoanConversionNFR",
  VALUATION_FLOOR_ST: "valuationFloorST",
  FOLLOW_CONVERSION_RULE_MD: "followConversionRuleMD",
  CONVERSION_PRICE_BASIS_MD: "conversionPriceBasisMD",
  FULLY_DILUTED_POST_MONEY_MD: "fullyDilutedPostMoneyMD",
  OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD:
    "outstandingClaEffectsIncludedPostMoneyMD",
  OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY:
    "outstandingClaEffectsIncludedPostMoneyMDArray",
  CHAMBER_OF_COMMERCE_MD: "chamberOfCommerceMD",
  CHAMBER_OF_COMMERCE_MD_OTHER: "chamberOfCommerceMDOther",
  REQUEST_LOAN_DISCOUNT_NFR: "requestLoanDiscountNFR",
  REQUEST_LOAN_SHARE_TYPE_NFR: "requestLoanShareTypeNFR",
  REQUEST_LOAN_DISCOUNT_NFR_NUM: "investorRequestDiscount",
  EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD:
    "employeeParticipationPlanPostMoneyMD",

  FLOOR_VALUATION_FULLY_DILUTED_NFR: "fullyDilutedFloorNFR",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR:
    "outstandingClaEffectsIncludedFloorNFR",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR:
    "outstandingClaEffectsIncludedFloorNFRArray",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR:
    "employeeParticipationPlanFloorNFR",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_ESOP_NFR: "esop",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_PSOP_NFR: "psop",

  FLOOR_VALUATION_FULLY_DILUTED_ST: "fullyDilutedFloorST",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ST:
    "outstandingClaEffectsIncludedFloorST",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST:
    "outstandingClaEffectsIncludedFloorSTArray",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST:
    "employeeParticipationPlanFloorST",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_ESOP_ST: "esop",
  FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_PSOP_ST: "psop",

  FAIR_MARKET_VALUE_FULLY_DILUTED: "fullyDilutedFairMarketMD",
  FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE:
    "outstandingClaEffectsIncludedFairMarketMD",
  FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY:
    "outstandingClaEffectsIncludedFairMarketMDArray",
  FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP:
    "employeeParticipationPlanFairMarketMD",
  FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_ESOP: "esopShares",
  FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_PSOP: "psopShares",
  DEVIDE_VALUATION: "fairMarketMDDividedBy",
  POST_MONEY_VALUATION_LAST_ROUND: "postMoneyValuationLFR",
  /* modelling */

  TRIGGER_EVENT_MODEL: "triggerEventModel",
  OTHER_CL: "otherCl",
  CONVERTIBLE_LOANS: "convertiblesOutstanding",
  ESOP: "outstandingEsop",
  ESOP_SHARES: "outstandingEsopShares",
  ESOP_ESTABLISHMENT: "esopEstablishment",

  /* security */

  UNDERLYING_SECURITY: "underlyingSecurity",
  UNDERLYING_SECURITY_TYPE: "underlyingSecuritySharesType",
  UNDERLYING_SECURITY_SHARES: "underlyingSecuritySharesNumber",
  UNDERLYING_SECURITY_SHARES_NOMINAL_VAL:
    "underlyingSecuritySharesNominalValue",
  LIQUIDATION_PREFERENCE: "underlyingSecuritySharesLiqudationMulti"
};
