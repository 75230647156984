import * as IoService from "../../../../api/ioService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const deleteIo = data => {
  return async dispatch => {
    try {
      start(dispatch, types.DELETE_IO);
      const response = await IoService.deleteIO(data);

      success(dispatch, types.DELETE_IO, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      fail(dispatch, types.DELETE_IO, {
        error: errorData
      });
    }
  };
};

export const deleteIoAttachment = data => {
  return async dispatch => {
    try {
      start(dispatch, types.DELETE_IO_ATTACHMENT);
      await IoService.deleteIoAttachment(data);

      success(dispatch, types.DELETE_IO_ATTACHMENT, {
        data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      fail(dispatch, types.DELETE_IO_ATTACHMENT, {
        error: errorData
      });
    }
  };
};
