import * as Yup from "yup";
import { returnError } from "../common";

const sharesMgmtSchema = Yup.object().shape({
  currency: Yup.string().required(returnError("required")),
  shareType: Yup.string().required(returnError("required")),
  restrictSharesTransfer: Yup.string().required(returnError("required")),
  subjectToVesting: Yup.string().required(returnError("required")),
  nominalValuePrice: Yup.number(),
  votesNumber: Yup.string(),
  contractualRights: Yup.array().test({
    name: "contractualRights",
    message() {
      return `Please select at least one option`;
    },
    test(value = []) {
      if (
        (this.parent.shareType === "common_with_preferential_rights" ||
          this.parent.shareType === "preferred") &&
        value.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),
  min_amountPrompt: Yup.string(),
  preMoneyValMinAmountPrompt: Yup.string(),
  min_amount: Yup.number(),
  antiDilutionOptions: Yup.string().nullable(),
  shareCategoryName: Yup.string().test({
    shareCategoryName: "name",
    message({ value = "" }) {
      return `You already have a share loan named "${value}" in the.`;
    },
    test(value) {
      if (this.parent.isUpdating) {
        return true;
      }
      return !value || !this.parent.shareNameExists;
    }
  }),
  liquidationPreferentialParticipation: Yup.string(),
  liquidationPreferentialClause: Yup.string(),
  liquidationPreferentialCap: Yup.number(),
  dividendPreferentialParticipation: Yup.string(),
  dividendPreferentialClause: Yup.string(),
  dividendPreferentialCap: Yup.number()
});

export default sharesMgmtSchema;
