import { start, success, fail } from "../../../utils/helper_actions";
import types from "../action_types";
import * as votingService from "../../../../api/votingService";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const getUserVoting = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_VOTING);
      const response = await votingService.getVotings(params);
      success(dispatch, types.FETCH_VOTING, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_VOTING, {
        error: errorData
      });
    }
  };
};

export const getVotingDoc = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_VOTING_DOC);
      const response = await votingService.getVotingDoc(params);
      success(dispatch, types.FETCH_VOTING_DOC, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_VOTING_DOC, {
        error: errorData
      });
    }
  };
};

export const getSingleVoting = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_SINGLE_VOTING);
      const response = await votingService.getSingleVoting(params);
      success(dispatch, types.FETCH_SINGLE_VOTING, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_SINGLE_VOTING, {
        error: errorData
      });
    }
  };
};
