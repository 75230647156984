const fixDecimal = num => {
  return parseFloat(num.toFixed(2));
};

export const readFees = (feesData, vat, new_vat) => {
  try {
    return {
      administrationFeeBool:
        feesData.administration && feesData.administration.value,
      closingFeeBool: feesData.setup && feesData.setup.value,
      performanceFeeBool:
        Array.isArray(feesData.performance) ||
        (feesData.performance && feesData.performance.value),
      fees: {
        setup: fixDecimal(feesData.setup.value * 100),
        administration: {
          value: fixDecimal(feesData.administration.value * 100),
          years: feesData.administration.years
        },
        performance: {
          simple:
            typeof feesData.performance === "object"
              ? {
                  value: fixDecimal(feesData.performance.value * 100),
                  hurdle: fixDecimal(feesData.performance.hurdle * 100)
                }
              : {},
          multiple: Array.isArray(feesData.performance)
            ? feesData.performance.map(item => ({
                breakpoint: item.breakpoint,
                value: fixDecimal(item.value * 100)
              }))
            : []
        }
      },
      multiple:
        Array.isArray(feesData.performance) && feesData.performance.length > 0,
      multipliers:
        (Array.isArray(feesData.performance) && feesData.performance.length) ||
        2,
      vat: new_vat ? new_vat : vat
    };
  } catch (e) {
    return {};
  }
};
