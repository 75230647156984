const columns = [
  {
    title: 'Entity',
    dataIndex: 'entity',
    key: 'entity'
  },
  {
    title: 'Type of deal',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency'
  },
  {
    title: 'Investment date',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: 'Amount invested',
    dataIndex: 'amount',
    key: 'amount'
  },
  {
    title: 'Number of shares',
    dataIndex: 'shares',
    key: 'shares'
  }
];

export { columns };
