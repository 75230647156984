import axios from "./apiConfig";

export const resendConfirmService = email =>
  axios.get(`auth/new_confirmation_email/${email}`);

export const verifyTokenService = token =>
  axios.get(`auth/two-factor-auth/verification?token=${token}`);

export const loginService = loginData =>
  axios.post(`auth/login`, {
    email: loginData.email,
    password: loginData.password
  });

export const registerService = data => axios.post("auth/registration", data);

export const confirmationService = data => {
  let route = "auth/confirmation?";

  if (data.token) {
    route += `token=${data.token}`;
  }

  return axios.put(route, data);
};

export const getNewPassService = data =>
  axios.post("auth/request-new-password", data);

export const resetPasswordService = data => axios.put("auth/password", data);

export const initTwoFactorAuthService = () =>
  axios.put(`auth/two-factor-auth/initialization`);

export const completeTwoFactorAuthService = values =>
  axios.put(`auth/two-factor-auth/activation`, values);
