import InputContainer from "../../../Shared/Input";
import Checkbox from "../../../Shared/Checkbox";

const investorOverviewColumns = (
  { checkboxHandler, inputHandler },
  { investorOverview }
) => {
  const notFullyPaidUp =
    Array.isArray(investorOverview) &&
    investorOverview.filter(({ fullyPaidUp }) => !fullyPaidUp);

  const fields = [
    {
      title: "Selection",
      dataIndex: "selected",
      key: "selected",
      render: (value, { cuid }) => (
        <Checkbox
          name="selected"
          onChange={checkboxHandler("investorOverview", cuid)}
          checked={value}
        />
      )
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
      render: lastName => (lastName.match(/\d+/g) ? " " : lastName)
    },
    {
      title: "Amount to be invested",
      dataIndex: "investedAmount",
      key: "investedAmount"
    },
    {
      title: "New shares receiving",
      dataIndex: "newShares",
      key: "newShares"
    },
    {
      title: "Fully paid up",
      dataIndex: "fullyPaidUp",
      key: "fullyPaidUp",
      render: (value, { cuid }) => (
        <Checkbox
          name="fullyPaidUp"
          onChange={checkboxHandler("investorOverview", cuid)}
          checked={value}
        />
      )
    }
  ];
  if (Array.isArray(notFullyPaidUp) && notFullyPaidUp.length > 0) {
    fields.push({
      title: "Insert the amount that has been paid up",
      dataIndex: "paidUpAmount",
      key: "paidUpAmount",
      render: (value, { cuid, fullyPaidUp = false }) => (
        <div>
          {!fullyPaidUp && (
            <InputContainer
              type="number"
              name="paidUpAmount"
              onChange={inputHandler("investorOverview", cuid)}
              value={value}
              formatter={val =>
                val && `CHF ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
              }
              parser={val =>
                val && val.replace(new RegExp(`CHF\\s?|(\\'*)`, "g"), "")
              }
            />
          )}
        </div>
      )
    });
  }
  return fields;
};

const subscriptionFields = (props, values) => [
  {
    id: 1,
    title: "Investor  overview",
    details:
      "Please select the investors who shall receive a subscription form to susbcribefor and pay in his/her/its shares",
    input: {
      type: "table_list",
      name: "investorOverview",
      cols: investorOverviewColumns(props, values)
    }
  }
];

const fieldList = ({ composedInputHandlers } = {}, values) => [
  {
    sectionId: 1,
    items: subscriptionFields(composedInputHandlers, values),
    stepTitle:
      'Welcome to your new best "legal" buddy. Currently Leva is suited for early stage funding rounds. If you are raising your pre-Seed or Seed round, this is the right place to be. In case you are raising a Series A or later round, please reach out to X, our trusted partner and one of the leading law firms for venture capital in Switzerland.'
  }
];

export default fieldList;
