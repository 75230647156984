import { useState } from "react";
import ModalWrapper from "../../../Modal";
import { Button } from "../../../Button";
import Switch from "../../../Switch";

const NdaSignature = ({ visible, signNda, ndaStatuses }) => {
  const [ndaSigned, setNdaSigned] = useState(false);

  const handleSwitch = checked => setNdaSigned(checked);
  return (
    <ModalWrapper
      title="Sign Confidentiality & Non-Circumvent Agreement"
      visible
      closable={false}
      footer={null}
    >
      <div className="nda-signature-container">
        <h1>
          The information disclosed by the deal manager on the Leva platform is
          confidential. Please sign this Confidentiality & Non-Circumvent
          Agreement with the Deal Manager, which template can be found{" "}
          <a
            href="/doc_viewer?doc=/docs/nda.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </h1>
        <Switch
          green
          label="I have read, understood and accept the Confidentiality & Non-Circumvent Agreement."
          onChange={handleSwitch}
          value={ndaSigned}
          name="signNda"
        />
        <div className="btn-container">
          <Button
            label="Sign"
            onClick={signNda}
            disabled={!ndaSigned}
            {...ndaStatuses}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default NdaSignature;
