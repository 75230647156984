import { useState } from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import useWindowSize from "#helpers/hooks/windowSize";
import PhoneMenu from "./components/menu/PhoneMenu";
import DesktopMenu from "./components/menu/DesktopMenu";
import { logoItem, hamburgerLogoItem } from "./components/menu/items/menuItems";

export default function Navbar() {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const history = useHistory();
  const { width } = useWindowSize();

  const handleLogoClick = () => {
    history.push("/");
  };

  const handleHamburgerClick = () => setVisibleDrawer(true);

  const logo = logoItem({ mobile: width <= 940, handleLogoClick });
  const hamburgerLogo = hamburgerLogoItem({
    handleHamburgerClick
  });

  const layout = () => {
    if (width >= 940) {
      return <DesktopMenu />;
    } else {
      return (
        <>
          {hamburgerLogo}
          <PhoneMenu setVisible={setVisibleDrawer} visible={visibleDrawer} />
        </>
      );
    }
  };

  return (
    <Menu className="navbar-menu navbar-center" mode="horizontal">
      {logo}
      {layout()}
    </Menu>
  );
}
