import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Button, Icon, Popover } from "antd";
import { withTooltip } from "../../../hocs";

export const LoadingButton = ({
  type = "primary",
  success,
  danger,
  loading,
  shadow,
  label,
  className,
  buttonStyle,
  onClick,
  disabled,
  icon,
  size,
  tooltip,
  href,
  hrefProps,
  tooltipProps,
  ...buttonProps
}) => {
  const renderContent = () => {
    if (success) {
      return <Icon type="check" />;
    }
    return label;
  };

  const classnames = `core-btn ${className || ""} ${shadow ? "shadow" : ""} ${
    danger ? "danger" : ""
  }`;

  const button = (
    <Button
      type={type}
      style={buttonStyle}
      onClick={!loading && !success ? onClick : null}
      disabled={disabled}
      loading={loading}
      size={size}
      className={classnames}
      icon={icon}
      danger={danger}
      {...buttonProps}
    >
      {renderContent()}
    </Button>
  );

  if (tooltip) {
    // TOOLTIPS DONT BEHAVE WELL WITH BUTTONS AND AS SUCH WE ARE WRAPPING THEM WITH POPOVERS

    return (
      <Popover className="btn-popover" content={tooltip} {...tooltipProps}>
        {href && !disabled ? <NavLink to={href}>{button}</NavLink> : button}
      </Popover>
    );
  }

  return href && !disabled ? (
    <NavLink to={href} {...hrefProps}>
      {button}
    </NavLink>
  ) : (
    button
  );
};

LoadingButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  spinnerStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  label: PropTypes.any,
  buttonStyle: PropTypes.object,
  classes: PropTypes.object,
  size: PropTypes.string,
  buttonProps: PropTypes.object,
  shadow: PropTypes.bool
};

export default LoadingButton;
