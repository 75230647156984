import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.GENERATE_CLA:
      return baseReducer(action, state, actions.CREATE, {
        success: () => ({
          cla: action.payload.data.cla
        })
      });

    case types.FETCH_COMPANY_INFO:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          companyInfo: action.payload.data
        }),
        fail: () => ({ ...state._data })
      });

    case types.GENERATE_CONTRACT:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          contracts: action.payload.data
        }),
        fail: () => ({ ...state._data })
      });

    case types.GET_CONTRACTS:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          contracts: action.payload.data
        })
      });

    case types.GET_CONTRACTS_PREVIEW:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          contracts: action.payload.data
        })
      });

    case types.FETCH_POOLED_FUND_TEMPLATES:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          contracts: action.payload.data
        })
      });

    case types.FETCH_INVESTMENT_COMPLIANCE:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          invCompl: action.payload.data
        })
      });

    case types.FETCH_INVESTMENT_CONFIRMATION:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          invConf: {
            contractName: "Investment confirmation",
            value: action.payload.data.value,
            createdAt: action.payload.data.createdAt
          }
        })
      });

    case types.FETCH_TRANS_CONFIRMATION:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          transConf: {
            contractName: "Transaction confirmation",
            value: action.payload.data.value,
            createdAt: action.payload.data.createdAt
          }
        })
      });

    case types.FETCH_DEAL_CONFIRMATION:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          dealConf: {
            contractName: "Deal confirmation",
            value: action.payload.data.value,
            createdAt: action.payload.data.createdAt
          }
        })
      });

    case types.FETCH_INV_SUMMARY:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          invSummary: {
            contractName: "Investment summary",
            value: action.payload.data.value,
            createdAt: action.payload.data.createdAt
          }
        })
      });

    case types.UPDATE_UNIT_COST:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => ({
          ...state._data
        })
      });

    case types.POST_CONTRACTS:
      return baseReducer(action, state, actions.CREATE, {
        success: () => ({
          ...state._data
        })
      });

    default:
      return state;
  }
};

export default reducer;
