const formSections = [
  // THE ID NUMBERING HERE INCLUDES THE NUMBER OF STEPS EACH SECTION HAS
  {
    id: 1,
    title: "A. General information"
  },
  {
    id: 2,
    title: "B. Financials",
    subSections: [
      {
        id: 2,
        title: "General information"
      },
      {
        id: 3,
        title: "Fundraising target"
      },
      {
        id: 4,
        title: "Valuation"
      }
    ]
  },
  {
    id: 5,
    title: "C. ESOP"
  },
  {
    id: 6,
    title: "D. Share information"
  },
  {
    id: 7,
    title: "E. Board of directors",
    subSections: [
      {
        id: 7,
        title: "Constitution"
      },
      {
        id: 8,
        title: "Voting power"
      }
    ]
  },
  {
    id: 9,
    title: "F. Shareholder matter"
  },
  {
    id: 10,
    title: "G. Founder Vesting"
  },
  {
    id: 11,
    title: "H. General investment info"
  },
  {
    id: 12,
    title: "I. Investors"
  }
];

export default formSections;
