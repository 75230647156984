import { baseSelector } from "./utils";

const selectEntityDataFunc = state => {
  const data = (state._data && state._data.entity) || {};

  return data;
};

const selectEntityData = baseSelector(selectEntityDataFunc);

export { selectEntityData };
