import format from "date-fns/format";

export const mapTaxValuesToPost = (values, entityId, type, taxId) => {
  const payload = {
    transactionDate: format(values.transactionDate, "YYYY-MM-DD") + " 00:00:00",
    issuedShares: values.issuedShares,
    taxShareValue: values.taxShareValue || 0,
    lastSharePrice: values.lastSharePrice,
    shareNominalValue: values.shareNominalValue,
    currencyId: values.currency,
    sharesAreDiluted: values.sharesAreDiluted === "diluted"
  };

  if (type === "patch") {
    payload.cuid = taxId;
  } else {
    payload.entityId = entityId;
    payload.year = format(values.transactionDate, "YYYY");
  }

  return payload;
};

export const mapTaxValuesToForm = values => {
  return {
    ...values,
    currency: values.currencyId
  };
};
