import { closedValues } from "#constants/io/closed";
import { mapClaDataToReadableFormVals } from "#helpers/mappers/convertibles";

export const setClaQuestValues = ({
  ioColl,
  shareholdersColl,
  allMemberships,
  setValues,
  step,
  esopShares,
  formData,
  cla
}) => () => {
  if (ioColl.didFetch) {
    const { data: { closed } = {} } = ioColl;

    const { data } = shareholdersColl;
    const formattedShareholders = data
      .filter(({ user: { role } }) => role === "investor")
      .map(({ user }) => ({
        ...user,
        newShares: 0,
        investedAmount: 0
      }));

    const formattedMemberships = allMemberships
      .filter(
        ({ isDirector, role, representativeOf }) =>
          representativeOf !== "board_observer" &&
          (role === "director" || isDirector)
      )
      .map(membership => ({
        membershipId: membership.cuid,
        presidentOfTheBoard: membership.presidentOfTheBoard,
        representativeOf: membership.representativeOf,
        signingAuthority: membership.signingAuthority,
        ...membership.user
      }));

    if (closed >= closedValues.CLA_DONE) {
      const { data: ioData } = ioColl;
      const { cla: readableCla } = mapClaDataToReadableFormVals(ioData);
      const { claDirectors = [] } = cla;
      const selectedDirectors = formattedMemberships.map(item => ({
        ...item,
        signingDirector: claDirectors.find(
          ({ membershipId }) => item.membershipId === membershipId
        )
      }));

      setValues({
        signingInvestor: formattedShareholders,
        signingDirector: selectedDirectors,
        outstandingEsopShares: esopShares,
        outstandingEsop: esopShares ? "yes" : "no",
        ...readableCla
      });
    } else if (step !== undefined && step !== null) {
      const formMemberships = formattedMemberships.reduce((acc = [], curr) => {
        const existingMember = formData.signingDirector.find(
          ({ membershipId, signingDirector }) =>
            membershipId === curr.membershipId && signingDirector
        );
        if (existingMember) {
          return [...acc, existingMember];
        }
        return [...acc, curr];
      }, []);
      setValues({
        ...formData,
        signingInvestor: formattedShareholders,
        signingDirector: formMemberships
      });
    } else {
      setValues({
        signingInvestor: formattedShareholders,
        signingDirector: formattedMemberships,
        outstandingEsopShares: esopShares,
        outstandingEsop: esopShares ? "yes" : "no"
      });
    }
  }
};
