import { connect } from "react-redux";
import { updateUserSettings } from "#core/modules/users/actions/update";
import { clearState } from "#core/utils/helper_actions";
import { getPortfolio } from "#core/modules/io/actions";
import { getIoMemberships } from "#core/modules/iomemberships/actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { entity: { userRole } = {} } = state.entities._data || {};
  const { deals: { iosWhereUserIsAM = [] } = {}, isFetching: dealsFetching } =
    state.io._data || {};

  const {
    isUpdating,
    didUpdate,
    _data: { tosAccepted, firstName } = {}
  } = state.users;
  const { _data: { memberships = [], objectRef } = {}, didFetch: ioDidFetch } =
    state.ioMemberships || {};

  return {
    userRole,
    userStates: {
      loading: isUpdating,
      success: didUpdate
    },
    dealsColl: {
      dataSource: iosWhereUserIsAM,
      loading: dealsFetching
    },
    membershipsColl: {
      dataSource: memberships.filter(({ status }) => status === 1),
      didFetch: ioDidFetch
    },
    tosAccepted,
    firstName
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  updateUserSettings: data => dispatch(updateUserSettings(data)),
  getIoMemberships: data => dispatch(getIoMemberships(data)),
  getPortfolio: params => dispatch(getPortfolio(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
