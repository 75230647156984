import { equityTs } from "#constants/io/equityPool";
import { basicOption } from "#helpers/general";
import {
  renderOtherInput,
  renderOtherInputWithoutId
} from "../../../common/prompts";

const shareholderMatter = values => [
  {
    title: (
      <span>
        What percentage threshold (of shareholder votes represented at the
        relevant shareholders' meeting) is required to approve important{" "}
        <a
          href="/doc_viewer?doc=/docs/shareholderMatters.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          shareholders' matters
        </a>
        ?
      </span>
    ),
    input: {
      type: "select_btn",
      name: equityTs.QUALIFIED_MAJORITIES_SH_MATTERS,
      options: [
        basicOption("66 (2/3)%", "66 (2/3)%", {
          tooltip:
            "If you want to change any Shareholders’ Matters, please reach out to X, our partner law firm. For more information reach out to hi@leva.pe"
        }),
        basicOption("Other", "Other", {
          tooltip:
            "If you want to change any Shareholders’ Matters, please reach out to X, our partner law firm. For more information reach out to hi@leva.pe"
        })
      ]
    }
  },
  values[equityTs.QUALIFIED_MAJORITIES_SH_MATTERS] === "Other"
    ? renderOtherInput(
        2,
        equityTs.QUALIFIED_MAJORITIES_SH_MATTERS_OTHER,
        "text"
      )
    : null,
  {
    title: (
      <span>
        To whom shall the Company provide additional
        <a
          href="/doc_viewer?doc=/docs/informationRights.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          {" "}
          information
        </a>
        (i.e. in addition to the statutory content)?
      </span>
    ),
    input: {
      type: "select_btn",
      name: equityTs.INFO_RIGHTS,
      options: [
        basicOption("investor", "Investors"),
        basicOption("shareholders", "All shareholders")
      ]
    }
  },
  {
    title: (
      <span>
        What shall the minimum shareholding of {values[equityTs.INFO_RIGHTS]}{" "}
        be, to have the right to require{" "}
        <a
          href="/doc_viewer?doc=/docs/informationRights.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          information
        </a>
        ?
      </span>
    ),
    input: {
      type: "select_btn",
      name: equityTs.INFO_INVESTOR,
      options: [basicOption("10%", "10%"), basicOption("Other")]
    }
  },
  values[equityTs.INFO_INVESTOR] === "Other"
    ? renderOtherInputWithoutId(equityTs.INFO_INVESTOR_OTHER, "number")
    : null,
  {
    title:
      "What threshold is required for a shareholder or group of shareholders to exercise their Drag-Along Right over the other shareholders (Co-Sale Obligation)?",
    details:
      "The tag along is mandatory with Leva. It is meant to protect your company",
    input: {
      type: "select_btn",
      name: equityTs.DRAG_ALONG_PERCENTAGE,
      options: [
        basicOption("more_than_50", "More than 50% of all shares "),
        basicOption("more_than_66.67", "More than 66,67% of all shares"),
        basicOption(
          "more_than_66.67_common_and_more_than_50/66.67_preferred",
          "More than (i) 66.67% of all common shares and (ii) 50 / 66.67% of all preferred shares"
        ),
        basicOption("Other")
      ]
    }
  },
  values[equityTs.DRAG_ALONG_PERCENTAGE] === "Other"
    ? renderOtherInputWithoutId(equityTs.DRAG_ALONG_PERCENTAGE_OTHER, "number")
    : null
];

export default shareholderMatter;
