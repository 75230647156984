import FormSuccessView from '../../../Shared/Form/FormStepper/components/SuccessView';
import { Button } from '../../../Shared/Button';

const FounderCreationSuccess = ({ entityId, email }) => () => {
  return (
    <div className="custom-success-view">
      <FormSuccessView
        title={`Great, we have sent an invitation email to ${email}`}
        id="success-prompt"
      />
      <div className="btn-container">
        <Button
          label="Finish deal set up"
          className="main-btn"
          href={`/create/deal?entityId=${entityId}`}
        />
        <span className="helper-text">You can set up deals anytime.</span>
      </div>
    </div>
  );
};

export default FounderCreationSuccess;
