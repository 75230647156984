import { contractNames, contractTypes, legalDocsType } from "#constants/deal";

export default {
  POOLED_EQUITY: "pooled_equity",
  CLA: "CLA",
  UNPOOLED_DEBT: "unpooled_debt",
  DEBT_FINANCING: "pooled_debt",
  CONVERTIBLE_LOAN: "pooled_hybrid_cla",
  CONVERSION_POOL: "conversion_pool",
  POOLED_FUND: "pooled_fund",
  SAFE: "pooled_safe",
  KISS: "pooled_kiss",
  BSA_AIR: "pooled_bsa_air",
  CONVERTED_EQUITY: "converted_equity"
};

export const equityAttachmentCategories = {
  TERMSHEET: "termsheet",
  INVESTMENT_AGREEMENT: "investment-agreement",
  SHA: "sha",
  BOARD_REGULATION: "board-regulation",
  NOTARY: "notary",
  CONVERTIBLE_AGREEMENT: "convertible"
};
export const dealAttachmentCategories = {
  DEAL: "deal",
  LEGAL: "legal",
  FINANCIAL: "financial",
  REPORTS: "reports",
  NEWS: "news",
  TAXES: "taxes",
  "SYNDICATE-TERMS": "syndicate-terms",
  SPA: "spa",
  "SPA-SUBSCRIPTION-AGREEMENT": "spa-subscription-agreement",
  TRANSACTION_SLIPS: "transaction_slips"
};

export const ioAttachmentCategoriesLabels = {
  [dealAttachmentCategories.DEAL]: "Deal",
  [dealAttachmentCategories.LEGAL]: "Legal",
  [dealAttachmentCategories.FINANCIAL]: "Financial",
  [dealAttachmentCategories.REPORTS]: "Reports",
  [dealAttachmentCategories.NEWS]: "News",
  [dealAttachmentCategories.TAXES]: "Taxes",
  [equityAttachmentCategories.TERMSHEET]: "Termsheet",
  [equityAttachmentCategories.INVESTMENT_AGREEMENT]: "Investment agreement",
  [equityAttachmentCategories.SHA]: "Shareholder agreement",
  [equityAttachmentCategories.BOARD_REGULATION]: "Board regulation",
  [equityAttachmentCategories.NOTARY]: "Notary",
  [equityAttachmentCategories.CONVERTIBLE_AGREEMENT]: "Convertibles",
  [dealAttachmentCategories.SPA]: "Subscription agreement",
  [dealAttachmentCategories.TRANSACTION_SLIPS]: "Transaction slips",
  general: "General"
};

export const ioContractCategories = {
  TERMSHEET: [contractTypes.TERM_SHEET, contractTypes.TERMSHEET_CLA],
  CONVERTIBLE_AGREEMENT: [contractTypes.CLA],
  INVESTMENT_AGREEMENT: [
    contractTypes.INVESTMENT_AGREEMENT,
    contractTypes.SUBSCRIPTION_FORM_VERRECHNUNG,
    contractTypes.SUBSCRIPTION_FORM_BAR,
    contractTypes.ARTICLES_OF_ASSOCIATION,
    contractTypes.STATUTE,
    contractTypes.SAFE,
    contractTypes.KISS
  ],
  SHA: [contractTypes.SHA],
  BOARD_REGULATION: [contractTypes.BOARD_REGULATIONS],
  NOTARY: [
    contractTypes.ACCEPTANCE_DECLARATION,
    contractTypes.BALANCE_CONFIRMATION,
    contractTypes.HANDELSREGISTERANMELDUNG,
    contractTypes.LEX_FRIEDRICH_LEX_COLLER_EQUITY,
    contractTypes.PUBLIC_DEED_BOD,
    contractTypes.PUBLIC_DEED_EGM,
    contractTypes.REPORT_CAPITAL_INCREASE_QUALIFIZIERT,
    contractTypes.REPORT_CAPITAL_INCREASE_BAR
  ]
};

export const dealAttachmentSubCategories = {
  [contractTypes.SHA]: [contractNames[contractTypes.SHA]],
  [contractTypes.CLA]: [contractNames[contractTypes.CLA]],
  [contractTypes.INVESTMENT_AGREEMENT]:
    contractNames[contractTypes.INVESTMENT_AGREEMENT],
  // [legalDocsType.LOAN]: "Loan agreement",
  [contractTypes.SUBSCRIPTION_FORM_VERRECHNUNG]:
    contractNames[contractTypes.SUBSCRIPTION_FORM_VERRECHNUNG],
  [contractTypes.CAPITAL_CALL_NOTICE]:
    contractNames[contractTypes.CAPITAL_CALL_NOTICE],
  [contractTypes.LIMITED_PARTNERSHIP_AGREEMENT]:
    contractNames[contractTypes.LIMITED_PARTNERSHIP_AGREEMENT],
  [contractTypes.PRIVATE_PLACEMENT_MEMORANDUM]:
    contractNames[contractTypes.PRIVATE_PLACEMENT_MEMORANDUM],
  [contractTypes.ARTICLES_OF_ASSOCIATION]: [
    contractNames[contractTypes.ACCEPTANCE_DECLARATION]
  ],
  [contractTypes.STATUTE]: [contractNames[contractTypes.STATUTE]],
  [contractTypes.SAFE]: [contractNames[contractTypes.SAFE]],
  [contractTypes.KISS]: [contractNames[contractTypes.KISS]],
  [contractTypes.BSA_AIR]: [contractNames[contractTypes.BSA_AIR]],
  general: "Other legal agreement"
};

export const downloadableContracts = [
  dealAttachmentCategories["SYNDICATE-TERMS"],
  dealAttachmentCategories.SPA,
  dealAttachmentCategories["SPA-SUBSCRIPTION-AGREEMENT"]
];

export const actionsContracts = [
  dealAttachmentCategories.SPA,
  dealAttachmentCategories["SPA-SUBSCRIPTION-AGREEMENT"]
];
