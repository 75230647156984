import { useState } from "react";
import { Button } from "../../../../Shared/Button";
import InputContainer from "../../../../Shared/Input";

const VerifyCode = ({ handleVerifyClick }) => {
  const [verify, setVerify] = useState();
  return (
    <div className="verify-input-btn">
      <span className="verify-row">
        <InputContainer
          type="text"
          name="verify"
          onChange={e => setVerify(e.target.value)}
          value={verify}
        />
        <Button
          type="secondary"
          label="Verify"
          size="large"
          onClick={handleVerifyClick(verify)}
        />
      </span>
    </div>
  );
};

export default VerifyCode;
