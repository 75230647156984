import { useEffect } from "react";
import { zefixHandlers } from "#helpers/zefix";
import getEntitySchema from "#validators/entitySchema";
import {
  mapDetailsToEntity,
  mapCompanyDetails
} from "#helpers/mappers/entitySettings";
import { fieldList, formSections } from "./list";
import { FormStepper, BasicFormWrapper } from "../../../Shared/Form";
import { handleInputChange } from "../../common/formHandler";
import InputContainer from "../../../Shared/Input";

const EntityCreationForm = ({
  values,
  setFieldValue,
  handleChange,
  handleSubmit,
  handleUpload,
  companyDetails,
  buttonStates,
  setValues,
  entityCuid,
  errors,
  userRole,
  entity,
  resetForm,
  entityId,
  localFileList,
  onFileRemove,
  ...props
}) => {
  useEffect(() => {
    if (companyDetails.didFetch) {
      const finalValues = {
        ...values,
        ...mapCompanyDetails(companyDetails.data)
      };
      setValues(finalValues);
    }
    if (entity) {
      setValues(entity);
    }
  }, [companyDetails.didFetch]);

  const extraField = () => (
    <div className="extra-field-container">
      Company name: <InputContainer value={values.name} />
    </div>
  );

  const { onBlur: handleBlur } = zefixHandlers();
  const formArgs = {
    values,
    errors,
    inputEvents: {
      onChange: handleInputChange({ handleChange, setFieldValue, values }),
      onUpload: handleUpload(setFieldValue)
    },
    list: fieldList({
      loading: companyDetails.isFetching,
      values,
      detailsFetched: companyDetails.didFetch,
      userRole,
      handleBlur,
      localFileList,
      onFileRemove
    }),
    sections: formSections,
    handleSubmit,
    success: buttonStates.success,
    buttonStates,
    extraField,
    validationSchema: getEntitySchema(),
    customClassName: "context--violet",
    ...props
  };

  return <FormStepper {...formArgs} />;
};

export default BasicFormWrapper(EntityCreationForm);
