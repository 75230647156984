import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};
const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.POST_USER_APPLICATION:
      return baseReducer(action, state, actions.CREATE, {});
    default:
      return state;
  }
};
export default reducer;
