import { getCroppedImg } from "#helpers/convertBase64";
import { useState, useCallback } from "react";
import { Slider } from "antd";
import Cropper from "react-easy-crop";
import { Button } from "../Button";

const ImageCropper = ({ image, onOk }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleOK = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    onOk(croppedImage);
  };

  return (
    <div className="cropper-wrapper">
      <div className="cropper-container">
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropSize={{ width: 200, height: 200 }}
          restrictPosition={false}
        />
      </div>
      <div className="zoom-wrapper">
        <span>Zoom</span>
        <Slider
          defaultValue={0}
          min={0.8}
          max={3}
          step={0.1}
          value={zoom}
          onChange={val => setZoom(val)}
        />
      </div>

      <div className="btn-container">
        <Button label="OK" onClick={handleOK} />
      </div>
    </div>
  );
};

export default ImageCropper;
