import { connect } from 'react-redux';
import { resetPassword, getNewPassword } from '#core/modules/auth/actions';
import { clearState } from '#core/utils/helper_actions';

import Presentational from './Presentational';

const mapStateToProps = (state) => ({
  isFetching: state.auth.isFetching,
  isUpdating: state.auth.isUpdating,
  didFetch: state.auth.didFetch,
  didUpdate: state.auth.didUpdate,
  isAuthCompleted: state.users._data && state.users._data.cuid
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (user) => dispatch(resetPassword(user)),
    getNewPassword: (data) => dispatch(getNewPassword(data)),
    clearState: (key) => clearState(dispatch, key)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
