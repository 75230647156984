import { Alert } from "antd";

const AlertWrapper = ({
  visible,
  message,
  messageProps = {},
  onClick,
  ...alertProps
}) => {
  if (visible) {
    const wrappedMsg = (
      <span onClick={onClick} {...messageProps} className="alert-label">
        {" "}
        {message}
      </span>
    );

    return <Alert message={wrappedMsg} {...alertProps} />;
  }

  return null;
};

export default AlertWrapper;
