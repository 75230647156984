import actionStatus from "../constants/action_status";
import * as types from "../constants/helper_action_types";
import authActionTypes from "../modules/auth/action_types";

/**
 * Generic functions to create actions for each phase of a call, starting point
 * success point and fail point.
 */

const action = (dispatch, actionType, payload, status) => {
  dispatch({
    type: actionType,
    status,
    payload
  });
};

export const start = (dispatch, actionType, payload) => {
  action(dispatch, actionType, payload, actionStatus.ACTION_START);
};
export const success = (dispatch, actionType, payload) => {
  action(dispatch, actionType, payload, actionStatus.ACTION_SUCCESS);
};
export const fail = (dispatch, actionType, payload) => {
  action(dispatch, actionType, payload, actionStatus.ACTION_FAIL);
};

/**
 * Clear state actions
 */

export const clearState = (dispatch, givenState) => {
  dispatch({
    type: types.CLEAR_STATE,
    givenState
  });
};

export const clearGlobalState = dispatch => {
  dispatch({
    type: types.CLEAR_GLOBAL_STATE
  });
};

export const clearStateData = (dispatch, givenState) => {
  dispatch({
    type: types.CLEAR_STATE_DATA,
    givenState
  });
};

export const clearStateDataByKey = (dispatch, givenState, ...stateKeys) => {
  dispatch({
    type: types.CLEAR_STATE_DATA_BY_KEY,
    givenState,
    stateKeys
  });
};
