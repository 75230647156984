import { connect } from 'react-redux';
import { clearState } from '#core/utils/helper_actions';
import { getCommits } from '#core/modules/commitment/actions';
import { fetchCaptable } from '#core/modules/io/actions';
import Presentational from './Presentational';

const mapStateToProps = (state) => {
  const { entity: { cuid: entityId, shares: entityShares } = {} } =
    (state.entities._data && state.entities._data) || {};

  const { commits = [] } = state.commits._data || {};

  return {
    entityId,
    entityShares,
    commits,
    commitsDidFetch: state.commits.didFetch,
    commitsFetching: state.commits.isFetching
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCommits: (data) => dispatch(getCommits(data)),
  fetchCaptable: (data) => dispatch(fetchCaptable(data)),
  clearState: (key) => clearState(dispatch, key)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
