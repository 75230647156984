const Approved = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="16" stroke="#15B982" stroke-width="2" />
    <line
      x1="14.0607"
      y1="18.9393"
      x2="19.0607"
      y2="23.9393"
      stroke="#15B982"
      stroke-width="3"
    />
    <line
      x1="16.9393"
      y1="23.9393"
      x2="25.9393"
      y2="14.9393"
      stroke="#15B982"
      stroke-width="3"
    />
  </svg>
);

export default Approved;
