import FormSuccessView from "../../../Shared/Form/FormStepper/components/SuccessView";
import { Button } from '../../../Shared/Button';

const BrCreationSuccessView = () => () => {
  return (
    <div className="custom-success-view ts-creation">
      <FormSuccessView
        title="Congrats, you just finished to set-up the board regulations for your next fundraising round."
        className="entity-creation"
        id="success-prompt"
      />
      <h3>
        You can come back and edit your board regulations documents at any time.{" "}
      </h3>
      <span className="helper-text">
        If you wish to add further terms, which are not covered in the
        questionnaire we recommend to reach out to X’s Startup Desk
      </span>
      <div className="btn-container">
        <Button
          label="View equity"
          className="main-btn"
          href="/equity/overview"
        />
      </div>
    </div>
  );
};

export default BrCreationSuccessView;
