import { dealRounds } from "#constants/deal";
import { basicOption } from "#helpers/general";
import { equityTs, iaKeys } from "#constants/io/equityPool";
import { confirmationPrompt, indexItem } from "../../../common/prompts";

const appendicesSchedules = [
  {
    title: "Excerpt of the commercial register",
    input: {
      name: "commercialRegister",
      type: "upload"
    }
  },
  {
    title: "List of all employees of the Company (anonymized)",
    input: {
      name: "employeesList",
      type: "upload"
    }
  },
  {
    title: "List of intellectual property rights",
    input: {
      name: "intellectualPropertyRights",
      type: "upload"
    }
  }
];

const otherAppendices = values => [
  {
    title: "Do you want to add a data room appendix?",
    input: {
      name: "dataRoom",
      ...confirmationPrompt
    }
  },
  values.dataRoom === "yes"
    ? {
        title: "Data room:",
        input: {
          name: "dataRoomUpload",
          type: "upload"
        }
      }
    : null,
  {
    title: "Does the company have a business plan?",
    input: {
      name: iaKeys.BUSINESS_PLAN,
      ...confirmationPrompt
    }
  },
  values[iaKeys.BUSINESS_PLAN] === "yes"
    ? {
        title:
          "Shall the business plan be uploaded, so every fundraising document is stored in one place?",
        input: {
          name: iaKeys.BUSINESS_PLAN_UPLOAD,
          ...confirmationPrompt
        }
      }
    : null,
  values[iaKeys.BUSINESS_PLAN_UPLOAD] === "yes"
    ? {
        title: "Business Plan",
        input: {
          name: iaKeys.BUSINESS_PLAN_FILE,
          type: "upload"
        }
      }
    : null,
  {
    title:
      "Please upload all documents and information you think can support an investor’s decision making (Pitch Decks, Financials, etc.). These documents will not be an appendix. You will find them in your data room.",
    input: {
      name: "investorDecisionMakingAttachments",
      type: "upload"
    }
  }
];

export default [appendicesSchedules, otherAppendices];
