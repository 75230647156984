import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './action_types';
import actions from '../../constants/action_type';

const initState = {
  ...initialState
};
const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.FETCH_NEWS: // REDUCER FOR NEWS
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            newsList: action.payload.data
          };
        }
      });
    case types.POST_NEWS: // REDUCER FOR NEWS
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const news = state._data ? state._data.newsList : [];
          return { ...state._data, newsList: [action.payload.data, ...news] };
        }
      });

    case types.FETCH_SINGLE_NEWS:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return { ...state._data, singleNews: action.payload.data };
        }
      });
    default:
      return state;
  }
};
export default reducer;
