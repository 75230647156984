import Icon from 'antd/es/icon';
import FormSuccessView from '../../../Shared/Form/FormStepper/components/SuccessView';
import { Button } from '../../../Shared/Button';

const SubscriptionSuccess = () => {
  return (
    <div className="custom-success-view subscription-creation">
      <FormSuccessView
        title="Congratulations, you just finished setting up the subscription form for your next fundraising round."
        className="subscription-creation"
        id="success-prompt"
      />
      <h3>You can come back and edit your subscription form at any time.</h3>
      <h3>
        You can come back and edit If you wish to add further terms, which are
        not covered in the questionnaire we recommend to reach out to X’s
        Startup Desk. your subscription form at any time.
      </h3>
      <div className="btn-container">
        <Button
          label="View equity"
          className="main-btn"
          href="/equity/overview"
        />
      </div>
    </div>
  );
};

export default SubscriptionSuccess;
