export const entityTypes = {
  ASSET_MANAGER_ENTITY: "asset_manager_entity",
  TARGET_ENTITY: "target_entity",
  LAWYER_ENTITY: "lawyer_entity",
  LEGAL_ENTITY: "legal_entity",
  NATURAL_PERSON: "natural_person"
};

export const entityModel = {
  NAME: "name",
  ADDRESS: "address",
  ZIP_CODE: "postalCode",
  STREET: "address",
  CITY: "city",
  COUNTRY: "country",
  UID: "uid",
  SHARES_NUMBER: "shares",
  NOMINAL_VALUE: "sharesNominalValue",
  FOUNDING_DATE: "birthDate",
  DESCRIPTION: "description",
  PURPOSE: "purpose",
  PRE_VALUE: "preValue"
};
