import { useState, Fragment, useEffect } from "react";
import { Popover, Badge, List } from "antd";
import { useHistory } from "react-router-dom";
import Icon from "antd/es/icon";
import {
  getNotifActionLink,
  grantNotificationPermission
} from "#helpers/notifications";
import Button from "../../Button/components/BtnWrapper/Presentational";
import AvatarWrapper from "../../Avatar";
import defaultLogo from "../../../../assets/images/default_logo.png";
import InfiniteScroller from "../../InfiniteScroller";

const NotifsPopover = ({
  notificationsColl,
  notificationCreated,
  postNotifsSubscription,
  updateNotifsStatus,
  bulkUpdateNotifsStatus
}) => {
  const history = useHistory();

  const defaultList =
    process.env.ENV === "PRE_MAINTENANCE"
      ? [
          {
            icon: defaultLogo,
            title: "Maintenance",
            body: (
              <span>
                We will do maintenance work on{" "}
                <strong>{process.env.MAINTENANCE_TIME}</strong> to improve the
                platform for you. Some disruptions might happen. Thank you for
                your understanding.
              </span>
            )
          }
        ]
      : [];

  const { data = [] } = notificationsColl;
  const notifsList = [
    ...defaultList,
    ...data.map(
      ({ content: { message, title, metadata } = {}, cuid, read, viewed }) => ({
        cuid,
        body: message,
        title,
        metadata,
        read,
        viewed
      })
    )
  ];

  const unviewedNotifisList = data.filter(({ viewed = false }) => !viewed);

  const [notifsPopover, setNotifsPopover] = useState(false);
  const handleBtnClick = async () =>
    grantNotificationPermission({ postNotifsSubscription });
  const toggleNotification = () => {
    setNotifsPopover(!notifsPopover);

    if (!notifsPopover) {
      const toUpdate = unviewedNotifisList.map(({ cuid }) => ({
        cuid,
        viewed: true,
        read: false
      }));

      if (data.length > 0) {
        bulkUpdateNotifsStatus(toUpdate);
      }
    }
  };
  const badgeCount =
    Notification.permission === "granted" ? unviewedNotifisList.length : 1;
  const handleNotifClick = ({ cuid, read, actionLink }) => () => {
    if (!read) {
      updateNotifsStatus({ cuid, read: true, viewed: true });
    }

    if (actionLink) {
      history.push(actionLink);
    }

    toggleNotification();
  };

  const NotificationList = () => {
    if (notifsList.length > 0) {
      return (
        <InfiniteScroller
          containerWidth={450}
          containerHeight={300}
          data={notifsList}
          ListRenderer={({ data }) => {
            return (
              <List
                className="notifications-list"
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => {
                  const actionLink = getNotifActionLink(item.metadata);
                  const finalClassname = `notification-item ${!item.read &&
                    "unread"}`;

                  return (
                    <List.Item key={item.cuid} className={finalClassname}>
                      <List.Item.Meta
                        title={item.title}
                        description={item.body}
                        onClick={handleNotifClick({ actionLink, ...item })}
                      />
                    </List.Item>
                  );
                }}
              />
            );
          }}
        />
      );
    }

    return (
      <div className="no-notification">
        <div className="no-notification-icon-wrapper">
          <Icon type="check-circle" className="no-notification-icon" />
        </div>
        <span>No notifications to show at the moment.</span>
      </div>
    );
  };

  const content = (
    <div className="notification-content">
      {process.env.ENV === "MAINTENANCE" ||
      process.env.ENV === "PRE_MAINTENANCE" ||
      Notification.permission === "granted" ||
      notificationCreated ? (
        <NotificationList />
      ) : (
        <div className="grant-access">
          <span>
            Please click this button to subscribe to our notifications and stay
            tuned
          </span>
          <br />
          <Button
            className="allow-notifications"
            icon="bell"
            label="Allow notifications"
            onClick={handleBtnClick}
          />
        </div>
      )}
    </div>
  );

  return (
    <Popover
      content={content}
      title="Notifications"
      trigger="click"
      visible={notifsPopover}
      className="popover-notifc"
      onVisibleChange={toggleNotification}
    >
      <Badge count={badgeCount}>
        <Button icon="bell" type="default" className="notifs-btn" />
      </Badge>
    </Popover>
  );
};

export default NotifsPopover;
