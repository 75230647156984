import axios from "./apiConfig";

export const getEntityCapTable = params =>
  axios.get("cap-tables/entity", { params });

export const getEntityShareholders = params =>
  axios.get("entity-shareholders", { params });

export const updateShareholder = (cuid, data) =>
  axios.patch(`entity-shareholders/${cuid}`, data);

export const deleteShareholder = (shareholderId, params) =>
  axios.delete(`entity-shareholders/${shareholderId}`, { params });

export const getShareClasses = params => axios.get("share-types", { params });

export const getShareClassesById = id => axios.get(`share-types/${id}`);

export const updateShareClassesById = (cuid, data) =>
  axios.patch(`share-types/${cuid}`, data);

export const postShareClasses = data => axios.post("share-types", data);

export const deleteShareClasses = id => axios.delete(`share-types/${id}`);

export const postShareholders = data => axios.post("entity-shareholders", data);
