import { AvatarUploader } from "../../../Shared/Avatar";
import InputRenderer from "../../../Shared/InputRenderer";

const CustomInputRenderer = props => {
  if (props.type === "avatar_uploader") {
    return <AvatarUploader {...props} />;
  }

  return <InputRenderer {...props} />;
};

export default CustomInputRenderer;
