import { connect } from 'react-redux';
import { getContractsPreview } from '#core/modules/contracts/actions';
import { fetchSingleIo } from '#core/modules/io/actions';
import Presentational from './Presentational';

const mapStateToProps = (state) => ({
  didFetch: state.io.didFetch,
  io: (state.io._data && state.io._data.io) || {},
  contracts: (state.contracts._data && state.contracts._data.contracts) || []
});

const mapDispatchToProps = (dispatch) => ({
  fetchSingleIo: (data) => dispatch(fetchSingleIo(data)),
  getContractsPreview: (data) => dispatch(getContractsPreview(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
