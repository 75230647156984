import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mapDealUpdateValues } from '#helpers/mappers/dealCreation';
import Form from './form/Presentational';
import { withPageTitle } from '../../Shared/hocs';
import { closedValues } from '#constants/io/closed';

const NotaryWrapper = ({
  clearState,
  getEntityMemberships,
  getEntityShareholders,
  entityId,
  putIoInfo,
  sharesDidFetch,
  shareTypes,
  getShareClasses,
  clearStateKeys,
  getEntity,
  fetchSingleIo,
  getCommits,
  ...props
}) => {
  const { id } = useParams();

  const [formattedShareTypes, setFormattedShareTypes] = useState([]);

  useEffect(() => {
    clearState('io');
    clearState('entities');
    clearState('commits');
    clearStateKeys('shares', 'shareholdersFetched');
    clearStateKeys('entities', 'membershipFetched');
    getEntity(entityId);
    getEntityMemberships({ id: entityId });
    getEntityShareholders({ entity_id: entityId, role: 'investor' });
    getShareClasses({ entity_id: entityId });
    fetchSingleIo(id);
    getCommits({ entity_id: entityId, investment_id: id });
  }, []);

  useEffect(() => {
    if (sharesDidFetch) {
      const frmtedShareTypes = shareTypes.reduce(
        (acc, { cuid, nominalValue } = {}) => ({
          ...acc,
          [cuid]: nominalValue
        }),
        {}
      );

      setFormattedShareTypes(frmtedShareTypes);
    }
  }, [sharesDidFetch]);

  const handleSubmit = (values) => {
    const payload = mapDealUpdateValues(values, false, true);
    const { ioColl: { data: { closed } = {} } = {} } = props;
    const finalClosed =
      closed < closedValues.NOTARY_DONE ? closedValues.NOTARY_DONE : closed;

    putIoInfo({ ioId: id, ...payload, closed: finalClosed });
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      shareTypes={formattedShareTypes}
      {...props}
    />
  );
};

export default withPageTitle('Notary documents')(NotaryWrapper);
