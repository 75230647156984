import logo from "../assets/images/logo.png";

export const investmentOpportunityTypes = {
  POOLED_EQUITY: "pooled_equity",
  UNPOOLED_EQUITY: "unpooled_equity",
  POOLED_DEBT: "pooled_debt",
  UNPOOLED_DEBT: "unpooled_debt",
  POOLED_HYBRID_CLA: "pooled_hybrid_cla",
  UNPOOLED_HYBRID_CLA: "unpooled_hybrid_cla",
  HYBRID_MEZZANINE: "hybrid_mezzanine",
  CONVERSION_POOL: "conversion_pool",
  POOLED_FUND: "pooled_fund",
  KISS: "pooled_kiss",
  SAFE: "pooled_safe",
  BSA_AIR: "pooled_bsa_air",
  CONVERTED_EQUITY: "converted_equity"
};

export const dealType = {
  EQUITY_FINANCING: "Equity financing",
  DEBT_FINANCING: "Convertible loan",
  CONVERTIBLE_LOAN: "Convertible loan"
};

export const legalDocsType = {
  SHAREHOLDER: "sha",
  LOAN: "loan",
  CLA: "cla"
};

export const dealRounds = {
  "Pre-Seed": "Pre-Seed",
  Seed: "Seed",
  bridge_financing: "Bridge Financing",
  Series_A: "Series A",
  Series_B: "Series B",
  Series_C: "Series C",
  Series_D: "Series D",
  Series_E: "Series E",
  Series_F: "Series F",
  other: "Other"
};

export const contractTypes = {
  SPA: "spa",
  CLA: "cla",
  IDI: "idi",
  ESOP: "esop",
  CSPA: "cspa",
  APPLICATION_TO_COMMERCIAL_REGISTER: "application-to-commercial-register",
  LEX_FRIEDRICH_LEX_COLLER_EQUITY: "lex-friedrich-lex-coller-equity",
  POA_ORDINARY: "poa-ordinary",
  PUBLIC_DEED_BOD: "public-deed-bod",
  PUBLIC_DEED_EGM: "public-deed-egm",
  REPORT_CAPITAL_INCREASE: "report-capital-increase",
  SUBSCRIPTION_FROM_LEVA: "subscription-from-leva",
  SUBSCRIPTION_RIGHT_WAIVER: "subscription-right-waiver",
  BOARD_REGULATIONS: "board-regulations",
  INVESTMENT_AGREEMENT: "investment-agreement",
  NDA: "nda",
  SHA: "sha",
  TERM_SHEET: "term-sheet-pooled-io",
  POOLED_IO_TERM_SHEET: "pooled-io-term-sheet",
  SYNDICATE_TERMS: "syndicate-terms",
  BALANCE_CONFIRMATION: "balance-confirmation",
  HANDELSREGISTERANMELDUNG: "handelsregisteranmeldung",
  SUBSCRIPTION_FORM_VERRECHNUNG: "subscription-form-verrechnung",
  SUBSCRIPTION_FORM_BAR: "subscription-form-bar",
  REPORT_CAPITAL_INCREASE_BAR: "report-capital-increase-bar",
  REPORT_CAPITAL_INCREASE_QUALIFIZIERT: "report-capital-increase-qualifiziert",
  ACCEPTANCE_DECLARATION: "acceptance-declaration",
  TERMSHEET_CLA: "term-sheet-cla",
  CAPITAL_CALL_NOTICE: "capital-call-notice",
  LIMITED_PARTNERSHIP_AGREEMENT: "limited-partnership-agreement",
  PRIVATE_PLACEMENT_MEMORANDUM: "private-placement-memorandum",
  ARTICLES_OF_ASSOCIATION: "articles-of-association",
  STATUTE: "statute",
  SAFE: "safe",
  KISS: "kiss",
  BSA_AIR: "bsa_air"
};

export const contractNames = {
  [contractTypes.SPA]: "Simple Partnership Agreement",
  [contractTypes.SPA_SUBSCRIPTION_AGREEMENT]: "Subscription Agreement",
  [contractTypes.CLA]: "Convertible Loan Agreement",
  [contractTypes.IDI]: "Direct Investment",
  [contractTypes.ESOP]: "ESOP",
  [contractTypes.CSPA]: "CSPA",
  [contractTypes.APPLICATION_TO_COMMERCIAL_REGISTER]:
    "Application to Commercial Register",
  [contractTypes.LEX_FRIEDRICH]: "Lex-Friedrich-Erklärung",
  [contractTypes.STAMPA]: "Stampa-Erklärung",
  [contractTypes.POA_ORDINARY]: "POA",
  [contractTypes.PUBLIC_DEED_BOD]: "Pubic Deed BOD",
  [contractTypes.PUBLIC_DEED_EGM]: "Pubic Deed EGM",
  [contractTypes.REPORT_CAPITAL_INCREASE]: "Report on the Capital Increase",
  [contractTypes.SUBSCRIPTION_FROM_LEVA]: "Subscription from Leva",
  [contractTypes.SUBSCRIPTION_RIGHT_WAIVER]: "Subscription Right Waiver",
  [contractTypes.BOARD_REGULATIONS]: "Board Regulations",
  [contractTypes.INVESTMENT_AGREEMENT]: "Investment Agreement",
  [contractTypes.NDA]: "Confidentiality & Non-Circumvent Agreement",
  [contractTypes.SHA]: "Shareholder Agreement",
  [contractTypes.TERM_SHEET_CLA]: "Term Sheet Convertible Loan",
  [contractTypes.TERM_SHEET_POOLED_IO]: "Term Sheet",
  [contractTypes.SYNDICATE_TERMS]: "Terms of Syndicate",
  [contractTypes.BALANCE_CONFIRMATION]: "Balance Confirmation",
  [contractTypes.HANDELSREGISTERANMELDUNG]: "Handelsregisteranmeldung",
  [contractTypes.SUBSCRIPTION_FORM_VERRECHNUNG]: "Subscription Form",
  [contractTypes.SUBSCRIPTION_FORM_BAR]: "Subscription Form BAR",
  [contractTypes.REPORT_CAPITAL_INCREASE_BAR]:
    "Report on the Capital Increase (1)",
  [contractTypes.REPORT_CAPITAL_INCREASE_QUALIFIZIERT]:
    "Report on the Capital Increase (2)",
  [contractTypes.ACCEPTANCE_DECLARATION]: "Acceptance Declaration",
  [contractTypes.SHARES_AND_BO]: "Share register and BO register",
  [contractTypes.INVESTMENT_CONFIRMATION]: "Investment Confirmation",
  [contractTypes.TRANSACTION_CONFIRMATION]: "Transfer Confirmation",
  [contractTypes.DEAL_CONFIRMATION]: "Transfer Confirmation",
  [contractTypes.CAPITAL_CALL_NOTICE]: "Capital Call Notice",
  [contractTypes.LIMITED_PARTNERSHIP_AGREEMENT]:
    "Limited Partnership Agreement",
  [contractTypes.PRIVATE_PLACEMENT_MEMORANDUM]: "Private Placement Memorandum",
  [contractTypes.ARTICLES_OF_ASSOCIATION]: "Articles of association",
  [contractTypes.STATUTE]: "Statute",
  [contractTypes.SAFE]: "SAFE Agreement",
  [contractTypes.KISS]: "KISS Agreement",
  [contractTypes.BSA_AIR]: "BSA AIR Agreement"
};

export const VAT_VALUE = 0.077;
export const VAT_NEW_VALUE = 0.081;

export const sampleDealObject = {
  name: "Leva Deal",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis diam non libero feugiat pretium. Donec pharetra turpis magna, eu elementum sapien lacinia ut. Maecenas pharetra ullamcorper mattis. Vivamus vitae nisi maximus, euismod libero in, accumsan elit. In hac habitasse platea dictumst. Curabitur interdum, turpis nec bibendum porta, ipsum diam iaculis justo, vitae pulvinar lacus ante eget tortor. Phasellus semper consectetur dolor, in convallis metus. Cras tortor velit, ornare sed erat et, commodo pellentesque nulla. Aenean eleifend vehicula elit sit amet bibendum.",
  previewImage: logo
};
