import { connect } from "react-redux";
import { clearState, clearStateData } from "#core/utils/helper_actions";
import { getPortfolioSyndicates } from "#core/modules/syndicates/actions";
import Presentational from "./Presentational";

const mapStateToProps = ({ io, entities, syndicate }) => {
  const { entitySelected: { industries = [] } = {} } = entities._data || {};

  return {
    loading: syndicate.isFetching,
    industries,
    syndicatesWhereUserIsAM: syndicate?._data?.syndicatesWhereUserIsAM
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: data => clearState(dispatch, data),
  clearStateData: data => clearStateData(dispatch, data),
  getPortfolioSyndicates: params => dispatch(getPortfolioSyndicates(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
