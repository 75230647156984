import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { useInterval } from "#helpers/hooks/interval";
import { saveForm } from "#helpers/mappers/form/storage";
import claTermsheetSchema from "#validators/cla/termsheet";
import { formatArrayKeys } from "#helpers/arrayFuncs";
import { globalChangeHandler } from "#helpers/inputHandlers";
import { claTsKeys } from "#constants/cla";
import { FormStepper, BasicFormWrapper } from "../../../Shared/Form";
import ModalWrapper from "../../../Shared/Modal";
import { Button } from "../../../Shared/Button";
import generateList from "./list";
import { sections, helpers } from "./sections";
import {
  handleInputChange,
  handleCheckboxInArray,
  handleInputInArray
} from "../../common/formHandler";
import CustomInputRenderer from "../../common/Inputs/CustomInputRenderer";
import CLAQuestionnaireSuccessView from "../../common/successViews/ClaTs";
import { setClaTsValues } from "./handlers";
import { setConvertibleNameExistValues } from "../../CLA/form/handlers";

const ClaFormContainer = ({
  userId,
  entityId,
  values,
  handleChange,
  setFieldValue,
  setFieldError,
  currencies,
  shareholdersColl,
  convertibles,
  buttonStates,
  handleSubmit,
  esopShares,
  errors,
  setValues,
  ioColl,
  shares,
  governingLaw,
  name,
  ioMemberships,
  allMemberships,
  commits = [],
  saveFormData,
  form,
  step,
  currPath,
  psopShares
}) => {
  const { id } = useParams();
  if (id) {
    useInterval(
      () => saveForm({ userId, entityId, values }),
      !buttonStates.success ? 5000 : null
    );
  }

  const handleSaveForm = vals => () => saveFormData(vals);
  const [state, setState] = useState({});

  const toggleModalState = () =>
    setState({ ...state, modalVisible: !state.modalVisible });

  useEffect(
    setClaTsValues({
      convertibles,
      allMemberships,
      shareholdersColl,
      id,
      step,
      ioColl,
      ioMemberships,
      commits,
      setValues,
      form,
      esopShares,
      psopShares
    }),
    [shareholdersColl.didFetch, allMemberships, esopShares]
  );

  useEffect(
    setConvertibleNameExistValues({
      convertibles,
      setFieldValue,
      name: values.name
    }),
    [values.name]
  );

  const { data: { cuid: createdIoCuid = "" } = {} } = ioColl;
  const {
    interestRate: interestRate = 0,
    maximumInterest: maximumInterest = 0,
    [claTsKeys.MONEY_RAISED]: moneyRaised = 0,
    safeHarborRule,
    [claTsKeys.INVESTORS_TABLE]: shareholders = [],
    [claTsKeys.INVESTOR_HAS_COMMITTED]: investorHasCommited = "no"
  } = values;

  let investingShareholders = [];
  if (shareholders) {
    investingShareholders = shareholders.filter(
      ({ select = false, amount = 0 }) => select && amount > 0
    );
  }
  const finalInterestRate =
    safeHarborRule === "yes" ? maximumInterest : interestRate;

  let annualInterestRate = (moneyRaised * finalInterestRate) / 100;

  if (investingShareholders.length > 0 && investorHasCommited === "yes") {
    let investorsAmount = 0;
    investingShareholders.forEach(({ amount = 0 }) => {
      investorsAmount += amount;
    });
    annualInterestRate = (investorsAmount * finalInterestRate) / 100;
  }

  const formikProps = { values, setFieldValue };
  const readableConvertibleTypes = formatArrayKeys(
    convertibles,
    "total",
    "name"
  );

  const inputHandlers = {
    checkboxHandler: globalChangeHandler(setFieldValue, values, "checkbox"),
    inputInArray: handleInputInArray(setFieldValue, values),
    checkboxInArray: handleCheckboxInArray(setFieldValue, values)
  };

  const fieldsList = generateList({
    values,
    currencies,
    convertibleTypes: readableConvertibleTypes,
    inputHandlers,
    formikProps,
    esopShares,
    name,
    shares,
    annualInterestRate,
    governingLaw,
    saveFormData: handleSaveForm,
    currPath,
    psopShares
  });

  const formArgs = {
    list: fieldsList,
    sections,
    inputEvents: {
      onChange: type =>
        handleInputChange({ handleChange, setFieldValue, values })(type)
    },
    values,
    CustomInputRenderer,
    clickableSection: true,
    buttonStates,
    handleSubmit,
    customClassName: "cla-termsheet-container",
    success: buttonStates.success,
    CustomSuccess: () =>
      CLAQuestionnaireSuccessView({ type: "ts", createdIoCuid }),
    validationSchema: claTermsheetSchema,
    setFieldError,
    errors,
    step,
    helpers: helpers({ toggleModalState })
  };

  const modalProps = {
    title: "Check our Convertible Loan Guide",
    visible: state.modalVisible,
    onCancel: toggleModalState,
    footer: null
  };

  return (
    <Fragment>
      <ModalWrapper {...modalProps}>
        <div className="convertible-loan-guide">
          <h1>Do you need support to make the right decisions?</h1>
          <h1>
            We can connect you with top-tier legal partners, ready to help you
            with the terms of your convertible loan agreement.
          </h1>
          <h1>
            We know that early-stage founders struggle with money until they
            close the first round. That's why we made sure to get you an
            exclusive discount.
          </h1>
          <div className="btn-container">
            <Button
              label={
                <a
                  href="https://askleva.typeform.com/to/IP8j5WD9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Talk to a legal expert
                </a>
              }
            />
          </div>
        </div>
      </ModalWrapper>{" "}
      <FormStepper {...formArgs} />
    </Fragment>
  );
};

ClaFormContainer.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  currencies: PropTypes.array,
  shareholdersColl: PropTypes.object,
  convertibles: PropTypes.array,
  buttonStates: PropTypes.object,
  handleSubmit: PropTypes.func,
  ioColl: PropTypes.object,
  setValues: PropTypes.func,
  allMemberships: PropTypes.array
};

export default BasicFormWrapper(ClaFormContainer);
