import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_ALL_COMMITS:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            commits: action.payload.data
          };
        }
      });

    case types.FETCH_ENTITY_CAPTABLE:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            captable: action.payload.data
          };
        }
      });

    case types.POST_COMMITMENT:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const { commits: currCommit = [] } = state._data || {};
          const newCommit = action.payload.data;

          return {
            ...state._data,
            commit: action.payload.data,
            commits: [...currCommit, newCommit]
          };
        }
      });
    case types.GET_PARTNERSHIP:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            partnership: action.payload.data
          };
        }
      });

    case types.PATCH_COMMITMENT:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { commits } = state._data || {};
          const { cuid, amount } = action.payload.data || {};

          return {
            ...state._data,
            commits: commits.map(item => {
              if (item.cuid === cuid) {
                return {
                  ...item,
                  amount
                };
              }

              return item;
            })
          };
        }
      });

    case types.UPDATE_COMMIT_STATUS:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { cuid, status } = action.payload.data;
          const commits = (state._data && state._data.commits) || [];

          return {
            ...state._data,
            commits: commits.map(item => {
              if (item.cuid === cuid) {
                return {
                  ...item,
                  status
                };
              }
              return item;
            })
          };
        }
      });
    case types.UPDATE_COMMIT_DATA_STATUS:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { cuid, requestType, ...response } = action.payload.data;
          const commits = (state._data && state._data.commits) || [];

          return {
            ...state._data,
            commits: commits.map(item => {
              if (item.cuid === cuid) {
                return {
                  ...item,
                  ...response,
                  status: requestType === "soft_to_real" ? 1 : item.status
                };
              }
              return item;
            }),
            commit: { cuid, ...response }
          };
        }
      });
    case types.DELETE_COMMIT:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          const currCommits = (state._data && state._data.commits) || [];

          return {
            ...state._data,
            commits: currCommits.filter(
              ({ cuid }) => cuid !== action.payload.data
            )
          };
        }
      });
    default:
      return state;
  }
};
export default reducer;
