import { Component } from "react";
import { Modal, Tabs } from "antd";
import queryString from "query-string";
import PropTypes from "prop-types";
import { reduceArrayByKeyToObject } from "#helpers/reducers";
import { roles } from "#constants/roles";
import { closedValues, closedLabels } from "#constants/io/closed";
import { withPageTitle, withContentFallback } from "../../../../../Shared/hocs";
import HistoryCardsWrapper from "../../../../../Shared/Cards/History/Wrapper";
import {
  CapTableModal,
  InviteInvestorsModal,
  TaxModal
} from "../../../../../Shared/Modals";
import DealTitle from "../../common/Title";
import ActionContainer from "../../common/ActionContainer";
import { Button } from "../../../../../Shared/Button";

const { confirm } = Modal;

class DealManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDashboard: this.props.location.pathname === "/dashboard",
      inviteModal: false,
      capTableModal: false,
      taxModal: false,
      tabKey: "0"
    };
  }

  componentDidMount() {
    const {
      clearState,
      selectedEntity,
      fetchAllIos,
      getPortfolio,
      getEntity,
      getSyndicates,
      getPortfolioSyndicates,
      clearStateData
    } = this.props;

    clearState("io");
    clearState("syndicate");

    if (this.state.isDashboard) {
      clearStateData("syndicate");
      getPortfolio();
      getPortfolioSyndicates();
    } else {
      if (selectedEntity.cuid) {
        getEntity(selectedEntity.cuid);
        getSyndicates({ entityId: selectedEntity.cuid });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      clearState,
      selectedEntity,
      getEntity,
      getSyndicates
    } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      clearState("io");

      this.setState({
        isDashboard: location.pathname === "/dashboard"
      });
    }

    if (
      !this.state.isDashboard &&
      prevProps.selectedEntity.cuid !== selectedEntity.cuid
    ) {
      getSyndicates({ entityId: selectedEntity.cuid });
      getEntity(selectedEntity.cuid);
    }
  }

  settingsList = ioId => [
    {
      label: "Approved by lawyers",
      onClick: this.handleIoUpdate({
        cuid: ioId,
        closed: closedValues.APPROVED_BY_LAWYERS
      }),
      roles: [roles.FOUNDER]
    },
    {
      label: "View cap table",
      onClick: this.handleCloseModal("capTableModal")(ioId),
      roles: [roles.FOUNDER]
    },
    {
      label: "Close deal",
      onClick: this.handleIoUpdate({
        cuid: ioId,
        closed: closedValues.CLOSED_BY_FOUNDER
      }),
      customStyle: "danger close-deal-menu-item",
      roles: [roles.FOUNDER, roles.ASSET_MANAGER]
    }
  ];

  handleTitleClick = cuid => () =>
    this.props.history.push(`/dashboard/deal_management/${cuid}`);

  mapItemForRender = key => {
    const { selectedEntity, ioIsUpdating, userRole } = this.props;
    const { isDashboard } = this.state;
    return ({
      cuid,
      name,
      currency: { shortName: valCurrency } = {},
      moneyCommitted,
      moneyInvested = 0,
      roundedMoneyCommitted,
      roundedMoneyInvested,
      roundedMoneySoftCommitted,
      fundingGoal,
      entity = {},
      closed,
      previewImage
    }) => {
      const percentage =
        key === closedLabels.PENDING
          ? (roundedMoneySoftCommitted / fundingGoal) * 100
          : ((roundedMoneyCommitted + roundedMoneyInvested) / fundingGoal) *
            100;

      const successPercentage = (roundedMoneyInvested / fundingGoal) * 100;

      const entityPreviewImage = isDashboard
        ? entity.previewImage
        : selectedEntity.previewImage;

      const value =
        key === closedLabels.PENDING
          ? roundedMoneySoftCommitted
          : roundedMoneyCommitted + roundedMoneyInvested;

      return {
        entity: isDashboard ? entity.name : selectedEntity.name,
        name,
        avatar: previewImage
          ? `${previewImage}?${Date.now()}`
          : entityPreviewImage,
        value,
        endValue: fundingGoal,
        valCurrency,
        percentage,
        successPercent: successPercentage,
        closed,
        status: "success",
        settingsList: this.settingsList(cuid).filter(({ roles: itemRoles }) =>
          itemRoles.includes(userRole)
        ),
        loading: ioIsUpdating === cuid,
        handleTitleClick: this.handleTitleClick(cuid)
      };
    };
  };

  handleIoUpdate = data => () => {
    this.props.updateIoClosingStatus(data);
  };

  parserFunc = key =>
    Object.keys(closedValues).find(item => closedValues[item] === key);

  handleCloseModal = modal => value => () => this.setState({ [modal]: value });

  handleTabChange = activeKey => this.setState({ tabKey: activeKey });

  handleEntityDelete = () => {
    confirm({
      title: "Are you sure you want to delete this entity?",
      maskClosable: true,
      okType: "danger",
      okText: "Yes",
      onOk: () => {
        this.props.deleteEntity({
          cuid: this.props.selectedEntity.cuid,
          entitiesList: this.props.entitiesList
        });
        setTimeout(() => {
          this.props.history.push("/dashboard");
        }, 200);
      }
    });
  };

  renderSyndicate = (syndicates, isDashboard, key) => () => {
    return syndicates.map(({ investments = [], ...syndicate }) => {
      return (
        <HistoryCardsWrapper
          inactive={key === closedLabels.CLOSED_BY_FOUNDER}
          isPublic={syndicate.public}
          capped={syndicate.capped}
          isDashboard={isDashboard}
          key={syndicate.cuid}
          id={syndicate.cuid}
          label={syndicate.name}
          data={investments}
          itemFormatter={this.mapItemForRender(key)}
        />
      );
    });
  };

  getLocalSyndicates = () => {
    const { syndicates = [], syndicatesWhereUserIsAM = [] } = this.props;

    const { isDashboard } = this.state;

    const localSyndicates = isDashboard ? syndicatesWhereUserIsAM : syndicates;
    const [softSynds, realSynds, inactiveSynds] = localSyndicates.reduce(
      ([softAcc, realAcc, inactiveAcc], item) => {
        const { investments, ...syndicateBody } = item;
        const [localSoft, localReal, localInactive] = [[], [], []];

        investments.map(investment => {
          if (investment.closed === closedValues.PENDING) {
            localSoft.push(investment);
          } else if (
            investment.closed === closedValues.EXECUTED ||
            investment.closed < closedValues.CLOSED_BY_FOUNDER
          ) {
            localReal.push(investment);
          } else {
            localInactive.push(investment);
          }
        });

        if (localSoft.length > 0) {
          softAcc.push({ ...syndicateBody, investments: localSoft });
        }

        if (localReal.length > 0) {
          realAcc.push({ ...syndicateBody, investments: localReal });
        }

        if (localInactive.length > 0) {
          inactiveAcc.push({ ...syndicateBody, investments: localInactive });
        }

        return [softAcc, realAcc, inactiveAcc];
      },
      [[], [], []]
    );
    return { softSynds, realSynds, inactiveSynds };
  };

  MainContent = () => {
    const { iosWhereUserIsAM, ios } = this.props;
    const { taxModal, inviteModal, capTableModal, isDashboard } = this.state;
    const { softSynds, realSynds, inactiveSynds } = this.getLocalSyndicates();
    const sectionLabels = {
      [closedLabels.PENDING]: "Collecting soft commitments",
      [closedLabels.EXECUTED]: "Signing and collecting funds",
      [closedLabels.CLOSED_BY_FOUNDER]: "Closed deals",
      [closedLabels.APPROVED_BY_LAWYERS]: "Approved by lawyers"
    };
    const source = isDashboard
      ? iosWhereUserIsAM.sort((a, b) => a.closed - b.closed)
      : Array.isArray(ios) && ios.sort((a, b) => a.closed - b.closed);

    const reducedData = reduceArrayByKeyToObject({
      array: source.filter(({ closed }) => closed <= closedValues.EXECUTED),
      key: "closed",
      keyParser: this.parserFunc
    });

    const { moneyInvested } =
      ios.find(({ cuid }) => cuid === capTableModal) || {};

    return (
      <div className="flex _column">
        <TaxModal
          visible={taxModal}
          handleClose={this.handleCloseModal("taxModal")(false)}
        />
        {inviteModal && (
          <InviteInvestorsModal
            visible
            handleClose={this.handleCloseModal("inviteModal")(false)}
            ioId={inviteModal}
          />
        )}
        {capTableModal && (
          <CapTableModal
            visible
            handleClose={this.handleCloseModal("capTableModal")(false)}
            ioId={capTableModal}
            ioMoneyInvested={moneyInvested}
          />
        )}
        {!isDashboard ? (
          <Tabs
            onChange={this.handleTabChange}
            activeKey={this.state.tabKey}
            type="card"
            className="deal-type-commitments-tabs-wrapper"
          >
            <Tabs.TabPane tab="Soft-commitment phase" key="0">
              {withContentFallback({
                customizedIcon: <div></div>,
                empty: softSynds.length === 0,
                customizedEmptyMsg: (
                  <div className="no-syndicates-wrapper">
                    <h3>
                      No syndicates are currently in the soft commitment phase
                    </h3>
                    <p>Check the investment or portfolio tabs above</p>
                  </div>
                )
              })(
                this.renderSyndicate(
                  softSynds,
                  isDashboard,
                  closedLabels.PENDING
                )
              )()}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Investment phase" key="1">
              {withContentFallback({
                customizedIcon: <div></div>,
                empty: realSynds.length === 0,
                customizedEmptyMsg: (
                  <div className="no-syndicates-wrapper">
                    <h3>No syndicates are currently in the investment phase</h3>
                    <p>Check the soft commitment or portfolio tabs above</p>
                  </div>
                )
              })(
                this.renderSyndicate(
                  realSynds,
                  isDashboard,
                  closedLabels.EXECUTED
                )
              )()}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Portfolio" key="2">
              {withContentFallback({
                customizedIcon: <div></div>,
                empty: inactiveSynds.length === 0,
                customizedEmptyMsg: (
                  <div className="no-syndicates-wrapper">
                    <h3>No syndicates have been closed yet</h3>
                    <p>Check the soft commitment or investment tabs above</p>
                  </div>
                )
              })(
                this.renderSyndicate(
                  inactiveSynds,
                  isDashboard,
                  closedLabels.CLOSED_BY_FOUNDER
                )
              )()}
            </Tabs.TabPane>
          </Tabs>
        ) : (
          <>
            {Array.isArray(softSynds) && softSynds.length > 0 && (
              <div className="deal-type-commitments-wrapper">
                <h2>Soft-commitment phase</h2>

                {this.renderSyndicate(
                  softSynds,
                  isDashboard,
                  closedLabels.PENDING
                )()}
              </div>
            )}
            {Array.isArray(realSynds) && realSynds.length > 0 && (
              <div className="deal-type-commitments-wrapper">
                <h2>Investment phase</h2>
                {this.renderSyndicate(
                  realSynds,
                  isDashboard,
                  closedLabels.EXECUTED
                )()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  render() {
    const { selectedEntity, syndicatesFetching, syndicates = [] } = this.props;
    const { isDashboard } = this.state;
    const { softSynds, realSynds, inactiveSynds } = this.getLocalSyndicates();
    const dataSource = isDashboard ? [...softSynds, ...realSynds] : syndicates;
    const allSyndicates = [...softSynds, ...realSynds, ...inactiveSynds];
    const MainWrapper = withContentFallback({
      customizedIcon: <div></div>,
      loading: syndicatesFetching,
      empty: dataSource.length === 0,
      customizedEmptyMsg: isDashboard ? (
        <>
          <h3>Here you will find all your open deals</h3>
          <p>
            You don't have any open deal at the moment, please come back later
          </p>
        </>
      ) : (
        <></>
      )
    })(this.MainContent)();

    return (
      <div className="deal-list-wrapper">
        {!isDashboard && (
          <ActionContainer
            buttons={[
              {
                label: "Edit",
                size: "large",
                onClick: () =>
                  this.props.history.push(
                    `/edit/entity/${this.props.selectedEntity.cuid}`
                  ),
                type: "secondary",
                ghost: true
              },
              inactiveSynds.length > 0 && {
                label: "Tax report",
                size: "large",
                onClick: () =>
                  this.setState({
                    taxModal: true
                  }),
                type: "primary",
                ghost: true,
                tooltip: "Manage tax report"
              },
              allSyndicates.length === 0 && {
                label: "Delete",
                size: "large",
                type: "danger",
                onClick: this.handleEntityDelete
              }
            ]}
            actionVisible={!isDashboard}
          >
            <div className="title-wrapper">
              <DealTitle
                title={selectedEntity.commonName || selectedEntity.name}
                details={selectedEntity}
                {...selectedEntity}
                className="standalone"
              />
            </div>
          </ActionContainer>
        )}
        {!isDashboard && (
          <div className="new-deal-btn-wrapper">
            <Button
              size="large"
              type="primary"
              label="Create new syndicate"
              href={"/create/deal"}
            />
          </div>
        )}
        {MainWrapper}
      </div>
    );
  }
}

DealManagement.propTypes = {
  clearState: PropTypes.func,
  selectedEntity: PropTypes.object,
  fetchAllIos: PropTypes.func,
  getPortfolio: PropTypes.func,
  getEntity: PropTypes.func,
  ioDidFetch: PropTypes.bool,
  ios: PropTypes.array,
  location: PropTypes.object,
  ioIsFetching: PropTypes.bool,
  iosWhereUserIsAM: PropTypes.array,
  history: PropTypes.object,
  ioIsUpdating: PropTypes.bool,
  userRole: PropTypes.string,
  putIoInfo: PropTypes.func,
  updateIoClosingStatus: PropTypes.func,
  entity: PropTypes.object
};

export default DealManagement;
