import { useState, useEffect } from "react";
import { Icon } from "antd";
import ioTypes from "#constants/io/ioTypes";
import { closedValues } from "#constants/io/closed";
import { calculateFinalAllocation } from "#helpers/mappers/commitments";
import { Button } from "../../../../../../../Shared/Button";
import ModalWrapper from "../../../../../../../Shared/Modal";
import { BasicForm } from "../../../../../../../Shared/Form";
import { formList } from "./components/list";
import { investmentOpportunityTypes } from "#constants/deal";
import MultipleSyndicates from "./components/MultipleSyndicates";
import SuccessScreen from "./components/SuccessScreen";
import RemainingShares from "./components/RemainingShares";

const adjustAmount = ({ amount, sharesPrice }) =>
  Math.ceil((Math.floor(amount / sharesPrice) * sharesPrice).toFixed(5) * 100) /
  100;

const getAllocationCorrectVal = (allocation, shares, newSharesPrice) => {
  const localAllocation = shares * newSharesPrice;
  return localAllocation - allocation;
};

const ExecuteDealModal = ({
  ioId,
  ioType,
  ioLoading,
  ioDidUpdate,
  ioIsUpdating,
  updateAndCloseDeal,
  onCancel,
  commits = [],
  fundingGoal,
  sharesPrice,
  currency,
  updateIoClosingStatus,
  roundedMoneySoftCommitted,
  visible,
  moneyCommitted,
  totalAmount,
  ...modalConfig
}) => {
  const [step, setStep] = useState(0);
  const [showMultiSyn, setShowMultiSyn] = useState(false);
  const [overSubscribedDeal, setOverSubscribedDeal] = useState(false);
  const [hasAllocationError, setHasAllocationError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [hasRemainingShares, setHasRemainingShares] = useState(false);
  const [localFinalAllocation, setLocalFinalAllocation] = useState(0);
  const [localNumberOfShares, setLocalNumberOfShares] = useState(0);
  const [localSharesPrice, setLocalSharesPrice] = useState(0);
  const [multipleSynClicked, setMultipleSynClicked] = useState(false);
  const differenceSoftCommitedAmount = +(
    roundedMoneySoftCommitted - localFinalAllocation
  ).toFixed(3);

  useEffect(() => {
    if (!visible) {
      setOverSubscribedDeal(false);
      setHasAllocationError(false);
      setHasRemainingShares(false);
      setShowMultiSyn(false);
      setShowSuccess(false);
      setStep(0);
    }
  }, [visible]);

  const customHook = ({ setValues, values, setFieldValue }) => {
    const finalAllocation = calculateFinalAllocation({
      commits,
      totalAllocation: fundingGoal,
      sharesPrice: values.preMoneySharePrice
    });
    useEffect(() => {
      if (
        !showMultiSyn &&
        multipleSynClicked &&
        (Number(localNumberOfShares) !== 0 ||
          Number(localSharesPrice) !== 0 ||
          Number(localFinalAllocation) !== 0)
      ) {
        setFieldValue("finalAllocation", localFinalAllocation);
        setFieldValue("preMoneySharePrice", localSharesPrice);
        setFieldValue("numberOfShares", localNumberOfShares);
        setMultipleSynClicked(false);
      }
    }, [showMultiSyn, finalAllocation]);

    useEffect(() => {
      setLocalFinalAllocation(values.finalAllocation);
      setLocalSharesPrice(values.preMoneySharePrice);
      setLocalNumberOfShares(values.numberOfShares);
      setFieldValue(
        "roundedMoneySoftCommitted",
        Number(roundedMoneySoftCommitted).toFixed(2)
      );
    }, [
      values.finalAllocation,
      values.preMoneySharePrice,
      values.numberOfShares,
      roundedMoneySoftCommitted
    ]);
  };

  useEffect(() => {
    if (ioDidUpdate) setShowSuccess(true);
    else setShowSuccess(false);
    if (hasRemainingShares && ioDidUpdate) setHasRemainingShares(false);
  }, [ioDidUpdate]);

  const adjustedLocalFinalAllocation = adjustAmount({
    amount: localFinalAllocation,
    sharesPrice: localSharesPrice || 1
  });

  const allocationLeft = getAllocationCorrectVal(
    localFinalAllocation,
    localNumberOfShares,
    localSharesPrice
  );

  const remainingSharesFinalAllocation =
    allocationLeft > 0
      ? localFinalAllocation + allocationLeft
      : adjustedLocalFinalAllocation;

  const checkOversubscribed = values => {
    const { finalAllocation } = values;
    if (totalAmount > finalAllocation + 1) {
      setOverSubscribedDeal(true);
      return true;
    } else {
      setOverSubscribedDeal(false);
      return false;
    }
  };

  const checkAllocation = values => {
    const {
      preMoneySharePrice = 0,
      numberOfShares = 0,
      finalAllocation = 0
    } = values;

    const sharesValue = numberOfShares * preMoneySharePrice;

    if (
      ioType === ioTypes.POOLED_EQUITY &&
      (isNaN(sharesValue) || sharesValue === 0)
    ) {
      setHasAllocationError(true);
      return true;
    }
    if (
      numberOfShares !== 0 &&
      preMoneySharePrice !== 0 &&
      ioType === ioTypes.POOLED_EQUITY &&
      (Number(finalAllocation + 0.1).toFixed(2) <
        Number(sharesValue.toFixed(2)) ||
        Number(finalAllocation - 0.1).toFixed(2) >
          Number(sharesValue.toFixed(2)))
    ) {
      setHasAllocationError(true);
      return true;
    }
    setHasAllocationError(false);
    return false;
  };

  const checkRemainingShares = values => {
    const { numberOfShares, preMoneySharePrice, finalAllocation } = values;
    if (
      Number(totalAmount.toFixed(2)) <
        Number(adjustedLocalFinalAllocation.toFixed(2)) &&
      roundedMoneySoftCommitted > 0 &&
      ioType === ioTypes.POOLED_EQUITY
    ) {
      setLocalNumberOfShares(numberOfShares);
      setLocalSharesPrice(preMoneySharePrice);
      setHasRemainingShares(true);
      return true;
    } else {
      setHasRemainingShares(false);
      return false;
    }
  };

  const handleSubmit = values => {
    updateIoClosingStatus({
      cuid: ioId,
      newSharesPrice: values.preMoneySharePrice || sharesPrice,
      newFundingGoal: Number(localFinalAllocation),
      closed: closedValues.EXECUTED,
      isPrecise: overSubscribedDeal
        ? false
        : Number(values.finalAllocation) ===
          roundedMoneySoftCommitted - differenceSoftCommitedAmount
    });
  };

  const handleClick = values => {
    if (step !== 1) {
      const overSubscribedOrAllocationError = checkAllocation(values);
      if (!overSubscribedOrAllocationError) setStep(step + 1);
    } else {
      const overSubscribed = checkOversubscribed(values);
      const remainingShares = checkRemainingShares(values);
      if (!overSubscribed && !remainingShares) handleSubmit(values);
    }
  };

  const handleOverSubcribedConfirm = values => {
    handleSubmit(values);
  };

  const handleBack = values => () => {
    if (step === 0 || overSubscribedDeal) {
      onCancel();
      setOverSubscribedDeal(false);
      setStep(0);
    } else setStep(step - 1);
  };

  const handleShowMultiSyn = values => () => {
    const { numberOfShares, finalAllocation, preMoneySharePrice } =
      values || {};
    setShowMultiSyn(!showMultiSyn);
    setMultipleSynClicked(true);
    if (numberOfShares !== 0 && !showMultiSyn) {
      setLocalFinalAllocation(finalAllocation);
      setLocalSharesPrice(preMoneySharePrice);
      setLocalNumberOfShares(numberOfShares);
    }
  };

  const handleRemainingSharesBtnClick = () => {
    updateIoClosingStatus({
      cuid: ioId,
      newSharesPrice: localSharesPrice,
      newFundingGoal: Number(remainingSharesFinalAllocation.toFixed(2)),
      closed: closedValues.EXECUTED,
      isPrecise: overSubscribedDeal
        ? false
        : Number(localFinalAllocation) ===
          roundedMoneySoftCommitted - differenceSoftCommitedAmount
    });
  };

  const modalTitle = () => {
    if (showMultiSyn) return "Multiple syndicates for the same deal?";
    if (overSubscribedDeal)
      return (
        <>
          <Icon type="warning" className="warning-icon" />
          The deal is over subscribed.
        </>
      );
    if (hasRemainingShares) return "Allocate the remaining shares";
    if (showSuccess) return "";
    if (step === 1) return "Make sure that all the details are correct.";
    return "You are now starting the investment phase: read carefully!";
  };

  const CustomFooter = ({
    values: {
      preCommitmentConfirmation = [],
      allocationRadio = "",
      amountRadio = "",
      ...formValues
    } = {}
  }) => {
    let primaryBtnLbl = "Next";
    if (step === 1) primaryBtnLbl = "Finish";
    if (overSubscribedDeal) primaryBtnLbl = "Confirm";

    const primaryBtnClickHandler = () => {
      if (overSubscribedDeal) {
        handleOverSubcribedConfirm(formValues);
        setOverSubscribedDeal(false);
      } else handleClick(formValues);
    };

    return (
      <div className="executed-custom-footer">
        <div className="form-btn-container">
          <Button
            label={step === 0 || overSubscribedDeal ? "Cancel" : "Back"}
            onClick={handleBack(formValues)}
            size="large"
            type="secondary"
            className="back-btn"
          />

          <Button
            label={primaryBtnLbl}
            size="large"
            onClick={primaryBtnClickHandler}
            loading={ioIsUpdating}
            disabled={step === 1 && preCommitmentConfirmation.length === 0}
            className="next-finish-btn"
          />
        </div>
      </div>
    );
  };

  const docsText = (finalDraft = false, agreement = false) => {
    let text = "";
    let extraText = "";
    if (ioType === investmentOpportunityTypes.POOLED_EQUITY) {
      text = `${finalDraft ? "final draft of the" : ""} subscription ${
        agreement ? "agreement" : "form"
      }`;
      extraText = " (not signed)";
    }
    if (ioType === investmentOpportunityTypes.POOLED_DEBT)
      text = "convertible loan agreement";
    if (ioType === investmentOpportunityTypes.SAFE) text = "SAFE";
    if (ioType === investmentOpportunityTypes.KISS) text = "KISS";
    if (ioType === investmentOpportunityTypes.BSA_AIR) text = "BSA AIR";

    return {
      text,
      extraText
    };
  };

  const formArgs = {
    fieldList: formList({
      step,
      currency,
      handleShowMultiSyn,
      hasAllocationError,
      ioType,
      overSubscribedDeal
    }),
    CustomFooter,
    handleSubmit,
    useCustomHook: customHook,
    onEnter: false
  };

  const mainContent = () => {
    if (showMultiSyn)
      return <MultipleSyndicates handleShowMultiSyn={handleShowMultiSyn} />;
    if (hasRemainingShares)
      return (
        <RemainingShares
          ioIsUpdating={ioIsUpdating}
          currency={currency}
          localSharesPrice={localSharesPrice}
          localNumberOfShares={localNumberOfShares}
          handleRemainingSharesBtnClick={handleRemainingSharesBtnClick}
          adjustedLocalFinalAllocation={remainingSharesFinalAllocation}
          allocationOnLeva={totalAmount}
          originalSharesPrice={sharesPrice}
          adjustAmount={adjustAmount}
        />
      );

    if (showSuccess) return <SuccessScreen handleOkay={onCancel} />;

    return (
      <>
        {step === 0 && (
          <div>
            <span>
              During the investment phase, investors will be asked to sign the
              Terms of Syndicate and wire the funds.
              <br />
              <br />
              <b>
                Before starting the investment phase: <br />
                1. Define the final investment terms with the target entity.
                <br />
                2. Upload the {docsText(true, true)?.text} in the data room
                {docsText()?.extraText}.
              </b>
            </span>
          </div>
        )}
        {step === 1 && !hasRemainingShares && !overSubscribedDeal && (
          <>
            <h3 className="_red">
              ATTENTION: READ CAREFULLY BEFORE CONFIRMING
            </h3>
            <p>
              This step cannot be reversed once you confirm it. The details
              below must match perfectly the values reported on the{" "}
              {docsText(true)?.text} that you will be signing with the target
              entity.
            </p>
          </>
        )}
        <BasicForm {...formArgs} />
      </>
    );
  };

  return (
    <ModalWrapper
      title={modalTitle()}
      visible={visible}
      {...modalConfig}
      width={850}
      footer={null}
      className="collect-modal"
      closable
      destroyOnClose
      onCancel={onCancel}
    >
      {mainContent()}
    </ModalWrapper>
  );
};

export default ExecuteDealModal;
