import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_NDAS:
      return baseReducer(action, state, actions.FETCH, {
        start: () => ({ ...state._data, ndaFetched: false }),
        success: () => {
          return {
            ...state._data,
            ndaFetched: true,
            list: action.payload.data
          };
        },
        fail: () => ({ ...state._data, ndaFetched: false })
      });

    case types.GET_NDA_PREVIEW:
      return baseReducer(action, state, actions.FETCH, {
        start: () => ({ ...state._data, ndaGenerated: false }),
        success: () => ({
          ...state._data,
          nda: action.payload.data,
          ndaGenerated: true
        }),
        false: () => ({ ...state._data, ndaGenerated: false })
      });

    case types.PUT_NDA_SIGNATURE:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { cuid, type } = action.payload.data;
          const currList = state._data.list || [];
          const signingKey =
            type === "user_signing"
              ? "userSignatureTimestamp"
              : "amSignatureTimestamp";

          return {
            ...state._data,
            list: currList.map(item => {
              if (item.cuid === cuid) {
                return { ...item, [signingKey]: new Date() };
              }
              return item;
            })
          };
        }
      });
    default:
      return state;
  }
};

export default reducer;
