import { connect } from "react-redux";
import { readCurrencies } from "#core/modules/currency/actions/read";
import { clearState } from "#core/utils/helper_actions";
import { confirmIoTransfer } from "#core/modules/io/actions/post";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { _data: { currencies = [] } = {} } = state.currency;

  const {
    _data: { entity: { name = "", address = {} } = {} } = {}
  } = state.entities;

  const entityAddress = `${address?.street}, ${address?.city}, ${address?.canton}, ${address?.country}, ${address?.postalCode}`;

  const {
    isCreating,
    didCreate,
    _data: {
      io: {
        confirmTransferTimestamp = "",
        currency = {},
        roundedMoneyInvested = 0,
        name: ioName
      } = {}
    } = {}
  } = state.io || {};

  const currencyShortname = currencies.find(
    ({ cuid }) => cuid === currency?.cuid
  )?.shortName;

  return {
    currencies,
    confirmTransferTimestamp,
    buttonStates: {
      loading: isCreating,
      success: didCreate
    },
    entityName: name,
    entityAddress,
    currency: currencyShortname,
    roundedMoneyInvested,
    ioName
  };
};

const mapDispatchToProps = dispatch => ({
  readCurrencies: () => dispatch(readCurrencies()),
  confirmIoTransfer: data => dispatch(confirmIoTransfer(data)),
  clearState: key => clearState(dispatch, key)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
