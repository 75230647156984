import { useState } from "react";
import SelectInput from "../../../../../Shared/SelectInput/ListSelect";
import BasicWrapper from "../../../../../Shared/BasicWrapper";
import ModalWrapper from "../../../../../Shared/Modal/";
import { Button } from "../../../../../Shared/Button";

const ConfirmationModal = ({
  ios,
  handleMembershipCreation,
  loading,
  accumulatedDeals,
  didCreate,
  ...modalConfig
}) => {
  const [state, setState] = useState({});

  const handleSelect = name => value => setState({ ...state, [name]: value });
  const flatAccDeals = accumulatedDeals.map(({ cuid }) => cuid);

  const formattedList = ios
    .filter(({ cuid }) => !flatAccDeals.includes(cuid))
    .map(({ name, cuid }) => ({
      label: name,
      value: cuid
    }));

  const handleSubmit = () => {
    handleMembershipCreation(state.deal);
  };

  return (
    <ModalWrapper
      {...modalConfig}
      footer={null}
      className="invite-confirmation-modal-0239"
    >
      <BasicWrapper>
        <h1>Please select the deal where you want to invite your investors</h1>
        <div className="deal-input-wrapper">
          <span>Deal: </span>
          <SelectInput
            list={formattedList}
            name="deal"
            value={state.deal}
            onChange={handleSelect}
          />
        </div>
        <div className="btn-container">
          <Button
            label="Invite"
            onClick={handleSubmit}
            loading={loading}
            success={didCreate}
          />
        </div>
      </BasicWrapper>
    </ModalWrapper>
  );
};

export default ConfirmationModal;
