import { NavLink } from "react-router-dom";
import { equityTs } from "#constants/io/equityPool";
import { basicOption, inputNumberFormatter } from "#helpers/general";
import { formatNumber } from "#helpers/number_format";
import { formatArrayKeys } from "#helpers/arrayFuncs";
import { claConvertingCols, convertibleCols } from "./tableCols";
import { confirmationPrompt } from "../../../common/prompts";

const generalInformation = values => [
  {
    title: "This is the number of shares you have allocated to the ESOP",
    input: {
      type: "number",
      name: equityTs.ESOP_SHARES_NUMBER,
      disabled: true,
      ...inputNumberFormatter("")
    }
  },
  Number(values[equityTs.ESOP_SHARES_NUMBER]) === 0
    ? {
        title:
          "Are you planning to establish an ESOP during this financing round?",
        input: {
          name: equityTs.ESOP_ESTABLISHMENT,
          type: "select_btn",
          options: [
            basicOption("yes", "Yes", {
              tooltip:
                "We currently can’t generate an ESOP. Please reach out to X, our partner law firm if you wish to have tailor made ESOP.  For more information reach out to hi@leva.pe"
            }),
            basicOption("no", "No")
          ]
        }
      }
    : null,
  values[equityTs.ESOP_ESTABLISHMENT] === "yes"
    ? {
        title:
          "What is the maximum percentage of the share capital post closing of the financing round to be used for the ESOP?",
        input: {
          type: "number",
          name: equityTs.ALLOCATED_SHARES_ESOP,
          required: true,
          min: 0,
          formatter: value => (value && value <= 100 ? `${value}%` : ""),
          parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), "")
        }
      }
    : null
];

const financial = (values, currencies, selectedCurrency = " ") => {
  return [
    {
      title: "In which currency are you raising money?",
      input: {
        type: "select_btn",
        name: equityTs.CURRENCY,
        options: formatArrayKeys(currencies, "cuid", "shortName")
      }
    },
    {
      title: "How much money are you raising in this round?",
      input: {
        type: "number",
        name: equityTs.AGREED_AMOUNT,
        formatter: value => {
          return value
            ? `${selectedCurrency} ${value}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                "'"
              )
            : value;
        },
        parser: value =>
          value.replace(new RegExp(`${selectedCurrency}\\s?|(\\'*)`, "g"), "")
      }
    },
    {
      title:
        "Shall the financing round be completed in one or multiple tranches?",
      input: {
        name: equityTs.SEVERAL_TRANCHES,
        type: "select_btn",
        options: [
          basicOption("no", "One tranche"),
          basicOption("yes", "Multiple tranches")
        ]
      }
    },
    values[equityTs.SEVERAL_TRANCHES] === "yes"
      ? {
          title: "What is the minimum investment amount for the first tranche?",
          input: {
            type: "number",
            name: equityTs.MIN_CAPITAL_INCREASE,
            formatter: value => {
              return value
                ? `${selectedCurrency} ${
                    value <= values[equityTs.AGREED_AMOUNT]
                      ? value
                      : values[equityTs.AGREED_AMOUNT]
                  }`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
                : value;
            },
            parser: value =>
              value.replace(
                new RegExp(`${selectedCurrency}\\s?|(\\'*)`, "g"),
                ""
              ),
            max: values[equityTs.AGREED_AMOUNT] - 1
          }
        }
      : null
  ];
};

const financialValuation = (values, selectedCurrency = "", handlers) => {
  let calculatedSharePrice;
  const esopShares =
    values[equityTs.ESOP_SHARES_NUMBER] > 0
      ? values[equityTs.ESOP_SHARES_NUMBER]
      : values[equityTs.ALLOCATED_SHARES_ESOP];

  if (values[equityTs.FULLY_DILUTED] === "yes") {
    calculatedSharePrice =
      values[equityTs.PRE_VALUE] / (esopShares + values.sharesNumber);
  } else {
    calculatedSharePrice = values[equityTs.PRE_VALUE] / values.sharesNumber;
  }

  return [
    {
      title: "At what pre-money valuation are you raising money?",
      input: {
        type: "number",
        name: equityTs.PRE_VALUE,
        ...inputNumberFormatter(selectedCurrency),
        required: true,
        min: 0
      }
    },
    {
      title: "Is the pre-money valuation fully diluted?",
      input: {
        name: equityTs.FULLY_DILUTED,
        ...confirmationPrompt
      }
    },
    values[equityTs.FULLY_DILUTED] === "yes" &&
    values.convertingInvestors &&
    values.convertingInvestors.length > 0
      ? {
          title:
            "Shall the effects of your outstanding convertible loans be included in the fully diluted valuation?",
          input: {
            name: equityTs.FULLY_DILUTED_CONVERTIBLE,
            ...confirmationPrompt
          }
        }
      : null,
    values[equityTs.FULLY_DILUTED_CONVERTIBLE] === "yes"
      ? {
          title:
            "This is the number of shares you've allocated to convertible loans:",
          input: {
            value: 0,
            disabled: true
          }
        }
      : null,

    values[equityTs.FULLY_DILUTED_CONVERTIBLE] === "yes" && {
      input: {
        type: "table_list",
        name: "formatedConvertibles",
        cols: convertibleCols(values, handlers, "formatedConvertibles")
      }
    },

    values[equityTs.FULLY_DILUTED_CONVERTIBLE] === "yes" && {
      title:
        "Which of the following Investors will convert their convertible loan in this equity financing round?",
      input: {
        type: "table_list",
        name: "convertingInvestors",
        cols: claConvertingCols(handlers)
      }
    },
    values[equityTs.FULLY_DILUTED_CONVERTIBLE] === "yes" && {
      showId: false,
      title: (
        <span>
          If you want to add an investor{" "}
          <NavLink to="/stakeholders">click here</NavLink>
        </span>
      ),
      className: "_hidden",
      input: {}
    },
    // values[equityTs.FULLY_DILUTED_CONVERTIBLE] === "yes" && {
    //   input: {
    //     type: "table_list",
    //     name: "formatedConvertibles",
    //     cols: convertibleCols(values, handlers)
    //   }
    // },
    ...(values[equityTs.ESOP_SHARES_NUMBER] > 0 &&
    values[equityTs.FULLY_DILUTED] === "yes"
      ? [
          {
            title:
              "Shall the effects of the ESOP be included in the fully diluted valuation?",
            input: {
              name: equityTs.FULLY_DILUTED_ESOP,
              ...confirmationPrompt
            }
          },
          ...(values[equityTs.FULLY_DILUTED_ESOP] === "yes"
            ? [
                {
                  title: "This is the number of shares allocated to the ESOP:",
                  input: {
                    name: equityTs.ALLOCATED_SHARES_ESOP,
                    value: formatNumber(esopShares),
                    disabled: true
                  }
                }
              ]
            : [])
        ]
      : []),
    {
      title:
        "Is the following number of your currently issued shares correct? Please double check with the entry of the commercial register.",
      input: {
        type: "number",
        name: "sharesNumber",
        formatter: value => formatNumber(value),
        required: true,
        disabled: true,
        min: 0
      }
    },
    {
      title: `This is the share price the equity investors will pay in ${
        values[equityTs.SERIES_ROUND]
      } financing round`,
      input: {
        type: "text",
        value: `${selectedCurrency} ${calculatedSharePrice.toFixed(2)}`,
        disabled: true
      }
    }
  ];
};

export default [generalInformation, financial, financialValuation];
