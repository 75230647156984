import { connect } from 'react-redux';
import { getEntityMemberships } from '#core/modules/entities/actions';
import {
  getIoMemberships,
  bulkCreateIoMemberships
} from '#core/modules/iomemberships/actions';
import { clearState } from '#core/utils/helper_actions';
import Presentational from './Presentational';

const mapStateToProps = (state) => {
  const {
    isFetching: entityIsFetching,
    _data: {
      entity: { cuid: entityId } = {},
      memberships: entityMemberships = []
    } = {}
  } = state.entities;
  const {
    isFetching: memberIsFetching,
    isCreating,
    didCreate,
    _data: { memberships: ioMemberships = [] } = {}
  } = state.ioMemberships;

  return {
    entityId,
    entityMemberships,
    ioMemberships,
    buttonStates: { loading: isCreating, success: didCreate },
    membershipsFetching: memberIsFetching || entityIsFetching
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEntityMemberships: (data) => dispatch(getEntityMemberships(data)),
  getIoMemberships: (data) => dispatch(getIoMemberships(data)),
  bulkCreateIoMemberships: (data) => dispatch(bulkCreateIoMemberships(data)),
  clearState: (key) => clearState(dispatch, key)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
