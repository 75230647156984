import { connect } from "react-redux";
import {
  postShareholders,
  updateShareholderById,
  getEntityCapTable
} from "#core/modules/shares/actions";
import { triggerSnackbar } from "#core/modules/snackbar/actions";
import {
  createEntityMembership,
  getEntityMemberships,
  updateEntityMembership
} from "#core/modules/entities/actions";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    entity: { cuid: entityId, shares: entityShares, name: entityName } = {},
    memberships: directors = []
  } = state.entities._data || {};
  const {
    _data: { convertibles = [] }
  } = state.convertible;
  const {
    _data: { shareholders = [], captable: { shares = [] } = {} } = {}
  } = state.shares;
  const { companyDetails } = state.entities._data || {};
  const { cuid: logedUserCuid = "" } = state.users._data || {};

  const allConvertibles = convertibles.map(convertible => {
    return {
      ...convertible,
      total: convertible.raisedAmount
    };
  });

  return {
    entityId,
    buttonStates: {
      loading:
        state.shares.isCreating ||
        state.entities.isCreating ||
        state.shares.isUpdating,
      success:
        state.shares.didCreate ||
        state.entities.didCreate ||
        state.shares.didUpdate ||
        state.entities.didUpdate
    },
    didCreate: state.shares.didCreate,
    didUpdate: state.shares.didUpdate,
    membershipDidCreate: state.entities.didCreate,
    membershipDidUpdate: state.entities.didUpdate,
    shares,
    companyDetails,
    entityShares,
    convertibles: allConvertibles,
    directors,
    shareholders,
    logedUserCuid,
    entityName
  };
};

const mapDispatchToProps = dispatch => ({
  postShareholders: data => dispatch(postShareholders(data)),
  createEntityMembership: data => dispatch(createEntityMembership(data)),
  getEntityMemberships: data => dispatch(getEntityMemberships(data)),
  clearState: key => clearState(dispatch, key),
  updateShareholderById: (cuid, data) =>
    dispatch(updateShareholderById(cuid, data)),
  updateEntityMembership: (cuid, data) =>
    dispatch(updateEntityMembership(cuid, data)),
  getEntityCapTable: params => dispatch(getEntityCapTable(params)),
  triggerSnackbar: snackbar => triggerSnackbar(dispatch, snackbar)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
