import { connect } from "react-redux";
import {
  generateTwoFactorAuth,
  completeTwoFactorAuth
} from "#core/modules/auth/actions/";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { _data: { auth2fa } = {} } = state.auth;
  return {
    auth2fa
  };
};

const mapDispatchToProps = dispatch => ({
  generateTwoFactorAuth: () => dispatch(generateTwoFactorAuth()),
  completeTwoFactorAuth: token => dispatch(completeTwoFactorAuth(token)),
  clearState: state => clearState(dispatch, state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
