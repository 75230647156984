import { useState } from 'react';
import { Tabs } from 'antd';
import { withPageTitle } from '../../../Shared/hocs/';
import { TabWrapper, OverviewTab, ShareTransfer, SplitTransfer } from './tabs';

const CapTablePresentational = () => {
  const [activeKey, setActiveKey] = useState('0');
  const handleTabChange = (key) => setActiveKey(key);
  const { TabPane } = Tabs;

  return (
    <div className="cap-table-wrapper">
      <Tabs
        onChange={handleTabChange}
        activeKey={activeKey}
        renderTabBar={TabWrapper}
      >
        <TabPane tab="Overview" key="0">
          {activeKey === '0' && <OverviewTab />}
        </TabPane>
        <TabPane tab="Share transfer" key="1">
          {activeKey === '1' && <ShareTransfer />}
        </TabPane>
        <TabPane tab="Split transfer" key="2">
          {activeKey === '2' && <SplitTransfer />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default withPageTitle('Cap Table')(CapTablePresentational);
