import { convertToBase64 } from "#helpers/convertBase64";
import { roles } from "#constants/roles";
import { mapFeesToCreation } from "#helpers/mappers/fees/create";
import { dealType, legalDocsType } from "#constants/deal";
import {
  mapEquityValues,
  mapInvestmentAgreementValues,
  mapNotaryFormValues
} from "#helpers/mappers/equity";
import { bankAccountKeys } from "#constants/io/bankAccount";
import { closedValues } from "#constants/io/closed";
import { readFees } from "./fees/read";

export const mapValuesToArgs = (values, termsheet, userRole, update) => {
  const fees = mapFeesToCreation(values);

  let payload = {
    name: values.deal_name,
    description: values.deal_description,
    minimal: values.minimal,
    fundingGoal: values.fundingGoal,
    currency: values.currency,
    leadInvestor: values.leadInvestor,
    investmentLevel: values.investmentLevel,
    type: values.type,
    dealType: values.dealType,
    fees,
    sharesPrice: values.sharesPrice || 1,
    vat: false,
    new_vat: values.vat,
    qualifiedInvestorsOnly: values.qualifiedInvestorsOnly === "yes"
  };

  if (!update) {
    payload = {
      ...payload,
      acceptsCommitments: true
    };
  }

  if (values.sharesNumber && !update) {
    payload.sharesNumber = values.sharesNumber;
  }

  if (values.investmentLevel === "other") {
    payload.investmentLevel = values.investmentLevel_desc;
  }

  if (values.companyValuation === "val_range") {
    payload.preMoneyValuation = {
      min: values.min_val,
      max: values.max_val
    };
  }

  if (values.companyValuation === "fixed_val") {
    payload.preMoneyValuation = values.companyValuation_num;
  }

  if (values.companyValuation === "valuation_unclear") {
    payload.preMoneyValuation = 0;
  }

  return payload;
};

export const mapDealToReadableFormValues = values => {
  const companyValuation_num = values.preMoneyValuation;
  let preMoneyVal = values.preMoneyValuation;
  let companyValuation = "fixed_val";

  if (typeof values.preMoneyValuation === "object") {
    preMoneyVal = {
      min_val: values.preMoneyValuation.min,
      max_val: values.preMoneyValuation.max
    };
    companyValuation = "val_range";
  }

  if (!preMoneyVal) {
    companyValuation = "valuation_unclear";
  }

  const readableFees = readFees(values.fees, values.vat, values.new_vat);
  let data = {
    deal_name: values.name,
    investmentLevel: values.investmentLevel,
    dealType: values.dealType,
    currency: values?.currency?.cuid,
    qualifiedInvestorsOnly: values?.qualifiedInvestorsOnly ? "yes" : "no",
    sharesPrice: values.sharesPrice,
    preMoneyVal,
    companyValuation,
    companyValuation_num,
    fundingGoal: values.fundingGoal,
    minimal: values.minimal,
    ...readableFees
  };

  if (typeof preMoneyVal === "object") {
    data = {
      ...data,
      ...preMoneyVal
    };
  }

  return data;
};

export const mapDealUpdateValues = (values, ia, notary) => {
  const payload = {};

  if (ia) {
    const iaAndSha = mapInvestmentAgreementValues(values);

    payload.iaAndSha = iaAndSha;
    payload.closed = closedValues.IA_DONE;
  }

  if (values[bankAccountKeys.IBAN]) {
    const bankAccount = {
      [bankAccountKeys.IBAN]: values[bankAccountKeys.IBAN],
      [bankAccountKeys.BIC]: values[bankAccountKeys.BIC],
      [bankAccountKeys.ACCOUNT_HOLDER]: values[bankAccountKeys.ACCOUNT_HOLDER]
    };

    payload.bankAccount = bankAccount;
  }

  if (notary) {
    const notaryVals = mapNotaryFormValues(values);

    payload.notary = notaryVals;
    payload.closed = closedValues.NOTARY_DONE;
  }

  return payload;
};

export const mapLegalDocs = async (type, attachments) => {
  if (type === dealType.EQUITY_FINANCING) {
    if (attachments[legalDocsType.SHAREHOLDER]) {
      const shaBase64 = await convertToBase64(
        attachments[legalDocsType.SHAREHOLDER]
      );
      return [
        {
          name: "Shareholder agreement",
          category: "legal",
          subCategory: legalDocsType.SHAREHOLDER,
          base64: shaBase64
        }
      ];
    }

    return [];
  }
  // else if (type === dealType.DEBT_FINANCING) {
  //   if (attachments[legalDocsType.LOAN]) {
  //     const loanBase64 = await convertToBase64(attachments[legalDocsType.LOAN]);
  //     return [
  //       {
  //         name: "Loan agreement",
  //         category: "legal",
  //         subCategory: legalDocsType.LOAN,
  //         base64: loanBase64
  //       }
  //     ];
  //   }

  //   return [];
  // }

  if (type === dealType.CONVERTIBLE_LOAN) {
    let claBase64 = null;
    let shaBase64 = null;
    const toUpload = [];

    if (attachments[legalDocsType.CLA]) {
      claBase64 = await convertToBase64(attachments[legalDocsType.CLA]);
    }

    if (attachments[legalDocsType.SHAREHOLDER]) {
      shaBase64 = await convertToBase64(attachments[legalDocsType.SHAREHOLDER]);
    }

    if (claBase64) {
      toUpload.push({
        name: "Convertible loan agreement",
        category: "legal",
        subCategory: legalDocsType.CLA,
        base64: claBase64
      });
    }

    if (shaBase64) {
      toUpload.push({
        name: "Shareholder agreement",
        category: "legal",
        subCategory: legalDocsType.SHAREHOLDER,
        base64: shaBase64
      });
    }

    return toUpload;
  }

  return [];
};

export const mapBoardRegulations = values => {
  const payload = {
    notary: {
      monthlyMeeting: values.boardMeeting === "Monthly",
      noticePeriod: values.boardMeetingPeriod,
      compensations: values.functionCompensation === "yes",
      materiallyInfluence: values.writingInform === "Materially Influence",
      managementMonthly: values.informBoard === "Monthly",
      articleNumber: values.articles
    },
    closed: closedValues.BOARD_REGULATIONS_DONE
  };
  return payload;
};
