import * as Yup from "yup";
import { returnError } from "../common";
import { iaKeys, shaKeys, iaAndSha } from "../../constants/io/equityPool";

Yup.addMethod(Yup.array, "checkIfTwo", function(message, mapper = a => a) {
  return this.test("checkIfTwo", message, function(list) {
    const singleDirector = list.filter(
      ({ PoA, signingAuthority }) => PoA && signingAuthority === "single"
    );
    const directors = list.filter(
      ({ PoA, signingAuthority }) => PoA && signingAuthority === "collective"
    );
    if (singleDirector.length > 0 && directors.length > 0) {
      return true;
    } else if (singleDirector.length > 0) {
      return true;
    } else if (directors.length > 1) {
      return true;
    }
    return false;
  });
});

const equityInvestmentAgreement = Yup.object().shape({
  [shaKeys.ENTITY_OBLIGATION_AMOUNT_OTHER]: Yup.number(),
  investorsWrittenConsent: Yup.array(),
  [iaKeys.BUSINESS_PLAN]: Yup.string().required(returnError("required")),
  [iaKeys.BUSINESS_PLAN_FILE]: Yup.boolean(),
  [iaKeys.BUSINESS_PLAN_UPLOAD]: Yup.string(),
  [iaKeys.COMERCIAL_REGISTER]: Yup.string(),
  [iaKeys.NEW_COMPANY_AUDITOR]: Yup.string().required(returnError("required")),
  [iaAndSha.DATA_ROOM]: Yup.string().required(returnError("required")),
  [iaKeys.DATA_ROOM_UPLOAD]: Yup.boolean(),
  [iaKeys.EMPLOYEE_LIST]: Yup.boolean(),
  [iaKeys.ENTITY_BUSINESS_AGREEMENT_AMOUNT]: Yup.string(),
  [iaKeys.ENTITY_BUSINESS_AGREEMENT_AMOUNT_OTHER]: Yup.number(),
  [iaKeys.ENTITY_CONVERTIBLE_OBLIGATION_AMOUNT]: Yup.number().required(
    returnError("required")
  ),
  [iaKeys.ENTITY_EQUITY_AMOUNT]: Yup.number().required(returnError("required")),
  [iaKeys.ENTITY_OBLIGATION_AMOUNT]: Yup.string().required(
    returnError("required")
  ),
  [iaKeys.ENTITY_OBLIGATION_AMOUNT_OTHER]: Yup.number(),
  [iaKeys.GUARANTEE_EXCESS]: Yup.string().required(returnError("required")),
  [iaKeys.GUARANTEE_EXCESS_OTHER]: Yup.number(),
  [shaKeys.IA_CONFIDENTIALITY]: Yup.string().required(returnError("required")),
  [shaKeys.INDEPENDENT_EXPERT_COUNTRY]: Yup.string().required(
    returnError("required")
  ),

  [shaKeys.INDEPENDENT_EXPERT_EMAIL]: Yup.string().required(
    returnError("required")
  ),
  [shaKeys.INDEPENDENT_EXPERT_CANTON]: Yup.string(),
  [shaKeys.INDEPENDENT_EXPERT_LAST_NAME]: Yup.string().required(
    returnError("required")
  ),
  [shaKeys.INDEPENDENT_EXPERT_NAME]: Yup.string().required(
    returnError("required")
  ),
  [shaKeys.INDEPENDENT_EXPERT_PLACE]: Yup.string().required(
    returnError("required")
  ),
  [shaKeys.INDEPENDENT_EXPERT_ZIP]: Yup.string().required(
    returnError("required")
  ),
  [shaKeys.INDEPENDENT_EXPERT_STATUS]: Yup.string().required(
    returnError("required")
  ),
  [shaKeys.INDEPENDENT_EXPERT_STREET]: Yup.string().required(
    returnError("required")
  ),
  [shaKeys.INTELLECTUAL_PROPERTY_RIGHTS]: Yup.boolean(),
  [shaKeys.INVESTOR_DICISION_MAKING_ATTACHMENTS]: Yup.boolean(),
  [shaKeys.INVESTOR_OVERVIEW]: Yup.array(),
  [iaKeys.MANAGEMENT_PL_DATE]: Yup.date().required(returnError("required")),
  [iaKeys.ORDINARY_COURT]: Yup.string().required(returnError("required")),
  [iaKeys.ORDINARY_COURT_OTHER]: Yup.string(),
  [iaKeys.SELECTION]: Yup.array(),
  signingDirector: Yup.array().checkIfTwo(({ originalValue = [] }) => {
    const singleDirector = originalValue.filter(
      ({ PoA, signingAuthority }) => PoA && signingAuthority === "single"
    );
    const singleNewDirector = originalValue.filter(
      ({ new: newChecked, signingAuthority }) =>
        newChecked && signingAuthority === "single"
    );
    const directors = originalValue.filter(
      ({ PoA, signingAuthority }) => PoA && signingAuthority === "collective"
    );
    if (
      singleDirector.length === 0 &&
      directors.length === 0 &&
      singleNewDirector.length === 0
    ) {
      return "Please select the director(s) as described in the intro text.";
    }
    // else if (singleDirector.length > 0 && directors.length > 0) {
    //   return "You selected a director which has collective signing authority and the one with single signing authority. To be able to continue, please select two directors with the collective signing authority or only one director with single signing authority.";
    // }
    else if (singleNewDirector.length >= 1 && singleDirector.length === 0) {
      return "Please check the PoA column next to the selected director(s) as described in the intro text.";
    } else if (directors.length < 2) {
      return "You selected a director which has collective signing authority. To be able to continue, please select one more director with the collective signing authority or switch to a director with single signing authority.";
    }
    return "Please select the director(s) as described in the intro text.";
  }),
  [iaKeys.CONSENT_NEEDED_TRANSFER]: Yup.string().required(
    returnError("required")
  ),
  unAuditedFsDatePrompt: Yup.string(),
  managementPlDatePrompt: Yup.string(),
  entityEquityAmountPrompt: Yup.string(),
  [iaKeys.UNAUDITED_FS_DATE]: Yup.date().required(returnError("required"))
});

export default equityInvestmentAgreement;
