import { Button } from "../../../Button";

const tokenUpdate = ({ handleClick }) => {
  return (
    <div className="updated-token">
      <h1>Your session has expired. Please log in again.</h1>
      <div className="btn-container">
        <Button label="Go to login page." onClick={handleClick} />
      </div>
    </div>
  );
};

export default tokenUpdate;
