import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import AddItemsModal from "./components/AddItems";
import { Button } from "../Button";

const SharesListInput = ({
  modalTitle,
  fields,
  formikProps,
  name,
  value,
  actionButtonTitle,
  itemRenderer,
  preEditHandler,
  modalProps,
  formArgs
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setFieldValue } = formikProps;
  const [editingType, setEditingType] = useState();

  const handleModalVisibility = open => () => {
    setModalOpen(open);
    setEditingType(null);
  };

  const handleEdit = editingValues => () => {
    if (typeof preEditHandler === "function") {
      const values = preEditHandler({ editingValues }) || editingValues;
      setEditingType(editingValues);
    } else {
      setEditingType(editingValues);
    }

    setModalOpen(true);
  };

  const handleAdd = () => {
    setModalOpen(true);
    setEditingType(null);
  };

  const handleDelete = (idx, _idx) => () => {
    if (idx !== undefined) {
      setFieldValue(
        name,
        value.filter(({ id }) => id !== idx)
      );
    } else {
      setFieldValue(
        name,
        value.filter(({ _id }) => _id !== _idx)
      );
    }
  };
  console.log("here");
  return (
    <Fragment>
      {!!modalOpen && (
        <AddItemsModal
          onCancel={handleModalVisibility(false)}
          visible={!!modalOpen}
          name={name}
          setFieldValue={setFieldValue}
          value={value}
          fields={fields}
          modalTitle={modalTitle}
          isEditing={!!editingType}
          initValues={editingType}
          formArgs={formArgs}
          {...modalProps}
        />
      )}
      <List
        dataSource={value}
        renderItem={({ _id, id, ...item }) => (
          <div>
            <List.Item
              key={id}
              actions={[
                <Button
                  icon="edit"
                  onClick={handleEdit({ _id, id, ...item })}
                />,
                <Button icon="close" onClick={handleDelete(id, _id)} />
              ]}
            >
              {itemRenderer(item)}
            </List.Item>
          </div>
        )}
      />
      <Button label={actionButtonTitle} icon="plus" onClick={handleAdd} />
    </Fragment>
  );
};

SharesListInput.propTypes = {
  modalTitle: PropTypes.string,
  fields: PropTypes.array,
  formikProps: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  actionButtonTitle: PropTypes.string,
  itemRenderer: PropTypes.any
};

export default SharesListInput;
