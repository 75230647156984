import { NameAvatar } from '../../../Avatar';

const investorsTable = [
  {
    title: 'Investor name',
    dataIndex: 'investor',
    render: (text) => <NameAvatar name={text} />
  }
];

export default investorsTable;
