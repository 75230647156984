self.addEventListener("message", event => {
  if (event.data && event.data.type === "SKIP_WAITING") {
    self.skipWaiting();
  }
});

self.addEventListener("push", event => {
  let msg = "Testing this";

  if (event.data) {
    try {
      msg = event.data.json();
    } catch (e) {
      msg = "";
    }
  }

  self.clients.matchAll().then(clients => {
    clients.forEach(client => client.postMessage({ msg }));
  });
});

const CACHE_NAME = "leva-cache-v1";
const urlsToCache = ["/dashboard/investors", "/index.html"];

self.addEventListener("activate", event => {
  console.log("Claiming control");
  return self.clients.claim();
});

self.addEventListener("install", event => {
  event.waitUntil(
    caches.open(CACHE_NAME).then(cache => {
      // Open a cache and cache our files
      return cache.addAll(urlsToCache);
    })
  );
});

self.addEventListener("fetch", event => {
  event.respondWith(
    fetch(event.request).catch(() => {
      return caches.match(event.request);
    })
  );
});
