import { Icon } from "antd";
import { Button } from "../../../../../../../../Shared/Button";

export default function SuccessScreen({ handleOkay }) {
  return (
    <div className="success-screen-wrapper">
      <Icon type="check-circle" className="check-icon" />
      <h1>The investment phase has started</h1>
      <h4 className="description">
        Tell your investors to enter their account and define their final
        investment amount on LEVA. You can monitor the capital collection via
        the deal dashboard
      </h4>
      <Button
        className="next-finish-btn"
        size="large"
        label="Okay"
        onClick={handleOkay}
      />
    </div>
  );
}
