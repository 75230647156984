import { connect } from "react-redux";
import { fetchSingleIo, bulkPostAttachments } from "#core/modules/io/actions";
import { clearState } from "#core/utils/helper_actions";
import { putIoInfo } from "#core/modules/io/actions/update";
import { getEntityMemberships } from "#core/modules/entities/actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { entity: { cuid: entityId } = {}, memberships = [] } =
    state.entities._data || {};
  const { cuid: userId } = state.users._data || {};
  const {
    _data: { io = {} } = {},
    didCreate,
    didUpdate,
    isCreating,
    isUpdating,
    didFetch: ioDidFetch
  } = state.io;
  const { list = [] } = state.shares._data || {};
  const {
    _data: {
      io: {
        termSheet: {
          severalTranches,
          investorDirectors,
          independentDirectors
        } = {}
      } = {}
    } = {}
  } = state.io;
  return {
    entityId,
    userId,
    ioDidFetch,
    io,
    buttonStates: {
      loading: isUpdating || isCreating,
      success: didUpdate && didCreate
    },
    memberships,
    severalTranches,
    investorDirectors,
    independentDirectors,
    shares: list
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSingleIo: data => dispatch(fetchSingleIo(data)),
  clearState: key => clearState(dispatch, key),
  bulkPostAttachments: data => dispatch(bulkPostAttachments(data)),
  getEntityMemberships: data => dispatch(getEntityMemberships(data)),
  patchIo: data => dispatch(putIoInfo(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
