import { Modal } from 'antd';

const ModalWrapper = ({ children, className, ...props }) => {
  const finalClassname = `modal-wrapper ${className || ''}`;

  return (
    <Modal className={finalClassname} {...props}>
      {children}
    </Modal>
  );
};

export default ModalWrapper;
