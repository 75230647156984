import { connect } from "react-redux";
import { getContractsPreview } from "#core/modules/contracts/actions";
import {
  fetchAllIos,
  updateIoClosingStatus,
  fetchSingleIo,
  patchAttachment,
  createDealAndBulkMembers
} from "#core/modules/io/actions";
import { clearState, clearStateDataByKey } from "#core/utils/helper_actions";
import { closedValues } from "#constants/io/closed";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { ios = [], io = {}, singleIoDidFetch } = state.io._data || {};
  const { isActive } = state.users._data || {};

  const claIos =
    (Array.isArray(ios) &&
      ios.filter(({ type }) => type === "unpooled_debt")) ||
    {};

  const { cuid: ioId } = claIos[claIos.length - 1] || {};

  const { entity = {} } = state.entities._data || {};
  const progress = {
    claTsFinished: io.cla && io.closed >= closedValues.TS_DONE,
    claFinished: io.cla && io.closed >= closedValues.CLA_DONE
  };

  return {
    entityId: entity.cuid,
    entity,
    ioId,
    io,
    didFetch: state.io.didFetch,
    isCreating: state.io.isCreating,
    contracts: (state.contracts._data && state.contracts._data.contracts) || [],
    contractsFetching: state.contracts.isFetching,
    contractsFetched: state.contracts.didFetch,
    progress,
    loading: state.io.isUpdating,
    singleIoDidFetch,
    canCreate: isActive
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAllIos: data => dispatch(fetchAllIos(data)),
  getContractsPreview: data => dispatch(getContractsPreview(data)),
  clearState: key => clearState(dispatch, key),
  clearStateDataByKey: (state, ...keys) =>
    clearStateDataByKey(dispatch, state, ...keys),
  updateIoClosingStatus: data => dispatch(updateIoClosingStatus(data)),
  fetchSingleIo: id => dispatch(fetchSingleIo(id)),
  patchAttachment: data => dispatch(patchAttachment(data)),
  createDealAndBulkMembers: data => dispatch(createDealAndBulkMembers(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
