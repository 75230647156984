export default {
  NOT_CONFIRMED: 0,
  CONFIRMED: 1
};

export const cLvl = {
  PENDING: 0,
  CONFIRMED: 1,
  ONBOARDED: 2,
  KYC_COMPLETED: 3,
  AML_COMPLETED: 4
};
