import { triggerSnackbar } from "#core/modules/snackbar/actions";
import axios from "../../../../api/apiConfig";
import types from "../action_types";
import * as userService from "../../../../api/userService";
import { store } from "../../../../src/store/configureStore";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import storageKeys from "../../../../src/constants/storage";
import { saveItem } from "../../../../src/utils/storage";

export const syncUserDetails = payload => {
  store.dispatch({
    type: types.SYNC_USER_DETAILS,
    payload
  });
};

export const updateUserSettings = settings => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_USER);
      const response = await userService.updateUser(settings);
      if (response.status === 204) {
        success(dispatch, types.UPDATE_USER, {
          data: settings
        });
        triggerSnackbar(dispatch, {
          variant: "success",
          message: "Successfully updated personal account information",
          placement: "bottomLeft",
          open: true
        });
      }
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_USER, {
        error: errorData
      });
    }
  };
};

export const putKycStatus = values => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_KYC_STATUS);
      const response = await userService.updateKycData(values);

      if (response.status === 204) {
        success(dispatch, types.UPDATE_KYC_STATUS, {
          data: values
        });
      }
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_KYC_STATUS, {
        error: errorData
      });
    }
  };
};

export const onboardUser = values => {
  return async dispatch => {
    try {
      start(dispatch, types.ONBOARD_USER);
      const response = await userService.onboardUser(values);

      if (response.status === 200) {
        const { token } = response.data || {};

        axios.defaults.headers.common.Authorization = `Bearer  ${token}`;

        saveItem(storageKeys.JWT, token);
        success(dispatch, types.ONBOARD_USER, {
          data: response.data
        });
      }
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.ONBOARD_USER, {
        error: errorData
      });
    }
  };
};
