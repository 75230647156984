import { formatArrayKeys } from "#helpers/arrayFuncs";
import { bankAccSchema } from "#validators/bankAccSchema";
import { List } from "antd";
import { countriesSortedByName } from "../../../Shared/Settings/countries";

export const listAddsSubmitHandler = ({
  setFieldValue,
  name,
  value = [],
  item
}) => {
  const newVal = [...value, { ...item, id: value.length }];
  setFieldValue(name, newVal);
};

export const listAddsEditHandler = ({
  setFieldValue,
  name,
  value = [],
  item
}) => {
  const newVal = value.map(({ id, ...origItem }) => {
    if (id === item.id) {
      return item;
    }

    return { id, ...origItem };
  });

  setFieldValue(name, newVal);
};

export const addressFields = [
  {
    title: "Address",
    className: "_fullwidth my-1",
    input: {
      type: "text",
      name: "address",
      required: true,
      placeholder: "Street and number"
    }
  },
  {
    className: "_width-50 my-1",
    input: {
      placeholder: "ZIP",
      type: "text",
      name: "postalCode",
      required: true
    }
  },
  {
    className: "_width-50 my-1",
    input: {
      placeholder: "City",
      type: "text",
      name: "city",
      required: true
    }
  },
  {
    className: "_fullwidth my-1",
    input: {
      placeholder: "Country",
      type: "autocomplete",
      name: "country",
      required: true,
      dataSource: countriesSortedByName,
      filterOption: true
    }
  }
];
export const bankAccountDetailsFields = ({
  name,
  currencies,
  currentVal = []
}) => [
  {
    title: "Bank accounts",
    className: "_fullwidth",
    input: {
      name: "bankAcc",
      type: "table_list",
      modalProps: {
        submitHandler: listAddsSubmitHandler,
        editHandler: listAddsEditHandler
      },
      formArgs: {
        validationSchema: bankAccSchema
      },
      actionButtonTitle: "Add bank account",
      itemRenderer: ({ beneficialOwner, bankAccNo, bankAccCurrency }) => (
        <List.Item.Meta
          title={`${beneficialOwner}`}
          description={`${bankAccNo} - ${currencies[bankAccCurrency]}`}
        />
      ),
      fields: [
        {
          title: "Beneficial owner",
          className: "_width-50",
          input: {
            name: "beneficialOwner",
            type: "text",
            value: name
          }
        },
        {
          title: "Name of the bank",
          className: "_width-50",
          input: {
            name: "bank",
            type: "text"
          }
        },
        {
          title: "Address of the bank",
          className: "_fullwidth",
          input: {
            name: "bankAddress",
            type: "text",
            placeholder: "Street and number"
          }
        },
        {
          title: "ZIP",
          className: "_width-50",
          input: {
            name: "bankZip",
            type: "text"
          }
        },
        {
          title: "City",
          className: "_width-50",
          input: {
            name: "bankCity",
            type: "text"
          }
        },
        {
          title: "Country",
          className: "_fullwidth",
          input: {
            placeholder: "Country",
            type: "autocomplete",
            name: "bankCountry",
            required: true,
            dataSource: countriesSortedByName,
            filterOption: true
          }
        },
        {
          title: "Account details",
          className: "_fullwidth _hidden",
          input: {}
        },
        {
          title: "Account number",
          className: "_width-50",
          input: {
            placeholder: "Account number",
            name: "bankAccNo",
            type: "text"
          }
        },
        {
          title: "IBAN",
          className: "_width-50",
          input: {
            name: "iban",
            type: "text"
          }
        },
        {
          title: "SWIFT / BIC",
          className: "_width-50",
          input: {
            name: "bankSwift",
            type: "text"
          }
        },
        {
          title: "Account currency",
          className: "_width-50",
          input: {
            name: "bankAccCurrency",
            type: "select",
            options: Object.keys(currencies)
              .map(key =>
                !currentVal.some(
                  ({ bankAccCurrency }) => bankAccCurrency === key
                )
                  ? {
                      value: key,
                      label: currencies[key]
                    }
                  : null
              )
              .filter(Boolean)
          }
        }
      ]
    }
  }
];
