import { Fragment } from "react";

const GuestLayout = ({ children }) => {
  return (
    <Fragment>
      {/* <Header />
      <div className="centralComponent">{children}</div>
      <Footer /> */}

      {children}
    </Fragment>
  );
};

export default GuestLayout;
