import { passwordRequirementsRegex } from "#helpers/general";
import * as Yup from "yup";
import { returnError } from "./common";

const registrationSchema = Yup.object().shape({
  email: Yup.string().required(returnError("required")),
  firstName: Yup.string().required(returnError("required")),
  lastName: Yup.string().required(returnError("required")),
  password: Yup.string()
    .min(6, returnError("min_password"))
    .matches(
      passwordRequirementsRegex,
      "Password must meet the above requirements"
    )
    .required(returnError("required")),
  passwordMatch: Yup.string()
    .oneOf([Yup.ref("password")], returnError("password_match"))
    .required(returnError("password_confirm")),
  tosAccepted: Yup.boolean()
    .required("Please confirm you accept our terms of service.")
    .oneOf([true], "Please confirm you accept our terms of service.")
});

export { registrationSchema };

export default registrationSchema;
