import PropTypes from 'prop-types';

const Title = (props) => {
  const { title, action } = props;
  return (
    <div className="section-title">
      <div className="title-container">{title}</div>
      {action && <div className="action-container">{action}</div>}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  action: PropTypes.any
};

export default Title;
