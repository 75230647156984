import { useEffect } from "react";
import claSchema from "#validators/management/cla";
import { globalChangeHandler } from "#helpers/inputHandlers";
import { mapClaArrayToReadable } from "#helpers/mappers/convertibles";
import { claTsKeys } from "#constants/cla";
import {
  handleCheckboxInArray,
  handleInputInArray
} from "../../../Forms/common/formHandler";
import CustomInputRenderer from "../CustomInputRenderer";

import { BasicForm } from "../../../Shared/Form";
import fieldList from "./list";

const MgmtFormWrapper = ({
  handleUpload,
  esopShares,
  psopShares,
  formValues,
  convertibles = [],
  registeredSeat,
  ...props
}) => {
  const customHook = ({ setValues, values, setFieldValue }) => {
    const formatedConvertibles = convertibles.map(
      ({ name: claName, cuid }) => ({
        name: claName,
        cuid
      })
    );

    useEffect(() => {
      if (formValues) {
        const formattedReadableoclEffectsInclusionArray = mapClaArrayToReadable(
          formatedConvertibles,
          formValues.oclEffectsInclusionArray
        );

        const formattedReadableminOclEffectsInclusionArray = mapClaArrayToReadable(
          formatedConvertibles,
          formValues.minOclEffectsInclusionArray
        );

        const formattedReadablematurityDateOclEffectsInclusionArray = mapClaArrayToReadable(
          formatedConvertibles,
          formValues.maturityDateOclEffectsInclusionArray
        );

        const formattedReadablesaleTransactionOclEffectsInclusionArray = mapClaArrayToReadable(
          formatedConvertibles,
          formValues.saleTransactionOclEffectsInclusionArray
        );

        const formattedReadableoutstandingClaEffectsIncludedFloorNFRArray = mapClaArrayToReadable(
          formatedConvertibles,
          formValues[
            claTsKeys
              .FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR
          ]
        );

        const formattedReadableoutstandingClaEffectsIncludedFloorSTArray = mapClaArrayToReadable(
          formatedConvertibles,
          formValues[
            claTsKeys
              .FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST
          ]
        );
        const formattedReadableoutstandingClaEffectsIncludedPreMoneyMDArray = mapClaArrayToReadable(
          formatedConvertibles,
          formValues[claTsKeys.CL_EFFECTS_MD_ARRAY]
        );

        const formattedReadableoutstandingClaEffectsIncludedFairMarketMDArray = mapClaArrayToReadable(
          formatedConvertibles,
          formValues[
            claTsKeys
              .FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
          ]
        );

        const formattedReadableoutstandingClaEffectsIncludedPostMoneyMDArray = mapClaArrayToReadable(
          formatedConvertibles,
          formValues[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]
        );

        setValues({
          ...formValues,
          isUpdating: true,
          oclEffectsInclusionArray:
            formattedReadableoclEffectsInclusionArray || formatedConvertibles,
          minOclEffectsInclusionArray:
            formattedReadableminOclEffectsInclusionArray ||
            formatedConvertibles,
          maturityDateOclEffectsInclusionArray:
            formattedReadablematurityDateOclEffectsInclusionArray ||
            formatedConvertibles,
          saleTransactionOclEffectsInclusionArray:
            formattedReadablesaleTransactionOclEffectsInclusionArray ||
            formatedConvertibles,
          [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR]:
            formattedReadableoutstandingClaEffectsIncludedFloorNFRArray ||
            formatedConvertibles,
          [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST]:
            formattedReadableoutstandingClaEffectsIncludedFloorSTArray ||
            formatedConvertibles,
          [claTsKeys.CL_EFFECTS_MD_ARRAY]:
            formattedReadableoutstandingClaEffectsIncludedPreMoneyMDArray ||
            formatedConvertibles,
          [claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]:
            formattedReadableoutstandingClaEffectsIncludedFairMarketMDArray ||
            formatedConvertibles,
          [claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]:
            formattedReadableoutstandingClaEffectsIncludedPostMoneyMDArray ||
            formatedConvertibles
        });
      } else {
        setFieldValue("isUpdating", false);
      }
    }, [formValues]);

    useEffect(() => {
      Object.keys(values).forEach(v => {
        if (Number.isNaN(values[v])) {
          setFieldValue(v, 0);
        }
      });
    }, [values]);

    useEffect(() => {
      setValues({
        triggerEvent: ["maturity_date"],
        maturityDatePreMoneyValPrompt: "yes",
        oclEffectsInclusionArray: formatedConvertibles,
        minOclEffectsInclusionArray: formatedConvertibles,
        maturityDateOclEffectsInclusionArray: formatedConvertibles,
        saleTransactionOclEffectsInclusionArray: formatedConvertibles,
        [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR]: formatedConvertibles,
        [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST]: formatedConvertibles,
        [claTsKeys.CL_EFFECTS_MD_ARRAY]: formatedConvertibles,
        [claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formatedConvertibles,
        [claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]: formatedConvertibles
      });
    }, []);

    useEffect(() => {
      const convertibleNameExists = convertibles
        .map(({ name: convertibleName }) => convertibleName)
        .includes(values.name);
      setFieldValue("convertibleNameExists", convertibleNameExists);
    }, [values.name]);
  };

  const inputHandlers = ({ setFieldValue, values }) => ({
    checkboxHandler: globalChangeHandler(setFieldValue, values, "checkbox"),
    inputInArray: handleInputInArray(setFieldValue, values),
    checkboxInArray: handleCheckboxInArray(setFieldValue, values)
  });

  const formArgs = {
    fieldList: fieldList(inputHandlers, esopShares, psopShares, registeredSeat),
    handleUpload,
    CustomInputRenderer: CustomInputRenderer(inputHandlers),
    ...props,
    useCustomHook: customHook,
    validationSchema: claSchema
  };

  return <BasicForm {...formArgs} />;
};

export default MgmtFormWrapper;
