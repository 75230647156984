import { formatNumber } from "#helpers/number_format";
import { closedValues } from "#constants/io/closed";
import ProgressWrapper from "../../../../../../../Shared/Progress";
import Tooltip from "../../../../../../../Shared/Tooltip";

const InlineStats = ({
  moneyInvested,
  moneyCommitted,
  moneySoftCommitted,
  fundingGoal,
  currency,
  closed,
  showRaw
}) => {
  const dealIsExecuted = closed >= closedValues.EXECUTED;
  const dealIsClosed = closed >= closedValues.CLOSED_BY_FOUNDER;

  const tooltipNote =
    "Note: If the share price has three decimals or more, the total might exceed the allocation on the subscription form by a few cents. This ensures the maximum number of shares can be purchased with the aggregated amount. The target will receive the exact subscription amount.";

  const getLabel = (labelText, condition) => {
    return condition ? (
      <Tooltip title={tooltipNote}>{labelText}</Tooltip>
    ) : (
      labelText
    );
  };

  const softCommitmentLabel = getLabel(
    "Soft committed: ",
    dealIsExecuted && moneySoftCommitted !== fundingGoal
  );
  const investedLabel = getLabel(
    "Invested: ",
    dealIsClosed && moneyInvested !== fundingGoal
  );

  const stats = [
    {
      label: investedLabel,
      indicator: "green",
      display: dealIsExecuted,
      value: formatNumber(moneyInvested, currency),
      wrapperClassname: "_bold"
    },
    {
      label: "Committed: ",
      indicator: "neutral",
      display: dealIsExecuted,
      value: formatNumber(moneyCommitted, currency)
    },
    {
      label: softCommitmentLabel,
      indicator: "violet",
      display: !dealIsExecuted || !!moneySoftCommitted,
      value: formatNumber(moneySoftCommitted, currency)
    }
  ];

  return (
    <div className="progress-container">
      <div className="funding-container">
        <span>Funding goal: {formatNumber(fundingGoal, currency)}</span>
      </div>
      {!dealIsExecuted || moneySoftCommitted ? (
        <ProgressWrapper
          className="soft-commit"
          strokeColor="green"
          showInfo={false}
          percent={
            closed >= closedValues.CLOSED_BY_FOUNDER
              ? 100
              : (moneySoftCommitted * 100) / fundingGoal
          }
          successPercent={
            closed >= closedValues.CLOSED_BY_FOUNDER
              ? 100
              : (moneySoftCommitted * 100) / fundingGoal
          }
        />
      ) : null}
      {closed >= closedValues.EXECUTED ? (
        <ProgressWrapper
          strokeColor="green"
          showInfo={false}
          percent={((moneyInvested + moneyCommitted) * 100) / fundingGoal}
          successPercent={
            closed >= closedValues.CLOSED_BY_FOUNDER
              ? 100
              : (moneyInvested * 100) / fundingGoal
          }
        />
      ) : null}
      <div className="inline-stats">
        {stats
          .filter(({ display }) => display)
          .map(({ label, indicator, value, wrapperClassname }) => (
            <div className="stat">
              <svg className="dot">
                <circle className={indicator} />
              </svg>
              <span className={wrapperClassname}>
                {label} {value}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default InlineStats;
