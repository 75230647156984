export default function ComingSoon() {
  return (
    <div className="comming-soon-wrapper">
      <h2>Coming Soon</h2>
      <p>
        Click{" "}
        <a target="_blank" href="https://askleva.typeform.com/to/BYQGAdZz">
          here
        </a>{" "}
        to join the waiting list
      </p>
    </div>
  );
}
