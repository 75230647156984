import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { mapCommitData } from "#helpers/mappers/commitments";
import { commitStatus } from "#constants/commit";
import { roles } from "#constants/roles";
import { InvestmentCard } from "../../../Shared/Cards";
import SharedTable from "../../../Shared/SharedTable/Presentational";
import { columns, mapToTableRow } from "./components/table";
import { withContentFallback } from "../../../Shared/hocs";

const PortfolioIndividual = ({
  getCommits,
  entityId,
  entityName,
  investorName,
  io = {},
  fetchSingleIo,
  industries,
  loading,
  commits,
  userRole
}) => {
  const { id } = useParams();
  useEffect(() => {
    fetchSingleIo(id);
    getCommits({
      entity_id: entityId,
      investment_id: id,
      search_key: userRole === roles.ASSET_MANAGER ? null : "investorName",
      search_value: userRole === roles.ASSET_MANAGER ? null : investorName
    });
  }, []);

  const formattedData = commits
    .filter(({ status }) => status === commitStatus.COMPLETED)
    .map(item => mapCommitData({ entityName, item, investment: io }));

  const MainContent = () => (
    <div className="portfolio-individual">
      <div className="card-container">
        <InvestmentCard
          portfolioDisplay
          {...io}
          industries={industries}
          disableHover
        />
      </div>
      <div className="table-container">
        <SharedTable columns={columns} dataSource={formattedData} />
      </div>
    </div>
  );

  const MainWrapper = withContentFallback({ loading })(MainContent);
  return <MainWrapper />;
};

export default PortfolioIndividual;
