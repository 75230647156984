import { roles } from "#constants/roles";

export const formSections = ({ userRole, editingMode, syndicateId = "" }) => {
  const isAm = userRole === roles.ASSET_MANAGER;
  let sections = [];

  if (!editingMode && syndicateId === "") {
    sections.push({
      id: 1,
      title: "Syndicate details",
      stepLabel: "Create your syndicate",
      details: "Data to show case your syndicate"
    });
  }

  sections = [
    ...sections,
    {
      id: 2,
      title: "Investment opportunity details",
      stepLabel: "Create your investment opportunity",
      details: "Data to show case your deal"
    },
    {
      id: 3,
      title: "Your fee structure",
      details: "Set your fee structure for this deal"
    },
    {
      id: 4,
      title: isAm ? "Deal documentation" : "Term sheet",
      stepLabel: isAm
        ? "You are uploading documents"
        : "You are uploading the term sheet",
      details: "Check and sign the deal documents"
    }
  ];

  return sections;
};
