import * as fieldList from './fields';
import { indexItem } from '../../common/prompts';

const getList = ({ composedInputHandlers, values = {}, shareCapital, calculatedSharePrice }) => {
  const {
    general: [financial, shares, stakeholders],
    shareholder,
    board
  } = fieldList;
  const formPropsBag = {
    handlers: composedInputHandlers,
    values
  };
  const indexedFinancial = financial.map(indexItem);
  const indexedShares = shares(calculatedSharePrice).filter(Boolean).map(indexItem);
  const indexedStakeholders = stakeholders.map(indexItem);
  const indexedShareholders = shareholder(formPropsBag, shareCapital)
    .filter(Boolean)
    .map(indexItem);
  const indexedBoard = board(formPropsBag).map(indexItem);

  return [
    {
      sectionId: 1,
      subSectionId: 1,
      items: indexedFinancial
    },
    {
      sectionId: 1,
      subSectionId: 2,
      items: indexedShares
    },
    {
      sectionId: 1,
      subSectionId: 3,
      items: indexedStakeholders
    },
    {
      sectionId: 4,
      items: indexedShareholders
    },
    {
      sectionId: 5,
      items: indexedBoard,
      stepTitle: 'Meeting of the board of directors'
    }
  ];
};

export default getList;
