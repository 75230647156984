import SharedTable from "../../../../../Shared/SharedTable/Presentational";
import { sharesTable } from "../tableColumns";

const SplitTransfer = () => {
  const data = [
    {
      name: "Jane Doe",
      date: "8 August 2019",
      amount: "100000",
      status: "progress"
    },
    {
      name: "Jane Doe",
      date: "8 August 2019",
      amount: "100000",
      status: "failed"
    },
    {
      name: "Jane Doe",
      date: "8 August 2019",
      amount: "100000",
      status: "progress"
    },
    {
      name: "Jane Doe",
      date: "8 August 2019",
      amount: "100000",
      status: "success"
    },
    {
      name: "Jane Doe",
      date: "8 August 2019",
      amount: "100000",
      status: "success"
    },
    {
      name: "Jane Doe",
      date: "8 August 2019",
      amount: "100000",
      status: "progress"
    },
    {
      name: "Jane Doe",
      date: "8 August 2019",
      amount: "100000",
      status: "progress"
    },
    {
      name: "Jane Doe",
      date: "8 August 2019",
      amount: "100000",
      status: "success"
    }
  ];

  return (
    <div className="share-transfer-wrapper">
      <SharedTable columns={sharesTable} dataSource={data} />
    </div>
  );
};

export default SplitTransfer;
