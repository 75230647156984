import InputRenderer from "../../../Shared/InputRenderer";
import QuestionsAdder from "./QuestionsAdder";
import QuestionsPreview from "./QuestionsPreview";
import SendCode from "./SendCode";
import VerifyCode from "./VerifyCode";

// FOR USE IN TS AND IA FORMS

const CustomInputRenderer = formikBag => props => {
  const { type } = props;
  
  if (type === "questions_adder")
    return <QuestionsAdder {...formikBag} {...props} />;

  if (type === "questions_preview")
    return <QuestionsPreview {...formikBag} {...props} />;

  if (type === "send-code-btn") return <SendCode {...formikBag} {...props} />;

  if (type === "verify-input-btn")
    return <VerifyCode {...formikBag} {...props} />;

  return <InputRenderer {...props} />;
};

export default CustomInputRenderer;
