import { Row, Col } from "antd";
import FooterBlock from "./Footerblock/Presentational";
import Logo from "../../../assets/illustrations/brand.svg";
import { useHistory } from "react-router-dom";

export default function Presentational() {
  const history = useHistory();

  const handleLogoClick = () => {
    history.push("/");
  };

  const footer = {
    solution: [
      { name: "For deal managers", link: "/deal-managers" }
      // { name: "For founders", link: "/founders" }
      // { name: "Venture syndicate", link: "/venture-syndicate" }
    ],
    // ecosystem: ["Customer", "Partner", "Press"],
    // about: [
    //   {
    //     name: 'Schedule demo',
    //     link: 'https://askleva.typeform.com/to/qQ7P2SHI',
    //     target: '_blank',
    //     onClick: googleAndLinkedInTracker
    //   },
    //   {
    //     name: 'Contact us',
    //     link: 'https://askleva.typeform.com/to/kE0IgN',
    //     target: '_blank',
    //   },
    //   {
    //     name: 'Get more info',
    //     link: 'https://askleva.typeform.com/to/wOVa1T',
    //     target: '_blank',
    //   },
    // ],
    contact: [
      {
        name: "FAQ",
        link:
          "https://levafundraising.notion.site/Frequent-Asked-Questions-Leva-540ccaf75e934decbac7faaef47316e4",
        target: "_blank"
      },
      {
        name: "Send us an email",
        link: "mailto:support@levamail.com",
        target: "_self"
      }
      // {
      //   name: "Call us: +41 33 533 33 77",
      //   link: "tel:+41-33-533-33-77",
      //   target: "_self"
      // }
    ],
    resources: [
      {
        name: "Impressum",
        link: "/impressum"
      },
      {
        name: "Cookie policy",
        link: "/doc_viewer?doc=/docs/cookiepolicy.pdf"
      },
      {
        name: "Cookie settings",
        link: "/cookies"
      }
    ],
    legal: [
      { name: "Risk warning", link: "/doc_viewer?doc=/docs/riskwarning.pdf" },
      {
        name: "Disclaimer terms of use",
        link: "/doc_viewer?doc=/docs/termsofwebsiteuse.pdf"
      },
      {
        name: "Privacy policy",
        link: "/doc_viewer?doc=/docs/privacypolicy.pdf"
      }
    ]
  };

  return (
    <Col span={20} className="footer-container">
      <Row>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <div className="logo">
            <img
              width="120"
              onClick={handleLogoClick}
              src={Logo}
              alt="leva-logo"
            />
          </div>
        </Col>
        {/*<Col xs={24} sm={12} md={4} lg={4} xl={4}>
          <FooterBlock header="Solutions" items={footer.solution} />
        </Col>*/}
        {/* <Col xs={24} sm={12} md={4} lg={4} xl={4}>
        <FooterBlock header="Ecosystem" items={footer.ecosystem} />
      </Col> */}
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <FooterBlock header="Contacts" items={footer.contact} />
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <FooterBlock header="Resources" items={footer.resources} />
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <FooterBlock header="Legal" items={footer.legal} />
        </Col>
      </Row>
    </Col>
  );
}
