import { useState } from 'react';
import { globalChangeHandler } from '#helpers/inputHandlers';
import { FormStepper, BasicFormWrapper } from '../../../../Shared/Form';

import fieldList from './items';
import sectionList from './items/sections';

const FormContainer = ({
  values,
  errors,
  handleChange,
  handleUpload,
  setFieldValue,
  handleSubmit,
  buttonStates
}) => {
  const SuccessView = () => (
    <div className="questionnaire-success">
      {' '}
      <h3>Application successfully received.</h3>
      <span>
        Click <a href="/">here</a> to go to the dashboard.
      </span>
    </div>
  );

  const formArgs = {
    sections: sectionList,
    list: fieldList(values),
    values,
    errors,
    inputEvents: {
      onUpload: handleUpload(setFieldValue),
      onChange: (type) => {
        return type === 'text' ||
          type === 'textarea' ||
          type === 'select_btn' ||
          type === 'radio'
          ? handleChange
          : globalChangeHandler(setFieldValue, values, type);
      }
    },
    success: buttonStates.success,
    CustomSuccess: SuccessView,
    buttonStates,
    handleSubmit
  };

  return <FormStepper {...formArgs} />;
};

export default BasicFormWrapper(FormContainer);
