import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};

const formReducer = (state = initState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.SAVE_FORM_DATA:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data,
            ...action.payload.data
          };
        }
      });
    case types.GET_FORM_DATA:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data
          };
        }
      });
    default:
      return state;
  }
};

export default formReducer;
