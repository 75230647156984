import { formatNumber } from "#helpers/number_format";

export const captableInvCols = [
  {
    title: "Full name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "C",
    dataIndex: "c",
    key: "c",
    render: value => (value === 0 ? 0 : formatNumber(value))
  },
  {
    title: "C+",
    dataIndex: "c1",
    key: "c1",
    render: value => (value === 0 ? 0 : formatNumber(value))
  },
  {
    title: "P",
    dataIndex: "p",
    key: "p",
    render: value => (value === 0 ? 0 : formatNumber(value))
  },
  {
    title: "Total number of shares",
    dataIndex: "sharesNumber",
    key: "sharesNumber",
    render: value => (value === 0 ? 0 : formatNumber(value))
  },
  {
    title: "Ownership percentange",
    dataIndex: "totalSharesPercen",
    key: "totalSharesPercen",
    render: value => `${value}%`
  },
  {
    title: "Diluted",
    dataIndex: "diluted",
    key: "diluted",
    render: _ => "N/A"
  }
];

export const captableClaCols = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Amount",
    dataIndex: "total",
    key: "total",
    render: value => (value === 0 ? 0 : `CHF ${formatNumber(value)}`)
  },
  {
    title: "Discount",
    dataIndex: "discount",
    key: "discount",
    render: value => `${value}%`
  },
  {
    title: "Cap",
    dataIndex: "valuationCap",
    key: "valuationCap",
    render: value => (value === 0 ? 0 : `CHF ${formatNumber(value)}`)
  },
  {
    title: "Shares price",
    dataIndex: "sharesPrice",
    key: "sharesPrice",
    render: value => (value === 0 ? 0 : `CHF ${formatNumber(value)}`)
  },
  {
    title: "Shares converted",
    dataIndex: "sharesConverted",
    key: "sharesConverted",
    render: value => (value === 0 ? 0 : formatNumber(value))
  }
];
