import { Row } from "antd";
import Logo from "./Logo";
import costEfficient from "../../../../assets/illustrations/legal/cost-efficient-icon.png";
import fastProcess from "../../../../assets/illustrations/legal/fastprocess-icon.png";
import convenient from "../../../../assets/illustrations/legal/convenient-icon.png";

export default function Presentational() {
  const logos = [
    {
      content: fastProcess,
      text: "Rapid deal setup",
      details: "Launch your deal in just 5 minutes"
    },
    {
      content: costEfficient,
      text: "Cost efficiency",
      details: "Start a syndicate for as low as 1'000.-"
    },
    {
      content: convenient,
      text: "Seamless management",
      details: "Effortlessly fund and oversee your deals online"
    }
  ];
  return (
    <div className="your-benefits-logolist-wrapper">
      {logos.map(logo => {
        return <Logo logo={logo} />;
      })}
    </div>
  );
}
