import { connect } from "react-redux";
import {
  getEntityMemberships,
  getEntityShareholders
} from "#core/modules/entities/actions";
import { getAllMembers } from "#helpers/general";
import { createIo, fetchSingleIo } from "#core/modules/io/actions";
import { putIoInfo } from "#core/modules/io/actions/update";
import { getFormData, saveFormData } from "#core/modules/form/actions";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { entity: { cuid: entityId, ...entityData } = {}, memberships = [] } =
    state.entities._data || {};
  const { cuid: userId } = state.users._data || {};
  const {
    isCreating,
    isUpdating,
    didCreate,
    didUpdate,
    didFetch: ioDidFetch,
    _data: { io } = {}
  } = state.io;
  const {
    isUpdating: entityUpdating,
    didCreate: entityDidCreate
  } = state.entities;
  const { _data: { shareholders = [] } = {}, didFetch } = state.shares || {};
  const { _data: { io: { cla } = {} } = {} } = state.io;
  const {
    entity: { employeeParticipationPlan: { esopShares = 0 } = {} }
  } = state.entities._data || {};
  const { _data: formData = {} } = state.form || {};

  const allMemberships = getAllMembers({ memberships, shareholders });

  return {
    entityId,
    entityData,
    userId,
    esopShares,
    buttonStates: {
      loading: isCreating || isUpdating || entityUpdating,
      success: didCreate || didUpdate || entityDidCreate
    },
    cla,
    memberships,
    shareholdersColl: {
      data: shareholders,
      didFetch
    },
    ioColl: {
      didFetch: ioDidFetch,
      data: io
    },
    allMemberships,
    formData
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  getEntityMemberships: data => dispatch(getEntityMemberships(data)),
  createIo: data => dispatch(createIo(data)),
  putIoInfo: data => dispatch(putIoInfo(data)),
  fetchSingleIo: id => dispatch(fetchSingleIo(id)),
  getEntityShareholders: data => dispatch(getEntityShareholders(data)),
  saveFormData: data => dispatch(saveFormData(data)),
  getFormData: () => dispatch(getFormData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
