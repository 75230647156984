import { AvatarUploader } from '../../../Avatar';
import InputRenderer from '../../../InputRenderer';

const CustomInputRenderer = (props) => {
  if (props.type === 'avatar_uploader') {
    return <AvatarUploader {...props} />;
  }

  return <InputRenderer {...props} />;
};

export default CustomInputRenderer;
