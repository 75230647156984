import { useState } from "react";
import { Form, List } from "./Views";
import ModalWrapper from "../../Modal";
import { Button } from "../../Button";

const AddMemberPresentational = ({
  visible,
  handleClose,
  ioId,
  title,
  entityId,
  withNda
}) => {
  const [state, setState] = useState({ view: 1 });

  const handleStateUpdate = (key, value) => () =>
    setState({ ...state, [key]: value });

  const buttonProps =
    state.view === 1
      ? { label: "Onboard new investor", onClick: handleStateUpdate("view", 2) }
      : {
          label: "Invite existing investors",
          onClick: handleStateUpdate("view", 1)
        };

  return (
    <ModalWrapper
      visible={visible}
      onCancel={handleClose}
      title={title}
      footer={null}
      destroyOnClose
      width={700}
    >
      {state.view === 1 ? (
        <List
          handleStateUpdate={handleStateUpdate}
          entityId={entityId}
          ioId={ioId}
          visible={visible}
          handleClose={handleClose}
          onboardInviteBtn={buttonProps}
        />
      ) : (
        <Form
          entityId={entityId}
          handleClose={handleClose}
          ioId={ioId}
          okButtonLabel="Invite"
          withNda={withNda}
          onboardInviteBtn={buttonProps}
        />
      )}
    </ModalWrapper>
  );
};

export default AddMemberPresentational;
