import { connect } from "react-redux";
import { clearState } from "#core/utils/helper_actions";
import { getSingleVoting } from "#core/modules/voting/actions";
import { roles } from "#constants/roles";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { cuid: userId } = state.users._data || {};
  const { _data: { assembly = {} } = {}, isFetching, didFetch } = state.voting;
  const { entity: { userRole } = {} } = state.entities._data || {};
  const isAm = userRole === roles.ASSET_MANAGER;
  return {
    loading: false,
    isAm,
    userId,
    votingColl: {
      data: assembly,
      loading: isFetching,
      success: didFetch
    }
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  getSingleVoting: params => dispatch(getSingleVoting(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
