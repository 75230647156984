import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useInput } from "#helpers/hooks/input";
import { convertToBase64 } from "#helpers/convertBase64";
import { allowedAttachTypes } from "#constants/attachmentsSubTypes";
import {
  dealAttachmentCategories,
  dealAttachmentSubCategories,
  ioAttachmentCategoriesLabels
} from "#constants/io/ioTypes";
import ModalWrapper from "../../Modal";
import InputContainer from "../../Input";
import { DraggerUploader } from "../../Uploader";
import SelectInput from "../../SelectInput/ListSelect";
import SwitchWrapper from "../../Switch";
import { removeEmojis } from "#helpers/attachments";

const AddAttachment = ({
  modalOpen,
  postAttachment,
  updateAttachment,
  toggleModal,
  ioId,
  attachLoading,
  attachSuccess,
  selectedAttach = {},
  clearState,
  bulkPostAttachments,
  ...modalProps
}) => {
  const { cuid, name = "" } = selectedAttach;
  const [state, setState] = useState({
    downloadable: true,
    name
  });

  const isUpdateMode = modalOpen === "update";

  useEffect(() => {
    if (modalOpen) {
      setState({ ...state, ...selectedAttach });
    }
  }, [modalOpen]);

  useEffect(() => {
    if (attachSuccess && !!modalOpen) {
      setTimeout(() => {
        clearState("io");
        toggleModal();
        setState({});
      }, 3000);
    }
  }, [attachSuccess]);

  const handleUpload = (_, { file }) => {
    if (isUpdateMode) {
      setState({
        ...state,
        attachment: file.originFileObj,
        name: file.originFileObj.name
      });
    } else {
      const currAttachments = state.attachments || [];
      const fileExists = currAttachments.find(
        ({ uid }) => file && file.uid === uid
      );

      if (!fileExists) {
        setState({
          ...state,
          attachments: [
            ...currAttachments,
            { ...file, percent: 100, status: "done" }
          ],
          name: file.originFileObj.name
        });
      }
    }
  };

  const handleModalOk = () => {
    if (isUpdateMode) {
      updateAttachmentFunc();
    } else {
      addAttachment();
    }
  };

  const updateAttachmentFunc = async () => {
    const {
      name,
      attachment,
      category = "io-doc",
      subCategory = "io-doc"
    } = state;

    updateAttachment({
      cuid,
      ioId,
      attachment: {
        name,
        category,
        subCategory,
        base64: await convertToBase64(attachment)
      }
    });
  };

  const addAttachment = async () => {
    const { attachments, category, subCategory } = state;
    const formattedAttachments = await Promise.all(
      attachments.map(async ({ originFileObj: file }) => {
        return {
          name: removeEmojis(file.name.replace(/\.[^/.]+$/, "")),
          category,
          subCategory,
          base64: await convertToBase64(file),
          downloadable: !!state.downloadable
        };
      })
    );

    bulkPostAttachments({ ioId, attachments: formattedAttachments });
  };

  const handleSelect = name => value => setState({ ...state, [name]: value });
  const handleSwitch = checked => setState({ ...state, downloadable: checked });
  const handleChange = ({ target: { name, value } }) =>
    setState({ ...value, [name]: value });

  const modalTitle =
    modalOpen === "update" ? "Update your attachment" : "Add new attachment";

  const buttonProps = {
    loading: attachLoading,
    disabled: modalOpen !== "update" && !state.category
  };
  const okBtnDisabled = !state.attachment || !state.attachments;
  const fileList = isUpdateMode
    ? [{ ...state.attachment, percent: 100, status: "done" }]
    : state.attachments;
  const allowedTypes = [...allowedAttachTypes, ".jpg", ".jpeg", ".png"];
  const categoriesBlacklist = [
    dealAttachmentCategories["SYNDICATE-TERMS"],
    dealAttachmentCategories.SPA,
    dealAttachmentCategories["SPA-SUBSCRIPTION-AGREEMENT"]
  ];

  const attachmentCategories = Object.keys(dealAttachmentCategories)
    .map(key =>
      !categoriesBlacklist.includes(dealAttachmentCategories[key])
        ? {
            value: dealAttachmentCategories[key],
            label: ioAttachmentCategoriesLabels[dealAttachmentCategories[key]]
          }
        : null
    )
    .filter(Boolean);

  const attachmentSubCategories = Object.keys(
    dealAttachmentSubCategories
  ).map(key => ({ value: key, label: dealAttachmentSubCategories[key] }));

  const canSetDownloadableDsbld =
    !state.attachments ||
    state.attachments.some(({ type }) => type !== "application/pdf");
  return (
    <ModalWrapper
      visible={!!modalOpen}
      onOk={handleModalOk}
      onCancel={toggleModal}
      okButtonProps={{ ...buttonProps }}
      title={modalTitle}
      destroyOnClose
      {...modalProps}
    >
      <div className="add-attach-container">
        <span>Please select attachment category here: </span>
        <SelectInput
          name="category"
          list={attachmentCategories}
          onChange={handleSelect}
          value={state.category}
        />
        {state.category === "legal" && (
          <>
            <span>Please select attachment sub-category here: </span>
            <SelectInput
              name="subCategory"
              list={attachmentSubCategories}
              onChange={handleSelect}
              value={state.subCategory}
            />
          </>
        )}
        <SwitchWrapper
          label="Downloadable"
          onChange={handleSwitch}
          name="downloadable"
          value={state.downloadable}
          disabled={canSetDownloadableDsbld}
        />
        <DraggerUploader
          onUpload={handleUpload}
          name="attachment"
          accept={allowedTypes.join(", ")}
          multiple={!isUpdateMode}
          showUploadList={{
            showPreviewIcon: false,
            showDownloadIcon: false,
            showRemoveIcon: false
          }}
        />
        {attachSuccess && (
          <span className="success">
            Attachment(s) successfully{" "}
            {modalOpen === "update" ? "updated" : "uploaded"}. This window will
            be automatically closed soon.
          </span>
        )}
      </div>
    </ModalWrapper>
  );
};

AddAttachment.propTypes = {
  modalOpen: PropTypes.any,
  postAttachment: PropTypes.func,
  updateAttachment: PropTypes.func,
  toggleModal: PropTypes.func,
  ioId: PropTypes.string,
  attachLoading: PropTypes.bool,
  attachSuccess: PropTypes.bool,
  selectedAttach: PropTypes.string,
  bulkPostAttachments: PropTypes.func,
  clearState: PropTypes.func
};

export default AddAttachment;
