import { InputNumber } from "antd";
import { handleNumberChange } from "#helpers/inputHandlers";

const InputNumberWrapper = ({
  wrapperClassname = "",
  name,
  value,
  onChange,
  formatter,
  strict = false,
  ...props
}) => {
  const finalClassname = `leva-input-wrapper ${wrapperClassname}`;

  const handleChange = handleNumberChange(onChange, name);

  const textCharsFormatter = val => {
    return val ? val.replace(/[^,;|^0-9.]+/g, "") : val;
  };

  const finalFormatter = val => {
    if (formatter) {
      return formatter(textCharsFormatter(val));
    }
    return textCharsFormatter(val);
  };

  return (
    <div className={finalClassname}>
      <InputNumber
        size="large"
        name={name}
        value={value}
        onChange={handleChange}
        addonBefore="CHF"
        {...props}
        formatter={strict ? finalFormatter : formatter}
        type="text"
        min="0"
      />
    </div>
  );
};

export default InputNumberWrapper;
