import { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Menu, Icon, Divider } from "antd";
import { closedValues } from "#constants/io/closed";
import AvatarWrapper from "../../../Avatar";

const MenuTitle = ({
  icon: IconWrapper,
  title,
  containerClassName = "menu-item-container",
  link = "",
  divider,
  id,
  className,
  onClick,
  disabled,
  entityId,
  ios = [],
  showIcon,
  absoluteLink,
  ...props
}) => {
  const selected = entityId === id;
  const filteredIos = ios.filter(
    ({ closed }) => closed < closedValues.CLOSED_BY_FOUNDER
  );
  const subtitle = () => {
    if (filteredIos.length === 1) {
      return filteredIos[0].name;
    } else if (filteredIos.length > 0) {
      return `${filteredIos.length}  deals`;
    }

    return "";
  };

  const IconComponent =
    typeof IconWrapper === "string" ? (
      <AvatarWrapper src={IconWrapper} shape="square" />
    ) : (
      <Icon component={() => <IconWrapper />} className="menu-item-icon" />
    );

  const container = (
    <Fragment>
      <div className={containerClassName}>
        <div className="inner-container">
          {showIcon && IconWrapper && IconComponent}
          <div className="info">
            <span className="menu-item-name">{title}</span>
            {selected && (
              <span className="menu-item-subtitle">{subtitle()}</span>
            )}
          </div>
        </div>
      </div>
      {(divider || showIcon) && <Divider className="divider" />}
    </Fragment>
  );

  const renderLink = () => {
    if (absoluteLink) {
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {container}
        </a>
      );
    }

    return (
      <Link to={link} className="menu-link">
        {container}
      </Link>
    );
  };

  return (
    <Menu.Item
      key={id}
      className={className}
      disabled={disabled}
      onClick={onClick || (() => {})} // work around to prevent some console errors that appear on mouse enter / click / leave
      onItemHover={() => {}}
      {...props}
    >
      {link ? renderLink() : container}
    </Menu.Item>
  );
};

MenuTitle.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.any,
  containerClassName: PropTypes.string,
  link: PropTypes.string,
  divider: PropTypes.bool,
  id: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  entityId: PropTypes.string,
  ios: PropTypes.array
};

export default MenuTitle;
