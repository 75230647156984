import { useState } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { Button } from "../Button";
import { clearAdvertisingCookies } from "#helpers/general";
import { useEffect } from "react";

const WITHOUT_ADVERTISING = "without-advertising";

const Cookies = ({ visible, relative, location, type = "create" }) => {
  const [cookies, setCookie] = useCookies(["cookie-policy-consent"]);
  const [cookiesUpdated, setCookiesUpdated] = useState(false);

  useEffect(() => {
    if (
      cookies["cookie-policy-consent"] === WITHOUT_ADVERTISING ||
      cookies["cookie-policy-consent"] === "false"
    ) {
      setTimeout(() => {
        clearAdvertisingCookies();
      }, 3000);
    }
  }, []);

  const handleCookies = value => () => {
    const currentDate = new Date();
    const expirationDate = new Date();

    expirationDate.setFullYear(currentDate.getFullYear() + 1);

    setCookie("cookie-policy-consent", value, {
      expires: expirationDate
    });
    setCookiesUpdated(true);
    if (value === false) clearAdvertisingCookies();
  };

  const handleRefuseAdvertisingCookies = () => {
    clearAdvertisingCookies();
    handleCookies(WITHOUT_ADVERTISING)();
  };

  const finalClassname = `cookies-consent-container ${
    relative ? "relative" : ""
  }`;

  const shouldShow =
    !location.includes("doc_viewer") && !location.includes("cookies");

  const content = {
    create: [
      <h1>Cookie Policy Information</h1>,
      <span>
        To deliver, maintain, and improve our services we use Cookies and
        similar technologies. You can change your selection at any time on our{" "}
        <a href="/cookies" target="_blank" rel="noopener noreferrer">
          Cookie settings page
        </a>
        .
      </span>
    ],
    update: [
      <h1>Welcome to our Cookie settings!</h1>,
      <span>You can change your cookies choices here.</span>,
      <span>
        As mentioned, to deliver, maintain, and improve our services we use
        Cookies and similar technologies.{" "}
      </span>
    ]
  };
  if (
    visible ||
    (shouldShow &&
      (cookies["cookie-policy-consent"] === undefined ||
        cookies["cookie-policy-consent"] === null))
  ) {
    return (
      <div className={finalClassname}>
        {content[type]}
        <span>
          For more information, read our{" "}
          <a
            href="/doc_viewer?doc=/docs/privacypolicy.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="/doc_viewer?doc=/docs/cookiepolicy.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Cookie Policy
          </a>
          .
        </span>
        <div className="btn-container">
          <Button
            label="I accept all cookies"
            className="accept"
            onClick={handleCookies(true)}
            size="large"
          />
          <Button
            label="I refuse advertising cookies"
            className="refuse-advertising"
            onClick={handleRefuseAdvertisingCookies}
            size="large"
          />
          <Button
            label="I refuse all cookies"
            className="reject"
            size="large"
            onClick={handleCookies(false)}
          />
        </div>
        {cookiesUpdated && (
          <h3 id="successfully-updated">Cookies consent has been updated.</h3>
        )}
      </div>
    );
  }
  return null;
};

Cookies.propTypes = {
  visible: PropTypes.bool,
  relative: PropTypes.bool,
  location: PropTypes.string,
  type: PropTypes.string
};

export default Cookies;
