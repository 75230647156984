import format from "date-fns/format";
import { Link } from "react-router-dom";
import { ioAttachmentCategoriesLabels } from "#constants/io/ioTypes";
import {
  deriveFileExt,
  getMissingAttachmentsLabel,
  returnExtIcon
} from "#helpers/attachments";
import { Icon as BaseIcon, Icon } from "antd";
import { ActionDropdown } from "../../../../../Shared/Dropdown";
import { Button } from "../../../../../Shared/Button";
import SwitchWrapper from "../../../../../Shared/Switch";
import TooltipWrapper from "../../../../../Shared/Tooltip";

const DocumentsDrawer = ({
  title,
  list = {},
  actionList,
  titleAction,
  handleAttachmentUpdate,
  isPrivileged,
  dealName,
  docConstraints = [[], false, false]
}) => {
  const renderDocItem = (item, index) => {
    const {
      cuid,
      name,
      contractName,
      createdAt,
      updatedAt,
      url,
      isBase64,
      type,
      ext,
      downloadable,
      showActions = true
    } = item;

    const { 0: derivedExt } = deriveFileExt(url) || {};
    const finalExt = ext || derivedExt; // to handle manually passed file extensions
    const Icon = returnExtIcon(finalExt);
    const isPdf = derivedExt === ".pdf";
    const isDoc = [".doc", ".docx"].includes(finalExt);
    const finalName = name || contractName;
    const localDealName = dealName.replace(/[\s.]/g, '-');
    const downloadName = `${finalName} ${localDealName ? ` - ${localDealName}`: ""}`

    const generateViewLink = () => {
      if (!downloadable && (type === "contract" || isBase64) && !isDoc) {
        return (
          <Link to={{ pathname: "/doc_viewer", state: { data: url } }}>
            {name}
          </Link>
        );
      } else if (
        (!downloadable || (downloadable === undefined && isPdf)) &&
        !isDoc
      ) {
        return (
          <Link to={`/doc_viewer?doc=${encodeURIComponent(url)}`}>{name}</Link>
        );
      }

      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          download={downloadName}
        >
          {name}
        </a>
      );
    };

    const getCreateAt = () => {
      if (createdAt) {
        if (createdAt.length === 10)
          return createdAt.indexOf("-") > -1
            ? createdAt.replaceAll("-", "/")
            : createdAt;
        else return format(createdAt, "DD/MM/YYYY");
      } else return format(Date.now(), "DD/MM/YYYY");
    };

    return (
      <div className="doc-item-wrapper" key={index}>
        <div className="doc-item-container">
          <span className="_bold doc-title">
            <BaseIcon component={Icon} />
            {generateViewLink()}
          </span>
          <span className="_grayed">{getCreateAt()}</span>
          {showActions && actionList && (
            <div className="settings">
              <ActionDropdown actionList={actionList(cuid, item)}>
                <BaseIcon type="ellipsis" />
              </ActionDropdown>
            </div>
          )}

          <div className="download-switch">
            {isPrivileged && type !== "contract" && (
              <TooltipWrapper title="Downloadable" placement="left">
                <SwitchWrapper
                  green
                  onChange={handleAttachmentUpdate(cuid)}
                  checked={downloadable}
                  disabled={!isPdf}
                />
              </TooltipWrapper>
            )}
          </div>
        </div>
      </div>
    );
  };

  const [missingAttachs, , , showAlert, days, daysElapsed] = docConstraints;

  const alertMsg = getMissingAttachmentsLabel(
    missingAttachs,
    days,
    daysElapsed
  );

  const missingAttachsAlert = showAlert && isPrivileged && (
    <span className="alert">{alertMsg}</span>
  );
  return (
    <div className="deal-view-documents-section">
      {titleAction && (
        <div className="title">
          <span className="_bold">{title}</span>
          <Button shape="circle" icon="plus" onClick={titleAction} />
        </div>
      )}
      {missingAttachsAlert}
      <div className="list-container">
        {Object.keys(list).map(key => {
          const docs = list[key];

          return (
            <div className="list-container">
              <details>
                <summary>
                  <div className="cat-title">
                    <h3>
                      {ioAttachmentCategoriesLabels[key] || "General"}{" "}
                      <span>
                        {docs.length}{" "}
                        {docs.length === 1 ? "document" : "documents"}
                      </span>
                    </h3>
                    <Icon type="up" className="arrow-up" />
                    <Icon type="down" className="arrow-down" />
                  </div>
                </summary>
                {docs.map((item, index) => renderDocItem(item, index))}
              </details>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentsDrawer;
