import { useEffect } from "react";
import PropTypes from "prop-types";
import { useInterval } from "#helpers/hooks/interval";
import { saveForm } from "#helpers/mappers/form/storage";
import { equityTs } from "#constants/io/equityPool";
import {
  getEquityFormStep,
  mapEquityDataToReadableFormVals
} from "#helpers/mappers/equity";
import { mapClaArrayToReadable } from "#helpers/mappers/convertibles";
import { formatStakeholdersToLenders } from "#helpers/mappers/convertibles";
import { FormStepper, BasicFormWrapper } from "../../../Shared/Form";
import list from "./list";
import sections from "./sections";
import {
  handleInputChange,
  handleCheckboxInArray,
  handleInputInArray
} from "../../common/formHandler";
import { TermsheetCreationSuccess } from "../../common/successViews";
import CustomInputRenderer from "../../common/Inputs/CustomInputRenderer";

const FormContainer = ({
  userId,
  entityId,
  values,
  handleChange,
  setFieldValue,
  buttonStates,
  handleSubmit,
  currencies,
  memberships,
  entityFetched,
  shareholdersColl,
  convertibles,
  entity = {},
  ioColl,
  setValues,
  shares,
  allMemberships,
  lastEquity: { termSheet = {}, cuid: equityCuid } = {},
  id,
  ioMemberships,
  commits
}) => {
  useInterval(
    () => saveForm({ userId, entityId, values }),
    !buttonStates.success ? 5000 : null
  );

  const formattedConvertibleTypes = convertibles.reduce(
    (acc, { cuid, ...item }) => ({ ...acc, [cuid]: item }),
    {}
  );

  useEffect(() => {
    if (entityFetched && shareholdersColl.didFetch && ioColl.didFetch) {
      const formattedMemberships = memberships
        .filter((membership = {}) => {
          if (
            membership.representativeOf === "board_observer" &&
            membership.role === "director"
          ) {
            return false;
          }
          return membership;
        })
        .map(({ user = {}, ...item }) => ({
          ...user,
          ...item,
          member: false,
          userCuid: user.cuid
        }));
      const { data = [] } = shareholdersColl;

      const formattedDirectors = allMemberships
        .filter(
          ({ isDirector, role, representativeOf }) =>
            representativeOf !== "board_observer" &&
            (role === "director" || isDirector)
        )
        .map(membership => ({
          membershipId: membership.cuid,
          presidentOfTheBoard: membership.presidentOfTheBoard,
          representativeOf: membership.representativeOf,
          signingAuthority: membership.signingAuthority,
          ...membership.user
        }));

      const formattedShareholders = formatStakeholdersToLenders({
        data,
        convertibleTypes: formattedConvertibleTypes
      });

      const formatedConvertibles = convertibles.map(({ name, cuid }) => ({
        name,
        cuid
      }));

      const uniqueShareholders =
        formattedShareholders &&
        formattedShareholders.length > 0 &&
        formattedShareholders.filter(
          (v, i, a) => a.findIndex(t => t.cuid === v.cuid) === i
        );
      const { employeeParticipationPlan: { esopShares = 0 } = {} } = entity;

      if (id) {
        let readableInvestors = [];
        let readableMemberships = [];
        let boardObeserverSelectors = [];
        let specificInvestors = [];
        let readableConversionInvestors = [];

        const {
          termSheet: readableTermSheet
        } = mapEquityDataToReadableFormVals(ioColl.data, {
          termSheet: true
        });
        const {
          termSheet: {
            directors = [],
            nominatesBoardObserver = {},
            nominatesSingleInvestor = {},
            conversionInvestors = [],
            convertibles = []
          } = {}
        } = ioColl.data || {};
        if (
          Array.isArray(uniqueShareholders) &&
          uniqueShareholders.length > 0 &&
          ioMemberships &&
          commits
        ) {
          readableInvestors = uniqueShareholders.map(investor => {
            const existingInvestor = ioMemberships.find(
              ({ investor: { cuid = "" } = {} }) => cuid === investor.cuid
            );
            if (existingInvestor) {
              const existingInvestorAmount = commits.find(
                ({ committerId = "", ioId = "" }) =>
                  existingInvestor.investor.cuid === committerId && ioId === id
              );
              return {
                ...investor,
                member: true,
                amount: existingInvestorAmount
                  ? existingInvestorAmount.amount
                  : ""
              };
            }
            return { ...investor };
          });
          readableConversionInvestors = uniqueShareholders.map(investor => {
            const existingInvestor = conversionInvestors.find(
              ({ membershipId }) => membershipId === investor.membershipId
            );
            if (existingInvestor) {
              return {
                ...investor,
                selection: true
              };
            }
            return { ...investor };
          });
        }
        if (
          Array.isArray(formattedDirectors) &&
          formattedDirectors.length > 0
        ) {
          readableMemberships = formattedDirectors.map(director => {
            const existingDirector = directors.find(
              ({ membershipId }) => membershipId === director.membershipId
            );
            if (existingDirector) {
              return {
                ...director,
                signingDirector: true
              };
            }
            return { ...director };
          });
        }

        if (
          Array.isArray(formattedMemberships) &&
          formattedMemberships.length > 0
        ) {
          specificInvestors = formattedMemberships.map(investor => {
            let specificInvestor;
            if (investor.cuid === nominatesSingleInvestor.membershipId) {
              specificInvestor = investor;
            }
            if (specificInvestor) {
              return {
                ...investor,
                selection: true
              };
            }
            return { ...investor };
          });

          boardObeserverSelectors = formattedMemberships.map(investor => {
            let specificBoardObserver;
            if (investor.cuid === nominatesBoardObserver.membershipId) {
              specificBoardObserver = investor;
            }
            if (specificBoardObserver) {
              return {
                ...investor,
                selection: true
              };
            }
            return { ...investor };
          });
        }

        const readableFormattedConvertibles = mapClaArrayToReadable(
          formatedConvertibles,
          convertibles
        );

        setValues({
          ...readableTermSheet,
          investors: readableInvestors,
          [equityTs.ESOP_SHARES_NUMBER]: esopShares,
          sharesNumber: entity.shares,
          convertingInvestors: readableConversionInvestors,
          signingDirector: readableMemberships,
          formatedConvertibles: readableFormattedConvertibles,
          esopFormattedConvertibles: formatedConvertibles,
          specificInvestors,
          boardObeserverSelectors,
          hasCommittedInvestors:
            Array.isArray(readableInvestors) && readableInvestors.length > 0
              ? "yes"
              : "no"
        });
      } else {
        setValues({
          investors: formattedMemberships,
          [equityTs.ESOP_SHARES_NUMBER]: esopShares,
          sharesNumber: entity.shares,
          convertingInvestors: uniqueShareholders,
          signingDirector: formattedDirectors,
          formatedConvertibles,
          esopFormattedConvertibles: formatedConvertibles,
          specificInvestors: formattedMemberships,
          boardObeserverSelectors: formattedMemberships
        });
      }
    }
  }, [entityFetched, shareholdersColl.didFetch, ioColl.didFetch]);

  const formHandlers = {
    checkboxHandler: handleCheckboxInArray(setFieldValue, values),
    inputHandler: handleInputInArray(setFieldValue, values)
  };
  const reverseFounderVesting = termSheet.reverseFounderVesting;
  const shareNames = shares.map(({ name }) => name);
  const fieldList = list(
    values,
    currencies,
    formHandlers,
    shareNames,
    reverseFounderVesting,
    equityCuid
  );
  const step = getEquityFormStep({ values, list: fieldList });

  const formArgs = {
    list: fieldList,
    sections,
    values,
    inputEvents: {
      onChange: type =>
        handleInputChange({ handleChange, setFieldValue, values })(type)
    },
    step,
    buttonStates,
    handleSubmit,
    clickableSection: true,
    success: buttonStates.success,
    CustomSuccess: TermsheetCreationSuccess,
    CustomInputRenderer
  };

  return <FormStepper {...formArgs} />;
};

FormContainer.propTypes = {
  userId: PropTypes.string,
  entityId: PropTypes.string,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  buttonStates: PropTypes.object,
  convertibles: PropTypes.array,
  currencies: PropTypes.array,
  memberships: PropTypes.array,
  entityFetched: PropTypes.bool,
  shareholdersColl: PropTypes.object,
  esopShares: PropTypes.any,
  ioColl: PropTypes.object,
  setValues: PropTypes.func,
  sharesOutstanding: PropTypes.any,
  entity: PropTypes.object,
  allMemberships: PropTypes.array
};

export default BasicFormWrapper(FormContainer);
