import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { triggerSnackbar } from "#core/modules/snackbar/actions";
import { getCurrentUser } from "#core/modules/users/actions/get";
import { updateUserSettings } from "#core/modules/users/actions/update";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { isUpdating, didUpdate, _data = {} } = state.users;
  const { _data: { entity: { userRole = "" } = {} } = {} } =
    state?.entities || {};
  return {
    buttonStates: { loading: isUpdating, success: didUpdate },
    user: _data,
    userRole
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserSettings: data => dispatch(updateUserSettings(data)),
  getCurrentUser: () => dispatch(getCurrentUser()),
  clearState: key => clearState(dispatch, key),
  triggerSnackbar: snackbar => triggerSnackbar(dispatch, snackbar)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
