import { connect } from "react-redux";
import {
  getSingleVoting,
  postVotingResult,
  putGenerateVotingCodeInv
} from "#core/modules/voting/actions";
import { clearState } from "#core/utils/helper_actions";
import { fetchSingleIo } from "#core/modules/io/actions";
import { getCurrentUser } from "#core/modules/users/actions/get";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    didCreate,
    isCreating,
    isFetching,
    didFetch,
    _data: { assembly = [] } = {}
  } = state.voting;

  const {
    isFetching: ioIsFetching,
    _data: { io: { syndicate: { name: syndicateName } = {} } = {} } = {}
  } = state.io;

  const {
    _data: {
      kyc: {
        kycResults: { generalQuestions: { phoneNumber = "" } = {} } = {}
      } = {}
    } = {}
  } = state.users || {};

  return {
    assemblyColl: {
      didFetch,
      isFetching,
      data: assembly
    },
    buttonStates: {
      loading: isCreating,
      success: didCreate
    },
    ioIsFetching,
    syndicateName,
    phoneNumber
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  getSingleVoting: params => dispatch(getSingleVoting(params)),
  postVotingResult: data => dispatch(postVotingResult(data)),
  fetchSingleIo: id => dispatch(fetchSingleIo(id)),
  getCurrentUser: () => dispatch(getCurrentUser()),
  putGenerateVotingCodeInv: data => dispatch(putGenerateVotingCodeInv(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
