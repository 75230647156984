/**
 * Accepts a number as an argument and returns a shorter
 * representation.
 * If the second argument is provided, it defines the precision
 * of the floating point result.
 *
 * @param {Number} input
 * @param {Number} precision
 *
 * @returns {String} Formatted number
 */

export const beautifyNumbers = (input, precision) => {
  const precisionToApply =
    (precision && typeof precision === 'number' && precision >= 0) ||
    precision === 0
      ? precision
      : 2;

  if (input < 1000) {
    if (input % 1 === 0) {
      return `${input}`;
    }
    return `${Number.parseFloat(input).toFixed(precisionToApply)}`;
  }

  let result = null;
  let suffix = null;

  if (input >= 1000 && input <= 999999) {
    const tmp = input / 1000;
    result = input % 1000 === 0 ? tmp : tmp.toFixed(precisionToApply);
    suffix = 'K';
  } else if (input >= 1000000 && input <= 999999999) {
    const tmp = input / 1000000;
    result = input % 1000000 === 0 ? tmp : tmp.toFixed(precisionToApply);
    suffix = 'M';
  } else if (input >= 1000000000 && input <= 999999999999) {
    const tmp = input / 1000000000;
    result = input % 1000000000 === 0 ? tmp : tmp.toFixed(precisionToApply);
    suffix = 'B';
  }

  if (result && suffix) {
    return `${result}${suffix}`;
  }

  return '-';
};

/**
 * Accepts a number or a number with decimal places as an argument
 * and returns a round up
 *
 * @param {Number} num
 */

export const roundNumbers = (num) => {
  if (!(num !== undefined && typeof num === 'number')) {
    throw new Error('Invalid argument provided');
  }

  if (num % 1 === 0) {
    return num;
  }

  return Number((Math.ceil(num / 0.05) * 0.05).toFixed(2));
};

export const formatNumber = (num, currency) => {
  return Intl.NumberFormat(
    'de-CH',
    currency && {
      style: 'currency',
      currency,
      currencyDisplay: 'code'
    }
  )
    .format(num)
    .replace(/\D00$/, '');
};
