import axios from "./apiConfig";

/* GET */

export const getMemberships = params =>
  axios.get(`investop-memberships`, { params });

export const getPortfolioMemberships = params =>
  axios.get("portfolio/investop-memberships", { params });

/* POST */

export const postMembership = data => axios.post(`investop-memberships`, data);

export const resendInvitationEmail = data =>
  axios.post(`investop-memberships/resend-invitation-email`, data);

/* PUT */

export const putMembershipStatus = ({ cuid, ...data }) =>
  axios.put(`investop-memberships/${cuid}/status`, data);

export const patchMembership = ({ cuid, ...data }) =>
  axios.patch(`investop-memberships/${cuid}`, data);

export const deleteMembership = cuid =>
  axios.delete(`investop-memberships/${cuid}`);
