import { Menu } from "antd";

export default function MenuItem({ children, onClick, ...props }) {
  return (
    <Menu.Item
      {...props}
      onClick={onClick || (() => {})} // work around to prevent some console errors that appear on mouse enter / click / leave
      onItemHover={() => {}}
    >
      {children}
    </Menu.Item>
  );
}
