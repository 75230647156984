import { Empty } from 'antd';
import SpinContainer from '../SpinContainer';

const ContentFallback = ({
  loading,
  customizedEmptyMsg,
  empty,
  success,
  children
}) => {
  if (loading) {
    return <SpinContainer />;
  }

  if (empty || success) {
    return (
      <Empty
        description={customizedEmptyMsg || 'No content to display right now'}
      />
    );
  }

  return children;
};

export default ContentFallback;
