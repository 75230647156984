import { clearState, clearStateDataByKey } from "#core/utils/helper_actions";
import {
  fetchSingleIo,
  notifyDmForIoFinalization,
  getRoundType
} from "#core/modules/io/actions";
import {
  patchAttachment,
  putIoInfo,
  updateAndCloseDeal,
  updateIoClosingStatus
} from "#core/modules/io/actions/update";
import { getSyndicates } from "#core/modules/syndicates/actions";
import { deleteIoAttachment, deleteIo } from "#core/modules/io/actions/delete";
import { readCurrencies } from "#core/modules/currency/actions/read";
import { getCommits } from "#core/modules/commitment/actions";
import { setEntity, updateEntity } from "#core/modules/entities/actions";
import {
  generateContracts,
  getDealConfirmation,
  getInvConfirmation,
  getInvestmentSummary,
  getPooledFundTemplates,
  getTransConfirmation
} from "#core/modules/contracts/actions";
import { getNdas, getNdaDoc } from "#core/modules/nda/actions";
import {
  getIoMemberships,
  patchMembershipStatus
} from "#core/modules/iomemberships/actions";

export const actionSelector = dispatch => ({
  fetchSingleIo: data => dispatch(fetchSingleIo(data)),
  getRoundType: data => dispatch(getRoundType(data)),
  putIoInfo: data => dispatch(putIoInfo(data)),
  updateAndCloseDeal: data => dispatch(updateAndCloseDeal(data)),
  updateEntity: data => dispatch(updateEntity(data)),
  deleteIoAttachment: data => dispatch(deleteIoAttachment(data)),
  getCommits: data => dispatch(getCommits(data)),
  generateContracts: data => dispatch(generateContracts(data)),
  updateIoClosingStatus: data => dispatch(updateIoClosingStatus(data)),
  readCurrencies: () => dispatch(readCurrencies()),
  getNdas: data => dispatch(getNdas(data)),
  getNdaDoc: data => dispatch(getNdaDoc(data)),
  clearState: key => clearState(dispatch, key),
  clearStateDataByKey: (state, ...keys) =>
    clearStateDataByKey(dispatch, state, ...keys),
  patchMembershipStatus: data => dispatch(patchMembershipStatus(data)),
  getIoMemberships: data => dispatch(getIoMemberships(data)),
  patchAttachment: data => dispatch(patchAttachment(data)),
  deleteIo: data => dispatch(deleteIo(data)),
  setEntity: data => dispatch(setEntity(data)),
  getPooledFundTemplates: params => dispatch(getPooledFundTemplates(params)),
  contracts: {
    getInvConfirmation: params => dispatch(getInvConfirmation(params)),
    getTransConfirmation: params => dispatch(getTransConfirmation(params)),
    getDealConfirmation: params => dispatch(getDealConfirmation(params))
  },
  getSyndicates: params => dispatch(getSyndicates(params)),
  notifyDmForIoFinalization: params =>
    dispatch(notifyDmForIoFinalization(params)),
  getInvestmentSummary: params => dispatch(getInvestmentSummary(params))
});
