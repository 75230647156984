import { connect } from "react-redux";
import {
  getEntityMemberships,
  setEntity
} from "#core/modules/entities/actions";
import { createIo, fetchSingleIo, putIoInfo } from "#core/modules/io/actions";
import { bulkCreateIoMemberships } from "#core/modules/iomemberships/actions";
import { readCurrencies } from "#core/modules/currency/actions/read";
import { clearState, clearStateDataByKey } from "#core/utils/helper_actions";
import { serializeDispatchers } from "#core/utils/helper_func";
import { createSyndicate } from "#core/modules/syndicates/actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    didFetch: currenciesDidFetch,
    _data: { currencies = [] } = {}
  } = state.currency;

  const {
    didFetch: entitiesDidFetch,
    _data: { entity: { cuid: entityId, userRole }, memberships = [] } = {}
  } = state.entities;

  const {
    didUpdate: ioDidUpdate,
    isUpdating: ioIsUpdating,
    didFetch: ioDidFetch,
    didCreate: ioDidCreate,
    isCreating: ioIsCreating,
    _data: { io = {} } = {}
  } = state.io;

  const {
    isCreating: membershipIsCreating,
    didCreate: membershipDidCreate
  } = state.ioMemberships;
  const {
    _data: { cuid: userId }
  } = state.users;

  return {
    entityId,
    currencies,
    currenciesDidFetch,
    buttonStates: {
      success: ioDidCreate || ioDidUpdate,
      loading: ioIsCreating || ioIsUpdating
    },
    ioDidCreate,
    memberships,
    entitiesDidFetch,
    ioColl: {
      data: io,
      didFetch: ioDidFetch
    },
    membershipStates: {
      membershipIsCreating,
      membershipDidCreate
    },
    userRole,
    userId
  };
};

const mapDispatchToProps = dispatch => ({
  createSyndicateAndIo: args =>
    dispatch(
      serializeDispatchers({
        dispatchers: [createSyndicate, createIo],
        args
      })
    ),
  createIo: ios => dispatch(createIo(ios)),
  readCurrencies: () => dispatch(readCurrencies()),
  clearState: key => clearState(dispatch, key),
  clearStateDataByKey: (givenState, ...keys) =>
    clearStateDataByKey(dispatch, givenState, ...keys),
  getEntityMemberships: data => dispatch(getEntityMemberships(data)),
  bulkCreateIoMemberships: data => dispatch(bulkCreateIoMemberships(data)),
  fetchSingleIo: data => dispatch(fetchSingleIo(data)),
  putIoInfo: data => dispatch(putIoInfo(data)),
  setEntity: data => dispatch(setEntity(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
