import { Component } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { convertToBase64 } from "#helpers/convertBase64";
import profileSchema from "#validators/profileSchema";
import { roles } from "#constants/roles";
import { BasicForm } from "../Shared/Form";
import { profileFields, settingsFields } from "./components/fieldList";
import BasicWrapper from "../Shared/BasicWrapper";
import CustomInputRenderer from "./components/CustomInputRenderer";

class MyAccountWrapper extends Component {
  state = {
    profilePicUpdated: null
  };

  componentDidMount() {
    this.props.clearState("users");
    this.props.getCurrentUser();
  }

  handleUpload = _ => info => {
    this.setState({
      profilePicUpdated: info.file.originFileObj
    });
  };
  componentDidUpdate() {
    const { buttonStates, history, userRole } = this.props;
    const isInvestor =
      userRole === roles.AM_INVESTOR || userRole === roles.INVESTOR;

    if (buttonStates.success) {
      setTimeout(() => {
        if (isInvestor) history.push("/dashboard/investments");
        else history.push("/dashboard");
      }, 3000);
    }
  }
  handleSubmit = async values => {
    const { updateUserSettings } = this.props;
    const { profilePicUpdated } = this.state;
    const { email, profilePicture, ...localValues } = values;

    let payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      ...localValues
    };

    if (profilePicUpdated) {
      const profilePictureBase64 = await convertToBase64(profilePicUpdated);
      payload = { ...payload, profilePictureBase64 };
    }
    updateUserSettings(payload);
  };

  render() {
    const { buttonStates, user: { profilePicture, ...user } = {} } = this.props;
    const { profilePicUpdated } = this.state;
    const finalPreviewImage = profilePicUpdated
      ? URL.createObjectURL(profilePicUpdated)
      : profilePicture;

    const args = {
      handleSubmit: this.handleSubmit,
      handleCancel: () => this.props.history.goBack(),
      buttonStates,
      wrapperClassname: "my-account-form",
      values: user,
      fieldList: profileFields(),
      validationSchema: profileSchema,
      handleUpload: this.handleUpload,
      CustomInputRenderer
    };

    const settingsArgs = {
      handleCancel: () => this.props.history.goBack(),
      buttonStates,
      wrapperClassname: "my-account-form",
      values: user,
      fieldList: settingsFields(finalPreviewImage),
      handleUpload: this.handleUpload,
      CustomInputRenderer,
      CustomFooter: () => <div />
    };

    return (
      <div className="account-settings-wrapper">
        <Row type="flex" justify="space-between">
          <Col span={12}>
            <BasicForm {...args} />
          </Col>
          <Col span={10}>
            <BasicForm {...settingsArgs} />
          </Col>
        </Row>
      </div>
    );
  }
}

MyAccountWrapper.propTypes = {
  getCurrentUser: PropTypes.func,
  user: PropTypes.object,
  buttonStates: PropTypes.object,
  history: PropTypes.object,
  updateUserSettings: PropTypes.func,
  triggerSnackbar: PropTypes.func
};
export default MyAccountWrapper;
