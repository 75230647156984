import { useEffect } from "react";
import PropTypes from "prop-types";
import { useInterval } from "#helpers/hooks/interval";
import { mapEquityDataToReadableFormVals } from "#helpers/mappers/equity";
import { globalChangeHandler } from "#helpers/inputHandlers";
import { saveForm } from "#helpers/mappers/form/storage";
import { equityTs, shaKeys } from "#constants/io/equityPool";
import { FormStepper, BasicFormWrapper } from "../../../Shared/Form";
import list from "./list";
import sections from "./sections";
import {
  handleInputChange,
  handleCheckboxInArray
} from "../../common/formHandler";
import EquityCreationSuccessView from "../../common/successViews/EquityCreation";
import CustomInputRenderer from "../../common/Inputs/CustomInputRenderer";

const FormContainer = ({
  userId,
  entityId,
  values,
  handleChange,
  setFieldValue,
  handleSubmit,
  handleUpload,
  buttonStates,
  io,
  reverseVesting,
  reverseVestingMonths,
  ioDidFetch,
  memberships,
  setValues,
  severalTranches,
  investorDirectors,
  independentDirectors,
  shares
}) => {
  // useEffect(() => {
  //   if (ioDidFetch) {
  //     const { iaAndSha = {} } = mapEquityDataToReadableFormVals(io, {
  //       iaAndSha: true
  //     });

  //     setFieldValue();
  //     setValues({
  //       [equityTs.LEAVER_SHARES_DUR]: reverseVestingYears,
  //       ...iaAndSha
  //     });
  //   }
  // }, [ioDidFetch]);
  useInterval(() => saveForm({ userId, entityId, values }), 5000);

  useEffect(() => {
    const { iaAndSha: readableIaAndSha } = mapEquityDataToReadableFormVals(io, {
      iaAndSha: true
    });
    const formattedMemberships = memberships
      .map(
        ({
          role,
          cuid,
          user,
          isDirector,
          representativeOf,
          signingAuthority
        }) => ({
          membershipId: cuid,
          role,
          representativeOf,
          signingAuthority,
          isDirector,
          ...user
        })
      )
      .filter(member => member.role === "director" || member.isDirector);
    const {
      termSheet: { minCapitalIncrease = 0, agreedAmount = 0 } = {},
      iaAndSha: { directors = [] }
    } = io || {};
    const readableMemberships = formattedMemberships.map(director => {
      const existingDirector = directors.find(
        ({ membershipId }) => membershipId === director.membershipId
      );
      if (existingDirector) {
        return {
          ...director,
          PoA: true
        };
      }
      return { ...director };
    });
    setValues({
      ...readableIaAndSha,
      signingDirector:
        Object.keys(readableIaAndSha).length > 0
          ? readableMemberships
          : formattedMemberships,
      [shaKeys.VOLUNTARY_CONVERSION_SHA]: "yes",
      [shaKeys.IPO_CONVERSION]: "yes",
      [shaKeys.MAX_INVESTMENT_AMOUNT_IF_SEVERAL_TRANCHES]:
        agreedAmount - minCapitalIncrease,
      [shaKeys.REVERSE_VESTING_MONTHS]: reverseVestingMonths
    });
  }, [memberships]);

  const { currency: { shortName: currency } = {}, closed } = io;

  let agreedAmount;

  if (io.termSheet) {
    agreedAmount = io.termSheet.agreedAmount;
  }

  const commonShares = shares.filter(share => share.type === "common");
  const sharesMinusCommon = shares.length - commonShares.length;

  const founders = memberships.filter(member => member.role === "founder");
  const membershipsMinusFounders = memberships.length - founders.length;

  const composedInputHandlers = {
    switchHandler: globalChangeHandler(setFieldValue, values, "switch"),
    checkboxHandler: globalChangeHandler(setFieldValue, values, "checkbox"),
    checkboxInArray: handleCheckboxInArray(setFieldValue, values),
    radioHandler: handleChange
  };
  const subscriptionFinished = io.subscriptionFinished;
  const formArgs = {
    list: list(
      values,
      currency,
      reverseVesting,
      composedInputHandlers,
      closed,
      membershipsMinusFounders,
      sharesMinusCommon,
      agreedAmount,
      subscriptionFinished,
      severalTranches,
      investorDirectors,
      independentDirectors
    ),
    sections,
    values,
    inputEvents: {
      onChange: type =>
        handleInputChange({ handleChange, setFieldValue, values })(type),
      onUpload: handleUpload(setFieldValue)
    },
    clickableSection: true,
    handleSubmit,
    buttonStates,
    success: buttonStates.success,
    CustomSuccess: EquityCreationSuccessView({ type: "SHA" }),
    customClassName: "shareholder-agreement-container",
    CustomInputRenderer
  };

  return <FormStepper {...formArgs} />;
};

FormContainer.propTypes = {
  userId: PropTypes.string,
  entityId: PropTypes.string,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleUpload: PropTypes.func,
  io: PropTypes.object,
  buttonStates: PropTypes.object,
  reverseVesting: PropTypes.bool,
  reverseVestingYears: PropTypes.number,
  ioDidFetch: PropTypes.bool,
  shares: PropTypes.array,
  severalTranches: PropTypes.bool,
  memberships: PropTypes.array,
  investorDirectors: PropTypes.string,
  independentDirectors: PropTypes.string
};

export default BasicFormWrapper(FormContainer);
