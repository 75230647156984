const CashControl = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M6.1202 13.3057H0.816003C0.35362 13.3057 0 12.952 0 12.4896C0 12.0273 0.35362 11.6736 0.816003 11.6736H6.1202C6.58264 11.6736 6.93621 12.0273 6.93621 12.4896C6.93621 12.952 6.58264 13.3057 6.1202 13.3057Z"
          fill="#597EF7"
        />
        <path
          d="M19.1768 13.3056H13.4918C13.0293 13.3056 12.6758 12.952 12.6758 12.4896C12.6758 12.0272 13.0294 11.6736 13.4918 11.6736H18.9047L22.6856 8.68154C23.0393 8.40956 23.5561 8.46392 23.8281 8.81754C24.1001 9.17116 24.0457 9.68795 23.6921 9.95998L19.6936 13.1425C19.5303 13.2512 19.3672 13.3056 19.1768 13.3056Z"
          fill="#597EF7"
        />
        <path
          d="M6.1202 16.4881H0.816003C0.35362 16.4881 0 16.1345 0 15.6721C0 15.2096 0.35362 14.8561 0.816003 14.8561H6.1202C6.58264 14.8561 6.93621 15.2097 6.93621 15.6721C6.93621 16.1345 6.58264 16.4881 6.1202 16.4881Z"
          fill="#597EF7"
        />
        <path
          d="M19.1766 16.4881H13.546C13.0835 16.4881 12.73 16.1345 12.73 15.6721C12.73 15.2098 13.0836 14.8561 13.546 14.8561H18.9045L22.6854 12.136C23.039 11.8641 23.5558 11.9456 23.8279 12.3264C24.0999 12.6801 24.0183 13.1969 23.6375 13.4689L19.6661 16.325C19.5029 16.4337 19.3397 16.4881 19.1766 16.4881Z"
          fill="#597EF7"
        />
        <path
          d="M23.8552 15.781C23.6104 15.4002 23.0936 15.2914 22.7128 15.5362L18.9046 18.0114H14.2261V10.1504H19.1767C19.3943 10.1504 19.5847 10.0688 19.7207 9.93274L23.7192 6.23342C23.964 6.01579 24.0456 5.66223 23.9368 5.33578C23.8009 5.03657 23.5016 4.81894 23.1752 4.81894H18.4422C18.3062 4.54696 18.0342 4.35651 17.7078 4.35651H10.5812C10.3635 4.35651 10.1731 4.43809 10.0372 4.57413L9.76517 4.81894H4.81454C4.59691 4.81894 4.40651 4.90053 4.27052 5.03657L0.271984 8.73589C0.0271775 8.95352 -0.0544071 9.30709 0.0543551 9.63353C0.190347 9.93274 0.48956 10.1504 0.816003 10.1504H5.46737V18.0114H0.816003C0.35362 18.0114 0 18.3651 0 18.8274C0 19.2898 0.35362 19.6435 0.816003 19.6435H19.1766C19.3398 19.6435 19.503 19.589 19.6118 19.5075L23.6104 16.8962C23.9912 16.6514 24.1 16.1346 23.8552 15.781ZM21.0807 6.45105L18.8502 8.51831H15.3141L17.5446 6.45105H21.0807ZM2.9105 8.51831L5.14098 6.45105H8.0243L5.79381 8.51831H2.9105ZM7.09943 9.52477L10.9076 5.98867H15.6405L12.866 8.57277C12.7028 8.73599 12.594 8.95357 12.594 9.1712V18.0115H7.09943V9.52477Z"
          fill="#18DC9B"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CashControl;
