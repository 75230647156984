import { Pie } from "react-chartjs-2";
import { colors } from "../../../../../styles/abstracts/variables";
import Slider from "../../../Slider";

const StatsSection = ({ title, labels, dataInfo }) => {
  const data = {
    labels,
    datasets: [
      {
        backgroundColor: [
          colors.middleBlue,
          colors.corn,
          colors.rosePink,
          colors.cerise,
          colors.juneBud,
          colors.cultured,
          colors.uranianBlue,
          colors.operaMauve,
          colors.terraCotta,
          colors.paleSpringBud,
          colors.opal,
          colors.alabaster,
          colors.robinEggBlue,
          colors.violetRed,
          colors.champagnePink,
          colors.jasmine,
          colors.celeste,
          colors.greenCrayola
        ],
        label: labels,
        data: dataInfo
      }
    ]
  };

  return (
    <div className="stats-section-wrapper">
      <span className="title">{title}</span>
      <div className="chart-wrapper">
        <Pie data={data} />
      </div>
      {/*
       <Slider marks={{ 0: '2019', 12: '2020' }} min={0} max={12} /> 
      */}
    </div>
  );
};

export default StatsSection;
