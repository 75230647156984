import { basicOption } from "#helpers/general";
import { formatNumber } from "#helpers/number_format";
import { NavLink } from "react-router-dom";
import { generalInformationsCols } from "./tableCols";
import { confirmationPrompt } from "../../../common/prompts";
import { keys, claTsKeys } from "../../../../../constants/cla";

const generalInformations = (values, handlers, handleFormSave, currPath) => [
  {
    title:
      "Does the company have a pre-existing shareholders' agreement in place?",
    input: {
      name: keys.preExistingAgreement,
      ...confirmationPrompt
    }
  },

  values.preExistingAgreement === "yes" && {
    title: "Please insert the date of the current shareholders' agreement",
    input: {
      type: "date",
      name: keys.preExistingDate
    }
  },
  values.preExistingAgreement === "yes" && {
    title: "Do you want to upload the current valid shareholders' agreement?",
    input: {
      name: keys.uploadAgreementConfirmation,
      ...confirmationPrompt
    }
  },
  values.preExistingAgreement === "yes" &&
    values.uploadAgreementConfirmation === "yes" && {
      input: {
        type: "upload",
        name: "sha_upload"
      }
    },
  {
    title: "For which purpose shall the convertible loan be used?",
    input: {
      type: "select_btn",
      name: keys.claPurpose,
      options: [
        basicOption("ordinary_business_activity", "Ordinary business activity"),
        basicOption("customize", "Customize")
      ]
    }
  },
  values.claPurpose === "customize" && {
    input: {
      placeholder: "The Loan shall be used exclusively for ",
      type: "textarea",
      rows: 4,
      name: keys.claPurposeArea
    }
  },
  {
    title: "Who shall sign this convertible loan?",
    input: {
      type: "select_btn",
      name: claTsKeys.CLA_SIGNED_BY,
      options: [
        basicOption("board_of_directors", "Board of directors"),
        basicOption(
          "board_of_directors_and_founders",
          "Board of directors and founders"
        ),
        basicOption(
          "board_of_directors_and_shareholders",
          "Board of directors and all existing shareholders"
        )
      ]
    }
  },
  values[claTsKeys.CLA_SIGNED_BY] && {
    title:
      "Which member(s) of the company's board of directors shall sign the convertible loan agreement?",
    input: {
      type: "table_list",
      name: "signingDirector",
      cols: generalInformationsCols(values, handlers)
    }
  },
  values[claTsKeys.CLA_SIGNED_BY] && {
    showId: false,
    title: (
      <span>
        If you want to add a board member{" "}
        <NavLink
          to={`/stakeholders?redirect=${currPath}?step=0`}
          onClick={handleFormSave(values)}
        >
          click here
        </NavLink>
      </span>
    ),
    className: "_hidden",
    input: {}
  },
  {
    title:
      "Within how many days after the execution of the convertible loan agreement is the investor required to pay the loan to the company?",
    input: {
      type: "select_btn",
      name: keys.investorLoanPayout,
      options: [basicOption("5", "5 days"), basicOption("Other")]
    }
  },
  values[keys.investorLoanPayout] === "Other" && {
    input: {
      type: "text",
      name: keys.investorLoanPayoutOther,
      addonAfter: "day(s)",
      placeholder: "123"
      // formatter: value => (value ? `${value} days` : ""),
      // parser: value => value.replace(new RegExp(` days\\s?|(\\'*)`, "g"), "")
    }
  },
  {
    title:
      "Shall the lenders be entitled to assign their rights and obligations under this Convertible loan Agreement in whole to an affiliate without the prior written consent of the founders and the existing shareholders?",
    input: {
      name: claTsKeys.LENDER_AFFILIATE_WITHOUT_CONSENT,
      ...confirmationPrompt
    }
  },
  {
    title:
      "Does the board have to consent to disclosure of the terms of the Convertible loan Agreement to third parties other than competitors of the Company?",
    input: {
      name: claTsKeys.BOARD_DISCLOSURE_THIRD_PARTIES,
      ...confirmationPrompt
    }
  }
];

export default generalInformations;
