import { connect } from "react-redux";
import { updateIoClosingStatus } from "#core/modules/io/actions";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { isUpdating, didUpdate } = state.io;
  return {
    buttonStates: {
      loading: isUpdating,
      success: didUpdate
    }
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    clearState: data => clearState(dispatch, data),
    updateIoClosingStatus: data => dispatch(updateIoClosingStatus(data))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
