import { roles } from "#constants/roles";
import { Button } from "../../Button";
import backArrow from "../../../../assets/illustrations/back-arrow.png";

const withBackNavigation = ({ link }) => Target => props => {
  const { history, location, userRole } = props;
  const hasHistory = history.length > 2;

  const handleClick = () => {
    if (link) {
      history.push(link);
    } else {
      const { fromForm } = location.state || {};

      if (fromForm) {
        history.push("/dashboard/deal_management");
      } else {
        history.goBack();
      }
    }
  };

  return (
    <div className="with-back-nav">
      <div className="go-back">
        {hasHistory && (
          <span onClick={handleClick}>
            <img width="30px" src={backArrow} alt="back-arrow" />
            <Button label="Back" type="link" />
          </span>
        )}
      </div>
      <Target {...props} />
    </div>
  );
};

export default withBackNavigation;
