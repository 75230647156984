import { addressFields, bankAccountDetailsFields } from "./common";

const naturalPersonFields = ({
  previewImage,
  currencies,
  values = {}
} = {}) => {
  return [
    {
      className: "_fullwidth",
      input: {
        type: "avatar_uploader",
        name: "previewImage",
        accept: ".jpg,.png,.jpeg",
        required: true,
        profilePic: previewImage
      }
    },

    {
      title: "Full name",
      className: "_fullwidth",
      input: {
        type: "text",
        name: "name",
        disabled: true
      }
    },
    {
      title: "Gender",
      input: {
        type: "select",
        name: "sex",
        options: [
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
          { value: "other", label: "Other" },
          { value: "not_disclosed", label: "Choose not to disclose" }
        ]
      }
    },

    {
      title: "Email address",
      input: {
        type: "text",
        name: "email",
        disabled: true
      }
    },

    ...addressFields,
    ...bankAccountDetailsFields({
      currencies,
      currentVal: values.bankAcc,
      name: values.name
    }),
    {
      title: "Linkedin profile url",
      className: "_width-50",
      input: {
        type: "text",
        name: "linkedInUrl",
        details: "www.linkedin.com/in/:username"
      }
    },

    {
      title: "Twitter profile url",
      className: "_width-50",
      input: {
        type: "text",
        name: "twitterUrl",
        details: "www.twitter.com/:username"
      }
    }
  ];
};

export { naturalPersonFields };
