import * as Yup from 'yup';
import { returnError } from './common';

export const addInvestorSchema = Yup.object().shape({
  userFirstName: Yup.string().required(returnError('required')),
  userLastName: Yup.string().required(returnError('required')),
  userEmail: Yup.string()
    .email(returnError('invalid_email'))
    .required(returnError('required'))
});
