import { connect } from "react-redux";
import {
  getEntityShareholders,
  deleteShareholder,
  updateShareholderById,
  getEntityCapTable
} from "#core/modules/shares/actions";
import { fetchAllIos } from "#core/modules/io/actions";
import { getEntityMemberships } from "#core/modules/entities/actions/get";
import { deleteEntityMembership } from "#core/modules/entities/actions";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { entity: { cuid: entityId, createdBy: creator } = {} } =
    state.entities._data || {};
  const shareholders =
    (state.shares._data && state.shares._data.shareholders) || [];
  const members =
    (state.entities._data && state.entities._data.memberships) || [];
  const allStakeholders = members.map(({ user = {}, ...item }) => {
    const shareholderRecord = shareholders.find(
      ({ user: { cuid: localCuid } = {} }) => localCuid === user.cuid
    );

    if (shareholderRecord) {
      return {
        ...item,
        user: {
          ...shareholderRecord.user,
          ...user
        },
        shares: shareholderRecord.shares,
        convertibles: shareholderRecord.convertibles,
        shareholderId: shareholderRecord.cuid,
        beneficialOwners: shareholderRecord.beneficialOwners,
        representatives: shareholderRecord.representatives,
        entityIsShareholder: shareholderRecord.entityIsShareholder
      };
    }

    return { ...item, user };
  });

  const { ios = [] } = state.io._data || {};

  const closedIos =
    (Array.isArray(ios) && ios.filter(({ closed }) => closed === 2)) || {};

  return {
    entityId,
    shareholders: allStakeholders,
    closedIos,
    creator
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAllIos: data => dispatch(fetchAllIos(data)),
  getEntityShareholders: params => dispatch(getEntityShareholders(params)),
  getEntityMemberships: params => dispatch(getEntityMemberships(params)),
  deleteShareholder: (shareholder, params, optData) =>
    dispatch(deleteShareholder(shareholder, params, optData)),
  updateShareholderById: (id, data) =>
    dispatch(updateShareholderById(id, data)),
  getEntityCapTable: params => dispatch(getEntityCapTable(params)),
  deleteEntityMembership: data => dispatch(deleteEntityMembership(data)),
  clearState: key => clearState(dispatch, key)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
