import { connect } from 'react-redux';
import { getCommits } from '#core/modules/commitment/actions';
import { fetchSingleIo } from '#core/modules/io/actions';
import Presentational from './Presentational';

const mapStateToProps = ({ commits, entities, users, io }) => {
  const { entity: { industries = [], name: entityName, userRole } = {} } =
    entities._data || {};

  return {
    entityName,
    investorName: users._data && users._data.firstName,
    entityId:
      entities._data && entities._data.entity && entities._data.entity.cuid,
    commits: (commits._data && commits._data.commits) || [],
    io: (io._data && io._data.io) || {},
    loading: commits.isFetching || io.isFetching,
    industries,
    userRole
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCommits: (data) => dispatch(getCommits(data)),
  fetchSingleIo: (data) => dispatch(fetchSingleIo(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
