import { Component } from "react";
import PropTypes from "prop-types";
import {
  mapEntityUpdateValues,
  mapEntityAddressAndBank
} from "#helpers/mappers/entitySettings";
import { checkDateString } from "#helpers/general";

import { getEntityUpdateSchema } from "#validators/entitySchema";
import { convertToBase64 } from "#helpers/convertBase64";
import { roles } from "#constants/roles";
import { withAccessRoles } from "../../hocs";
import { Button } from "../../Button";
import { BasicForm } from "../../Form/";
import { companyFields } from "../../Form/EntityForm/components/list";
import CustomInputRenderer from "./components/CustomInputRenderer";

class EntitySettings extends Component {
  state = {};

  componentDidMount() {
    const {
      entity: { cuid: id }
    } = this.props;

    this.props.clearState("entities");
    this.props.getEntity(id);
  }

  componentDidUpdate(prevProps) {
    const { didUpdate } = this.props;
    if (didUpdate && didUpdate !== prevProps.didUpdate) {
      this.updateNotification();
    }
  }

  updateNotification = () => {
    this.props.triggerSnackbar({
      variant: "success",
      message: "Successfully updated entity settings.",
      placement: "bottomLeft",
      open: true
    });
    setTimeout(() => this.props.history.push("/dashboard/generator"), 2500);
  };

  handleStateKeyChange = key => value => this.setState({ [key]: value });

  handleUpload = setFieldValue => info => {
    this.setState({
      previewImageUpdated: info.file.originFileObj
    });
  };
  handleSubmit = async values => {
    const {
      updateEntity,
      entity: { cuid }
    } = this.props;

    const { previewImageUpdated } = this.state;
    let payload = { cuid, ...mapEntityUpdateValues(values) };

    if (previewImageUpdated) {
      const previewImageBase64 = await convertToBase64(previewImageUpdated);
      payload = { ...payload, previewImageBase64 };
    }
    updateEntity(payload);
  };

  customFooter = ({ buttonStates, ...props }) => (
    <div className="btn-container">
      <Button label="Update" {...buttonStates} {...props} />
    </div>
  );

  render() {
    const { previewImageUpdated } = this.state;

    const { buttonStates, entity = {} } = this.props;
    const formArgs = {
      handleSubmit: this.handleSubmit,
      handleCancel: this.handleCancel,
      handleUpload: this.handleUpload,
      buttonStates,
      CustomInputRenderer,
      validationSchema: getEntityUpdateSchema()
    };

    const finalPreviewImg = previewImageUpdated
      ? URL.createObjectURL(previewImageUpdated)
      : entity.previewImage;

    const {
      employeeParticipationPlan: { esopShares = 0, psopShares = 0 } = {}
    } = entity || {};
    const fields = companyFields({}, finalPreviewImg);
    const mappedEntityValues = {
      ...entity,
      publicShareCapital: entity.shareCapital,
      publicFoundingDate: checkDateString(entity.birthDate),
      aoaDate: checkDateString(entity.aoaDate),
      shares: entity.shares,
      idNumber: entity.idNumber
        ? entity.idNumber.includes("CH") && !entity.idNumber.includes("-")
          ? `${entity.idNumber.split("CHE")[0]}CHE-${entity.idNumber
              .split("E")[1]
              .replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`
          : `${entity.idNumber.replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`
        : "",
      descriptionEnglish: entity.translatedDescription || "",
      governingLaw: entity.governingLaw,
      esopShares,
      psopShares,
      ...mapEntityAddressAndBank(entity)
    };
    return (
      <div className="my-account-wrapper">
        <BasicForm
          wrapperClassname="entity-update-form"
          fieldList={fields}
          {...formArgs}
          values={mappedEntityValues}
          CustomFooter={this.customFooter}
        />
      </div>
    );
  }
}

EntitySettings.propTypes = {
  entity: PropTypes.object,
  triggerSnackbar: PropTypes.func,
  buttonStates: PropTypes.object,
  updateEntity: PropTypes.func,
  didUpdate: PropTypes.bool,
  clearState: PropTypes.func,
  getEntity: PropTypes.func
};

export default withAccessRoles([roles.FOUNDER])(EntitySettings);
