import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Pie } from "react-chartjs-2";
import { Tabs } from "antd";
import { readEntityShareholders } from "#helpers/mappers/shares";
import { readConvertiblesData } from "#helpers/mappers/convertibles";
import BasicWrapper from "../Shared/BasicWrapper";
import SharedTable from "../Shared/SharedTable/Presentational";
import { withPageTitle } from "../Shared/hocs";
import { captableClaCols, captableInvCols } from "./components/cols";

const FoundersCaptable = ({
  entityId,
  getEntityShareholders,
  shareholders,
  getShareClasses,
  sharesList,
  sharesDidFetch,
  clearState,
  getConvertibleTypes,
  convertibles,
  entitySharesNo
}) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    getEntityShareholders({ entity_id: entityId });
    getShareClasses({ entity_id: entityId });
    getConvertibleTypes({ entity_id: entityId });
    clearState("shares");
  }, []);

  useEffect(() => {
    if (sharesDidFetch) {
      const [
        labels,
        data,
        backgroundColor,
        frmtedShareholders
      ] = readEntityShareholders(shareholders, sharesList);

      setState({
        labels,
        data,
        backgroundColor,
        frmtedShareholders
      });
    }
  }, [sharesDidFetch]);

  const handleTabChange = key => setState({ ...state, view: key });

  const { labels, data, backgroundColor, frmtedShareholders, view } = state;

  const [
    convertibleLabels,
    convertibleData,
    convertibleColors,
    frmtedConvertibles
  ] = readConvertiblesData(convertibles, entitySharesNo);

  const chartConfig =
    view === "1"
      ? {
          labels,
          datasets: [
            {
              label: labels,
              data,
              backgroundColor
            }
          ]
        }
      : {
          labels: convertibleLabels,
          datasets: [
            {
              label: convertibleLabels,
              data: convertibleData,
              backgroundColor: convertibleColors
            }
          ]
        };

  return (
    <BasicWrapper>
      <div className="chart-wrapper">
        <Pie data={chartConfig} />
      </div>
      <Tabs onChange={handleTabChange}>
        <Tabs.TabPane tab="Shareholders" key="1">
          <SharedTable
            columns={captableInvCols}
            dataSource={frmtedShareholders}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Convertibles" key="2">
          <SharedTable
            columns={captableClaCols}
            dataSource={frmtedConvertibles}
          />
        </Tabs.TabPane>
      </Tabs>
    </BasicWrapper>
  );
};

FoundersCaptable.propTypes = {
  entityId: PropTypes.string,
  shareholders: PropTypes.array,
  sharesList: PropTypes.array,
  getEntityShareholders: PropTypes.func,
  getShareClasses: PropTypes.func,
  sharesDidFetch: PropTypes.bool,
  clearState: PropTypes.func,
  getConvertibleTypes: PropTypes.func,
  convertibles: PropTypes.array,
  entitySharesNo: PropTypes.string
};

export default withPageTitle("Cap table")(FoundersCaptable);
