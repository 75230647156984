import { closedValues } from "#constants/io/closed";
import { NavLink } from "react-router-dom";

export const btn_actions = ({
  handleStateChange,
  handleDealEdit,
  handleIoDelete,
  handleCreateVoting,
  showCloseConfirm,
  handleConfirmTransfer,
  ...deps
}) => ({
  create_voting: deps.isPrivileged &&
    deps.dealIsClosed && {
      label: "Start voting session",
      size: "large",
      onClick: handleCreateVoting,
      type: "secondary",
      ghost: true
    },
  collect_funds: deps.isPrivileged &&
    !deps.executed && {
      label: "Collect funds",
      size: "large",
      onClick: handleStateChange("executeDealModalOpen", true),
      type: "secondary"
    },
  close_deal: deps.isPrivileged &&
    deps.executed && {
      label: deps.dealIsClosed ? "Deal is closed" : "Close deal",
      disabled: !deps.canCloseDeal,
      size: "large",
      type: "danger",
      ghost: true,
      onClick: showCloseConfirm,
      loading: deps.loading,
      tooltip:
        !deps.canCloseDeal && !deps.dealIsClosed
          ? "There are pending commitments."
          : null
    },
  add_investor: deps.isPrivileged &&
    !deps.dealIsClosed && {
      label: "Add investor",
      size: "large",
      disabled: deps.dealIsClosed,
      onClick: handleStateChange("addMemberModalOpen", true),
      type: "primary"
    },
  confirm_transfer: deps.isPrivileged &&
    deps.executed && !deps.dealIsClosed && {
      label: "Confirm transfer",
      disabled: false,
      size: "large",
      type: "secondary",
      ghost: true,
      onClick: handleConfirmTransfer
    },
  edit_deal: deps.isPrivileged &&
    deps.canEdit &&
    deps.canEditFees && {
      label: "Edit deal",
      size: "large",
      type: "secondary",
      ghost: true,
      onClick: handleDealEdit
    },
  delete_deal: deps.isPrivileged &&
    deps.canEdit &&
    deps.canEditFees && {
      label: "Delete deal",
      type: "danger",
      ghost: true,
      size: "large",
      onClick: handleIoDelete
    },
  captable: deps.isPrivileged &&
    deps.dealIsClosed && {
      label: "View Cap-table",
      size: "large",
      onClick: handleStateChange("captableModalOpen", true),
      disabled: !deps.dealIsClosed,
      tooltip: !deps.dealIsClosed
        ? "Captable data is available only for close deals."
        : null
    },
  invest: ((!deps.isPrivileged && deps.executed) ||
    (!deps.isPrivileged && deps.closed)) &&
    !deps.dealIsClosed && {
      label: "Invest",
      size: "large",
      onClick: handleStateChange("invProcessModalOpen", true),
      disabled: deps.dealIsClosed || !deps.canInvest,
      tooltip: !deps.canInvest ? (
        <>
          Before you commit, please{" "}
          <NavLink to="/account-settings"> click here </NavLink> to fill out
          your profile form.
        </>
      ) : null
    },
  soft_commit: !deps.isPrivileged &&
    !deps.executed && {
      label: deps.pendingSoftCommit
        ? "Edit soft commitment"
        : "Place soft commitment",
      size: "large",
      onClick: handleStateChange("invProcessModalOpen", true),
      tooltip: !deps.pendingSoftCommit
        ? "By placing a soft commitment, you can reserve your desired allocation in the deal. The soft commitment is not binding, you can change or withdraw it at any time."
        : null
    },
  cta_btn: deps.isPrivileged &&
    deps.dealIsClosed &&
    deps.closed < closedValues.APPROVED_BY_LAWYERS && {
      label: "Confirm details",
      size: "large",
      onClick: handleStateChange("fundDealModal", true),
      ghost: true,
      disabled: deps.hasMandatoryMissingDocs,
      tooltip: deps.hasMandatoryMissingDocs
        ? "This deal has missing mandatory documents. Upload them and come back to fill the form."
        : "Keep your investors informed by confirming the investment details here."
    }
});
