import { NavLink } from "react-router-dom";
import { basicOption } from "#helpers/general";
import { claTsKeys } from "#constants/cla";
import { formatNumber } from "#helpers/number_format";
import { confirmationPrompt } from "../../../common/prompts";
import { generalInformationsCols } from "./tableCols";

const generalFields = (
  values,
  shares,
  handlers,
  governingLaw,
  saveFormData,
  currPath
) => {
  values.sharesNumber = shares;
  values[claTsKeys.GOVERNING_LAW] = "Swiss Law";
  values.registeredSeat = "registered_seat";
  return [
    {
      title: "How many issued shares does your company have?",
      input: {
        type: "number",
        name: claTsKeys.SHARES_OUTSTANDING,
        formatter: value => formatNumber(value),
        value: shares,
        disabled: true
      }
    },
    {
      title:
        "What is the name of the financing round the company intends to raise next?",
      input: {
        type: "select_btn",
        name: claTsKeys.SERIES_ROUND,
        options: [
          basicOption("Pre-Seed"),
          basicOption("Seed"),
          basicOption("Series A"),
          basicOption("Series B")
        ]
      }
    },
    {
      title:
        "What is the name of this convertible loan (the name should be different from the existing convertible loan(s) already contained in the convertible loan management section)?",
      input: {
        type: "text",
        name: claTsKeys.NAME,
        placeholder: "Convertible Loan A"
      }
    },
    {
      title: "What governing law should be applicable to the term sheet?",
      input: {
        type: "text",
        name: claTsKeys.GOVERNING_LAW,
        value: "Swiss Law",
        disabled: true
      }
    },
    {
      title: "What jurisdiction should be applicable to the term sheet?",
      input: {
        type: "text",
        name: "registeredSeat",
        disabled: true,
        value: governingLaw
      }
    },
    // values[claTsKeys.GOVERNING_LAW] === "Other" && {
    //   input: {
    //     type: "autocomplete",
    //     name: claTsKeys.GOVERNING_LAW_OTHER,
    //     dataSource: cantonsSortedByName,
    //     filterOption: true
    //   }
    // },
    {
      title: "What is the term (in months) of the convertible loan?",
      input: {
        type: "text",
        name: claTsKeys.VALIDITY,
        addonAfter: "month(s)",
        placeholder: "123"
        // formatter: value => `${value}${value ? " months" : ""}`,
        // parser: value => value.replace(" months", "")
      }
    },
    {
      title: (
        <span>
          Is the convertible loan and accrued interest thereon (if any)
          subordinated in the sense of{" "}
          <NavLink to="/doc_viewer?doc=/docs/Art725.pdf">
            art. 725 para. 2 CO?
          </NavLink>
        </span>
      ),
      input: {
        name: claTsKeys.CL_SUBORDINATED,
        type: "select_btn",
        options: [
          basicOption("yes", "Yes", {
            tooltip:
              "If you choose to subordinate your convertible loan, it allows your company’s board to abstain from informing the judge in the sense of art. 725 para 2 CO in case of over-indebtedness of your company (which may trigger bankruptcy). However, if you choose this option, the convertible loan will still be displayed as debt on your balance sheet. According to market standards, subordination of a convertible loan is deemed founder-friendly."
          }),
          basicOption("no", "No", {
            tooltip:
              "If you choose not to subordinate your convertible loan, the convertible loan counts legally as normal debt. In case your company is not able to cover the debts with your company assets, it is legally required by the board to inform the judge about the over-indebtedness (which may lead to bankruptcy)."
          })
        ]
      }
    },
    values[claTsKeys.CL_SUBORDINATED] === "yes" && {
      title:
        "Shall this loan be equally managed (without discrimination or preference) with other unsecured subordinated obligations of the company?",
      input: {
        name: claTsKeys.EQUALLY_MANAGED,
        ...confirmationPrompt
      }
    },
    {
      title: "Who shall sign this term sheet?",
      input: {
        type: "select_btn",
        name: claTsKeys.TS_SIGNED_BY,
        options: [
          basicOption("board_of_directors", "Board of directors"),
          basicOption(
            "board_of_directors_and_founders",
            "Board of directors and founders"
          ),
          basicOption(
            "board_of_directors_and_shareholders",
            "Board of directors and all existing shareholders"
          )
        ]
      }
    },
    values[claTsKeys.TS_SIGNED_BY] && {
      title:
        "Which member of the board of directors of your company shall sign this term sheet?",
      input: {
        type: "table_list",
        name: "signingDirector",
        cols: generalInformationsCols(values, handlers)
      }
    },
    values[claTsKeys.TS_SIGNED_BY] && {
      showId: false,
      title: (
        <span>
          If you want to add a board member{" "}
          <NavLink
            to={`/stakeholders?redirect=${currPath}?step=0`}
            onClick={saveFormData(values)}
          >
            click here
          </NavLink>
        </span>
      ),
      className: "_hidden",
      input: {}
    }
  ];
};

export default generalFields;
