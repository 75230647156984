import { baseSelector } from "./utils";

const selectCommitDataFunc = state => {
  const data = (state._data && state._data.commits) || [];

  return data;
};

const selectCommitData = baseSelector(selectCommitDataFunc);

export { selectCommitData };
