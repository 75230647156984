import i18n from "../../../i18n";
import EasyFundraising from "../../../assets/illustrations/startup page/EasyFundraising.svg";
import LessAdminMoreFocus from "../../../assets/illustrations/startup page/LessAdminMoreFocus.svg";
import FutureAttractivity from "../../../assets/illustrations/startup page/FutureAttractivity.svg";
import Invite from "../../../assets/illustrations/startup page/invite.png";
import Upload from "../../../assets/illustrations/startup page/upload.png";
import Collect from "../../../assets/illustrations/startup page/collect.png";

export const features = size => [
  {
    header: i18n.t("landing.startup.headers.easy_fundrising"),
    textLeft: true,
    text: i18n.t("landing.startup.features.easy_fundrising"),
    icon: EasyFundraising
  },
  {
    header: i18n.t("landing.startup.headers.less_admin"),
    textLeft: size.width <= 768 ? true : false,
    text: i18n.t("landing.startup.features.less_admin"),
    icon: LessAdminMoreFocus
  },
  {
    header: i18n.t("landing.startup.headers.future_attractivity"),
    textLeft: true,
    text: i18n.t("landing.startup.features.future_attractivity"),
    icon: FutureAttractivity
  }
];

export const howItWorks = [
  {
    title: "Setup your terms and upload deal docs",
    content:
      "Setup the deal terms, choose the funding goal, upload relevant documents to your private data room",
    icon: Upload
  },
  {
    title: "Invite co-investors to your campaign",
    content:
      "Send a private invitation to your co-investors and allow them to check the deal and participate in the campaign",
    icon: Invite
  },
  {
    title: "Collect commitments and investments",
    content:
      "Co-investors can commit and send funds online. We make investing in your business easier than ever",
    icon: Collect
  }
];

export const faqs = [
  {
    question: "How many investors can participate?",

    answer:
      "There is no limitation in the number of investors. You can invite as many investors as you want."
  },
  {
    question: "Do investors have to be accredited investors?",
    answer:
      "No, with LEVA co-investors don't have to be accredited investors. You can raise funds from anyone. "
  },
  {
    question: "Who can see the terms of the investment?",
    answer: "Only the investors you invite to participate in the funding round."
  },
  {
    question: "Can investors invest via a legal entity, like a holding?",
    answer:
      "Yes, if the legal entity meets the compliance requirements of LEVA."
  },
  {
    question: "How much time does it take to set up my company on LEVA?",
    answer: "Less than 10 minutes."
  },
  {
    question: "I have international investors. Is that ok?",
    answer:
      "Yes, if they are not US taxpayers and you comply with the local distribution regulations."
  },
  {
    question: "Is LEVA a crowdfunding platform?",
    answer:
      "No, the deals on LEVA are private and you must bring your own investors."
  },
  {
    question: "What happens if there is a follow-on round?",
    answer:
      "Open a new syndicate and invite new investors or ask your existing investors to commit pro rata to the round."
  }
];
