import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { roles } from "#constants/roles";
import { useHistory } from "react-router-dom";
import { EmailSender } from "../../../../../Shared/Modals/";
import SharedTable from "../../../../../Shared/SharedTable/Presentational";
import { Button } from "../../../../../Shared/Button";
import {
  filteringOpts,
  investorListColumns
} from "../../components/definitions";
import { MemberAddIcon } from "../../../../../Shared/Icons/Investors";
import ConfirmationModal from "../../components/ConfirmationModal";
import DealsListModal from "../../components/DealsListModal";
import SpinContainer from "../../../../../Shared/SpinContainer";

const InvestorsListPresentational = ({
  loading,
  membershipIsFetching,
  clearState,
  didCreate,
  bulkDeleteEntityMemberships,
  fetchAllIos,
  entityId,
  ios,
  getPortfolioIoMemberships,
  bulkCreateIoMemberships,
  userRole,
  membershipColl,
  userCuid,
  getNdas,
  ndaColl,
  getNdaDoc
}) => {
  const history = useHistory();
  const downloadRef = useRef();

  const [state, setState] = useState({
    selectedRows: [],
    memberships: []
  });

  const [filteredInfo, setFilteredInfo] = useState({ status: "" });
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: "",
    order: "ascend"
  });

  useEffect(() => {
    fetchAllIos(entityId);
    getPortfolioIoMemberships({ role: userRole });
    getNdas();
    clearState("entities");
    clearState("ioMemberships");
  }, []);

  useEffect(() => {
    if (didCreate) {
      setTimeout(() => {
        history.goBack();
      }, 3001);
    }
  }, [didCreate]);

  useEffect(() => {
    if (state.inviteModalOpen) {
      clearState("ioMemberships");
    }
  }, [state.inviteModalOpen]);

  useEffect(() => {
    if (ndaColl.success) {
      const { data } = ndaColl;

      const reducedNdas = data.reduce(
        (acc, { userId, cuid }) => ({
          ...acc,
          [userId]: cuid
        }),
        {}
      );

      setState({ ...state, ndas: reducedNdas });
    }
  }, [ndaColl.success]);

  useEffect(() => {
    if (membershipColl.didFetch && state.ndas) {
      const { data } = membershipColl;
      const { ndas } = state;

      const array = data.reduce((acc = [], item) => {
        const { userId, io, user, status } = item;
        if (userId !== userCuid) {
          const usrObjIdx = acc.findIndex(
            ({ userId: locUserId }) => locUserId === userId
          );

          if (usrObjIdx !== -1) {
            acc[usrObjIdx] = {
              ...acc[usrObjIdx],
              name: `${user.firstName} ${user.lastName}`,
              deals: [...acc[usrObjIdx].deals, { ...io, status }]
            };
          } else {
            acc.push({
              userId,
              name: `${user.firstName} ${user.lastName}`,
              ...user,
              nda: ndas[userId],
              deals: [io]
            });
          }
        }
        return acc;
      }, []);

      setState({ ...state, memberships: array });
    }
  }, [membershipColl, state.ndas]);

  useEffect(() => {
    if (ndaColl?.ndaGenerated) downloadRef.current?.click();
  }, [ndaColl?.ndaGenerated]);

  const handleStateChange = newState => () =>
    setState({ ...state, ...newState });

  const handleDropdownChange = ({ name, value }) => () =>
    setState({ ...state, [name]: value });

  const handleRowSelection = ({ userId }, selected) => {
    const { selectedRows = [] } = state;

    if (selected) {
      setState({ ...state, selectedRows: [...selectedRows, userId] });
    } else {
      setState({
        ...state,
        selectedRows: selectedRows.filter(item => item !== userId)
      });
    }
  };

  const handleMembershipCreation = ioId => {
    const { selectedRows = [], memberships } = state;

    const payload = memberships
      .map(({ userId, email }) => {
        if (selectedRows.includes(userId)) {
          return {
            userEmail: email,
            ioId,
            redirectUrlForNewUsers: `${process.env.HOST}/onboarding`,
            redirectUrlForExistingUsers: `${process.env.HOST}/login?redirectTo=invitations`,
            avoidNotification: userRole === roles.FOUNDER
          };
        }
      })
      .filter(Boolean);

    bulkCreateIoMemberships(payload);
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  const selected =
    filteringOpts.find(({ value }) => value === state.filter) || {};

  /* TO BE INTEGRATED WHEN THE FULL FUNCTIONALITY IS READY */

  const actionContainer = (
    <div className="custom-actions">
      <Button label="Resend invite" type="primary" />
      <Button
        label="Send email"
        type="primary"
        onClick={handleStateChange({ emailSenderOpen: true })}
      />
      <Button label="Delete investor" type="primary" />
      <Button
        onClick={handleStateChange({ inviteModalOpen: true })}
        className="custom-btn plus"
        label={<Icon component={MemberAddIcon} />}
        disabled={state.selectedRows.length === 0}
        tooltip={
          state.selectedRows.length === 0
            ? "Please select at least one investor."
            : ""
        }
        tooltipProps={{
          placement: "topRight"
        }}
      />
    </div>
  );

  const inviteModalProps = {
    visible: state.inviteModalOpen,
    onCancel: handleStateChange({
      inviteModalOpen: false
    }),
    ios
  };

  const { deals = [] } =
    typeof state.dealModal === "string"
      ? state.memberships.find(({ userId }) => userId === state.dealModal)
      : {};

  const dealListModalProps = {
    visible: !!state.dealModal,
    onCancel: handleStateChange({
      dealModal: false
    }),
    deals
  };

  const filteredMemberships =
    state.memberships.filter(
      ({ deals = [] }) =>
        !state.dealName ||
        deals.some(({ name }) =>
          name.toLowerCase().includes(state.dealName.toLowerCase())
        )
    ) || [];

  const accumulatedDeals = state.memberships.reduce(
    (acc, { userId, deals = [] }) => {
      return state.selectedRows.includes(userId) ? [...acc, ...deals] : acc;
    },
    []
  );

  const finalColumns = investorListColumns({
    sortedInfo,
    filteredInfo,
    getNdaDoc
  }).filter(({ role }) => !role || role === userRole);

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo({
      columnKey: sorter.columnKey,
      order: sorter.order
    });
  };

  if (filteredMemberships.length > 0 && membershipColl?.didFetch)
    return (
      <div className="investors-list-wrapper">
        {ndaColl?.nda && ndaColl?.ndaGenerated && (
          <a
            ref={downloadRef}
            href={ndaColl?.nda?.value}
            download={`${ndaColl?.nda?.contractName} - ${ndaColl?.nda?.createdAt}`}
            target="_blank"
            rel="noopener noreferrer"
          />
        )}
        <ConfirmationModal
          {...inviteModalProps}
          handleMembershipCreation={handleMembershipCreation}
          loading={membershipColl.isCreating}
          didCreate={membershipColl.didCreate}
          accumulatedDeals={accumulatedDeals}
        />
        <EmailSender
          visible={state.emailSenderOpen}
          onCancel={handleStateChange({ emailSenderOpen: false })}
        />
        <DealsListModal {...dealListModalProps} />
        <SharedTable
          columns={finalColumns}
          dataSource={filteredMemberships}
          tableProps={{
            onChange: handleChange,
            loading,
            hasRowSelection: true,
            rowSelection: {
              type: "checkbox",
              onSelect: handleRowSelection
            },
            pagination: {
              pageSizeOptions: ["10", "25", "50"],
              showSizeChanger: true
            }
          }}
        />
      </div>
    );
  else if (
    !membershipIsFetching &&
    membershipColl?.didFetch &&
    filteredMemberships.length === 0
  )
    return (
      <div className="no-memberships-wrapper">
        <h3>
          Here you will find a database with all the investors invited by you on
          Leva
        </h3>
        <p>You haven't invited any investor yet, please come back later</p>
      </div>
    );

  return <SpinContainer />;
};

InvestorsListPresentational.propTypes = {
  membershipColl: PropTypes.object,
  loading: PropTypes.bool,
  clearState: PropTypes.func,
  didFetch: PropTypes.bool,
  didCreate: PropTypes.bool,
  bulkDeleteEntityMemberships: PropTypes.func,
  fetchAllIos: PropTypes.func,
  entityId: PropTypes.string,
  ios: PropTypes.array,
  getPortfolioIoMemberships: PropTypes.func,
  bulkCreateIoMemberships: PropTypes.func,
  userRole: PropTypes.string,
  userCuid: PropTypes.string,
  getNdas: PropTypes.func,
  ndaColl: PropTypes.object
};

export default InvestorsListPresentational;
