import { initialState, baseReducer } from "../../utils/helper_reducers";
import { sortByDate } from "#helpers/general";
import types from "./action_types";
import actions from "../../constants/action_type";
const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.FETCH_SYNDICATES:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          const sortedList = sortByDate(action?.payload?.data);
          return {
            ...state._data,
            list: sortedList
          };
        }
      });
    case types.FETCH_PORTFOLIO_SYNDICATES:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          const syndicatesWhereUserIsAM = sortByDate(
            action?.payload?.data?.syndicatesWhereUserIsAM
          );
          const syndicatesWhereUserIsInvestor = sortByDate(
            action?.payload?.data?.syndicatesWhereUserIsInvestor
          );
          return {
            ...state._data,
            syndicatesWhereUserIsAM,
            syndicatesWhereUserIsInvestor
          };
        }
      });

    case types.FETCH_SYNDICATE:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            syndicate: action.payload.data
          };
        }
      });

    case types.CREATE_SYNDICATE:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return { ...state._data };
        }
      });

    case types.UPDATE_SYNDICATE:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          return { ...state._data };
        }
      });

    case types.DELETE_SYNDICATE:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          return { ...state._data };
        }
      });
    default:
      return state;
  }
};

export default reducer;
