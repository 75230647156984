import { useLocation } from 'react-router-dom';
import GuestLayout from './GuestLayout';
import MemberLayout from './MemberLayout';

const LayoutMarkup = ({ authCompleted, children }) => {
  const location = useLocation();
  const showDrawer =
    !location.pathname.includes('onboarding') &&
    !location.pathname.includes('doc_viewer');

  const renderContent = () => {
    if (authCompleted) {
      return <MemberLayout drawer={showDrawer}>{children}</MemberLayout>;
    }

    return <GuestLayout>{children}</GuestLayout>;
  };

  return <div className="appWrapper">{renderContent()}</div>;
};

export default LayoutMarkup;
