import { useCallback, useEffect, useState } from "react";
import snsWebSdk from "@sumsub/websdk";
import SpinContainer from "../../Shared/SpinContainer";
import SumsubWebSdk from "@sumsub/websdk-react";
import * as userService from "../../../../api/userService";
import { notification } from "antd";
import i18n from "../../../i18n";

const KycComponent = ({ user }) => {
  const [sumsubToken, setSumsubToken] = useState("");

  useEffect(async () => {
    //generate KYC token on initial mount
    try {
      const generateNewToken = await userService.generateKycToken({
        levelName: "basic-kyc-level"
      });
      const newAccessToken = await generateNewToken.data.token;
      setSumsubToken(newAccessToken);
    } catch (error) {
      return notification.error({
        description: i18n.t("errors.server_errors.common"),
        duration: 2
      });
    }
  }, []);

  // EXPIRATION HANDLER
  const handleExpiredToken = async () => {
    let newAccessToken = "";
    /* generate a new toke and launch WebSDK again */
    try {
      //generate a new token in BE
      const generateNewToken = await userService.generateKycToken({
        levelName: "basic-kyc-level"
      });
      newAccessToken = await generateNewToken.data.token;
    } catch (error) {
      //if an error occurs, refresh the page to start the process again
      window.location.reload();
    }
    //return the  newly generated token
    return newAccessToken;
  };

  const sumsubConfig = {
    lang: "en",
    email: user.email,
    phone: user.phoneNumber // if available
  };

  const handleMessage = (type, payload = {}) => {
    if (payload.height && payload.height === 670) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleError = error => {
    console.log("WebSDK onError", error);
  };

  return (
    <div id="sumsub-websdk-container">
      {sumsubToken ? (
        <SumsubWebSdk
          baseUrl={process.env.KYC_HOST}
          accessToken={sumsubToken}
          expirationHandler={handleExpiredToken}
          config={sumsubConfig}
          onMessage={handleMessage}
          onError={handleError}
        />
      ) : (
        <SpinContainer />
      )}
    </div>
  );
};

export default KycComponent;
