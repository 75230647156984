const Timeline = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8742 3.77887C20.684 3.53425 20.385 3.42552 20.086 3.47988L15.71 3.1809C15.2479 3.15374 14.8674 3.47988 14.8403 3.94196C14.8131 4.40405 15.1393 4.78455 15.6013 4.81171L18.0204 4.97481L12.8833 8.97028L8.5345 5.51841C8.20836 5.27379 7.74628 5.30095 7.47445 5.57277L2.77228 10.0574C2.44614 10.3564 2.44614 10.8728 2.74512 11.199C2.90822 11.3621 3.12563 11.4436 3.34309 11.4436C3.53335 11.4436 3.75081 11.3621 3.91385 11.2262L8.07242 7.25784L12.394 10.6554C12.693 10.9 13.1007 10.9 13.3997 10.6554L19.2706 6.06191L19.0804 8.48094C19.0532 8.94302 19.3794 9.32353 19.8414 9.35068C19.8686 9.35068 19.8958 9.35068 19.8958 9.35068C20.3307 9.35068 20.684 9.02454 20.7112 8.58962L21.0373 4.34952C21.0373 4.32237 21.0373 4.29516 21.0373 4.268C21.0373 4.10501 20.983 3.91476 20.8742 3.77887Z"
        fill="#18DC9B"
      />
      <path
        d="M23.1846 19.1899H20.7384V14.3518C20.7384 13.8898 20.3851 13.5365 19.9231 13.5365C19.461 13.5365 19.1077 13.8898 19.1077 14.3518V19.1899H16.7702V12.6939C16.7702 12.2318 16.4168 11.8785 15.9548 11.8785C15.4927 11.8785 15.1394 12.2318 15.1394 12.6939V19.1899H12.8019V15.0042C12.8019 14.5421 12.4486 14.1888 11.9865 14.1888C11.5245 14.1888 11.1711 14.5421 11.1711 15.0042V19.1899H8.86085V11.7154C8.86085 11.2533 8.5075 10.9 8.04547 10.9C7.58344 10.9 7.23009 11.2534 7.23009 11.7154V19.1899H4.89243V15.3303C4.89243 14.8682 4.53908 14.5149 4.07705 14.5149C3.61502 14.5149 3.26167 14.8683 3.26167 15.3303V19.1899H0.815482C0.35335 19.1899 0 19.5433 0 20.0053C0 20.4674 0.35335 20.8207 0.815378 20.8207H23.1846C23.6467 20.8207 24 20.4674 24 20.0053C24 19.5433 23.6467 19.1899 23.1846 19.1899Z"
        fill="#597EF7"
      />
    </svg>
  );
};

export default Timeline;
