import Modal from "../../Modal";

const withModalWrapper = ({
  title,
  visible,
  handleClose,
  closable
}) => Target => props => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handleClose}
      footer={null}
      closable={closable}
    >
      <Target
        modalProps={{
          title,
          visible,
          handleClose,
          closable
        }}
        {...props}
      />
    </Modal>
  );
};

export default withModalWrapper;
