import format from "date-fns/format";
import { renderType } from "#helpers/general";
import TagWrapper from "../../../../../Shared/Tag";
import AvatarWrapper, { AvatarUploader } from "../../../../../Shared/Avatar";
import TooltipWrapper from "../../../../../Shared/Tooltip";
import { industries } from "../../../../../Shared/FilterSidebar/filters";

import Details from "../../Views/ListView/components/Details";
import link from "../../../../../../assets/illustrations/urllink.png";
import location from "../../../../../../assets/illustrations/location.png";
import industriesIcon from "../../../../../../assets/illustrations/industries.png";
import dmguidelineIcon from "../../../../../../assets/illustrations/dmguideline.png";

const DealTitle = ({
  title,
  websiteUrl,
  syndicateName,
  previewImage,
  updatedPreviewImage,
  handlePreviewUpload,
  address: { postalCode = "", city = "", country = "", street = "" } = {},
  handleAvatarDblClick,
  className = "",
  editable,
  canEdit,
  createdAt,
  type,
  details,
  entityIndustries = [],
  isPrivileged,
  assetManager,
  isInvestor
}) => {
  const localIndustries =
    Array.isArray(entityIndustries) && entityIndustries.length > 2
      ? entityIndustries.slice(0, 3)
      : entityIndustries;

  const flattenIndustries = industries.reduce(
    (acc, { label, value }) => ({ ...acc, [value]: label }),
    {}
  );

  const Title = props => <h2 {...props}>{title}</h2>;

  const finalClassname = `deal-title ${className}`;

  const profilePic = updatedPreviewImage
    ? URL.createObjectURL(updatedPreviewImage)
    : previewImage;

  const isStandalone = className === "standalone";

  const handleDmGuidelinesClick = () => {
    window.open("/doc_viewer?doc=/docs/managerGuidelines.pdf");
  };
  return (
    <div className={finalClassname}>
      <div className="img-wrapper">
        {editable && canEdit ? (
          <AvatarUploader
            profilePic={profilePic}
            onUpload={handlePreviewUpload}
          />
        ) : canEdit ? (
          <TooltipWrapper title="Double click to edit">
            <AvatarWrapper
              src={`${previewImage}?${Date.now()}`}
              onDoubleClick={handleAvatarDblClick}
              shape="square"
            />
          </TooltipWrapper>
        ) : (
          <AvatarWrapper
            src={`${previewImage}?${Date.now()}`}
            onDoubleClick={handleAvatarDblClick}
            shape="square"
          />
        )}
      </div>
      <div className="title">
        <div className="inline-block">
          <Title />
          <div className="tags-wrapper">
            {syndicateName && (
              <TooltipWrapper title="The name of the syndicate">
                <TagWrapper color="black" nativeColor>
                  {syndicateName}
                </TagWrapper>
              </TooltipWrapper>
            )}
            {!isStandalone && type && type !== "target_entity" && (
              <TooltipWrapper title="Type of round">
                <TagWrapper color="transparent" nativeColor>
                  {renderType(type)}
                </TagWrapper>
              </TooltipWrapper>
            )}
            {!isStandalone && createdAt && (
              <TooltipWrapper title="The date of creation">
                <TagWrapper color="transparent" nativeColor>
                  {format(createdAt, "DD.MM.YYYY")}
                </TagWrapper>
              </TooltipWrapper>
            )}
            {isPrivileged && (
              <TooltipWrapper title="Deal manager guideline">
                <img
                  className="dm-guidelines-icon"
                  onClick={handleDmGuidelinesClick}
                  src={dmguidelineIcon}
                />
              </TooltipWrapper>
            )}
          </div>
        </div>
        {localIndustries.length > 0 && (
          <span className="industries">
            {localIndustries.map(industry => (
              <span>{flattenIndustries[industry]} </span>
            ))}
          </span>
        )}
        <span>
          <a href={websiteUrl} target="_blank">
            <img
              className="title-icon-link"
              src={link}
              alt="link"
              height="30"
            />
          </a>
          {websiteUrl}
          <img
            className="title-icon-location"
            src={location}
            alt="location"
            height="30"
          />
          {` ${street} | ${postalCode} ${city} | ${country}`}
        </span>
        {assetManager?.name && !isInvestor && (
          <span className="am-wrapper">
            <AvatarWrapper
              className="am-icon"
              src={assetManager?.previewImage}
              shape="square"
            />
            <h3 className="am-name">{assetManager?.name}</h3>
          </span>
        )}
        {details && <Details selectedEntity={details} />}
      </div>
    </div>
  );
};

export default DealTitle;
