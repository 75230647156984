import { withFormik } from "formik";

import Input from "../../Shared/Input";
import { LoadingButton } from "../../Shared/Button/components/BtnWrapper/Presentational";

import ResetSchema from "#validators/resetPasswordSchema";
import i18n from "../../../i18n";
import { checkPasswordRequirements } from "#helpers/general";

const PswInput = ({
  error,
  name,
  value,
  helper,
  details,
  onChange,
  ...props
}) => (
  <div className="psw-input">
    <Input
      name={name}
      value={value}
      onChange={onChange}
      type="password"
      details={details}
      {...props}
    />
    {error && (
      <span style={{ color: "red", textAlign: "left", width: "100%" }}>
        {error}
      </span>
    )}
    {helper && <span>{helper}</span>}
  </div>
);

const Form = ({
  values,
  handleSubmit,
  handleChange,
  touched,
  errors,
  isUpdating,
  didUpdate
}) => {
  const passwordRequirements = checkPasswordRequirements(values.new_password);

  return (
    <div className="form-wrapper">
      <h4>{i18n.t("reset_psw.reset_placeholder")}</h4>
      <PswInput
        name="new_password"
        value={values.new_password}
        details={i18n.t("reset_psw.new_password")}
        onChange={handleChange}
        onPressEnter={handleSubmit}
        error={
          touched.new_password && errors.new_password ? errors.new_password : ""
        }
        helper={
          passwordRequirements.length === 0 ? (
            <span>Great! Your password matches the requirements!</span>
          ) : (
            passwordRequirements.length > 0 && (
              <span>
                Password requirements: <br />
                {passwordRequirements.map(requirement => (
                  <span>
                    {requirement} <br />
                  </span>
                ))}
                <br />
              </span>
            )
          )
        }
      />
      <PswInput
        name="new_password_confirm"
        value={values.new_password_confirm}
        details={i18n.t("reset_psw.new_password_confirm")}
        onChange={handleChange}
        onPressEnter={handleSubmit}
        error={
          touched.new_password_confirm && errors.new_password_confirm
            ? errors.new_password_confirm
            : ""
        }
      />
      <div className="btn-container">
        <LoadingButton
          label={i18n.t("reset_psw.change")}
          onClick={handleSubmit}
          loading={isUpdating}
          disabled={didUpdate}
          success={didUpdate}
          className="reset-psw-btn"
        />
      </div>
      {didUpdate && <h4>{i18n.t("reset_psw.update_success")}</h4>}
    </div>
  );
};

const Wrapper = withFormik({
  mapPropsToValues: () => ({
    new_password: "",
    new_password_confirm: ""
  }),
  validationSchema: ResetSchema,
  handleSubmit: (values, { props: { handleSubmit } }) => {
    handleSubmit(values);
  }
})(Form);

export default Wrapper;
