import { getTaxes, postTaxes, patchTaxes } from "#core/modules/taxes/actions";
import { readCurrencies } from "#core/modules/currency/actions/read";
import { clearState } from "#core/utils/helper_actions";
import { connect } from "react-redux";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    isCreating,
    didFetch,
    isUpdating,
    didUpdate,
    didCreate,
    _data: { taxes = [] } = {}
  } = state.taxes;
  const { entity: { cuid: entityId } = {} } = state.entities._data || {};
  const latestTax = taxes[taxes.length - 1] || {};
  const { currencies = [] } = state.currency._data || {};
  const frmtdCurrencies = currencies.reduce(
    (acc, { cuid, shortName }) => ({
      ...acc,
      [cuid]: shortName
    }),
    {}
  );

  return {
    buttonStates: {
      loading: isCreating || isUpdating,
      success: didCreate || didUpdate
    },
    entityId,
    taxes: latestTax,
    taxesDidFetch: didFetch,
    currencies: frmtdCurrencies
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    clearState: key => clearState(dispatch, key),
    getTaxes: params => dispatch(getTaxes(params)),
    postTaxes: data => dispatch(postTaxes(data)),
    patchTaxes: data => dispatch(patchTaxes(data)),
    readCurrencies: data => dispatch(readCurrencies(data))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
