import * as membershipsService from "../../../../api/ioMembershipsService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const putIoMembershipStatus = ({ entityId, ...data }) => {
  return async dispatch => {
    try {
      start(dispatch, types.PUT_IO_MEMBERSHIP_STATUS, {
        data
      });

      await membershipsService.putMembershipStatus(data);

      success(dispatch, types.PUT_IO_MEMBERSHIP_STATUS, {
        data: { entityId, ...data }
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.PUT_IO_MEMBERSHIP_STATUS, {
        error: errorData
      });
    }
  };
};

export const patchMembershipStatus = data => {
  return async dispatch => {
    try {
      start(dispatch, types.PATCH_IO_MEMBERSHIP, {
        data
      });

      await membershipsService.patchMembership(data);

      success(dispatch, types.PATCH_IO_MEMBERSHIP, {
        data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.PATCH_IO_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};
