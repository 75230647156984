import { connect } from "react-redux";
import {
  createEntity,
  getEntity,
  updateEntity
} from "#core/modules/entities/actions";
import { clearStateDataByKey, clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = ({ entities }) => {
  const {
    companyDetails,
    detailsFetched,
    entitiesList,
    entity: { userRole, type: entityType } = {}
  } = entities._data || {};
  const { cuid: lastEntityCuid } = entitiesList[entitiesList.length - 1] || {};
  const { entity = {} } = entities._data || {};

  return {
    companyDetails: {
      isFetching: entities.isFetching,
      didFetch: detailsFetched,
      data: companyDetails
    },
    lastEntityCuid,
    buttonStates: {
      loading: entities.isCreating || entities.isUpdating,
      success: entities.didCreate || entities.didUpdate,
      created: entities.didCreate
    },
    userRole,
    entityType,
    entity
  };
};

const mapDispatchToProps = dispatch => ({
  createEntity: data => dispatch(createEntity(data)),
  updateEntity: data => dispatch(updateEntity(data)),
  getEntity: data => dispatch(getEntity(data)),
  clearStateDataByKey: (...keys) => clearStateDataByKey(dispatch, ...keys),
  clearState: key => clearState(dispatch, key)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
