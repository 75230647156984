import { useEffect } from "react";
import ModalWrapper from "../../Shared/Modal";
import { Button } from "../../Shared/Button";

export default function VotingModal({
  onCancel,
  onSubmit,
  success,
  loading,
  ...modalProps
}) {
  useEffect(() => {
    if (success) {
      onCancel();
    }
  }, [success]);

  return (
    <ModalWrapper
      title="Your about to confirm and publish your votes"
      width={500}
      footer={null}
      closable={false}
      {...modalProps}
    >
      <div>
        <h3>
          Be sure you are okay with your picks as you are submitting your votes,
          and won't be able to edt our votes anmore
        </h3>
      </div>
      <div className="btn-container">
        <Button
          label="Edit again"
          size="large"
          type="secondary"
          onClick={onCancel}
        />
        <Button
          label="Submit"
          size="large"
          loading={loading}
          success={success}
          onClick={onSubmit}
        />
      </div>
    </ModalWrapper>
  );
}
