import { useState } from "react";
import InputContainer from "../../Shared/Input";
import { Button } from "../../Shared/Button";
import InputRenderer from "../../Shared/InputRenderer";

// FOR USE IN TS AND IA FORMS

const CustomInputRenderer = formikBag => props => {
  const [verify, setVerify] = useState("");
  const { type } = props;
  const {
    handleSendCodeClick,
    handleVerifyClick,
    phoneNumber,
    success
  } = formikBag;
  if (type === "btn")
    return (
      <div className="send-code-btn-wrapper">
        <Button
          type="secondary"
          label="Send code"
          onClick={handleSendCodeClick}
          size="large"
          disabled={success}
        />
      </div>
    );

  if (type === "verify-input-btn")
    return (
      <div className="verify-input-btn">
        <span className="verify-row">
          <p>Your phone number</p>
          <InputContainer value={phoneNumber} disabled />
        </span>
        <span className="verify-row">
          <InputContainer
            onChange={e => setVerify(e.target.value)}
            value={verify}
          />
          <Button
            type="secondary"
            label="Verify"
            size="large"
            onClick={handleVerifyClick(verify)}
          />
        </span>
      </div>
    );

  return <InputRenderer {...props} />;
};

export default CustomInputRenderer;
