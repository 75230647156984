import { useState } from "react";
import Avatar from "../../../../Shared/Avatar";
import GetStarted from "../../../../../assets/illustrations/getstarted.png";
import AccessCode from "./second-screen/AccessCode";
import ReachOut from "./second-screen/ReachOut";
import ComingSoon from "./second-screen/ComingSoon";

export default function MethodCard({
  title,
  icon,
  type,
  onClick,
  description,
  secondScreen = false
}) {
  const [mouseOver, setMouseOver] = useState(false);
  const [accessCodeScreen, setAccessCodeScreen] = useState(false);
  const [showAccessCodeError, setShowAccessCodeError] = useState(false);
  const [accessCodeInput, setAccessCodeInput] = useState("");

  const handleAccessCodeChange = e => {
    const value = e?.target?.value;
    setShowAccessCodeError(false);
    setAccessCodeInput(value);
    if (value?.length === 4) {
      if (value === "0852") {
        onClick(type)();
      } else {
        setShowAccessCodeError(true);
      }
    }
  };

  const mouseOverContent = () =>
    type === "existing_shareholder" ? (
      <ComingSoon />
    ) : accessCodeScreen ? (
      <AccessCode
        showAccessCodeError={showAccessCodeError}
        handleAccessCodeChange={handleAccessCodeChange}
        accessCodeInput={accessCodeInput}
      />
    ) : (
      <ReachOut setAccessCodeScreen={setAccessCodeScreen} />
    );

  const onMouseLeaveHandler = () => {
    if (secondScreen) {
      setMouseOver(false);
      setAccessCodeScreen(false);
      setShowAccessCodeError(false);
      setAccessCodeInput("");
    }
  };

  return (
    <div
      className={`${
        secondScreen && mouseOver && type === "existing_shareholder"
          ? "second-screen"
          : ""
      } method-container`}
      onMouseEnter={() => secondScreen && setMouseOver(true)}
      onMouseLeave={onMouseLeaveHandler}
      onClick={!secondScreen && onClick(type)}
    >
      {secondScreen && mouseOver ? (
        mouseOverContent()
      ) : (
        <>
          <div className="avatar-wrapper">
            <Avatar shape="square" size="large" className="avatar" src={icon}>
              {title && title[0]}
            </Avatar>
          </div>
          <h3>{title}</h3>
          <p>{description}</p>
          <span className="link">
            Get started
            <img src={GetStarted} alt="get-started" />
          </span>
        </>
      )}
    </div>
  );
}
