import NdaSignature from './components/NdaSignature';

const withNdaSignature = ({ ...modalHandlers }) => (children) => {
  return (
    <div className="unsigned-ndas-wrapper">
      <NdaSignature {...modalHandlers} />
      <div className="main-container">{children}</div>
    </div>
  );
};

export default withNdaSignature;
