import { industries } from "../../../../../../Shared/FilterSidebar/filters";
import TooltipWrapper from "../../../../../../Shared/Tooltip";
import qualifiedInvestorsIcon from "../../../../../../../assets/illustrations/qualified.png";
import TagWrapper from "../../../../../../Shared/Tag";

export default function Details({ selectedEntity }) {
  const localIndustries =
    Array.isArray(selectedEntity.industries) &&
    selectedEntity.industries.length > 2
      ? selectedEntity.industries.slice(0, 3)
      : selectedEntity.industries;
  const flattenIndustries = industries.reduce(
    (acc, { label, value }) => ({ ...acc, [value]: label }),
    {}
  );
  return (
    <div className="details">
      <div className="second-row">
        {selectedEntity?.investmentType && (
          <TagWrapper color="black">
            {selectedEntity?.investmentType === "direct_investment"
              ? "Direct investment"
              : "Structuring vehicle (SPV)"}
          </TagWrapper>
        )}
        {selectedEntity?.companyStatus && (
          <TagWrapper color="transparent">
            {selectedEntity?.companyStatus.charAt(0).toUpperCase() +
              selectedEntity?.companyStatus.slice(1)}
          </TagWrapper>
        )}
        {localIndustries.map(industry => (
          <span className="industries">{flattenIndustries[industry]} </span>
        ))}
        {selectedEntity?.qualifiedInvestorsOnly && (
          <TooltipWrapper title="Qualified investors only">
            <img
              width="40"
              height="40"
              src={qualifiedInvestorsIcon}
              alt="qualifiedInvestors"
            />
          </TooltipWrapper>
        )}
      </div>
      <p className="description">{selectedEntity?.description}</p>
    </div>
  );
}
