import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './action_types';
import actions from '../../constants/action_type';

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.POST_ACTIVITY:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            // TO BE CUSTOMIZED ACCORDING TO OUR NEEDS
            ...state._data
          };
        }
      });
    case types.GET_SINGLE_ACTIVITY:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            // TO BE CUSTOMIZED ACCORDING TO OUR NEEDS
            ...state._data,
            visits: action.payload.data
          };
        }
      });

    case types.GET_DOCS_VISIT:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            docsVisit: action.payload.data
          };
        }
      });
    default:
      return state;
  }
};
export default reducer;
