export default function ReachOut({ setAccessCodeScreen }) {
  return (
    <div className="reach-out-wrapper">
      <h3>Closed beta</h3>
      <p>
        Click
        <a target="_blank" href="https://askleva.typeform.com/to/yMSXOmbl">
          {" "}
          here{" "}
        </a>
        to reach out and participate in our closed beta
      </p>
      <p>
        Or{" "}
        <a onClick={() => setAccessCodeScreen(true)}>
          use your access code now
        </a>
      </p>
    </div>
  );
}
