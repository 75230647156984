import { dealRounds } from "#constants/deal";
import { basicOption } from "#helpers/general";
import { equityTs } from "#constants/io/equityPool";
import { generalInformationsCols } from "./tableCols";

const general = (values, handlers) => [
  {
    title: "What is the name of this financing round?",
    input: {
      type: "select_btn",
      name: equityTs.SERIES_ROUND,
      options: [
        basicOption("Pre-Seed", dealRounds["Pre-Seed"]),
        basicOption("Seed", dealRounds.Seed),
        basicOption("Series A", dealRounds.Series_A, {
          disabled: true,
          tooltip:
            "We currently can’t offer this option. If you wish to conduct the Series A financing round, please contact our partner law firm X. For more information reach out to hi@leva.pe"
        })
      ]
    }
  },
  {
    title: "Who shall sign this term sheet?",
    input: {
      type: "select_btn",
      name: equityTs.SIGNED_BY,
      options: [
        basicOption("board_of_directors", "Board of directors"),
        basicOption(
          "board_of_directors_and_founders",
          "Board of directors and founders"
        ),
        basicOption(
          "board_of_directors_and_shareholders",
          "Board of directors and all existing shareholders"
        )
      ]
    }
  },
  values[equityTs.SIGNED_BY] && {
    title:
      "Which member(s) of the company's board of directors shall sign the term sheet?",
    input: {
      type: "table_list",
      name: "signingDirector",
      cols: generalInformationsCols(values, handlers)
    }
  }
];

export default general;
