import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import SharedTable from "../../../Shared/SharedTable/Presentational";
import { votingArchiveCols, testData } from "./columns";

const VotingDetails = ({
  history,
  votingColl,
  getSingleVoting,
  isAm,
  userId
}) => {
  const { cuid } = useParams();

  useEffect(() => {
    getSingleVoting({ cuid });
  }, []);

  const [state, setState] = useState({
    selectedRows: []
  });

  const handleRowSelection = ({ userId }, selected) => {
    const { selectedRows = [] } = state;
    if (selected) {
      setState({ ...state, selectedRows: [...selectedRows, userId] });
    } else {
      setState({
        ...state,
        selectedRows: selectedRows.filter(item => item !== userId)
      });
    }
  };

  const handleNav = url => () => history.push(url);

  return (
    <div>
      <SharedTable
        columns={votingArchiveCols(isAm, userId, handleNav).filter(Boolean)}
        dataSource={[votingColl.data]}
        tableProps={{
          loading: votingColl.loading,
          hasRowSelection: true,
          rowSelection: {
            type: "checkbox",
            onSelect: handleRowSelection
          }
        }}
      />
    </div>
  );
};

VotingDetails.propTypes = {
  votingColl: PropTypes.object
};

export default VotingDetails;
