import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import SharedTable from "../../../Shared/SharedTable/Presentational";
import { votingArchiveCols, testData } from "./columns";
import { withContentFallback } from "../../../Shared/hocs";

const VotingArchive = ({
  clearState,
  clearStateDataByKey,
  history,
  archiveColl,
  userId,
  entityId,
  getVotings,
  getVotingDoc,
  isAm,
  isInvestor,
  doc = {}
}) => {
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    selectedRows: []
  });

  const downloadRef = useRef();

  useEffect(() => {
    if (archiveColl.docGenerated) {
      downloadRef.current?.click();
      clearStateDataByKey("voting", "doc");
      const updatedData = data.map(item => {
        const { loading, ...rest } = item;
        return rest;
      });
      setData(updatedData);
    }
  }, [archiveColl.docGenerated]);

  useEffect(() => {
    clearState("voting");
    getVotings({ entity_id: entityId });
  }, []);

  useEffect(() => {
    if (Array.isArray(archiveColl?.data) && archiveColl.data.length !== 0)
      setData(archiveColl.data);
  }, [archiveColl?.data]);

  const handleRowSelection = ({ userId }, selected) => {
    const { selectedRows = [] } = state;
    if (selected) {
      setState({ ...state, selectedRows: [...selectedRows, userId] });
    } else {
      setState({
        ...state,
        selectedRows: selectedRows.filter(item => item !== userId)
      });
    }
  };

  const handleNav = url => () => history.push(url);

  const handleDownloadDoc = ({ votingId, ioId }) => () => {
    getVotingDoc({
      entity_id: entityId,
      voting_id: votingId,
      ...(isInvestor && { io_id: ioId })
    });
    setData(
      data.map(voting => {
        if (voting.cuid === votingId) {
          return { ...voting, loading: true };
        }
        return voting;
      })
    );
  };

  const noDataInv = () => (
    <>
      <h3>There are no active voting sessions at the moment </h3>
      <p>
        When a new session is opened, you will be notified by the bell in the
        upper right corner.
      </p>
    </>
  );
  const noDataDM = () => (
    <>
      <h3>
        Here you will find your voting reports <br />
      </h3>
      <p>
        You haven't initiated any voting session yet, please come back later
        <br />
        You can initiate a voting session for any the deals in your portfolio by
        clicking on "Start voting session" in the deal dashboard.
        <br />
        Once the votes of the investors have been collected, a voting report
        will be automatically generated here.
      </p>
    </>
  );

  const mainContent = () => (
    <div>
      {archiveColl.docGenerated && (
        <a
          ref={downloadRef}
          href={doc?.value}
          download={`${doc.contractName} - ${doc?.createdAt}`}
        ></a>
      )}
      <SharedTable
        columns={votingArchiveCols({
          isAm,
          currUser: userId,
          handleNav,
          handleDownloadDoc
        }).filter(Boolean)}
        dataSource={data}
        tableProps={{
          loading: archiveColl.loading
          // hasRowSelection: true,
          // rowSelection: {
          //   type: "checkbox",
          //   onSelect: handleRowSelection
          // }
        }}
      />
    </div>
  );
  return withContentFallback({
    customizedIcon: <div></div>,
    loading: archiveColl.loading,
    empty: archiveColl.data.length === 0,
    customizedEmptyMsg: isInvestor ? noDataInv() : noDataDM()
  })(mainContent)();
};

VotingArchive.propTypes = {
  archiveColl: PropTypes.object
};

export default VotingArchive;
