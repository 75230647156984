import { Menu, Button } from "antd";
import { NavLink } from "react-router-dom";
import Logo from "../../../../../../assets/illustrations/brand.svg";
import MenuHamburger from "../../../../../../assets/illustrations/menu.svg";
import MenuItem from "../../item/MenuItem";
import { googleAndLinkedInTracker } from "#helpers/general";

const { SubMenu } = Menu;

export const logoItem = ({ mobile, handleLogoClick }) => (
  <MenuItem
    className={`${mobile ? "navbar-mr" : "navbar-logo-p"} navbar-menu-w-b`}
    key="1"
  >
    <img width="120" src={Logo} alt="Logo" onClick={handleLogoClick} />
  </MenuItem>
);

export const hamburgerLogoItem = ({ handleHamburgerClick }) => (
  <MenuItem key="2">
    <img
      src={MenuHamburger}
      alt="hamburger-menu"
      height="25px"
      width="25px"
      className="navbar-hamburger-logo"
      onClick={handleHamburgerClick}
    />
  </MenuItem>
);

export const firstMenuItems = mobile => (
  <MenuItem key="3">
    <NavLink to="/">Home</NavLink>
  </MenuItem>
);

export const submenuItems = () => (
  <SubMenu
    className="navbar-center nav-submenu navbar-menu-w-b"
    title={<span>Startups</span>}
  >
    <MenuItem key="9" className="navbar-dropdown-item">
      <NavLink to="/startup">Fundraising</NavLink>
    </MenuItem>
    <MenuItem key="10" className="navbar-dropdown-item">
      <NavLink to="/legal">Legal</NavLink>
    </MenuItem>
  </SubMenu>
);

export const lastMenuItems = mobile => [
  //<MenuItem key="9">
  //  <NavLink to="/deal-managers">Deal managers</NavLink>
  //</MenuItem>,

  // <MenuItem key="4">
  //   <NavLink to="/founders">Founders</NavLink>
  // </MenuItem>,

  //<MenuItem key="5">
  //  <a
  //    href="https://levafundraising.notion.site/Frequent-Asked-Questions-Leva-540ccaf75e934decbac7faaef47316e4"
  //    target="_blank"
  //    rel="noopener noreferrer"
  //  >
  //    FAQ
  //  </a>
  //</MenuItem>,

  <MenuItem key="6">
    <NavLink to="/team">Team</NavLink>
  </MenuItem>,

  <MenuItem
    key="7"
    className={`${
      !mobile ? "navbar-menu-w-b navbar-demo-btn" : "navbar-menu-btn"
    }`}
  >
    <Button
      size="large"
      type="ghost"
      onClick={googleAndLinkedInTracker}
      className={`${mobile && "connect-btn"}`}
    >
      <a target="_blank" href="https://go.leva.pe/contact-us">
        Get in touch
      </a>
    </Button>
  </MenuItem>,

  <MenuItem
    className={`${!mobile && "navbar-sub-menu-button navbar-menu-w-b"}`}
    key="8"
  >
    <NavLink to="/login">Sign in</NavLink>
  </MenuItem>
];
