import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { handleUpload } from "#helpers/inputHandlers";
import { goBack } from "#helpers/general";
import {
  mapClaMgmtValues,
  mapClaMgmtEditValues
} from "#helpers/mappers/convertibles";
import Form from "./form/Presentational";
import { withPageTitle } from "../../Shared/hocs";
import BasicWrapper from "../../Shared/BasicWrapper";

const MgmtPresentational = ({
  entityId,
  postConvertibleTypes,
  buttonStates,
  clearState,
  location,
  updateCla,
  triggerSnackbar,
  didCreate,
  didUpdate,
  convertibles,
  esopShares,
  psopShares,
  ...props
}) => {
  const [state, setState] = useState({});
  const history = useHistory();
  useEffect(() => {
    clearState("convertible");
  }, []);
  useEffect(() => {
    if (buttonStates.success) {
      setTimeout(() => {
        history.goBack();
      }, 3001);
    }
  }, [buttonStates.success]);

  useEffect(() => {
    if (didCreate) {
      triggerSnackbar({
        variant: "success",
        message: "Successfully added a new convertible",
        placement: "bottomLeft",
        open: true
      });
    }
    if (didUpdate) {
      triggerSnackbar({
        variant: "success",
        message: "Successfully updated a convertible",
        placement: "bottomLeft",
        open: true
      });
    }
  }, [didCreate, didUpdate]);

  const handleSubmit = async values => {
    if (location.state) {
      const payload = await mapClaMgmtValues({
        values,
        file: state.cla_upload,
        entityId: undefined,
        esop: esopShares,
        psop: psopShares
      });
      updateCla(location.state.data.cuid, payload);
    } else {
      const payload = await mapClaMgmtValues({
        values,
        file: state.cla_upload,
        entityId,
        esop: esopShares,
        psop: psopShares
      });
      postConvertibleTypes(payload);
    }
  };

  let formValues;

  if (location.state) {
    formValues = mapClaMgmtEditValues(location.state.data);
  }
  return (
    <BasicWrapper className="cla-mgmt-container">
      <h1>
        To set-up the existing convertible loan structure, please create the
        current convertible loan categories your company has. Please use the
        same wording as in your current convertible loan agreement.
      </h1>
      <h4>
        Please note, this information must be accurate as it will automatically
        be inputted into all legal documents.
      </h4>
      <Form
        formValues={formValues}
        buttonStates={buttonStates}
        handleSubmit={handleSubmit}
        handleCancel={goBack(history)}
        handleUpload={handleUpload(setState, state)}
        convertibles={convertibles}
        esopShares={esopShares}
        psopShares={psopShares}
        {...props}
      />
    </BasicWrapper>
  );
};

export default withPageTitle("Existing Convertible Management")(
  MgmtPresentational
);
