import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import AddItemsModal from './AddItems/Presentational';
import { Button } from '../../../../Shared/Button';

const SharesListInput = ({
  modalTitle,
  fields,
  formikProps,
  name,
  value,
  actionButtonTitle,
  itemRenderer
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setFieldValue } = formikProps;

  const handleModalVisibility = (open) => () => setModalOpen(open);
  const handleDelete = (idx) => () => {
    setFieldValue(name, value.filter(({ id }) => id !== idx));
  };

  return (
    <Fragment>
      <AddItemsModal
        onCancel={handleModalVisibility(false)}
        visible={!!modalOpen}
        name={name}
        setFieldValue={setFieldValue}
        value={value}
        fields={fields}
        modalTitle={modalTitle}
      />
      <List
        dataSource={value}
        renderItem={({ id, ...item }) => (
          <List.Item
            key={id}
            actions={[<Button icon="close" onClick={handleDelete(id)} />]}
          >
            {itemRenderer(item)}
          </List.Item>
        )}
      />
      <Button
        label={actionButtonTitle}
        icon="plus"
        onClick={handleModalVisibility(true)}
      />
    </Fragment>
  );
};

SharesListInput.propTypes = {
  modalTitle: PropTypes.string,
  fields: PropTypes.array,
  formikProps: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  actionButtonTitle: PropTypes.string,
  itemRenderer: PropTypes.any
};

export default SharesListInput;
