import { Menu, Dropdown } from "antd";
import { NavLink } from "react-router-dom";

const ActionDropdown = ({
  actionList = [],
  wrapperProps,
  children,
  id = ""
}) => {
  const list = () => (
    <Menu id={id} className="action-dropdown-menu">
      {actionList.map(({ label, onClick, customStyle = "", href }) => (
        <Menu.Item
          key={label}
          onClick={onClick}
          className={`menu-item ${customStyle}`}
        >
          {href ? <NavLink to={href}>{label}</NavLink> : <span>{label}</span>}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={list} trigger={["click"]} {...wrapperProps}>
      <div>{children}</div>
    </Dropdown>
  );
};

export default ActionDropdown;
