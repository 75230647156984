const formSections = [
  {
    id: 1,
    title: "A. Company Details",
    subSections: [
      {
        id: 1,
        title: "Fundraise"
      },
      { id: 2, title: "Board" }
    ]
  },
  {
    id: 3,
    title: "B. Shares",
    subSections: [
      {
        id: 3,
        title: "Conversion"
      },
      { id: 4, title: "Independent valuation" },
      {
        id: 5,
        title: "Vesting"
      }
    ]
  },
  {
    id: 6,
    title: "C. General Information"
  },
  {
    id: 7,
    title: "D. Appendices"
  }
];

export default formSections;
