import { iaKeys } from "#constants/io/equityPool";
import { confirmationPrompt } from "../../../common/prompts";
import { investorWrittenConsentCols } from "./tableCols";

const confidentiality = (values, handlers) => [
  {
    title:
      "Is Company allowed to announce the investment in this financing round without the prior written consent of the investor(s)?",
    input: {
      name: iaKeys.CONSENT_NEEDED_INV_CONFIRMATION,
      ...confirmationPrompt
    }
  },
  values[iaKeys.CONSENT_NEEDED_INV_CONFIRMATION] === "no" && {
    title: "Please select the investors whose consent is needed:",
    input: {
      type: "table_list",
      name: "investorsWrittenConsent",
      cols: investorWrittenConsentCols(handlers)
    }
  }
];

export default confidentiality;
