import DraggerUploader from './DraggerUploader';
import SimpleUploader from './SimpleUploader';

export { DraggerUploader, SimpleUploader };

const UploaderRenderer = ({ type, ...props }) => {
  if (type === 'drag_upload') {
    return <DraggerUploader {...props} />;
  }
  return <SimpleUploader {...props} />;
};

export default UploaderRenderer;
