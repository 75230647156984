import { Spin } from "antd";
import { basicOption } from "#helpers/general";
import { formatNumber } from "#helpers/number_format";
import { sortArrayByName } from "#helpers/arrayFuncs";
import { roles } from "#constants/roles";
import filters from "../../../FilterSidebar/filters";
import { countriesSortedByName } from "../../../../Shared/Settings/countries";
import { cantonsSortedByName } from "../../../../Shared/Settings/cantons";

const companyFields = (
  { handleBlur, zefixHandlers, loading } = {},
  previewImage
) => {
  const {
    industry: { array: industryFields }
  } = filters();
  return [
    {
      id: 16,
      title: "Company Logo",
      className: "_width-50",
      input: {
        type: "avatar_uploader",
        name: "previewImage",
        accept: ".jpg,.png,.jpeg",
        required: true,
        profilePic: previewImage
      }
    },
    {
      id: 7,
      title: "Website",
      className: "_width-50",
      input: {
        type: "text",
        name: "websiteUrl",
        details: "www.supergrowth.com"
      }
    },
    {
      id: 1,
      title: "Company name",
      className: "_width-50",
      input: {
        type: "text",
        name: "name",
        details: "Super Growth AG",
        onBlur: handleBlur || null,
        suffix: loading ? <Spin /> : null,
        required: true,
        ...zefixHandlers
      }
    },
    {
      title: "Registered seat",
      className: "_width-50",
      input: {
        type: "text",
        name: "governingLaw",
        placeholder: "Registered seat (city)"
      }
    },

    {
      title: "Domicile Address",
      className: "_width-50",
      input: {
        type: "text",
        name: "address",
        required: true,
        placeholder: "Street and address"
      }
    },
    {
      title: "Correspondence Address",
      className: "_width-50",
      input: {
        type: "text",
        name: "address",
        required: true,
        placeholder: "Street and address"
      }
    },
    {
      className: "_width-20",
      input: {
        placeholder: "ZIP",
        type: "text",
        name: "postalCode",
        required: true
      }
    },
    {
      className: "_width-30",
      input: {
        placeholder: "City",
        type: "text",
        name: "city",
        required: true
      }
    },
    {
      className: "_width-20",
      input: {
        placeholder: "ZIP",
        type: "text",
        name: "postalCode",
        required: true
      }
    },
    {
      className: "_width-30",
      input: {
        placeholder: "City",
        type: "text",
        name: "city",
        required: true
      }
    },
    {
      className: "_width-20",
      input: {
        placeholder: "Canton",
        type: "autocomplete",
        name: "canton",
        dataSource: cantonsSortedByName,
        filterOption: true
      }
    },
    {
      className: "_width-30",
      input: {
        placeholder: "Country",
        type: "autocomplete",
        name: "country",
        required: true,
        dataSource: countriesSortedByName,
        filterOption: true
      }
    },
    {
      className: "_width-20",
      input: {
        placeholder: "Canton",
        type: "autocomplete",
        name: "canton",
        required: true,
        dataSource: cantonsSortedByName,
        filterOption: true
      }
    },
    {
      className: "_width-30",
      input: {
        placeholder: "Country",
        type: "autocomplete",
        name: "country",
        required: true,
        dataSource: countriesSortedByName,
        filterOption: true
      }
    },

    {
      className: "_fullwidth",
      title: "Information from public register",
      input: {
        type: "hidden",
        name: "info"
      }
    },

    {
      className: "_width-50",
      title: "UID",

      input: {
        placeholder: "Unique Identifier",
        type: "text",
        name: "idNumber",
        required: true
      }
    },

    {
      className: "_width-50",
      title: "Shares outstanding",
      input: {
        type: "number",
        name: "shares",
        formatter: value => formatNumber(value),
        min: 0
      }
    },

    {
      id: 15,
      title: "Employee Stock Ownership Plan (ESOP) shares",
      className: "_width-50",
      optional: true,
      roles: [],
      input: {
        type: "number",
        name: "esopShares",
        formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'"),
        parser: value => value.replace(/\\s?|('*)/g, "") || 0
      }
    },
    {
      title: "Phantom Stock Ownership Plan (PSOP) shares",
      className: "_width-50",
      optional: true,
      roles: [],
      input: {
        type: "number",
        name: "psopShares",
        formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'"),
        parser: value => value.replace(/\\s?|('*)/g, "") || 0
      }
    },
    {
      className: "_width-50",
      title: "Share capital",

      input: {
        type: "number",
        name: "publicShareCapital",
        required: true,
        formatter: value =>
          value ? `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'") : "",
        parser: value => value.replace(new RegExp(`CHF\\s?|(\\'*)`, "g"), "")
      }
    },
    {
      className: "_width-50",
      title: "Founding date",

      input: {
        type: "date",
        name: "publicFoundingDate",
        required: true
      }
    },
    {
      className: "_width-50",
      title: "Date of the current articles of association",
      input: {
        type: "date",
        name: "aoaDate",
        required: true
      }
    },
    {
      id: 11,
      title: "Company’s business description",
      className: "_fullwidth",
      input: {
        type: "textarea",
        name: "description",
        details:
          "Copy your company’s purpose as registered in the commercial registry in German."
      }
    },
    {
      title: "Company’s business description english translation",
      className: "_fullwidth",
      input: {
        type: "textarea",
        name: "descriptionEnglish",
        details:
          "Translate your company’s purpose as registered in the commercial registry in English."
      }
    },

    {
      className: "_width-50",
      title: "Primary bank account",
      input: {
        type: "hidden",
        name: "bankAcc"
      }
    },
    {
      className: "_width-50",
      title: "Capital deposit account",
      input: {
        type: "hidden",
        name: "bankAcc"
      }
    },

    {
      className: "_width-50",
      title: "Bank",

      input: {
        type: "text",
        name: "bank",
        required: true,
        placeholder: "Swiss Bank AG"
      }
    },

    {
      className: "_width-50",
      title: "Bank",

      input: {
        type: "text",
        name: "bank",
        required: true,
        placeholder: "Swiss Bank AG"
      }
    },

    {
      className: "_width-30",
      title: "Address",

      input: {
        type: "text",
        name: "bankAddress",
        required: true,
        placeholder: "Bernstrasse 1"
      }
    },
    {
      className: "_width-20",
      title: "City",

      input: {
        type: "text",
        name: "bankCity",
        required: true,
        placeholder: "Zurich"
      }
    },

    {
      className: "_width-30",
      title: "Address",

      input: {
        type: "text",
        name: "bankAddress",
        required: true,
        placeholder: "Bernstrasse 1"
      }
    },
    {
      className: "_width-20",
      title: "City",

      input: {
        type: "text",
        name: "bankCity",
        required: true,
        placeholder: "Zurich"
      }
    },

    {
      title: "Canton",
      className: "_width-20",
      input: {
        type: "autocomplete",
        name: "bankCanton",
        dataSource: cantonsSortedByName,
        filterOption: true
      }
    },
    {
      title: "Country",
      className: "_width-30",
      input: {
        name: "bankCountry",
        type: "autocomplete",
        required: true,
        dataSource: countriesSortedByName,
        filterOption: true
      }
    },

    {
      title: "Canton",
      className: "_width-20",
      input: {
        type: "autocomplete",
        name: "bankCanton",
        dataSource: cantonsSortedByName,
        filterOption: true
      }
    },
    {
      title: "Country",
      className: "_width-30",
      input: {
        name: "bankCountry",
        type: "autocomplete",
        required: true,
        dataSource: countriesSortedByName,
        filterOption: true
      }
    },

    {
      className: "_width-50",
      title: "Account number",

      input: {
        type: "text",
        name: "accountNumber",
        required: true,
        placeholder: "12-202028101"
      }
    },
    {
      className: "_width-50",
      title: "Account number",

      input: {
        type: "text",
        name: "accountNumber",
        required: true,
        placeholder: "12-202028101"
      }
    },

    {
      className: "_width-50",
      title: "IBAN",

      input: {
        type: "text",
        name: "iban",
        required: true,
        placeholder: "CH90 1220 2028 1052 9579"
      }
    },
    {
      className: "_width-50",
      title: "IBAN",

      input: {
        type: "text",
        name: "iban",
        required: true,
        placeholder: "CH90 1220 2028 1052 9579"
      }
    },

    {
      className: "_width-50",
      title: "Clearing number",

      input: {
        type: "text",
        name: "clearingNumber",
        required: true,
        placeholder: "000123"
      }
    },
    {
      className: "_width-50",
      title: "Clearing number",

      input: {
        type: "text",
        name: "clearingNumber",
        required: true,
        placeholder: "000123"
      }
    },

    // {
    //   id: 5,
    //   title: 'City',
    //   input: {
    //     type: 'text',
    //     name: 'city',
    //     suffix: loading ? <Spin /> : null,
    //   },
    // },
    {
      id: 9,
      title: "Select the industries which describe the company best.",
      className: "_fullwidth",
      optional: true,
      input: {
        type: "checkbox",
        name: "industries",
        options: industryFields.sort(sortArrayByName)
      }
    },
    {
      id: 16,
      title: "Select the stage which describes the company best?",
      className: "_fullwidth",
      optional: true,
      input: {
        type: "select_btn",
        name: "companyStatus",
        options: [
          basicOption("pre-revenue", "Pre-revenue"),
          basicOption("early-stage", "Early stage"),
          basicOption("growth-stage", "Growth stage"),
          basicOption("Pre-ipo", "Pre-IPO"),
          basicOption("project-financing", "Project financing"),
          basicOption("buyout", "Buyout")
        ]
      }
    }
  ];
};

export { companyFields };
