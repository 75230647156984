import { useEffect } from "react";
import ModalWrapper from "../../../../../../../Shared/Modal";
import { Button } from "../../../../../../../Shared/Button";

export default function ContactUsModal({
  updateUserSettings,
  isActive,
  isUpdating,
  didUpdate,
  ...modalProps
}) {
  useEffect(() => {
    if (didUpdate) {
      const { onCancel } = modalProps;
      onCancel();
    }
  }, [didUpdate]);

  const handleUnlock = () => updateUserSettings({ isActive: true });

  return (
    <ModalWrapper
      title="Unlimited Convertible Loan Agreements for one-time payment of 700 CHF"
      width={850}
      closable
      {...modalProps}
    >
      <div>
        <span>
          Legal does not need to be a headache.
          <br />
          Focus on your funding round and let us take care of the paperwork.
          <br />
          <br />
          We have been there! Legal has been so difficult, expensive, and time
          consuming for us. That's why we made it simple and convenient for you.
          <br />
          <br />
          Save on legal fees and create as many convertible loan agreements as
          you want.
          <br />
          <br />
          Invest your money in growth not paperwork.
          <br />
          <br />
        </span>
      </div>
      <div className="btn-container">
        <Button
          label={
            <a
              href="https://askleva.typeform.com/to/FA9aDMMr"
              target="_blank"
              rel="noopener noreferrer"
            >
              Unlock the CLA
            </a>
          }
          size="large"
          loading={isUpdating}
          success={didUpdate}
          // onClick={handleUnlock} - ABSENT AS A FEATURE
        />
      </div>
    </ModalWrapper>
  );
}
