import { basicOption, handleColor, inputNumberFormatter } from '#helpers/general';
import { disabledConfirmationPrompt } from '../../../common/prompts';
import Radio from '../../../../Shared/Radio';
import Checkbox from '../../../../Shared/Checkbox';
import { notariesList } from '.';
import TagWrapper from '../../../../Shared/Tag';

const checkIfSelected = (directors, cuid) => {
  const selectedDirector = directors.filter(({ cuid: directorCuid, presentShareholders = false }) => cuid === directorCuid && presentShareholders);
  if (selectedDirector.length > 0) return true;
  return false;
};

const bodCols = ({ handlers: { radioHandler, checkboxHandler }, values }) => {
  return [
    {
      title: 'Select the Opener',
      dataIndex: 'opener',
      key: 'opener',
      render: (_, { cuid }) => (
        <Radio
          name="opener"
          value={cuid}
          label=" "
          checked={values.opener === cuid && checkIfSelected(values.boardOfDirectors, cuid)}
          disabled={!checkIfSelected(values.boardOfDirectors, cuid)}
          onChange={radioHandler}
        />
      )
    },
    {
      title: 'Present',
      dataIndex: 'presentShareholders',
      key: 'presentShareholders',
      render: (value, { cuid }) => (
        <Checkbox
          name="presentShareholders"
          checked={value}
          onChange={checkboxHandler('boardOfDirectors', cuid)}
        />
      )
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'President of the board',
      dataIndex: 'presidentOfBoard',
      key: 'president',
      render: (value) => (value ? 'Yes' : 'No')
    },
    {
      title: 'Signature',
      dataIndex: 'signingAuthority',
      key: 'signingAuthority',
      render: (text) => (
        <TagWrapper color={handleColor(text)}>
          {text}
        </TagWrapper>
      )
    },
  ]
};

const shareCapitalCols = ({ handlers: { checkboxHandler } }) => [
  {
    title: 'Select',
    dataIndex: 'select',
    key: 'select',
    render: (value, { cuid }) => {
      return (
        <Checkbox
          checked={value}
          label=" "
          name="select"
          onChange={checkboxHandler('shareCapital', cuid)}
        />
      );
    }
  },
  {
    title: 'First name',
    dataIndex: 'name',
    key: 'firstName',
    render: (_, { firstName }) => `${firstName} `
  },
  {
    title: 'Last name',
    dataIndex: 'name',
    key: 'lastName',
    render: (_, { lastName }) => `${lastName.match(/\d+/g) ? ' ' : lastName}`
  },
  {
    title: 'Share Capital',
    dataIndex: 'shareCapital',
    key: 'shareCapital',
    render: (value) => (value ? `${value} CHF` : '0 CHF')
  }
];

const notariesCols = (name, { handlers: { radioHandler }, values }) => [
  {
    title: 'Select',
    dataIndex: 'selection',
    key: 'selection',
    render: (_, { cuid }) => (
      <Radio
        name={name}
        value={cuid}
        onChange={radioHandler}
        checked={values[name] === cuid}
        label=" "
      />
    )
  },
  {
    title: 'First name',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
    key: 'lastName'
  }
];

const confirmOptsWithTooltip = {
  type: 'select_btn',
  options: [
    basicOption('yes', 'Yes'),
    basicOption('no', 'No', {
      tooltip:
        'We currently can’t offer this option. Please reach out to X, our partner law firm. For more information reach out to hi@leva.pe'
    })
  ]
};

const shareholderFields = (formPropsBag, shareCapital) => {
  const { values } = formPropsBag;
  values.totalShareRepresented = 'no';
  let totalShareCapital = 0;

  values && values.shareCapital && values.shareCapital.filter(({ select = false }) => select).forEach(({ shareCapital }) => totalShareCapital += Number(shareCapital))

  const shareCapitalCalculated = (totalShareCapital / shareCapital) * 100;
  if (shareCapitalCalculated === 100) values.totalShareRepresented = 'yes';
  return [
    {
      title: 'Board of directors overview',
      details:
        'This are your current members of the board of directors. Please choose one board member, who  will open / opened the shareholders’ meeting, took the chair and will act / acted as recording secretary and scrutineer (it can be the current president or any other board member)',
      input: {
        type: 'table_list',
        name: 'boardOfDirectors',
        cols: bodCols(formPropsBag)
      }
    },
    {
      title:
        'Is / has the entire share capital amount been represented in the extraordinary shareholders’ meeting?',
      input: {
        type: 'table_list',
        name: 'shareCapital',
        cols: shareCapitalCols(formPropsBag),
        footer: () => (
          <div>
            <span className='shareCapital-footer'>
              Your current share capital amount
                        {/* {' '}{totalShareCapital} */}
              <p className='shareCapital-text'>{shareCapital}</p>
            </span>
            <span className='shareCapital-footer'>
              Total share capital represented:
            {/* {' '}{totalShareCapital} */}
              <TagWrapper color={shareCapital === totalShareCapital ? 'green' : 'red'}>{`${shareCapitalCalculated.toFixed(2)}%`}</TagWrapper>
            </span>
          </div>

        )
      }
    },
    {
      title: 'Total share capital?',
      input: {
        name: 'totalShareRepresented',
        ...disabledConfirmationPrompt
      }
    },
    {
      title:
        'Have / will the shareholders approved / approve the creation of a conditional share capital?',
      input: {
        name: 'shareholdersApproval',
        ...confirmOptsWithTooltip
      }
    },
    values.shareholdersApproval === 'yes'
      ? {
        title: 'How high is / shall the total conditional capital be?',
        input: {
          type: 'number',
          name: 'conditionalCapitalAmount',
          ...inputNumberFormatter('CHF'),
        }
      }
      : null,
    {
      title:
        'Have / will the shareholders approved / approve the creation of a authorized share capital?',
      input: {
        ...confirmOptsWithTooltip,
        name: 'shareholdersApprovalAuthShareCap'
      }
    },
    values.shareholdersApprovalAuthShareCap === 'yes'
      ? {
        title: 'How high is / shall the total authorized capital be?',
        input: {
          type: 'number',
          name: 'authorizedCapitalAmount',
          ...inputNumberFormatter('CHF'),
        }
      }
      : null,
    {
      title:
        'Which notary of our partner law firm X  shall notarize the public deed?',
      input: {
        type: 'table_list',
        name: 'shareholdersMeetingNotary',
        cols: notariesCols('shareholdersMeetingNotary', formPropsBag),
        data: notariesList
      }
    },
    {
      title:
        'When did / will the extraordinary shareholders’ meeting take place / be held ?',
      input: {
        type: 'date',
        name: 'publicDeedEgmDate'
      }
    }
  ];
};

export { notariesCols };

export default shareholderFields;
