import { handleColor } from '#helpers/general'
import TagWrapper from '../../../../Shared/Tag';

const bodOverviewCols = [
  {
    title: 'First and last name',
    dataIndex: 'firstName',
    key: 'firstName',
    render: (val, { firstName, lastName }) => `${firstName} ${lastName}`
  },
  // {
  //   title: 'Last name',
  //   dataIndex: 'lastName',
  //   key: 'lastName'
  // },
  {
    title: 'New',
    dataIndex: 'new',
    key: 'new',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: 'President of the board',
    dataIndex: 'presidentOfBoard',
    key: 'presidentOfBoard',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: 'Power of attorney',
    dataIndex: 'poa',
    key: 'poa',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: 'Leaving',
    dataIndex: 'leaving',
    key: 'leaving',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: 'Representing',
    dataIndex: 'representativeOf',
    key: 'representativeOf',
    render: (text) => (
      <TagWrapper color={handleColor(text)}>
        {text}
      </TagWrapper>
    )
  },
  {
    title: 'Signature',
    dataIndex: 'signingAuthority',
    key: 'signingAuthority',
    render: (text) => (
      <TagWrapper color={handleColor(text)}>
        {text}
      </TagWrapper>
    )
  },
];

const shareholdersOverviewCols = [
  {
    title: 'First and last name',
    key: 'name',
    dataIndex: 'name',
    render: (_, { firstName, lastName }) => `${firstName} ${lastName.match(/\d+/g) ? ' ' : lastName}`
  },
  {
    title: 'Amount to be invested',
    key: 'amount',
    dataIndex: 'amount',
    render: (value = 0) => value
  },
  {
    title: 'New shares receiving',
    dataIndex: 'shares',
    key: 'shares',
    render: (value = 0) => value
  },
  {
    title: 'Share Price',
    dataIndex: 'sharePrice',
    key: 'sharePrice',
    render: (value) => (value ? `${value} CHF` : '0 CHF')
  },
  {
    title: 'Fully paid up',
    dataIndex: 'paidUp',
    key: 'paidUp',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: 'New',
    dataIndex: 'new',
    key: 'new',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: 'Investment',
    dataIndex: 'investment',
    key: 'investment',
    render: () => <TagWrapper color='blue' >Cash</TagWrapper>
  }
];

const financialFields = [
  {
    title: 'Your total amount raised in this financing round',
    input: {
      type: 'number',
      name: 'totalAmount',
      disabled: true
    }
  },
  {
    title: 'Your total set-off amount in this financing round',
    input: {
      type: 'number',
      name: 'setOffAmount',
      disabled: true
    }
  }
];

const sharesInformation = (calculatedSharePrice) => [
  {
    title: 'Your current shares outstanding',
    input: {
      type: 'number',
      name: 'sharesNumber',
      disabled: true
    }
  },
  {
    title: 'Your current share capital amount',
    input: {
      type: 'number',
      name: 'sharesCapital',
      disabled: true
    }
  },
  {
    title: 'Your new share capital amount',
    input: {
      type: 'number',
      name: 'newSharesCapital',
      disabled: true
    }
  },
  {
    title: 'Your nominal value of newly issued shares',
    input: {
      type: 'number',
      name: 'newlyIssuedShares',
      disabled: true
    }
  },
  {
    title: 'Your newly issued share price',
    input: {
      type: 'number',
      name: 'newSharePrice',
      disabled: true,
      value: calculatedSharePrice.toFixed(2)
    }
  },
  {
    title: 'Your newly issued share number',
    input: {
      type: 'number',
      name: 'newSharesNumber',
      disabled: true
    }
  }
];

const stakeholders = [
  {
    title: 'Your certified Auditor',
    input: {
      type: 'text',
      name: 'certifiedAuditor',
      disabled: true
    }
  },
  {
    title: 'Your independent Expert',
    input: {
      type: 'text',
      name: 'independentExpert',
      disabled: true
    }
  },
  {
    title: 'Your Capital Deposit Account',
    input: {
      type: 'text',
      name: 'capDepAcc',
      disabled: true
    }
  },
  {
    input: {
      type: 'text',
      name: 'capDepAccNo',
      disabled: true
    }
  },
  {
    title: 'Board of directors overview',
    details: 'This are your Board Members after the financing round',
    input: {
      type: 'table_list',
      name: 'boardOfDirectors',
      cols: bodOverviewCols
    }
  },
  {
    title: 'Shareholders overview',
    details: 'Your shareholders after the financing round.',
    input: {
      type: 'table_list',
      name: 'shareholders',
      cols: shareholdersOverviewCols
    }
  }
];

export default [financialFields, sharesInformation, stakeholders];
