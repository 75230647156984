const appendices = [
  {
    title:
      'Please upload the Article of Association for the Shareholder agreement appendix',
    input: {
      type: 'upload',
      name: 'articleOfAssociation'
    }
  }
];

export default appendices;
