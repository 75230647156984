import { connect } from "react-redux";
import { fetchAllIos, fetchSingleIo } from "#core/modules/io/actions";
import { getNdas, putNdaSignature } from "#core/modules/nda/actions";
import { clearState, clearStateDataByKey } from "#core/utils/helper_actions";
import { getEntitiesList, getEntity } from "#core/modules/entities/actions";
import { getPortfolioSyndicates } from "#core/modules/syndicates/actions";
import { entityTypes } from "#constants/entity";
import Investments from "./Presentational";

const mapStateToProps = state => {
  const {
    entity: {
      cuid,
      previewImage,
      description,
      createdBy,
      investmentType,
      commonName
    },
    entitiesData = []
  } = (state.entities._data && state.entities._data) || {};
  const {
    _data: { list: ndasList = [] } = {},
    isFetching: ndaIsFetching,
    didFetch: ndaDidFetch,
    isUpdating: ndaIsUpdating,
    didUpdate: ndaDidUpdate
  } = state.nda;
  const { cuid: currUserId } = state.users._data || {};

  const item =
    entitiesData.find(
      ({ type, creator: { cuid: creatorCuid } }) =>
        type === entityTypes.ASSET_MANAGER_ENTITY && creatorCuid === createdBy
    ) || {};

  return {
    assetManager: {
      cuid: createdBy,
      previewImage: item.previewImage,
      name: item.name
    },
    entity: {
      cuid,
      previewImage,
      description,
      investmentType,
      commonName
    },
    loading: state.syndicate.isFetching || ndaIsFetching,
    ndasList:
      ndasList &&
      ndasList.filter(
        ({ assetManagerId, userSignatureTimestamp }) =>
          !userSignatureTimestamp && createdBy === assetManagerId
      ),
    ndaDidFetch,
    currUserId,
    ndaStatuses: {
      loading: ndaIsUpdating,
      success: ndaDidUpdate
    },
    syndicatesWhereUserIsInvestor:
      state?.syndicate?._data?.syndicatesWhereUserIsInvestor
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      getNdas: params => dispatch(getNdas(params)),
      putNdaSignature: data => dispatch(putNdaSignature(data)),
      getEntitiesList: params => dispatch(getEntitiesList(params)),
      getEntity: data => dispatch(getEntity(data)),
      clearState: state => clearState(dispatch, state),
      clearStateDataByKey: (state, ...keys) =>
        clearStateDataByKey(dispatch, state, ...keys),
      getPortfolioSyndicates: params => dispatch(getPortfolioSyndicates(params))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Investments);
