import types from "../action_types";
import * as entitiesServices from "../../../../api/entitiesService";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const getEntities = params => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_ENTITIES);
      const response = await entitiesServices.getEntities(params);
      success(dispatch, types.GET_ENTITIES, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_ENTITIES, {
        error: errorData
      });
    }
  };
};

export const getEntitiesList = params => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_ENTITIES_LIST);
      const response = await entitiesServices.getEntitiesList(params);
      success(dispatch, types.GET_ENTITIES_LIST, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_ENTITIES_LIST, {
        error: errorData
      });
    }
  };
};

export const getEntity = id => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_SINGLE_ENTITY);
      const response = await entitiesServices.getSingleEntity(id);
      success(dispatch, types.GET_SINGLE_ENTITY, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_SINGLE_ENTITY, {
        error: errorData
      });
    }
  };
};

export const getEntityMemberships = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_ENTITY_MEMBERSHIPS);
      const response = await entitiesServices.getEntityMemberships(data);
      success(dispatch, types.GET_ENTITY_MEMBERSHIPS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_ENTITY_MEMBERSHIPS, {
        error: errorData
      });
    }
  };
};

export const getEntityShareholders = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_ENTITY_SHAREHOLDERS);

      const response = await entitiesServices.getEntityShareholders(data);

      success(dispatch, types.GET_ENTITY_SHAREHOLDERS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_ENTITY_SHAREHOLDERS, {
        error: errorData
      });
    }
  };
};

export const getCompanyDetails = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_COMPANY_DETAILS);

      const response = await entitiesServices.getCompanyDetails(data);

      success(dispatch, types.GET_COMPANY_DETAILS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      fail(dispatch, types.GET_COMPANY_DETAILS, {
        error: errorData
      });
    }
  };
};

export const getFounderInvitations = () => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_FOUNDER_INVITATIONS);

      const response = await entitiesServices.getFounderInvitations();

      success(dispatch, types.GET_FOUNDER_INVITATIONS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_FOUNDER_INVITATIONS, {
        error: errorData
      });
    }
  };
};

export const getSingleFounderInvitation = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_SINGLE_FOUNDER_INVITATION);

      const response = await entitiesServices.getSingleFounderInvitation(data);

      success(dispatch, types.GET_SINGLE_FOUNDER_INVITATION, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_SINGLE_FOUNDER_INVITATION, {
        error: errorData
      });
    }
  };
};

export const getAllEntityMemberships = () => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_ALL_ENTITY_MEMBERSHIPS);

      const response = await entitiesServices.getAllEntityMemberships();

      success(dispatch, types.GET_ALL_ENTITY_MEMBERSHIPS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_ALL_ENTITY_MEMBERSHIPS, {
        error: errorData
      });
    }
  };
};

export const getCompaniesList = params => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_COMPANIES_LIST);

      const response = await entitiesServices.getCompanies(params);

      success(dispatch, types.GET_COMPANIES_LIST, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);

      fail(dispatch, types.GET_COMPANIES_LIST, {
        error: errorData
      });
    }
  };
};
