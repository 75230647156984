import * as contractsService from "../../../../api/contractsService";
import types from "../action_types";
import { start, fail, success } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const generateContracts = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GENERATE_CONTRACT);
      const response = await contractsService.generateContractService(data);

      success(dispatch, types.GENERATE_CONTRACT, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GENERATE_CONTRACT, {
        error: errorData
      });
    }
  };
};

export const getCompanyInfo = data => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_COMPANY_INFO);
      const response = await contractsService.getCompanyinfo(data);

      success(dispatch, types.FETCH_COMPANY_INFO, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_COMPANY_INFO, {
        error: errorData
      });
    }
  };
};

export const getContracts = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_CONTRACTS);

      const response = await contractsService.getContracts(data);

      success(dispatch, types.GET_CONTRACTS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_CONTRACTS, {
        error: errorData
      });
    }
  };
};

export const getContractsPreview = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_CONTRACTS_PREVIEW);

      const response = await contractsService.getContractsPreview(data);

      success(dispatch, types.GET_CONTRACTS_PREVIEW, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      // catchError(dispatch, message);
      fail(dispatch, types.GET_CONTRACTS_PREVIEW, {
        error: errorData
      });
    }
  };
};

export const getDocumentTypes = () => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_DOCUMENT_TYPES);

      const response = await contractsService.getDocumentTypes();

      success(dispatch, types.GET_DOCUMENT_TYPES, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_DOCUMENT_TYPES, {
        error: errorData
      });
    }
  };
};

export const getPooledFundTemplates = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_POOLED_FUND_TEMPLATES);

      const response = await contractsService.getPooledFundTemplates(params);

      success(dispatch, types.FETCH_POOLED_FUND_TEMPLATES, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_POOLED_FUND_TEMPLATES, {
        error: errorData
      });
    }
  };
};

export const getInvestmentCompliance = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_INVESTMENT_COMPLIANCE);

      const response = await contractsService.getInvestmentCompliance(params);

      success(dispatch, types.FETCH_INVESTMENT_COMPLIANCE, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_INVESTMENT_COMPLIANCE, {
        error: errorData
      });
    }
  };
};

export const getInvConfirmation = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_INVESTMENT_CONFIRMATION);

      const response = await contractsService.getInvConfirmation(params);

      success(dispatch, types.FETCH_INVESTMENT_CONFIRMATION, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_INVESTMENT_CONFIRMATION, {
        error: errorData
      });
    }
  };
};

export const getTransConfirmation = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_TRANS_CONFIRMATION);

      const response = await contractsService.getTransConfirmation(params);

      success(dispatch, types.FETCH_TRANS_CONFIRMATION, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_TRANS_CONFIRMATION, {
        error: errorData
      });
    }
  };
};

export const getInvestmentSummary = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_INV_SUMMARY);

      const response = await contractsService.getInvestmentSummary(params);

      success(dispatch, types.FETCH_INV_SUMMARY, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_INV_SUMMARY, {
        error: errorData
      });
    }
  };
};

export const getDealConfirmation = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_DEAL_CONFIRMATION);

      const response = await contractsService.getDealConfirmation(params);

      success(dispatch, types.FETCH_DEAL_CONFIRMATION, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_DEAL_CONFIRMATION, {
        error: errorData
      });
    }
  };
};
