export default {
  FETCH_VOTING: "FETCH_VOTING",
  FETCH_VOTING_DOC: "FETCH_VOTING_DOC",
  FETCH_SINGLE_VOTING: "FETCH_SINGLE_VOTING",
  POST_VOTING_ATTACHMENT: "POST_VOTING_ATTACHMENT",
  POST_VOTING_ASSEMBLY: "POST_VOTING_ASSEMBLY",
  POST_VOTING_RESULT: "POST_VOTING_RESULT",
  POST_PUBLIC_VOTING: "POST_PUBLIC_VOTING",
  PATCH_VOTING: "PATCH_VOTING",
  PUT_VOTING: "PUT_VOTING",
  GENERATE_VOTING_CODE_DM: "GENERATE_VOTING_CODE_DM",
  GENERATE_VOTING_CODE_INV: "GENERATE_VOTING_CODE_INV",
  DELETE_VOTING: "DELETE_VOTING",
  DELETE_VOTING_ATTACHMENT: "DELETE_VOTING_ATTACHMENT"
};
