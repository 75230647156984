import { triggerSnackbar } from "../modules/snackbar/actions";
import i18n from "../../src/i18n";

/**
 * @param {object} error
 * @param {string} name
 */

export const getErrorData = error => {
  const errorData = {};

  const getMessageFromLocales = (code, message) => {
    return code &&
      i18n.t(`errors.server_errors.${code}`) !== `errors.server_errors.${code}`
      ? i18n.t(`errors.server_errors.${code}`)
      : message;
  };

  if (error && error.response && error.response.data) {
    const { code, message, payload } = error.response.data;

    if (code || message || payload) {
      if (code) {
        errorData.code = code;
      }

      if (message) {
        errorData.message = getMessageFromLocales(code, message);
      }

      if (payload) {
        if (payload.code) {
          errorData.code = payload.code;
        }

        if (payload.message) {
          errorData.message = getMessageFromLocales(
            payload.code,
            payload.message
          );
        }
        errorData.payload = payload;
      }
    } else {
      const status = error.response.status;
      const msg = error.message;

      errorData.code = status;
      errorData.message = getMessageFromLocales(status, msg);
    }
  }

  return errorData;
};

export function catchError(dispatch, message = "", optionalData) {
  const splitMsg = message.split(" ");
  const is401 = splitMsg[splitMsg.length - 1] === "401";

  triggerSnackbar(dispatch, {
    optionalData,
    message: message || "An error occurred",
    variant: "warning",
    vertical: "bottom",
    horizontal: "left",
    open: !is401
  });
}

export const serializeDispatchers = ({ dispatchers, args }) => {
  return async dispatch => {
    try {
      await dispatchers.reduce(async (prevRes, item, idx) => {
        const res = await prevRes;

        const args_formatter =
          typeof args[idx] === "function" ? args[idx](res || {}) : args[idx];

        return await dispatch(item(args[idx] ? args_formatter : {}));
      }, Promise.resolve({}));
    } catch (error) {}
  };
};
