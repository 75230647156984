import InputRenderer from '../../../../Shared/InputRenderer';
import { Fees } from '../../../../Shared/Input';

const InputWrapper = ({
  type,
  values,
  handleCheckboxChange,
  errors,
  ...props
}) => {
  if (type === 'fees') {
    return (
      <Fees
        {...props}
        errors={errors}
        values={values}
        handleCheckboxChange={handleCheckboxChange}
      />
    );
  }

  return <InputRenderer type={type} {...props} />;
};

export default InputWrapper;
