import Icon from 'antd/es/icon';
import FormSuccessView from '../../../Shared/Form/FormStepper/components/SuccessView';
import { Button } from '../../../Shared/Button';

const GeneratorSuccess = ({ headline, subContent }) => () => {
  return (
    <div className="custom-success-view subscription-creation">
      <FormSuccessView
        title={headline}
        className="subscription-creation"
        id="success-prompt"
      />
      {subContent.map((item) => (
        <h3>{item}</h3>
      ))}
      <div className="btn-container">
        <Button
          label="View equity"
          className="main-btn"
          href="/equity/overview"
        />
      </div>
    </div>
  );
};

export default GeneratorSuccess;
