import { Row, Col, Typography, Button } from "antd";
import Tick from "../../../../assets/illustrations/tick.svg";
import Check from "../../../../assets/illustrations/check.svg";
import Buttons from "../../shared/Features/FeatureButtons/Presentational";

const { Title } = Typography;

export default function Presentational(props) {
  return (
    <div className="custom-feature-col-wrapper">
      <Row className="custom-feature-row">
        <Col
          xs={22}
          sm={22}
          md={8}
          lg={8}
          xl={8}
          className="custom-feature-col"
        >
          <Title
            level={2}
            className={props.className || "custom-feature-l2-title"}
          >
            {props.header}
          </Title>
          <p className="custom-feature-text">{props.text}</p>
          {props.showButtons && <Buttons buttons={props.buttons} />}
          <div className="custom-feature-items">
            {props.items &&
              props.items.map(item => (
                <div>
                  <div className="custom-feature-item">
                    <img
                      className="custom-feature-item-img"
                      src={Tick}
                      alt="tick"
                    />
                    <p className="custom-feature-item-content">
                      {item.content}
                    </p>
                  </div>
                  {item.insideItems &&
                    item.insideItems.map(insideItem => (
                      <div className="custom-feature-inside-item">
                        <img
                          // className="custom-feature-inside-item-img"
                          style={{ margin: "6px 10px 0px 0px" }}
                          width="12px"
                          height="12px"
                          src={Check}
                          alt="check"
                        />
                        <p className="custom-feature-inside-item-content">
                          {insideItem.itemContent}
                        </p>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={16}
          lg={16}
          xl={16}
          className="custom-feature-col"
        >
          <img className="custom-feature-col-img" src={props.icon} alt="icon" />
        </Col>
      </Row>
    </div>
  );
}
