import { connect } from "react-redux";
import {
  getNotifsHistory,
  postNotifsSubscription,
  updateNotifsStatus,
  bulkUpdateNotifsStatus
} from "#core/modules/notifications/actions";
import { clearState } from "#core/utils/helper_actions";
import { roles } from "#constants/roles";
import { entityTypes } from "#constants/entity";
import AppBar from "./";

const mapStateToProps = state => {
  const { entity = {}, entitiesList = [] } = state.entities._data || {};
  const { _data: { history } = {}, didFetch, isFetching } = state.notifications;

  const user = (state.users && state.users._data) || {};
  const userDidUpdate = state.users.didUpdate;
  const { userRole, type } = entity;
  const isAmFounder =
    userRole === roles.FOUNDER && type === entityTypes.ASSET_MANAGER_ENTITY;
  const hasEntities = entitiesList.some(
    ({ type }) => type === entityTypes.TARGET_ENTITY
  );

  return {
    user,
    userRole,
    userDidUpdate,
    notificationCreated: state.notifications.didCreate,
    isAmFounder,
    hasEntities,
    notificationsColl: {
      data: history,
      didFetch,
      isFetching
    }
  };
};

const mapDispatchToProps = dispatch => ({
  getNotifsHistory: params => dispatch(getNotifsHistory(params)),
  postNotifsSubscription: () => dispatch(postNotifsSubscription()),
  clearState: key => clearState(dispatch, key),
  updateNotifsStatus: data => dispatch(updateNotifsStatus(data)),
  bulkUpdateNotifsStatus: data => dispatch(bulkUpdateNotifsStatus(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
