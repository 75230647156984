import { roles } from "#constants/roles";
import { entityTypes } from "#constants/entity";
import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};
export default (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.GET_ENTITIES: {
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          const entities = action.payload.data || [];

          return {
            ...state._data,
            entitiesList: entities.reverse() // ENTITIES WITH MEMBERSHIP DATA
          };
        }
      });
    }

    case types.GET_ENTITIES_LIST: {
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          const entities = action.payload.data || [];

          return {
            ...state._data,
            entitiesData: entities.reverse() // ENTITIES WITH NO MEMBERSHIPS
          };
        }
      });
    }

    case types.GET_SINGLE_ENTITY: {
      return baseReducer(action, state, actions.FETCH, {
        start: () => ({ ...state._data, entityFetched: false }),
        success: () => {
          const currEntity = (state._data && state._data.entity) || {};
          const { type } = action.payload.data;

          if (type === entityTypes.ASSET_MANAGER_ENTITY) {
            return {
              ...state._data,
              am_entity: action.payload.data
            };
          } else {
            return {
              ...state._data,
              entity: { ...currEntity, ...action.payload.data },
              entityFetched: true
            };
          }
        },
        fail: () => ({ ...state._data, entityFetched: false })
      });
    }

    case types.GET_ENTITY_MEMBERSHIPS: {
      return baseReducer(action, state, actions.FETCH, {
        start: () => ({ ...state._data, membershipFetched: false }),
        success: () => {
          return {
            ...state._data,
            memberships: action.payload.data,
            membershipFetched: true
          };
        },
        fail: () => ({ ...state._data, membershipFetched: false })
      });
    }

    case types.GET_ENTITY_SHAREHOLDERS: {
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            shareholders: action.payload.data
          };
        }
      });
    }

    case types.GET_COMPANY_DETAILS: {
      return baseReducer(action, state, actions.FETCH, {
        start: () => ({
          ...state._data,
          detailsFetched: false,
          detailsFetching: true
        }),
        success: () => {
          return {
            ...state._data,
            detailsFetching: false,
            detailsFetched: true,
            companyDetails: action.payload.data
          };
        },
        fail: () => ({
          ...state._data,
          companyDetails: {},
          detailsFetched: false,
          detailsFetching: false
        })
      });
    }

    case types.GET_COMPANIES_LIST:
      return baseReducer(action, state, actions.FETCH, {
        start: () => ({ ...state._data, companiesListFetching: true }),
        success: () => {
          const listEmpty = action.payload.data.length === 0;
          const companyDetails = !listEmpty
            ? state._data && state._data.companyDetails
            : {};

          return {
            ...state._data,
            companiesList: action.payload.data.map(({ name, chid }) => ({
              value: chid,
              text: name
            })),
            companiesListFetching: false,
            companyDetails
          };
        },
        fail: () => ({ ...state._data, companiesListFetching: false })
      });

    case types.GET_FOUNDER_INVITATIONS:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            founderInvitations: action.payload.data
          };
        }
      });

    case types.GET_SINGLE_FOUNDER_INVITATION:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            founderInvitation: action.payload.data
          };
        }
      });

    case types.GET_ALL_ENTITY_MEMBERSHIPS:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            memberships: action.payload.data
          };
        }
      });

    case types.CREATE_ENTITY: {
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const entitiesList = (state._data && state._data.entitiesList) || [];
          const userRole = action.payload.data.fromAM
            ? roles.ASSET_MANAGER
            : roles.FOUNDER;

          return {
            ...state._data,
            entitiesList: [
              { ...action.payload.data, userRole, membership: { status: 1 } },
              ...entitiesList
            ],
            entity: {
              ...action.payload.data,
              userRole,
              membership: { status: 1 }
            }
          };
        }
      });
    }

    case types.CREATE_ENTITY_MEMBERSHIP: {
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const memberships = (state._data && state._data.memberships) || [];

          return {
            ...state._data,
            memberships: [...memberships, action.payload.data]
          };
        }
      });
    }

    case types.BULK_CREATE_ENTITY_MEMBERSHIP:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const memberships = (state._data && state._data.memberships) || [];

          return {
            ...state._data,
            memberships: [...memberships, ...action.payload.data]
          };
        }
      });

    case types.CREATE_ENTITY_DEAL_MEMBERSHIP: {
      return baseReducer(action, state, actions.CREATE, {
        success: () => ({ ...state._data })
      });
    }

    case types.BULK_CREATE_ENTITY_DEAL_MEMBERSHIP: {
      return baseReducer(action, state, actions.CREATE, {
        success: () => ({ ...state._data })
      });
    }

    case types.CREATE_FOUNDER_INVITATION:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });

    case types.UPDATE_ENTITY: {
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { cuid, type } = action.payload.data;
          const currList = (state._data && state._data.entitiesList) || [];

          let newData = { ...state._data };

          if (type === entityTypes.ASSET_MANAGER_ENTITY) {
            const currEntity = (state._data && state._data.am_entity) || {};
            newData = {
              ...newData,
              am_entity: { ...currEntity, ...action.payload.data }
            };
          } else {
            const currEntity = (state._data && state._data.entity) || {};
            newData = {
              ...newData,
              entity: { ...currEntity, ...action.payload.data }
            };
          }

          return {
            ...newData,
            entitiesList: currList.map(item => {
              if (item.cuid === cuid) {
                return { ...item, ...action.payload.data };
              }

              return item;
            })
          };
        }
      });
    }

    case types.SET_ENTITY:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { id } = action.payload;
          const entities = state._data && state._data.entitiesList;
          const currEntity = (state._data && state._data.entity) || {};

          const formattedEntity = () => {
            const entity = entities.find(({ cuid }) => cuid === id) || {};
            const { role, membership = {} } = entity;

            if (!role) {
              return {
                ...entity,
                userRole: membership.role || roles.ASSET_MANAGER
              };
            }

            return entity;
          };

          return {
            ...state._data,
            entity: id !== currEntity.cuid ? formattedEntity() : currEntity
          };
        }
      });

    case types.PUT_FOUNDER_INVITATION:
      return baseReducer(action, state, actions.CREATE, {
        // IGNORE THE NAMING. THE LOGIC IS MORE ABOUT CREATING THAN UPDATING SOMETHING.

        success: () => {
          return {
            ...state._data,
            userData: action.payload.data
          };
        }
      });

    case types.EXPIRE_FOUNDER_INVITATION:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const founderInvitations =
            (state._data && state._data.founderInvitations) || [];
          const cuid = action.payload.data;

          return {
            ...state._data,
            founderInvitations: founderInvitations.map(item => {
              if (item.cuid === cuid) {
                return {
                  ...item,
                  isExpired: true
                };
              }

              return item;
            })
          };
        }
      });

    case types.PUT_IO_MEMBERSHIP_STATUS:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { entityId } = action.payload.data || {};
          const { entitiesList = [] } = state._data || {};

          return {
            ...state._data,
            entitiesList: entitiesList.map(({ cuid, ...item }) => {
              if (cuid === entityId) {
                return {
                  ...item,
                  cuid,
                  membership: {
                    ...item.membership,
                    status: 1 // since the current support only one method of updating memberships (by approving) we can assume the status is updated to 1
                  }
                };
              }

              return { cuid, ...item };
            })
          };
        }
      });

    case types.CREATE_ENTITY_FOUNDER:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });

    case types.DELETE_ENTITY_MEMBERSHIP:
      return baseReducer(action, state, actions.DELETE, {
        start: () => {
          // isDeleting was added to keep reference of the current cuid that is being deleted for UI purposes
          const { id } = action.payload.data;

          return {
            ...state._data,
            isDeleting: id
          };
        },

        success: () => {
          const { id } = action.payload.data;
          const memberships = (state._data && state._data.memberships) || [];
          const membershipsUpdated = memberships.filter(
            ({ cuid }) => cuid !== id
          );

          return {
            ...state._data,
            memberships: membershipsUpdated,
            isDeleting: null
          };
        }
      });

    case types.DELETE_SHAREHOLDER:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          const { membershipId } = action.payload.data || {};

          const memberships = (state._data && state._data.memberships) || [];
          const membershipsUpdated = memberships.filter(
            ({ cuid }) => cuid !== membershipId
          );

          return {
            ...state._data,
            memberships: membershipsUpdated
          };
        }
      });

    case types.DELETE_ENTITY:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          const { entitiesUpdatedList = [] } = action.payload.data || {};
          return {
            ...state._data,
            entitiesList: entitiesUpdatedList
          };
        }
      });

    case types.UPDATE_ENTITY_MEMBERSHIP:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const memberships = (state._data && state._data.memberships) || [];
          const objIndex = memberships.findIndex(
            membership => membership.cuid === action.payload.data.cuid
          );
          memberships[objIndex] = action.payload.data;
          return {
            ...state._data,
            memberships
          };
        }
      });

    case types.BULK_DELETE_ENTITY_MEMBERSHIP:
      return baseReducer(action, state, actions.DELETE, {
        start: () => {
          const { cuid, idx } = action.payload.data || {};
          let deleting = [];

          if (idx !== 0) {
            deleting = (state._data && state._data.deleting) || [];
          }

          return {
            ...state._data,
            deleting: [...deleting, cuid]
          };
        },
        success: () => {
          const memberships = (state._data && state._data.memberships) || [];
          const deleted = actions.payload.data || [];

          return {
            ...state._data,
            memberships: memberships.filter(
              ({ cuid }) => !deleted.includes(cuid)
            ),
            deleting: []
          };
        },
        fail: () => ({ ...state._data, deleting: [] })
      });

    default:
      return state;
  }
};
