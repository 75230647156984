import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { roles } from "#constants/roles";
import { useCookies } from "react-cookie";
import ModalWrapper from "../../Modal";
import { PlatformUpdates, TokenUpdate } from "./content";
import { useHistory } from "react-router";

const UpdatesModal = ({ userRole, ndaList = [], logout, getNdas }) => {
  const [cookies, setCookie] = useCookies(["updateTimestamp"]);
  const [visible, setVisible] = useState(false);
  const [signed, setSigned] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [ndaSignedByUser, setNdaSignedByUser] = useState(false);

  const envUpdate = process.env.UPDATES !== cookies.updateTimestamp;

  const handleSwitch = checked => setSigned(checked);
  const history = useHistory();

  useEffect(() => {
    if (ndaList[0]?.userSignatureTimestamp) {
      setNdaSignedByUser(true);
    }
  }, [ndaList.length]);

  useEffect(() => {
    if (userRole === roles.INVESTOR || userRole === roles.AM_INVESTOR) {
      if (!ndaSignedByUser) {
        setVisible(false);
      } else if (ndaSignedByUser && envUpdate) {
        // setVisible(true); commented because we dont want to show the modal - uncomment when we want to show the modal
      }
    }
    if (userRole === roles.ASSET_MANAGER && envUpdate) {
      // setVisible(true); commented because we dont want to show the modal - uncomment when we want to show the modal
    }
  }, [ndaSignedByUser]);

  useEffect(() => {
    if (!cookies.updateTimestamp) {
      setCookie("updateTimestamp", process.env.UPDATES, {
        expires: new Date(2025, 5, 5)
      });
    }

    if (cookies.updateTimestamp && envUpdate) {
      setTokenExpired(true);
      setVisible(true);
    }
  }, [envUpdate]);

  const handleClick = () => {
    setCookie("updateTimestamp", process.env.UPDATES, {
      expires: new Date(2025, 5, 5)
    });
    setVisible(false);
  };

  const handleExpiredToken = () => {
    logout();
    setCookie("updateTimestamp", process.env.UPDATES, {
      expires: new Date(2025, 5, 5)
    });
    history.push("/login");
  };

  const platformUpdatesProps = {
    userRole,
    handleClick,
    handleSwitch,
    signed
  };

  return (
    <ModalWrapper
      className="updates-modal"
      footer={null}
      width={tokenExpired ? 500 : 1100}
      closable={false}
      maskClosable={false}
      keyboard={false}
      visible={visible}
    >
      <div className="content-wrapper">
        {tokenExpired ? (
          <TokenUpdate handleClick={handleExpiredToken} />
        ) : (
          <PlatformUpdates {...platformUpdatesProps} />
        )}
      </div>
    </ModalWrapper>
  );
};

UpdatesModal.propTypes = {
  userRole: PropTypes.string
};

export default UpdatesModal;
