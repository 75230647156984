import { connect } from 'react-redux';

import {
  getEntity,
  getEntityShareholders
} from '#core/modules/entities/actions';

import Presentational from './Presentational';

const mapStateToProps = (state) => {
  const {
    entity: { cuid: entityId, shares: entityShares } = {},
    shareholders = []
  } = (state.entities._data && state.entities._data) || {};

  return {
    entityId,
    entityShares,
    shareholders
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEntity: (data) => dispatch(getEntity(data)),
  getEntityShareholders: (data) => dispatch(getEntityShareholders(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
