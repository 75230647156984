import { basicOption } from "#helpers/general";
import { shaKeys } from "#constants/io/equityPool";
import { countriesSortedByName } from "../../../../Shared/Settings/countries";
import { cantonsSortedByName } from "../../../../Shared/Settings/cantons";

const conversion = shares => [
  {
    title:
      "Shall Investors with preferred shares have the right to request with conversion of all or part of its preferred shares into common shares at 1:1 ratio?",
    input: {
      name: shaKeys.VOLUNTARY_CONVERSION_SHA,
      type: "select_btn",
      options: [
        basicOption("yes", "Yes", { disabled: shares === 0 }),
        basicOption("no", "No", {
          disabled: true,
          tooltip:
            "The investors in pre-Seed and Seed financing round always need the right to request with conversion of all or part of its preferred shares into common shares at 1:1 ratio."
        })
      ]
    }
  },
  {
    title:
      "Shall all preferred shares be converted into common shares in case of an IPO (initial public offering) of the company at a conversion rate of 1:1?",
    input: {
      name: shaKeys.IPO_CONVERSION,
      type: "select_btn",
      options: [
        basicOption("yes", "Yes", { disabled: shares === 0 }),
        basicOption("no", "No", {
          tooltip:
            "In pre-Seed and Seed financing round all preferred shares are converted into common shares in case of an IPO (initial public offering) of the company at a conversion rate of 1:1."
        })
      ]
    }
  }
];

const vesting = values => [
  {
    title: "Reverse vesting duration:",
    input: {
      type: "text",
      name: shaKeys.REVERSE_VESTING_MONTHS,
      disabled: true,
      addonAfter: "month(s)"
    }
  },
  {
    title: (
      <span>
        <strong>Good leaver.</strong> <br />
        At which value shall the Leaver Shares be sold if a Founder leaves the
        Company before the end of the Leaver Period and the Founder is not
        considered a Bad Leaver (Good Leaver)?{" "}
        <a
          href="/doc_viewer?doc=/docs/Art337.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          art. 337 CO
        </a>
        .
      </span>
    ),
    input: {
      type: "select_btn",
      options: [
        basicOption("nominal", "Nominal value"),
        basicOption("fair_market", "Fair market value")
      ],
      name: shaKeys.GOOD_LEAVER_NOMINAL_VAL
    }
  },
  {
    title: (
      <span>
        <strong>Bad leaver.</strong>
        <br /> At which value shall the Leaver Shares be sold if a Founder
        leaves the Company before the end of the Leaver Period and the Founder
        is considered a Bad Leaver?{" "}
        <a
          href="/doc_viewer?doc=/docs/Art337.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          art. 337 CO
        </a>
        .
      </span>
    ),
    input: {
      type: "select_btn",
      name: shaKeys.BAD_LEAVER,
      options: [
        basicOption("combined", "Combined values"),
        basicOption("full", "Full nominal value")
      ]
    }
  },
  ...(values[shaKeys.BAD_LEAVER] === "combined"
    ? [
        {
          title: "Fill in the percentage of nominal value:",
          input: {
            type: "number",
            name: shaKeys.VESTED_SHARES_NOMINAL_VAL,
            placeholder: "75%",
            max: 100,
            min: 0,
            formatter: value => (value && value <= 100 ? `${value}%` : ""),
            parser: value => (value ? value.replace("%", "") : value)
          }
        },
        {
          title: "Calculated fair market value:",
          input: {
            type: "number",
            name: shaKeys.VESTED_SHARES_FMARKET_VAL,
            placeholder: "25%",
            disabled: true,
            value: String(100 - values[shaKeys.VESTED_SHARES_NOMINAL_VAL]),
            formatter: value => (value ? `${value}%` : value),
            parser: value => (value ? value.replace("%", "") : value)
          }
        }
      ]
    : [])
];

const independentVal = values => [
  {
    title: "Company name",
    className: "_width-50",
    input: {
      type: "text",
      name: shaKeys.INDEPENDENT_EXPERT_NAME
    }
  },
  {
    title: "UID",
    className: "_width-50",
    input: {
      type: "text",
      name: shaKeys.INDEPENDENT_EXPERT_LAST_NAME
    }
  },
  {
    title: "Street",
    input: {
      type: "text",
      name: shaKeys.INDEPENDENT_EXPERT_STREET
    }
  },
  {
    title: "Postal code",
    className: "_width-50",
    input: {
      type: "text",
      name: shaKeys.INDEPENDENT_EXPERT_ZIP
    }
  },
  {
    title: "Place",
    className: "_width-50",
    input: {
      type: "text",
      name: shaKeys.INDEPENDENT_EXPERT_PLACE
    }
  },
  {
    title: "Canton",
    className: "_width-50",
    input: {
      type: "autocomplete",
      name: shaKeys.INDEPENDENT_EXPERT_CANTON,
      dataSource: cantonsSortedByName,
      filterOption: true
    }
  },
  {
    title: "Country",
    className: "_width-50",
    input: {
      type: "autocomplete",
      name: shaKeys.INDEPENDENT_EXPERT_COUNTRY,
      dataSource: countriesSortedByName,
      filterOption: true
    }
  },
  {
    title: "Email",
    input: {
      type: "email",
      name: shaKeys.INDEPENDENT_EXPERT_EMAIL
    }
  },
  {
    title: "Which chamber of commerce shall be referred to?",
    input: {
      type: "select_btn",
      name: shaKeys.CHAMBER_COMMERCE_PLACE,
      options: [
        basicOption("Zurich"),
        basicOption("Bern"),
        basicOption("St.Gallen"),
        basicOption("Aargau")
      ]
    }
  }
];

export default [conversion, independentVal, vesting];
