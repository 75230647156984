const Modeling = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9855 22.9951C11.8404 22.9951 11.6953 22.9661 11.5792 22.879L0.464319 16.8138C0.17414 16.6687 0 16.3785 0 16.0592C0 15.74 0.17414 15.4498 0.464319 15.3047L4.14993 13.2733L4.99153 14.8114L2.66988 16.0592L11.9855 21.1379L21.3011 16.0592L19.0085 14.7823L19.8501 13.2442L23.5357 15.2756C23.8259 15.4207 24 15.7109 24 16.0302C24 16.3494 23.8259 16.6396 23.5357 16.7847L12.3918 22.879C12.2757 22.9661 12.1306 22.9951 11.9855 22.9951Z"
        fill="#18DC9B"
      />
      <path
        d="M11.9855 18.9323C11.8404 18.9323 11.6953 18.9033 11.5792 18.8162L0.464319 12.7509C0.17414 12.6058 0 12.3156 0 11.9964C0 11.6771 0.17414 11.3869 0.464319 11.2418L4.17893 9.21039L5.02052 10.7485L2.69887 11.9964L11.9855 17.075L21.2721 11.9964L18.9504 10.7194L19.792 9.18134L23.5066 11.2128C23.7968 11.3578 23.9709 11.6481 23.9709 11.9673C23.9709 12.2865 23.7968 12.5767 23.5066 12.7219L12.3918 18.8162C12.2757 18.9033 12.1306 18.9323 11.9855 18.9323Z"
        fill="#597EF7"
      />
      <path
        d="M11.9855 14.8695C11.8404 14.8695 11.6953 14.8405 11.5792 14.7534L0.464319 8.68806C0.17414 8.54297 0 8.25274 0 7.93351C0 7.61428 0.17414 7.3241 0.464319 7.17896L11.5792 1.1137C11.8404 0.96861 12.1596 0.96861 12.4208 1.1137L23.5357 7.17901C23.8259 7.3241 24 7.61433 24 7.93356C24 8.25279 23.8259 8.54297 23.5357 8.68812L12.3918 14.7534C12.2757 14.8404 12.1306 14.8695 11.9855 14.8695ZM2.69893 7.93351L11.9855 13.0121L21.2721 7.93351L11.9855 2.85494L2.69893 7.93351Z"
        fill="#18DC9B"
      />
    </svg>
  );
};

export default Modeling;
