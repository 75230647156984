import { withFormik } from "formik";
import { keys } from "#constants/io/equityPool";
import Presentational from "./Presentational";

export default withFormik({
  mapPropsToValues: () => ({
    name: "",
    description: "",
    fees: {
      setup: "",
      administration: {
        years: "",
        value: ""
      },
      performance: {
        simple: {
          value: "",
          hurdle: ""
        },
        multiple: []
      }
    },
    sharesPrice: 1,
    multiply: false,
    multipliers: 2,
    currency: "",
    syndicatePublic: "no",
    companyValuation: "fixed_val",
    [keys.SERIES_ROUND]: [],
    [keys.USE_PROCEEDS]: "",
    [keys.PRE_MONEY_VAL]: 0,
    [keys.PRE_MONEY_VAL_DILUTED]: "",
    [keys.NOMINAL_VAL]: 0,
    [keys.ISSUE_PRICE]: 0,
    [keys.VOTING_RIGHTS]: "",
    [keys.AMOUNT_INTEREST]: "",
    [keys.AGREED_AMOUNT_BOOL]: "",
    [keys.AGREED_AMOUNT]: "",
    [keys.EMPLOYEE_PART_PLAN_BOOL]: "",
    [keys.EMPLOYEE_PART_PLAN]: "",
    [keys.PREFERENCE_RIGHTS]: "",
    [keys.DIVIDEND_PREFERENCE]: "",
    [keys.LIQUDIATION_PREFERENCE]: "",
    [keys.LIQUDIATION_PREFERENCE_AMOUNT_BOOL]: "",
    [keys.LIQUDIATION_PREFERENCE_AMOUNT]: "",
    [keys.ANTI_DILUTION_PROTECTION]: "",
    [keys.ANTI_DILUTION_PROTECTION_FORMULA]: "",
    [keys.MAX_BOARD_DIRECTORS]: "",
    [keys.DIRECTOR_APPOINT_RIGHTS]: "",
    [keys.DIRECTOR_APPOINT_NUM]: "",
    [keys.INDEPENDENT_DIRECTOR]: "",
    [keys.MINIMUM_PERCENTAGE]: "",

    /* STEP 9 */

    [keys.SHARE_TRANSFER_RESTRICTION]: "",
    [keys.RIGHT_OF_REFUSAL]: "",
    [keys.TAG_ALONG_RIGHT]: "",
    [keys.DRAG_ALONG_RIGHT]: "",
    [keys.DRAG_ALONG_PERCENTAGE]: "",
    [keys.DRAG_ALONG_VALUATION]: "",
    [keys.PURCHASING_OPTION_SHAREHOLDER]: "",

    /* STEP 10 */

    [keys.TERM_SHEET_EXCLUSIVITY]: "",
    [keys.TERM_SHEET_EXCLUSIVITY_TERM]: "",
    [keys.PURSUANT_DOCUMENTS]: [],
    [keys.EXPENSES_COVERAGE]: "",
    [keys.EXPENSES_COVERAGE_FOUNDER]: "",
    [keys.GOVERNING_LAW]: "",
    [keys.APPENDICES]: ""
  }),
  handleSubmit: (values, { props: { handleSubmit }, ...props }) => {
    handleSubmit(values);
  }
})(Presentational);
