export default {
  alex1: require("./alex1.png"),
  alex2: require("./alex2.png"),
  philippe1: require("./philippe1.png"),
  philippe2: require("./philippe2.png"),
  mihai1: require("./mihai1.png"),
  mihai2: require("./mihai2.png"),
  silvan1: require("./silvan1.png"),
  silvan2: require("./silvan2.png"),
  fabio1: require("./fabio1.png"),
  fabio2: require("./fabio2.png"),
  drini1: require("./drini1.png"),
  drini2: require("./drini2.png"),
  nikhil1: require("./nikhil1.png"),
  nikhil2: require("./nikhil2.png"),
  alexo1: require("./alexo1.png"),
  alexo2: require("./alexo2.png"),
  simo1: require("./simo1.png"),
  simo2: require("./simo2.png"),
  laisa1: require("./laisa1.png"),
  laisa2: require("./laisa2.png"),
  nicolas1: require("./nicolas1.png"),
  nicolas2: require("./nicolas2.png"),
  katharina1: require("./katharina1.png"),
  katharina2: require("./katharina2.png"),
  mikael1: require("./mikael1.png"),
  mikael2: require("./mikael2.png"),
  marieChristine1: require("./marie-christine1.png"),
  marieChristine2: require("./marie-christine2.png"),
  mila1: require("./mila1.png"),
  mila2: require("./mila2.png")
};
