import { connect } from 'react-redux';
import { getEntity } from '#core/modules/entities/actions';
import Presentational from './Presantational';

const mapStateToProps = ({ entities }) => {
  const { _data: { entity: { cuid: id } } = {} } = entities;
  return {
    id
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEntity: (data) => dispatch(getEntity(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
