import { formatNumber } from "#helpers/number_format";
import { basicOption } from "#helpers/general";
import { confirmationPrompt } from "../../../../Forms/common/prompts";

const registrationFields = ({ zefixHandlers, values } = {}) => {
  return [
    {
      title: "Company name",
      className: "_fullwidth",

      input: {
        type: "autocomplete",
        name: "name",
        required: true,
        backfill: true,
        ...zefixHandlers
      }
    },
    {
      title: "Website",
      className: "_fullwidth",
      input: {
        type: "text",
        name: "websiteUrl",
        details: "www.supergrowth.com"
      }
    },
    {
      title: "Do you have an outstanding Employee Participation Plan (EPP)?",
      className: "_fullwidth",
      input: {
        name: "eppPrompt",
        ...confirmationPrompt
      }
    },
    values &&
      values.eppPrompt === "yes" && {
        className: "_fullwidth",
        title: "Select which EPP do you have:",
        input: {
          type: "checkbox",
          name: "eppSelector",
          options: [
            basicOption("esop", "ESOP shares"),
            basicOption("psop", "PSOP shares")
          ]
        }
      },
    values &&
      values.eppPrompt === "yes" &&
      Array.isArray(values.eppSelector) &&
      values.eppSelector.includes("esop") && {
        title:
          "How many shares are allocated to your Employee Stock Ownership Plan (ESOP)",
        className: "_fullwidth",
        roles: [],
        input: {
          type: "number",
          name: "esopShares",
          formatter: value => formatNumber(value),
          min: 0
        }
      },

    values &&
      values.eppPrompt === "yes" &&
      Array.isArray(values.eppSelector) &&
      values.eppSelector.includes("psop") && {
        title:
          "How many shares are allocated to your Phantom Stock Ownership Plan (PSOP)",
        className: "_fullwidth",
        roles: [],
        input: {
          type: "number",
          name: "psopShares",
          formatter: value => formatNumber(value),
          min: 0
        }
      },
    {
      title:
        "How many shareholders are registered directly in the share register?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: "registeredShareholders",
        options: [
          basicOption("1-20", "1-20"),
          basicOption("21-50", "21-50"),
          basicOption("51-100", "51-100"),
          basicOption("100+", "100+")
        ]
      }
    }
  ];
};

export { registrationFields };
