import { useState, useRef } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { usePdf } from "@mikecousins/react-pdf";
import { withBackNavigation } from "../Shared/hocs";
import Navigation from "./components/Navigation";
import SpinContainer from "../Shared/SpinContainer";

const PdfViewer = ({ data, location = {}, withNavigation = true }) => {
  const [loading, setLoading] = useState(true);

  const getData = () => {
    if (!data) {
      const parsedUrl = queryString.parse(location.search);

      if (parsedUrl && parsedUrl.doc) {
        const { doc: urlData } = parsedUrl || {};

        return decodeURIComponent(urlData);
      }

      const { data: stateData } = location.state || {};
      return stateData;
    }

    return data;
  };

  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);
  const finalData = getData();

  const { pdfDocument = { _pdfInfo: {} } } = finalData
    ? usePdf({
        onDocumentLoadSuccess: () => setLoading(false),
        file: finalData,
        page,
        canvasRef
      })
    : {};

  const handleNavigation = step => () => setPage(page + step);
  const disabledContextMenu = e => e.preventDefault();

  return (
    <div className="doc-viewer" onContextMenu={disabledContextMenu}>
      {withNavigation && (
        <Navigation
          current={page}
          total={pdfDocument._pdfInfo.numPages}
          onNext={handleNavigation(+1)}
          onPrev={handleNavigation(-1)}
        />
      )}
      {loading ? <SpinContainer /> : <canvas ref={canvasRef} />}
    </div>
  );
};

PdfViewer.propTypes = {
  location: PropTypes.object,
  data: PropTypes.any
};

export { PdfViewer };

export default withBackNavigation({})(PdfViewer);
