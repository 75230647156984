import Icon from 'antd/es/icon';
import FormSuccessView from '../../../Shared/Form/FormStepper/components/SuccessView';
import { Button } from '../../../Shared/Button';

const EquityCreationSuccessView = ({ type, ioId }) => () => {
  return (
    <div className="custom-success-view entity-creation">
      <FormSuccessView
        title={`Congratulations, ${type} has been set up.`}
        className="entity-creation"
        id="success-prompt"
      />
      <div className="btn-container">
        <Button
          label="View equity"
          className="main-btn"
          href={ioId ? `/dashboard/equity/${ioId}` : `/equity/overview`}
        />
      </div>
    </div>
  );
};

export default EquityCreationSuccessView;
