import * as Yup from "yup";
import { returnError } from "../common";

Yup.addMethod(Yup.array, "checkIfTwo", function(message, mapper = a => a) {
  return this.test("checkIfTwo", message, function(list) {
    const singleDirector = list.filter(
      ({ signingDirector, signingAuthority }) =>
        signingDirector && signingAuthority === "single"
    );
    const directors = list.filter(
      ({ signingDirector, signingAuthority }) =>
        signingDirector && signingAuthority === "collective"
    );
    if (singleDirector.length > 0 && directors.length > 0) {
      return true;
    } else if (singleDirector.length > 0) {
      return true;
    } else if (directors.length > 1) {
      return true;
    }
    return false;
  });
});

Yup.addMethod(Yup.array, "checkSelectedBothSides", function(
  message,
  mapper = a => a
) {
  return this.test("d", message, function(list = []) {
    const localList = list.map(shareholder =>
      !shareholder.select && shareholder.shareholderSelect
        ? { ...shareholder, shareholderSelect: false }
        : shareholder
    );
    const selectedRight = localList.filter(
      ({ shareholderSelect }) => shareholderSelect
    );
    const selectedLeft = localList.filter(({ select }) => select);
    const hasAmount = selectedLeft.filter(({ amount }) => amount > 0);

    if (selectedLeft.length > 0) {
      if (selectedRight.length > 0) {
        if (selectedRight.length !== selectedLeft.length) {
          return false;
        }
      }
      if (hasAmount.length === selectedLeft.length) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  });
});

const convertibleTermsheetSchema = Yup.object().shape({
  chamberOfCommerceMDOther: Yup.string(),
  postMoneyValuationLFR: Yup.number(),
  requestLoanDiscountNFRPrompt: Yup.string(),
  requestLoanDiscountNFR: Yup.string(),
  requestLoanShareTypeNFR: Yup.string(),
  investorRequestDiscount: Yup.number(),
  outstandingClaEffectsIncludedFloorNFR: Yup.string(),
  outstandingClaEffectsIncludedFloorNFRArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedFloorNFRArray",
    message() {
      return `You previously selected that the convertibles shall be included in the fully diluted valuation. Please select which convertibles shall be included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedFloorNFR === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),
  employeeParticipationPlanFloorNFR: Yup.string(),
  esop: Yup.number(),
  psop: Yup.number(),
  fullyDilutedFloorST: Yup.string(),
  outstandingClaEffectsIncludedFloorST: Yup.string(),
  outstandingClaEffectsIncludedFloorSTArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedFloorSTArray",
    message() {
      return `You previously selected that the convertibles shall be included in the fully diluted valuation. Please select which convertibles shall be included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedFloorST === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),
  employeeParticipationPlanFloorST: Yup.string(),
  outstandingClaEffectsIncludedFairMarketMD: Yup.string(),
  outstandingClaEffectsIncludedFairMarketMDArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedFairMarketMDArray",
    message() {
      return `You previously selected that the convertibles shall be included in the fully diluted valuation. Please select which convertibles shall be included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedFairMarketMD === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),
  employeeParticipationPlanFairMarketMD: Yup.string(),
  esopShares: Yup.number(),
  psopShares: Yup.number(),
  esopPsopFairMarketValue: Yup.array(),
  fullyDilutedFairMarketMD: Yup.string(),
  esopPsopEffectsIncludedFloorST: Yup.string(),
  esopPsopEffectsIncludedFloorNFR: Yup.array(),
  fullyDilutedFloorNFR: Yup.string(),
  employeeParticipationPlanPostMoneyMD: Yup.string(),
  fairMarketMDDividedBy: Yup.number(),

  outstandingClaEffectsIncludedPreMoneyMD: Yup.string(),
  employeeParticipationPlanMD: Yup.string(),
  requestLoanConversionNFR: Yup.string(),
  esopPsopEffectsIncludedPostValuationMD: Yup.array(),
  postMoneyValuationEPP: Yup.string(),
  outstandingClaEffectsIncludedPostMoneyMDArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedPostMoneyMDArray",
    message() {
      return `You previously selected that the convertibles shall be included in the fully diluted valuation. Please select which convertibles shall be included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedPostMoneyMD === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),
  outstandingClaEffectsIncludedPostMoneyMD: Yup.string(),
  fullyDilutedPostMoneyMD: Yup.string(),
  chamberOfCommerceMD: Yup.string(),
  qualifyEventNFR: Yup.string(),
  minEffectsIncludedNFREsopPsop: Yup.array(),
  valuationFloorDefined: Yup.string(),
  valuationFloorNFR: Yup.number(),
  requestLoanConversion: Yup.string(),
  esopPsopEffectsIncludedNFR: Yup.array(),
  esopPsopEffectsIncludedST: Yup.array(),
  valuationFloorDefinedST: Yup.string(),
  valuationFloorST: Yup.number(),
  claConversionShareClassST: Yup.string(),
  followConversionRuleMD: Yup.string(),
  conversionPriceBasisMD: Yup.array(),
  esopPsopEffectsIncludedMD: Yup.array(),

  reduceLoanWhenOversubscription: Yup.string().required(
    returnError("required")
  ),
  minEppEffectsIncludedNFR: Yup.string(),
  minEsopValue: Yup.number(),
  minPsopEffectsIncludedNFR: Yup.string(),
  minPsopValue: Yup.number(),
  eppEffectsIncludedNFR: Yup.string(),
  outstandingEsopValue: Yup.number(),
  psopEffectsIncludedNFR: Yup.string(),
  outstandingPsopValue: Yup.number(),
  eppEffectsIncludedMD: Yup.string(),
  maturityDateEsopValue: Yup.number(),
  psopEffectsIncludedMD: Yup.string(),
  maturityDatePsopValue: Yup.number(),
  eppEffectsIncludedST: Yup.string(),
  saleTransactionEsopValue: Yup.number(),
  psopEffectsIncludedST: Yup.string(),
  saleTransactionPsopValue: Yup.number(),
  pariPassu: Yup.string(),
  entitledToRepayLoan: Yup.string(),
  lendersReplyToPrepaymentOfferInDays: Yup.string(),
  lendersReplyToPrepaymentOfferInDaysOther: Yup.number(),

  outstandingClaEffectsIncludedNFRArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedNFRArray",
    message() {
      return `You previously selected that the convertibles shall be included in the fully diluted valuation. Please select which convertibles shall be included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedNFR === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),
  outstandingClaEffectsIncludedPreMoneyMDArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedPreMoneyMDArray",
    message() {
      return `You previously selected that the convertibles shall be included in the fully diluted valuation. Please select which convertibles shall be included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedPreMoneyMD === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),

  outstandingClaEffectsIncludedSTArray: Yup.array().test({
    name: "outstandingClaEffectsIncludedSTArray",
    message() {
      return `You previously selected that the convertibles shall be included in the fully diluted valuation. Please select which convertibles shall be included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.outstandingClaEffectsIncludedST === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),

  minOutstandingClaEffectsIncludedNFRArray: Yup.array().test({
    name: "minOutstandingClaEffectsIncludedNFRArray",
    message() {
      return `You previously selected that the convertibles shall be included in the fully diluted valuation. Please select which convertibles shall be included.`;
    },
    test(value = []) {
      const filteredConvertibles = value.filter(({ select = false }) => select);
      if (
        this.parent.minOutstandingClaEffectsIncludedNFR === "yes" &&
        Array.isArray(filteredConvertibles) &&
        filteredConvertibles.length === 0
      ) {
        return false;
      }
      return true;
    }
  }),
  registeredSeat: Yup.string(),
  tsSignedBy: Yup.string().required(returnError("required")),
  discountBoolST: Yup.string(),
  shareTypeMD: Yup.string(),
  saleTransictionDiscountBool: Yup.string(),
  valuationCapBoolST: Yup.string(),
  maximumInterest: Yup.number(),
  safeHarborRule: Yup.string(),
  sharesNumber: Yup.number().required(returnError("required")),
  nextFinancingRound: Yup.string().required(returnError("required")),
  name: Yup.string().test({
    name: "name",
    message({ value = "" }) {
      return `You already have a convertible loan named "${value}" in the Convertible loan management on your Dashboard. Please change it here.`;
    },
    test(value) {
      return !value || !this.parent.convertibleNameExists;
    }
  }),
  governingLaw: Yup.string().required(returnError("required")),
  governingLawOther: Yup.string(),
  maturityMonths: Yup.string().required(returnError("required")),
  currency: Yup.string().required(returnError("required")),
  signingDirector: Yup.array().checkIfTwo(({ originalValue = [] }) => {
    const singleDirector = originalValue.filter(
      ({ signingDirector, signingAuthority }) =>
        signingDirector && signingAuthority === "single"
    );
    const directors = originalValue.filter(
      ({ signingDirector, signingAuthority }) =>
        signingDirector && signingAuthority === "collective"
    );
    if (singleDirector.length === 0 && directors.length === 0) {
      return "Please select the director(s) as asked in the question";
    }
    // else if (singleDirector.length > 0 && directors.length > 0) {
    //   return "You selected a director which has collective signing authority and the one with single signing authority. To be able to continue, please select two directors with the collective signing authority or only one director with single signing authority.";
    // }
    return "You selected a director which has collective signing authority. To be able to continue, please select one more director with the collective signing authority or switch to a director with single signing authority.";
  }),

  moneyRaised: Yup.number().required(returnError("required")),
  investorHasCommited: Yup.string(),
  investorsTable: Yup.array().checkSelectedBothSides(
    ({ originalValue = [] }) => {
      const selected = originalValue.filter(({ select = false }) => select);
      const hasAmount = selected.filter(({ amount }) => amount > 0);
      const selectedRight = originalValue.filter(
        ({ shareholderSelect = false }) => shareholderSelect
      );

      if (selected.length === 0) {
        return "Please select the investor(s) as asked in the intro text.";
      }
      if (hasAmount.length !== selected.length) {
        return "You selected the investor who has committed a certain amount for a convertible loan. Please insert the committed amount in the relevant field next to the selected investor.";
      }
      if (selectedRight.length > 0) {
        if (selectedRight.length !== selected.length) {
          return "You picked a combination of investors that according to the upper instruction is not allowed. Please follow the instruction in the intro text.";
        }
      }
      return "You picked a combination of investors that is not allowed in the same term sheet. Please follow the instruction in the intro text.";
    }
  ),
  interest: Yup.string().required(returnError("required")),
  interestRate: Yup.number(),
  annualInterestCalculation: Yup.string(),
  annualInterest: Yup.number(),
  interestCompensationAtConversion: Yup.string(),
  mandatoryConversion: Yup.string().required(returnError("required")),
  triggeringEvent: Yup.array(),
  minNextFinancingAmount: Yup.number(),
  valuationCapBool: Yup.string(),
  valuationCap: Yup.number(),
  fullyDiluted: Yup.string(),
  fullyDilutedSelect: Yup.boolean(),
  outstandingClaEffectsIncluded: Yup.string(),
  esopEffectsIncluded: Yup.string(),
  discountBool: Yup.string(),
  discount: Yup.string(),
  shareType: Yup.string(),
  shareTransferRestriction: Yup.string(),
  saleTransactionDiscountRateBool: Yup.string(),
  otherCl: Yup.string(),
  convertiblesOutstanding: Yup.array(),
  outstandingEsop: Yup.string(),
  outstandingEsopShares: Yup.string(),
  esopEstablishment: Yup.string(),
  triggerEventModel: Yup.string(),
  preMoneyVal: Yup.string(),
  esopShares: Yup.boolean(),
  previousLoans: Yup.boolean(),
  underlyingSecurity: Yup.string(),
  underlyingSecuritySharesType: Yup.string(),
  underlyingSecuritySharesNumber: Yup.number(),
  underlyingSecuritySharesNominalValue: Yup.number(),
  underlyingSecuritySharesLiqudationMulti: Yup.number(),
  subordination: Yup.string(),
  saleTransactionValuationCap: Yup.string(),
  maturityDateShareTypeConversion: Yup.string(),
  saleTransactionDiscountRate: Yup.string(),
  saleTransactionPreMoneyVal: Yup.number(),
  maturityDatePreMoneyVal: Yup.number(),
  nextFinancingFullyDiluted: Yup.string(),
  potentiallyOutstandingConvertible: Yup.string(),
  potentiallyOutstandingEsop: Yup.string(),
  articleOfAssociation: Yup.string(),
  saleTransactionFullyDilyted: Yup.string(),
  saleTransactionpotentiallyOutstandingConvertible: Yup.string(),
  saleTransactionPotentiallyOutstandingEsop: Yup.string(),
  maturityDateFullyDiluted: Yup.string(),
  maturityDatePotentiallyOutstandingConvertible: Yup.string(),
  maturityDatePotentiallyOutstandingEsop: Yup.string(),
  maturityDateArticleOfAssociation: Yup.string(),
  minNextFinancingAmountNFRPrompt: Yup.string(),
  minNextFinancingAmountNFR: Yup.string(),
  minValuationNFRPrompt: Yup.string(),
  minValuationNFR: Yup.number(),
  minFullyDilutedNFR: Yup.string(),
  minOutstandingClaEffectsIncludedNFR: Yup.string(),
  minEsopEffectsIncludedNFR: Yup.string(),
  valuationCapBoolNFR: Yup.string(),
  valuationCapNFR: Yup.number(),
  fullyDilutedNFR: Yup.string(),
  outstandingClaEffectsIncludedNFR: Yup.string(),
  esopEffectsIncludedNFR: Yup.string(),
  discountBoolNFR: Yup.string(),
  discountNFR: Yup.number(),
  shareTypeNFR: Yup.string(),
  shareTransferRestrictionNFR: Yup.string(),
  discountST: Yup.number(),
  valuationCapST: Yup.string(),
  saleTransactionPercentage: Yup.number(),
  fullyDilutedST: Yup.string(),
  outstandingClaEffectsIncludedST: Yup.string(),
  esopEffectsIncludedST: Yup.string(),
  saleTransactionShareholdersOwningLess: Yup.number(),
  valuationCapMDPrompt: Yup.string(),
  valuationCapMD: Yup.number(),
  fullyDilutedPreMoneyMD: Yup.string(),
  outstandingClaEffectsIncludedMD: Yup.string(),
  esopEffectsIncludedMD: Yup.string()
});

export default convertibleTermsheetSchema;
