import {
  actionsContracts,
  dealAttachmentCategories,
  downloadableContracts
} from "#constants/io/ioTypes";

export const mapContractToAttach = values => {
  return {
    name: values.contractName,
    url: values.value,
    createdAt: values.createdAt,
    type: "contract",
    category: dealAttachmentCategories.LEGAL,
    downloadable: downloadableContracts.includes(values.contractType),
    showActions: actionsContracts.includes(values.contractType)
  };
};

export const mapFinancialContractsToAttach = values => ({
  name: values.contractName,
  url: values.value,
  createdAt: values.createdAt,
  type: "contract",
  category: dealAttachmentCategories.TRANSACTION_SLIPS,
  downloadable: true
});
