export default {
  LOGIN: 'LOGIN',
  REGISTRATION: 'REGISTRATION',
  VERIFY_TOKEN: 'VERIFY_TOKEN',
  RESEND_EMAIL_VERIFICATION: 'RESEND_EMAIL_VERIFICATION',
  NEW_PASSWORD: 'NEW_PASSWORD',
  GENERATE_2FA: 'GENERATE_2FA',
  COMPLETE_2FA: 'COMPLETE_2FA',
  REQUEST_PASSWORD_RESET: 'REQUEST_PASSWORD_RESET',
  CONFIRM_ACCOUNT: 'CONFIRM_ACCOUNT'
};
