import qualifiedInvestors from "../../../../assets/docs/qualifiedinvestor.pdf";
import { Button } from "../../../Shared/Button";

const Disclaimers = ({ name }) => {
  return (
    <div className="disclaimers-wrapper">
      <div className="disclaimers-container">
        <div className="content">
          <span>Dear {name},</span>
          <span>
            You have successfully created your investor profile and can now
            access the platform.
            <br />
            If you have any questions, feel free to reach out to us Monday –
            Friday from 9.30 to 17.00 at
            <a href="tel: +41 33 533 33 77"> +41 33 533 33 77</a>.
          </span>
        </div>
        <div className="btn-container">
          <Button label="Go to deal" href="/" className="redirection" />
        </div>
      </div>
    </div>
  );
};

export default Disclaimers;
