import "antd/lib/list/style/css";
import { List, Icon } from "antd";
import PropTypes from "prop-types";
import { VAT_NEW_VALUE, VAT_VALUE } from "#constants/deal";
import { formatNumber } from "../../../../../../helpers/number_format";
import Input from "../../../../Input";

const FirstStep = ({
  amount,
  minimal,
  validationError,
  handleSetState,
  currency,
  fees,
  vat,
  new_vat,
  adjustedAmount = 0,
  title = "",
  subTitle = "",
  manageCommitmentSubmit
}) => {
  const { adminFee, setupFee, performance } = fees;
  let vatValue = 0;

  if (new_vat) vatValue = (adminFee + setupFee) * VAT_NEW_VALUE;
  else if (vat) vatValue = (adminFee + setupFee) * VAT_VALUE;

  const totalAdminFee = Number(adminFee * adjustedAmount).toFixed(2);
  const totalSetupFee = Number(setupFee * adjustedAmount).toFixed(2);
  const totalVat = Number(vatValue * adjustedAmount).toFixed(2);

  const allFees = [
    {
      type:
        "Investment amount (adjusted to match the precise number of shares you're buying)",
      customValue: formatNumber(adjustedAmount, currency)
    },
    {
      type: "Closing fee",
      customValue: formatNumber(totalSetupFee, currency)
    },
    {
      type: "Administration fee",
      customValue: formatNumber(totalAdminFee, currency)
    }
  ];

  if (typeof performance === "object" && !!performance.value) {
    allFees.push({
      type: "Carried interest",
      percentage: performance.value,
      showPercent: true
    });
  } else if (Array.isArray(performance) && performance.length > 0) {
    allFees.push({
      type: "Performance fee",
      customValue: (
        <div className="performance-fee-block">
          {performance.map(({ breakpoint, value }, index) => (
            <div className="item">
              {Number(value * 100).toFixed(2)}% if performance{" "}
              {index === 0 ? "below or equal to" : "above"} {breakpoint}X
            </div>
          ))}
        </div>
      )
    });
  }

  if (vat || new_vat) {
    allFees.push({
      type: "VAT",
      percentage: vatValue
    });
  }
  allFees.push({
    type: "Total to pay",
    customValue: formatNumber(
      Number(
        Number(adjustedAmount) +
          Number(totalAdminFee) +
          Number(totalSetupFee) +
          Number(totalVat)
      ).toFixed(2),
      currency
    )
  });

  const regex = new RegExp(`${currency}\\s?|(\\'*)`, "g");
  return (
    <div className="investment-size">
      <div className="step-title">{title}</div>
      <div className="step-subtitle">{subTitle}</div>
      <div className="investment-amount">
        <Input
          type="number"
          placeholder="Enter your investment amount"
          value={amount}
          wrapperClassname={validationError ? "error-class" : null}
          formatter={
            amount
              ? value =>
                  `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
              : null
          }
          parser={value => value.replace(regex, "")}
          onChange={e => {
            handleSetState("amount")(e.target.value);
            handleSetState("validationError")("");
          }}
          onPressEnter={manageCommitmentSubmit}
        />
        {validationError ? (
          <div className="error-message">{validationError}</div>
        ) : (
          <div className="info-message">
            A minimum of {formatNumber(minimal, currency)} is required for the
            transaction.
          </div>
        )}

        <List
          className="fees"
          dataSource={allFees}
          itemLayout="horizontal"
          renderItem={({ type, percentage, showPercent, customValue }) => {
            const feeAmount = Number(
              parseFloat(amount, 10) * percentage
            ).toFixed(2);
            const finalAmount = amount ? formatNumber(feeAmount, currency) : 0;
            const renderFinalValue = () => {
              if (showPercent) {
                return `${percentage * 100}%`;
              } else if (customValue) {
                return customValue;
              }

              return finalAmount;
            };

            return (
              <List.Item className="fee-item">
                <span className="fee-name">{type}</span>
                <span className="fee-amount">{renderFinalValue()}</span>
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
};

FirstStep.propTypes = {
  handleSetState: PropTypes.func,
  minimal: PropTypes.number,
  currency: PropTypes.string,
  validationError: PropTypes.string,
  fees: PropTypes.object,
  vat: PropTypes.bool,
  adjustedAmount: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  manageCommitmentSubmit: PropTypes.func
};

export default FirstStep;
