import { baseSelector } from "./utils";
import { cLvl as levels } from "#constants/confirmationLevel";

const selectUserDataFunc = state => {
  const data = state._data || {};

  const kycFinished =
    !!data.kyc &&
    !!data.kyc.reviewResult &&
    !!data.kyc.kycResults &&
    data.cLvl > levels.ONBOARDED;

  return { ...data, kycFinished };
};

const selectUserData = baseSelector(selectUserDataFunc);

export { selectUserData };
