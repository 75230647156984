import { SimpleUploader } from '../../Uploader';
import { CardWrapper } from '../';
import { Button } from '../../Button';
import InputContainer from '../../Input';

const AddNewsCard = ({
  title,
  body,
  handleSubmit,
  handleChange,
  buttonStates,
  uploadEvents,
  visible
}) => {
  return visible ? (
    <CardWrapper className="addnews-card-wrapper">
      <div className="text-area-container">
        <InputContainer
          type="text"
          name="title"
          details="Write the news title here"
          onChange={handleChange}
          value={title}
        />
        <InputContainer
          type="textarea"
          name="body"
          autoSize={{ minRows: 4 }}
          details="News content goes here"
          onChange={handleChange}
          value={body}
        />
        <div className="btn-container">
          <SimpleUploader name="news_attachments" {...uploadEvents} />
          <Button label="Post news" onClick={handleSubmit} {...buttonStates} />
        </div>
      </div>
    </CardWrapper>
  ) : null;
};

export default AddNewsCard;
