import * as Yup from 'yup';
import { returnError } from './common';

export const founderInvSchema = Yup.object().shape({
  inviteeEmail: Yup.string()
    .email('Not a valid email')
    .required(returnError('required')),
  inviteeFirstName: Yup.string().required(returnError('required')),
  inviteeLastName: Yup.string().required(returnError('required'))
});
