export const NOTIFICATION_SCOPES = {
  KYC_APPROVAL: "kyc_approval"
};

export const NOTIFICATION_ACTIONS = {
  COMMITMENTS_REVIEWED: "commitments_reviewed"
};

export const grantNotificationPermission = async ({
  postNotifsSubscription
}) => {
  if (!("Notification" in window)) {
    console.log("This browser does not support system notifications");
    return;
  }

  if (
    Notification.permission !== "denied" &&
    Notification.permission === "default"
  ) {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      postNotifsSubscription();
    }
  }
};

export const getNotifActionLink = metadata => {
  const { scope, targetId } = metadata || {};
  if (scope === "investments" || scope === "commitments") {
    return `/dashboard/deal_management/${targetId}`;
  } else if (scope === "investment_memberships") {
    return `/invitations`;
  }
};

export const catchNotifications = (action, opts) => {
  const { userId, strict } = opts || {};

  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener("message", event => {
      const {
        data: { msg: { metadata: { userId: recipient } = {} } = {} } = {}
      } = event;

      if (strict) {
        if (recipient === userId) {
          action(event);
        }
      } else {
        action(event);
      }
    });
  }
};

export const dispatchActions = (props, afterEffect) => event => {
  const { data: { msg = {} } = {} } = event;

  const { title, message, metadata = {} } = msg;
  const { userId, cuid } = metadata;

  const actionLink = getNotifActionLink(metadata);

  if (userId === props.userId) {
    props.triggerSnackbar({
      message: title,
      description: message,
      action: actionLink
        ? {
            description: message,
            label: "View here",
            handlers: { onClick: () => props.history.push(actionLink) }
          }
        : null,
      variant: "info",
      open: true
    });

    if (typeof afterEffect === "function") {
      afterEffect(msg);
    }

    if (cuid) {
      props.updateNotifsList({ cuid, content: msg });
    }
  }
};
