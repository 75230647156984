import { contractTypes } from "#constants/deal";

// Docs for each deal type defined below. 1 means mandatory, 0 optional.

export const equityDealDocs = {
  [contractTypes.SUBSCRIPTION_FORM_VERRECHNUNG]: 1,
  [contractTypes.INVESTMENT_AGREEMENT]: 0
};
export const claDealDocs = { [contractTypes.CLA]: 1 };
export const fundDealDocs = {
  [contractTypes.SUBSCRIPTION_FORM_VERRECHNUNG]: 1,
  [contractTypes.CAPITAL_CALL_NOTICE]: 1,
  [contractTypes.LIMITED_PARTNERSHIP_AGREEMENT]: 1,
  [contractTypes.PRIVATE_PLACEMENT_MEMORANDUM]: 1
};
export const safeDealDocs = { [contractTypes.SAFE]: 1 };
export const kissDealDocs = { [contractTypes.KISS]: 1 };
export const bsaAirDealDocs = { [contractTypes.BSA_AIR]: 1 };
