import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { List, Icon } from "antd";
import { returnUserRole } from "#helpers/general";
import { Button } from "../../Button";
import AvatarWrapper from "../../Avatar";
import InvitationGraphic from "../../../../assets/images/invitation.png";
import moment from "moment";

const InvitationAcceptancePresentational = ({
  clearState,
  getEntities,
  getIoMemberships,
  entitiesData,
  iosData,
  putIoMembershipStatus,
  scope = "invitation_page",
  setAfterAcceptance,
  userFirstName
}) => {
  const [aggregatedMemberships, setAggregatedMemberships] = useState([]);

  useEffect(() => {
    clearState("entities");
    clearState("ioMemberships");
    getEntities();
    getIoMemberships();
  }, []);

  useEffect(() => {
    if ((entitiesData.didFetch && iosData.didFetch) || iosData.didUpdate) {
      const { list: entitiesList } = entitiesData;
      const { list: iosList } = iosData;

      const formattedIos = iosList
        .filter(({ status, fresh }) => status === 0 || fresh)
        .reduce((acc, item) => {
          const { io: { entityId } = {} } = item || {};
          const { [entityId]: entityItems = [] } = acc;
          return {
            ...acc,
            [entityId]: [...entityItems, item]
          };
        }, {});
      const aggregated = entitiesList
        .map(item => {
          const {
            cuid,
            name,
            previewImage,
            membership: { role } = {},
            createdAt
          } = item;
          if (formattedIos[cuid] && formattedIos[cuid].length > 0) {
            return {
              entityId: cuid,
              name,
              previewImage,
              memberships: formattedIos[cuid],
              role,
              createdAt
            };
          }
        })
        .filter(Boolean);
      setAggregatedMemberships(aggregated);
    }
    if (scope === "onboarding" && iosData.didUpdate) {
      setAfterAcceptance(true);
    }
  }, [entitiesData.didFetch, iosData.didFetch, iosData.didUpdate]);

  const handleMembershipStatusUpdate = item => () => {
    putIoMembershipStatus(item);
  };

  const Action = ({ cuid }) => {
    if (scope === "onboarding") {
      return <span style={{ cursor: "default" }}>Invitation accepted.</span>;
    }

    return (
      <Button
        label={
          <span>
            View deal <Icon type="arrow-right" />{" "}
          </span>
        }
        href={`/dashboard/deal_management/${cuid}`}
      />
    );
  };

  const ListItem = ({ previewImage, name, memberships, role }) => (
    <List.Item>
      <List.Item.Meta
        avatar={<AvatarWrapper src={previewImage} />}
        title={name}
        description={
          <List
            dataSource={memberships}
            renderItem={({
              cuid,
              io: { cuid: ioId, name: ioName, entityId } = {},
              createdAt,
              status
            }) => {
              const createdDate = moment(createdAt);
              const now = moment();

              const diff = now.diff(createdDate, "days");
              const today = diff === 0;
              const yesterday = diff === 1;
              const receivedDate = () => {
                if (today) return "Received today";
                if (yesterday) return "Received yesterday";
                return `Received on ${createdDate.format("MMMM Do, YYYY")}`;
              };
              return (
                <List.Item
                  actions={[
                    status === 0 ? (
                      <div className="received-date">
                        <p>{receivedDate()}</p>
                        <Button
                          className="accept-btn"
                          label="Accept"
                          tooltip="Accept this invitation"
                          theme="filled"
                          onClick={handleMembershipStatusUpdate({
                            action: "approve",
                            cuid,
                            entityId
                          })}
                          loading={
                            iosData.isUpdating && iosData.objectRef === cuid
                          }
                          disabled={iosData.isUpdating}
                        />
                      </div>
                    ) : (
                      <Action cuid={ioId} />
                    )
                  ]}
                >
                  <List.Item.Meta
                    title={ioName}
                    description={`Invited as ${returnUserRole(role)}`}
                  />
                </List.Item>
              );
            }}
          />
        }
      />
    </List.Item>
  );

  return (
    <div className="invitation-acceptance">
      {scope === "onboarding" && (
        <h1>
          Welcome {userFirstName}. Accept the pending invitation to view the
          deal.
        </h1>
      )}
      <div className="img-wrapper">
        <img src={InvitationGraphic} alt="Invitation" />
      </div>
      <List
        dataSource={aggregatedMemberships}
        renderItem={ListItem}
        className="entities-list-container"
        locale={{
          emptyText: "No pending invitations."
        }}
      />
    </div>
  );
};

InvitationAcceptancePresentational.propTypes = {
  clearState: PropTypes.func,
  getEntities: PropTypes.func,
  getIoMemberships: PropTypes.func,
  putIoMembershipStatus: PropTypes.func,
  entitiesData: PropTypes.object,
  iosData: PropTypes.object,
  setAfterAcceptance: PropTypes.func,
  userFirstName: PropTypes.string
};

export default InvitationAcceptancePresentational;
