import { Layout, Row, Col, Typography } from "antd";
import Navbar from "../Navbar/Presentational";
import Feature from "../shared/Features/Presentational";
import CustomFeature from "../Home2/CustomFeature/Presentational";
import Footer from "../Footer/Presentational";
import Contact from "../shared/Contact/Presesntational";
import Copy from "../shared/Copy/Presentational";
import HeroDm from "../../../assets/illustrations/deal manager page/herodm.png";
import StepsFeature from "../shared/StepsFeature/Presentational";
import VentureFeatures from "../VentureSyndicate/VentureFeatures/Presentational";
import LogoList from "../shared/LogoList/Presentational";
import HowItWorksCard from "../Startup/HowItWorksCard/Presentational";
import Carousel from "../shared/Carousel/Presentational";
import {
  steps,
  expertiseFeatures,
  expertiseTexts,
  careFreeSolution,
  carouselItems,
  howItWorks
} from "./list";

const { Title } = Typography;

const featureButtons = [
  {
    name: "Schedule demo",
    href: "https://askleva.typeform.com/to/dWuscfPj",
    left: true
  }
  // { name: 'I am a startup raising funds', to: '/startup', left: false },
];

export default function DealManagers() {
  const onCarouselChange = current => {};

  return (
    <Layout className="shared-layout">
      <Row>
        <Col className="shared-col-2" span={24}>
          <Row type="flex" justify="center">
            <Col span={20}>
              <Navbar />
              <Feature
                showButtons={true}
                buttons={featureButtons}
                className="feature-l2-title2"
                header="End-to-End co-investment platform"
                textLeft={true}
                text="Your private back-office solution to compliantly syndicate deals with your co-investors, monetize your deal flow, and manage your venture portfolio."
                icon={HeroDm}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row type="flex" justify="center">
        <Col span={24} style={{ marginTop: "5%" }}>
          <Row type="flex" justify="center">
            <Col className="how-it-works-wrapper" span={24}>
              <h2 className="l1-title">How does it work?</h2>
              <div className="how-it-works-features-wrapper">
                {howItWorks.map(({ title, content, icon }) => (
                  <HowItWorksCard title={title} content={content} icon={icon} />
                ))}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="venture-features-wrapper">
          <Row type="flex" justify="center">
            <Col xs={22} sm={22} md={20} lg={20} xl={20}>
              <VentureFeatures
                ventureTexts={expertiseTexts}
                features={expertiseFeatures}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Contact
          scheduleDemo={false}
          content="Grow your network and scale your investment activity"
        />
        <Col span={18} className="my-3">
          <StepsFeature
            header="Close deals in just a few clicks"
            steps={steps}
          />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col span={20} className="custom-feature-dm-wrapper">
          <div>
            <Title level={2} className="l1-title">
              Trusted by professional co-investors worldwide
            </Title>
            <LogoList customerIcons className="logolist-wrapper" />
          </div>
          <CustomFeature
            className="custom-feature-l2-title2"
            header={careFreeSolution.header}
            textLeft
            text={careFreeSolution.text}
            icon={careFreeSolution.icon}
            items={careFreeSolution.items}
          />
        </Col>

        <Col xs={22} sm={22} md={22} lg={16} xl={12} className="my-3">
          <Title level={2} className="l1-title">
            What do they say about us
          </Title>
          <Carousel items={carouselItems} onCarouselChange={onCarouselChange} />
        </Col>

        <Contact
          contactUs={false}
          title="Discover our product"
          content="Get in touch to learn more about LEVA"
        />
        <Footer />
        <Copy />
      </Row>
    </Layout>
  );
}
