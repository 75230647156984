import { useState, useEffect } from "react";
import { Icon } from "antd";
import { useInput } from "#helpers/hooks/input";
import { roles } from "#constants/roles";

import Input from "../Input";
import UserDropdown from "./components/UserDropdown";
import NotificationPopover from "./components/NotifsPopover";
import DropdownButton from "./components/DropdownButton";

const Appbar = ({
  user,
  clearState,
  userDidUpdate,
  postNotifsSubscription,
  notificationCreated,
  isAmFounder,
  hasEntities,
  userRole,
  getNotifsHistory,
  notificationsColl,
  updateNotifsStatus,
  bulkUpdateNotifsStatus
}) => {
  const { value: searchValue, bind } = useInput("");
  const [profileModalVisible, setModalVisible] = useState(false);
  const toggleModal = () => {
    setModalVisible(!profileModalVisible);
  };

  useEffect(() => {
    clearState("notifications");
    getNotifsHistory();
  }, []);

  //Drini commented, not sure if it would brake anything
  // useEffect(() => {
  //   if (userDidUpdate) {
  //     clearState("users");
  //   }
  // }, [userDidUpdate]);

  const handleSearch = e => {
    if (e.keyCode === 13) {
      // ASSUMING WE WANT THE LOGIC TO BE EXECUTED WHEN THE USER PRESSES ENTER
      // fire a searching function here
    }
  };

  return (
    <div className="appbar">
      {userRole !== roles.FOUNDER && userRole !== roles.INVESTOR ? (
        <div className="search-input-container">
          <Input
            prefix={<Icon type="search" className="search-icon" />}
            wrapperId="search-input"
            details="Search by keyword..."
            size="large"
            value={searchValue}
            {...bind}
            onKeyDown={handleSearch}
            disabled
          />
        </div>
      ) : null}

      {/* {(userRole === roles.ASSET_MANAGER || userRole === roles.FOUNDER) && (
        <DropdownButton isAmFounder={isAmFounder} hasEntities={hasEntities} />
      )} 
      {userRole === roles.ASSET_MANAGER && (
        <div className="asset-manager-dropdown">
          <DropdownButton />
        </div>
      )}*/}
      <div className="notification-user-items-wrapper">
        {userRole !== roles.INVESTOR && userRole !== roles.FOUNDER ? (
          <div
            className={`${userRole === roles.AM_INVESTOR &&
              "notification-popover-wrapper"} notification-popover-container`}
          >
            <NotificationPopover
              notificationsColl={notificationsColl}
              postNotifsSubscription={postNotifsSubscription}
              notificationCreated={notificationCreated}
              updateNotifsStatus={updateNotifsStatus}
              bulkUpdateNotifsStatus={bulkUpdateNotifsStatus}
            />
          </div>
        ) : null}
        <div
          className={`user-dropdown-container
            ${
              userRole === roles.FOUNDER || userRole === roles.INVESTOR
                ? "user-dropdown-container-right"
                : ""
            }`}
        >
          <UserDropdown
            toggleModal={toggleModal}
            userDetails={user}
            userRole={userRole}
          />
        </div>
      </div>
    </div>
  );
};

export default Appbar;
