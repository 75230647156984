import { useState, useEffect } from "react";
import { Row, Col, Typography, Steps } from "antd";

const { Title } = Typography;
const { Step } = Steps;

export default function Presentational({ steps, ...props }) {
  const [icon, setIcon] = useState(steps[0]?.icon);
  const [current, setCurrent] = useState(0);

  const handleStepChange = current => {
    setCurrent(current);
    setIcon(steps[current]?.icon);
  };

  return (
    <div className="steps-feature-col-wrapper">
      <Row className="steps-feature-row">
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="steps-feature-col"
        >
          <img className="steps-feature-col-img" src={icon} alt="step-icon" />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="steps-feature-col"
        >
          <Title
            level={2}
            className={props.className || "steps-feature-l2-title"}
          >
            {props.header}
          </Title>
          <Steps
            current={current}
            onChange={handleStepChange}
            direction="vertical"
          >
            {steps.map(step => (
              <Step title={step.title} description={step.description} />
            ))}
          </Steps>
        </Col>
      </Row>
    </div>
  );
}
