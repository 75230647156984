import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Steps, Button, Modal as baseModal } from "antd";
import { legalDocsType } from "#constants/deal";
import { commitStatus } from "#constants/commit";
import ioTypes from "#constants/io/ioTypes";
import FirstStep from "./components/FirstStep";
import SecondStep from "./components/SecondStep";
import ThirdStep from "./components/ThirdStep";
import FourthStep from "./components/FourthStep";
import FifthStep from "./components/FifthStep";
import Modal from "../../Modal";
import { KycStatusAlertContent } from "..";
import { formatNumber } from "#helpers/number_format";

const { Step } = Steps;

class InvestmentProcessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      amount: undefined,
      penaltyFeeTerms: false,
      validationError: undefined
    };
  }

  componentDidMount() {
    this.props.clearState("commits");
  }

  componentDidUpdate(prevProps) {
    const {
      commitDidCreate,
      executed,
      success,
      pendingSoftCommit,
      commitDidDelete
    } = this.props;

    const automaticModalClose =
      (!executed || commitDidDelete) &&
      success &&
      success !== prevProps.success;

    if (automaticModalClose) {
      this.clearStateAndCloseModal();
    } else if (prevProps.success !== success && this.state.current === 3) {
      this.handleSetState("current")(4);
    }

    if (
      pendingSoftCommit &&
      prevProps.pendingSoftCommit !== pendingSoftCommit
    ) {
      this.setState({
        amount: pendingSoftCommit.amount
      });
    }
  }

  handleSetState = key => value => {
    this.setState({
      [key]: value
    });
  };

  adjustAmount = ({ amount, sharesPrice }) =>
    Math.ceil(
      (Math.floor(amount / sharesPrice) * sharesPrice).toFixed(5) * 100
    ) / 100;

  validateCommitment = commitedAmount => {
    const sharesPrice = this?.props?.io?.sharesPrice || 0;
    const {
      io: { minimal, fundingGoal, roundedMoneyCommitted, roundedMoneyInvested },
      executed,
      pendingSoftCommit
    } = this.props;

    let err;

    const adjustedAmount = this.adjustAmount({
      amount: commitedAmount,
      sharesPrice
    });

    if (adjustedAmount < minimal) {
      err = "The committed amount is less than the defined minimum!";
    } else if (
      roundedMoneyInvested + roundedMoneyCommitted + adjustedAmount >
      fundingGoal + 1
    ) {
      err = "The committed amount should not exceed the funding goal!";
    } else if (
      executed &&
      pendingSoftCommit &&
      pendingSoftCommit.amount < adjustedAmount
    ) {
      err = "The committed amount should not exceed the initial amount!";
    } else {
      err = "";
    }
    this.handleSetState("validationError")(err);
    return err;
  };

  completeFirstStep = commitedAmount => {
    if (!this.validateCommitment(commitedAmount)) {
      this.manageNavigation(1)();
    }
  };

  manageCommitmentSubmit = () => {
    const { io, name, executed, pendingSoftCommit } = this.props;

    const { currency, moneyCommitted } = io;
    if (!this.validateCommitment(this.state.amount)) {
      if (executed && pendingSoftCommit) {
        this.props.updateCommitDataStatus({
          amount: this.state.amount,
          cuid: pendingSoftCommit.cuid,
          moneyCommitted:
            moneyCommitted - pendingSoftCommit.amount + this.state.amount,
          requestType: "soft_to_real",
          ioId: io.cuid
        });
      } else if (pendingSoftCommit) {
        this.props.patchCommit({
          cuid: pendingSoftCommit.cuid,
          moneyCommitted:
            moneyCommitted - pendingSoftCommit.amount + this.state.amount,
          amount: this.state.amount,
          ioId: io.cuid
        });
      } else {
        const commitmentData = {
          amount: this.state.amount,
          ioId: io.cuid,
          status: executed ? commitStatus.PENDING : commitStatus.SOFT
        };

        const defaults = {
          // the data here should match the model of the data we receive when we fetch all commits
          currency,
          committer: { name },
          moneyCommitted
        };

        this.props.postCommit({ commit: commitmentData, defaults });
      }
    }
  };

  manageNavigation = move => () => {
    const amount = this.state?.amount || 0;
    const sharesPrice = this?.props?.io?.sharesPrice || 0;
    const adjustedAmount = amount
      ? this.adjustAmount({ amount, sharesPrice })
      : 0;
    if (this.state.current === 0) {
      if (!this.validateCommitment(this.state.amount)) {
        if (this.props.io.type === ioTypes.POOLED_FUND) {
          this.props.getPooledFundTemplates({
            io_id: this.props.io.cuid,
            amount: parseFloat(this.state.amount, 10)
          });
        } else {
          this.props.generateContracts({
            investmentId: this.props.io.cuid,
            amount: parseFloat(this.state.amount, 10)
          });
        }
        this.handleSetState("current")(1);
      }
    } else if (this.state.current === 1 && move !== -1) {
      this.handleSetState("current")(this.state.current + move);
    } else if (this.state.current === 3 && move !== -1) {
      const {
        currency: { shortName: currencyShortName } = {},
        syndicate: { name = "" } = {}
      } = this.props.io || {};

      baseModal.confirm({
        icon: <div />,
        title: "Final investment confirmation",
        content: (
          <span>
            You are about to commit{" "}
            <b>{`${currencyShortName} ${formatNumber(adjustedAmount)}`}</b>{" "}
            unconditionally to the syndicate <b>{name}</b>.
          </span>
        ),
        onOk: this.manageCommitmentSubmit,
        okText: "Confirm",
        centered: true
      });
    } else {
      const step = this.state.current + move;
      this.handleSetState("current")(step);
    }
  };

  resetState = () => {
    this.setState({
      current: 0,
      amount: undefined,
      penaltyFeeTerms: false,
      validationError: undefined,
      attachmentTerms: false,
      penaltyFeeTerms: false,
      spaAgreementTerms: false,
      partnerAgreement: false
    });
  };

  clearStateAndCloseModal = () => {
    const { confirm } = baseModal;
    const { success, executed } = this.props;

    if (success) {
      this.props.closeModal();
      this.props.clearState("commits");
      this.resetState();
    } else if (executed) {
      confirm({
        title:
          "You are in the middle of the investment process. Are you sure you want to exit?",
        onOk: () => {
          this.setState({
            current: 0,
            validationError: undefined
          });
          this.props.closeModal();
        },
        centered: true
      });
    } else {
      this.props.closeModal();
    }
  };

  manageNextDisability = () => {
    let disabled;

    const {
      current,
      amount,
      attachmentTerms,
      penaltyFeeTerms,
      spaAgreementTerms,
      partnerAgreement
    } = this.state;

    if (current === 0 && amount) {
      disabled = false;
    } else if (current === 1 && attachmentTerms) {
      disabled = false;
    } else if (
      (current === 2 || current === 3) &&
      penaltyFeeTerms &&
      spaAgreementTerms &&
      partnerAgreement
    ) {
      disabled = false;
    } else {
      disabled = true;
    }

    return disabled;
  };

  handleKycRedir = () => {
    const { history } = this.props;

    history.push("/kyc");
  };

  render() {
    const {
      current,
      attachmentTerms,
      penaltyFeeTerms,
      spaAgreementTerms,
      partnerAgreement,
      validationError,
      amount
    } = this.state;

    const {
      io,
      contracts,
      contractsAreFetching,
      entityName,
      executed,
      pendingSoftCommit,
      pendingConfirmation,
      canInvest,
      kycFinished,
      name: investorName
    } = this.props;
    const {
      bankAccount,
      minimal,
      fundingGoal,
      moneyInvested,
      currency: { shortName: currencyShortName } = {},
      fees,
      attachments = [],
      vat,
      new_vat,
      name,
      syndicate: { name: syndicateName = "" } = {},
      sharesPrice = 1
    } = io;

    const shortName = currencyShortName;
    const adjustedAmount = amount
      ? Math.ceil(
          (Math.floor(amount / sharesPrice) * sharesPrice).toFixed(5) * 100
        ) / 100
      : 0;
    const finalWrapperClsName = `inv-process-wrapper ${
      !canInvest ? "blocked" : ""
    }`;

    const softCommitmentStep = {
      content: (
        <FirstStep
          amount={amount}
          fees={{
            adminFee: fees?.administration?.value,
            setupFee: fees?.setup?.value,
            performance: fees?.performance
          }}
          vat={vat}
          new_vat={new_vat}
          minimal={minimal}
          currency={shortName || ""}
          fundingGoal={fundingGoal}
          moneyInvested={moneyInvested}
          handleSetState={this.handleSetState}
          validationError={validationError}
          setValidationError={this.setValidationError}
          adjustedAmount={adjustedAmount}
          title="Soft commitment amount"
          subTitle="You are commiting"
          manageCommitmentSubmit={this.manageCommitmentSubmit}
        />
      )
    };

    const steps = [
      {
        title: "Investment amount",
        content: (
          <FirstStep
            amount={amount}
            fees={{
              adminFee: fees?.administration?.value,
              setupFee: fees?.setup?.value,
              performance: fees?.performance
            }}
            vat={vat}
            new_vat={new_vat}
            minimal={minimal}
            currency={shortName || ""}
            fundingGoal={fundingGoal}
            moneyInvested={moneyInvested}
            handleSetState={this.handleSetState}
            validationError={validationError}
            setValidationError={this.setValidationError}
            adjustedAmount={adjustedAmount}
            title="Investment amount"
            subTitle="You are investing"
          />
        )
      },
      {
        title: "Contract signing",
        content: (
          <SecondStep
            attachmentTerms={attachmentTerms}
            handleSetState={this.handleSetState}
            contracts={contracts}
            attachments={attachments}
            loading={contractsAreFetching}
            type={io.type}
          />
        )
      },
      {
        title: "Review",
        content: (
          <ThirdStep
            spaAgreementTerms={spaAgreementTerms}
            penaltyFeeTerms={penaltyFeeTerms}
            partnerAgreement={partnerAgreement}
            handleSetState={this.handleSetState}
            syndicateName={syndicateName}
            adjustedAmount={adjustedAmount}
            entity={entityName}
            type={io.type}
          />
        )
      },
      {
        title: "Commitment",
        content: (
          <FourthStep
            amount={adjustedAmount}
            currency={shortName || ""}
            handleSetState={this.handleSetState}
            fees={{
              adminFee: fees?.administration?.value,
              setupFee: fees?.setup?.value
            }}
            dealName={name}
            contracts={contracts}
            vat={vat}
            new_vat={new_vat}
            adjustedAmount={adjustedAmount}
            entity={entityName}
            type={io.type}
          />
        )
      },
      {
        title: "Summary",
        content: (
          <FifthStep
            bankDetails={bankAccount}
            commitData={this.props.commit}
            currency={shortName || ""}
            administrationFee={fees?.administration?.value}
            setupFee={fees?.setup?.value}
            name={name}
            vat={vat}
            new_vat={new_vat}
            adjustedAmount={adjustedAmount}
            investorName={investorName}
          />
        )
      }
    ];

    const handleSoftCommitDelete = () => {
      if (Object.keys(pendingSoftCommit).length > 0) {
        this.props.deleteCommit({
          cuid: pendingSoftCommit.cuid,
          ioId: this.props.io.cuid
        });
      }
    };

    const modalTitle = () => (
      <div className="steps-container">
        <Steps current={this.state.current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
    );

    const BtnContainer = () => {
      const { isDeleting } = this.props;
      const isFirstStep = current === 0;

      const submitBtn = {
        label: current === 3 || !this.props.executed ? "Commit" : "Next",
        action: executed
          ? this.manageNavigation(1)
          : this.manageCommitmentSubmit,
        loading: this.props.loading,
        disabled: !canInvest || this.manageNextDisability()
      };

      const backBtn = {
        label: isFirstStep ? "Cancel" : "Back",
        action: isFirstStep ? this.props.closeModal : this.manageNavigation(-1),
        class: isFirstStep ? "cancel" : "back"
      };

      const investorHasInvested = this?.props?.myInvestments.filter(
        inv => inv.hasRealCommitTimestamp
      );

      const showWithdrawCommitmentBtn =
        (!executed && pendingSoftCommit) ||
        (executed &&
          investorHasInvested.length === 0 &&
          this?.props?.myInvestments.length > 0 &&
          this.state.current === 0);

      return (
        <Fragment>
          {current < 4 && (
            <div className="cancel-back-next">
              <div>
                {executed && (
                  <Button className={backBtn.class} onClick={backBtn.action}>
                    {backBtn.label}
                  </Button>
                )}
                {showWithdrawCommitmentBtn && (
                  <Button
                    type="danger"
                    className="delete"
                    onClick={handleSoftCommitDelete}
                    loading={isDeleting}
                    disabled={!canInvest}
                  >
                    Withdraw commitment
                  </Button>
                )}
              </div>
              <Button
                type="primary"
                disabled={submitBtn.disabled}
                onClick={submitBtn.action}
                loading={submitBtn.loading}
              >
                {submitBtn.label}
              </Button>
            </div>
          )}
          {current === 4 && (
            <div className="back-to-dashboard">
              <Button type="primary" onClick={this.clearStateAndCloseModal}>
                Back to dashboard
              </Button>
            </div>
          )}
        </Fragment>
      );
    };

    return (
      <Modal
        visible={this.props.visibility}
        title={this.props.executed && modalTitle()}
        width={1000}
        footer={null}
        onCancel={this.clearStateAndCloseModal}
        className="investment-process-modal"
      >
        {!canInvest && (
          <KycStatusAlertContent
            onOk={this.handleKycRedir}
            onCancel={this.props.closeModal}
            kycFinished={kycFinished}
          />
        )}
        <div className={finalWrapperClsName}>
          <div className="steps-content">
            {this.props.executed
              ? steps[current].content
              : softCommitmentStep.content}
          </div>
          <div className="steps-action">
            <BtnContainer />
          </div>
        </div>
      </Modal>
    );
  }
}

InvestmentProcessModal.propTypes = {
  contracts: PropTypes.array,
  commitDidCreate: PropTypes.bool,
  commitIsCreating: PropTypes.bool,
  clearState: PropTypes.func,
  closeModal: PropTypes.func,
  visibility: PropTypes.bool,
  io: PropTypes.object,
  postCommit: PropTypes.func,
  commit: PropTypes.object,
  generateContracts: PropTypes.func,
  contractsAreFetching: PropTypes.bool,
  name: PropTypes.string,
  entityName: PropTypes.string,
  executed: PropTypes.bool,
  deleteCommit: PropTypes.func,
  pendingSoftCommit: PropTypes.object,
  pendingConfirmation: PropTypes.bool
};

export default InvestmentProcessModal;
