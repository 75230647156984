import Switch from "antd/es/switch";

const SwitchWrapper = ({
  label,
  checked,
  value,
  onChange,
  name,
  className = "",
  green = false,
  ...props
}) => {
  const finalClassname = `switch-container ${className}`;
  const finalValue = checked !== undefined ? checked : value;

  return (
    <div className={finalClassname}>
      <div className={`${green ? "green-switch" : ""}`}>
        <Switch
          className="mui-switch"
          name={name}
          onChange={onChange}
          checked={finalValue}
          {...props}
        />
      </div>
      {label && <div className="label">{label}</div>}
    </div>
  );
};
export default SwitchWrapper;
