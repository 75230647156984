import propTypes from "prop-types";
import Logo from "../../../assets/illustrations/Logo.png";

export default function NotFound({ message, img }) {
  return (
    <div className="notfound-wrapper">
      <div className="notfound-container">
        <div className="logo-wrapper">
          {img ? (
            <img src={img} alt="Syndicate logo" />
          ) : (
            <img width="120" src={Logo} alt="Logo" />
          )}
        </div>
        <div className="content">
          <h3>{message || "Sorry, we can't find this page"}</h3>
        </div>
      </div>
    </div>
  );
}

NotFound.propTypes = {
  message: propTypes.string,
  img: propTypes.any
};
