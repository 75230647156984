const sections = [
  {
    id: 1,
    title: "A. General information",
    subSections: [
      {
        id: 1,
        title: "Financial"
      },
      {
        id: 2,
        title: "Shares"
      },
      {
        id: 3,
        title: "Stakeholders"
      }
    ]
  },
  {
    id: 4,
    title: "B. General assembly"
  },
  {
    id: 5,
    title: "C. Board resolution"
  }
];

export default sections;
