import { useParams } from "react-router-dom";
import FormSuccessView from "../../../Shared/Form/FormStepper/components/SuccessView";
import { Button } from "../../../Shared/Button";

const CLAQuestionnaireSuccessView = cuid => {
  const { id = "" } = useParams();
  return (
    <div className="custom-success-view ts-creation">
      <FormSuccessView
        title="Congrats, you just finished to set-up the convertible loan agreement."
        className="entity-creation"
        id="success-prompt"
      />
      <h3>
        You can come back and edit your convertible loan agreement at any time. 
      </h3>
      <span className="helper-text">
        If you wish to add further terms, which are not covered in the
        questionnaire we recommend to reach out to X’s Startup Desk.
      </span>
      <div className="btn-container">
        <Button
          label="View convertible"
          className="main-btn"
          href={
            id
              ? `/cla/overview/${id}`
              : cuid
              ? `/cla/overview/${cuid}`
              : "/cla/overview"
          }
        />
      </div>
    </div>
  );
};

export default CLAQuestionnaireSuccessView;
