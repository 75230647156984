import * as taxesService from "../../../../api/taxesService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import { roles } from "../../../../src/constants/roles";

export const getTaxes = params => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_TAXES);
      const response = await taxesService.getTaxes(params);
      success(dispatch, types.GET_TAXES, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_TAXES, {
        error: errorData
      });
    }
  };
};

export const getYearlyReport = (userRole, params) => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_YEARLY_REPORT);
      const response = await taxesService.getYearlyReport(params);
      success(dispatch, types.GET_YEARLY_REPORT, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;

      const errorMessage =
        userRole === roles.ASSET_MANAGER
          ? "Please remember to fill all your entities tax data to download your yearly tax report."
          : "We are sorry, but your deal manager has still not finalised the tax document for this year.";
      catchError(dispatch, errorMessage);
      fail(dispatch, types.GET_YEARLY_REPORT, {
        error: errorData
      });
    }
  };
};
