import { connect } from "react-redux";
import {
  getShareClasses,
  getEntityCapTable,
  deleteShareClasses
} from "#core/modules/shares/actions";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = ({ entities, shares }) => {
  const { entity: { cuid: entityId, shares: entityShares } = {} } =
    entities._data || {};
  const {
    _data: { list = [], captable: { shares: sharesList = [] } = {} } = {},
    isFetching: loading
  } = shares;

  const allShares = sharesList.map(({ cuid, ...item }) => {
    const shareRecord = list.find(({ cuid: listCuid }) => listCuid === cuid);
    if (shareRecord) {
      return {
        ...item,
        cuid: shareRecord.cuid,
        currency: shareRecord.currency
      };
    }
    return { ...item, cuid };
  });

  return {
    entityId,
    sharesList: allShares,
    loading,
    entityShares
  };
};

const mapDispatchToProps = dispatch => ({
  getShareClasses: params => dispatch(getShareClasses(params)),
  getEntityCapTable: params => dispatch(getEntityCapTable(params)),
  clearState: key => clearState(dispatch, key),
  deleteShareClasses: params => dispatch(deleteShareClasses(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
