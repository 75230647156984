const riskWarnings = [
  {
    input: {
      type: 'switch',
      name: 'startupFail',
      label:
        'I understand that most startups fail and it is therefore risky to invest in these businesses. And, I therefore understand that neither the business nor Leva is obliged to pay back my investment if the business fails.'
    }
  },
  {
    input: {
      type: 'switch',
      name: 'ipoCheck',
      label:
        'I understand that I am unlikely to be able to sell my shares until and unless the company I invested in goes public in an initial public offering (IPO), or is bought by another company.'
    }
  },
  {
    input: {
      type: 'switch',
      name: 'dividends',
      label:
        ' I understand that early-stage companies very rarely pay dividends, as profits are typically re-invested into the business to fuel growth and build shareholder value.'
    }
  },
  {
    input: {
      type: 'switch',
      name: 'portfolio',
      label:
        'I understand that investing in startups should only be done as part of a diversified portfolio.'
    }
  }
];

export default [{ id: 1, sectionId: 2, items: riskWarnings }];
