import { useEffect } from "react";
import PropTypes from "prop-types";
import { entityTypes } from "#constants/entity";
import { InvestmentCard } from "../../../Shared/Cards";
import { withNdaSignature, withContentFallback } from "../../../Shared/hocs";
import SpinContainer from "../../../Shared/SpinContainer";
import investorDidntParticipate from "../../../../assets/illustrations/investor-didnt-participate.svg";

const InvestmentWrapper = props => {
  const {
    actions,
    entity,
    history,
    ndaDidFetch,
    ndasList,
    currUserId,
    ndaStatuses,
    loading,
    assetManager,
    syndicatesWhereUserIsInvestor = []
  } = props;

  useEffect(() => {
    actions.clearState("nda");
    actions.clearState("syndicate");
    actions.clearStateDataByKey("io", "ios");
    actions.clearStateDataByKey("nda", "list");
    actions.getNdas({ asset_manager_id: assetManager.cuid });
    actions.getEntitiesList({
      creator_id: assetManager.cuid,
      entity_type: entityTypes.ASSET_MANAGER_ENTITY
    });
    actions.getEntity(entity.cuid);
  }, []);

  useEffect(() => {
    actions.clearState("nda");
    actions.clearState("syndicate");
    actions.clearStateDataByKey("io", "ios");
    actions.clearStateDataByKey("nda", "list");
    actions.getNdas({ asset_manager_id: assetManager.cuid });
    actions.getEntitiesList({
      creator_id: assetManager.cuid,
      entity_type: entityTypes.ASSET_MANAGER_ENTITY
    });
    actions.getEntity(entity.cuid);
  }, [entity.cuid]);

  useEffect(() => {
    if ((ndaDidFetch || ndaStatuses.success) && ndasList.length === 0) {
      actions.getPortfolioSyndicates();
      actions.clearState("nda");
    }
  }, [ndaDidFetch, ndaStatuses.success]);
  const filteredSyndicates =
    syndicatesWhereUserIsInvestor.filter(
      syndicate => syndicate.entityId === entity.cuid
    ) || [];
  const handleIoNav = cuid => () =>
    history.push(`/dashboard/deal_management/${cuid}`);

  const signNda = () => {
    const { cuid } = ndasList.find(({ userId }) => userId === currUserId) || {};

    if (cuid) {
      actions.putNdaSignature({ cuid, type: "user_signing" });
    }
  };

  const actionList = [
    {
      label: "Upgrade document"
    },
    { label: "Signing document" },
    { label: "Delete", customStyle: "danger" }
  ];
  const content = (
    <div className="investment-cards-wrapper">
      <div className="cards-container">
        {Array.isArray(filteredSyndicates) &&
          filteredSyndicates.map(({ investments, ...syndicate }) => {
            return investments.map(item => {
              return (
                <InvestmentCard
                  {...item}
                  syndicateName={syndicate.name}
                  previewImage={item.previewImage || entity.previewImage}
                  description={entity.description}
                  commonName={entity?.commonName}
                  actionList={actionList}
                  key={`rand-${item.cuid}-om`}
                  onClickHandler={handleIoNav(item.cuid)}
                  amProfilePic={assetManager.previewImage}
                  amName={assetManager.name}
                  investmentType={entity.investmentType}
                />
              );
            });
          })}
      </div>
    </div>
  );
  const MainContent = () => {
    if (ndasList.length !== 0) {
      return withNdaSignature({ signNda, ndaStatuses })(content);
    }

    const FinalContent = withContentFallback({
      loading,
      empty: filteredSyndicates.length === 0,
      customizedIcon: <div />,
      customizedEmptyMsg: (
        <>
          <p>
            You did not invest in <b>{entity?.commonName}</b> and the deal is
            now closed. <br />
            Click on Management → Invitations from the left menu to check if you
            have any other pending invite to a different deal. <br /> If not,
            we'll notify you via email as soon as a deal manager invites you to
            participate in another opportunity.
          </p>
        </>
      )
    })(() => content);

    return <FinalContent />;
  };

  if (loading) {
    return <SpinContainer />;
  }

  return MainContent();
};

InvestmentWrapper.propTypes = {
  ios: PropTypes.array,
  IOsAreFetching: PropTypes.bool,
  IOsDidFetch: PropTypes.bool,
  actions: PropTypes.object,
  entity: PropTypes.object,
  history: PropTypes.object
};

export default InvestmentWrapper;
