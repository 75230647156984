import { useEffect, useState } from 'react';
import { convertToBase64 } from '#helpers/convertBase64';
import {
  mapInvestmentDetails,
  mapPersonalDetails
} from '#helpers/mappers/onboarding';
import Form from './Form/Presentational';

const Questionnaire = ({ clearState, buttonStates, updateUserSettings }) => {
  const [por, setPor] = useState(null);

  useEffect(() => {
    clearState('users');
  }, []);

  const handleUpload = (setFieldValue) => (name, info) => {
    if (info.file && info.file.originFileObj) {
      setPor(info.file.originFileObj);
      setFieldValue(name, true);
    }
  };

  const handleSubmit = async (values) => {
    const payload = mapInvestmentDetails(values);

    if (por) {
      payload.investmentDetails.powerOfRepresentationBase64 = await convertToBase64(
        por
      );
    }

    updateUserSettings(payload);
  };

  return (
    <div className="onboarding-questionnaire">
      <Form
        handleSubmit={handleSubmit}
        buttonStates={buttonStates}
        handleUpload={handleUpload}
      />
    </div>
  );
};

export default Questionnaire;
