import { connect } from "react-redux";
import {
  getEntity,
  updateEntity,
  getEntityMemberships,
  getEntityShareholders
} from "#core/modules/entities/actions";
import {
  createIo,
  fetchSingleIo,
  createDealAndBulkMembers,
  updateDealAndBulkMembers
} from "#core/modules/io/actions";
import { putIoInfo } from "#core/modules/io/actions/update";
import { getCommits } from "#core/modules/commitment/actions/";
import { clearState } from "#core/utils/helper_actions";
import { getAllMembers } from "#helpers/general";
import { bulkCreateIoMemberships } from "#core/modules/iomemberships/actions";
import { getIoMemberships } from "#core/modules/iomemberships/actions";

import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { entity: { cuid: entityId, ...entityData } = {}, memberships = [] } =
    state.entities._data || {};
  const { cuid: userId } = state.users._data || {};
  const {
    isCreating,
    isUpdating,
    didCreate,
    didUpdate,
    didFetch: ioDidFetch,
    _data: { io } = {}
  } = state.io;

  const {
    isUpdating: entityUpdating,
    didCreate: entityDidCreate
  } = state.entities;

  const { commits = [] } = state.commits._data || {};

  const { _data: { shareholders = [] } = {}, didFetch } = state.shares || {};

  const {
    isCreating: membershipsCreating,
    isUpdating: membershipsUpdating,
    didCreate: membershipsCreated,
    didUpdate: membershipUpdated
  } = state.ioMemberships;

  const allMemberships = getAllMembers({ memberships, shareholders });
  const { memberships: ioMemberships = [] } = state.ioMemberships._data || {};

  return {
    entityId,
    entityData,
    userId,
    buttonStates: {
      loading:
        isCreating ||
        isUpdating ||
        entityUpdating ||
        membershipsCreating ||
        membershipsUpdating,
      success: didCreate || didUpdate || entityDidCreate
    },
    memberships: allMemberships,
    shareholdersColl: {
      data: shareholders,
      didFetch
    },
    ioColl: {
      didFetch: ioDidFetch,
      data: io
    },
    ioMemberships,
    commits
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  updateEntity: data => dispatch(updateEntity(data)),
  getEntityMemberships: data => dispatch(getEntityMemberships(data)),
  getEntityShareholders: data => dispatch(getEntityShareholders(data)),
  bulkCreateIoMemberships: data => dispatch(bulkCreateIoMemberships(data)),
  createIo: data => dispatch(createIo(data)),
  fetchSingleIo: data => dispatch(fetchSingleIo(data)),
  putIoInfo: data => dispatch(putIoInfo(data)),
  getEntity: data => dispatch(getEntity(data)),
  getIoMemberships: data => dispatch(getIoMemberships(data)),
  updateDealAndBulkMembers: data => dispatch(updateDealAndBulkMembers(data)),
  createDealAndBulkMembers: data => dispatch(createDealAndBulkMembers(data)),
  getCommits: entityId => dispatch(getCommits(entityId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
