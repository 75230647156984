import { useEffect, useState } from "react";
import { mapVotingSessionValues } from "#helpers/mappers/voting";
import { BasicFormWrapper, FormStepper } from "../../../Shared/Form";
import { sections, mapFieldsList } from "./list";
import { handleInputChange } from "../../common/formHandler";
import CustomInputRenderer from "../CustomInputRenderer";
import VotingModal from "../VotingModal";

const VotingRightsForm = ({
  values,
  assemblyColl,
  setFieldValue,
  handleChange,
  buttonStates,
  phoneNumber,
  votingId,
  putGenerateVotingCodeInv,
  postVotingResult
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [list, setFieldList] = useState([]);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (assemblyColl.didFetch) {
      const { questionnaire } = assemblyColl.data || [];
      setFieldList(questionnaire);
    }
  }, [assemblyColl.didFetch]);

  const handleSendCodeClick = () => {
    setFieldValue("send_sms_code", "sent");
    putGenerateVotingCodeInv({ cuid: votingId });
  };

  const onSubmit = () => {
    const localValues = values;
    delete localValues["send_sms_code"];
    const mappedValues = mapVotingSessionValues(localValues);

    postVotingResult({
      cuid: votingId,
      data: { token, questionsAndAnswers: mappedValues }
    });
    setModalVisible(false);
  };

  const handleVerifyClick = token => () => {
    setModalVisible(true);
    setToken(token);
  };

  const formArgs = {
    customClassName: "voting-session",
    list: mapFieldsList(list, values),
    sections,
    values,
    inputEvents: {
      onChange: type =>
        handleInputChange({ handleChange, setFieldValue, values })(type)
    },
    CustomInputRenderer: CustomInputRenderer({
      setFieldValue,
      values,
      handleSendCodeClick,
      handleVerifyClick,
      phoneNumber,
      success: buttonStates.success
    }),
    buttonStates,
    // handleSubmit,
    success: buttonStates.success,
    successTitle: "You have successfully participated in this voting assembly.",
    finish: false,
    skippable: false
  };

  return (
    <>
      {modalVisible && (
        <VotingModal
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          onSubmit={onSubmit}
          {...buttonStates}
        />
      )}
      <FormStepper {...formArgs} />
    </>
  );
};

export default BasicFormWrapper(VotingRightsForm);
