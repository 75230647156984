import { Tag } from 'antd';

const TagWrapper = ({ className, color, bordered, children, ...props }) => {
  const finalClassname = `root-tag-wrapper ${color} ${className} ${
    bordered ? 'bordered' : ''
    }`;

  return (
    <Tag className={finalClassname} {...props}>
      {children}
    </Tag>
  );
};

export default TagWrapper;
