import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  getCompaniesList,
  getCompanyDetails
} from "#core/modules/entities/actions";
import { createSelector } from "reselect";

const zefixHandlers = opts => {
  const { onSelectActions = () => {} } = opts || {};
  const { companiesList = [], companiesListFetching } =
    useSelector(state => state.entities._data) || {};
  const dispatch = useDispatch();

  const fetchDetails = useCallback(
    data => {
      dispatch(getCompanyDetails(data));
    },
    [dispatch]
  );

  const fetchList = useCallback(
    data => {
      dispatch(getCompaniesList(data));
    },
    [dispatch]
  );

  return {
    onSearch: value => {
      if (value) {
        fetchList({ name: value });
        onSelectActions();
      }
    },
    onSelect: value => {
      if (value) {
        fetchDetails({ chid: value });
      }
    },
    onBlur: value => {
      if (value) {
        fetchDetails({ name: value });
        onSelectActions();
      }
    },
    dataSource: companiesList,
    loading: companiesListFetching
  };
};

export { zefixHandlers };
