import KickOffYourCampaign from "../../../assets/illustrations/founder/kick-off-your-campaign.png";
import ManageAndGrow from "../../../assets/illustrations/deal manager page/ManageAndGrow.svg";
import CreateShareClose from "../../../assets/illustrations/deal manager page/create-share.svg";
import CloseTheDeal from "../../../assets/illustrations/founder/close-the-deal.png";
import ManageYourPortfolio from "../../../assets/illustrations/founder/manage-your-portfolio.png";
import benefits from "../../../assets/illustrations/venture syndicate/benefits.svg";
import Pascal from "../../../assets/illustrations/founder/carousel/pascal.jpeg";
import Mathew from "../../../assets/illustrations/founder/carousel/mathew.jpeg";
import Siev from "../../../assets/illustrations/founder/carousel/siev.jpeg";
import Ava from "../../../assets/illustrations/founder/carousel/ava.png";
import Adresta from "../../../assets/illustrations/founder/carousel/adresta.png";
import Testmate from "../../../assets/illustrations/founder/carousel/testmate.png";
import Invite from "../../../assets/illustrations/startup page/invite.png";
import Upload from "../../../assets/illustrations/startup page/upload.png";
import Collect from "../../../assets/illustrations/startup page/collect.png";

export const steps = [
  {
    title: "Kick-off your campaign",
    description: "Register your company and set the terms of the deal",
    icon: KickOffYourCampaign
  },
  {
    title: "Invite your co-investors",
    description: "Invite potential co-investors to check out your campaign",
    icon: ManageAndGrow
  },
  {
    title: "Track the fundraising",
    description: "Co-investors invest into your deal online in a few clicks",
    icon: CreateShareClose
  },
  {
    title: "Close the deal",
    description: "We'll collect the funds and send them to the target entity",
    icon: CloseTheDeal
  },
  {
    title: "Manage your co-investor relations",
    description: "Use the platform to share reports and manage voting",
    icon: ManageYourPortfolio
  }
];

export const expertiseTexts = {
  header: "Benefits of digitizing your round with LEVA",
  content:
    "Learn how you can take advantage of LEVA's digital fundraising process to close your round with no headaches",
  icon: benefits
};

export const expertiseFeatures = [
  {
    title: "Pool co-investors",
    content:
      "Pool the smaller tickets participating in the round to keep your cap table clean"
  },
  {
    title: "Data room",
    content:
      "Leverage a professional data room to share documents with the co-investors"
  },
  {
    title: "Raise more",
    content:
      "Anyone can invest electronically, managing multiple co-investors becomes easy with LEVA"
  },
  {
    title: "Show traction",
    content:
      "Collect soft commitments to show traction to the co-investors and close the round faster"
  },
  {
    title: "Online signatures",
    content:
      "All the co-investors conveniently sign the pooling agreement online"
  },
  {
    title: "Simplify governance",
    content:
      "The pool elects one representative who will sign and vote on behalf of the other co-investors"
  }
];

export const carouselItems = [
  {
    img: Pascal,
    content:
      "Raising funds and managing co-investors is incredibly time consuming. I wish I would have known LEVA years back! They make it so easy to raise funds and most importantly, with the Venture Syndicate you can pool your co-investors and keep a clean cap table.",
    name: "Pascal Koenig    ",
    position: "Serial founder",
    firm: "Raised 100m for several startups",
    logo: Ava
  },
  {
    img: Mathew,
    content:
      "Coordinating with co-investors is very time consuming. LEVA's platform made it so easy for us to onboard interested co-investors, share all relevant documents, and even collect soft commitments. Their team was super helpful and always supported us.",
    name: "Mathew Chittazhathu",
    position: "CEO & Co-Founder",
    firm: "Adresta",
    logo: Adresta
  },
  {
    img: Siev,
    content:
      "We wanted to give value-add co-investors the possibility to participate in our round alongside our VCs. LEVA made it so easy for us to raise funds and pool our co-investors in no time! They took care of all the compliance and our co-investors could sign everything online.",
    name: "Siew-Veena Sahi",
    position: "CEO & Co-Founder",
    firm: "Testmate Health",
    logo: Testmate
  }
];

export const howItWorks = [
  {
    title: "Setup your terms and upload deal docs",
    content:
      "Setup the deal terms, choose the funding goal, upload relevant documents to your private data room",
    icon: Upload
  },
  {
    title: "Invite co-investors to your campaign",
    content:
      "Send a private invitation to your co-investors and allow them to check the deal and participate in the campaign",
    icon: Invite
  },
  {
    title: "Collect commitments and investments",
    content:
      "Co-investors can commit and send funds online. We make investing in your business easier than ever",
    icon: Collect
  }
];
