import { handleColor } from "#helpers/general";
import Checkbox from "../../../../Shared/Checkbox";
import TagWrapper from "../../../../Shared/Tag";

const generalInformationsCols = (values, { checkboxInArray }) => [
  {
    title: "Select",
    dataIndex: "signingDirector",
    key: "signingDirector",
    render: (value, { cuid, signingAuthority }) => {
      return (
        <Checkbox
          checked={value}
          name="signingDirector"
          onChange={checkboxInArray("signingDirector", cuid)}
          disabled={signingAuthority === "none"}
        />
      );
    }
  },
  {
    title: "First name",
    dataIndex: "firstName",
    key: "firstName"
  },
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName"
  },
  {
    title: "President of the board",
    dataIndex: "presidentOfTheBoard",
    key: "presidentOfTheBoard",
    render: presidentOfTheBoard => (presidentOfTheBoard ? "Yes" : "No")
  },
  {
    title: "Representing",
    dataIndex: "representativeOf",
    key: "representativeOf",
    render: text => <TagWrapper color={handleColor(text)}>{text}</TagWrapper>
  },
  {
    title: "Signature",
    dataIndex: "signingAuthority",
    key: "signingAuthority",
    render: text => <TagWrapper color={handleColor(text)}>{text}</TagWrapper>
  }
];

const convertibleCols = (values, { checkboxInArray }, name) => {
  const array = values[name] || [];
  const selected =
    Array.isArray(array) &&
    array.length > 0 &&
    array.filter(({ select }) => select).map(({ cuid }) => cuid);
  return [
    {
      title: "Select",
      dataIndex: "select",
      key: "select",
      render: (value, { cuid }) => {
        return (
          <Checkbox
            checked={value}
            name="select"
            onChange={checkboxInArray(name, cuid)}
          />
        );
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    }
    // {
    //   title: "Fully-diluted",
    //   dataIndex: "fullyDilutedConvertible",
    //   key: "fullyDilutedConvertible",
    //   render: (value, { cuid }) => {
    //     return (
    //       <Checkbox
    //         checked={value}
    //         name="fullyDilutedConvertible"
    //         onChange={checkboxInArray(name, cuid)}
    //         disabled={!selected.includes(cuid)}
    //       />
    //     );
    //   }
    // }
  ];
};

export { generalInformationsCols, convertibleCols };
