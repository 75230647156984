import { connect } from "react-redux";
import {
  getAllEntityMemberships,
  bulkCreateEntityAndDealMembership,
  bulkCreateEntityMembership
} from "#core/modules/entities/actions";
import {
  getIoMemberships,
  bulkCreateIoMemberships
} from "#core/modules/iomemberships/actions";
import { clearState } from "#core/utils/helper_actions";

import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    didFetch: ioMembershipFetched,
    _data: { memberships: ioMemberships = [] } = {}
  } = state.ioMemberships || {};
  const {
    _data: {
      memberships: entityMemberships = [],
      entity: { cuid: entityId, userRole }
    } = {},
    didFetch: entitiesFetched,
    didCreate,
    isCreating
  } = state.entities || {};

  return {
    ioMemberships,
    entityMemberships,
    entitiesFetched,
    entityId,
    ioMembershipFetched,
    membershipsLoading: state.ioMemberships.isFetching,
    buttonStates: {
      success: state.ioMemberships.didCreate || didCreate,
      loading: state.ioMemberships.isCreating || isCreating
    },
    userRole
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  getIoMemberships: data => dispatch(getIoMemberships(data)),
  bulkCreateIoMemberships: data => dispatch(bulkCreateIoMemberships(data)),
  bulkCreateEntityAndDealMembership: data =>
    dispatch(bulkCreateEntityAndDealMembership(data)),
  getAllEntityMemberships: () => dispatch(getAllEntityMemberships()),
  bulkCreateEntityMembership: data => dispatch(bulkCreateEntityMembership(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
