import { Tag } from 'antd';
import avatarColors from '#constants/avatarColors';
import Avatar from '../../../../Shared/Avatar';

const renderTagData = (status) => {
  if (status === 'progress') {
    return { indicator: 'blue', text: 'In progress' };
  } else if (status === 'success') {
    return { indicator: 'green', text: 'Success' };
  }

  return { indicator: 'red', text: 'Failed' };
};

const renderAvatar = (text, fullWidth) => (
  <div className={`name-wrapper ${fullWidth ? '_fullWidth' : ''}`}>
    <Avatar color={avatarColors[Math.floor(Math.random() * 4)]}>
      {text && text[0]}
    </Avatar>
    <span className="name">{text}</span>
  </div>
);
const overviewTable = [
  {
    title: 'Shareholder',
    dataIndex: 'shareholder',
    render: (text) => renderAvatar(text)
  },
  {
    title: 'Shares',
    dataIndex: 'shares'
  },
  {
    title: 'Shareholding',
    dataIndex: 'shareholding'
  }
];

const sharesTable = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text) => renderAvatar(text, true)
  },
  {
    title: 'Date',
    dataIndex: 'date'
  },
  {
    title: 'Amount',
    dataIndex: 'amount'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (text) => {
      const data = renderTagData(text);
      return (
        <Tag className="tag-wrapper" color={data.indicator}>
          {data.text}
        </Tag>
      );
    }
  }
];

export { overviewTable, sharesTable };
