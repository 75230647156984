import Title from '../../Title';

const withPageTitle = (title) => (Target) => (props) => (
  <div className="custom-page-wrapper">
    <Title title={title} />
    <Target {...props} />
  </div>
);

export default withPageTitle;
