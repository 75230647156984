import { getYearlyReport } from "#core/modules/taxes/actions";
import { clearState } from "#core/utils/helper_actions";
import { connect } from "react-redux";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { isFetching, didFetch, _data: { yearlyReport } = {} } = state.taxes;

  return {
    loading: isFetching,
    success: didFetch,
    yearlyReport,
  };
};

const mapDispatchToProps = dispatch => ({
  getYearlyReport: (userRole, params) =>
    dispatch(getYearlyReport(userRole, params)),
  clearState: key => clearState(dispatch, key)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
