import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './action_types';
import actions from '../../constants/action_type';
import { updateObjectInArray } from '../../utils/mutators';

const initState = {
  ...initialState
};
const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.FETCH_WATCHLIST:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            list: action.payload.data
          };
        }
      });
    case types.ADD_WATCHLIST:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          const list = state._data ? state._data.list : [];
          return {
            ...state._data,
            list: updateObjectInArray(list, action.payload.data.cuid, action.payload.data),
          };
        }
      });
    case types.DELETE_WATCHLIST:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          let list = state._data ? state._data.list : [];
          if (list.length > 0) {
            list = list.filter((item) => {
              return item.cuid !== action.payload.data.cuid;
            });
          }
          return {
            ...state._data,
            list
          };
        }
      });
    default:
      return state;
  }
};
export default reducer;
