import avatarColors from '#constants/avatarColors';
import Avatar from '../../';

const NameAvatar = ({ customClassname = '', src, name }) => {
  const finalClassname = `avatar-name-wrapper ${customClassname}`;

  return (
    <div className={finalClassname}>
      <Avatar color={avatarColors[Math.floor(Math.random() * 4)]} src={src}>
        {name && name[0]}
      </Avatar>
      <span className="name">{name}</span>
    </div>
  );
};

export default NameAvatar;
