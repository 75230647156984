import { Button } from "../../Shared/Button";
import InvitationGraphic from "../../../assets/images/invitation.png";

export default function AfterAcceptance({ handleNav }) {
  return (
    <div className="after-acceptance">
      <h1>Congratulations: you’re about to access your first deal on Leva!</h1>
      <div className="img-wrapper">
        <img src={InvitationGraphic} alt="Invitation" />
      </div>
      <p>
        Leva is an invite-only investor platform to make startup investing
        easier than ever. To participate in a deal, you must receive an
        invitation from the deal manager. Check out our investor{" "}
        <a
          href="https://levafundraising.notion.site/levafundraising/Getting-Started-on-Leva-8c610ed95f0944259cc00ecc0ab9616b"
          target="_blank"
        >
          step-by-step guide
        </a>{" "}
        to learn how to navigate the deal dashboard. Are you ready? Let’s get
        started!
      </p>
      <div className="custom-btn-container">
        <Button label="View deal" onClick={handleNav} />
      </div>
    </div>
  );
}
