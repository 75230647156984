import { Col } from "antd";

export default function Logo(props) {
  return (
    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="feature-col">
      <div style={{ textAlign: "center", height: "100%" }}>
        <img
          src={props.logo.content}
          className="your-benefits-logolist-img"
          alt="logo"
        />
        <br />
        <p className="l4-title">{props.logo.text}</p>
        {props?.logo?.details && <p className="text">{props?.logo?.details}</p>}
      </div>
    </Col>
  );
}
