import { useEffect } from "react";
import { addInvestorSchema } from "#validators/investor";
import { roles } from "#constants/roles";
import { BasicForm } from "../../../../Form";
import list from "./list";
import BasicWrapper from "../../../../BasicWrapper";
import { Button } from "../../../../Button";

const FormWrapper = ({
  createMembership,
  ioId,
  buttonStates,
  handleClose,
  clearState,
  okButtonLabel,
  createEntityMembership,
  entityId,
  withNda,
  userRole,
  onboardInviteBtn
}) => {
  useEffect(() => {
    clearState("ioMemberships");
    clearState("entities");
  }, [ioId]);

  useEffect(() => {
    if (buttonStates.success) {
      clearState("ioMemberships");
      handleClose();
    }
  }, [buttonStates]);

  const handleSubmit = values => {
    const avoidNotification = roles.FOUNDER === userRole;

    createMembership({
      ioId,
      entityMembershipRole: roles.AM_INVESTOR,
      redirectUrlForNewUsers: `${process.env.HOST}/onboarding`,
      redirectUrlForExistingUsers: `${process.env.HOST}/login?redirectTo=invitations`,
      avoidNotification,
      ...values
    });
  };

  const CustomFooter = ({ values, buttonStates, onCancel, onClick }) => (
    <div className="form-btn-container">
      <Button {...onboardInviteBtn} type="secondary" />
      <Button label="Cancel" onClick={onCancel} type="normal" />
      <Button
        label="Invite"
        onClick={onClick}
        {...buttonStates}
        disabled={withNda && !values.amSignedNDA}
        tooltip={
          withNda && !values.amSignedNDA ? "Please make sure to sign NDA" : null
        }
      />
    </div>
  );

  const finalList = list(withNda).filter(Boolean);

  return (
    <BasicForm
      wrapperClassname="add-member-form"
      fieldList={finalList}
      handleCancel={handleClose}
      handleSubmit={handleSubmit}
      validationSchema={addInvestorSchema}
      buttonStates={buttonStates}
      CustomFooter={CustomFooter}
    />
  );
};

export default FormWrapper;
