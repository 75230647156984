import { baseSelector } from "./utils";

const selectContractsDataFunc = state => {
  const { contracts = [], invConf, transConf, dealConf, invSummary } =
    state._data || {};

  return { contracts, invConf, transConf, dealConf, invSummary };
};

const selectContractsData = baseSelector(selectContractsDataFunc);

export { selectContractsData };
