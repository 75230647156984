export default {
  FETCH_ALL_COMMITS: "FETCH_ALL_COMMITS",
  FETCH_ENTITY_CAPTABLE: "FETCH_ENTITY_CAPTABLE",
  PATCH_COMMITMENT: "PATCH_COMMITMENT",
  UPDATE_COMMIT_DATA_STATUS: "UPDATE_COMMIT_DATA_STATUS",
  POST_COMMITMENT: "POST_COMMITMENT",
  GET_PARTNERSHIP: "GET_PARTNERSHIP",
  UPDATE_COMMIT_STATUS: "UPDATE_COMMIT_STATUS",
  UPDATE_IO: "UPDATE_IO",
  DELETE_COMMIT: "DELETE_COMMIT"
};
