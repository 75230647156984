const Voting = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9214 14.7208C9.79198 14.6803 9.69654 14.5867 9.65407 14.4777L8.2869 11.4345C8.18756 11.212 8.29513 10.9427 8.52163 10.8381C8.74813 10.7335 9.01645 10.8334 9.11579 11.0559L10.2938 13.6731L17.2312 10.1182C17.4626 9.99919 17.7358 10.0847 17.8352 10.3071C17.9489 10.5341 17.8557 10.8079 17.6292 10.9125L10.2675 14.6856C10.1662 14.7496 10.0365 14.7568 9.9214 14.7208Z"
      fill="#18DC9B"
    />
    <path
      d="M15.0448 19.7505C13.4426 18.6599 11.0964 19.0418 9.94195 20.6602L3.32528 16.4027C2.41422 15.8165 2.16395 14.6289 2.76097 13.7248L9.79252 3.07676C10.4104 2.14112 11.6965 1.86716 12.6422 2.46973L20.7959 7.66467C21.7181 8.25228 21.9688 9.45372 21.3585 10.3613L15.0448 19.7505Z"
      stroke="#597EF7"
      stroke-width="2"
    />
  </svg>
);

export default Voting;
