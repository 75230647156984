import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import currency from "./currency/reducers";
import io from "./io/reducers";
import users from "./users/reducers";
import news from "./news/reducers";
import invites from "./invites/reducers";
import snackbar from "./snackbar/reducers";
import watchlist from "./watchlist/reducers";
import commits from "./commitment/reducers";
import userApplication from "./userApplication/reducers";
import activity from "./activity/reducers";
import entities from "./entities/reducers";
import fundraiser from "./fundraiser/reducers";
import iopermissions from "./iopermissions/reducer";
import contracts from "./contracts/reducers";
import auth from "./auth/reducers";
import ioMemberships from "./iomemberships/reducers";
import notifications from "./notifications/reducers";
import shares from "./shares/reducers";
import convertible from "./convertible/reducers";
import nda from "./nda/reducers";
import form from "./form/reducers";
import voting from "./voting/reducers";
import syndicate from "./syndicates/reducers";
import taxes from "./taxes/reducers";

const appReducer = combineReducers({
  users,
  news,
  snackbar,
  io,
  watchlist,
  invites,
  currency,
  commits,
  routing: routerReducer,
  userApplication,
  activity,
  entities,
  fundraiser,
  iopermissions,
  contracts,
  auth,
  ioMemberships,
  notifications,
  shares,
  convertible,
  nda,
  form,
  voting,
  syndicate,
  taxes
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
    localStorage.clear();
  } else if (action.type === "CLEAR_STATE") {
    state = {
      ...state,
      [action.givenState]: {
        ...state[action.givenState],
        isCreating: false,
        didCreate: false,

        isFetching: false,
        didFetch: false,

        isUpdating: false,
        didUpdate: false,

        isDeleting: false,
        didDelete: false,
        error: ""
      }
    };
  } else if (action.type === "CLEAR_GLOBAL_STATE") {
    state = undefined;
  } else if (action.type === "CLEAR_STATE_DATA_BY_KEY") {
    state = {
      ...state,
      [action.givenState]: {
        ...state[action.givenStaten],
        _data: {
          ...(state[action.givenState]._data || []),
          ...action.stateKeys.reduce((acc, key) => {
            acc[key] = undefined;
            return acc;
          }, {})
        }
      }
    };
  } else if (action.type === "CLEAR_STATE_DATA") {
    state = {
      ...state,
      [action.givenState]: {
        ...state[action.givenState],
        _data: undefined
      }
    };
  }
  return appReducer(state, action);
};
export default rootReducer;
