import { baseSelector } from "./utils";

const selectMembershipDataFunc = state => {
  const data = (state._data && state._data.memberships) || [];

  return data;
};

const selectMembershipData = baseSelector(selectMembershipDataFunc);

export { selectMembershipData };
