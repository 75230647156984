export const countries = [
  { id: "AFG", country_code: "AF", country_name: "Afghanistan" },
  { id: "ALB", country_code: "AL", country_name: "Albania" },
  { id: "DZA", country_code: "DZ", country_name: "Algeria" },
  { id: "ASM", country_code: "AS", country_name: "American Samoa" },
  { id: "ANR", country_code: "L5", country_name: "Andean Region" },
  { id: "AND", country_code: "AD", country_name: "Andorra" },
  { id: "AGO", country_code: "AO", country_name: "Angola" },
  { id: "ATG", country_code: "AG", country_name: "Antigua and Barbuda" },
  { id: "ARG", country_code: "AR", country_name: "Argentina" },
  { id: "ARM", country_code: "AM", country_name: "Armenia" },
  { id: "ABW", country_code: "AW", country_name: "Aruba" },
  { id: "AUS", country_code: "AU", country_name: "Australia" },
  { id: "AUT", country_code: "AT", country_name: "Austria" },
  { id: "AZE", country_code: "AZ", country_name: "Azerbaijan" },
  { id: "BHS", country_code: "BS", country_name: "Bahamas, The" },
  { id: "BHR", country_code: "BH", country_name: "Bahrain" },
  { id: "BGD", country_code: "BD", country_name: "Bangladesh" },
  { id: "BRB", country_code: "BB", country_name: "Barbados" },
  { id: "BLR", country_code: "BY", country_name: "Belarus" },
  { id: "BEL", country_code: "BE", country_name: "Belgium" },
  { id: "BLZ", country_code: "BZ", country_name: "Belize" },
  { id: "BEN", country_code: "BJ", country_name: "Benin" },
  { id: "BMU", country_code: "BM", country_name: "Bermuda" },
  { id: "BTN", country_code: "BT", country_name: "Bhutan" },
  { id: "BOL", country_code: "BO", country_name: "Bolivia" },
  {
    id: "BIH",
    country_code: "BA",
    country_name: "Bosnia and Herzegovina"
  },
  { id: "BWA", country_code: "BW", country_name: "Botswana" },
  { id: "BRA", country_code: "BR", country_name: "Brazil" },
  {
    id: "VGB",
    country_code: "VG",
    country_name: "British Virgin Islands"
  },
  { id: "BRN", country_code: "BN", country_name: "Brunei Darussalam" },
  { id: "BGR", country_code: "BG", country_name: "Bulgaria" },
  { id: "BFA", country_code: "BF", country_name: "Burkina Faso" },
  { id: "BDI", country_code: "BI", country_name: "Burundi" },
  { id: "CPV", country_code: "CV", country_name: "Cabo Verde" },
  { id: "KHM", country_code: "KH", country_name: "Cambodia" },
  { id: "CMR", country_code: "CM", country_name: "Cameroon" },
  { id: "CAN", country_code: "CA", country_name: "Canada" },
  { id: "CYM", country_code: "KY", country_name: "Cayman Islands" },
  {
    id: "CAF",
    country_code: "CF",
    country_name: "Central African Republic"
  },
  { id: "TCD", country_code: "TD", country_name: "Chad" },
  { id: "CHI", country_code: "JG", country_name: "Channel Islands" },
  { id: "CHL", country_code: "CL", country_name: "Chile" },
  { id: "CHN", country_code: "CN", country_name: "China" },
  { id: "COL", country_code: "CO", country_name: "Colombia" },
  { id: "COM", country_code: "KM", country_name: "Comoros" },
  { id: "COD", country_code: "CD", country_name: "Congo, Dem. Rep." },
  { id: "COG", country_code: "CG", country_name: "Congo, Rep." },
  { id: "CRI", country_code: "CR", country_name: "Costa Rica" },
  { id: "CIV", country_code: "CI", country_name: "Cote d'Ivoire" },
  { id: "HRV", country_code: "HR", country_name: "Croatia" },
  { id: "CUB", country_code: "CU", country_name: "Cuba" },
  { id: "CUW", country_code: "CW", country_name: "Curacao" },
  { id: "CYP", country_code: "CY", country_name: "Cyprus" },
  { id: "CZE", country_code: "CZ", country_name: "Czech Republic" },
  { id: "DNK", country_code: "DK", country_name: "Denmark" },
  { id: "DJI", country_code: "DJ", country_name: "Djibouti" },
  { id: "DMA", country_code: "DM", country_name: "Dominica" },
  { id: "DOM", country_code: "DO", country_name: "Dominican Republic" },
  { id: "ECU", country_code: "EC", country_name: "Ecuador" },
  { id: "EGY", country_code: "EG", country_name: "Egypt, Arab Rep." },
  { id: "SLV", country_code: "SV", country_name: "El Salvador" },
  { id: "GNQ", country_code: "GQ", country_name: "Equatorial Guinea" },
  { id: "ERI", country_code: "ER", country_name: "Eritrea" },
  { id: "EST", country_code: "EE", country_name: "Estonia" },
  { id: "SWZ", country_code: "SZ", country_name: "Eswatini" },
  { id: "ETH", country_code: "ET", country_name: "Ethiopia" },
  { id: "FRO", country_code: "FO", country_name: "Faroe Islands" },
  { id: "FJI", country_code: "FJ", country_name: "Fiji" },
  { id: "FIN", country_code: "FI", country_name: "Finland" },
  { id: "FRA", country_code: "FR", country_name: "France" },
  { id: "PYF", country_code: "PF", country_name: "French Polynesia" },
  { id: "GAB", country_code: "GA", country_name: "Gabon" },
  { id: "GMB", country_code: "GM", country_name: "Gambia, The" },
  { id: "GEO", country_code: "GE", country_name: "Georgia" },
  { id: "DEU", country_code: "DE", country_name: "Germany" },
  { id: "GHA", country_code: "GH", country_name: "Ghana" },
  { id: "GIB", country_code: "GI", country_name: "Gibraltar" },
  { id: "GRC", country_code: "GR", country_name: "Greece" },
  { id: "GRL", country_code: "GL", country_name: "Greenland" },
  { id: "GRD", country_code: "GD", country_name: "Grenada" },
  { id: "GUM", country_code: "GU", country_name: "Guam" },
  { id: "GTM", country_code: "GT", country_name: "Guatemala" },
  { id: "GIN", country_code: "GN", country_name: "Guinea" },
  { id: "GNB", country_code: "GW", country_name: "Guinea-Bissau" },
  { id: "GUY", country_code: "GY", country_name: "Guyana" },
  { id: "HTI", country_code: "HT", country_name: "Haiti" },
  { id: "HND", country_code: "HN", country_name: "Honduras" },
  { id: "HKG", country_code: "HK", country_name: "Hong Kong SAR, China" },
  { id: "HUN", country_code: "HU", country_name: "Hungary" },
  { id: "ISL", country_code: "IS", country_name: "Iceland" },
  { id: "IND", country_code: "IN", country_name: "India" },
  { id: "IDN", country_code: "ID", country_name: "Indonesia" },
  { id: "IRN", country_code: "IR", country_name: "Iran, Islamic Rep." },
  { id: "IRQ", country_code: "IQ", country_name: "Iraq" },
  { id: "IRL", country_code: "IE", country_name: "Ireland" },
  { id: "IMN", country_code: "IM", country_name: "Isle of Man" },
  { id: "ISR", country_code: "IL", country_name: "Israel" },
  { id: "ITA", country_code: "IT", country_name: "Italy" },
  { id: "JAM", country_code: "JM", country_name: "Jamaica" },
  { id: "JPN", country_code: "JP", country_name: "Japan" },
  { id: "JOR", country_code: "JO", country_name: "Jordan" },
  { id: "KAZ", country_code: "KZ", country_name: "Kazakhstan" },
  { id: "KEN", country_code: "KE", country_name: "Kenya" },
  { id: "KIR", country_code: "KI", country_name: "Kiribati" },
  {
    id: "PRK",
    country_code: "KP",
    country_name: "Korea, Dem. People’s Rep."
  },
  { id: "KOR", country_code: "KR", country_name: "Korea, Rep." },
  { id: "XKX", country_code: "XK", country_name: "Kosovo" },
  { id: "KWT", country_code: "KW", country_name: "Kuwait" },
  { id: "KGZ", country_code: "KG", country_name: "Kyrgyz Republic" },
  { id: "LAO", country_code: "LA", country_name: "Lao PDR" },
  { id: "LVA", country_code: "LV", country_name: "Latvia" },
  { id: "LBN", country_code: "LB", country_name: "Lebanon" },
  { id: "LSO", country_code: "LS", country_name: "Lesotho" },
  { id: "LBR", country_code: "LR", country_name: "Liberia" },
  { id: "LBY", country_code: "LY", country_name: "Libya" },
  { id: "LIE", country_code: "LI", country_name: "Liechtenstein" },
  { id: "LTU", country_code: "LT", country_name: "Lithuania" },
  { id: "LUX", country_code: "LU", country_name: "Luxembourg" },
  { id: "MAC", country_code: "MO", country_name: "Macao SAR, China" },
  { id: "MDG", country_code: "MG", country_name: "Madagascar" },
  { id: "MWI", country_code: "MW", country_name: "Malawi" },
  { id: "MYS", country_code: "MY", country_name: "Malaysia" },
  { id: "MDV", country_code: "MV", country_name: "Maldives" },
  { id: "MLI", country_code: "ML", country_name: "Mali" },
  { id: "MLT", country_code: "MT", country_name: "Malta" },
  { id: "MHL", country_code: "MH", country_name: "Marshall Islands" },
  { id: "MRT", country_code: "MR", country_name: "Mauritania" },
  { id: "MUS", country_code: "MU", country_name: "Mauritius" },
  { id: "MEX", country_code: "MX", country_name: "Mexico" },
  {
    id: "FSM",
    country_code: "FM",
    country_name: "Micronesia, Fed. Sts."
  },
  { id: "MDA", country_code: "MD", country_name: "Moldova" },
  { id: "MCO", country_code: "MC", country_name: "Monaco" },
  { id: "MNG", country_code: "MN", country_name: "Mongolia" },
  { id: "MNE", country_code: "ME", country_name: "Montenegro" },
  { id: "MAR", country_code: "MA", country_name: "Morocco" },
  { id: "MOZ", country_code: "MZ", country_name: "Mozambique" },
  { id: "MMR", country_code: "MM", country_name: "Myanmar" },
  { id: "NAM", country_code: "NA", country_name: "Namibia" },
  { id: "NRU", country_code: "NR", country_name: "Nauru" },
  { id: "NPL", country_code: "NP", country_name: "Nepal" },
  { id: "NLD", country_code: "NL", country_name: "Netherlands" },
  { id: "NCL", country_code: "NC", country_name: "New Caledonia" },
  { id: "NZL", country_code: "NZ", country_name: "New Zealand" },
  { id: "NIC", country_code: "NI", country_name: "Nicaragua" },
  { id: "NER", country_code: "NE", country_name: "Niger" },
  { id: "NGA", country_code: "NG", country_name: "Nigeria" },
  { id: "MKD", country_code: "MK", country_name: "North Macedonia" },
  {
    id: "MNP",
    country_code: "MP",
    country_name: "Northern Mariana Islands"
  },
  { id: "NOR", country_code: "NO", country_name: "Norway" },
  { id: "OMN", country_code: "OM", country_name: "Oman" },
  { id: "PAK", country_code: "PK", country_name: "Pakistan" },
  { id: "PLW", country_code: "PW", country_name: "Palau" },
  { id: "PAN", country_code: "PA", country_name: "Panama" },
  { id: "PNG", country_code: "PG", country_name: "Papua New Guinea" },
  { id: "PRY", country_code: "PY", country_name: "Paraguay" },
  { id: "PER", country_code: "PE", country_name: "Peru" },
  { id: "PHL", country_code: "PH", country_name: "Philippines" },
  { id: "POL", country_code: "PL", country_name: "Poland" },
  { id: "PRT", country_code: "PT", country_name: "Portugal" },
  { id: "PRI", country_code: "PR", country_name: "Puerto Rico" },
  { id: "QAT", country_code: "QA", country_name: "Qatar" },
  { id: "ROU", country_code: "RO", country_name: "Romania" },
  { id: "RUS", country_code: "RU", country_name: "Russian Federation" },
  { id: "RWA", country_code: "RW", country_name: "Rwanda" },
  { id: "WSM", country_code: "WS", country_name: "Samoa" },
  { id: "SMR", country_code: "SM", country_name: "San Marino" },
  {
    id: "STP",
    country_code: "ST",
    country_name: "Sao Tome and Principe"
  },
  { id: "SAU", country_code: "SA", country_name: "Saudi Arabia" },
  { id: "SEN", country_code: "SN", country_name: "Senegal" },
  { id: "SRB", country_code: "RS", country_name: "Serbia" },
  { id: "SYC", country_code: "SC", country_name: "Seychelles" },
  { id: "SLE", country_code: "SL", country_name: "Sierra Leone" },
  { id: "SGP", country_code: "SG", country_name: "Singapore" },
  {
    id: "SXM",
    country_code: "SX",
    country_name: "Sint Maarten (Dutch part)"
  },
  { id: "SVK", country_code: "SK", country_name: "Slovak Republic" },
  { id: "SVN", country_code: "SI", country_name: "Slovenia" },
  { id: "SLB", country_code: "SB", country_name: "Solomon Islands" },
  { id: "SOM", country_code: "SO", country_name: "Somalia" },
  { id: "SAS", country_code: "8S", country_name: "South Asia" },
  { id: "SSD", country_code: "SS", country_name: "South Sudan" },
  { id: "ESP", country_code: "ES", country_name: "Spain" },
  { id: "LKA", country_code: "LK", country_name: "Sri Lanka" },
  { id: "KNA", country_code: "KN", country_name: "St. Kitts and Nevis" },
  { id: "LCA", country_code: "LC", country_name: "St. Lucia" },
  {
    id: "MAF",
    country_code: "MF",
    country_name: "St. Martin (French part)"
  },
  {
    id: "VCT",
    country_code: "VC",
    country_name: "St. Vincent and the Grenadines"
  },
  { id: "SDN", country_code: "SD", country_name: "Sudan" },
  { id: "SUR", country_code: "SR", country_name: "Suriname" },
  { id: "SWE", country_code: "SE", country_name: "Sweden" },
  { id: "CHE", country_code: "CH", country_name: "Switzerland" },
  { id: "SYR", country_code: "SY", country_name: "Syrian Arab Republic" },
  { id: "TWN", country_code: "TW", country_name: "Taiwan, China" },
  { id: "TJK", country_code: "TJ", country_name: "Tajikistan" },
  { id: "TZA", country_code: "TZ", country_name: "Tanzania" },
  { id: "THA", country_code: "TH", country_name: "Thailand" },
  { id: "TLS", country_code: "TL", country_name: "Timor-Leste" },
  { id: "TGO", country_code: "TG", country_name: "Togo" },
  { id: "TON", country_code: "TO", country_name: "Tonga" },
  { id: "TTO", country_code: "TT", country_name: "Trinidad and Tobago" },
  { id: "TUN", country_code: "TN", country_name: "Tunisia" },
  { id: "TUR", country_code: "TR", country_name: "Turkey" },
  { id: "TKM", country_code: "TM", country_name: "Turkmenistan" },
  {
    id: "TCA",
    country_code: "TC",
    country_name: "Turks and Caicos Islands"
  },
  { id: "TUV", country_code: "TV", country_name: "Tuvalu" },
  { id: "UGA", country_code: "UG", country_name: "Uganda" },
  { id: "UKR", country_code: "UA", country_name: "Ukraine" },
  { id: "ARE", country_code: "AE", country_name: "United Arab Emirates" },
  { id: "GBR", country_code: "GB", country_name: "United Kingdom" },
  { id: "USA", country_code: "US", country_name: "United States" },
  { id: "URY", country_code: "UY", country_name: "Uruguay" },
  { id: "UZB", country_code: "UZ", country_name: "Uzbekistan" },
  { id: "VUT", country_code: "VU", country_name: "Vanuatu" },
  { id: "VEN", country_code: "VE", country_name: "Venezuela, RB" },
  { id: "VNM", country_code: "VN", country_name: "Vietnam" },
  {
    id: "VIR",
    country_code: "VI",
    country_name: "Virgin Islands (U.S.)"
  },
  { id: "PSE", country_code: "PS", country_name: "West Bank and Gaza" },
  { id: "YEM", country_code: "YE", country_name: "Yemen, Rep." },
  { id: "ZMB", country_code: "ZM", country_name: "Zambia" },
  { id: "ZWE", country_code: "ZW", country_name: "Zimbabwe" }
];

export const MAJOR_COUNTRIES = {
  EU_SWISS: "EU / Switzerland",
  UK: "United Kingdom",
  US: "United States",
  AU: "Australia",
  CHN: "China",
  AF: "Africa",
  IN: "India",
  BR: "Brazil",
  JN: "Japan",
  ME: "Middle East"
};

export const majorCountries = Object.keys(MAJOR_COUNTRIES).map(countryCode => ({
  id: countryCode.toLowerCase(),
  country_code: countryCode,
  country_name: MAJOR_COUNTRIES[countryCode]
}));

export const countriesSorted = countries.map(
  ({ country_code: value, country_name: label }) => ({ value, label })
);

export const countriesSortedByName = countries.map(
  ({ country_name: label }) => label
);

export const majorCountriesSortedByName = majorCountries.map(
  ({ country_name: label }) => label
);
