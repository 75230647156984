import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { equityTs, shaKeys } from "#constants/io/equityPool";
import { closedValues } from "#constants/io/closed";
import { mapShareholderQuestionnaireValues } from "#helpers/mappers/equity";
import { handleUpload } from "#helpers/inputHandlers";
import { convertToBase64 } from "#helpers/convertBase64";
import Form from "./form/Presentational";

const ShareholderAgreementGenerator = ({
  clearState,
  fetchSingleIo,
  bulkPostAttachments,
  patchIo,
  getEntityMemberships,
  entityId,
  ...props
}) => {
  const { dealId } = useParams();
  const { io: { termSheet = {}, closed, subscriptionFinished } = {} } = props;

  const [attachments, setAttachments] = useState({});
  useEffect(() => {
    clearState("io");
    fetchSingleIo(dealId);
    getEntityMemberships({ id: entityId });
  }, []);

  const handleSubmit = async values => {
    const { articleOfAssociation } = attachments;

    let articleOfAssociationBase64;
    const attachmentsStack = [];

    if (articleOfAssociation) {
      articleOfAssociationBase64 = await convertToBase64(articleOfAssociation);

      attachmentsStack.push({
        name: "Article of association",
        category: "Shareholder",
        subCategory: "articleOfAssociation",
        base64: articleOfAssociationBase64
      });
    }

    patchIo({
      ioId: dealId,
      closed: closed < closedValues.SHA_DONE ? closedValues.SHA_DONE : closed,
      iaAndSha: mapShareholderQuestionnaireValues(
        values,
        termSheet[equityTs.LEAVER_SHARES],
        subscriptionFinished
      )
    });

    bulkPostAttachments({ ioId: dealId, attachments: attachmentsStack });
  };

  const defaultedFields = [
    { input: { name: [equityTs.AGREED_AMOUNT] } },
    { input: { name: [equityTs.SEVERAL_TRANCHES] } },
    {
      input: { name: [shaKeys.VESTED_SHARES_FMARKET_VAL] }
    },
    {
      input: { name: [shaKeys.VESTED_SHARES_NOMINAL_VAL] }
    }
  ];

  const defaultValues = {
    [equityTs.AGREED_AMOUNT]: termSheet[equityTs.AGREED_AMOUNT] || 0,
    [equityTs.SEVERAL_TRANCHES]: termSheet[equityTs.SEVERAL_TRANCHES],
    [shaKeys.VESTED_SHARES_FMARKET_VAL]: 25,
    [shaKeys.VESTED_SHARES_NOMINAL_VAL]: 75
  };

  return (
    <Form
      fieldList={defaultedFields}
      values={defaultValues}
      handleSubmit={handleSubmit}
      handleUpload={handleUpload(setAttachments, attachments)}
      reverseVesting={termSheet[equityTs.LEAVER_SHARES]}
      reverseVestingMonths={termSheet[equityTs.LEAVER_SHARES_DUR]}
      {...props}
    />
  );
};

ShareholderAgreementGenerator.propTypes = {
  clearState: PropTypes.func,
  fetchSingleIo: PropTypes.func,
  patchIo: PropTypes.func,
  bulkPostAttachments: PropTypes.func,
  getEntityMemberships: PropTypes.func,
  io: PropTypes.object,
  entityId: PropTypes.string
};

export default ShareholderAgreementGenerator;
