import Radio from '../Radio';

const RadioInputWrapper = ({
  customClassname = '',
  value,
  options,
  name,
  onChange
}) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const finalClassname = `radio-input-wrapper ${customClassname}`;

  return (
    <div className={finalClassname}>
      {options.map(
        ({ label, value: localValue, disabled, type, ...props }, index) => {
          return (
            <Radio
              key={`${label || ''}-${localValue}`}
              label={label}
              value={localValue}
              name={name}
              checked={value === localValue}
              onChange={onChange}
              disabled={disabled}
              indicator={type === 'button' ? alphabet[index] : null}
              type={type}
              {...props}
            />
          );
        }
      )}
    </div>
  );
};

export default RadioInputWrapper;
