import { Switch, List } from "antd";
import "antd/lib/list/style/css";
import PropTypes from "prop-types";
import ioTypes from "#constants/io/ioTypes";
import { Link } from "react-router-dom";

const ThirdStep = ({
  spaAgreementTerms,
  penaltyFeeTerms,
  partnerAgreement,
  handleSetState,
  syndicateName,
  entity = "",
  type,
  invCompl
}) => {
  const legalTermsLabel =
    type === ioTypes.POOLED_FUND
      ? "the terms of Simple Partnership Agreement and Subscription Agreement"
      : "the Terms of Syndicate";

  const termsAndConditions = [
    {
      checked: penaltyFeeTerms,
      key: "penaltyFeeTerms",
      description:
        "I agree to transfer the total amount within 7 calendar days as of today and that I pay a penalty of CHF 1’000, in the event of a delayed or uncompleted transfer."
    },
    {
      checked: spaAgreementTerms,
      key: "spaAgreementTerms",
      description: (
        <span>
          I have read and understood the{" "}
          <a
            href="/doc_viewer?doc=/docs/riskwarning.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Risk Warning
          </a>
          , all the investment documents provided by the {entity} and agree to{" "}
          {legalTermsLabel}, and confirm the{" "}
          <a
            href="/doc_viewer?doc=/docs/invCompl.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Investment Compliance Statements
          </a>
          .
        </span>
      )
    },
    {
      checked: partnerAgreement,
      key: "partnerAgreement",
      description: `I agree to be a partner of the syndicate ${syndicateName} and elect ${entity} as the service provider.`
    }
  ];

  return (
    <div className="confirmation">
      <div className="step-title">Review</div>
      <div className="terms-container">
        {termsAndConditions.map(item => (
          <div className="terms-conditions" key={item.description}>
            <div className="terms-switch">
              <Switch
                className="switch"
                checked={item.checked}
                onChange={handleSetState(item.key)}
              />
            </div>
            <div className="terms">{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

ThirdStep.propTypes = {
  spaAgreementTerms: PropTypes.bool,
  penaltyFeeTerms: PropTypes.bool,
  handleSetState: PropTypes.func,
  dealName: PropTypes.string,
  partnerAgreement: PropTypes.bool,
  entity: PropTypes.string
};

export default ThirdStep;
