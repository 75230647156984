import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { useState } from "react";
import { SingleDatePicker, isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";

const DateInputWrapper = props => {
  const [isFocused, setFocus] = useState(false);

  const handleFocus = () => setFocus(!isFocused);
  const { name, value, onChange } = props;

  const finalArgs = {
    ...props,
    date: value,
    onDateChange: onChange(name),
    focused: isFocused,
    onFocusChange: handleFocus,
    displayFormat: "DD-MM-YYYY",
    id: "date",
    isOutsideRange: day => !isInclusivelyBeforeDay(day, moment())
  };

  return <SingleDatePicker {...finalArgs} />;
};

export default DateInputWrapper;
