import * as Yup from "yup";
import { passwordRequirementsRegex } from "#helpers/general";
import { returnError } from "./common";

const schema = {
  new_password: Yup.string()
    .min(8, returnError("min_password"))
    .max(16, returnError("max_password"))
    .matches(
      passwordRequirementsRegex,
      "Password must meet the above requirements"
    )
    .required(returnError("required")),
  new_password_confirm: Yup.string()
    .oneOf([Yup.ref("new_password")], returnError("password_match"))
    .required(returnError("password_confirm"))
};
export default Yup.object().shape(schema);
