const ImgIcon = () => {
  return (
    <svg
      width="346"
      height="447"
      viewBox="0 0 346 447"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 447H322C327.523 447 332 442.523 332 437V80.3263C332 77.5658 330.859 74.928 328.847 73.0379L268.886 16.7115C267.032 14.9697 264.584 14 262.04 14H10C4.47715 14 0 18.4772 0 24V437C0 442.523 4.47716 447 10 447Z"
        fill="#C69B2D"
      />
      <path
        d="M269 88H322.052C330.933 88 335.407 77.2854 329.163 70.9694L276.111 17.308C269.828 10.9529 259 15.402 259 24.3386V78C259 83.5229 263.477 88 269 88Z"
        fill="#7A5C0E"
      />
      <path d="M47.2695 268H31.8887V204.016H47.2695V268Z" fill="white" />
      <path
        d="M78.251 204.016L92.5332 248.532L106.771 204.016H127.074V268H111.605V253.059L113.1 222.473L97.6309 268H87.4355L71.9229 222.429L73.417 253.059V268H57.9922V204.016H78.251Z"
        fill="white"
      />
      <path
        d="M174.052 256.047H152.914L149.223 268H132.743L156.21 204.016H170.712L194.354 268H177.787L174.052 256.047ZM156.605 244.138H170.36L163.461 221.945L156.605 244.138Z"
        fill="white"
      />
      <path
        d="M250.473 260.09C248.1 262.727 244.628 264.851 240.058 266.462C235.487 268.073 230.478 268.879 225.028 268.879C216.649 268.879 209.955 266.315 204.945 261.188C199.936 256.062 197.255 248.928 196.903 239.787L196.859 234.25C196.859 227.951 197.973 222.458 200.199 217.771C202.426 213.054 205.604 209.436 209.735 206.916C213.896 204.367 218.7 203.093 224.149 203.093C232.118 203.093 238.3 204.924 242.694 208.586C247.118 212.219 249.696 217.653 250.429 224.89H235.575C235.048 221.315 233.905 218.767 232.147 217.243C230.39 215.72 227.899 214.958 224.677 214.958C220.81 214.958 217.821 216.599 215.712 219.88C213.603 223.161 212.533 227.849 212.504 233.942V237.81C212.504 244.196 213.588 249.001 215.756 252.224C217.953 255.417 221.396 257.014 226.083 257.014C230.097 257.014 233.085 256.12 235.048 254.333V244.401H224.325V233.811H250.473V260.09Z"
        fill="white"
      />
      <path
        d="M299.12 240.886H274.862V256.135H303.515V268H259.438V204.016H303.603V215.925H274.862V229.416H299.12V240.886Z"
        fill="white"
      />
    </svg>
  );
};

export default ImgIcon;
