import { connect } from "react-redux";
import { getNdaDoc } from "#core/modules/nda/actions";
import { clearState, clearStateDataByKey } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    _data: { nda, ndaGenerated } = {},
    isFetching: ndaIsFetching,
    didFetch: ndaDidFetch
  } = state.nda;

  return {
    ndaColl: {
      contract: nda,
      loading: ndaIsFetching,
      success: ndaGenerated
    }
  };
};

const mapDispatchToProps = dispatch => ({
  getNdaDoc: params => dispatch(getNdaDoc(params)),
  clearStateDataByKey: (state, ...keys) =>
    clearStateDataByKey(dispatch, state, ...keys)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
