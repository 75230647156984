import QueueAnim from "rc-queue-anim";
import { closedValues } from "#constants/io/closed";
import { investmentOpportunityTypes } from "#constants/deal";
import { getPostDealClosureSchema } from "#validators/postDealClosureSchema";
import Modal from "../../Modal";
import { Button } from "../../Button";
import { formList } from "./list";
import { BasicForm } from "../../Form";
import { useEffect } from "react";

const PostDealClosureModalWrapper = ({
  visible,
  buttonStates,
  handleClose,
  actions,
  ioId,
  dealType,
  currency,
  sharesPrice = 0,
  sharesNumber = 0,
  ...props
}) => {
  useEffect(() => {
    actions.clearState("io");
  }, []);

  useEffect(() => {
    if (buttonStates.success) {
      handleClose();
    }
  }, [buttonStates.success]);

  const customHook = ({ setValues }) => {
    useEffect(() => {
      setValues({ sharesPrice, sharesNumber });
    }, []);
  };

  const handleSubmit = values => {
    const {
      sharesNumber,
      sharesPrice,
      totalOutstandingSharesAreDiluted,
      ...otherFields
    } = values;

    const payload = {
      ...otherFields
    };

    if (dealType === investmentOpportunityTypes.POOLED_EQUITY) {
      payload.totalOutstandingSharesAreDiluted =
        totalOutstandingSharesAreDiluted === "diluted";
    }

    actions.updateIoClosingStatus({
      cuid: ioId,
      closed: closedValues.APPROVED_BY_LAWYERS,
      ...payload
    });
  };

  const CustomFooter = ({ onClick, buttonStates }) => {
    return (
      <div className="btn-container">
        <Button
          label="Save"
          type="secondary"
          onClick={onClick}
          loading={buttonStates.loading}
          success={buttonStates.success}
          disabled={buttonStates.loading || buttonStates.success}
        />
      </div>
    );
  };

  const validationSchema = getPostDealClosureSchema(dealType);
  let title = "Equity";

  if (dealType === investmentOpportunityTypes.POOLED_FUND) {
    title = "Fund";
  } else if (dealType === investmentOpportunityTypes.POOLED_DEBT) {
    title = "CLA";
  }

  const formArgs = {
    fieldList: formList({ dealType, currency }),
    handleSubmit,
    CustomFooter,
    buttonStates,
    key: "1",
    useCustomHook: customHook,
    validationSchema
  };

  return (
    <Modal
      title={title + " deal"}
      visible={visible}
      onCancel={handleClose}
      footer={null}
      width={700}
      maskClosable
      className="post-deal-closure-wrapper"
      {...props}
    >
      <QueueAnim type={["right", "left"]} ease={["easeInOutQuart"]}>
        <BasicForm {...formArgs} />
      </QueueAnim>
    </Modal>
  );
};

export default PostDealClosureModalWrapper;
