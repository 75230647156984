import useWindowSize from "#helpers/hooks/windowSize";
import { Drawer, Menu } from "antd";
import { firstMenuItems, lastMenuItems, submenuItems } from "./items/menuItems";

export default function PhoneMenu({ setVisible, visible }) {
  const { width } = useWindowSize();
  const submenuDropdown = submenuItems();
  const firstMenu = firstMenuItems(width <= 940);
  const lastMenu = lastMenuItems(width <= 940);

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={() => setVisible(false)}
      visible={visible}
    >
      <Menu mode="inline">
        {firstMenu}
        {/* {submenuDropdown} */}
        {lastMenu}
      </Menu>
    </Drawer>
  );
}
