import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import queryStrings from "query-string";
import {
  mapStakeholdersValues,
  mapEntityShareholdersEdit
} from "#helpers/mappers/shares";
import { handleUpload } from "#helpers/inputHandlers";
import Form from "./components/form/Presentational";
import BasicWrapper from "../Shared/BasicWrapper";
import { withPageTitle } from "../Shared/hocs";
import { zefixHandlers } from "#helpers/zefix";

const StakeholdersPresentational = ({
  postShareholders,
  createEntityMembership,
  clearState,
  entityId,
  convertibles,
  shares,
  location = {},
  updateShareholderById,
  buttonStates,
  getEntityCapTable,
  triggerSnackbar,
  didCreate,
  didUpdate,
  membershipDidCreate,
  getEntityMemberships,
  updateEntityMembership,
  membershipDidUpdate,
  entityShares,
  shareholders,
  ...props
}) => {
  const { redirect = "" } = queryStrings.parse(location.search);

  const [state, setState] = useState({});
  const history = useHistory();
  useEffect(() => {
    clearState("shares");
    clearState("entities");
    getEntityCapTable({ entity_id: entityId });
    getEntityMemberships({ id: entityId });
  }, []);

  useEffect(() => {
    if (buttonStates.success) {
      setTimeout(() => {
        if (redirect) {
          history.push(redirect);
        } else {
          history.goBack();
        }

        clearState("shares");
        clearState("entities");
      }, 3001);
    }
  }, [buttonStates.success]);

  useEffect(() => {
    if (didCreate) {
      triggerSnackbar({
        variant: "success",
        message: "Successfully added a new stakeholder",
        placement: "bottomLeft",
        open: true
      });
    }
    if (didUpdate) {
      triggerSnackbar({
        variant: "success",
        message: "Successfully updated a stakeholder",
        placement: "bottomLeft",
        open: true
      });
    }
    if (membershipDidCreate) {
      triggerSnackbar({
        variant: "success",
        message: "Successfully added a new entity membership",
        placement: "bottomLeft",
        open: true
      });
    }
    if (membershipDidUpdate) {
      triggerSnackbar({
        variant: "success",
        message: "Successfully updated a entity membership",
        placement: "bottomLeft",
        open: true
      });
    }
  }, [didCreate, didUpdate, membershipDidCreate, membershipDidUpdate]);

  const handleSubmit = role => async values => {
    if (location.state && location.state.data) {
      const isUpdating = true;
      const payload = await mapStakeholdersValues({
        values,
        role,
        state,
        shares,
        isUpdating
      });
      if (
        Array.isArray(values.convertibles) &&
        values.convertibles.length > 0
      ) {
        const { convertibles: lenderConvertibles = [] } = values || {};
        const closedConvertibles = lenderConvertibles
          .map(({ paymentDate = "" }) => paymentDate === "")
          .filter(Boolean);
        if (closedConvertibles.length > 0) {
          triggerSnackbar({
            variant: "error",
            message:
              "This shareholder contains convertibles that don't have a payment date.",
            placement: "bottomLeft",
            open: true
          });
          return;
        }
      }
      if (role !== "3" && location.state.data.shareholderId) {
        updateShareholderById(location.state.data.shareholderId, payload);
        role = "3";
        const membershipPayload = await mapStakeholdersValues({
          values,
          role,
          state,
          shares,
          isUpdating: true
        });
        updateEntityMembership(location.state.data.cuid, membershipPayload);
      } else if (role === "3" && location.state.data.shareholderId) {
        updateEntityMembership(location.state.data.cuid, payload);
        role = "2";
        values.isDirector = "Yes";
        const shareholderPayload = await mapStakeholdersValues({
          values,
          role,
          state,
          shares,
          isUpdating: true
        });
        updateShareholderById(
          location.state.data.shareholderId,
          shareholderPayload
        );
      } else if (
        role === "1" ||
        (role === "2" && !location.state.data.shareholderId)
      ) {
        const shareholderPayload = await mapStakeholdersValues({
          values,
          role,
          entityId,
          state,
          shares,
          isUpdating: false
        });
        postShareholders(shareholderPayload);
        const membershipPayload = await mapStakeholdersValues({
          values,
          role: "3",
          state,
          shares,
          isUpdating: true
        });
        membershipPayload.role = shareholderPayload.role;
        updateEntityMembership(location.state.data.cuid, membershipPayload);
      } else if (role === "3") {
        updateEntityMembership(location.state.data.cuid, payload);
      } else {
        updateShareholderById(location.state.data.shareholderId, payload);
      }
    } else {
      const isUpdating = false;
      const payload = await mapStakeholdersValues({
        values,
        role,
        entityId,
        state,
        shares,
        isUpdating
      });
      if (role === "1" || role === "2") {
        postShareholders(payload);
      } else if (role === "3") {
        createEntityMembership(payload);
      }
    }
  };

  let formValues;
  if (location.state) {
    formValues = mapEntityShareholdersEdit(location.state.data);
  }
  const zefix = zefixHandlers();
  const { data: { isOriginalFounder } = {} } = location.state || {};

  return (
    <BasicWrapper>
      <Form
        formValues={formValues}
        handleSubmit={handleSubmit}
        handleUpload={handleUpload(setState, state)}
        shares={shares}
        convertibles={convertibles}
        triggerSnackbar={triggerSnackbar}
        buttonStates={buttonStates}
        entityShares={entityShares}
        zefix={zefix}
        redirect={redirect}
        isOriginalFounder={isOriginalFounder}
        {...props}
      />
    </BasicWrapper>
  );
};

StakeholdersPresentational.propTypes = {
  postShareholders: PropTypes.func,
  getShareClasses: PropTypes.func,
  entityId: PropTypes.string,
  clearState: PropTypes.func,
  createEntityMembership: PropTypes.func,
  getConvertibleTypes: PropTypes.func,
  convertibles: PropTypes.array,
  shares: PropTypes.array,
  location: PropTypes.object,
  updateShareholderById: PropTypes.func,
  buttonStates: PropTypes.object,
  getEntityMemberships: PropTypes.func
};

export default withPageTitle("Stakeholder")(StakeholdersPresentational);
