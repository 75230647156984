import { Table } from '../../../../../Shared/CapTable';

const ShareTransfer = () => {
  const data = [
    {
      name: 'Jane Doe',
      date: '8 August 2019',
      amount: '100000',
      status: 'progress'
    },
    {
      name: 'Jane Doe',
      date: '8 August 2019',
      amount: '100000',
      status: 'failed'
    },
    {
      name: 'Jane Doe',
      date: '8 August 2019',
      amount: '100000',
      status: 'progress'
    },
    {
      name: 'Jane Doe',
      date: '8 August 2019',
      amount: '100000',
      status: 'success'
    },
    {
      name: 'Jane Doe',
      date: '8 August 2019',
      amount: '100000',
      status: 'success'
    },
    {
      name: 'Jane Doe',
      date: '8 August 2019',
      amount: '100000',
      status: 'progress'
    },
    {
      name: 'Jane Doe',
      date: '8 August 2019',
      amount: '100000',
      status: 'progress'
    },
    {
      name: 'Jane Doe',
      date: '8 August 2019',
      amount: '100000',
      status: 'success'
    }
  ];

  return (
    <div className="share-transfer-wrapper">
      <Table type="shares" data={data} />
    </div>
  );
};

export default ShareTransfer;
