import { connect } from "react-redux";
import { clearState, clearStateData } from "#core/utils/helper_actions";
import {
  updateFounderInvitation,
  getSingleFounderInvitation
} from "#core/modules/entities/actions";
import { getCompanyDetails } from "#core/modules/entities/actions";
import Presentational from "./Presentational";
import { readCurrencies } from "#core/modules/currency/actions/read";
import { syncUserDetails } from "#core/modules/users/actions/update";

const mapStateToProps = ({ entities, currency }) => {
  const {
    companyDetails,
    detailsFetched,
    detailsFetching,
    founderInvitation = {},
    userData
  } = entities._data || {};

  const currencies = (currency._data && currency._data.currencies) || [];

  return {
    buttonStates: {
      loading: entities.isCreating,
      success: entities.didCreate
    },
    states: {
      entitiesDidFetch: entities.didFetch,
      entitiesFetching: entities.isFetching,
      entitiesDidCreate: entities.didCreate,
      detailsFetching,
      detailsFetched,
      currenciesFetched: currency.didFetch
    },
    companyDetails,
    founderInvitation,
    currencies,
    userData
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    clearState: data => clearState(dispatch, data),
    updateFounderInvitation: data => dispatch(updateFounderInvitation(data)),
    getSingleFounderInvitation: data =>
      dispatch(getSingleFounderInvitation(data)),
    getCompanyDetails: data => dispatch(getCompanyDetails(data)),
    readCurrencies: () => dispatch(readCurrencies()),
    syncUserDetails: data => syncUserDetails(data),
    clearStateData: data => clearStateData(dispatch, data)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
