import BasicWrapper from "../Shared/BasicWrapper";
import Cookies from "../Shared/Cookies";
import { withBackNavigation } from "../Shared/hocs";

const CookiesSettings = () => {
  return (
    <BasicWrapper className="cookies-wrapper">
      <Cookies visible relative location="/cookies" type="update" />
    </BasicWrapper>
  );
};

export default withBackNavigation({ link: "/" })(CookiesSettings);
