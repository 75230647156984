import { Input as AntdInput } from "antd";
import Input from "../Input";
import CheckboxSelection from "../CheckboxSelection";
import { ListSelect, SelectButtons } from "../SelectInput/";
import RadioInput from "../RadioInput";
import UploadRenderer from "../Uploader";
import AutocompleteWrapper from "../Autocomplete";
import Switch from "../Switch";
import ListForm from "../ListForm";
import DatePicker from "../DatePicker";

const InputRenderer = ({ type, options, ...props }) => {
  if (type === "select") {
    return <ListSelect size="large" type={type} list={options} {...props} />;
  }
  if (type === "select_btn") {
    return <SelectButtons type={type} list={options} {...props} />;
  }
  if (type === "checkbox") {
    return <CheckboxSelection type={type} options={options} {...props} />;
  }
  if (type === "radio") {
    return <RadioInput type={type} options={options} {...props} />;
  }
  if (type === "drag_upload" || type === "upload") {
    return <UploadRenderer type={type} {...props} />;
  }
  if (type === "autocomplete") {
    return <AutocompleteWrapper type={type} {...props} />;
  }
  if (type === "switch") {
    return <Switch type={type} {...props} />;
  }
  if (type === "table_list") {
    return <ListForm type={type} {...props} />;
  }
  if (type === "date") {
    return <DatePicker {...props} />;
  }
  if (type === "password") {
    return (
      <AntdInput.Password
        size="large"
        className="leva-input-wrapper"
        {...props}
      />
    );
  }
  return <Input type={type} {...props} />;
};

export default InputRenderer;
