import { connect } from "react-redux";
import { clearState } from "#core/utils/helper_actions";
import { postAttachment, bulkPostAttachments } from "#core/modules/io/actions";
import { updateAttachment } from "#core/modules/io/actions/update";
import { deleteIoAttachment } from "#core/modules/io/actions/delete";

import Presentational from "./Presentational";

const mapStateToProps = state => {
  return {
    attachLoading:
      state.io.isCreating || state.io.isUpdating || state.io.isDeleting,
    attachSuccess: state.io.didCreate || state.io.didUpdate
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  postAttachment: data => dispatch(postAttachment(data)),
  bulkPostAttachments: data => dispatch(bulkPostAttachments(data)),
  updateAttachment: data => dispatch(updateAttachment(data)),
  deleteIoAttachment: data => dispatch(deleteIoAttachment(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
