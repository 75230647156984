import { equityTs, shaKeys, iaKeys } from "#constants/io/equityPool";

export const mapEquityValues = values => {
  let calculatedSharePrice;
  const esopShares =
    values[equityTs.ESOP_SHARES_NUMBER] > 0
      ? values[equityTs.ESOP_SHARES_NUMBER]
      : values[equityTs.ALLOCATED_SHARES_ESOP];

  if (values[equityTs.FULLY_DILUTED] === "yes") {
    calculatedSharePrice =
      values[equityTs.PRE_VALUE] / (esopShares + values.sharesNumber);
  } else {
    calculatedSharePrice = values[equityTs.PRE_VALUE] / values.sharesNumber;
  }

  const convertibles = Array.isArray(values.formatedConvertibles)
    ? values.formatedConvertibles
        .filter(({ select }) => select)
        .map(({ fullyDilutedConvertible = false, cuid }) => ({
          convertibleId: cuid,
          fullyDiluted: fullyDilutedConvertible
        }))
    : [];

  const directors = Array.isArray(values.signingDirector)
    ? values.signingDirector
        .filter(({ signingDirector }) => signingDirector)
        .map(({ membershipId }) => ({ membershipId }))
    : [];

  const nominatesBoardObserver =
    Array.isArray(values.boardObeserverSelectors) &&
    values.boardObeserverSelectors.length > 0
      ? values.boardObeserverSelectors.map(({ cuid }) => ({
          membershipId: cuid
        }))[0]
      : [];

  const nominatesSingleInvestor =
    Array.isArray(values.specificInvestors) &&
    values.specificInvestors.length > 0
      ? values.specificInvestors.map(({ cuid }) => ({ membershipId: cuid }))[0]
      : [];

  const conversionInvestors = Array.isArray(values.convertingInvestors)
    ? values.convertingInvestors
        .filter(({ selection }) => selection)
        .map(({ membershipId, totalAmount, sharesPrice }) => ({
          membershipId,
          amount: totalAmount,
          sharesPrice
        }))
    : [];

  let payload = {
    convertibles,
    directors,
    nominatesBoardObserver,
    nominatesSingleInvestor,
    [equityTs.SIGNED_BY]: values[equityTs.SIGNED_BY],
    [equityTs.PRE_VALUE]: values[equityTs.PRE_VALUE],
    [equityTs.SERIES_ROUND]: values[equityTs.SERIES_ROUND],
    [equityTs.AGREED_AMOUNT]: values[equityTs.AGREED_AMOUNT],
    [equityTs.SEVERAL_TRANCHES]: values[equityTs.SEVERAL_TRANCHES] === "yes",
    [equityTs.FULLY_DILUTED]: values[equityTs.FULLY_DILUTED] === "yes",
    [equityTs.CONTRACTUAL_PREFERRED]:
      values[equityTs.CONTRACTUAL_PREFERRED] ===
      "common_with_preferential_rights",
    //not totally sure if its alright ^^^^^^

    // [equityTs.CONTRACTUAL_PREFERRED]:
    //   values[equityTs.CONTRACTUAL_PREFERRED] === "preferred",
    //  &&
    // !values[equityTs.NO_PREFERENCE_RIGHTS],
    [equityTs.LIQUIDATION_PREFERENTIAL]: !!values[
      equityTs.LIQUIDATION_PREFERENTIAL
    ],
    [equityTs.COMMON_SHARE_NOMINAL]:
      values[equityTs.COMMON_SHARE_NOMINAL] === "Other"
        ? +values[equityTs.COMMON_SHARE_NOMINAL_OTHER]
        : 1,
    [equityTs.AOA_RESTRICT]: values[equityTs.AOA_RESTRICT] === "yes",
    [equityTs.ESOP_PERCENT_CALCULATION]:
      values[equityTs.ESOP_PERCENT_CALCULATION],
    [equityTs.DIVIDEND_PREFERENTIAL]: !!values[equityTs.DIVIDEND_PREFERENTIAL],
    [equityTs.ANTI_DILUTION]: !!values[equityTs.ANTI_DILUTION],
    [equityTs.VOTING_RIGHTS]: values[equityTs.VOTING_RIGHTS],
    [equityTs.DIRECTORS_NUMBER]: values[equityTs.DIRECTORS_NUMBER],
    [equityTs.INVESTOR_DIRECTOR]: values[equityTs.INVESTOR_DIRECTOR],
    [equityTs.INDEPENDENT_DIRECTORS_NUM]:
      values[equityTs.INDEPENDENT_DIRECTORS_NUM] === "Other"
        ? values[equityTs.INDEPENDENT_DIRECTORS_NUM_OTHER]
        : values[equityTs.INDEPENDENT_DIRECTORS_NUM],
    [equityTs.INVESTOR_DIRECTOR_PERCENTAGE]:
      values[equityTs.INVESTOR_DIRECTOR_PERCENTAGE],
    [equityTs.BOARD_OBSERVER]: values[equityTs.BOARD_OBSERVER] === "yes",
    [equityTs.BOARD_MEMBERS_ACQUIRE_PERCENTAGE]:
      values[equityTs.BOARD_MEMBERS_ACQUIRE_PERCENTAGE],
    [equityTs.BOARD_MATTERS_EXEEDING_AMOUNT]:
      values[equityTs.BOARD_MATTERS_EXEEDING_AMOUNT],
    [equityTs.BOARD_MATTERS_EXEEDING_OBLIGATION]:
      values[equityTs.BOARD_MATTERS_EXEEDING_OBLIGATION],
    [equityTs.BOARD_MATTERS_EXEEDING_SEC_INT]:
      values[equityTs.BOARD_MATTERS_EXEEDING_SEC_INT],
    [equityTs.QUALIFIED_MAJORITIES_SH_MATTERS]:
      values[equityTs.QUALIFIED_MAJORITIES_SH_MATTERS],
    [equityTs.INFO_INVESTOR]: values[equityTs.INFO_INVESTOR],
    [equityTs.DRAG_ALONG_PERCENTAGE]: values[equityTs.DRAG_ALONG_PERCENTAGE],
    [equityTs.SOCIAL_SECURITY]: values[equityTs.SOCIAL_SECURITY] === "yes",
    [equityTs.GOVERNING_LAW]: values[equityTs.GOVERNING_LAW],
    [equityTs.ISSUE_PRICE]: Number(calculatedSharePrice.toFixed(2)) || 0,
    [equityTs.BOARD_OBSERVER_PERCENTAGE]:
      values[equityTs.BOARD_OBSERVER_PERCENTAGE],
    [equityTs.FOUNDER_DIRECTOR]: values[equityTs.FOUNDER_DIRECTOR],
    [equityTs.INFO_RIGHTS]: values[equityTs.INFO_RIGHTS],
    [equityTs.ESOP]: values[equityTs.ESOP_ESTABLISHMENT]
      ? values[equityTs.ESOP_ESTABLISHMENT] === "yes"
      : values[equityTs.FULLY_DILUTED_ESOP] === "yes",
    conversionInvestors,
    [equityTs.LEAVER_SHARES]: values[equityTs.LEAVER_SHARES] === "yes",
    [equityTs.RIGHT_TO_NOMINATE_INVESTOR]:
      values[equityTs.RIGHT_TO_NOMINATE_INVESTOR],
    [equityTs.FULLY_DILUTED_ESOP]:
      values[equityTs.FULLY_DILUTED_ESOP] === "yes",
    [equityTs.CONVERTING_CLA]: conversionInvestors.length > 0,
    [equityTs.FULLY_DILUTED_CONVERTIBLE]:
      values[equityTs.FULLY_DILUTED_CONVERTIBLE] === "yes",
    [equityTs.MAJORITY_CONSENT]: values[equityTs.MAJORITY_CONSENT],
    [equityTs.INVESTORS_CONSENT]: values[equityTs.INVESTORS_CONSENT]
  };

  if (payload[equityTs.LEAVER_SHARES]) {
    payload = {
      ...payload,
      [equityTs.LEAVER_SHARES_VAL]: values[equityTs.LEAVER_SHARES_VAL],
      [equityTs.LEAVER_SHARES_DUR]: Number(values[equityTs.LEAVER_SHARES_DUR]),
      [equityTs.LEAVER_SHARES_REDUCTION]:
        values[equityTs.LEAVER_SHARES_REDUCTION],
      [equityTs.LEAVER_SHARES_CLIFF_PERIOD]: Number(
        values[equityTs.LEAVER_SHARES_CLIFF_PERIOD]
      )
    };
  }

  if (values[equityTs.GOVERNING_LAW] === "other") {
    payload[equityTs.GOVERNING_LAW_OTHER] =
      values[equityTs.GOVERNING_LAW_OTHER];
  }
  if (values[equityTs.GOVERNING_LAW] === "zurich") {
    payload[equityTs.ZURICH_COURT] = values[equityTs.GOVERNING_LAW_OTHER];
  }

  if (values[equityTs.INVESTORS_CONSENT] === "other") {
    payload[equityTs.INVESTORS_CONSENT_OTHER] =
      values[equityTs.INVESTORS_CONSENT_OTHER];
  }
  if (values[equityTs.BOARD_OBSERVER] === "yes") {
    payload[equityTs.RIGHT_TO_NOMINATE_BOARD_OBSERVER] =
      values[equityTs.RIGHT_TO_NOMINATE_BOARD_OBSERVER];
  }
  if (
    +values[equityTs.INDEPENDENT_DIRECTORS_NUM] > 0 ||
    values[equityTs.INDEPENDENT_DIRECTORS_NUM] === "Other"
  ) {
    payload[equityTs.INDEPENDENT_DIRECTORS_APPOINTER] =
      values[equityTs.INDEPENDENT_DIRECTORS_APPOINTER];
    payload[equityTs.INDEPENDENT_DIRECOTRS_APPOINTMENT_PERCENT] =
      values[equityTs.INDEPENDENT_DIRECOTRS_APPOINTMENT_PERCENT];
  }
  if (values[equityTs.SEVERAL_TRANCHES] === "yes") {
    if (
      values[equityTs.MIN_CAPITAL_INCREASE] <= values[equityTs.AGREED_AMOUNT]
    ) {
      payload[equityTs.MIN_CAPITAL_INCREASE] =
        values[equityTs.MIN_CAPITAL_INCREASE];
    } else {
      payload[equityTs.MIN_CAPITAL_INCREASE] = values[equityTs.AGREED_AMOUNT];
    }
  }

  if (values[equityTs.FULLY_DILUTED] === "yes") {
    payload[equityTs.ALLOCATED_SHARES_ESOP] =
      values[equityTs.ALLOCATED_SHARES_ESOP];
  }

  if (values[equityTs.ANTI_DILUTION]) {
    payload[equityTs.ANTI_DILUTION_PROTECTION_FORMULA] =
      values[equityTs.ANTI_DILUTION_PROTECTION_FORMULA];
  }

  if (values[equityTs.DIVIDEND_PREFERENTIAL]) {
    payload[equityTs.DIVIDEND_PREFERENCE_PARTICIPATING] =
      values[equityTs.DIVIDEND_PREFERENCE_PARTICIPATING];
    payload[equityTs.DIVIDEND_PREFERENCE_CLAUSE] =
      values[equityTs.DIVIDEND_PREFERENCE_CLAUSE];
    payload[equityTs.DIVIDEND_PREFERENTIAL_CAP] =
      values[equityTs.DIVIDEND_PREFERENTIAL_CAP];
  }

  if (values[equityTs.LIQUIDATION_PREFERENTIAL]) {
    payload[equityTs.LIQUDIATION_PREFERENCE_PARTICIPATING] =
      values[equityTs.LIQUDIATION_PREFERENCE_PARTICIPATING];
    payload[equityTs.LIQUDIATION_PREFERENCE_CLAUSE] =
      values[equityTs.LIQUDIATION_PREFERENCE_CLAUSE];
    payload[equityTs.LIQUIDATION_PREFERENTIAL_CAP] =
      values[equityTs.LIQUIDATION_PREFERENTIAL_CAP];
  }

  if (values[equityTs.VOTING_RIGHTS] === "Other") {
    payload[equityTs.VOTING_RIGHTS] = values[equityTs.VOTING_RIGHTS_OTHER];
  }

  if (values[equityTs.BOARD_OBSERVER_PERCENTAGE] === "Other") {
    payload[equityTs.BOARD_OBSERVER_PERCENTAGE] = values[
      equityTs.BOARD_OBSERVER_PERCENTAGE_OTHER
    ].toString();
  }

  if (values[equityTs.DIRECTORS_NUMBER] === "Other") {
    payload[equityTs.DIRECTORS_NUMBER] = values[
      equityTs.DIRECTORS_NUMBER_OTHER
    ].toString();
  }

  if (values[equityTs.INVESTOR_DIRECTOR] === "Other") {
    payload[equityTs.INVESTOR_DIRECTOR] = values[
      equityTs.INVESTOR_DIRECTOR_OTHER
    ].toString();
  }

  if (values[equityTs.INVESTOR_DIRECTOR_PERCENTAGE] === "Other") {
    payload[equityTs.INVESTOR_DIRECTOR_PERCENTAGE] = values[
      equityTs.INVESTOR_DIRECTOR_PERCENTAGE_OTHER
    ].toString();
  }

  if (values[equityTs.BOARD_MEMBERS_ACQUIRE_PERCENTAGE] === "Other") {
    payload[equityTs.BOARD_MEMBERS_ACQUIRE_PERCENTAGE] = values[
      equityTs.BOARD_MEMBERS_ACQUIRE_PERCENTAGE_OTHER
    ].toString();
  }

  if (values[equityTs.BOARD_MATTERS_EXEEDING_AMOUNT] === "Other") {
    payload[equityTs.BOARD_MATTERS_EXEEDING_AMOUNT] = values[
      equityTs.BOARD_MATTERS_EXEEDING_AMOUNT_OTHER
    ].toString();
  }

  if (values[equityTs.BOARD_MATTERS_EXEEDING_OBLIGATION] === "Other") {
    payload[equityTs.BOARD_MATTERS_EXEEDING_OBLIGATION] = values[
      equityTs.BOARD_MATTERS_EXEEDING_OBLIGATION_OTHER
    ].toString();
  }

  if (values[equityTs.QUALIFIED_MAJORITIES_SH_MATTERS] === "Other") {
    payload[equityTs.QUALIFIED_MAJORITIES_SH_MATTERS] = values[
      equityTs.QUALIFIED_MAJORITIES_SH_MATTERS_OTHER
    ].toString();
  }

  if (values[equityTs.INFO_INVESTOR] === "Other") {
    payload[equityTs.INFO_INVESTOR] = values[
      equityTs.INFO_INVESTOR_OTHER
    ].toString();
  }

  if (values[equityTs.DRAG_ALONG_PERCENTAGE] === "Other") {
    payload[equityTs.DRAG_ALONG_PERCENTAGE] = values[
      equityTs.DRAG_ALONG_PERCENTAGE_OTHER
    ].toString();
  }

  if (values[equityTs.GOVERNING_LAW] === "Other") {
    // payload[equityTs.GOVERNING_LAW] = values[equityTs.GOVERNING_LAW_OTHER];
  }

  if (values[equityTs.FOUNDER_DIRECTOR] === "Other") {
    payload[equityTs.FOUNDER_DIRECTOR] = values[
      equityTs.FOUNDER_DIRECTOR_OTHER
    ].toString();
  }

  if (values[equityTs.LEAVER_SHARES_VAL] === "Other") {
    payload[equityTs.LEAVER_SHARES_VAL] =
      values[equityTs.LEAVER_SHARES_VAL_OTHER];
  }

  if (values[equityTs.LEAVER_SHARES_DUR] === "Other") {
    payload[equityTs.LEAVER_SHARES_DUR] =
      values[equityTs.LEAVER_SHARES_DUR_OTHER];
  }

  if (values[equityTs.LEAVER_SHARES_CLIFF_PERIOD] === "Other") {
    payload[equityTs.LEAVER_SHARES_CLIFF_PERIOD] =
      values[equityTs.LEAVER_SHARES_CLIFF_PERIOD_OTHER];
  }

  if (values[equityTs.LEAVER_SHARES_REDUCTION] === "Other") {
    payload[equityTs.LEAVER_SHARES_REDUCTION] =
      values[equityTs.LEAVER_SHARES_REDUCTION_OTHER];
  }

  if (values[equityTs.BOARD_MATTERS_EXEEDING_SEC_INT] === "Other") {
    payload[equityTs.BOARD_MATTERS_EXEEDING_SEC_INT] = String(
      values[equityTs.BOARD_MATTERS_EXEEDING_SEC_INT_VALUE]
    );
  }

  if (values.esopPercentCalculation === "yes") {
    payload[equityTs.INCLUDE_CLA_EFFECTS] =
      values[equityTs.INCLUDE_CLA_EFFECTS] === "yes";
    payload[equityTs.INCLUDE_ESOP_EFFECTS] =
      values[equityTs.INCLUDE_ESOP_EFFECTS] === "yes";
  }

  return payload;
};

export const getEquityFormStep = ({ values, list }) => {
  let step = null;

  // WE USE THIS FUNCTION TO DETERMINE WHICH STEP THE USER HAS LEFT OFF IN

  for (let i = 0; i < list.length; i++) {
    const { items } = list[i];

    for (let j = 0; j < items.length; j++) {
      const { input: { name } = {} } = items[j];

      if (!values[name]) {
        step = i;
        break;
      }
    }

    if (step !== null) {
      break;
    }
  }

  return step;
};

export const mapShareholderQuestionnaireValues = (
  values,
  reverseVesting,
  subscriptionCompleted
) => {
  const parsedAllParties = values[shaKeys.ALL_PARTIES]
    ? values[shaKeys.ALL_PARTIES].toString()
    : undefined;

  const payload = {
    [shaKeys.BOARD_QUORUM_HALF]: values[shaKeys.BOARD_QUORUM_HALF],
    [shaKeys.VOLUNTARY_CONVERSION_SHA]: subscriptionCompleted
      ? false
      : values[shaKeys.VOLUNTARY_CONVERSION_SHA] === "yes",
    // [shaKeys.IPO_CONVERSION]:
    //   subscriptionCompleted ? false : values[shaKeys.IPO_CONVERSION] === 'yes',
    [shaKeys.MAX_INVESTMENT_AMOUNT_IF_SEVERAL_TRANCHES]:
      values[shaKeys.MAX_INVESTMENT_AMOUNT_IF_SEVERAL_TRANCHES],
    [shaKeys.CHAMBER_COMMERCE_PLACE]: values[shaKeys.CHAMBER_COMMERCE_PLACE],
    [shaKeys.LIQUIDATED_DAMANGES_AMOUNT]:
      values[shaKeys.LIQUIDATED_DAMANGES_AMOUNT] === "Other"
        ? values[shaKeys.LIQUIDATED_DAMANGES_AMOUNT_OTHER]
        : +values[shaKeys.LIQUIDATED_DAMANGES_AMOUNT],
    [shaKeys.NON_COMPETITION_SOLICITATION_TERM]:
      values[shaKeys.NON_COMPETITION_SOLICITATION_TERM] ===
      ("Other" || "Zürich")
        ? values[shaKeys._OTHER]
        : values[shaKeys.NON_COMPETITION_SOLICITATION_TERM].toString(),
    [shaKeys.IA_CONFIDENTIALITY]: values[shaKeys.IA_CONFIDENTIALITY] === "yes",
    [shaKeys.IA_NOTICES]: values[shaKeys.IA_NOTICES] === "yes",
    [shaKeys.ALL_PARTIES]: parsedAllParties,
    [shaKeys.MAX_CAPITAL_INCREASE]: values[shaKeys.MAX_CAPITAL_INCREASE],
    [shaKeys.SUBS_RIGHTS_WAIVER]:
      values[shaKeys.SUBS_RIGHTS_WAIVER] === "Founder",
    [shaKeys.TRANSFER_CONSENT]: values[shaKeys.TRANSFER_CONSENT] === "yes",
    independentExpert: {
      firstName: values[shaKeys.INDEPENDENT_EXPERT_NAME],
      lastName: values[shaKeys.INDEPENDENT_EXPERT_LAST_NAME],
      street: values[shaKeys.INDEPENDENT_EXPERT_STREET],
      postalCode: values[shaKeys.INDEPENDENT_EXPERT_ZIP],
      place: values[shaKeys.INDEPENDENT_EXPERT_PLACE],
      country: values[shaKeys.INDEPENDENT_EXPERT_COUNTRY],
      email: values[shaKeys.INDEPENDENT_EXPERT_EMAIL],
      canton: values[shaKeys.INDEPENDENT_EXPERT_CANTON]
    },

    jurisdictionOfCourts: values[iaKeys.ORDINARY_COURT]
  };
  if (subscriptionCompleted) {
    payload.newCompanyAuditor = {
      firstName: values.auditorName,
      lastName: values.auditorLastName,
      street: values.auditorStreet,
      postalCode: values.auditorPostalCode,
      place: values.auditorPlace,
      country: values.auditorCountry,
      email: values.auditorEmail,
      canton: values.auditorCanton
    };
  }
  if (reverseVesting) {
    payload[shaKeys.VESTED_SHARES_FMARKET_VAL] =
      100 - Number(values[shaKeys.VESTED_SHARES_NOMINAL_VAL]);
    payload[shaKeys.VESTED_SHARES_NOMINAL_VAL] = Number(
      values[shaKeys.VESTED_SHARES_NOMINAL_VAL]
    );
    payload[shaKeys.GOOD_LEAVER_NOMINAL_VAL] =
      values[shaKeys.GOOD_LEAVER_NOMINAL_VAL] === "nominal";
  }

  if (values[shaKeys.BAD_LEAVER] === "full") {
    payload[shaKeys.VESTED_SHARES_NOMINAL_VAL] = 100;
    payload[shaKeys.VESTED_SHARES_FMARKET_VAL] = 0;
  }

  if (subscriptionCompleted) {
    payload.directors = mapBoardOfDirectorValues(values);
  }

  return payload;
};

export const mapInvestmentAgreementValues = values => {
  const payload = {
    [iaKeys.UNAUDITED_FS_DATE]: values[iaKeys.UNAUDITED_FS_DATE],
    [iaKeys.MANAGEMENT_PL_DATE]: values[iaKeys.MANAGEMENT_PL_DATE],
    [iaKeys.ENTITY_EQUITY_AMOUNT]: values[iaKeys.ENTITY_EQUITY_AMOUNT],
    [iaKeys.ENTITY_BUSINESS_AGREEMENT_AMOUNT]:
      values[iaKeys.ENTITY_BUSINESS_AGREEMENT_AMOUNT] === "Other"
        ? values[iaKeys.ENTITY_BUSINESS_AGREEMENT_AMOUNT_OTHER]
        : +values[iaKeys.ENTITY_BUSINESS_AGREEMENT_AMOUNT],
    [iaKeys.ENTITY_CONVERTIBLE_OBLIGATION_AMOUNT]:
      values[iaKeys.ENTITY_CONVERTIBLE_OBLIGATION_AMOUNT],
    [iaKeys.ENTITY_OBLIGATION_AMOUNT]:
      values[iaKeys.ENTITY_OBLIGATION_AMOUNT] === "Other"
        ? values[iaKeys.ENTITY_OBLIGATION_AMOUNT_OTHER]
        : values[iaKeys.ENTITY_OBLIGATION_AMOUNT],
    [iaKeys.CONSENT_NEEDED_INV_CONFIRMATION]:
      values[iaKeys.CONSENT_NEEDED_INV_CONFIRMATION] === "yes",
    [iaKeys.CONSENT_NEEDED_TRANSFER]:
      values[iaKeys.CONSENT_NEEDED_TRANSFER] === "yes",
    [iaKeys.GUARANTEE_EXCESS]:
      values[iaKeys.GUARANTEE_EXCESS] === "Other"
        ? values[iaKeys.GUARANTEE_EXCESS_OTHER]
        : values[iaKeys.GUARANTEE_EXCESS],
    [iaKeys.COMPANY_AUDITOR_NAME]: values[iaKeys.COMPANY_AUDITOR_NAME],
    jurisdictionOfCourts: values[iaKeys.ORDINARY_COURT]
  };

  if (values[iaKeys.ORDINARY_COURT] === "Other") {
    payload.jurisdictionOfCourts = values[iaKeys.ORDINARY_COURT_OTHER];
  }

  if (values[iaKeys.NEW_COMPANY_AUDITOR] === "yes") {
    payload.newCompanyAuditor = {
      firstName: values[shaKeys.INDEPENDENT_EXPERT_NAME],
      lastName: values[shaKeys.INDEPENDENT_EXPERT_LAST_NAME],
      street: values[shaKeys.INDEPENDENT_EXPERT_STREET],
      postalCode: values[shaKeys.INDEPENDENT_EXPERT_ZIP],
      place: values[shaKeys.INDEPENDENT_EXPERT_PLACE],
      country: values[shaKeys.INDEPENDENT_EXPERT_COUNTRY],
      email: values[shaKeys.INDEPENDENT_EXPERT_EMAIL],
      canton: values[shaKeys.INDEPENDENT_EXPERT_CANTON]
    };
  }

  payload.directors = mapDirectorsValues(values);

  return payload;
};

export const mapEquityInvestorValues = (ioId, memberships = []) => {
  if (memberships.length > 0) {
    return memberships
      .filter(({ member, select, selected }) => member || select || selected)
      .map(
        ({
          email,
          firstName,
          lastName,
          amount,
          shares,
          shareholderSelect = false
        }) => {
          return {
            ioId,
            userEmail: email,
            userFirstName: firstName,
            userLastName: lastName,
            amount,
            shares,
            entityIsShareholder: shareholderSelect,
            redirectUrlForExistingUsers: `${process.env.HOST}/login?redirectTo=invitations`,
            redirectUrlForNewUsers: `${process.env.HOST}/onboarding`
          };
        }
      );
  }
  return [];
};

export const mapPatchEquityInvestorValues = (
  memberships = [],
  ioMemberships = [],
  ioId
) => {
  return memberships
    .filter(({ member, select, selected }) => member || select || selected)
    .map(
      ({
        amount,
        shares,
        shareholderSelect = false,
        cuid,
        userCuid = "",
        email = "",
        firstName = "",
        lastName = ""
      }) => {
        const ioMembershipRecord = ioMemberships.find(
          ({ investor: { cuid: investorCuid = "" } = {} }) =>
            investorCuid === cuid || investorCuid === userCuid
        );
        if (ioMembershipRecord) {
          return {
            cuid: ioMembershipRecord.cuid,
            amount,
            shares,
            entityIsShareholder: shareholderSelect
          };
        }
        return {
          ioId,
          userEmail: email,
          userFirstName: firstName,
          userLastName: lastName,
          amount,
          shares,
          entityIsShareholder: shareholderSelect,
          redirectUrlForExistingUsers: `${process.env.HOST}/login?redirectTo=invitations`,
          redirectUrlForNewUsers: `${process.env.HOST}/onboarding`
        };
      }
    );
};

export const mapBoardOfDirectorValues = ({
  selection = [],
  president
} = {}) => {
  return selection.map(cuid => ({
    membershipId: cuid,
    presidentOfBoard: president === cuid,
    new: false,
    powerOfAttorney: false,
    leavingMember: false
  }));
};

export const mapDirectorsValues = ({ signingDirector } = {}) => {
  return signingDirector
    .filter(({ PoA }) => PoA)
    .map(({ membershipId, presidentOfTheBoard }) => ({
      membershipId,
      presidentOfBoard: presidentOfTheBoard,
      new: false,
      powerOfAttorney: false,
      leavingMember: false
    }));
};

export const mapNotaryFormValues = values => {
  return {
    publicDeedEgmDate: values.publicDeedEgmDate,
    publicDeedBodDate: values.publicDeedBodDate,
    articleNumber: values.articleNumber,
    conditionalCapitalAmount: values.conditionalCapitalAmount,
    authorizedCapitalAmount: values.authorizedCapitalAmount
  };
};

export const mapEquityDataToReadableFormVals = (deal = {}, opts = {}) => {
  const { termSheet = {}, notary = {}, iaAndSha = {} } = deal;

  const data = {};
  const mapBooleansToConfirmationOpts = dataToConvert =>
    Object.keys(dataToConvert)
      .filter(key => typeof dataToConvert[key] === "boolean")
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: dataToConvert[key] ? "yes" : dataToConvert[key]
        }),
        {}
      );
  if (opts.termSheet) {
    data.termSheet = {
      ...termSheet,
      ...mapBooleansToConfirmationOpts(termSheet),
      fullyDiluted: termSheet.fullyDiluted ? "yes" : "no",
      reverseVestingMonths: termSheet.reverseVestingMonths
        ? termSheet.reverseVestingMonths.toString()
        : "",
      reverseVestingCliff: termSheet.reverseVestingCliff
        ? termSheet.reverseVestingCliff.toString()
        : "",
      [equityTs.DIRECTORS_NUMBER]:
        termSheet[equityTs.DIRECTORS_NUMBER] !== ("1" || "2")
          ? "Other"
          : termSheet[equityTs.DIRECTORS_NUMBER],
      directorsNumOther:
        termSheet[equityTs.DIRECTORS_NUMBER] === ("1" || "2")
          ? ""
          : termSheet[equityTs.DIRECTORS_NUMBER],
      [equityTs.INDEPENDENT_DIRECTORS_NUM]:
        termSheet[equityTs.INDEPENDENT_DIRECTORS_NUM] !== ("1" || "2")
          ? "Other"
          : termSheet[equityTs.INDEPENDENT_DIRECTORS_NUM],
      [equityTs.INDEPENDENT_DIRECTORS_NUM_OTHER]:
        termSheet[equityTs.INDEPENDENT_DIRECTORS_NUM] === ("1" || "2")
          ? ""
          : termSheet[equityTs.INDEPENDENT_DIRECTORS_NUM],
      [equityTs.FOUNDER_DIRECTOR]:
        termSheet[equityTs.FOUNDER_DIRECTOR] !== ("1" || "2")
          ? "Other"
          : termSheet[equityTs.FOUNDER_DIRECTOR],
      [equityTs.FOUNDER_DIRECTOR_OTHER]:
        termSheet[equityTs.FOUNDER_DIRECTOR] === ("1" || "2")
          ? ""
          : termSheet[equityTs.FOUNDER_DIRECTOR],
      [equityTs.INVESTOR_DIRECTOR]:
        termSheet[equityTs.INVESTOR_DIRECTOR] !== ("1" || "2")
          ? "Other"
          : termSheet[equityTs.INVESTOR_DIRECTOR],
      [equityTs.INVESTOR_DIRECTOR_OTHER]:
        termSheet[equityTs.INVESTOR_DIRECTOR] === ("1" || "2")
          ? ""
          : termSheet[equityTs.INVESTOR_DIRECTOR],
      [equityTs.COMMON_SHARE_NOMINAL]:
        termSheet[equityTs.COMMON_SHARE_NOMINAL] === 1 ? "CHF 1" : "Other",
      [equityTs.COMMON_SHARE_NOMINAL_OTHER]:
        termSheet[equityTs.COMMON_SHARE_NOMINAL] > 1
          ? termSheet[equityTs.COMMON_SHARE_NOMINAL]
          : "",

      esopPercentCalculation:
        termSheet[equityTs.INCLUDE_CLA_EFFECTS] ||
        termSheet[equityTs.INCLUDE_ESOP_EFFECTS]
          ? "yes"
          : "no",
      [equityTs.AOA_RESTRICT]: termSheet[equityTs.AOA_RESTRICT] ? "yes" : "no",
      [equityTs.CONTRACTUAL_PREFERRED]: termSheet[
        equityTs.CONTRACTUAL_PREFERRED
      ]
        ? "common_with_preferential_rights"
        : "common"
    };
  }

  if (opts.notary) {
    data.notary = {
      ...notary,
      ...mapBooleansToConfirmationOpts(notary)
    };
  }
  if (opts.iaAndSha) {
    let expert = {};
    const { newCompanyAuditor = {}, independentExpert = {} } = iaAndSha;
    if (Object.keys(independentExpert).length > 0) {
      expert = {
        [shaKeys.INDEPENDENT_EXPERT_NAME]: independentExpert.firstName,
        [shaKeys.INDEPENDENT_EXPERT_LAST_NAME]: independentExpert.lastName,
        [shaKeys.INDEPENDENT_EXPERT_STREET]: independentExpert.street,
        [shaKeys.INDEPENDENT_EXPERT_ZIP]: independentExpert.postalCode,
        [shaKeys.INDEPENDENT_EXPERT_PLACE]: independentExpert.place,
        [shaKeys.INDEPENDENT_EXPERT_COUNTRY]: independentExpert.country,
        [shaKeys.INDEPENDENT_EXPERT_EMAIL]: independentExpert.email,
        [shaKeys.INDEPENDENT_EXPERT_CANTON]: independentExpert.canton
      };
    }
    data.iaAndSha = {
      ...iaAndSha,
      companyAuditor: Object.keys(newCompanyAuditor).length > 0 ? "yes" : "no",
      ...expert,
      ...mapBooleansToConfirmationOpts(iaAndSha),
      [shaKeys.GOOD_LEAVER_NOMINAL_VAL]: iaAndSha[
        shaKeys.GOOD_LEAVER_NOMINAL_VAL
      ]
        ? "nominal"
        : "fair_market",
      [shaKeys.BAD_LEAVER]:
        iaAndSha[shaKeys.VESTED_SHARES_NOMINAL_VAL] === 100 &&
        iaAndSha[shaKeys.VESTED_SHARES_FMARKET_VAL] === 0
          ? "full"
          : "combined",
      unAuditedFsDatePrompt: iaAndSha.unAuditedFsDate ? "yes" : "no",
      managementPlDatePrompt: iaAndSha.managementPlDate ? "yes" : "no",
      entityEquityAmountPrompt: iaAndSha.entityEquityAmount > 0 ? "yes" : "no",
      entityBusinessAgreementAmount: iaAndSha.entityBusinessAgreementAmount
        ? iaAndSha.entityBusinessAgreementAmount.toString()
        : "",
      [shaKeys.LIQUIDATED_DAMANGES_AMOUNT]:
        iaAndSha[shaKeys.LIQUIDATED_DAMANGES_AMOUNT] === 20000 ||
        iaAndSha[shaKeys.LIQUIDATED_DAMANGES_AMOUNT] === 50000 ||
        iaAndSha[shaKeys.LIQUIDATED_DAMANGES_AMOUNT] === 100000
          ? iaAndSha[shaKeys.LIQUIDATED_DAMANGES_AMOUNT].toString()
          : "Other",
      [shaKeys.LIQUIDATED_DAMANGES_AMOUNT_OTHER]:
        iaAndSha[shaKeys.LIQUIDATED_DAMANGES_AMOUNT] !==
        (20000 || 50000 || 100000)
          ? iaAndSha[shaKeys.LIQUIDATED_DAMANGES_AMOUNT]
          : "",
      [shaKeys.NON_COMPETITION_NON_SOLICITATION_CALUSE]: iaAndSha[
        shaKeys.NON_COMPETITION_SOLICITATION_TERM
      ]
        ? "yes"
        : "no",

      [shaKeys.NON_COMPETITION_SOLICITATION_TERM]:
        iaAndSha[shaKeys.NON_COMPETITION_SOLICITATION_TERM] === (6 || 12)
          ? iaAndSha[shaKeys.NON_COMPETITION_SOLICITATION_TERM].toString()
          : "Other",
      [shaKeys.NON_COMPETITION_SOLICITATION_TERM_OTHER]:
        iaAndSha[shaKeys.NON_COMPETITION_SOLICITATION_TERM]
    };
  }

  return data;
};
