import { connect } from "react-redux";
import { getCurrentUser } from "#core/modules/users/actions/get";
import {
  updateUserSettings,
  onboardUser
} from "#core/modules/users/actions/update";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { isUpdating, didUpdate, _data = {} } = state.users;
  const { _data: { memberships = [] } = {} } = state.ioMemberships || {};
  const hasPendingInvitations = !!memberships.find(
    ({ status }) => status === 0
  );

  return {
    didUpdate,
    buttonStates: { loading: isUpdating },
    hasPendingInvitations,
    user: _data
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserSettings: data => dispatch(updateUserSettings(data)),
  onboardUser: data => dispatch(onboardUser(data)),
  getCurrentUser: () => dispatch(getCurrentUser()),
  clearState: key => clearState(dispatch, key)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
