import { founderInvSchema } from '#validators/founderInvitationSchema';
import { FormStepper, BasicFormWrapper } from '../../../Shared/Form';
import { list, formSections } from './list';
import { handleInputChange } from '../../common/formHandler';
import { FounderCreationSuccess } from '../../common/successViews';

const FounderInvitationForm = ({
  values,
  setFieldValue,
  handleChange,
  entityId,
  errors,
  ...props
}) => {
  const handlersArgs = { handleChange, setFieldValue, values };
  const formArgs = {
    errors,
    sections: formSections,
    list,
    values,
    inputEvents: { onChange: handleInputChange(handlersArgs) },
    validationSchema: founderInvSchema,
    CustomSuccess: FounderCreationSuccess({
      entityId,
      email: values.inviteeEmail
    }),
    ...props
  };

  return <FormStepper {...formArgs} />;
};

export default BasicFormWrapper(FounderInvitationForm);
