import { useEffect } from "react";
import { useHistory } from "react-router";
import { roles } from "#constants/roles";
import { mapDealToReadableFormValues } from "#helpers/mappers/dealCreation";
import { dealSchema } from "#validators/ioSchema";
import ioTypes from "#constants/io/ioTypes";
import { FormStepper } from "../../../../Shared/Form";
import { formItems } from "./items/list";
import { formSections } from "./items/sections";
import CustomInputRenderer from "../InputRenderer";
import { handleInputChange } from "../../../common/formHandler";

const FormContainer = ({
  handleUpload,
  handleChange,
  setFieldValue,
  currencies,
  values,
  buttonStates,
  investorsList,
  membershipStates,
  leadInvestor,
  memberships,
  userRole,
  currenciesDidFetch,
  setValues,
  resetForm,
  createdIoCuid,
  ioColl,
  ioId,
  compliant,
  syndicateId,
  ...props
}) => {
  const history = useHistory();
  useEffect(() => {
    if (compliant) {
      setValues({ ...values, dealType: ioTypes.CONVERSION_POOL });
    }
  }, [compliant]);

  useEffect(() => {
    if (ioColl.didFetch && ioId) {
      const { data } = ioColl;
      if (Object.keys(data).length > 0) {
        const formattedData = mapDealToReadableFormValues(data);
        setValues({ ...values, ...formattedData });
      }
    }
  }, [ioColl.didFetch]);

  useEffect(() => {
    if (buttonStates.success)
      history.push(`/dashboard/deal_management/${ioColl?.data?.cuid}`);
  }, [buttonStates]);

  const formListArgs = {
    termsheetFile: values.termsheetFile,
    values,
    currencies,
    userRole,
    compliant
  };

  const filteredMemberships = memberships
    .filter(
      ({ role }) => role === roles.ASSET_MANAGER || role === roles.INVESTOR
    )
    .map(({ user: { cuid: value, firstName, lastName } }) => ({
      value,
      label: `${firstName} ${lastName}`
    }));

  const formList = formItems({
    ...formListArgs,
    investorsList: filteredMemberships,
    editingMode: !!ioId,
    syndicateId
  }).filter(Boolean);

  const filteredSections = formSections({
    userRole,
    editingMode: !!ioId,
    syndicateId
  })
    .filter(Boolean)
    .sort((a, b) => a.id - b.id);

  const propsToBePassed = {
    values,
    CustomInputRenderer,
    list: formList,
    sections: filteredSections,
    inputEvents: {
      values,
      onUpload: handleUpload(setFieldValue),
      onChange: handleInputChange({ handleChange, setFieldValue, values })
    },
    buttonStates,
    success: buttonStates.success,
    isAm: userRole === roles.ASSET_MANAGER,
    validationSchema: userRole === roles.ASSET_MANAGER ? dealSchema : null,
    skippable: false,
    customClassName: "context--violet",
    ...props
  };

  return (
    <div className="form-container">
      <FormStepper {...propsToBePassed} />
    </div>
  );
};

export default FormContainer;
