import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './action_types';
import actions from '../../constants/action_type';

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.FETCH_ALL_PERMISSIONS:
      return baseReducer(action, state, actions.FETCH, { success: () => {} });
    case types.UPDATE_PERMISSIONS:
      return baseReducer(action, state, actions.UPDATE, { success: () => {} });
    default:
      return state;
  }
};

export default reducer;
