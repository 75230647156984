import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './action_types';
import actions from '../../constants/action_type';

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.LOGIN:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            ...action.payload.data
          };
        }
      });

    case types.RESEND_EMAIL_VERIFICATION:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return { ...state._data };
        }
      });

    case types.GENERATE_2FA:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data,
            auth2fa: action.payload.data
          };
        }
      });

    case types.COMPLETE_2FA:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data,
            twoStepAuthActive: action.payload.twoStepAuthActive
          };
        }
      });

    case types.VERIFY_TOKEN:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            tokenAuth: action.payload.tokenAuth
          };
        }
      });

    case types.NEW_PASSWORD:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return { ...state._data };
        }
      });

    case types.REQUEST_PASSWORD_RESET:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          return { ...state._data };
        }
      });

    case types.REGISTRATION:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data,
            registrationSuccess: true,
            registration: action.payload.data
          };
        }
      });
    case types.CONFIRM_ACCOUNT:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });

    default:
      return state;
  }
};

export default reducer;
