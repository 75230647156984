import axios from "./apiConfig";

export const addIosService = investment => axios.post(`investops`, investment);

export const getAllIos = id => {
  let path = "investops?";

  if (id) {
    path += `entity_id=${id}`;
  }

  return axios.get(path);
};

export const getPortfolio = params =>
  axios.get(`/portfolio/investops`, { params });

export const getSingleIos = ioId => axios.get(`investops/${ioId}`);

export const getCapTable = params =>
  axios.get(`cap-tables/investop`, { params });

export const getRoundType = ({ ioId, type }) =>
  axios.get(`investops/${ioId}/${type}`);

export const postIoDocs = docs => axios.post(`investops/docs`, docs);

export const postIoAttachment = ({ ioId, attachment }) =>
  axios.post(`/investops/${ioId}/attachments`, attachment);

export const notifyDmForIoFinalization = ({ cuid }) =>
  axios.post(`investops/${cuid}/notify-dm-for-io-finalization`);

export const updateIO = (id, data) => axios.put(`investops/${id}`, data);

export const putIO = (id, data) => axios.patch(`investops/${id}`, data);

export const updateIoClosingStatus = ({ cuid, ...data }) =>
  axios.put(`investops/${cuid}/status`, data);

export const patchIoAttachment = ({ ioId, attachmentId, ...data }) =>
  axios.patch(`investops/${ioId}/attachments/${attachmentId}`, data);

export const deleteIO = cuid => axios.delete(`investops/${cuid}`);

export const deleteIoAttachment = ({ ioId, cuid }) =>
  axios.delete(`investops/${ioId}/attachments/${cuid}`);

export const submitIoConfirmTransfer = ({ ioId, data }) =>
  axios.post(`investops/${ioId}/confirm-transfer`, data);
