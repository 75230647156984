import axios from "../../../../api/apiConfig";
import types from "../action_types";
import * as authService from "../../../../api/authService";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import { saveItem } from "../../../../src/utils/storage";
import storageKeys from "../../../../src/constants/storage";

export const submitLogin = loginData => {
  return async dispatch => {
    try {
      start(dispatch, types.LOGIN);
      const response = await authService.loginService(loginData);
      axios.defaults.headers.common.Authorization = `Bearer  ${response.data.token}`;

      saveItem(storageKeys.JWT, response.data.token);
      success(dispatch, types.LOGIN, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message, code } = errorData;
      catchError(dispatch, message, { code, email: loginData.email });
      fail(dispatch, types.LOGIN, {
        error: errorData
      });
    }
  };
};

export const register = user => {
  return async dispatch => {
    try {
      start(dispatch, types.REGISTRATION);
      const response = await authService.registerService(user);
      success(dispatch, types.REGISTRATION, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.REGISTRATION, {
        error: errorData
      });
    }
  };
};
