import { handleColor } from "#helpers/general";
import Checkbox from "../../../../Shared/Checkbox";
import InputContainer from "../../../../Shared/Input";
import TagWrapper from "../../../../Shared/Tag";

// TODO: INTEGRATE FETCHED BOARD DIRECTORS LIST AS A FIELD VALUE

const shareholdersInvestingCols = (
  values,
  { checkboxInArray, inputInArray } = {},
  selectedConversionInvestor = []
) => {
  return [
    {
      title: "Selection",
      dataIndex: "selected",
      key: "selected",
      render: (value, { cuid }) => (
        <Checkbox
          name="selected"
          onChange={checkboxInArray("investorsOverview", cuid)}
          checked={value}
        />
      )
    },
    {
      title: "First and last name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, { firstName = "", lastName = "" }) =>
        `${firstName} ${lastName.match(/\d+/g) ? " " : lastName}`
    },
    {
      title: "Amount to be invested",
      dataIndex: "amount",
      key: "amountForInvestment",
      render: (value, { selected, cuid }) => (
        <InputContainer
          type="number"
          name="amount"
          value={selected ? value : ""}
          onChange={inputInArray("investorsOverview", cuid)}
          formatter={val =>
            val && `CHF ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
          }
          disabled={!selected}
          parser={val => val.replace(new RegExp(`CHF\\s?|(\\'*)`, "g"), "")}
        />
      )
    },

    {
      title: "New shares receiving",
      dataIndex: "newShares",
      key: "newShares",
      render: value => <InputContainer disabled value={value} />
    },
    {
      title: "Share price",
      dataIndex: "sharePrice",
      key: "sharePrice"
    },
    {
      title: "Investment",
      dataIndex: "investment",
      key: "investment",
      render: (value, { cuid, entityMembershipId }) => {
        const selectedInvestor = values.investorsOverview
          .filter(({ cuid: invCuid, selected }) => cuid === invCuid && selected)
          .map(({ cuid: invId }) => invId);
        if (selectedConversionInvestor.includes(entityMembershipId)) {
          return <TagWrapper color="custom-green"> Set Off </TagWrapper>;
        }
        if (selectedInvestor.includes(cuid)) {
          return <TagWrapper color="blue"> Cash </TagWrapper>;
        }
      }
    },
    {
      title: "Fully paid up",
      dataIndex: "fullyPaidUp",
      key: "fullyPaidUp",
      render: (value, { cuid, entityMembershipId }) => (
        <Checkbox
          name="fullyPaidUp"
          onChange={
            selectedConversionInvestor.includes(entityMembershipId)
              ? null
              : checkboxInArray("investorsOverview", cuid)
          }
          checked={
            selectedConversionInvestor.includes(entityMembershipId) || value
          }
        />
      )
    }
  ];
};

const boardOfDirectorsColumns = (
  { signingDirector = [] },
  { checkboxInArray }
) => {
  const newSelected =
    Array.isArray(signingDirector) &&
    signingDirector
      .filter(({ new: newDirector = false }) => newDirector)
      .map(({ cuid }) => cuid);
  const poaSelected =
    Array.isArray(signingDirector) &&
    signingDirector.filter(({ PoA = false }) => PoA).map(({ cuid }) => cuid);
  return [
    {
      title: "New",
      dataIndex: "new",
      key: "new",
      render: (value, { cuid }) => {
        return (
          <Checkbox
            checked={value}
            name="new"
            onChange={checkboxInArray("signingDirector", cuid)}
            disabled={poaSelected.includes(cuid)}
          />
        );
      }
    },
    {
      title: "PoA",
      dataIndex: "PoA",
      key: "PoA",
      render: (value, { cuid, representativeOf }) => (
        <Checkbox
          checked={value}
          name="PoA"
          onChange={checkboxInArray("signingDirector", cuid)}
          // disabled={representativeOf !== "founder"}
          disabled={newSelected.includes(cuid)}
        />
      )
    },
    {
      title: "First and last name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, { firstName, lastName }) => `${firstName} ${lastName}`
    },
    {
      title: "President of the board",
      dataIndex: "presidentOfTheBoard",
      key: "presidentOfTheBoard",
      render: value => (value ? "Yes" : "No")
    },
    {
      title: "Representing",
      dataIndex: "representativeOf",
      key: "representativeOf",
      render: text => <TagWrapper color={handleColor(text)}>{text}</TagWrapper>
    },
    {
      title: "Signature",
      dataIndex: "signingAuthority",
      key: "signingAuthority",
      render: text =>
        text && <TagWrapper color={handleColor(text)}>{text}</TagWrapper>
    }
  ];
};

const capTableCols = [
  {
    title: "First name",
    dataIndex: "firstName",
    key: "firstName"
  },
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName"
  },
  {
    title: "Total amount invested",
    dataIndex: "totalAmount",
    key: "totalAmount"
  },
  {
    title: "Total shares",
    dataIndex: "sharesNumber",
    key: "sharesNumber"
  },
  {
    title: "Average Share price",
    dataIndex: "avgSharePrice",
    key: "avgSharePrice"
  }
];

const investorWrittenConsentCols = ({ checkboxInArray } = {}) => {
  return [
    {
      title: "Selection",
      dataIndex: "selected",
      key: "selected",
      render: (value, { cuid }) => (
        <Checkbox
          name="selected"
          onChange={checkboxInArray("investorsWrittenConsent", cuid)}
          checked={value}
        />
      )
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
      render: lastName => (lastName.match(/\d+/g) ? " " : lastName)
    }
  ];
};

export {
  shareholdersInvestingCols,
  boardOfDirectorsColumns,
  capTableCols,
  investorWrittenConsentCols
};
