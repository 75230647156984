import { Button } from "../../../../Button";

const FormProgress = ({
  total,
  current,
  disabledSubmit,
  onActionClick,
  buttonStates,
  handleSkip,
  skippable,
  isValidating,
  history,
  finish
}) => {
  const showValidatingSpinner =
    (total === current + 1 && isValidating) || buttonStates.loading;
  let localFinish = true;

  if (!finish && current === total - 1) localFinish = false;

  const showNextFinish = !disabledSubmit && localFinish;

  return (
    !disabledSubmit && (
      <div className="form-progress-wrapper">
        <div className="container">
          <Button
            label="Back"
            ghost
            size="large"
            type="secondary"
            className="back-btn"
            disabled={!history && current === 0}
            onClick={
              current === 0 && !!history
                ? () => history.goBack()
                : onActionClick(-1)
            }
          />
          {showNextFinish && (
            <Button
              size="large"
              label={total !== current + 1 ? "Next" : "Finish"}
              onClick={onActionClick(+1)}
              {...buttonStates}
              className="next-finish-btn"
              loading={showValidatingSpinner}
            />
          )}
        </div>
        {skippable && total !== current + 1 && (
          <Button
            size="large"
            label="Skip"
            className="danger"
            type="ghost"
            onClick={handleSkip}
          />
        )}
      </div>
    )
  );
};

export default FormProgress;
