import { useEffect, useState } from "react";
import { List, message, Avatar, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroller";

const InfiniteList = ({
  containerWidth,
  containerHeight,
  data,
  ListRenderer
}) => {
  const [state, setState] = useState({});

  useEffect(() => {
    setState({
      ...state,
      currIdx: 5,
      hasMore: true,
      data: data.slice(0, 5)
    });
  }, []);
  useEffect(() => {
    setState({
      ...state,
      currIdx: 5,
      hasMore: true,
      data: data.slice(0, 5)
    });
  }, [data]);

  const handleInifiniteLoad = () => {
    setState({ ...state, loading: true });

    if (state.data.length == data.length) {
      setState({ ...state, hasMore: false });
    } else {
      const { currIdx } = state;
      setState({
        ...state,
        data: data.slice(0, currIdx + 5),
        currIdx: currIdx + 5
      });
    }
  };

  return (
    <div
      style={{
        maxWidth: containerWidth,
        maxHeight: containerHeight,
        overflowY: "scroll"
      }}
    >
      <InfiniteScroll
        loadMore={handleInifiniteLoad}
        initialLoad={false}
        pageStart={0}
        hasMore={!state.loading && state.hasMore}
      >
        <ListRenderer data={data} />
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteList;
