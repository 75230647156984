import { claTsKeys } from "#constants/cla";
import {
  formatStakeholdersToConvertibleInvestors,
  mapClaArrayToReadable,
  mapClaDataToReadableFormVals
} from "#helpers/mappers/convertibles";

export const setClaFormValues = ({
  convertibles,
  shareholdersColl,
  ioColl,
  allMemberships,
  step,
  ioMemberships,
  commits,
  formData,
  id,
  setValues,
  esopShares,
  psopShares
}) => () => {
  const formattedConvertibleTypes = convertibles.reduce(
    (acc, { cuid, ...item }) => ({ ...acc, [cuid]: item }),
    {}
  );
  const { data } = shareholdersColl;
  const { data: ioData } = ioColl;
  const { cla: { claDirectors = [] } = {} } = ioData || {};

  const formattedShareholders = formatStakeholdersToConvertibleInvestors({
    data,
    convertibleTypes: formattedConvertibleTypes
  });

  const formatedConvertibles = convertibles.map(({ name: claName, cuid }) => ({
    name: claName,
    cuid
  }));
  const formattedMemberships = allMemberships
    .filter(
      ({ isDirector, role, representativeOf }) =>
        representativeOf !== "board_observer" &&
        (role === "director" || isDirector)
    )
    .map(membership => ({
      membershipId: membership.cuid,
      presidentOfTheBoard: membership.presidentOfTheBoard,
      representativeOf: membership.representativeOf,
      signingAuthority: membership.signingAuthority,
      signingDirector: !!claDirectors.find(
        ({ membershipId }) => membershipId === membership.cuid
      ),
      ...membership.user
    }));

  let uniqueShareholders = [];
  uniqueShareholders =
    formattedShareholders &&
    formattedShareholders.length > 0 &&
    formattedShareholders.filter(
      (v, i, a) => a.findIndex(t => t.cuid === v.cuid) === i
    );

  const { cla = {} } = mapClaDataToReadableFormVals(ioData);

  if (id && !step) {
    let readableInvestors = [];
    let readableMemberships = [];

    if (Array.isArray(uniqueShareholders) && uniqueShareholders.length > 0) {
      readableInvestors = uniqueShareholders.map(investor => {
        const existingInvestor = ioMemberships.find(
          ({ investor: { cuid = "" } = {} }) => cuid === investor.cuid
        );
        if (existingInvestor) {
          const existingInvestorAmount = commits.find(
            ({ committerId = "", ioId = "" }) =>
              existingInvestor.investor.cuid === committerId && ioId === id
          );
          return {
            ...investor,
            select: true,
            shareholderSelect: existingInvestor.entityIsShareholder,
            amount: existingInvestorAmount ? existingInvestorAmount.amount : ""
          };
        }
        return { ...investor };
      });
    }
    if (
      Array.isArray(formattedMemberships) &&
      formattedMemberships.length > 0
    ) {
      readableMemberships = formattedMemberships.map(director => {
        const existingDirector = claDirectors.find(
          ({ membershipId }) => membershipId === director.membershipId
        );
        if (existingDirector) {
          return {
            ...director,
            signingDirector: true
          };
        }
        return { ...director };
      });
    }
    const formattedReadableClaMinOutstanding = mapClaArrayToReadable(
      formatedConvertibles,
      ioColl.data.cla[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]
    );
    const formattedReadableClaOutstanding = mapClaArrayToReadable(
      formatedConvertibles,
      ioColl.data.cla[claTsKeys.CL_EFFECTS_ARRAY]
    );
    const formattedReadableClaOutstandingST = mapClaArrayToReadable(
      formatedConvertibles,
      ioColl.data.cla[
        claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
      ]
    );
    const formattedReadableClaOutstandingMD = mapClaArrayToReadable(
      formatedConvertibles,
      ioColl.data.cla[claTsKeys.CL_EFFECTS_MD_ARRAY]
    );

    const formattedReadableClaOutstandingPostMoneyMD = mapClaArrayToReadable(
      formatedConvertibles,
      ioColl.data.cla[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]
    );
    const formattedReadableFairMarketConvertibles = mapClaArrayToReadable(
      formatedConvertibles,
      ioColl.data.cla[
        claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
      ]
    );
    const formattedReadableFloorValuationConvertiblesSt = mapClaArrayToReadable(
      formatedConvertibles,
      ioColl.data.cla[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST
      ]
    );
    const formattedReadableFloorValuationConvertiblesNfr = mapClaArrayToReadable(
      formatedConvertibles,
      ioColl.data.cla[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR
      ]
    );
    setValues({
      ...cla,
      [claTsKeys.INVESTOR_HAS_COMMITTED]: readableInvestors.find(
        ({ select }) => select
      )
        ? "yes"
        : "no",
      [claTsKeys.INVESTORS_TABLE]: readableInvestors,
      signingDirector: readableMemberships,
      [claTsKeys.CONVERTIBLE_LOANS]: convertibles,
      outstandingEsop: esopShares ? "yes" : "no",
      outstandingEsopShares: esopShares,
      [claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formattedReadableClaMinOutstanding,
      [claTsKeys.CL_EFFECTS_ARRAY]: formattedReadableClaOutstanding,
      [claTsKeys.CL_EFFECTS_MD_ARRAY]: formattedReadableClaOutstandingMD,
      [claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formattedReadableClaOutstandingST,
      [claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]: formattedReadableClaOutstandingPostMoneyMD,

      [claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formattedReadableFairMarketConvertibles,
      [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST]: formattedReadableFloorValuationConvertiblesSt,
      [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR]: formattedReadableFloorValuationConvertiblesNfr,
      esopValue: esopShares,
      psopValue: psopShares
    });
  } else if (step !== undefined && step !== null) {
    let formShareholders = [];
    let formMemberships = [];

    if (Array.isArray(uniqueShareholders) && uniqueShareholders.length > 0) {
      formShareholders = uniqueShareholders.reduce((acc = [], curr) => {
        const existingShareholder = formData[claTsKeys.INVESTORS_TABLE].find(
          ({ cuid, select }) => cuid === curr.cuid && select
        );
        if (existingShareholder) {
          return [...acc, existingShareholder];
        }
        return [...acc, curr];
      }, []);
    }

    if (
      Array.isArray(formattedMemberships) &&
      formattedMemberships.length > 0
    ) {
      formMemberships = formattedMemberships.reduce((acc = [], curr) => {
        const existingMember = formData.signingDirector.find(
          ({ membershipId, signingDirector }) =>
            membershipId === curr.membershipId && signingDirector
        );
        if (existingMember) {
          return [...acc, existingMember];
        }
        return [...acc, curr];
      }, []);
    }
    setValues({
      ...formData,
      [claTsKeys.INVESTORS_TABLE]: formShareholders,
      [claTsKeys.CONVERTIBLE_LOANS]: formData[claTsKeys.CONVERTIBLE_LOANS]
        ? formData[claTsKeys.CONVERTIBLE_LOANS]
        : convertibles,
      signingDirector: formMemberships,
      [claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formData[
        claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
      ]
        ? formData[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]
        : formatedConvertibles,
      [claTsKeys.CL_EFFECTS_ARRAY]: formData[claTsKeys.CL_EFFECTS_ARRAY]
        ? formData[claTsKeys.CL_EFFECTS_ARRAY]
        : formatedConvertibles,
      [claTsKeys.CL_EFFECTS_MD_ARRAY]: formData[[claTsKeys.CL_EFFECTS_MD_ARRAY]]
        ? formData[claTsKeys.CL_EFFECTS_MD_ARRAY]
        : formatedConvertibles,
      [claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formData[
        claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
      ]
        ? [claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]
        : formatedConvertibles,
      [claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]: formData[
        claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY
      ]
        ? formData[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]
        : formatedConvertibles,
      [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR]: formData[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR
      ]
        ? formData[
            claTsKeys
              .FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR
          ]
        : formatedConvertibles,

      [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST]: formData[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST
      ]
        ? formData[
            claTsKeys
              .FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST
          ]
        : formatedConvertibles,

      [claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formData[
        claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
      ]
        ? formData[
            claTsKeys
              .FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
          ]
        : formatedConvertibles,
      esopValue: esopShares,
      psopValue: psopShares
    });
  } else {
    setValues({
      [claTsKeys.INVESTORS_TABLE]: uniqueShareholders,
      signingDirector: formattedMemberships,
      [claTsKeys.CONVERTIBLE_LOANS]: convertibles,
      outstandingEsop: esopShares ? "yes" : "no",
      outstandingEsopShares: esopShares,
      [claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formatedConvertibles,
      [claTsKeys.CL_EFFECTS_ARRAY]: formatedConvertibles,
      [claTsKeys.CL_EFFECTS_MD_ARRAY]: formatedConvertibles,
      [claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formatedConvertibles,
      [claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]: formatedConvertibles,
      [claTsKeys.TRIGGER_EVENT]: ["maturity_date"],
      [claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]: formatedConvertibles,
      [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR]: formatedConvertibles,
      [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST]: formatedConvertibles,
      valuationCapMDPrompt: "yes",
      [claTsKeys.CURRENCY]: "ck8rarclm000145vicky7014j",
      esopValue: esopShares,
      psopValue: psopShares
    });
  }
};

export const setConvertibleNameExistValues = ({
  convertibles,
  setFieldValue,
  name
}) => () => {
  const convertibleNameExists = convertibles
    .map(({ name: convertibleName }) => convertibleName)
    .includes(name);
  setFieldValue("convertibleNameExists", convertibleNameExists);
};
