import { closedValues } from "#constants/io/closed";
import { roles } from "#constants/roles";
import { getMissingAttachments } from "#helpers/attachments";
import { differenceInDays } from "date-fns";
import { useCallback } from "react";
import Alert from "../../Alert";

const withBackNavigation = Target => props => {
  const {
    userRole,
    io: { cuid, attachments = [], dealType, closed, closingTimestamp } = {},
    actions
  } = props;

  const handleDmNotification = useCallback(() => {
    actions.notifyDmForIoFinalization({ cuid });
  }, [cuid]);

  const [
    missingAttachments,
    hasMissingAttachs,
    hasMandatoryMissingAttachs
  ] = getMissingAttachments(attachments, dealType);

  const closedByFounder =
    closed >= closedValues.CLOSED_BY_FOUNDER &&
    closed < closedValues.APPROVED_BY_LAWYERS;

  const getDaysElapsed = () =>
    Math.abs(differenceInDays(new Date(closingTimestamp), Date.now()));

  const isAm = userRole === roles.ASSET_MANAGER;
  const isInvestor =
    userRole === roles.INVESTOR || userRole === roles.AM_INVESTOR;

  const daysElapsed =
    (isAm || isInvestor) && closedByFounder ? getDaysElapsed() : "X";

  const days = 28 - daysElapsed;

  const alertMsg = isAm
    ? `The deal is missing a mandatory document to update your co-investors. ${
        daysElapsed <= 28 ? `You have ${days} days to upload them. ` : ``
      }Please go to the data room and upload the document requested in red.`
    : "Deal has missing uploads. Click here to remind the Deal Manager to upload them.";
  const sha = attachments.find(attachment => attachment.subCategory === "sha");
  const showDocsAlert =
    (isAm || (isInvestor && daysElapsed > 28 && !sha)) &&
    closedByFounder &&
    hasMissingAttachs;

  const showTopBarAlert =
    (isAm || (isInvestor && daysElapsed > 28 && !sha)) &&
    closedByFounder &&
    hasMandatoryMissingAttachs;

  const messageProps = isInvestor && {
    onClick: handleDmNotification
  };

  const alertProps = {
    message: alertMsg,
    visible: true,
    type: "warning",
    messageProps,
    onClick: () =>
      isAm &&
      window.scrollTo({
        top: 700,
        left: 100,
        behavior: "smooth"
      })
  };

  const alert = showTopBarAlert && <Alert {...alertProps} />;

  const finalProps = {
    ...props,
    docConstraints: [
      missingAttachments,
      hasMissingAttachs,
      hasMandatoryMissingAttachs,
      showDocsAlert,
      days,
      daysElapsed
    ]
  };

  return (
    <>
      {alert}
      <Target {...finalProps} />
    </>
  );
};

export default withBackNavigation;
