import { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import ModalWrapper from "../../../Shared/Modal";
import { BasicForm } from "../../../Shared/Form";

const AddItemsPresentational = ({
  fields,
  setFieldValue,
  name,
  value = [],
  onCancel,
  modalTitle,
  entityShares,
  formattedConvertibles,
  handleNotification,
  initValues,
  isEditing,
  existingShares = [],
  submitHandler,
  editHandler,
  values,
  formArgs,
  ...modalProps
}) => {
  const handleSubmit = (item = {}, resetForm) => {
    const args = { setFieldValue, name, value, item };
    if (isEditing) {
      editHandler(args);
    } else {
      submitHandler(args);
    }

    onCancel();
  };

  return (
    <ModalWrapper
      title={modalTitle}
      onCancel={onCancel}
      {...modalProps}
      destryOnClose
      footer={null}
    >
      <BasicForm
        handleCancel={onCancel}
        fieldList={fields}
        handleSubmit={handleSubmit}
        values={initValues}
        rawInitValues
        {...formArgs}
      />
    </ModalWrapper>
  );
};

AddItemsPresentational.propTypes = {
  modalTitle: PropTypes.string,
  fields: PropTypes.array,
  setFieldValue: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  onCancel: PropTypes.func,
  editingType: PropTypes.object,
  editing: PropTypes.bool,
  entityShares: PropTypes.number,
  formattedConvertibles: PropTypes.array,
  handleNotification: PropTypes.func,
  initValues: PropTypes.object,
  isEditing: PropTypes.bool,
  existingShares: PropTypes.array
};

export default AddItemsPresentational;
