import * as syndicateService from "../../../../api/syndicateService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const getSyndicates = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_SYNDICATES);
      const response = await syndicateService.getSyndicates(params);
      success(dispatch, types.FETCH_SYNDICATES, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_SYNDICATES, {
        error: errorData
      });
    }
  };
};

export const getPortfolioSyndicates = params => {
  return async dispatch => {
    try {
      const lastRequestTimestamp = (document._lastRequestTimestamp = Date.now());
      start(dispatch, types.FETCH_PORTFOLIO_SYNDICATES);
      const response = await syndicateService.getPortfolioSyndicates(params);
      if (lastRequestTimestamp !== document._lastRequestTimestamp) return;
      success(dispatch, types.FETCH_PORTFOLIO_SYNDICATES, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_PORTFOLIO_SYNDICATES, {
        error: errorData
      });
    }
  };
};

export const getSingleSyndicate = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_SYNDICATE);
      const response = await syndicateService.getSyndicate(params);
      success(dispatch, types.FETCH_SYNDICATE, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_SYNDICATE, {
        error: errorData
      });
    }
  };
};
