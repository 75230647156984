import * as fields from "./fields";
import { indexItem } from "../../common/prompts";

const { generalInformation } = fields;

const getList = ({
  values = {},
  inputHandlers = {},
  handleFormSave,
  currPath
} = {}) => {
  const indexedGeneralInformations = generalInformation(
    values,
    inputHandlers,
    handleFormSave,
    currPath
  )
    .filter(Boolean)
    .map(indexItem);

  const finalList = [
    {
      sectionId: 1,
      items: indexedGeneralInformations
    }
  ];

  return finalList;
};

export default getList;
