import ioTypes from "#constants/io/ioTypes";
import { roles } from "#constants/roles";

/* FUNCTION TO STRIP PHONE INPUT FROM ITS MASK CHARACTERS (+, -) */

/**
 * A function that strips string value from characters that might have been passed from the phone input mask e.g (+ ) -  -
 * @param {*} phone
 */
export const returnPlainPhone = phone => phone.replace(/\D+/g, "");

/**
 * Adds http to the string provided if it doesn't have it already.
 * @param {String} link Link to be checked.
 */
export const addHttp = link => {
  if (!link.match(/^[a-zA-Z]+:\/\//)) {
    return `http://${link}`;
  }
  return link;
};
export const maximumInterestCurrencyPercentige = {
  eur: 0.5,
  usd: 2.25,
  gbp: 1.5,
  chf1: 3,
  chf2: 1
};

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const testEmail = input =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input);

export const handleNav = (history, url) => () => history.push(url);

export const returnUserRole = role => {
  if (role === roles.FOUNDER) {
    return "Founder";
  } else if (role === roles.EMPLOYEE) {
    return "Employee";
  } else if (role === roles.INVESTOR || role === roles.AM_INVESTOR) {
    return "Investor";
  } else if (role === roles.ASSET_MANAGER) {
    return "Asset manager";
  } else if (role === roles.LAWYER) {
    return "Lawyer";
  }

  return role;
};

export const renderType = type => {
  if (type === ioTypes.CONVERSION_POOL) return "Conversion pool";
  else if (type === ioTypes.CONVERTIBLE_LOAN || type === ioTypes.DEBT_FINANCING)
    return "Convertible loan";
  else if (type === ioTypes.POOLED_EQUITY) return "Equity";
  else if (type === ioTypes.KISS) return "KISS";
  else if (type === ioTypes.SAFE) return "SAFE";
  else if (type === ioTypes.BSA_AIR) return "BSA AIR";
  else if (type === ioTypes.POOLED_FUND) return "Pooling into funds";
  else return type;
};

export const handleColor = role => {
  return role === roles.FOUNDER
    ? "blue"
    : role === roles.INVESTOR
    ? "orange"
    : role === roles.EMPLOYEE
    ? "green"
    : role === roles.ASSET_MANAGER
    ? "red"
    : role === roles.DIRECTOR
    ? "teal"
    : role === "auditor"
    ? "custom-green"
    : role === "board_observer"
    ? "green"
    : role === "collective"
    ? "custom-green"
    : role === "none"
    ? "green"
    : role === "single"
    ? "blue"
    : role === "independent"
    ? "teal"
    : "white";
};

export const checkDateString = (date = "") => {
  return date.includes("T") ? date.split("T")[0] : date;
};

export const checkPasswordRequirements = (password = "") => {
  const errors = [];

  if (password.length < 8) {
    errors.push("- Must be minimum 8 characters long");
  }

  if (!/[A-Z]/.test(password)) {
    errors.push("- Must contain minimum 1 uppercase letter");
  }

  if (!/[a-z]/.test(password)) {
    errors.push("- Must contain minimum 1 lowercase letter");
  }

  if (!/[@$!%*;?&-]/.test(password)) {
    errors.push("- Must contain minimum 1 special symbol (@$!%*;?&-)");
  }

  const disallowedSymbols = /[)#^(=+<>`~'\[{}\]|/]/;
  if (disallowedSymbols.test(password)) {
    errors.push("- Must not include certain symbols: )#^(=+<>`~'[{}|/]");
  }

  if (!/\d/.test(password)) {
    errors.push("- Must contain minimum 1 number");
  }

  return errors;
};

export const passwordRequirementsRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*;?&-])(?=.*\d)[^)\s#^=(+<>~'\][{}|/]{8,}$/;

export const passwordRequirementsSpan = (
  <span>
    Password requirements: <br />
    - Must be minimum 8 characters long <br />
    - Must contain minimum 1 uppercase letter <br />
    - Must contain minimum 1 lowercase letter <br />
    - Must contain minimum 1 special symbol (@$!%*;?&-)
    <br />- Must not include these symbols: )#^(=+<>`~'][{}|/</>\"
    <br />
    - Must contain minimum 1 number.
    <br />
  </span>
);

export const basicOption = (value, label, opts = {}) => ({
  value,
  label: label || value,
  ...opts
});

export const getAllMembers = ({ memberships, shareholders }) => {
  return memberships.map(({ user = {}, ...item }) => {
    const shareholderRecord = shareholders.find(
      ({ user: { cuid: localCuid } = {} }) => localCuid === user.cuid
    );
    if (shareholderRecord) {
      return {
        ...item,
        user: {
          ...shareholderRecord.user,
          ...user
        }
      };
    }
    return { ...item, user };
  });
};

export const urlIsImg = url => url.match(/\.(jpeg|jpg|gif|png)$/);

export const targetBlankPdf = pdf => () => {
  // DOING THIS BECAUSE CHROME DOESNT OPEN base64 files on TARGET _BLANK FOR SOME REASON.
  const win = window.open(null, "_blank");
  win.document.write(
    `<iframe src="${pdf}" frameborder="0" style="margin:0,border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
  );
};

export const inputNumberFormatter = currency => ({
  formatter: (value = "") => {
    if (value.toString().split(".")[0].length <= 3)
      return value ? `${currency} ${value}` : value;
    else
      return value
        ? `${currency} ${value}`
            .replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            .replace(/,/g, "")
        : value;
  },
  parser: value =>
    value
      .replace(new RegExp(`${currency}\\s?|(\\'*)`, "g"), "")
      .replace(/,/g, "")
});

export const goBack = history => () => history.goBack();

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF".split("");
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const checkIfMobileOrTablet = () => {
  let check = false;
  const agent = navigator.userAgent || navigator.vendor || window.opera;
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    agent
  );
  const isMobileOne = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
    agent
  );
  const isMobileTwo = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
    agent.substr(0, 4)
  );
  if (isMobileOne || isMobileTwo || isTablet) {
    check = true;
  }
  return check;
};

export const sortByDate = data => {
  return data.sort((a, b) => {
    const synA = a?.investments.sort((invA, invB) => {
      new Date(invB.createdAt) - new Date(invA.createdAt);
    });
    const synB = b?.investments.sort((invA, invB) => {
      new Date(invB.createdAt) - new Date(invA.createdAt);
    });
    return new Date(synB[0]?.createdAt) - new Date(synA[0]?.createdAt);
  });
};

export const removeUndefinedFromObject = object => {
  return JSON.parse(JSON.stringify(object));
};

export const generateUUID = () => {
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "rc-upload-xxxxxxxxxxxxx-x".replace(/[xy]/g, function(c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const googleAndLinkedInTracker = () => {
  gtag_report_conversion();
  window.lintrk("track", { conversion_id: 8114161 });
};

export const clearAdvertisingCookies = () => {
  const cookies = document.cookie.split(";");
  cookies.forEach(cookie => {
    const cookieParts = cookie.split("=");
    const cookieName = cookieParts[0].trim();

    if (
      cookieName !== "updateTimestamp" &&
      cookieName !== "cookie-policy-consent"
    ) {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  });
};
