import { List } from "antd";
import format from "date-fns/format";
import PropTypes from "prop-types";
import { VAT_NEW_VALUE, VAT_VALUE } from "#constants/deal";
import { formatNumber } from "../../../../../../helpers/number_format";

const FinalStep = ({
  name,
  bankDetails,
  commitData,
  currency,
  administrationFee,
  setupFee,
  adjustedAmount,
  vat,
  new_vat,
  investorName
}) => {
  const { iban, bic, accountHolder } = bankDetails;
  const { deadline } = commitData;
  let vatValue = 0;

  if (new_vat) vatValue = Number(setupFee + administrationFee) * VAT_NEW_VALUE;
  else if (vat) vatValue = Number(setupFee + administrationFee) * VAT_VALUE;

  const totalAdministrationFee = Number(
    adjustedAmount * administrationFee
  ).toFixed(2);
  const totalSetupFee = Number(adjustedAmount * setupFee).toFixed(2);
  const totalVatValue = Number(adjustedAmount * vatValue).toFixed(2);

  const totalToPay =
    Number(adjustedAmount) +
    Number(totalAdministrationFee) +
    Number(totalSetupFee) +
    Number(totalVatValue);

  const bankInfo = [
    {
      label: "Beneficiary:",
      info: accountHolder
    },
    {
      label: "IBAN:",
      info: iban
    },
    {
      label: "BIC:",
      info: bic
    },
    {
      label: "Payment reason: ",
      info: `Investment in ${name} (LILALI2X - for further credit to LINSCH23)`
    }
  ];

  return (
    <div className="summary">
      <div className="congrats">Congratulations!</div>
      <div className="success">You successfully commited into {name}</div>
      <div className="check-email">
        Please check your emails. We just sent you a confirmation email with the
        next steps. To complete your investment please wire{" "}
        <span className="inv-date">{formatNumber(totalToPay, currency)}</span>{" "}
        until <span className="inv-date">{format(deadline, "DD/MM/YYYY")}</span>{" "}
        to the following bank account.
      </div>
      <div className="bank-account-info">
        <List
          className="bank-list"
          itemLayout="horizontal"
          dataSource={bankInfo}
          renderItem={({ label, info }) => (
            <List.Item className="bank-info-item">
              <div className="label">{label}</div>
              <div className="info">{info}</div>
            </List.Item>
          )}
        />
      </div>
      <h3 className="attention">ATTENTION: READ CAREFULLY!</h3>
      <h4 className="attention-details">
        Make sure to wire the funds from an account in the name of{" "}
        {investorName} or the transfer will not be accepted. Make sure to cover
        any transfer or exchange rate fee (if any): we must receive the right
        amount of funds in the pooling account or you'll be asked to wire the
        difference. <br />
        Use your personal account. For Joint Accounts please contact
        compliance@levamail.com. If first transaction, avoid TransferWise,
        Revolut or non-traditional bank accounts.
      </h4>
    </div>
  );
};

FinalStep.propTypes = {
  name: PropTypes.string,
  bankDetails: PropTypes.object,
  commitData: PropTypes.object,
  currency: PropTypes.string,
  administrationFee: PropTypes.number,
  setupFee: PropTypes.number
};

export default FinalStep;
