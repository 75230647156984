export default {
  GET_SHARE_CLASSES: 'GET_SHARE_CLASSES',
  GET_ENTITY_CAPTABLE: 'GET_ENTITY_CAPTABLE',
  GET_ENTITY_SHAREHOLDERS: 'GET_ENTITY_SHAREHOLDERS',
  POST_SHARE_CLASSES: 'POST_SHARE_CLASSES',
  POST_SHAREHOLDERS: 'POST_SHAREHOLDERS',
  DELETE_SHARE_CLASSES: 'DELETE_SHARE_CLASSES',
  GET_SHARE_CLASSES_BY_ID: 'GET_SHARE_CLASSES_BY_ID',
  UPDATE_SHARE_CLASSES_BY_ID: 'UPDATE_SHARE_CLASSES_BY_ID',
  UPDATE_SHAREHOLDER_BY_ID: 'UPDATE_SHAREHOLDER_BY_ID',
  DELETE_SHAREHOLDER: 'DELETE_SHAREHOLDER',
};
