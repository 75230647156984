import { useEffect } from "react";
import { globalChangeHandler } from "#helpers/inputHandlers";
import { useParams } from "react-router";
import investmentAgreementValidationSchema from "#validators/equity/investmentAgreement";
import { useInterval } from "#helpers/hooks/interval";
import { saveForm } from "#helpers/mappers/form/storage";
import { FormStepper, BasicFormWrapper } from "../../../Shared/Form";
import getFormList from "./list";
import sections from "./sections";
import {
  handleInputChange,
  handleInputInArray,
  handleCheckboxInArray
} from "../../common/formHandler";
import CustomInputRenderer from "../../common/Inputs/CustomInputRenderer";
import { EquityCreationSuccess } from "../../common/successViews";
import { mapEquityDataToReadableFormVals } from "#helpers/mappers/equity";

const IaFormPresentational = ({
  values = {},
  handleUpload,
  handleChange,
  setFieldValue,
  buttonStates,
  handleSubmit,
  memberships,
  shareholdersColl,
  ioColl,
  setValues,
  setFieldError,
  errors,
  entityId,
  ioMemberships,
  commits = [],
  userId
}) => {
  const { id } = useParams();
  if (id) {
    useInterval(
      () => saveForm({ userId, entityId, values }),
      !buttonStates.success ? 5000 : null
    );
  }

  let selectedConversionInvestor = [];
  if (ioColl.data && ioColl.data.termSheet) {
    selectedConversionInvestor = ioColl.data.termSheet.conversionInvestors.map(
      ({ membershipId }) => membershipId
    );
  }

  useEffect(() => {
    if (shareholdersColl.didFetch) {
      const { data } = shareholdersColl;

      const formattedShareholders = data.map(
        ({ user, entityMembershipId }) => ({
          ...user,
          entityMembershipId,
          newShares: 0,
          investedAmount: 0
        })
      );

      const allFormattedShareholders = formattedShareholders.map(
        shareholder => {
          if (selectedConversionInvestor.length > 0) {
            const shareholderRecord = selectedConversionInvestor.find(
              conversionInvestor =>
                conversionInvestor === shareholder.entityMembershipId
            );
            if (shareholderRecord) {
              return {
                ...shareholder,
                selected: true,
                fullyPaidUp: true
              };
            }
          }
          return {
            ...shareholder,
            selected: false,
            fullyPaidUp: true
          };
        }
      );
      const formattedMemberships = memberships
        .filter(
          ({ isDirector, role, representativeOf }) =>
            representativeOf !== "board_observer" &&
            (role === "director" || isDirector)
        )
        .map(({ cuid, user, ...data }) => ({
          ...data,
          membershipId: cuid,
          cuid,
          key: cuid,
          ...user
        }));

      const formattedInvestors = memberships
        .filter((membership = {}) => {
          if (
            membership.representativeOf === "board_observer" &&
            membership.role === "director"
          ) {
            return false;
          }
          return membership;
        })
        .map(({ user = {}, ...item }) => ({
          ...user,
          ...item,
          member: false,
          userCuid: user.cuid
        }));

      const { iaAndSha: readableIaAndSha } = mapEquityDataToReadableFormVals(
        ioColl.data,
        {
          iaAndSha: true
        }
      );
      const { directors = [] } = readableIaAndSha || {};
      const readableMemberships = formattedMemberships.map(director => {
        const existingDirector = directors.find(
          ({ membershipId }) => membershipId === director.membershipId
        );
        if (existingDirector) {
          return {
            ...director,
            PoA: true
          };
        }
        return { ...director };
      });
      let readableInvestors = [];
      if (
        Array.isArray(allFormattedShareholders) &&
        allFormattedShareholders.length > 0 &&
        ioMemberships &&
        commits
      ) {
        readableInvestors = allFormattedShareholders.map(investor => {
          const existingInvestor = ioMemberships.find(
            ({ investor: { cuid = "" } = {} }) => cuid === investor.cuid
          );
          if (existingInvestor) {
            const existingInvestorAmount = commits.find(
              ({ committerId = "", ioId = "" }) =>
                existingInvestor.investor.cuid === committerId && ioId === id
            );
            return {
              ...investor,
              selected: true,
              amount: existingInvestorAmount
                ? existingInvestorAmount.amount
                : ""
            };
          }
          return { ...investor };
        });
      }

      setValues({
        ...readableIaAndSha,
        signingDirector:
          Object.keys(readableIaAndSha).length > 0
            ? readableMemberships
            : formattedMemberships,
        investorsOverview:
          Object.keys(readableIaAndSha).length > 0
            ? readableInvestors
            : allFormattedShareholders,
        investorsWrittenConsent: formattedInvestors
      });
    }
  }, [shareholdersColl.didFetch, memberships]);

  const composedInputHandlers = {
    switchHandler: globalChangeHandler(setFieldValue, values, "switch"),
    checkboxHandler: globalChangeHandler(setFieldValue, values, "checkbox"),
    checkboxInArray: handleCheckboxInArray(setFieldValue, values),
    inputInArray: handleInputInArray(setFieldValue, values),
    radioHandler: handleChange
  };

  const formListArgs = {
    values,
    inputHandlers: composedInputHandlers,
    selectedConversionInvestor
  };

  const formArgs = {
    list: getFormList(formListArgs),
    sections,
    values,
    inputEvents: {
      onChange: type =>
        handleInputChange({ handleChange, setFieldValue, values })(type),
      onUpload: handleUpload(setFieldValue)
    },
    clickableSection: true,
    handleSubmit,
    buttonStates,
    CustomInputRenderer,
    success: buttonStates.success,
    CustomSuccess: EquityCreationSuccess({
      type: "Investment Agreement"
    }),
    customClassName: "investment-agreement-container",
    validationSchema: investmentAgreementValidationSchema,
    setFieldError,
    errors
  };

  return <FormStepper {...formArgs} />;
};

export default BasicFormWrapper(IaFormPresentational);
