import { connect } from "react-redux";

import { getEntities, setEntity } from "#core/modules/entities/actions";
import { fetchAllIos } from "#core/modules/io/actions";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => ({
  entitiesList:
    (state.entities._data && state.entities._data.entitiesList) || [],
  entitiesDidFetch: state.entities.didFetch,
  entity: (state.entities._data && state.entities._data.entity) || {},
  ios: (state.io._data && state.io._data.ios) || [],
  userHasAcceptedTerms: state.users._data && state.users._data.tosAccepted,
  userCanCreateCla: state.users._data && state.users._data.isActive
});

const mapDispatchToProps = dispatch => ({
  getEntities: () => dispatch(getEntities()),
  fetchAllIos: data => dispatch(fetchAllIos(data)),
  clearState: key => clearState(dispatch, key),
  setEntity: data => dispatch(setEntity(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
