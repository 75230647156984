const AddMembersIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 81 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M74.5 1H6.5C3.46243 1 1 3.46243 1 6.5V74.5C1 77.5376 3.46243 80 6.5 80H74.5C77.5376 80 80 77.5376 80 74.5V6.5C80 3.46243 77.5376 1 74.5 1Z"
      stroke="black"
    />
    <path
      d="M51.0286 28.8731C51.4148 30.4506 51.498 32.1919 51.2858 34.0454V34.0455C51.2166 34.6484 51.1181 35.3225 50.9794 36.0362C52.1404 37.08 53.6318 37.7341 55.5624 37.734C58.3779 37.7338 60.2857 36.3392 61.5821 34.3199C62.8927 32.2787 63.5645 29.6115 63.8446 27.1652C64.1853 24.1899 63.6442 21.652 62.287 19.8698C60.9407 18.1019 58.7323 17 55.5624 17C53.0708 17 51.1775 17.6821 49.8325 18.8219C48.5572 19.9027 47.7337 21.4298 47.3799 23.2844C47.9119 23.6642 48.4005 24.0984 48.8381 24.589L51.0286 28.8731ZM51.0286 28.8731C50.6213 27.21 49.8872 25.765 48.8381 24.589L51.0286 28.8731Z"
      stroke="black"
    />
    <path
      d="M71.8952 52.6298C71.8295 50.7029 71.6035 48.767 71.2671 46.8698C70.8601 44.5718 70.3413 41.2025 68.3349 39.7048C67.1746 38.8391 65.6721 38.5549 64.344 38.0329C63.6973 37.7788 63.1181 37.5265 62.5747 37.2393C60.7399 39.2513 58.3476 40.3036 55.5619 40.3038C53.3942 40.3038 51.4661 39.6634 49.8522 38.432C49.4807 39.5813 48.9833 40.7386 48.3184 41.796C48.9024 42.1477 49.534 42.4285 50.3015 42.7301C50.6412 42.8637 51.0142 42.9856 51.4092 43.1146C52.4769 43.4635 53.6868 43.859 54.7731 44.67C57.3556 46.5977 57.9913 50.2099 58.4555 52.8476L58.5055 53.1323C58.7456 54.4848 58.9219 55.8136 59.0353 57.1046C61.0964 56.8934 63.1469 56.5326 65.1575 56.1081C66.7828 55.765 68.3971 55.3464 69.9606 54.7823C71.2095 54.3318 71.9465 54.1224 71.8952 52.6298Z"
      fill="#15B982"
    />
    <path
      d="M30.1119 34.0456C29.8997 32.1921 29.983 30.4507 30.3691 28.8733C30.7763 27.2104 31.5105 25.7651 32.5596 24.5892C32.9971 24.0985 33.4857 23.6642 34.0176 23.2844C33.6638 21.4298 32.8403 19.9027 31.565 18.8219C30.22 17.6821 28.3267 17 25.8351 17C22.6653 17 20.4569 18.1019 19.1105 19.8698C17.7533 21.6521 17.2121 24.19 17.5528 27.1652C17.8331 29.6116 18.505 32.2788 19.8156 34.3201C21.1121 36.3393 23.0199 37.7339 25.8355 37.7341C27.7659 37.7343 29.2572 37.0802 30.4183 36.0363M30.1119 34.0456C30.1811 34.6485 30.2796 35.3226 30.4183 36.0363M30.1119 34.0456L30.4183 36.0363"
      stroke="black"
    />
    <path
      d="M26.6245 44.67C27.7108 43.859 28.921 43.4635 29.9885 43.1146C30.3835 42.9856 30.7563 42.8637 31.0961 42.7301C31.8636 42.4287 32.4955 42.1479 33.0793 41.796C32.4145 40.7388 31.917 39.5813 31.5455 38.432C29.9317 39.6634 28.0035 40.3038 25.8359 40.3038C23.0502 40.3037 20.6577 39.2512 18.8231 37.2393C18.2797 37.5265 17.7003 37.7787 17.0537 38.0329C15.7256 38.5549 14.2231 38.8391 13.0628 39.7048C11.0565 41.2026 10.5377 44.5717 10.1307 46.8698C9.7942 48.7669 9.56826 50.7028 9.5025 52.6298C9.45132 54.1225 10.1883 54.3318 11.437 54.7823C13.0004 55.3463 14.615 55.7649 16.2401 56.1081C18.2507 56.5327 20.3013 56.8936 22.3623 57.1046C22.4757 55.8136 22.652 54.485 22.8922 53.1323L22.9421 52.8476C23.4064 50.21 24.042 46.5977 26.6245 44.67Z"
      fill="#15B982"
    />
    <path
      d="M57.4557 53.3128L57.4054 53.0268C56.9713 50.5598 56.3766 47.1812 54.1233 45.4991C53.173 44.7898 52.0972 44.4384 51.0567 44.0983C50.6469 43.9644 50.2602 43.8379 49.8916 43.6931C49.3544 43.482 48.8704 43.2754 48.4169 43.0469C46.5119 45.5889 43.8717 46.9272 40.7311 46.9276C37.5913 46.9276 34.9515 45.5891 33.0465 43.0469C32.5929 43.2756 32.1089 43.4821 31.5715 43.6933C31.2034 43.8379 30.8164 43.9644 30.4068 44.0983C29.3661 44.4383 28.29 44.7899 27.34 45.4991C25.086 47.1814 24.4917 50.5608 24.0577 53.0282L24.0074 53.3132C23.6448 55.3611 23.4283 57.3478 23.3643 59.2179C23.2892 61.4119 24.651 61.901 25.8524 62.3327L25.9817 62.3793C27.4265 62.9005 29.0426 63.3471 30.9223 63.7443C34.7605 64.5544 37.8754 64.9413 40.7242 64.9614H40.7315H40.7388C43.5881 64.9412 46.7028 64.5544 50.5405 63.7443C52.4204 63.3473 54.0364 62.9007 55.4806 62.3794L55.6098 62.3329C56.8115 61.9013 58.1737 61.4123 58.0986 59.2178C58.0347 57.3427 57.8181 55.3562 57.4557 53.3128Z"
      fill="#15B982"
    />
    <path
      d="M32.5301 34.2518V34.2517C32.1889 31.2764 32.7303 28.7386 34.0877 26.9564C35.4343 25.1885 37.6429 24.0868 40.8121 24.0869C43.9818 24.0869 46.1902 25.1888 47.5366 26.9566C48.8939 28.7388 49.4351 31.2766 49.0944 34.2518C48.8143 36.6983 48.1425 39.3655 46.8319 41.4067C45.5355 43.4259 43.6277 44.8205 40.8121 44.8206C37.9965 44.8208 36.0889 43.4263 34.7925 41.407C33.482 39.3657 32.8102 36.6983 32.5301 34.2518Z"
      stroke="black"
    />
  </svg>
);

export default AddMembersIcon;
