const formSections = [
  {
    id: 1,
    title: "Choose your type of voting"
  },
  {
    id: 2,
    title: "Supporting documents"
  },
  {
    id: 3,
    title: "Pre-compiled questionnaire"
  },
  {
    id: 4,
    title: "Add your questions"
  },
  {
    id: 5,
    title: "Double-check questionnaire"
  },
  {
    id: 6,
    title: "M-Tan verification"
  }
];

export default formSections;
