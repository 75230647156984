import commitedConfirmed from "../../../../assets/invited-investors/commited-confirmed.png";

const CommitedConfirmed = () => (
  <img
    src={commitedConfirmed}
    width="30"
    height="30"
    alt="commited-confirmed"
  />
);

export default CommitedConfirmed;
