const ClaDescription = () => {
  return (
    <div className="equity-description">
      <span>
        A convertible loan is a loan which will either be repaid or, in most
        cases, convert into equity at a future date. These loans represent a
        form of financing which ordinarily takes less time than an equity
        funding round. The convertible loan is governed by the underlying
        agreement, which you can set-up by following our step-by-step process.
        You can use our convenient and founder-friendly solution to create your
        convertible loan agreement and raise your financing round. If you are
        unsure about any point, please confer with your lawyer or get in touch
        with us, as these terms are important for your company’s future. You can
        return at any point to this site and modify the documents if needed.
      </span>
    </div>
  );
};

export default ClaDescription;
