import * as Yup from "yup";
import { returnError } from "../common";

Yup.addMethod(Yup.array, "checkIfTwo", function(message, mapper = a => a) {
  return this.test("checkIfTwo", message, function(list) {
    const singleDirector = list.filter(
      ({ signingDirector, signingAuthority }) =>
        signingDirector && signingAuthority === "single"
    );
    const directors = list.filter(
      ({ signingDirector, signingAuthority }) =>
        signingDirector && signingAuthority === "collective"
    );
    if (singleDirector.length > 0 && directors.length > 0) {
      return true;
    } else if (singleDirector.length > 0) {
      return true;
    } else if (directors.length > 1) {
      return true;
    }
    return false;
  });
});

const convertibleSchema = Yup.object().shape({
  affiliateAssign: Yup.string(),
  boardConsentConfidentiality: Yup.string(),
  claPaymentDeadlineInDays: Yup.string(),
  esopEffectsIncludedNFR: Yup.string(),
  fullyDilutedNFR: Yup.string(),
  preExistingAgreement: Yup.string().required(returnError("required")),
  preExistingDate: Yup.date(),
  uploadAgreementConfirmation: Yup.string(),
  claPurpose: Yup.string().required(returnError("required")),
  claPurposeArea: Yup.string(),
  claSignedBy: Yup.string().required(returnError("required")),
  signingDirector: Yup.array().checkIfTwo(({ originalValue = [] }) => {
    const singleDirector = originalValue.filter(
      ({ signingDirector, signingAuthority }) =>
        signingDirector && signingAuthority === "single"
    );
    const directors = originalValue.filter(
      ({ signingDirector, signingAuthority }) =>
        signingDirector && signingAuthority === "collective"
    );
    if (singleDirector.length === 0 && directors.length === 0) {
      return "Please select the director(s) as asked in the question";
    }
    // else if (singleDirector.length > 0 && directors.length > 0) {
    //   return "You selected a director which has collective signing authority and the one with single signing authority. To be able to continue, please select two directors with the collective signing authority or only one director with single signing authority.";
    // }
    return "You selected a director which has collective signing authority. To be able to continue, please select one more director with the collective signing authority or switch to a director with single signing authority.";
  }),
  sha_upload: Yup.boolean(),
  investorLoanPayout: Yup.string().required(returnError("required")),
  preMoneyValuation: Yup.number().required(returnError("required")),
  includedOutstandingLoans: Yup.string().required(returnError("required")),
  includedOutstandingESOP: Yup.string().required(returnError("required")),
  signingInvestor: Yup.array(),
  fixedAnnualInterestRate: Yup.string().required(returnError("required")),
  interestRate: Yup.string(),
  annualInterestRateCalculated: Yup.string().required(returnError("required")),
  convertedInterest: Yup.string().required(returnError("required")),
  investorLoanPayoutOther: Yup.string()
});

export default convertibleSchema;
