import { useState } from "react";
import Modal from "../../Modal";
import Radio from "../../Radio";
import Input from "../../Input";
import { FormBtnContainer } from "../../Button";

const EmailSender = ({ onCancel, ...props }) => {
  const [selectedOpt, setSelectedOpt] = useState("");

  const handleSelection = ({ target: { value } }) => setSelectedOpt(value);

  const options = [
    {
      value: "kyc",
      label: "Send a KYC friendly reminder"
    },
    {
      value: "commit",
      label: "Send a commitment reminder"
    },
    {
      label: "Write a custom message",
      value: "custom"
    }
  ];

  return (
    <Modal
      title="Send an email to your investor(s)"
      footer={null}
      onCancel={onCancel}
      {...props}
    >
      <div className="email-sender-wrapper">
        <h1>Please select one of these options:</h1>
        <div className="options">
          {options.map(({ value, label }) => (
            <div key={value} className="option-container">
              <Radio
                value={value}
                label={label}
                onChange={handleSelection}
                checked={value === selectedOpt}
              />
            </div>
          ))}
        </div>
        {selectedOpt === "custom" && (
          <div className="textarea">
            <span>Please write your custom message here:</span>
            <Input type="textarea" rows={3} />
          </div>
        )}
        <FormBtnContainer onCancel={onCancel} />
      </div>
    </Modal>
  );
};

export default EmailSender;
