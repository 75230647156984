import { equityTs } from "#constants/io/equityPool";
import * as fields from "./fields";
import { indexItem } from "../../common/prompts";

export const termsheetList = (
  values = {},
  currencies = [],
  formHandlers = {},
  shareNames,
  reverseFounderVesting,
  equityCuid
) => {
  const {
    general: generalFields,
    financials: [generalInformation, financialTarget, valuation],
    shareInfo: [shareInfo],
    directorsBoard: [board, power],
    shareholderMatter,
    investmentInfo: invInfoFields,
    investors,
    vesting,
    esop
  } = fields;

  const { shortName: selectedCurrency = "" } =
    currencies.find(({ cuid }) => cuid === values[equityTs.CURRENCY]) || {};

  const indexedGeneralFields = generalFields(values, formHandlers)
    .filter(Boolean)
    .map(indexItem);
  const indexedGeneralInformation = generalInformation(values)
    .filter(Boolean)
    .map(indexItem);
  const indexedFinancialTarget = financialTarget(
    values,
    currencies,
    selectedCurrency
  )
    .filter(Boolean)
    .map(indexItem);
  const indexedValuation = valuation(values, selectedCurrency, formHandlers)
    .filter(Boolean)
    .map(indexItem);
  const indexedShareInfo = shareInfo(values, shareNames)
    .filter(Boolean)
    .map(indexItem);
  const indexedBoard = board(values, formHandlers)
    .filter(Boolean)
    .map(indexItem);
  const indexedBoardPower = power(values, selectedCurrency)
    .filter(Boolean)
    .map(indexItem);
  const indexedShareMatter = shareholderMatter(values)
    .filter(Boolean)
    .map(indexItem);
  const indexedInfoFields = invInfoFields(values)
    .filter(Boolean)
    .map(indexItem);

  const indexedInvestors = investors(values, formHandlers)
    .filter(Boolean)
    .map(indexItem);

  const indexedVesting = vesting(values, reverseFounderVesting, equityCuid)
    .filter(Boolean)
    .map(indexItem);

  const indexedEsop = esop(values, formHandlers)
    .filter(Boolean)
    .map(indexItem);

  const list = [
    {
      sectionId: 1,
      items: indexedGeneralFields,
      stepTitle: `Welcome to your new best "legal" buddy. Currently Leva is suited for early stage funding rounds. If you are raising your pre-Seed or Seed round, this is the right place to be. In case you are raising a Series A or later round, please reach out to X, our trusted partner and one of the leading law firms for venture capital in Switzerland.`
    },
    {
      sectionId: 2,
      subSectionId: 2,
      items: indexedGeneralInformation
    },
    {
      sectionId: 2,
      subSectionId: 3,
      items: indexedFinancialTarget
    },
    {
      sectionId: 2,
      subSectionId: 4,
      items: indexedValuation
    },
    {
      sectionId: 5,
      items: indexedEsop
    },
    {
      sectionId: 6,
      items: indexedShareInfo
    },
    {
      sectionId: 7,
      subSectionId: 7,
      items: indexedBoard
    },
    {
      sectionId: 7,
      subSectionId: 8,
      items: indexedBoardPower
    },
    {
      sectionId: 9,
      items: indexedShareMatter
    },
    {
      sectionId: 10,
      items: indexedVesting
    },
    {
      sectionId: 11,
      items: indexedInfoFields
    },
    {
      sectionId: 12,
      items: indexedInvestors
    }
  ];

  const indexedList = list.map(indexItem);

  return indexedList;
};

export default termsheetList;
