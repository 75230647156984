import * as syndicateService from "../../../../api/syndicateService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const createSyndicate = data => {
  return async dispatch => {
    try {
      start(dispatch, types.CREATE_SYNDICATE);
      const response = await syndicateService.postSyndicate(data);

      success(dispatch, types.CREATE_SYNDICATE, {
        data: response.data
      });

      return response.data;
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.CREATE_SYNDICATE, {
        error: errorData
      });
    }
  };
};
