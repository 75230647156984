import { useEffect, useState, Fragment } from "react";

import { roles } from "#constants/roles";
import SharedTable from "../../../../SharedTable/Presentational";
import { listViewColumns } from "./columns";
import { Button } from "../../../../Button";
const List = ({
  entityId,
  ioId,
  getIoMemberships,
  getAllEntityMemberships,
  bulkCreateIoMemberships,
  ioMemberships,
  entityMemberships,
  entitiesFetched,
  buttonStates,
  membershipsLoading,
  clearState,
  bulkCreateEntityMembership,
  ioMembershipFetched,
  visible,
  userRole,
  handleClose,
  handleStateUpdate,
  onboardInviteBtn
}) => {
  const [aggregatedData, setAggregatedData] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({
    columnKey: "",
    order: "ascend"
  });
  useEffect(() => {
    if (visible) {
      clearState("ioMemberships");
      clearState("entities");
      getAllEntityMemberships();
      if (ioId) {
        getIoMemberships({ io_id: ioId });
      }
    }
  }, [visible]);

  useEffect(() => {
    if (buttonStates.success) {
      setTimeout(() => {
        clearState("ioMemberships");
        handleClose();
      }, 3000);
    }
  }, [buttonStates]);

  useEffect(() => {
    if (entitiesFetched && ioMembershipFetched) {
      // THIS MODAL IS MEANT TO BE USED IN EITHER DEAL OR ENTITY LEVEL. THUS, DIFFERENT FILTERING LOGICS NEED TO BE APPLIED

      const arr = [];

      entityMemberships.map(({ role, user = {} }) => {
        const isMember = ioMemberships.find(
          ({ userId }) => userId === user.cuid
        );
        const isAdded = arr.find(({ cuid }) => cuid === user.cuid);

        const allowedRole =
          role === roles.AM_INVESTOR || role === roles.INVESTOR;

        if (!isMember && !isAdded && allowedRole) {
          arr.push({ ...user, key: user.cuid });
        }
      });
      setAggregatedData(arr);
      if (arr.length === 0) {
        handleStateUpdate("view", 2)();
      }
    }
  }, [entitiesFetched, ioMembershipFetched]);

  const handleTotalRowsSelection = selected =>
    setAggregatedData(
      aggregatedData.map(item => {
        return {
          ...item,
          checked: selected
        };
      })
    );

  const handleRowSelection = (record, selected) => {
    setAggregatedData(
      aggregatedData.map(item => {
        if (item.cuid === record.cuid) {
          return {
            ...item,
            checked: selected
          };
        }

        return { ...item };
      })
    );
  };

  const handleInvite = () => {
    const filteredData = aggregatedData.filter(({ checked }) => checked);
    const avoidNotification = userRole === roles.FOUNDER;

    const payload = filteredData.map(item => ({
      ioId,
      entityMembershipRole: roles.AM_INVESTOR,
      userFirstName: item.firstName,
      userLastName: item.lastName,
      userEmail: item.email,
      redirectUrlForNewUsers: `${process.env.HOST}/onboarding`,
      redirectUrlForExistingUsers: `${process.env.HOST}/login?redirectTo=invitations`,
      avoidNotification
    }));
    if (filteredData.length > 0) {
      bulkCreateIoMemberships(payload);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo({
      columnKey: sorter.columnKey,
      order: sorter.order
    });
  };

  return (
    <div className="add-member-table-wrapper">
      <SharedTable
        columns={listViewColumns({
          sortedInfo
        })}
        dataSource={aggregatedData}
        tableProps={{
          onChange: handleChange,
          rowSelection: {
            type: "checkbox",
            onSelect: handleRowSelection,
            onSelectAll: handleTotalRowsSelection
          },
          pagination: {
            defaultPageSize: 8,
            pageSizeOptions: ["8", "10", "25", "50"],
            showSizeChanger: true
          },
          // pagination: aggregatedData.length > 10,
          loading: membershipsLoading
        }}
      />
      <div className="btn-container">
        <Button {...onboardInviteBtn} type="ghost" />
        <Button
          label="Send invitations"
          onClick={handleInvite}
          disabled={
            aggregatedData.filter(({ checked }) => checked).length === 0
          }
          {...buttonStates}
        />
      </div>
    </div>
  );
};

export default List;
