import { Upload, Icon } from "antd";

const DraggerUploader = ({
  name,
  onUpload,
  accept,
  multiple,
  showUploadList,
  ...props
}) => {
  const { Dragger } = Upload;
  const args = {
    ...props,
    name,
    accept,
    multiple,
    showUploadList,

    onChange: info => onUpload(name, info, { accept, multiple })
  };

  const dummyRequest = ({ file, onSuccess }) => {
    // WORKAROUND TO DISABLE UPLOADER'S DEFAULT ACTION ON FILE UPLOAD WHICH WILL FAIL SINCE WE HAVENT SET IT UP THE WAY IT EXPECTS
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Dragger {...args} customRequest={dummyRequest}>
      <p className="ant-upload-drag-icon">
        <Icon type="upload" />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </Dragger>
  );
};

export default DraggerUploader;
