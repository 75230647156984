import { List } from "antd";

const QuestionsPreview = ({ values: { assembly_questions = [] } = {} }) => {
  return (
    <List className="questions-list">
      {assembly_questions.map(({ question, index }) => {
        return (
          <List.Item key={index}>
            <List.Item.Meta title={question} />
          </List.Item>
        );
      })}
    </List>
  );
};

export default QuestionsPreview;
