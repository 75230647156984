import { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";
import { normalizeCollection } from "#helpers/normalize";
import { roles } from "#constants/roles";
import { entityTypes } from "#constants/entity";
import { closedValues } from "#constants/io/closed";
import { userPanels, sharedItems } from "../MenuItems";
import { TermsAgreementModal, UpdatesModal } from "../../Modals";
import AppDrawerHeader from "../MenuHeader/Presentational";
import Logo from "../../Logo";
import { MenuTitle, Submenu } from "./components";

class SideDrawer extends Component {
  state = {
    currPathname: this.props.location.pathname,
    termsModal: false
  };

  componentDidMount() {
    const {
      clearState,
      getEntities,
      fetchAllIos,
      history,
      entity: { cuid: entityId, userRole },
      userHasAcceptedTerms
    } = this.props;

    clearState("entities");
    getEntities();

    if (userRole === roles.ASSET_MANAGER || userRole === roles.FOUNDER) {
      fetchAllIos(entityId);
    }

    if (!userHasAcceptedTerms) {
      this.setState({
        termsModal: true
      });
    }

    history.listen(this.updateLocation);
  }

  componentDidUpdate(prevProps) {
    const {
      entitiesDidFetch,
      entitiesList,
      fetchAllIos,
      entity: { cuid: entityCuid, ...entity }
    } = this.props;

    const prevEntityCuid = prevProps.entity && prevProps.entity.cuid;

    if (
      entitiesDidFetch &&
      entitiesDidFetch !== prevProps.entitiesDidFetch &&
      Object.keys(entity).length === 0 &&
      entitiesList.length > 0
    ) {
      const targetEntity = entitiesList.find(
        ({ type, membership: { status } = {} }) =>
          type === entityTypes.TARGET_ENTITY && status === 1
      );

      const { type, membership: { status: membershipStatus } = {} } =
        entitiesList[0] || {};

      const isEligibleFirstEntity =
        type === entityTypes.TARGET_ENTITY && membershipStatus === 1;

      // used to automatically select the first entity as selected
      if (!isEligibleFirstEntity && targetEntity) {
        this.props.setEntity(targetEntity.cuid);
      } else {
        this.props.setEntity(entitiesList[0].cuid);
      }
    }

    if (entityCuid && entityCuid !== prevEntityCuid) {
      if (
        entity.userRole !== roles.INVESTOR &&
        entity.userRole !== roles.AM_INVESTOR
      ) {
        fetchAllIos(entityCuid);
      }
    }
  }

  updateLocation = location => {
    const { currPathname } = this.state;

    if (currPathname !== location.pathname) {
      this.setState({ currPathname: location.pathname });
    }
  };

  toggleModal = modalKey => () => {
    this.setState(state => ({
      [modalKey]: !state[modalKey]
    }));
  };

  handleSetEntity = cuid => () => {
    this.props.history.push("/dashboard/deal_management");
    this.props.setEntity(cuid);
  };

  handleSetIo = (_, { cuid, cla: { onlyCLA = false } = {} }) => () => {
    if (onlyCLA) {
      this.props.history.push(`/cla/overview/${cuid}`);
    } else {
      this.props.history.push(`/cla-ts/overview/${cuid}`);
    }
  };

  renderMenuItems = () => {
    const { currPathname } = this.state;
    const { entitiesList, ios, entity, history, userCanCreateCla } = this.props;
    const { userRole, type } = entity;
    const normalizePairs = (pairs = {}) => ({
      id: "cuid",
      title: "name",
      icon: "previewImage",
      ...pairs
    });

    const finalRole =
      type === entityTypes.ASSET_MANAGER_ENTITY && userRole === "founder"
        ? roles.ASSET_MANAGER
        : userRole;

    const normalizedIos =
      normalizeCollection(
        ios.filter(({ cla = {} }) => Object.keys(cla).length > 0),
        ({ cuid, cla: { onlyCLA } }) => ({
          ...normalizePairs({
            link: "/cla/overview/",
            id: onlyCLA ? `/cla/overview/${cuid}` : `/cla-ts/overview/${cuid}`
          })
        })
      ) || [];

    const collections = {
      entities: {
        data: normalizeCollection(
          entitiesList.filter(
            ({ type, membership: { status } = {} }) =>
              type !== entityTypes.ASSET_MANAGER_ENTITY && status === 1
          ),
          normalizePairs({
            link: "/dashboard/deal_management/",
            showIcon: true
          })
        ),
        itemClickHandler: this.handleSetEntity
      },
      opened_ios: {
        data: normalizedIos.filter(
          ({ closed }) => closed < closedValues.CLOSED_BY_FOUNDER
        ),
        itemClickHandler: this.handleSetIo,
        enabledActions: userCanCreateCla
          ? ["/cla-ts/overview", "/cla/overview"]
          : ["/cla-ts/overview"]
      },
      closed_ios: {
        data: normalizedIos.filter(
          ({ closed }) => closed >= closedValues.CLOSED_BY_FOUNDER
        ),
        itemClickHandler: this.handleSetIo
      }
    };

    const hasEntities = entitiesList.some(
      ({ type }) => type === entityTypes.TARGET_ENTITY
    );

    return (
      <Menu
        mode="inline"
        id="app-drawer-menu"
        selectedKeys={[entity.cuid, currPathname]}
      >
        {userPanels
          .filter(({ roles }) => roles.includes(finalRole))
          .map(
            ({
              title,
              link,
              id,
              icon,
              submenuCollection,
              submenuActions,
              disabled,
              tooltip
            }) => {
              const containerClassName = `menu-item-container _bold`;
              const collectionDisplay =
                submenuCollection && Array.isArray(submenuCollection.data)
                  ? submenuCollection
                  : collections[submenuCollection];

              const menuArgs = {
                id,
                icon,
                title,
                containerClassName,
                className: "menu-item-full",
                link,
                divider: true,
                disabled,
                tooltip
              };

              const container = <MenuTitle key={id} {...menuArgs} />;

              if (submenuCollection) {
                const submenuArgs = {
                  title: container,
                  collection: collectionDisplay,
                  submenuActions,
                  userRole: finalRole,
                  history,
                  ios,
                  actionDependencies: { hasEntities },
                  entityId: entity.cuid
                };

                return <Submenu key={id} {...submenuArgs} />;
              }

              return container;
            }
          )}
      </Menu>
    );
  };

  render() {
    const { entitiesList, entity, setEntity, history } = this.props;
    const { termsModal } = this.state;
    const isInvestor =
      entity.userRole === roles.AM_INVESTOR ||
      entity.userRole === roles.INVESTOR;

    return (
      <div className="appDrawer-layout" width={256}>
        <TermsAgreementModal
          visible={termsModal}
          onCancel={this.toggleModal("termsModal")}
        />
        <UpdatesModal />
        <div className="inner-wrapper">
          <Logo />
          <div className="type-wrapper">
            <h3>
              {isInvestor ? "Investor interface" : "Deal manager interface"}
            </h3>
          </div>
          {isInvestor && (
            <AppDrawerHeader
              setEntity={setEntity}
              entitiesList={entitiesList}
              entity={entity}
              toggleModal={this.toggleModal}
              history={history}
            />
          )}
          <div className="app-drawerMenu-container">
            {this.renderMenuItems()}
            <div mode="inline" className="app-drawer-menu">
              {sharedItems.map(({ title, classname, icon, link }) => {
                return (
                  <div key={link} className="drawer-menu-item">
                    <Link to={link}>
                      <div className={classname}>
                        <Icon type={icon} className="menu-item-icon" />
                        <span className="menu-item-name">{title}</span>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SideDrawer.propTypes = {
  history: PropTypes.object,
  entity: PropTypes.object,
  entitiesList: PropTypes.array,
  setEntity: PropTypes.func,
  entitiesDidFetch: PropTypes.bool,
  ios: PropTypes.array,
  clearState: PropTypes.func,
  getEntities: PropTypes.func,
  fetchAllIos: PropTypes.func,
  location: PropTypes.object
};

export default withRouter(SideDrawer);
