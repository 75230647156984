import { useEffect } from "react";
import { globalChangeHandler } from "#helpers/inputHandlers";
import claQuestionaireSchema from "#validators/cla/convertible";
import { FormStepper, BasicFormWrapper } from "../../../Shared/Form";
import getFormList from "./list";
import sections from "./sections";
import {
  handleInputChange,
  handleCheckboxInArray,
  handleInputInArray
} from "../../common/formHandler";

import CustomInputRenderer from "../../common/Inputs/CustomInputRenderer";
import CLAQuestionnaireSuccess from "../../common/successViews/ClaTs";
import { setClaQuestValues } from "./handlers";

const ClaFormPresentational = ({
  values = {},
  handleUpload,
  handleChange,
  setFieldValue,
  setFieldError,
  buttonStates,
  handleSubmit,
  memberships,
  shareholdersColl,
  cla,
  errors,
  ioColl,
  setValues,
  esopShares,
  allMemberships,
  formData,
  step,
  saveFormData,
  currPath
}) => {
  useEffect(
    setClaQuestValues({
      ioColl,
      shareholdersColl,
      allMemberships,
      setValues,
      step,
      esopShares,
      formData,
      cla
    }),
    [shareholdersColl.didFetch, allMemberships, esopShares, ioColl.didFetch]
  );

  const handleFormSave = values => () => saveFormData(values);

  const composedInputHandlers = {
    switchHandler: globalChangeHandler(setFieldValue, values, "switch"),
    checkboxHandler: globalChangeHandler(setFieldValue, values, "checkbox"),
    inputInArray: handleInputInArray(setFieldValue, values),
    checkboxInArray: handleCheckboxInArray(setFieldValue, values),
    radioHandler: handleChange
  };

  const formListArgs = {
    values,
    inputHandlers: composedInputHandlers,
    cla,
    handleFormSave,
    currPath
  };

  const formArgs = {
    list: getFormList(formListArgs),
    sections,
    values,
    inputEvents: {
      onChange: type =>
        handleInputChange({ handleChange, setFieldValue, values })(type),
      onUpload: handleUpload(setFieldValue)
    },
    clickableSection: true,
    handleSubmit,
    buttonStates,
    success: buttonStates.success,
    CustomInputRenderer,
    validationSchema: claQuestionaireSchema,
    CustomSuccess: () => CLAQuestionnaireSuccess({ type: "cla" }),
    setFieldError,
    errors,
    step
  };

  return <FormStepper {...formArgs} />;
};

export default BasicFormWrapper(ClaFormPresentational);
