import PropTypes from "prop-types";
import BasicWrapper from "../../../../../Shared/BasicWrapper";
import ModalWrapper from "../../../../../Shared/Modal/";
import TagWrapper from "../../../../../Shared/Tag";

const DealsListModal = ({ deals, ...modalConfig }) => {
  return (
    <ModalWrapper
      title="Deals"
      className="deals-list-modal"
      {...modalConfig}
      footer={null}
    >
      <BasicWrapper className="deals-list">
        {deals.map(({ name, status }) => (
          <TagWrapper color={status === 1 ? "green" : "gray"}>
            {name}
          </TagWrapper>
        ))}
      </BasicWrapper>
    </ModalWrapper>
  );
};

DealsListModal.propTypes = {
  deals: PropTypes.array
};

export default DealsListModal;
