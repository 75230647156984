import types from "../action_types";
import * as userInfoService from "../../../../api/userService";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

const getCurrentUser = () => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_USER_DETAILS);
      const response = await userInfoService.getUserProfile();

      success(dispatch, types.FETCH_USER_DETAILS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_USER_DETAILS, {
        error: errorData
      });
    }
  };
};

export const getSmsVerification = () => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_SMS_VERIFICATION);
      const response = await userInfoService.putSmsVerification();
      success(dispatch, types.GET_SMS_VERIFICATION, {
        data: response.data || {}
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, null, {
        open: true,
        message,
        vertical: "top",
        horizontal: "left",
        variant: "warning"
      });
      fail(dispatch, types.GET_SMS_VERIFICATION, {
        error: errorData
      });
    }
  };
};

export const getSmsVerificationWithNumber = (
  phoneNumber,
  kycPersonalInformation
) => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_SMS_VERIFICATION_NUMBER);
      const payload = {
        kyc: {
          personalInformation: {
            phoneNumber,
            ...kycPersonalInformation
          }
        }
      };

      await userInfoService.updateKycData(payload);
      const response = await userInfoService.putSmsVerification();

      success(dispatch, types.GET_SMS_VERIFICATION_NUMBER, {
        data: response.data || {}
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, null, {
        open: true,
        message,
        vertical: "top",
        horizontal: "left",
        variant: "warning"
      });
      fail(dispatch, types.GET_SMS_VERIFICATION_NUMBER, {
        error: errorData
      });
    }
  };
};

export { getCurrentUser };
