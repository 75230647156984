/* FORMIK SUPPORT FOR SELECTION INPUTS */

/**
 * A curried custom event change handler for selection inputs wrapped with Formik.
 * @param {Function} setFieldValue Formik native func. Used to trigger changes in Formik state.
 * @param {Object} values Formik values.
 * @param {String} type Type of selection input. One of ['checkbox','select'].
 *
 * @returns {Function} Returns another function.
 *
 * @param {String} name Input name. Passed after the event has been intially called.
 *
 * @param {String} value Value changed.
 */

const handleCheckboxChange = (setFieldValue, values, type) => (
  name,
  value
) => () => {
  if (type === 'checkbox') {
    let checkBoxArray = values[name] || [];
    checkBoxArray = checkBoxArray.includes(value)
      ? checkBoxArray.filter((el) => {
          return el !== value;
        })
      : [...checkBoxArray, value];
    setFieldValue(name, checkBoxArray);
  }
  if (type === 'select') {
    setFieldValue(name, value);
  }
};

/**
 * A curried custom event change handler for native selection inputs wrapped with Formik.
 * @param {Function} setFieldValue Formik native func. Used to trigger changes in Formik state.
 *
 * @returns {Function} Returns a function to be used as an onChange handler directly in the input set up.
 */
const handleSelectChange = (setFieldValue) => (name) => (value) => {
  setFieldValue(name, value);
};

const handleCheckChange = (setFieldValue) => ({ target: { name, checked } }) =>
  setFieldValue(name, checked);

const handleSwitchChange = (setFieldValue) => (
  el,
  { target: { name, checked } }
) => setFieldValue(name, !checked);

const globalChangeHandler = (setFieldValue, values, type) => {
  if (type === 'checkbox') {
    return handleCheckboxChange(setFieldValue, values, 'checkbox');
  }

  if (type === 'select') {
    return handleSelectChange(setFieldValue);
  }

  if (type === 'switch') {
    return handleSwitchChange(setFieldValue);
  }
};

const handleNumberChange = (changeHandler, name) => (value) =>
  changeHandler({ target: { name, value } }); // MATCH ANTD INPUT NUMBER CHANGE EVENT TO FORMIK'S CHANGE

const handleUpload = (setState, prevState = {}) => (setFieldValue) => async (
  name,
  info = {},
  opts = {}
) => {
  const { file, fileList } = info;
  let newState = {};

  if (opts.multiple) {
    newState = {
      [name]: fileList.map((item) => item.originFileObj)
    };
  } else {
    newState = {
      [name]: file.originFileObj
    };
  }

  setState({ ...prevState, ...newState });

  if (setFieldValue) {
    setFieldValue(name, true);
  }
};

export {
  handleCheckboxChange,
  handleSelectChange,
  handleCheckChange,
  globalChangeHandler,
  handleNumberChange,
  handleUpload
};
