import { useEffect, useState } from "react";
import Input from "../../../components/Shared/Input";
import { Button } from "../../../components/Shared/Button";

export default function TwoFactorAuth({
  generateTwoFactorAuth,
  auth2fa,
  completeTwoFactorAuth
}) {
  const [twoFaNum, setTwoFaNum] = useState();
  const handleInputChange = e => setTwoFaNum(e.target.value);

  const handleEnableClick = () => {
    completeTwoFactorAuth({ token: twoFaNum.toString() });
  };

  useEffect(() => {
    generateTwoFactorAuth();
  }, []);

  return (
    <div className="two-fa-wrapper">
      <img src={auth2fa} width={200} />
      <div className="two-fa-input-wrapper">
        <Input maxLength={6} type="number" onChange={handleInputChange} />
        <Button
          size="large"
          label="Enable"
          className="enable-btn"
          onClick={handleEnableClick}
        />
      </div>
    </div>
  );
}
