export const getRequestStates = state => {
  const {
    isFetching,
    isCreating,
    isUpdating,
    isDeleting,
    didFetch,
    didCreate,
    didUpdate,
    didDelete
  } = state;

  return {
    isFetching,
    isCreating,
    isUpdating,
    isDeleting,
    didFetch,
    didCreate,
    didUpdate,
    didDelete
  };
};

export const destructureKeys = (data, keys) => {
  return keys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: data[key]
    };
  }, {});
};

export const destructureByKeys = (data, keys) => {
  return Object.keys(data).reduce((acc, item) => {
    if (keys[item]) {
      return {
        ...acc,
        [keys[item]]: data[item]
      };
    } else {
      return {
        ...acc,
        [item]: data[item]
      };
    }
  }, {});
};

export const baseSelector = func => (
  state,
  {
    dataKeys,
    dataKeysTransform,
    dataTransformer,
    requestStatesKeys,
    requestStatesKeysTransform,
    requestStatesTransformer
  } = {}
) => {
  let data = {};

  if (typeof func === "function") {
    data = func(state);
  }

  if (dataKeys) {
    data = destructureKeys(data, dataKeys);
  }

  if (dataKeysTransform) {
    data = destructureByKeys(data, dataKeysTransform);
  }

  if (typeof dataTransformer === "function") {
    data = dataTransformer(data);
  }

  let requestStates = getRequestStates(state);

  if (requestStatesKeys) {
    requestStates = destructureKeys(requestStates, requestStatesKeys);
  }

  if (requestStatesKeysTransform) {
    requestStates = destructureByKeys(
      requestStates,
      requestStatesKeysTransform
    );
  }

  if (typeof requestStatesTransformer === "function") {
    requestStates = requestStatesTransformer(requestStates);
  }

  return [data, requestStates];
};
