import { useState } from "react";
import { Input, Icon } from "antd";
import Highlighter from "react-highlight-words";
import { Button } from "../../components/Shared/Button";

export const useGetColumnSearchProps = ({
  dataIndex,
  render = false,
  onFilter = false
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchInput, setSearchInput] = useState();
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("");
  };

  const localRender = text =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    );

  const localOnFilter = (value, record) =>
    record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : "";

  const finalRender = render ? render : localRender;
  const finalOnFilter = onFilter ? onFilter : localOnFilter;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            setSearchInput(node);
          }}
          placeholder={`Search ${
            dataIndex === "firstName"
              ? "first name"
              : dataIndex === "lastName"
              ? "last name"
              : dataIndex
          }`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          label="Search"
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<Icon type="search" fontSize="16px" />}
          size="small"
          style={{ width: 78, marginRight: "8%" }}
        />

        <Button
          label="Reset"
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 78, color: "#36C792", backgroundColor: "white" }}
        />
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type="search"
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: finalOnFilter,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: finalRender
  };
};
