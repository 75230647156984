import { basicOption, inputNumberFormatter } from "#helpers/general";
import { investmentOpportunityTypes } from "#constants/deal";

export const formList = ({ dealType, currency }) => {
  if (dealType === investmentOpportunityTypes.POOLED_DEBT) {
    return [
      {
        className: "_fullwidth",
        title: "Date when the CLA has been signed and executed:",
        input: {
          type: "date",
          name: "claClosingDate"
        }
      },
      {
        className: "_fullwidth",
        title: "CLA term:",
        input: {
          type: "date",
          name: "claTerm"
        }
      }
    ];
  } else if (
    dealType === investmentOpportunityTypes.POOLED_FUND ||
    dealType === investmentOpportunityTypes.POOLED_EQUITY
  ) {
    return [
      {
        className: "_fullwidth",
        title: "Date of the official capital increase:",
        input: {
          type: "date",
          name: "capitalIncreaseDate"
        }
      },
      {
        className: "_fullwidth",
        title: "Number of the shares assigned to the particular deal:",
        input: {
          type: "number",
          name: "sharesNumber",
          disabled: true
        }
      },
      ...(dealType === investmentOpportunityTypes.POOLED_EQUITY
        ? [
            {
              className: "_fullwidth",
              title: "Total shares outstanding of the target company:",
              input: {
                type: "number",
                name: "totalOutstandingShares"
              }
            },
            {
              className: "_fullwidth",
              title:
                "Please specify if the number of total shares outstanding is displayed on diluted or non-diluted basis",
              input: {
                type: "select_btn",
                name: "totalOutstandingSharesAreDiluted",
                options: [
                  basicOption("diluted", "Diluted"),
                  basicOption("non-diluted", "Non-diluted")
                ]
              }
            }
          ]
        : []),
      {
        className: "_fullwidth",
        title: "Nominal value per share:",
        input: {
          type: "number",
          name: "sharesNominalValue",
          ...inputNumberFormatter(currency)
        }
      },
      {
        className: "_fullwidth",
        title: "Share price:",
        input: {
          type: "number",
          name: "sharesPrice",
          disabled: true,
          ...inputNumberFormatter(currency)
        }
      }
    ];
  }

  return [];
};
