import { Fragment } from "react";
import { Icon, Divider } from "antd";
import { closedValues } from "#constants/io/closed";
import Progress from "../../Progress";
import { ActionDropdown } from "../../Dropdown";
import { CardWrapper, InfoSection } from "../";
import { formatNumber } from "../../../../helpers/number_format";
import TagWrapper from "../../Tag";
import AvatarWrapper from "../../Avatar";
import TooltipWrapper from "../../Tooltip";
import qualifiedInvestorsIcon from "../../../../assets/illustrations/qualified.png";
import closedIcon from "../../../../assets/illustrations/closed.png";

const InvestmentCardWrapper = ({
  cuid,
  name,
  commonName,
  description = "",
  currency = {},
  moneyInvested,
  moneyCommitted,
  roundedMoneyInvested,
  roundedMoneyCommitted,
  fundingGoal,
  minimal,
  closed,
  progressIndicator = "green",
  actionList,
  onClickHandler,
  portfolioDisplay,
  industries,
  disableHover,
  previewImage,
  amProfilePic,
  amName,
  displaySettings,
  investmentType,
  qualifiedInvestorsOnly,
  syndicateName
}) => {
  const { shortName } = currency;
  const dealIsClosed = closed >= closedValues.CLOSED_BY_FOUNDER;

  const finalWrapperClass = `io-card-wrapper ${
    disableHover ? "disabled_hover" : ""
  } ${closed >= closedValues.CLOSED_BY_FOUNDER ? "closed" : ""}`;

  return (
    <CardWrapper className={finalWrapperClass} onClick={onClickHandler}>
      <div className="io-card-container">
        <div className="top">
          <div className="img-wrapper">
            <img src={previewImage} alt="Entity logo" className="img" />
          </div>
          {displaySettings && (
            <ActionDropdown actionList={actionList}>
              <div className="settings">
                <Icon type="ellipsis" />
              </div>
            </ActionDropdown>
          )}
        </div>
        <Divider />
        <div className="content-wrapper">
          <div className="title-wrapper">
            <div className="title">
              <h1>{commonName || name}</h1>
              {description.slice(0, 240)}
              {description.length > 240 && "..."}
            </div>
          </div>
          <Divider />
          <div className="inv-type-qualified-wrapper">
            {investmentType && (
              <TagWrapper color="black">
                {investmentType === "direct_investment"
                  ? "Direct investment"
                  : "Structuring vehicle (SPV)"}
              </TagWrapper>
            )}
            {qualifiedInvestorsOnly && (
              <TooltipWrapper title="Qualified investors only">
                <img
                  width="33"
                  height="33"
                  src={qualifiedInvestorsIcon}
                  alt="qualifiedInvestors"
                />
              </TooltipWrapper>
            )}
          </div>
          {/* {amName && (
            <div className="title-wrapper centered">
              <h3>Deal manager</h3>
              <TagWrapper color="teal">{amName}</TagWrapper>
            </div>
          )} */}

          {/* <AvatarWrapper src={amProfilePic} size="large" shape="square" /> */}
          {/* {portfolioDisplay && <Tags tags={Array(4).fill("Industry")} />} */}
          <Divider />
          <div className="progress-section-wrapper">
            <div className="stats-info">
              <div className="_bold">
                <span>
                  {formatNumber(roundedMoneyInvested, shortName)} Invested
                </span>
              </div>
              <div>
                <span>
                  {dealIsClosed
                    ? 100
                    : Number(
                        (roundedMoneyInvested * 100) / fundingGoal
                      ).toFixed(2)}
                  %
                </span>
              </div>
            </div>
            <div className="progress-container">
              <Progress
                successPercent={
                  dealIsClosed
                    ? 100
                    : (roundedMoneyInvested / fundingGoal) * 100
                }
                percentage={
                  ((roundedMoneyCommitted + roundedMoneyInvested) /
                    fundingGoal) *
                  100
                }
                showInfo={false}
              />
            </div>
            <div className="stats-info">
              <span className="overlay">
                Min: {formatNumber(minimal, shortName)}
              </span>
              <span className="overlay">
                Commited: {formatNumber(roundedMoneyCommitted, shortName)}
              </span>
            </div>
            <div className="investment-closed">
              {closed >= closedValues.CLOSED_BY_FOUNDER ? (
                <TagWrapper color="green">
                  <span>
                    <img width="25" height="25" src={closedIcon} alt="closed" />
                    Investment closed
                  </span>
                </TagWrapper>
              ) : null}
            </div>
            {amName && (
              <span className="am-wrapper">
                <AvatarWrapper src={amProfilePic} shape="square" />
                <h4 className="am-name">{amName}</h4>
              </span>
            )}
            <div className="am-wrapper syndicate-wrapper">
              <h4>Syndicate: {syndicateName}</h4>
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default InvestmentCardWrapper;
