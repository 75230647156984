import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './action_types';
import actions from '../../constants/action_type';

const initState = {
  ...initialState,
};

const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.GET_CONVERTIBLE_TYPES:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          convertibles: action.payload.data,
        }),
      });

    case types.POST_CONVERTIBLE_TYPES:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const currTypes = (state._data && state._data.convertibles) || [];

          return {
            convertibles: [...currTypes, action.payload.data],
          };
        },
      });

    case types.DELETE_CLA:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          const currTypes = (state._data && state._data.convertibles) || [];
          return {
            ...state._data,
            convertibles: currTypes.filter(
              ({ cuid }) => cuid !== action.payload.data
            ),
          };
        },
      });
    case types.UPDATE_CLA:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const currList = (state._data && state._data.convertibles) || [];
          const objIndex = currList.findIndex(
            (cla) => cla.cuid === action.payload.data.cuid
          );
          currList[objIndex] = action.payload.data;
          return {
            ...state._data,
            convertibles: currList,
          };
        },
      });

    default:
      return state;
  }
};

export default reducer;
