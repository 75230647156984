import { connect } from 'react-redux';
import {
  getEntity,
  updateEntity,
  getEntityMemberships
} from '#core/modules/entities/actions';
import { createIo, fetchSingleIo } from '#core/modules/io/actions';
import { putIoInfo } from '#core/modules/io/actions/update';
import { clearState } from '#core/utils/helper_actions';
import Presentational from './Presentational';

const mapStateToProps = (state) => {
  const { entity: { cuid: entityId, ...entityData } = {}, memberships = [] } =
    state.entities._data || {};
  const { cuid: userId } = state.users._data || {};
  const {
    isCreating,
    isUpdating,
    didCreate,
    didUpdate,
    didFetch,
    _data: { io } = {}
  } = state.io;
  const {
    isUpdating: entityUpdating,
    didCreate: entityDidCreate
  } = state.entities;

  return {
    entityId,
    entityData,
    userId,
    buttonStates: {
      loading: isCreating || isUpdating || entityUpdating,
      success: didCreate || didUpdate || entityDidCreate
    },
    memberships,
    ioColl: {
      didFetch,
      data: io
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearState: (key) => clearState(dispatch, key),
  updateEntity: (data) => dispatch(updateEntity(data)),
  getEntityMemberships: (data) => dispatch(getEntityMemberships(data)),
  createIo: (data) => dispatch(createIo(data)),
  fetchSingleIo: (data) => dispatch(fetchSingleIo(data)),
  putIoInfo: (data) => dispatch(putIoInfo(data)),
  getEntity: (data) => dispatch(getEntity(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
