/* Palette */
export const colors = {
  white: "#ffffff",
  black: "#000000",
  // greys
  battleshipGrey: "#71798a",
  levaGradient: "linear-gradient(30deg, #758dbf, #75b3bd)",
  paleGrey: "#e7ebee",
  paleGreyTwo: "#eff2f4",

  // blues
  aquaMarine: "#3ed3e9",
  skyBlue: "#42bffe",
  niceBlue: "#1a8da8",

  // greens
  greenBlue: "#00cf7e",
  lightBlue: "rgb(188,212,218)",
  fieldGreen: "#89da9c",
  magicLilac: "#758dbf",
  aubergine: "#33475b",
  pumpkins: "#ffa633", // warning interaction only
  reject: "#601F41",
  ban: "#D30030",
  gray: "#F2F2F2",
  darkNavy: "#120e33",
  // reds
  pomegranate: "#c0392b",
  alizarin: "#e74c3c",
  orange: "#f39c12",
  rose: "#E83A94",

  // new variables
  green: "#15b982",
  navyBlue: "#0044cc",
  red: "#e84033",

  // pastel colors
  middleBlue: "#92dce5",
  cultured: "#f8f7f9",
  corn: "#f7ec59",
  rosePink: "#ff66d8",
  cerise: "#db2763",
  juneBud: "#b0db43",
  uranianBlue: "#bce7fd",
  operaMauve: "#c492b1",
  terraCotta: "#ED6A5A",
  paleSpringBud: "#F4F1BB",
  opal: "#9BC1BC",
  alabaster: "#E6EBE0",
  robinEggBlue: "#36C9C6",
  violetRed: "#F75590",
  champagnePink: "#FCE4D8",
  jasmine: "#FBD87F",
  celeste: "#B5F8FE",
  greenCrayola: "#10FFCB"
};

/* Typography */
export const fonts = {
  latoRegular: "Lato",
  latoBold: "Lato-Bold"
};

/* Base font sizes */
export const fontSizes = {
  baseFontSize: 16,
  tabletFontSize: 14,
  mobileFontSize: 12
};
