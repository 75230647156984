import PropTypes from 'prop-types';
import { Icon } from 'antd';
import Button from '../../../../Shared/Button/components/BtnWrapper/Presentational';

const TabWrapper = ({ activeKey, onTabClick }) => {
  const tabs = [
    { key: '0', label: 'Overview' }
    // { key: '1', label: 'Share Transfer' },
    // { key: '2', label: 'Split transfer' }
  ];
  const handleSetKey = (key) => () => onTabClick(key);
  const className = (key) => `tab-btn ${activeKey === key ? 'selected' : ''}`;
  const renderIcon = (key) => {
    if (key === '0') {
      return <Icon type="dashboard" />;
    } else if (key === '1') {
      return <Icon type="dashboard" />;
    }

    return <Icon type="dashboard" />;
  };
  const actionLabel =
    activeKey === '1' ? 'New Share Transfer' : 'New Split Transfer';

  return (
    <div className="tabs-wrapper">
      <div className="tabs-container">
        {tabs.map(({ label, key, name }) => (
          <Button
            type="normal"
            label={
              <span>
                {renderIcon(key)} {label}
              </span>
            }
            className={className(key)}
            key={key}
            onClick={handleSetKey(key)}
          />
        ))}
      </div>
      {(activeKey === '1' || activeKey === '2') && (
        <div className="btn-container">
          <Button
            label={
              <span>
                <Icon type="plus" /> {actionLabel}
              </span>
            }
          />
        </div>
      )}
    </div>
  );
};

TabWrapper.propTypes = {
  activeKey: PropTypes.any,
  onTabClick: PropTypes.func
};

export default TabWrapper;
