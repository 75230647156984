import { mapPatchEquityInvestorValues } from "#helpers/mappers/equity";
import { triggerSnackbar } from "#core/modules/snackbar/actions";
import { closedValues } from "#constants/io/closed";
import * as IoService from "../../../../api/ioService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import {
  patchMembership,
  postMembership,
  deleteMembership
} from "../../../../api/ioMembershipsService";

export const updateIO = params => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_IO);
      const body = {};

      if (params.description) {
        body.description = params.description;
      }

      if (params.relations) {
        body.relations = params.relations;
      }

      const response = await IoService.updateIO(params.ioId, body);

      success(dispatch, types.UPDATE_IO, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      fail(dispatch, types.UPDATE_IO, {
        error: errorData
      });
    }
  };
};

export const putIoInfo = ({ ioId, closed, ...data }) => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_IO, {
        cuid: ioId
      });

      const response = await IoService.putIO(ioId, data);

      if (closed !== undefined) {
        await IoService.updateIoClosingStatus({ cuid: ioId, closed });
      }

      success(dispatch, types.UPDATE_IO, {
        data: response.data
      });
    } catch (error) {
      const { message } = errorData;
      catchError(dispatch, message);
      const errorData = getErrorData(error);
      fail(dispatch, types.UPDATE_IO, {
        error: errorData
      });
    }
  };
};

export const resetIaAndSha = (ioId, data, closed) => {
  return async dispatch => {
    try {
      start(dispatch, types.RESET_IA_AND_SHA);
      const response = await IoService.putIO(ioId, data);
      const closingResponse = IoService.updateIoClosingStatus({
        cuid: ioId,
        closed
      });

      success(dispatch, types.RESET_IA_AND_SHA, {
        data: response.data,
        closingStatus: closingResponse.data
      });
    } catch (error) {
      const { message } = errorData;
      catchError(dispatch, message);
      const errorData = getErrorData(error);
      fail(dispatch, types.RESET_IA_AND_SHA, {
        error: errorData
      });
    }
  };
};

export const updateAttachment = data => {
  /* UPDATING ATTACHMENT CONSISTS OF REMOVING CURRENT ATTACH AND ADDING A NEW ONE */

  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_ATTACHMENT);

      const response1 = await IoService.deleteIoAttachment(data);
      let response2;

      if (response1.status === 204) {
        response2 = await IoService.postIoAttachment(data);
      }

      success(dispatch, types.UPDATE_ATTACHMENT, {
        data: { deletedAttachCuid: data.cuid, ...response2.data } // newly added attachment
      });
    } catch (error) {
      const { message } = errorData;
      catchError(dispatch, message);
      const errorData = getErrorData(error);
      fail(dispatch, types.UPDATE_ATTACHMENT, {
        error: errorData
      });
    }
  };
};

export const updateIoClosingStatus = ({ type, ...data }) => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_IO_CLOSING_STATUS);

      await IoService.updateIoClosingStatus(data);

      let successMessage = "";

      if (type === "docs_generation") {
        successMessage =
          "Deal has been successfully closed! You'll be notified when the documents are finished generating.";
      } else if (type === "cla_closed") {
        successMessage =
          "Fundraising has been successfully closed! You'll be redirected to the dashboard soon.";

        await IoService.updateIoClosingStatus({
          ...data,
          closed: closedValues.APPROVED_BY_LAWYERS
        });
      }

      success(dispatch, types.UPDATE_IO_CLOSING_STATUS, {
        data
      });

      if (successMessage) {
        triggerSnackbar(dispatch, {
          open: true,
          message: successMessage,
          variant: "success"
        });
      }
    } catch (error) {
      const { message } = errorData;
      catchError(dispatch, message);
      const errorData = getErrorData(error);
      fail(dispatch, types.UPDATE_IO_CLOSING_STATUS, {
        error: errorData
      });
    }
  };
};

export const updateDealAndBulkMembers = ({
  id,
  dealData,
  memberships,
  ioMemberships,
  closed
}) => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_DEAL_BULK_MEMBERS);

      const response = await IoService.putIO(id, dealData);

      if (closed !== undefined) {
        IoService.updateIoClosingStatus({ cuid: id, closed });
      }
      const formattedMemberships = mapPatchEquityInvestorValues(
        memberships,
        ioMemberships,
        id
      );

      /* ADDS NEW OR UPDATES PREVIOUS MEMBERSHIPS */

      if (formattedMemberships.length > 0) {
        await Promise.all(
          formattedMemberships.map(({ cuid, ...data }) => {
            if (cuid) {
              patchMembership({ cuid, ...data });
            } else {
              postMembership(data);
            }
          })
        );
      }

      /* DELETES DATA THAT GOT UNSELECTED */
      const pendingDeletions = [];

      ioMemberships.map(
        ({ cuid, investor: { cuid: investorCuid = "" } = {} }) => {
          const membershipToDelete = memberships.find(
            ({ cuid: localCuid, select, selected, member }) =>
              investorCuid === localCuid &&
              (select === false || selected === false || member === false)
          );

          if (membershipToDelete) {
            pendingDeletions.push(cuid);
          }
        }
      );

      if (pendingDeletions.length > 0) {
        await Promise.all(pendingDeletions.map(cuid => deleteMembership(cuid)));
      }

      success(dispatch, types.UPDATE_DEAL_BULK_MEMBERS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_DEAL_BULK_MEMBERS, {
        error: errorData
      });
    }
  };
};

export const patchAttachment = data => {
  return async dispatch => {
    try {
      start(dispatch, types.PATCH_ATTACHMENT);

      await IoService.patchIoAttachment(data);

      success(dispatch, types.PATCH_ATTACHMENT, {
        data
      });
    } catch (error) {
      const { message } = errorData;
      catchError(dispatch, message);
      const errorData = getErrorData(error);
      fail(dispatch, types.PATCH_ATTACHMENT, {
        error: errorData
      });
    }
  };
};

export const updateAndCloseDeal = ({ closed, ioId, ...data }) => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_AND_CLOSE_IO);

      const response = await IoService.putIO(ioId, data);
      await IoService.updateIoClosingStatus({ cuid: ioId, closed });

      success(dispatch, types.UPDATE_AND_CLOSE_IO, {
        data: { ...response.data, closed }
      });
    } catch (error) {
      const { message } = errorData;
      catchError(dispatch, message);
      const errorData = getErrorData(error);
      fail(dispatch, types.UPDATE_AND_CLOSE_IO, {
        error: errorData
      });
    }
  };
};
