import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { equityTs } from "#constants/io/equityPool";
import BasicWrapper from "../../../../../Shared/BasicWrapper";
import { ContentFallbackScreen } from "../../../../../Shared/Screens";
import DealTitle from "../../common/Title";
import DocumentsDrawer from "../../common/DocumentsSection";
import Terms from "./components/Terms";
import ActionContainer from "../../common/ActionContainer";

const EquityView = ({
  fetchSingleIo,
  contracts,
  getContractsPreview,
  loading,
  didFetch,
  io
}) => {
  const { dealId } = useParams();
  const { entity = {}, termSheet = {}, iaAndSha } = io;

  useEffect(() => {
    fetchSingleIo(dealId);
  }, []);

  useEffect(() => {
    if (didFetch) {
      const contractsList = ["term-sheet"];

      if (iaAndSha) {
        contractsList.push("sha");
      }

      // TODO: CONVERT CONTRACTS TYPE TO CONSTANT KEYS
      getContractsPreview({
        io_id: dealId,
        contracts: contractsList
      });
    }
  }, [didFetch]);

  const formattedAttachments = contracts.map(item => ({
    ...item,
    name: item.contractName,
    url: item.value,
    ext: ".doc"
  }));

  return (
    <ContentFallbackScreen loading={loading}>
      <BasicWrapper>
        <ActionContainer
          buttons={[{ label: "Generate SHA", href: `/create/sha/${dealId}` }]}
          actionVisible
        >
          <DealTitle
            dealName={termSheet[equityTs.SERIES_ROUND]}
            title={entity.name}
            {...entity}
          />
        </ActionContainer>
        <Terms io={io} />
        <DocumentsDrawer title="Deal documents" list={formattedAttachments} />
      </BasicWrapper>
    </ContentFallbackScreen>
  );
};

EquityView.propTypes = {
  contracts: PropTypes.array,
  getContractsPreview: PropTypes.func,
  fetchSingleIo: PropTypes.func,
  loading: PropTypes.bool,
  io: PropTypes.object
};

export default EquityView;
