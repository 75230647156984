import PropTypes from "prop-types";
import Checkbox from "../Checkbox";

const CheckboxSelection = ({
  options,
  name,
  type,
  value = [],
  onFocus,
  onChange,
  classes
}) => {
  return (
    <div className="select-container">
      {options.map(
        (
          { value: checkboxVal, label, disabled, toolTip, changeDisabled },
          index
        ) => {
          return (
            <div key={`${index}`} className="checkbox">
              <Checkbox
                name={name}
                value={checkboxVal || label}
                onClick={onFocus}
                classes={classes}
                onChange={onChange(name, checkboxVal)}
                checked={
                  type === "checkbox"
                    ? value.includes(checkboxVal)
                    : value === checkboxVal
                }
                disabled={disabled}
                toolTip={toolTip}
                label={label}
                changedDisabled={changeDisabled}
              />
            </div>
          );
        }
      )}
    </div>
  );
};

CheckboxSelection.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  classes: PropTypes.object,
  value: PropTypes.any
};

export default CheckboxSelection;
