import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { mapFounderInviteValues } from '#helpers/mappers/founderInvitation';
import queryStrings from 'query-string';
import { list } from './form/list';
import FounderInvitationForm from './form/Presentational';

const FounderInvitationWrapper = ({
  createFounderInvitation,
  buttonStates,
  location
}) => {
  const [entityId, setEntityId] = useState(null);

  useEffect(() => {
    const query = queryStrings.parse(location.search);
    const parentEntity = query.entityId;

    if (parentEntity) {
      setEntityId(parentEntity);
    }
  }, []);

  const fieldList = list[0].items;

  const handleSubmit = (values) =>
    createFounderInvitation(mapFounderInviteValues(values, entityId));

  const wrapperArgs = {
    fieldList,
    handleSubmit,
    success: buttonStates.success,
    successTitle: 'Founder created successfully.',
    entityId,
    buttonStates
  };

  return <FounderInvitationForm {...wrapperArgs} />;
};

FounderInvitationWrapper.propTypes = {
  createFounderInvitation: PropTypes.func,
  buttonStates: PropTypes.object,
  location: PropTypes.object
};

export default FounderInvitationWrapper;
