import { useState, useEffect, Fragment } from "react";
import queryStrings from "query-string";
import { registrationSchema } from "#validators/registrationSchema";
import {
  mapUpdateFounderInvitation,
  mapFounderInviteToUser,
  mapCompanyDetails
} from "#helpers/mappers/founderInvitation";
import { zefixHandlers } from "#helpers/zefix";
import { updateFormValues } from "#helpers/hooks/updateFormValues";
import { mapDetailsToEntity } from "#helpers/mappers/entitySettings";
import { convertToBase64 } from "#helpers/convertBase64";
import { entityTypes } from "#constants/entity";
import { roles } from "#constants/roles";
import { BasicForm } from "../../Shared/Form";
import registrationList from "../RegistrationPage/components/list";
import { companyFields as fullEntityList } from "./components/entityFieldsList";
import { naturalPersonFields } from "./components/naturalPersonList";
import { registrationFields as entityList } from "../../Shared/Form/EntityForm/components/registrationList";
import CustomInputRenderer from "./components/CustomInputRenderer";
import Layout from "../common/Layout";
import BtnContainer from "../common/btnContainer";
import FounderInviteSuccess from "./Views/SuccessView";
import DmInviteSuccess from "./Views/DmSuccessView";
import SignUpGraphic from "../../../assets/images/signup.png";

const FounderInvitation = ({
  buttonStates,
  states,
  actions,
  companyDetails,
  founderInvitation,
  location,
  history,
  currencies,
  userData
}) => {
  const { invitation_id = "" } = queryStrings.parse(location.search) || {};
  const [frmtdCurrencies, setFrmtedCurrencies] = useState({});

  useEffect(() => {
    actions.clearState("entities");
    actions.clearState("currencies");
    actions.clearStateData("entities");

    actions.readCurrencies();

    if (invitation_id) {
      actions.getSingleFounderInvitation(invitation_id);
    }
  }, []);

  useEffect(() => {
    if (!states.detailsFetched && founderInvitation.entityName) {
      actions.getCompanyDetails({ name: founderInvitation.entityName });
    }
  }, [founderInvitation]);

  useEffect(() => {
    if (states.currenciesFetched) {
      const frmtdCurrencies = currencies.reduce(
        (acc, { cuid, shortName }) => ({
          ...acc,
          [cuid]: shortName
        }),
        {}
      );

      setFrmtedCurrencies(frmtdCurrencies);
    }
  }, [states.currenciesFetched]);

  const [currStep, setCurrStep] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);
  const handleUserDetailsSync = () => actions.syncUserDetails(userData);

  const handleSubmit = async args => {
    args.name = args.name.split(",")[0];
    let previewImageBase64;
    if (previewImage) {
      previewImageBase64 = await convertToBase64(previewImage);
    }

    if (currStep === 0) {
      setCurrStep(1);
    } else {
      const payload = {
        cuid: invitation_id,
        paramsForNewEntity: {
          entityType: founderInvitation.entityType,
          ...mapUpdateFounderInvitation(
            args,
            founderInvitation.entityLegalType === entityTypes.NATURAL_PERSON
              ? "userAddress"
              : "entityAddress"
          )
        }
      };

      if (previewImageBase64) {
        payload.paramsForNewEntity.entityPreviewImageBase64 = previewImageBase64;
      }

      actions.updateFounderInvitation(payload);
    }
  };

  const handleUpload = setFieldValue => info => {
    setPreviewImage(info.file.originFileObj);
  };

  const zefixHandlersWrapper = zefixHandlers();

  const handleListSelect = values => {
    const profilePic = previewImage
      ? URL.createObjectURL(previewImage)
      : previewImage;

    if (founderInvitation.entityType === entityTypes.ASSET_MANAGER_ENTITY) {
      if (founderInvitation.entityLegalType === entityTypes.NATURAL_PERSON) {
        return naturalPersonFields({
          previewImage: profilePic,
          currencies: frmtdCurrencies,
          values
        });
      }

      return fullEntityList({
        zefixHandlers: zefixHandlersWrapper,
        values,
        loading: states.detailsFetching,
        previewImage: profilePic,
        currencies: frmtdCurrencies
      });
    }

    return entityList({
      zefixHandlers: zefixHandlersWrapper,
      values,
      role: roles.FOUNDER
    });
  };

  const fieldList =
    currStep === 0
      ? registrationList(true)
      : ({ values }) => {
          return handleListSelect(values);
        };

  const btnLabel = currStep === 0 ? "Next" : "Finish";
  const wrapperClassname = `founder-invitation-form ${
    currStep === 0 ? " personal-details" : "entity-details"
  }`;
  const validationSchema = currStep === 0 ? registrationSchema : null;

  const formArgs = {
    fieldList,
    CustomFooter: BtnContainer({ label: btnLabel }),
    wrapperClassname,
    validationSchema,
    handleSubmit,
    buttonStates,
    useCustomHook: updateFormValues(
      currStep === 0
        ? {
            deps: [states.entitiesDidFetch],
            values: mapFounderInviteToUser(founderInvitation)
          }
        : {
            deps: [states.detailsFetched, companyDetails],
            values: mapDetailsToEntity(companyDetails)
          }
    ),
    handleUpload,
    CustomInputRenderer,
    disabledFields: currStep === 0 ? ["email"] : []
  };

  const headline =
    currStep === 0 ? "Complete your account details" : "Complete your profile";

  const SuccessView = props => {
    if (founderInvitation.entityType === entityTypes.ASSET_MANAGER_ENTITY) {
      return DmInviteSuccess(props);
    }

    return FounderInviteSuccess(props);
  };
  const getWrapperCls = () => {
    if (states.entitiesDidCreate) {
      return "success-wrapper";
    }

    if (currStep === 1) {
      return "entity-details";
    }

    return "";
  };

  return (
    <Layout
      prompt={{
        title: "Already a member?",
        linkLabel: "Log in",
        link: "/login"
      }}
      customWrapperClassname={getWrapperCls()}
      showLeftPane
      graphic={SignUpGraphic}
      leftPaneTitle="Glad to have you on board!"
      leftPaneSubtitle="Welcome to our invite-only investor community"
    >
      <div className="founder-invitation-container">
        {states.entitiesDidCreate ? (
          <SuccessView
            history={history}
            onboarding={false}
            name={founderInvitation.inviteeFirstName}
            syncUserDetails={handleUserDetailsSync}
          />
        ) : (
          <Fragment>
            <h1>{headline}</h1>
            <BasicForm {...formArgs} />
          </Fragment>
        )}
      </div>
    </Layout>
  );
};

export default FounderInvitation;
