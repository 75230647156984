export const entityKeys = {
  COMPANY_NAME: "companyName",
  REGISTERED_SEAT: "registeredSeat",
  CURR_NUM_SHARES: "currentNumberOfShares",
  SHAREHOLDER_STRUCTURE: "shareholderStructure"
};

export const keys = {
  SERIES_ROUND: "seriesRound",
  USE_PROCEEDS: "useProceeds",
  PRE_MONEY_VAL: "preMoneyVal",
  PRE_MONEY_VAL_DILUTED: "preMoneyValDiluted",
  NOMINAL_VAL: "nominalVal",
  ISSUE_PRICE: "issuePrice",
  VOTING_RIGHTS: "votingRights",
  AMOUNT_INTEREST: "amountInterest",
  AGREED_AMOUNT_BOOL: "agreedAmountBool",
  AGREED_AMOUNT: "agreedAmount",
  EMPLOYEE_PART_PLAN_BOOL: "employeeParticipationPlanBool",
  EMPLOYEE_PART_PLAN: "employeePartPlan",
  PREFERENCE_RIGHTS: "preferenceRights",
  DIVIDEND_PREFERENCE: "dividendPreference",
  LIQUDIATION_PREFERENCE: "liqudiationPreference",
  LIQUDIATION_PREFERENCE_AMOUNT_BOOL: "liqudiationPreferenceAmountBool",
  LIQUDIATION_PREFERENCE_AMOUNT: "liqudiationPreferenceAmount",
  ANTI_DILUTION_PROTECTION: "antiDilutionProtection",
  ANTI_DILUTION_PROTECTION_FORMULA: "antiDilutionProtectionFormula",
  MAX_BOARD_DIRECTORS: "maxBoardDirectors",
  DIRECTOR_APPOINT_RIGHTS: "directorAppointRights",
  DIRECTOR_APPOINT_NUM: "directorAppointNum",
  INDEPENDENT_DIRECTOR: "independentDirector",
  MINIMUM_PERCENTAGE: "minimumPercentange",
  ARTICLE_OF_ASSOCIATION: "articleOfAssociation",

  /* STEP 9 */

  SHARE_TRANSFER_RESTRICTION: "shareTransferRestriction",
  RIGHT_OF_REFUSAL: "rightOfRefusal",
  TAG_ALONG_RIGHT: "tagAlongRight",
  DRAG_ALONG_RIGHT: "dragAlongRight",
  DRAG_ALONG_PERCENTAGE: "dragAlongPercentage",
  DRAG_ALONG_VALUATION: "dragAlongValuation",
  PURCHASING_OPTION_SHAREHOLDER: "purchasingOptionShareholder",

  /* STEP 10 */

  TERM_SHEET_EXCLUSIVITY: "termSheetExclusivity",
  TERM_SHEET_EXCLUSIVITY_TERM: "termSheetExclusivityTerm",
  PURSUANT_DOCUMENTS: "pursuantDocuments",
  EXPENSES_COVERAGE: "expensesCoverage",
  EXPENSES_COVERAGE_FOUNDER: "expensesCoverageFounder",
  GOVERNING_LAW: "governingLaw",
  APPENDICES: "appendices"
};

export const equityTs = {
  RIGHT_TO_NOMINATE_BOARD_OBSERVER: "boardObserverAppointedBy",
  LIQUIDATION_PREFERENTIAL_CAP: "liquidationPreferentialCap",
  DIVIDEND_PREFERENCE_PARTICIPATING: "dividendPreferentialParticipation",
  DIVIDEND_PREFERENCE_CLAUSE: "dividendPreferentialClause",
  DIVIDEND_PREFERENTIAL_CAP: "dividendPreferentialCap",
  SIGNED_BY: "signedBy",
  SHARES_NUMBER: "sharesNumber",
  SERIES_ROUND: "seriesRound",
  PRE_VALUE: "preValue",
  FULLY_DILUTED: "fullyDiluted",
  ESOP: "esop",
  ALLOCATED_SHARES_ESOP: "allocatedSharesEsop",
  CONTRACTUAL_PREFERRED: "contractualPreferred",
  RIGHT_TO_NOMINATE_INVESTOR: "nominateInvestorDirector",
  ISSUE_PRICE: "issuePrice",
  SEVERAL_TRANCHES: "severalTranches",
  AGREED_AMOUNT: "agreedAmount",
  MIN_CAPITAL_INCREASE: "minCapitalIncrease",
  DIVIDEND_PREFERENTIAL: "dividendPreferential",
  LIQUIDATION_PREFERENTIAL: "liquidationPreferential",
  ANTI_DILUTION: "antiDilution",
  ANTI_DILUTION_PROTECTION_FORMULA: "antiDilutionFormula",
  QUALIFIED_MAJORITIES_SH_MATTERS: "qualifiedMajoritiesMatters",
  INVESTOR_BOARD_DIRECTOR: "investorBoardDirector",
  FOUNDER_BOARD_DIRECTOR: "founderBoardDirector",
  DIRECTORS_NUMBER: "directorsNumber",
  INVESTOR_DIRECTOR: "investorDirectors",
  INVESTOR_DIRECTOR_NUM: "independentDirectorsNum",
  INVESTOR_DIRECTOR_PERCENTAGE: "investorDirectorPercentage",
  FOUNDER_DIRECTOR: "founderDirectors",
  BOARD_OBSERVER: "boardObserver",
  BOARD_OBSERVER_PERCENTAGE: "boardObserverPercentage",
  DRAG_ALONG_PERCENTAGE: "dragAlongPercentage",
  INFO_INVESTOR: "infoInvestor",
  GOVERNING_LAW: "governingLaw",
  ZURICH_COURT: "zurichCourt",
  SOCIAL_SECURITY: "socialSecurity",
  BOARD_MEMBERS_ACQUIRE_PERCENTAGE: "boardMembersAcquirePercentage",
  BOARD_MATTERS_EXEEDING_AMOUNT: "boardMattersExeedingAmount",
  BOARD_MATTERS_EXEEDING_OBLIGATION: "boardMattersExeedingObligation",
  BOARD_MATTERS_EXEEDING_SEC_INT: "boardMattersExeedingSecInt",
  ESOP_PERCENT_CALCULATION: "esopPercentage",
  HAS_COMMITTED_INVESTORS: "hasCommittedInvestors",
  INVESTORS: "investors",
  VOTING_RIGHTS: "votingRights",
  VOTING_RIGHTS_OTHER: "otherVotingRights",
  DIRECTORS_NUMBER_OTHER: "directorsNumOther",
  INVESTOR_DIRECTOR_OTHER: "investorDirectorOther",
  INVESTOR_DIRECTOR_PERCENTAGE_OTHER: "investorDirectorPercentOther",
  BOARD_OBSERVER_PERCENTAGE_OTHER: "boardObserverPercentOther",
  BOARD_MEMBERS_ACQUIRE_PERCENTAGE_OTHER: "boardMembersPercentAcquisitionOther",
  BOARD_MATTERS_EXEEDING_AMOUNT_OTHER: "boardMattersExceedingAmountOther",
  BOARD_MATTERS_EXEEDING_OBLIGATION_OTHER: "boardMattersExceedingObligOther",
  QUALIFIED_MAJORITIES_SH_MATTERS_OTHER: "qualifiedMajoritiesMattersOther",
  INFO_INVESTOR_OTHER: "infoInvOther",
  DRAG_ALONG_PERCENTAGE_OTHER: "dragAlongPercentOther",
  GOVERNING_LAW_OTHER: "otherGoverningLaw",
  CURRENCY: "currency",
  FOUNDER_DIRECTOR_OTHER: "founderDirectorOther",
  INFO_RIGHTS: "informationRights",
  ESOP_ESTABLISHMENT: "esopEstablishment",
  AOA_RESTRICT: "aoaRestriction",
  COMMON_SHARE_NOMINAL: "commonShareNominalValue",
  BOARD_MATTERS_EXEEDING_SEC_INT_VALUE: "boardMattersExeedingSecIntVal",

  LEAVER_SHARES: "reverseFounderVesting",
  LEAVER_SHARES_VAL: "leaverSharesPercentage",
  LEAVER_SHARES_VAL_OTHER: "leaverSharesValOther",
  LEAVER_SHARES_DUR: "reverseVestingMonths",
  LEAVER_SHARES_DUR_OTHER: "leaverSharesDurOther",
  LEAVER_SHARES_CLIFF_PERIOD: "reverseVestingCliff",
  LEAVER_SHARES_CLIFF_PERIOD_OTHER: "leaverSharesCliffPeriodOther",
  LEAVER_SHARES_REDUCTION: "leaverSharesPercentageReduction",
  LEAVER_SHARES_REDUCTION_OTHER: "leaverSharesReductionOther",
  FULLY_DILUTED_ESOP: "fullyDilutedEsop",
  CUSTOM_SHARE_CATEGORY: "customShareCategory",

  COMMON_SHARE_NOMINAL_OTHER: "commonShareNominalOther",
  BOARD_MATTERS_EXEEDING_SEC_INT_VALUE_OTHER:
    "boardMattersExeedingSecIntValOther",
  LIQUDIATION_PREFERENCE_CLAUSE: "liquidationPreferentialClause",
  LIQUDIATION_PREFERENCE_PARTICIPATING: "liquidationPreferentialParticipation",

  MAJORITY_CONSENT: "investorDirectorsConsent",
  INVESTORS_CONSENT: "investorDirectorsAgreementRequirement",
  INVESTORS_CONSENT_OTHER: "investorDirectorsAgreementRequirementNumber",
  NO_PREFERENCE_RIGHTS: "noPreferenceRights",

  FULLY_DILUTED_CONVERTIBLE: "fullyDilutedConvertible",
  INCLUDE_ESOP_EFFECTS: "includeEsopEffects",
  INCLUDE_CLA_EFFECTS: "includeClaEffects",

  ESOP_SHARES_NUMBER: "esopSharesNumber",
  CONVERTING_CLA: "cla",

  INDEPENDENT_DIRECTORS_NUM: "independentDirectors",
  INDEPENDENT_DIRECTORS_NUM_OTHER: "independentDirectorsOther",
  INDEPENDENT_DIRECTORS_APPOINTMENT: "independentDirectorsAppointment",
  INDEPENDENT_DIRECTORS_APPOINTER: "independentDirectorsAppointedBy",
  INDEPENDENT_CHAIRMAN_APPOINTMENT: "independentChairmanAppointer",

  INDEPENDENT_DIRECOTRS_APPOINTMENT_PERCENT: "independentDirectorsNominatedBy",
  INDEPENDENT_CHAIRMAN_APPOINTMENT_PERCENT:
    "independentChairmanAppointmentPercent"
};

export const keysDependencies = {
  [keys.AGREED_AMOUNT]: keys.AGREED_AMOUNT_BOOL,
  [keys.EMPLOYEE_PART_PLAN]: keys.EMPLOYEE_PART_PLAN_BOOL,
  [keys.DIVIDEND_PREFERENCE]: keys.PREFERENCE_RIGHTS,
  [keys.LIQUDIATION_PREFERENCE]: keys.PREFERENCE_RIGHTS,
  [keys.LIQUDIATION_PREFERENCE_AMOUNT_BOOL]: keys.LIQUDIATION_PREFERENCE,
  [keys.LIQUDIATION_PREFERENCE_AMOUNT]: keys.LIQUDIATION_PREFERENCE_AMOUNT_BOOL,
  [keys.ANTI_DILUTION_PROTECTION_FORMULA]: keys.ANTI_DILUTION_PROTECTION,
  [keys.DIRECTOR_APPOINT_NUM]: keys.DIRECTOR_APPOINT_RIGHTS,
  [keys.DRAG_ALONG_PERCENTAGE]: keys.DRAG_ALONG_RIGHT,
  [keys.DRAG_ALONG_VALUATION]: keys.DRAG_ALONG_RIGHT,
  [keys.TERM_SHEET_EXCLUSIVITY_TERM]: keys.TERM_SHEET_EXCLUSIVITY
};

export const shaKeys = {
  MAX_INVESTMENT_AMOUNT_IF_SEVERAL_TRANCHES:
    "maxInvestmentAmountIfSeveralTranches",
  MAX_CAPITAL_INCREASE: "maxCapitalIncrease",
  BOARD_QUORUM_HALF: "boardQuorumHalf",
  VOLUNTARY_CONVERSION_SHA: "voluntaryConversionSha",
  INDEPENDENT_EXPERT_NAME: "independentExpertName",
  CHAMBER_COMMERCE_PLACE: "chamberCommercePlace",
  LIQUIDATED_DAMANGES_AMOUNT: "liqudatedDamagesAmount",
  LIQUIDATED_DAMANGES_AMOUNT_OTHER: "liqudatedDamagesAmountOther",
  IA_CONFIDENTIALITY: "iaConfidentiality",
  NON_COMPETITION_SOLICITATION_TERM: "nonCompetitionSolicitationTerm",
  NON_COMPETITION_SOLICITATION_TERM_OTHER:
    "nonCompetitionSolicitationTermOther",
  IA_NOTICES: "iaNotices",
  ALL_PARTIES: "allParties",
  NON_COMPETITION_NON_SOLICITATION_CALUSE:
    "nonCompetitionNonSolicitationClause",
  SUBS_RIGHTS_WAIVER: "founder",
  TRANSFER_CONSENT: "transferConsent",
  GOOD_LEAVER_NOMINAL_VAL: "goodLeaverNominalValue",
  BAD_LEAVER: "badLeaver",
  VESTED_SHARES_NOMINAL_VAL: "vestedSharesNominalValue",
  VESTED_SHARES_NOMINAL_VAL_OTHER: "vestedSharesNominalValueOther",
  VESTED_SHARES_FMARKET_VAL: "vestedSharesFMarketValue",
  VESTED_SHARES_FMARKET_VAL_OTHER: "vestedSharesFMarketValueOther",
  REVERSE_VESTING_MONTHS: "reverseVestingMonths",
  INDEPENDENT_EXPERT_LAST_NAME: "independentExpertLastName",
  INDEPENDENT_EXPERT_STATUS: "independentExpertStatus",
  INDEPENDENT_EXPERT_STREET: "independentExpertStreet",
  INDEPENDENT_EXPERT_ZIP: "independentExpertPostalCode",
  INTELLECTUAL_PROPERTY_RIGHTS: "intellectualPropertyRights",
  INVESTOR_DICISION_MAKING_ATTACHMENTS: "investorDecisionMakingAttachments",
  INVESTOR_OVERVIEW: "investorsOverview",
  INDEPENDENT_EXPERT_PLACE: "independentExpertPlace",
  INDEPENDENT_EXPERT_CANTON: "independentExpertCanton",
  INDEPENDENT_EXPERT_COUNTRY: "independentExpertCountry",
  INDEPENDENT_EXPERT_EMAIL: "independentExpertEmail",
  IPO_CONVERSION: "ipoConversion",
  AUDITOR_EXPERT_NAME: "auditorExpertName",
  AUDITOR_EXPERT_LAST_NAME: "auditorExpertLastName",
  AUDITOR_EXPERT_STATUS: "auditorExpertStatus",
  AUDITOR_EXPERT_STREET: "auditorExpertStreet",
  AUDITOR_EXPERT_ZIP: "auditorExpertPostalCode",
  AUDITOR_EXPERT_PLACE: "auditorExpertPlace",
  AUDITOR_EXPERT_COUNTRY: "auditorExpertCountry",
  AUDITOR_EXPERT_EMAIL: "auditorExpertEmail"
};

export const iaAndSha = {
  NEW_DIRECTOR: "newDirector",
  NEW_COMPANY_AUDITOR: "newCompanyAuditor",
  BENEFICIAL_OWNER: "beneficialOwner",
  LIABILITY_CAP_INVESTOR: "liabilityCapInvestor",
  ANNOUNCEMENT: "anouncment",
  NO_PARTY_ASSIGN: "noPartyAssign",
  DATA_ROOM: "dataRoom",
  UNAUDITED_FS_DATE: "unAuditedFsDate",
  MANAGEMENT_PL_DATE: "managementPlDate",
  ENTITY_EQUITY_AMOUNT: "entityEquityAmount",
  ENTITY_BUSINESS_AGREEMENT_AMOUNT: "entityBusinessAgreementAmount",
  ENTITY_CONVERTIBLE_OBLIGATION_AMOUNT: "entityConvertibleObligationAmount",
  ENTITY_OBLIGATION_AMOUNT: "entityObligationAmount",
  VOLUNTARY_CONVERSION_SHA: "voluntaryConversionSha"
};

export const iaKeys = {
  BUSINESS_PLAN: "businessPlan",
  BUSINESS_PLAN_UPLOAD: "businessPlanUpload",
  BUSINESS_PLAN_FILE: "businessPlanFile",
  DATA_ROOM_UPLOAD: "dataRoomUpload",
  EMPLOYEE_LIST: "employeesList",
  COMERCIAL_REGISTER: "commercialRegister",
  ORDINARY_COURT: "ordinaryCourt",
  ORDINARY_COURT_OTHER: "ordinaryCourtOther",
  SELECTION: "selection",
  FINAL_PRE_MONEY_VAL: "finalPreMoneyVauation",
  VALUATION_DILUTED: "valuationDiluted",
  SHARES_PRICE: "sharePrice",
  TOTAL_FUNDS: "totalFunds",
  TOTAL_SHARES_ISSUED: "totalSharesIssued",
  NEW_COMPANY_AUDITOR: "companyAuditor",
  COMPANY_AUDITOR_NAME: "newCompanyAuditor",
  COMPANY_AUDITOR_ADDRESS: "companyAuditorAddress",
  COMPANY_AUDITOR_CITY: "companyAuditorCity",
  UNAUDITED_FS_DATE: "unAuditedFsDate",
  MANAGEMENT_PL_DATE: "managementPlDate",
  ENTITY_EQUITY_AMOUNT: "entityEquityAmount",
  ENTITY_BUSINESS_AGREEMENT_AMOUNT: "entityBusinessAgreementAmount",
  ENTITY_BUSINESS_AGREEMENT_AMOUNT_OTHER: "entityBusinessAgreementAmountOther",
  ENTITY_CONVERTIBLE_OBLIGATION_AMOUNT: "entityConvertibleObligationAmount",
  ENTITY_OBLIGATION_AMOUNT: "entityObligationAmount",
  ENTITY_OBLIGATION_AMOUNT_OTHER: "entityObligationAmountOther",
  CONSENT_NEEDED_INV_CONFIRMATION: "iaConfidentiality",
  CONSENT_NEEDED_TRANSFER: "transferConsent",
  GUARANTEE_EXCESS: "guaranteeExcess",
  GUARANTEE_EXCESS_OTHER: "guaranteeExcessOther"
};
