import { InvitationAcceptance } from "../Shared/Modals";
import BasicWrapper from "../Shared/BasicWrapper";

const InvitationAcceptanceWrapper = () => {
  return (
    <div className="invitation-acceptance-wrapper">
      <BasicWrapper>
        <InvitationAcceptance />
      </BasicWrapper>
    </div>
  );
};

export default InvitationAcceptanceWrapper;
