import { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import baseModal from "antd/es/modal";
import { equityTs } from "#constants/io/equityPool";
import { closedValues } from "#constants/io/closed";
import { useParams } from "react-router-dom";
import {
  equityAttachmentCategories,
  ioAttachmentCategories,
  ioContractCategories
} from "#constants/io/ioTypes";
import BasicWrapper from "../../../../../Shared/BasicWrapper";
import { ContentFallbackScreen } from "../../../../../Shared/Screens";
import DealTitle from "../../common/Title";
import DocumentsDrawer from "../../common/DocumentsSection";
import Controls from "./components/Controls";
import ClaDescription from "./components/Description";
import { withPageTitle } from "../../../../../Shared/hocs";
import SpinContainer from "../../../../../Shared/SpinContainer";
import ActionContainer from "../../common/ActionContainer";
import ContactUsContainer from "./components/ContactUsModal/";

const ConvertibleView = ({
  fetchAllIos,
  contracts = [],
  getContractsPreview,
  loading,
  didFetch,
  entityId,
  entity,
  contractsFetching = false,
  contractsFetched = false,
  io,
  progress,
  clearState,
  clearStateDataByKey,
  updateIoClosingStatus,
  ioId,
  fetchSingleIo,
  singleIoDidFetch,
  patchAttachment,
  history,
  createDealAndBulkMembers,
  isCreating,
  canCreate
}) => {
  const {
    termSheet = {},
    cuid,
    closed,
    attachments,
    closedByFounder: closedByFounderState,
    name: dealName
  } = io;
  const { id: claTsId = "" } = useParams();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (contracts.length === 0) {
      clearState("io");
      clearState("contracts");
      clearStateDataByKey("contracts", "contracts");
      // clearStateDataByKey("io", "ios");
      clearStateDataByKey("io", "io");
      clearStateDataByKey("io", "singleIoDidFetch");
      fetchAllIos(entityId);
    }
  }, []);

  useEffect(() => {
    if (claTsId) {
      fetchSingleIo(claTsId);
    } else {
      clearStateDataByKey("io", "io");
      clearStateDataByKey("contracts", "contracts");
    }
  }, [claTsId]);

  useEffect(() => {
    if (singleIoDidFetch) {
      const contractsList = [];

      if (progress.claTsFinished) {
        contractsList.push("term-sheet-cla");
      }

      if (progress.claFinished) {
        contractsList.push("cla");
      }

      getContractsPreview({
        io_id: claTsId,
        contracts: contractsList
      });
    }
  }, [singleIoDidFetch]);

  useEffect(() => {
    if (closedByFounderState) {
      setTimeout(() => {
        history.push("/dashboard/generator");
        clearStateDataByKey("io", "io");
      }, 3000);
    }
  }, [closedByFounderState]);

  const handleDealClose = () =>
    baseModal.confirm({
      title:
        "Are you sure you want to close this convertible? The effects are irreversible.",
      onOk: () =>
        updateIoClosingStatus({
          cuid,
          closed: closedValues.CLOSED_BY_FOUNDER,
          type: "cla_closed"
        })
    });

  const handleAttachmentUpdate = attachmentId => checked =>
    patchAttachment({ ioId: cuid, attachmentId, downloadable: checked });

  const closedByFounder = closed >= closedValues.CLOSED_BY_FOUNDER;

  const renderClosedTooltip = tooltip => {
    if (closedByFounder) {
      return "Deal has been closed";
    }

    return tooltip;
  };

  const handleDuplicate = () => {
    const defaults = {
      type: io.type,
      entityId,
      leadInvestor: io.leadInvestor.cuid,
      fundingGoal: io.fundingGoal,
      minimal: 1000,
      acceptsCommitments: false,
      currency: io.currency.cuid,
      closed: io.closed
    };
    const { cla: { tsUpdateCounter, claUpdateCounter, ...filteredCla } = {} } =
      io || {};
    createDealAndBulkMembers({
      dealData: {
        name: `Copy of ${io.name}`,
        ...defaults,
        cla: filteredCla
      },
      memberships: []
    });
  };

  const reduceAttachments = () => {
    const shouldDisplayContracts = !closedByFounder || !closed;

    const readableContracts = contracts
      .filter(
        ({ contractType }) =>
          contractType === "term-sheet-cla" || contractType === "cla"
      )
      .map(item => ({
        ...item,
        name: item.contractName,
        url: item.value,
        ext: ".doc",
        downloadable: true
      }));

    const [initList, categoryKey] = shouldDisplayContracts
      ? [readableContracts, "contractType"]
      : [attachments, "type"];

    return initList.reduce((acc, item) => {
      const { [categoryKey]: category } = item || {};

      const subCategory = Object.keys(ioContractCategories).find(key =>
        ioContractCategories[key].includes(category)
      );

      const key =
        equityAttachmentCategories[subCategory] ||
        equityAttachmentCategories.TERMSHEET;
      const currItems = acc[key] || [];

      return {
        ...acc,
        [key]: [...currItems, item]
      };
    }, {});
  };

  const handleContacUsModal = () => {
    setShowModal(true);
  };
  const handleContactUsModalCancel = () => {
    setShowModal(false);
  };

  return (
    <div>
      <ContentFallbackScreen>
        {contractsFetching ? (
          <SpinContainer />
        ) : (
          <BasicWrapper className="equity-overview">
            {cuid && (
              <ActionContainer
                buttons={[
                  !closedByFounder && {
                    label: "Duplicate",
                    onClick: handleDuplicate,
                    size: "large",
                    loading: isCreating,
                    type: "default"
                  },
                  {
                    label: closedByFounder
                      ? "Deal is closed"
                      : "Close convertible",
                    onClick: handleDealClose,
                    loading,
                    disabled: closed < closedValues.CLA_DONE || closedByFounder,
                    tooltip:
                      closed < closedValues.CLA_DONE
                        ? "Please finish all the forms first."
                        : null,
                    size: "large",
                    type: "ghost",
                    className: "btn-danger"
                  }
                ]}
                actionVisible
              >
                <DealTitle
                  dealName={dealName}
                  title={entity.name}
                  {...entity}
                />
              </ActionContainer>
            )}
            {!closedByFounder && (
              <Fragment>
                <ClaDescription />
                <Controls
                  list={[
                    {
                      label: `Termsheet`,
                      description: (
                        <span>
                          With a convertible loan, companies are able to raise
                          funds at a relatively low cost by deferring the
                          negotiation and complexity of a priced round until a
                          later time. Although convertible loan transaction
                          documents are simple enough that no term sheet may be
                          required, they are often used to discuss the terms of
                          the deal. If used, a convertible loan term sheet will
                          usually include among others, the following terms:
                          <br />
                          <ul>
                            <li>Aggregate amount of the round</li>
                            <li>Interest, if any</li>
                            <li>Discount rate, if any</li>
                            <li>Valuation cap, if any</li>
                            <li>Maturity date</li>
                            <li>Conversion triggering event</li>
                          </ul>
                        </span>
                      ),
                      href: claTsId
                        ? `/create/cla_termsheet/${claTsId}`
                        : "/create/cla_termsheet",
                      disabled: closedByFounder,
                      tooltip: renderClosedTooltip()
                    },
                    {
                      label: `Convertible loan agreement`,
                      description: `As mentioned above, the convertible loan is governed by its underlying agreement. In other words, the CLA can be understood as the binding version of a Convertible Loan Termsheet.`,
                      href: claTsId
                        ? `/create/cla-questionnaire/${claTsId}`
                        : `/create/cla-questionnaire/${cuid}`,
                      disabled:
                        !progress.claTsFinished ||
                        closedByFounder ||
                        !canCreate,
                      tooltip: renderClosedTooltip(
                        !progress.claTsFinished
                          ? "Please complete the Termsheet generation form first"
                          : "Please contact us to enable the convertible loan feature"
                      )
                    }
                  ]}
                />
              </Fragment>
            )}

            {claTsId && contractsFetched && (
              <DocumentsDrawer
                title="Legal documents"
                list={reduceAttachments()}
                handleAttachmentUpdate={handleAttachmentUpdate}
              />
            )}
          </BasicWrapper>
        )}
      </ContentFallbackScreen>
      {!canCreate && (
        <BasicWrapper className="margined-overview">
          {!closedByFounder && (
            <Fragment>
              <Controls
                list={[
                  {
                    label: `Get your convertible loan agreement `,
                    description: (
                      <span>
                        Reach out to save up to <b>CHF 2'000</b> in legal fees
                        and create easily as many convertible loan agreements as
                        you need
                      </span>
                    ),
                    onClick: handleContacUsModal,
                    customButtonTitle: "Contact us"
                  }
                ]}
              />
            </Fragment>
          )}
        </BasicWrapper>
      )}
      <BasicWrapper className="margined-overview">
        <span>
          Leva provides a self-service solution at users' discretion. We are not
          a law firm or a substitute for an attorney or law firm. We cannot
          provide any kind of advice, explanation, opinion, or recommendation
          about possible legal rights, remedies, defenses, options, tax
          implications, selection of forms, or strategies.
        </span>
      </BasicWrapper>
      {showModal && (
        <ContactUsContainer
          footer={null}
          visible={showModal}
          onCancel={handleContactUsModalCancel}
        />
      )}
    </div>
  );
};

ConvertibleView.propTypes = {
  contracts: PropTypes.array,
  getContractsPreview: PropTypes.func,
  fetchAllIos: PropTypes.func,
  loading: PropTypes.bool,
  io: PropTypes.object,
  didFetch: PropTypes.bool,
  entityId: PropTypes.string,
  entity: PropTypes.object,
  contractsFetching: PropTypes.bool,
  progress: PropTypes.object,
  clearState: PropTypes.func,
  clearStateDataByKey: PropTypes.func,
  updateIoClosingStatus: PropTypes.func,
  ioId: PropTypes.string,
  fetchSingleIo: PropTypes.func,
  patchAttachment: PropTypes.func,
  history: PropTypes.object,
  singleIoDidFetch: PropTypes.bool,
  contractsFetched: PropTypes.bool,
  createDealAndBulkMembers: PropTypes.func,
  isCreating: PropTypes.bool,
  canCreate: PropTypes.bool
};

export default withPageTitle(
  "Termsheet and Convertible loan agreement overview"
)(ConvertibleView);
