import { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import { injectScript, ejectScript } from "#helpers/scriptFuncs";
import { handleNav } from "#helpers/general";
import {
  catchNotifications,
  dispatchActions,
  getNotifActionLink,
  NOTIFICATION_ACTIONS,
  NOTIFICATION_SCOPES
} from "#helpers/notifications";
import { register } from "../../serviceWorker";
import LayoutMarkup from "../Shared/Layouts/LayoutMarkup";
import routes from "../../routes";
import NotificationToast from "../Shared/NotificationToast/Container";
import ScrollRestoration from "../Shared/ScrollRestoration";
import ErrorHandler from "../Shared/ErrorHandler";
import AlertWrapper from "../Shared/Alert";
import Cookies from "../Shared/Cookies";

class App extends Component {
  componentDidMount() {
    const {
      authCompleted,
      handleUpdateAppNotif,
      triggerSnackbar,
      history
    } = this.props;
    injectScript();

    if (process.env.ENV === "PRE_MAINTENANCE") {
      triggerSnackbar({
        message: `We will do maintenance work on ${process.env.MAINTENANCE_TIME} to improve the platform for you. Some disruptions might happen. Thank you for
        your understanding.`,
        open: true,
        variant: "info"
      });
    }

    register({
      onUpdate: handleUpdateAppNotif
    });

    const propsBag = {
      history,
      updateNotifsList: this.props.updateNotifsList,
      triggerSnackbar,
      userId: this.props.userId
    };

    catchNotifications(dispatchActions(propsBag, this.updateUserData), {
      userId: this.props.userId,
      strict: true
    });

    if (authCompleted) {
      this.handlePostUserActivity();
    }
  }

  updateUserData = msg => {
    const { metadata: { action } = {} } = msg;

    if (action === NOTIFICATION_SCOPES.KYC_APPROVAL) {
      this.props.getCurrentUser();
    }
  };

  componentDidUpdate(prevProps) {
    if (
      !this.props.authCompleted &&
      prevProps.authCompleted !== this.props.authCompleted
    ) {
      injectScript();
    }
    if (this.props.authCompleted && prevProps.userId !== this.props.userId) {
      ejectScript();
      this.handlePostUserActivity();
    }
  }

  handlePostUserActivity() {
    const { userId, postUserActivity } = this.props;
    postUserActivity({
      userId,
      dateCreated: new Date()
    });
  }

  render() {
    const {
      roleNoKyc,
      kycFinished,
      location,
      history,
      authCompleted,
      confirmationLevel
    } = this.props;

    const { redirectTo } = queryString.parse(location.search) || {};
    if (authCompleted && redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    const isDashboardRoute = location.pathname.includes("dashboard");
    const kycAlert = !kycFinished
      ? "You haven't completed your onboarding process. Click here to finish your KYC."
      : "Thank you for finishing the onboarding process. We will review your data within 1 business day.";

    const alertVisible =
      isDashboardRoute &&
      !roleNoKyc &&
      ((kycFinished && confirmationLevel < 3) || !kycFinished) &&
      authCompleted;

    const kycAlertType = kycFinished ? "success" : "warning";

    return (
      <LayoutMarkup authCompleted={authCompleted}>
        <ErrorHandler>
          <Cookies location={location.pathname} />
          <AlertWrapper
            message={kycAlert}
            messageProps={{
              onClick: !kycFinished ? handleNav(history, "/kyc") : null
            }}
            type={kycAlertType}
            showIcon
            closable
            visible={alertVisible}
          />

          <ScrollRestoration />
          <NotificationToast />
          {routes}
        </ErrorHandler>
      </LayoutMarkup>
    );
  }
}

App.propTypes = {
  userId: PropTypes.any,
  postUserActivity: PropTypes.func,
  authCompleted: PropTypes.bool
};

export default App;
