import { useEffect, useState } from "react";
import { mapMembershipData } from "#helpers/mappers/memberships";
import { roles } from "#constants/roles";
import Modal from "../../Modal";
import Table from "../../SharedTable/Presentational";
import list from "./components/list";
import { Button } from "../../Button";

const InviteInvestorsPresentational = ({
  entityId,
  visible,
  handleClose,
  getIoMemberships,
  getEntityMemberships,
  membershipsFetching,
  ioMemberships,
  entityMemberships,
  bulkCreateIoMemberships,
  ioId,
  buttonStates,
  clearState
}) => {
  const [membershipsState, setMembershipsState] = useState([]);

  useEffect(() => {
    clearState("ioMemberships");
    if (ioId) {
      getEntityMemberships({ id: entityId, role: roles.AM_INVESTOR });
      getIoMemberships({ io_id: ioId });
    }
  }, [ioId]);

  useEffect(() => {
    setMembershipsState(
      entityMemberships
        .map(item => {
          const { cuid } = item.user || {};

          if (!ioMemberships.find(({ userId }) => userId === cuid)) {
            return mapMembershipData(item);
          }
          return null;
        })
        .filter(Boolean)
    );
  }, [membershipsFetching]);

  const handleRowSelection = selectedRowKeys => {
    setMembershipsState(
      membershipsState.map((item, index) => {
        return {
          ...item,
          checked: selectedRowKeys.includes(index)
        };
      })
    );
  };

  const handleInviteSubmit = () => {
    const memberships = membershipsState
      .map(({ checked, value }) => {
        if (checked) {
          return {
            userId: value,
            permissionLevel: 3,
            ioId
          };
        }

        return null;
      })
      .filter(Boolean);

    bulkCreateIoMemberships(memberships);
  };

  return (
    <Modal
      title="Invite investors"
      visible={visible}
      onCancel={handleClose}
      footer={null}
      className="invite-investors-wrapper"
    >
      <Table
        hasRowSelection
        columns={list}
        dataSource={membershipsState}
        handleRowSelection={handleRowSelection}
        tableProps={{
          rowSelection: {
            selectedRowKeys: membershipsState
              .map(({ checked }, index) => (checked ? index : null))
              .filter(item => item !== null),
            onChange: handleRowSelection
          },
          pagination: false,
          loading: membershipsFetching
        }}
      />
      <div className="btn-container">
        <Button label="Invite" onClick={handleInviteSubmit} {...buttonStates} />
      </div>
    </Modal>
  );
};

export default InviteInvestorsPresentational;
