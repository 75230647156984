import { basicOption, inputNumberFormatter } from "#helpers/general";
import { equityTs } from "#constants/io/equityPool";
import {
  renderOtherInputWithoutId,
  indexItem,
  confirmationPrompt
} from "../../../common/prompts";
import { investorCols } from "./tableCols";

const boardOfDirectors = (values, handlers) => {
  const maxBoardMemberNum =
    values[equityTs.DIRECTORS_NUMBER] === "Other"
      ? Number(values[equityTs.DIRECTORS_NUMBER_OTHER])
      : Number(values[equityTs.DIRECTORS_NUMBER]);

  const invDirectorNum =
    (values[equityTs.INVESTOR_DIRECTOR] === "Other"
      ? Number(values[equityTs.INVESTOR_DIRECTOR_OTHER])
      : Number(values[equityTs.INVESTOR_DIRECTOR])) || 0;

  const founderDirectorNum =
    (values[equityTs.FOUNDER_DIRECTOR] === "Other"
      ? Number(values[equityTs.FOUNDER_DIRECTOR_OTHER])
      : values[equityTs.FOUNDER_DIRECTOR]) || 0;

  const independentDirectorsNum =
    (values[equityTs.INDEPENDENT_DIRECTORS_NUM] === "Other"
      ? Number(values[equityTs.INDEPENDENT_DIRECTORS_NUM_OTHER])
      : Number(values[equityTs.INDEPENDENT_DIRECTORS_NUM])) || 0;

  const maxFounderDirectorNum = invDirectorNum
    ? maxBoardMemberNum - invDirectorNum
    : maxBoardMemberNum;

  const maxIndependentDirectorsNum =
    maxBoardMemberNum - invDirectorNum - founderDirectorNum;
  return [
    {
      title: `How many board members ("Directors") shall the Company have after this equity financing round?`,
      input: {
        type: "select_btn",
        name: equityTs.DIRECTORS_NUMBER,
        options: [basicOption("1"), basicOption("2"), basicOption("Other")]
      }
    },
    values[equityTs.DIRECTORS_NUMBER] === "Other"
      ? renderOtherInputWithoutId(equityTs.DIRECTORS_NUMBER_OTHER, "number")
      : null,
    {
      title:
        "How many Directors shall be designated by and represent the investors / holders of preferred shares?",
      input: {
        type: "select_btn",
        name: equityTs.INVESTOR_DIRECTOR,
        options: [
          basicOption("0"),
          basicOption("1", "1", {
            disabled:
              maxBoardMemberNum < 1 ||
              independentDirectorsNum >= 1 ||
              founderDirectorNum >= 1,
            tooltip:
              maxBoardMemberNum < 1 ||
              independentDirectorsNum >= 1 ||
              founderDirectorNum >= 1
                ? "The total number of directors representing investors and founders shall not exceed the maximal number of directors selected in the first question."
                : null
          }),
          basicOption("2", "2", {
            disabled: maxBoardMemberNum < 2,
            tooltip:
              maxBoardMemberNum < 2
                ? "The total number of directors representing investors and founders shall not exceed the maximal number of directors selected in the first question."
                : null
          }),
          basicOption("Other", "Other", {
            disabled: maxBoardMemberNum < 3,
            tooltip: maxBoardMemberNum
              ? "The total number of directors representing investors and founders shall not exceed the maximal number of directors selected in the first question."
              : null
          })
        ]
      }
    },

    values[equityTs.INVESTOR_DIRECTOR] === "Other"
      ? renderOtherInputWithoutId(equityTs.INVESTOR_DIRECTOR_OTHER, "number", {
          max: maxBoardMemberNum
        })
      : null,
    invDirectorNum >= 1 && {
      title: "Who shall have the right to nominate the Investor Director(s)?",
      input: {
        type: "select_btn",
        name: equityTs.RIGHT_TO_NOMINATE_INVESTOR,
        options: [
          basicOption("each_investor", "Each Investor"),
          basicOption("simple_majority", "Simple majority of all Investors"),
          basicOption("specific_investor", "Specific Investor")
        ]
      }
    },
    values[equityTs.RIGHT_TO_NOMINATE_INVESTOR] === "specific_investor" && {
      input: {
        type: "table_list",
        name: "specificInvestors",
        cols: investorCols(
          handlers,
          "specificInvestors",
          values.specificInvestors
        )
      }
    },
    (values[equityTs.RIGHT_TO_NOMINATE_INVESTOR] === "simple_majority" ||
      values[equityTs.RIGHT_TO_NOMINATE_INVESTOR] === "each_investor") &&
      invDirectorNum >= 1 && {
        title: `What shall the minimum aggregate shareholding of ${
          values[equityTs.RIGHT_TO_NOMINATE_INVESTOR] === "each_investor"
            ? "each investor "
            : "all investors "
        }be, to have the right to appoint an Investor Director?`,
        input: {
          type: "select_btn",
          name: equityTs.INVESTOR_DIRECTOR_PERCENTAGE,
          options: [
            basicOption("10%", "10%"),
            basicOption("20%", "20%"),
            basicOption("Other")
          ]
        }
      },
    values[equityTs.INVESTOR_DIRECTOR_PERCENTAGE] === "Other"
      ? renderOtherInputWithoutId(
          equityTs.INVESTOR_DIRECTOR_PERCENTAGE_OTHER,
          "number"
        )
      : null,
    {
      title: "How many board of directors will represent the Founders?",
      input: {
        type: "select_btn",
        name: equityTs.FOUNDER_DIRECTOR,
        options: [
          basicOption("0"),
          basicOption("1", "1", {
            disabled:
              maxBoardMemberNum < 1 ||
              maxFounderDirectorNum < 1 ||
              independentDirectorsNum >= 1,
            tooltip:
              maxBoardMemberNum < 1 ||
              maxFounderDirectorNum < 1 ||
              independentDirectorsNum >= 1
                ? "The total number of directors representing investors and founders shall not exceed the maximal number of directors selected in the first question."
                : null
          }),
          basicOption("2", "2", {
            disabled: maxBoardMemberNum < 2 || maxFounderDirectorNum < 2,
            tooltip:
              maxBoardMemberNum < 2 || maxFounderDirectorNum < 2
                ? "The total number of directors representing investors and founders shall not exceed the maximal number of directors selected in the first question."
                : null
          }),
          basicOption("Other", "Other", {
            disabled: maxBoardMemberNum < 3 || maxFounderDirectorNum < 3,
            tooltip:
              maxBoardMemberNum < 3 || maxFounderDirectorNum < 3
                ? "The total number of directors representing investors and founders shall not exceed the maximal number of directors selected in the first question."
                : null
          })
        ]
      }
    },
    values[equityTs.FOUNDER_DIRECTOR] === "Other"
      ? renderOtherInputWithoutId(equityTs.FOUNDER_DIRECTOR_OTHER, "number", {
          max: maxFounderDirectorNum
        })
      : null,
    {
      title: "Shall there be independent board members? If so, how many?",
      input: {
        name: equityTs.INDEPENDENT_DIRECTORS_NUM,
        type: "select_btn",
        options: [
          basicOption("0"),
          basicOption("1", "1", {
            disabled: maxIndependentDirectorsNum < 1,
            tooltip:
              maxIndependentDirectorsNum < 1
                ? "The total number of directors representing investors and founders shall not exceed the maximal number of directors selected in the first question."
                : null
          }),
          basicOption("2", "2", {
            disabled: maxIndependentDirectorsNum < 2,
            tooltip:
              maxIndependentDirectorsNum < 2
                ? "The total number of directors representing investors and founders shall not exceed the maximal number of directors selected in the first question."
                : null
          }),
          basicOption("Other", "Other", {
            disabled: maxIndependentDirectorsNum < 3,
            tooltip:
              maxIndependentDirectorsNum < 3
                ? "The total number of directors representing investors and founders shall not exceed the maximal number of directors selected in the first question."
                : null
          })
        ]
      }
    },
    values[equityTs.INDEPENDENT_DIRECTORS_NUM] === "Other"
      ? renderOtherInputWithoutId(
          equityTs.INDEPENDENT_DIRECTORS_NUM_OTHER,
          "number"
        )
      : null,
    values[equityTs.INDEPENDENT_DIRECTORS_NUM] > 0 ||
    values[equityTs.INDEPENDENT_DIRECTORS_NUM] === "Other"
      ? {
          title: "Who will appoint the Independent Director?",
          input: {
            name: equityTs.INDEPENDENT_DIRECTORS_APPOINTER,
            type: "select_btn",
            options: [
              basicOption("board", "Board"),
              basicOption("shareholders", "Shareholders")
            ]
          }
        }
      : null,

    (values[equityTs.INDEPENDENT_DIRECTORS_NUM] > 0 ||
      values[equityTs.INDEPENDENT_DIRECTORS_NUM] === "Other") &&
    values[equityTs.INDEPENDENT_DIRECTORS_APPOINTER] === "shareholders"
      ? {
          title:
            "What percentage of shareholders have to nominate the Independent Director?",
          input: {
            name: equityTs.INDEPENDENT_DIRECOTRS_APPOINTMENT_PERCENT,
            type: "select_btn",
            options: [
              basicOption("simple_majority", "Simple majority"),
              basicOption("two_thirds", "66 (2/3)%")
            ]
          }
        }
      : null,
    ...(invDirectorNum > 0
      ? [
          {
            title: `Who shall nominate the chairperson after the initial chairperson's term?`,
            input: {
              name: equityTs.INDEPENDENT_CHAIRMAN_APPOINTMENT,
              type: "select_btn",
              options: [basicOption("Board"), basicOption("Shareholder")]
            }
          },
          values[equityTs.INDEPENDENT_CHAIRMAN_APPOINTMENT] ===
            "Shareholder" && {
            title:
              "What percentage of shareholders have to nominate the chairman?",
            input: {
              name: equityTs.INDEPENDENT_CHAIRMAN_APPOINTMENT_PERCENT,
              type: "select_btn",
              options: [
                basicOption("Simple majority"),
                basicOption("66 (2/3)%")
              ]
            }
          }
        ]
      : []),
    {
      title: "Shall there be any Board Observers?",
      input: {
        name: equityTs.BOARD_OBSERVER,
        ...confirmationPrompt
      }
    },
    values[equityTs.BOARD_OBSERVER] === "yes" && {
      title: "Who shall have the right to appoint a Board Observer?",
      input: {
        type: "select_btn",
        name: equityTs.RIGHT_TO_NOMINATE_BOARD_OBSERVER,
        options: [
          basicOption("each_investor", "Each Investor"),
          basicOption("specific_investor", "Specific Investor")
        ]
      }
    },
    values[equityTs.RIGHT_TO_NOMINATE_BOARD_OBSERVER] ===
      "specific_investor" && {
      input: {
        type: "table_list",
        name: "boardObeserverSelectors",
        cols: investorCols(
          handlers,
          "boardObeserverSelectors",
          values.boardObeserverSelectors
        )
      }
    },

    values[equityTs.RIGHT_TO_NOMINATE_BOARD_OBSERVER] === "each_investor"
      ? {
          title:
            "What shall the minimum shareholding of an Investor be, to have the right to appoint a Board Observer?",
          input: {
            type: "select_btn",
            name: equityTs.BOARD_OBSERVER_PERCENTAGE,
            options: [
              basicOption("10%", "10%"),
              basicOption("20%", "20%"),
              basicOption("Other")
            ]
          }
        }
      : null,
    values[equityTs.BOARD_OBSERVER_PERCENTAGE] === "Other"
      ? renderOtherInputWithoutId(
          equityTs.BOARD_OBSERVER_PERCENTAGE_OTHER,
          "text"
        )
      : null
  ];
};

const boardOfDirectorsPower = (values, currency) => {
  const boardMattersRef = {
    href: "/doc_viewer?doc=/docs/boardMatters.pdf",
    rel: "noreferrer noopener",
    target: "_blank"
  };

  const invDirectorNum =
    values[equityTs.INVESTOR_DIRECTOR] === "Other"
      ? Number(values[equityTs.INVESTOR_DIRECTOR_OTHER])
      : Number(values[equityTs.INVESTOR_DIRECTOR]);

  const independentDirectorsNum =
    (values[equityTs.INDEPENDENT_DIRECTORS_NUM] === "Other"
      ? Number(values[equityTs.INDEPENDENT_DIRECTORS_NUM_OTHER])
      : Number(values[equityTs.INDEPENDENT_DIRECTORS_NUM])) || 0;
  return [
    {
      title: (
        <span>
          What percentage of any shares may be transferred to one acquirer
          without qualifying the transfer as an important{" "}
          <a {...boardMattersRef}>board matter</a>?
        </span>
      ),
      input: {
        type: "select_btn",
        name: equityTs.BOARD_MEMBERS_ACQUIRE_PERCENTAGE,
        options: [basicOption("66 (2/3)%", "66 (2/3)%"), basicOption("Other")]
      }
    },
    values[equityTs.BOARD_MEMBERS_ACQUIRE_PERCENTAGE] === "Other"
      ? renderOtherInputWithoutId(
          equityTs.BOARD_MEMBERS_ACQUIRE_PERCENTAGE_OTHER,
          "number"
        )
      : null,
    {
      title:
        "From what amount shall any investment, capital expenditure, sale of assets, incurrence of debt or any contract obligation by the Company be qualified as a significant board matter?",

      input: {
        type: "select_btn",
        name: equityTs.BOARD_MATTERS_EXEEDING_AMOUNT,
        options: [
          basicOption("20000", "CHF 20k"),
          basicOption("100000", "CHF 100k"),
          basicOption("Other")
        ]
      }
    },
    values[equityTs.BOARD_MATTERS_EXEEDING_AMOUNT] === "Other"
      ? renderOtherInputWithoutId(
          equityTs.BOARD_MATTERS_EXEEDING_AMOUNT_OTHER,
          "number",
          { ...inputNumberFormatter(currency) }
        )
      : null,
    {
      title:
        "From what amount shall any agreement providing the obligations be qualified as a significant board matter?",
      input: {
        type: "select_btn",
        name: equityTs.BOARD_MATTERS_EXEEDING_OBLIGATION,
        options: [
          basicOption("20000", "CHF 20k"),
          basicOption("100000", "CHF 100k"),
          basicOption("Other")
        ]
      }
    },
    values[equityTs.BOARD_MATTERS_EXEEDING_OBLIGATION] === "Other"
      ? renderOtherInputWithoutId(
          equityTs.BOARD_MATTERS_EXEEDING_OBLIGATION_OTHER,
          "number",
          { ...inputNumberFormatter(currency) }
        )
      : null,
    {
      title: `From what amount shall any security interests upon any part of the Company's property or assets in any form whatsoever be qualified as a significant board matter?`,
      input: {
        type: "select_btn",
        name: equityTs.BOARD_MATTERS_EXEEDING_SEC_INT,
        options: [
          basicOption("20000", "CHF 20k"),
          basicOption("100000", "CHF 100k"),
          basicOption("Other")
        ]
      }
    },
    values[equityTs.BOARD_MATTERS_EXEEDING_SEC_INT] === "Other"
      ? renderOtherInputWithoutId(
          equityTs.BOARD_MATTERS_EXEEDING_SEC_INT_VALUE,
          "number",
          { ...inputNumberFormatter(currency) }
        )
      : null,
    invDirectorNum > 0
      ? {
          title: (
            <span>
              Is the consent of the Investor Director(s) required to resolve
              important{" "}
              <a
                href="/doc_viewer?doc=/docs/boardMatters.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                Board Matters?
              </a>{" "}
            </span>
          ),
          input: {
            type: "select_btn",
            name: equityTs.MAJORITY_CONSENT,
            options: [
              basicOption(
                "majority_of_board_members",
                "Majority of all board members",
                {
                  tooltip: `If you want to change any Board Matters, please reach out to X, our partner law firm .
            For more information reach out to hi@leva.pe`
                }
              ),
              basicOption("investors_more_power", "More power to investors", {
                tooltip: `If you want to change any Board Matters, please reach out to X, our partner law firm .
            For more information reach out to hi@leva.pe`
              })
            ]
          }
        }
      : null,
    values[equityTs.MAJORITY_CONSENT] === "investors_more_power" &&
    invDirectorNum > 0 &&
    independentDirectorsNum === 0
      ? {
          title:
            "How many investor directors have to agree separately on important Board Matters?",
          input: {
            type: "select_btn",
            name: equityTs.INVESTORS_CONSENT,
            options: [
              basicOption(
                "each_investor_director",
                "Each investor director",
                {}
              ),
              basicOption("at_least_one", "At least one investor director"),
              basicOption("other", "Other", {
                tooltip:
                  "Maximum is the number of board of directors representing the investors."
              })
            ]
          }
        }
      : null,
    values[equityTs.MAJORITY_CONSENT] === "investors_more_power" &&
    invDirectorNum > 0 &&
    independentDirectorsNum > 0
      ? {
          title:
            "How many investor directors have to agree separately on important Board Matters?",
          input: {
            type: "select_btn",
            name: equityTs.INVESTORS_CONSENT,
            options: [
              basicOption(
                "each_investor_director",
                "Each investor director",
                {}
              ),
              basicOption("at_least_one", "At least one investor director"),
              basicOption(
                "one_investor_or_independent",
                "One of the investor directors or the independent director"
              ),
              basicOption("other", "Other", {
                tooltip:
                  "Maximum is the number of board of directors representing the investors."
              })
            ]
          }
        }
      : null,
    values[equityTs.INVESTORS_CONSENT] === "other"
      ? renderOtherInputWithoutId(equityTs.INVESTORS_CONSENT_OTHER, "number", {
          max: invDirectorNum - 1
        })
      : null
  ];
};
export default [boardOfDirectors, boardOfDirectorsPower];
