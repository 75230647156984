import { DocsIcon, XlsIcon, ImgIcon } from "../components/Shared/Icons/Files";
import { Pdf } from "../components/Shared/Icons";
import { contractNames, investmentOpportunityTypes } from "#constants/deal";
import {
  equityDealDocs,
  claDealDocs,
  fundDealDocs,
  safeDealDocs,
  kissDealDocs,
  bsaAirDealDocs
} from "#constants/io/documents";

export const deriveFileExt = url => url.match(/\.[0-9a-z]+$/i);

export const returnExtIcon = ext => {
  const docFileTypes = [".doc", ".docx"];
  const xlsFileTypes = [".xls", ".xlsx"];
  const imgFileTypes = [".jpg", ".jpeg", ".png"];

  if (docFileTypes.includes(ext)) {
    return DocsIcon;
  } else if (xlsFileTypes.includes(ext)) {
    return XlsIcon;
  } else if (imgFileTypes.includes(ext)) {
    return ImgIcon;
  }

  return Pdf;
};

export const getMissingAttachments = (attachments, type) => {
  let documentSchema = {};

  if (type === investmentOpportunityTypes.POOLED_EQUITY) {
    documentSchema = equityDealDocs;
  } else if (
    type === investmentOpportunityTypes.POOLED_DEBT ||
    type === investmentOpportunityTypes.POOLED_HYBRID_CLA
  ) {
    documentSchema = claDealDocs;
  } else if (type === investmentOpportunityTypes.SAFE)
    documentSchema = safeDealDocs;
  else if (type === investmentOpportunityTypes.KISS)
    documentSchema = kissDealDocs;
  else if (type === investmentOpportunityTypes.BSA_AIR)
    documentSchema = bsaAirDealDocs;
  else {
    documentSchema = fundDealDocs;
  }

  const missingAttachs = attachments.reduce((acc, item) => {
    const { subCategory } = item;

    if (acc[subCategory] !== null) {
      return {
        ...acc,
        [subCategory]: 2
      };
    }

    return acc;
  }, documentSchema);

  const [optional_docs_list, mandatory_docs_list] = Object.keys(
    missingAttachs
  ).reduce(
    (acc, key) => {
      const item = missingAttachs[key];

      if (item === 0) {
        return [[...(acc[0] || []), key], acc[1] || []];
      } else if (item === 1) {
        return [acc[0] || [], [...(acc[1] || []), key]];
      }

      return acc;
    },
    [[], []]
  );

  const readable_mandatory_list = mandatory_docs_list.map(
    name => contractNames[name]
  );
  const readable_optional_list = optional_docs_list.map(
    name => contractNames[name]
  );

  return [
    [readable_mandatory_list, readable_optional_list],
    readable_mandatory_list.length + readable_optional_list.length > 0,
    mandatory_docs_list.length > 0
  ];
};

export const getMissingAttachmentsLabel = (
  missingAttachs,
  days,
  daysElapsed
) => {
  const [mandatoryMissingAttachs, optionalMissingAttachs] = missingAttachs;
  let alert = "";

  const mandatoryMissingAttachsLabel = mandatoryMissingAttachs.join(", ");
  const optionalMissingAttachsLabel = optionalMissingAttachs.join(", ");

  if (mandatoryMissingAttachs.length > 0) {
    alert += `Please upload the following signed document to the data room: ${mandatoryMissingAttachsLabel}. Click on the plus icon, select the “Legal” category, and choose the right sub-category before uploading the document.`;
  }

  if (optionalMissingAttachs.length > 0) {
    alert +=
      alert.length > 0
        ? ` You may also upload the following equivalent optional doc(s): ${optionalMissingAttachsLabel}.`
        : `You may upload the following equivalent optional doc(s): ${optionalMissingAttachsLabel}.`;
  }

  return alert;
};

export const removeEmojis = fileName => {
  return fileName.replace(
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F700}-\u{1F77F}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}\u{1F004}\u{1F0CF}\u{1F004}\u{1F0CF}]/gu,
    ""
  );
};
