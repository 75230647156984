import { claTsKeys } from "#constants/cla";
import * as fields from "./fields";
import { indexItem } from "../../common/prompts";

const getList = ({
  values,
  currencies,
  convertibleTypes,
  inputHandlers,
  formikProps,
  esopShares,
  name,
  shares,
  annualInterestRate,
  governingLaw,
  currPath,
  handleFormSave,
  psopShares
}) => {
  const {
    generalInformation,
    financials: [financialInformation, investor, interest],
    conversion: [conversionInformation]
  } = fields;
  const { shortName: currency } =
    currencies.find(({ cuid }) => cuid === values[claTsKeys.CURRENCY]) || {};

  const indexedGeneralInformation = generalInformation(
    values,
    shares,
    inputHandlers,
    governingLaw,
    handleFormSave,
    currPath
  )
    .filter(Boolean)
    .map(indexItem);

  const indexedFinancialInformation = financialInformation(
    currencies,
    currency,
    values
  )
    .filter(Boolean)
    .map(indexItem);

  const indexedInvestor = investor(
    values,
    { currency, inputHandlers, name },
    handleFormSave,
    currPath
  )
    .filter(Boolean)
    .map(indexItem);

  const indexedInterest = interest(values, currency, annualInterestRate)
    .filter(Boolean)
    .map(indexItem);

  const indexedConversionInformation = conversionInformation(
    values,
    currency,
    inputHandlers,
    esopShares,
    psopShares,
    governingLaw
  )
    .filter(Boolean)
    .map(indexItem);

  return [
    {
      sectionId: 1,
      items: indexedGeneralInformation
    },
    {
      sectionId: 2,
      subSectionId: 2,
      items: indexedFinancialInformation
    },
    {
      sectionId: 2,
      subSectionId: 3,
      items: indexedInvestor
    },
    {
      sectionId: 2,
      subSectionId: 4,
      items: indexedInterest
    },
    { sectionId: 5, items: indexedConversionInformation }
  ];
};

export default getList;
