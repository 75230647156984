import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './actionTypes';
import actions from '../../constants/action_type';

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.TRIGGER:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          return {
            ...action.payload.snackbar
          };
        }
      });
    default:
      return state;
  }
};
export default reducer;
