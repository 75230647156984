import { formatNumber } from "#helpers/number_format";
import Icon from "antd/es/icon";
import TagWrapper from "../../../../../Shared/Tag";
import Tooltip from "../../../../../Shared/Tooltip";
import { Popconfirm } from "antd";

export const shareTableCols = (handleDelete, handleEdit) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "shareType",
      render: (text, { type }) => {
        return (
          <TagWrapper
            color={
              type === "common_with_preferential_rights"
                ? "custom-green"
                : type === "common"
                ? "blue"
                : "red"
            }
          >
            {text}
          </TagWrapper>
        );
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "shareName",
      render: text => {
        return (
          <TagWrapper
            className="custom-min-width"
            color={
              text === "common_with_preferential_rights"
                ? "custom-green"
                : text === "common"
                ? "blue"
                : "red"
            }
          >
            <Tooltip
              title={
                text === "common_with_preferential_rights"
                  ? "Preferential Common Shares"
                  : text === "common"
                  ? "Common shares"
                  : text === "preferred"
                  ? "Preferred shares"
                  : text
              }
            >
              {text === "common_with_preferential_rights"
                ? "C+"
                : text === "preferred"
                ? "P"
                : text === "common"
                ? "C"
                : text}
            </Tooltip>
          </TagWrapper>
        );
      }
    },
    {
      title: "Voting right",
      dataIndex: "votingRights",
      key: "votingRights",
      render: () => <div> 1/share </div>
    },
    {
      title: "Number of shares",
      dataIndex: "total",
      key: "shares",
      render: text => formatNumber(text)
    },
    {
      title: "Nominal value",
      dataIndex: "nominalValue",
      key: "nominalValue",
      render: (text, { currency: { shortName = "" } = {} }) =>
        `${shortName} ${formatNumber(Number(text))}`
    },
    {
      title: "Action",
      key: "action",
      render: (text, { cuid = "" }) => (
        <div>
          <Icon
            onClick={handleEdit(cuid)}
            type="edit"
            className="icon stakeholder-management-icon"
          />
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={handleDelete(cuid)}
          >
            <Icon type="delete" className="icon stakeholder-management-icon" />
          </Popconfirm>
        </div>
      )
    }
  ];
};
