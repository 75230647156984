import { triggerSnackbar } from "../../snackbar/actions";
import { pushNotifsStatus } from "../../../../api/notificationsService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const updateNotifsList = data => {
  return dispatch => {
    try {
      success(dispatch, types.UPDATE_NOTIFS_LIST, { data });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_NOTIFS_LIST, {
        error: errorData
      });
    }
  };
};

export const updateNotifsStatus = ({ cuid, ...data }) => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_NOTIFS_STATUS);
      await pushNotifsStatus({ cuid, ...data });
      success(dispatch, types.UPDATE_NOTIFS_STATUS, {
        data: cuid
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_NOTIFS_STATUS, {
        error: errorData
      });
    }
  };
};

export const bulkUpdateNotifsStatus = data => {
  return async dispatch => {
    try {
      start(dispatch, types.BULK_UPDATE_NOTIFS_STATUS);

      await Promise.all(data.map(async item => pushNotifsStatus(item)));

      success(dispatch, types.BULK_UPDATE_NOTIFS_STATUS, { data });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.BULK_UPDATE_NOTIFS_STATUS, {
        error: errorData
      });
    }
  };
};

export const updateAppNotif = registration => {
  return async dispatch => {
    try {
      const waitingWorker = registration.waiting;

      triggerSnackbar(dispatch, {
        message: "Leva has been updated.",
        open: true,
        variant: "success",
        action: {
          description: "Click the Refresh button to get the latest version",
          label: "Refresh",
          handlers: {
            onClick: () => {
              waitingWorker.postMessage({ type: "SKIP_WAITING" });
              window.location.reload();
            }
          }
        },
        duration: 0
      });
      success(dispatch, types.UPDATE_APP);
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_APP, {
        error: errorData
      });
    }
  };
};
