import { Fragment } from "react";
import PropTypes from "prop-types";
import SectionItem from "../SectionItem";

const Sections = ({
  sections,
  helpers,
  currentStep,
  clickableSection,
  clickHandler,
  success
}) => {
  return (
    <Fragment>
      <div className="list-container">
        {sections.map(({ id, ...item }) => (
          <SectionItem
            key={id}
            {...item}
            type="section"
            subSectionId={currentStep.subSectionId}
            current={currentStep.sectionId === id}
            completed={currentStep.sectionId > id || success}
            onClick={clickableSection ? clickHandler(id - 1) : null}
            clickHandler={clickHandler}
          />
        ))}
      </div>
      {helpers.length > 0 && (
        <div className="list-container">
          <h3>Need our help?</h3>
          {helpers.map(({ id, ...item }) => (
            <SectionItem key={id} {...item} />
          ))}
        </div>
      )}
    </Fragment>
  );
};

Sections.propTypes = {
  sections: PropTypes.array,
  helpers: PropTypes.array,
  clickHandler: PropTypes.any,
  currentStep: PropTypes.object,
  success: PropTypes.bool,
  clickableSection: PropTypes.bool
};

export default Sections;
