import { basicOption } from "#helpers/general";

export const confirmationPrompt = {
  type: "select_btn",
  options: [basicOption("yes", "Yes"), basicOption("no", "No")]
};

export const disabledConfirmationPrompt = {
  type: "select_btn",
  options: [
    basicOption("yes", "Yes", { disabled: true }),
    basicOption("no", "No", { disabled: true })
  ]
};

export const renderOtherInput = (id, name, type = "text", input = {}) => ({
  id,
  input: { type, name, ...input }
});
export const renderOtherInputWithoutId = (name, type = "text", input = {}) => ({
  input: {
    type,
    name,
    min: type === "number" ? 1 : "",
    formatter: value => (value && value[0] === "0" ? "" : value),
    ...input
  }
});

export const indexItem = (item, index) => ({ id: index + 1, ...item });
