import { Switch, Icon, Tabs } from "antd";
import PropTypes from "prop-types";
import ioTypes from "#constants/io/ioTypes";
import { Button } from "../../../../../Shared/Button";
import { PdfViewer } from "../../../../../PDFViewer/Presentational";
import SpinContainer from "../../../../SpinContainer";
import { useState } from "react";

const SecondStep = ({
  attachmentTerms,
  handleSetState,
  contracts,
  loading,
  type
}) => {
  const initKey = type === ioTypes.POOLED_FUND ? "spa" : "tos";

  const [activeKey, setActiveKey] = useState(initKey);

  const Terms = ({ name, checked, label }) => (
    <div className="terms-and-conditions">
      <div className="switch">
        <Switch
          className="terms-switch"
          size="default"
          checked={checked}
          onChange={handleSetState(name)}
        />
      </div>
      <div className="terms">{label}</div>
    </div>
  );

  const tos =
    contracts.find(({ contractType }) => contractType === "syndicate-terms") ||
    {};

  const spa =
    contracts.find(({ contractType }) => contractType === "spa") || {};

  const spaSubsAgr =
    contracts.find(
      ({ contractType }) => contractType === "spa-subscription-agreement"
    ) || {};

  const tabs = [
    {
      tab: "Terms of Syndicate",
      key: "tos",
      data: tos.value,
      display: type !== ioTypes.POOLED_FUND
    },
    {
      tab: "SPA",
      key: "spa",
      data: spa.value,
      display: type === ioTypes.POOLED_FUND
    },
    {
      tab: "Subscription Agreement",
      key: "spa-subs-agr",
      data: spaSubsAgr.value,
      display: type === ioTypes.POOLED_FUND
    }
  ];

  const MainViewer = (
    <Tabs
      activeKey={activeKey}
      onChange={key => {
        setActiveKey(key);
      }}
    >
      {tabs
        .filter(({ display }) => display)
        .map(({ tab, key, data }) => (
          <Tabs.TabPane tab={tab} key={key}>
            <PdfViewer data={data} />
          </Tabs.TabPane>
        ))}
    </Tabs>
  );

  const docsLabel = {
    tos: "TOS",
    spa: "SPA",
    "spa-subs-agr": "Subscription agreement"
  };

  const getCurrentDoc = key => {
    if (key === "spa") {
      return spa.value;
    } else if (key === "spa-subs-agr") {
      return spaSubsAgr.value;
    }

    return tos.value;
  };

  const signingLabel =
    type === ioTypes.POOLED_FUND
      ? "I have read, understood and agree to the terms of Simple Partnership Agreement and Subscription Agreement."
      : "I have read, understood and agree to the Terms of Syndicate.";

  return (
    <div className="contract-signing">
      {loading ? <SpinContainer /> : MainViewer}
      <Button
        icon="download"
        className="download-btn"
        label={
          <a
            href={getCurrentDoc(activeKey)}
            download
            title="Terms of syndicate"
          >
            Download {docsLabel[activeKey]}
          </a>
        }
      />
      <Terms
        name="attachmentTerms"
        checked={attachmentTerms}
        label={signingLabel}
      />
    </div>
  );
};

SecondStep.propTypes = {
  loading: PropTypes.bool,
  attachmentTerms: PropTypes.bool,
  handleSetState: PropTypes.func,
  contracts: PropTypes.array
};

export default SecondStep;
