import { format } from "date-fns";
import { formatNumber } from "#helpers/number_format";
import TagWrapper from "../../../../../Shared/Tag";
import Icon from "antd/es/icon";
import Tooltip from "../../../../../Shared/Tooltip";
import { Popconfirm } from "antd";
import Tick from "../../../../../../assets/illustrations/tick.svg";

export const mgmtCols = (handleDelete, handleEdit) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "shareType",
      render: text => <TagWrapper color="orange">{text}</TagWrapper>
    },
    {
      title: "Trigger Events",
      dataIndex: "triggeringEvent",
      key: "triggeringEvent",
      render: (data = []) =>
        data.map(triggerEvent => {
          if (triggerEvent === "next_financing_round")
            return (
              <TagWrapper className="custom-min-width" color="green">
                <Tooltip title="Next Financing Round">NFR</Tooltip>
              </TagWrapper>
            );
          if (triggerEvent === "sale_transaction")
            return (
              <TagWrapper className="custom-min-width" color="blue">
                <Tooltip title="Sale Transaction">ST</Tooltip>
              </TagWrapper>
            );
          if (triggerEvent === "maturity_date")
            return (
              <TagWrapper className="custom-min-width" color="teal">
                <Tooltip title="Maturity Date">MD</Tooltip>
              </TagWrapper>
            );
        })
    },

    {
      title: () => (
        <Tooltip title="Next Financing Round Valuation cap">NFR VC</Tooltip>
      ),
      dataIndex: "valuationCapNFR",
      key: "valuationCapNFR",
      render: text => (text ? `CHF ${formatNumber(text)}` : "N/A")
    },
    {
      title: () => (
        <Tooltip title="Next Financing Round Discount">NFR D</Tooltip>
      ),
      dataIndex: "discountNFR",
      key: "discountNFR",
      render: text => (text ? `${Number(Number(text)).toFixed(2)}%` : "N/A")
    },
    {
      title: () => <Tooltip title="Maturity Date Valuation cap">MT VC</Tooltip>,
      dataIndex: "valuationCapMD",
      key: "valuationCapMD",
      render: text => (text ? `CHF ${formatNumber(text)}` : "N/A")
    },
    {
      title: () => <Tooltip title="Sale Transaction Discount">ST D</Tooltip>,
      dataIndex: "discountST",
      key: "discountST",
      render: text => (text ? `${Number(Number(text)).toFixed(2)}%` : "N/A")
    },
    {
      title: () => (
        <Tooltip title="Sale Transaction Valuation cap">ST VC</Tooltip>
      ),
      dataIndex: "valuationCapST",
      key: "valuationCapST",
      render: text => (text ? `CHF ${formatNumber(text)}` : "N/A")
    },
    {
      title: "Amount",
      dataIndex: "currentRaisedTotal",
      key: "total",
      render: (text, { raisedAmount }) => {
        return text !== undefined || text !== null ? (
          <span>
            CHF {formatNumber(raisedAmount)}
            {text === raisedAmount && (
              <Tooltip title="The convertible amount equals your outstanding convertible amount">
                <img
                  style={{ margin: "0 0 6px 4%" }}
                  src={Tick}
                  alt="tick"
                  height="20px"
                />
              </Tooltip>
            )}
          </span>
        ) : (
          "N/A"
        );
      }
    },
    {
      title: "Interest",
      dataIndex: "annualInterestRate",
      key: "annualInterestRate",
      render: text => (text ? `${Number(Number(text)).toFixed(2)}%` : "N/A")
    },
    {
      title: "Maturity Date",
      dataIndex: "maturityDate",
      key: "maturityDate",
      render: maturityDate => format(maturityDate, "DD-MM-YYYY")
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Icon
            type="edit"
            className="icon stakeholder-management-icon"
            onClick={handleEdit(record)}
          />
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={handleDelete(record.cuid)}
          >
            <Icon type="delete" className="icon stakeholder-management-icon" />
          </Popconfirm>
        </div>
      )
    }
  ];
};

export const dummyData = [
  {
    cuid: 3,
    maturity: new Date(),
    name: "Convertible Loan C ",
    issueDate: new Date(),
    maturityDate: new Date(),
    mandatoryConversion: "voluntary",
    triggerEvent: ["next_financing_round", "sale_transaction"],
    underlyingSecurity: "yes",
    interest: "yes",
    subordinated: "yes",
    claAddition: "no",
    shareTypeConversion: "common",
    aoaRestriction: "yes",
    underlyingSecurityType: "common",
    min_amount: 12,
    valuationCapBool: "yes",
    preMoneyVal: 12,
    preMoneyValDiluted: "no",
    discountRateBool: "yes",
    discountRate: 12,
    saleTransactionDiscountRateBool: "yes",
    saleTransactionDiscountRate: 1,
    underlyignSecurityAllocShares: 2222,
    underlyingSecuritySharesNominalValue: 12,
    interestRate: 21,
    interestCalculation: "365",
    interestCompensation: "no"
  },
  {
    cuid: "2",
    name: "Convertible Loan A",
    amount: 4000,
    valuationCap: 3000,
    discount: 0.1,
    maturity: new Date(),
    issueDate: new Date(),
    maturityDate: new Date(),
    mandatoryConversion: "voluntary",
    triggerEvent: ["next_financing_round"],
    min_amount: 12,
    shareTypeConversion: "common",
    shareTransferRestriction: true,
    claSubordinated: true
  },
  {
    cuid: "3",
    name: "Convertible Loan B",
    amount: 150,
    valuationCap: 250,
    discount: 0.29,
    maturity: new Date()
  }
];
