import * as shareService from "../../../../api/shareService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const updateShareClassesById = (cuid, data) => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_SHARE_CLASSES_BY_ID);
      const response = await shareService.updateShareClassesById(cuid, data);
      success(dispatch, types.UPDATE_SHARE_CLASSES_BY_ID, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_SHARE_CLASSES_BY_ID, {
        error: errorData
      });
    }
  };
};

export const updateShareholderById = (cuid, data) => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_SHAREHOLDER_BY_ID);
      const response = await shareService.updateShareholder(cuid, data);
      success(dispatch, types.UPDATE_SHAREHOLDER_BY_ID, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_SHAREHOLDER_BY_ID, {
        error: errorData
      });
    }
  };
};
