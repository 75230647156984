//customers
import tugboat from "../../../../assets/illustrations/logolist/customers/tugboat.png";
import combinator from "../../../../assets/illustrations/logolist/customers/combinator.png";
import anchor from "../../../../assets/illustrations/logolist/customers/anchor.png";
import anker from "../../../../assets/illustrations/logolist/customers/anker.png";
import citadel from "../../../../assets/illustrations/logolist/customers/citadel.png";
import convergence from "../../../../assets/illustrations/logolist/customers/convergence.png";
import emip from "../../../../assets/illustrations/logolist/customers/emip.png";
import gfv from "../../../../assets/illustrations/logolist/customers/gfv.png";
import impact51 from "../../../../assets/illustrations/logolist/customers/impact51.png";
import sandorn from "../../../../assets/illustrations/logolist/customers/sandorn.png";
import venturelab from "../../../../assets/illustrations/logolist/customers/venturelab.png";
import infinitas from "../../../../assets/illustrations/logolist/customers/infinitas.png";
//startups
import apiax from "../../../../assets/illustrations/logolist/startups/APIAX.png";
import destinus from "../../../../assets/illustrations/logolist/startups/destinus.png";
import forme from "../../../../assets/illustrations/logolist/startups/forme.png";
import karmen from "../../../../assets/illustrations/logolist/startups/karmen.png";
import kickfund from "../../../../assets/illustrations/logolist/startups/kickfund.png";
import kraken from "../../../../assets/illustrations/logolist/startups/kraken.png";
import lapse from "../../../../assets/illustrations/logolist/startups/lapse.png";
import lyst from "../../../../assets/illustrations/logolist/startups/lyst.png";
import maximon from "../../../../assets/illustrations/logolist/startups/maximon.png";
import neurocare from "../../../../assets/illustrations/logolist/startups/neurocare.png";
import redapline from "../../../../assets/illustrations/logolist/startups/redapline.png";
import revolut from "../../../../assets/illustrations/logolist/startups/revolut.png";

export const customers = [
  [
    { content: tugboat },
    { content: venturelab },
    { content: convergence }
  ],
  [
    { content: sandorn },
    { content: emip },
    { content: gfv },
    { content: impact51 }
  ],
  [
    { content: infinitas },
    { content: anchor },
    { content: anker },
    { content: combinator }
  ]
];

export const startups = [
  [
    { content: apiax },
    { content: destinus },
    { content: forme },
    { content: karmen }
  ],
  [
    { content: kickfund },
    { content: kraken },
    { content: lapse },
    { content: lyst }
  ],
  [
    { content: maximon },
    { content: neurocare },
    { content: redapline },
    { content: revolut }
  ]
];
