export default [
  {
    id: 1,
    title: '1. Client segmentation'
  },
  {
    id: 2,
    title: '2. Risk warning'
  }
];
