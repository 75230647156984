import { AutoComplete } from "antd";
import Spin from "antd/es/spin";

const AutocompleteWrapper = ({
  dataSource,
  onSelect,
  onSearch,
  onChange: changeEvt,
  name,
  loading,
  ...props
}) => {
  return (
    <div className="autocomplete-wrapper">
      <AutoComplete
        size="large"
        className="autocomplete-container"
        dataSource={dataSource}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={changeEvt(name)}
        {...props}
      />
      {loading && <Spin />}
    </div>
  );
};

export default AutocompleteWrapper;
