import { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  SharesManagement,
  StakeholderManagement,
  ClaManagement
} from './components';

const Fundraising = ({ id, getEntity }) => {
  useEffect(() => {
    if (id) {
      getEntity(id);
    }
  }, []);

  return (
    <div className="fundraising-container">
      <StakeholderManagement />
      <SharesManagement />
      <ClaManagement />
    </div>
  );
};

Fundraising.propTypes = {
  id: PropTypes.string,
  getEntity: PropTypes.func
};

export default Fundraising;
