import moment from "moment";
import TagWrapper from "../../../Shared/Tag";

export const votingArchiveCols = (isAm, currUser, handleNav) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Category",
    dataIndex: "type",
    key: "category",
    render: val => <TagWrapper color="custom-green">{val}</TagWrapper>
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "date",
    render: val => {
      const createdDate = moment(val);
      const now = moment();
      const diff = now.diff(createdDate, "days");

      return diff === 0 ? "Today" : `${diff} day(s) ago`;
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: val => (val === 0 ? "Closed" : "Open")
  },
  !isAm
    ? {
        title: "Participated?",
        dataIndex: "results",
        key: "results",
        render: (val = [], { cuid }) => {
          return val.find(({ userId }) => userId === currUser) ? (
            "Yes"
          ) : (
            <span>
              No. Participate <a onClick={handleNav(`/voting/${cuid}`)}>here</a>
              .
            </span>
          );
        }
      }
    : null
];

export const testData = [
  {
    name: "Drr",
    category: "ete",
    date: "5 Days ago",
    status: "Downloaded"
  },
  {
    name: "Drr",
    category: "ete",
    date: "5 Days ago",
    status: "Downloaded"
  },
  {
    name: "Drr",
    category: "ete",
    date: "5 Days ago",
    status: "Downloaded"
  }
];
