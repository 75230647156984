import axios from "./apiConfig";

export const getSyndicates = params =>
  axios.get(`syndicates?entity_id=${params?.entityId}`, params);

export const getPortfolioSyndicates = params =>
  axios.get(
    `portfolio/syndicates${
      params?.io_status ? "?io_status=" + params?.io_status : ""
    }`,
    params
  );
export const getSyndicate = ({ cuid, ...params }) =>
  axios.get(`syndicates/${cuid}`, params);

export const postSyndicate = data => axios.post(`syndicates`, data);

export const patchSyndicate = ({ cuid, ...data }) =>
  axios.get(`syndicates/${cuid}`, data);

export const deleteSyndicate = ({ cuid }) => axios.get(`syndicates/${cuid}`);
