import { baseSelector } from "./utils";

const selectCurrenciesDataFunc = state => {
  const { currencies = [] } = state._data || {};

  return currencies;
};

const selectCurrenciesData = baseSelector(selectCurrenciesDataFunc);

export { selectCurrenciesData };
