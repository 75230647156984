import { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory } from "react-router";
import { registrationSchema } from "#validators/registrationSchema";
import { roles } from "#constants/roles";
import { checkIfMobileOrTablet } from "#helpers/general";
import AuthLayout from "../Authorization/common/Layout";
import { BasicForm } from "../Shared/Form";
import registrationFields from "../Authorization/RegistrationPage/components/list";

import { Disclaimers } from "./Views";
import { InvitationAcceptance } from "../Shared/Modals";
import SignUpGraphic from "../../assets/images/signup.png";
import AfterAcceptance from "./AfterAcceptance";

const OnboardingPresentational = ({
  buttonStates,
  user,
  didUpdate,
  onboardUser,
  location,
  clearState,
  hasPendingInvitations
}) => {
  const [view, setView] = useState(1);
  const [afterAcceptance, setAfterAcceptance] = useState(false);

  const history = useHistory();
  const {
    user_email: email,
    confirmation_token: confirmationToken
  } = queryString.parse(location.search);

  useEffect(() => {
    clearState("users");
  }, []);

  useEffect(() => {
    if (didUpdate) {
      setView(2);
    }
  }, [didUpdate]);

  const handleSetView = view => () => setView(view);

  const handleNav = () => {
    history.push("/dashboard/investments");
    setAfterAcceptance(false);
  };

  const handleSubmit = values => {
    const mobileOrTablet = checkIfMobileOrTablet();
    if (mobileOrTablet) {
      history.push("/phone");
    } else {
      onboardUser({
        ...values,
        investmentDetails: {},
        confirmationToken,
        tosAccepted: true,
        newsletter_accept: undefined,
        passwordMatch: undefined,
        email: undefined
      });
    }
  };

  const defaultedValues = { email, tosAccepted: false };
  const isFounder = user.userRole === roles.FOUNDER;

  const mainView = () => {
    if (view === 1) {
      return (
        <div className="onboarding-wrapper">
          <h1>Welcome to Leva.</h1>
          <h2>Please update your information to get started.</h2>
          <BasicForm
            fieldList={registrationFields(true)}
            values={defaultedValues}
            disabledFields={["email"]}
            validationSchema={registrationSchema}
            handleSubmit={handleSubmit}
            buttonStates={buttonStates}
          />
        </div>
      );
    } else if (view === 2) {
      return (
        <div className="onboarding-wrapper">
          {afterAcceptance ? (
            <AfterAcceptance handleNav={handleNav} />
          ) : (
            <InvitationAcceptance
              userFirstName={user.firstName}
              scope="onboarding"
              setAfterAcceptance={setAfterAcceptance}
            />
          )}
        </div>
      );
    }

    return <Disclaimers name={user.firstName} />;
  };

  return (
    <AuthLayout
      showLeftPane
      graphic={SignUpGraphic}
      leftPaneTitle="Glad to have you on board!"
      leftPaneSubtitle="Welcome to our invite-only investor community"
    >
      {mainView()}
    </AuthLayout>
  );
};

export default OnboardingPresentational;
