import { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import ModalWrapper from "../../../Shared/Modal";
import { BasicForm } from "../../../Shared/Form";

const AddItemsPresentational = ({
  fields,
  setFieldValue,
  name,
  value = [],
  onCancel,
  modalTitle,
  entityShares,
  formattedConvertibles,
  handleNotification,
  initValues,
  isEditing,
  existingShares = [],
  ...modalProps
}) => {
  const handleSubmit = (item = {}, resetForm) => {
    const selected = formattedConvertibles[item.type] || {};
    let allLocalType = 0;
    if (value.length > 0) {
      const mappedExistingShares = existingShares.map(({ _id }) => _id);
      const filteredValue = value.filter(
        ({ _id }) => !mappedExistingShares.includes(_id)
      );
      filteredValue.map(({ number }) => {
        allLocalType += number;
      });
    }
    if (
      Object.keys(selected).length > 0 &&
      item.number > selected.raisedAmount - selected.currentRaisedTotal &&
      !isEditing
    ) {
      onCancel();
    } else if (
      !item.paymentDate &&
      item.number > entityShares - allLocalType &&
      !isEditing
    ) {
      onCancel();
    } else {
      let finalValue = value;
      const onOk = () => {
        setFieldValue(name, [
          ...finalValue,
          { id: finalValue.length, ...item }
        ]);

        handleNotification(name, false);

        onCancel();
        resetForm();
      };

      if (value.length > 0) {
        finalValue = value.filter(({ type = "" }) => type !== item.type);
      }

      if (false) {
        const shareholdersNumber = selected.shareholdersNumber > 20 ? 20 : 10;

        const content = `Attention, the Company has more than ${shareholdersNumber} Investors, which were granted one or multiple convertible loan(s). Please consult a lawyer to clarify the financial regulatory impact.`;

        Modal.confirm({
          title: content,
          onOk
        });
      } else {
        onOk();
      }
    }
  };

  return (
    <ModalWrapper
      title={modalTitle}
      onCancel={onCancel}
      {...modalProps}
      destryOnClose
      footer={null}
    >
      <BasicForm
        handleCancel={onCancel}
        fieldList={fields}
        handleSubmit={handleSubmit}
        values={initValues}
        rawInitValues
      />
    </ModalWrapper>
  );
};

AddItemsPresentational.propTypes = {
  modalTitle: PropTypes.string,
  fields: PropTypes.array,
  setFieldValue: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  onCancel: PropTypes.func,
  editingType: PropTypes.object,
  editing: PropTypes.bool,
  entityShares: PropTypes.number,
  formattedConvertibles: PropTypes.array,
  handleNotification: PropTypes.func,
  initValues: PropTypes.object,
  isEditing: PropTypes.bool,
  existingShares: PropTypes.array
};

export default AddItemsPresentational;
