import PropTypes from "prop-types";
import { Menu, Icon } from "antd";
import { handleNav } from "#helpers/general";
import { MenuTitle } from ".";
import Tooltip from "../../../Tooltip";

const Submenu = ({
  title,
  collection = {},
  submenuActions,
  userRole,
  history,
  ios,
  entityId,
  actionDependencies = {},
  ...props
}) => {
  const { SubMenu, Item } = Menu;
  const { data = [], filter, itemClickHandler, enabledActions } = collection;

  const submenuButtons = () => {
    const renderButton = (btn, link, toolip = "", className) => (
      <Item onClick={handleNav(history, link)} key={btn} onItemHover={() => {}}>
        <div className={`${className} submenu-btn`}>
          <Icon type="plus-square" theme="filled" />
          <Tooltip title={toolip}>
            <span>{btn}</span>
          </Tooltip>
        </div>
      </Item>
    );

    if (submenuActions) {
      if (Array.isArray(submenuActions) && Array.isArray(submenuActions)) {
        const finalSubmenuActions = enabledActions
          ? submenuActions.filter(({ link }) => enabledActions.includes(link))
          : submenuActions;

        return finalSubmenuActions.map(
          ({ label, link, display, tooltip, className }) => {
            if (!display || (display && !!actionDependencies[display])) {
              return renderButton(label, link, tooltip, className);
            }

            return null;
          }
        );
      }
    }

    return null;
  };

  const filteredData = filter ? data.filter(filter(userRole)) : data;

  return (
    <SubMenu title={title} {...props}>
      <div className="btn-container">{submenuButtons()}</div>
      {filteredData.map(
        ({
          id,
          title,
          link,
          icon,
          previewImage,
          disabled,
          showIcon,
          absoluteLink,
          ...item
        }) => (
          <MenuTitle
            key={id}
            id={id}
            title={title}
            link={link}
            absoluteLink={absoluteLink}
            icon={icon || previewImage}
            onClick={itemClickHandler ? itemClickHandler(id, item) : null}
            containerClassName="menu-item-container _full"
            ios={ios}
            entityId={entityId}
            disabled={disabled}
            showIcon={showIcon}
          />
        )
      )}
    </SubMenu>
  );
};

Submenu.propTypes = {
  title: PropTypes.any,
  collection: PropTypes.object,
  submenuActions: PropTypes.any,
  actionDependencies: PropTypes.object,
  userRole: PropTypes.string,
  history: PropTypes.object,
  entityId: PropTypes.string,
  ios: PropTypes.array
};

export default Submenu;
