import { mapTaxValuesToPost, mapTaxValuesToForm } from "#helpers/mappers/taxes";
import { inputNumberFormatter } from "#helpers/general";
import { useEffect, useState } from "react";
import format from "date-fns/format";
import QueueAnim from "rc-queue-anim";
import ModalWrapper from "../../Modal";
import { Button } from "../../Button";
import { BasicForm } from "../../Form";
import { formList } from "./list";
import SuccessView from "./Views/SuccessView";
import BtnContainer from "../../Button/components/FormBtnContainer";
import TaxMenuShowcase from "../../../../assets/images/taxmenushowcase.png";
import { formatNumber } from "#helpers/number_format";

const TaxModal = ({
  visible,
  handleClose,
  buttonStates,
  actions,
  taxes,
  currencies,
  ...props
}) => {
  useEffect(() => {
    actions.clearState("taxes");
    actions.getTaxes({ entity_id: props.entityId });
    actions.readCurrencies();
  }, []);

  const handleSubmit = values => {
    const type = taxes.transactionDate ? "patch" : "post";

    const action = taxes.transactionDate
      ? actions.patchTaxes
      : actions.postTaxes;

    const payload = mapTaxValuesToPost(
      values,
      props.entityId,
      type,
      taxes.cuid
    );
    action(payload);
  };

  const handleExit = () => {
    handleClose();
    setTimeout(() => {
      actions.clearState("taxes");
    }, 1500);
  };

  const CustomFooter = ({ onClick, buttonStates }) => {
    return (
      <div className="btn-container">
        <Button
          label="Save"
          type="secondary"
          onClick={onClick}
          loading={buttonStates.loading}
          success={buttonStates.success}
          disabled={buttonStates.loading || buttonStates.success}
        />
      </div>
    );
  };

  const initValues = ({ deps = [], values }) => ({
    setValues,
    values: prevValues
  }) => {
    useEffect(() => {
      const [didFetch] = deps;
      if (didFetch) {
        let sharesAreDiluted = "";

        if (values.sharesAreDiluted) {
          sharesAreDiluted = "diluted";
        } else if (
          values.sharesAreDiluted !== undefined &&
          !values.sharesAreDiluted
        ) {
          sharesAreDiluted = "non-diluted";
        }
        setValues({
          ...prevValues,
          ...values,
          sharesAreDiluted
        });
      }
    }, deps);
  };

  const formArgs = {
    fieldList: formList({ currencies }),
    handleSubmit,
    CustomFooter,
    buttonStates,
    useCustomHook: initValues({
      deps: [props.taxesDidFetch],
      values: mapTaxValuesToForm(taxes)
    })
  };

  const hasTaxDetails = !!taxes.transactionDate;
  const dataLabels = {
    transactionDate: "Transaction date",
    totalIssuedShares: "Total issued shares",
    taxShareValue: "Tax share value",
    shareNominalValue: "Nominal value",
    lastSharePrice: "Last share price",
    sharesAreDiluted: "Outstanding shares basis"
  };

  const taxCurrency = currencies[taxes.currencyId];
  const currencyFormatter = inputNumberFormatter(taxCurrency);

  const data = {
    transactionDate: format(taxes.transactionDate, "DD-MM-YYYY"),
    totalIssuedShares: formatNumber(taxes.issuedShares),
    taxShareValue: currencyFormatter.formatter(
      taxes?.taxShareValue?.toFixed(2)
    ),
    shareNominalValue: currencyFormatter.formatter(
      taxes?.shareNominalValue?.toFixed(2)
    ),
    lastSharePrice: currencyFormatter.formatter(
      taxes?.lastSharePrice?.toFixed(2)
    ),
    sharesAreDiluted: taxes.sharesAreDiluted ? "Diluted" : "Non-diluted"
  };

  return (
    <ModalWrapper
      footer={null}
      width={700}
      closable
      maskClosable
      keyboard={false}
      visible={visible}
      onCancel={handleClose}
    >
      <div className="tax-decl-wrapper">
        <QueueAnim type={["right", "left"]} ease={["easeInOutQuart"]}>
          {!buttonStates.success ? (
            <div className="tax-container" key="1">
              {hasTaxDetails && (
                <>
                  <div className="transaction-details">
                    <h3>Last tax declarations:</h3>
                    {Object.keys(data).map(key => {
                      return (
                        <div className="detail">
                          <span>
                            <strong>{dataLabels[key]}</strong>
                          </span>
                          <span>{data[key]}</span>
                        </div>
                      );
                    })}
                  </div>
                  <hr className="divider" />
                </>
              )}
              <BasicForm {...formArgs} />
            </div>
          ) : (
            <div className="success-view" key="2">
              <h1>
                Thank you for sharing the information needed to generate the tax
                report.
              </h1>
              <div className="container">
                <div className="img-wrapper">
                  <img src={TaxMenuShowcase} alt="Menu description" />
                </div>
                <div className="content">
                  <h3>
                    The tax report is available to you and your co-investors in
                    the “Management” menu on the left-hand side.
                  </h3>
                  <br />
                  <h3>
                    <strong>Keep your co-investors up to date. </strong>If
                    anything changes or the company sends you updated values you
                    can amend the tax report under “Tax reports” in the company
                    overview.
                  </h3>
                  <BtnContainer
                    okButtonLabel="Ok, got it!"
                    showCancel={false}
                    onOk={handleExit}
                    okButtonProps={{ type: "secondary" }}
                  />
                </div>
              </div>
            </div>
          )}
        </QueueAnim>
      </div>
    </ModalWrapper>
  );
};

TaxModal.propTypes = {};

export default TaxModal;
