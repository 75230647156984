import AppDrawer from '../../AppDrawer/Menu/Container';
import Appbar from '../../Appbar/Container';

const MemberLayout = ({ children, drawer }) => {
  return (
    <div className="app-wrapper">
      {drawer && <AppDrawer />}
      <div className="right-section-wrapper">
        <Appbar />
        <div className="centralComponent-member">{children}</div>
      </div>
    </div>
  );
};

export default MemberLayout;
