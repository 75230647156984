const Fundraising = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6957 8.08691H1.30433C0.573919 8.08691 0 8.66083 0 9.39129V21.1304C0 21.8609 0.573919 22.4347 1.30433 22.4347H22.6956C23.4261 22.4347 24 21.8608 24 21.1304V9.39129C24 8.66083 23.4261 8.08691 22.6957 8.08691ZM2.86955 9.65213C2.86955 10.2261 2.39998 10.6956 1.82607 10.6956H1.56522V9.65213H2.86955ZM1.56522 19.826H1.82607C2.39998 19.826 2.86955 20.2956 2.86955 20.8695H1.56522V19.826ZM22.4348 20.8695H21.1305C21.1305 20.2956 21.6 19.826 22.1739 19.826H22.4348V20.8695ZM22.4348 18.2608H22.1739C20.7391 18.2608 19.5652 19.4347 19.5652 20.8695H4.43477C4.43477 19.4347 3.26086 18.2608 1.82607 18.2608H1.56522V12.2608H1.82607C3.26086 12.2608 4.43477 11.0869 4.43477 9.65213H19.8261C19.8261 11.0869 21 12.2608 22.4348 12.2608V18.2608ZM22.4348 10.6956C21.8609 10.6956 21.3913 10.2261 21.3913 9.65213H22.4348V10.6956Z"
        fill="#597EF7"
      />
      <path
        d="M21.1304 6.26085H2.8695C2.426 6.26085 2.08691 5.92171 2.08691 5.47826C2.08691 5.03482 2.42605 4.69568 2.8695 4.69568H21.1304C21.5739 4.69568 21.9129 5.03482 21.9129 5.47826C21.9129 5.92171 21.5739 6.26085 21.1304 6.26085Z"
        fill="#18DC9B"
      />
      <path
        d="M18.7826 3.13042H5.2174C4.7739 3.13042 4.43481 2.79128 4.43481 2.34783C4.43481 1.90439 4.77395 1.56525 5.2174 1.56525H18.7826C19.2261 1.56525 19.5652 1.90439 19.5652 2.34783C19.5652 2.79128 19.2261 3.13042 18.7826 3.13042Z"
        fill="#18DC9B"
      />
      <path
        d="M11.9999 19.5391C9.62598 19.5391 7.69556 17.6087 7.69556 15.2348C7.69556 12.8608 9.62598 10.9304 11.9999 10.9304C14.3738 10.9304 16.3042 12.8608 16.3042 15.2348C16.3042 17.6087 14.3738 19.5391 11.9999 19.5391ZM11.9999 12.4956C10.4869 12.4956 9.26078 13.7217 9.26078 15.2348C9.26078 16.7478 10.4869 17.9739 11.9999 17.9739C13.5129 17.9739 14.739 16.7478 14.739 15.2348C14.739 13.7217 13.5129 12.4956 11.9999 12.4956Z"
        fill="#18DC9B"
      />
    </svg>
  );
};

export default Fundraising;
