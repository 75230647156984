import PropTypes from "prop-types";
import { Button } from "../../Button";

const SelectButtons = ({
  name,
  list = [],
  onChange,
  value,
  onBlur,
  disabled,
  customListRenderer,
  placeholder,
  className,
  ...props
}) => {
  return (
    <div className={`select-input-wrapper select-btns ${className || ""}`}>
      {list.map(item => {
        const btnClassname = item.value === value ? "selected" : "";

        return (
          <Button
            className={btnClassname}
            disabled={disabled}
            {...item}
            onClick={onChange(name)}
          />
        );
      })}
    </div>
  );
};

SelectButtons.propTypes = {
  name: PropTypes.string,
  list: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  customListRenderer: PropTypes.any,
  placeholder: PropTypes.string,
  className: PropTypes.string
};

export default SelectButtons;
