import { entityTypes } from "#constants/entity";

export const mapBankAccountsFromBE = bankAccounts => {
  return Array.isArray(bankAccounts) && bankAccounts.length > 0
    ? bankAccounts.map(item => {
        return {
          beneficialOwner: item.beneficialOwner,
          bank: item.name,
          bankAddress: item.street,
          bankCity: item.city,
          bankZip: item.postalCode,
          bankCountry: item.country,
          bankAccNo: item.accountNumber,
          iban: item.iban,
          bankSwift: item.swift,
          bankAccCurrency: item.currency,
          id: bankAccounts.findIndex(
            i =>
              i.beneficialOwner === item.beneficialOwner &&
              i.iban === item.iban &&
              i.swift === item.swift
          )
        };
      })
    : [];
};

export const mapUserFromBE = user => {
  return {
    email: user.email,
    profilePicture: user.profilePicture,
    city: user.city,
    country: user.country,
    firstName: user.firstName,
    lastName: user.lastName,
    sex: user.sex,
    street: user.street,
    zipCode: user.zipCode
  };
};

export const mapLegalRepresentativesFromBE = legalReps => {
  return Array.isArray(legalReps) && legalReps.length > 0
    ? legalReps.map(item => {
        const [firstName, lastName] = item.split(" ");
        return {
          id: legalReps.indexOf(item),
          firstName,
          lastName
        };
      })
    : [];
};

export const mapUpdateEntity = (values, type) => {
  return {
    name: values.name,
    address: {
      street: values.address !== "undefined undefined" ? values.address : "-",
      city: values.city || "-",
      country: values.country || "-",
      postalCode: values.postalCode || "-",
      canton: values.canton || "-"
    },
    phoneNumber: "-",
    idNumber: values.idNumber,
    governingLaw: values.governingLaw,
    socialSecurityNumber: values.socialSecurityNumber,
    birthDate: values.birthdate,
    industries: values.industries,
    description: values.description,
    teaser: values.teaser,
    companyStatus: values.companyStatus,
    employees: values.employees,
    websiteUrl: values.websiteUrl,
    fundraisingActive: true,
    shares: values.entityShares || 100000,
    employeeParticipationPlan: {
      esopShares: values.esopShares || 0,
      psopShares: values.psopShares || 0
    },
    bankAccounts: values.bankAcc
      ? values.bankAcc.map(item => {
          return {
            beneficialOwner: item.beneficialOwner,
            name: item.bank,
            street: item.bankAddress,
            city: item.bankCity,
            postalCode: item.bankZip,
            country: item.bankCountry,
            accountNumber: item.bankAccNo,
            iban: item.iban,
            swift: item.bankSwift,
            currency: item.bankAccCurrency
          };
        })
      : [],
    legalRepresentatives: values.legalReps
      ? values.legalReps.map(
          ({ firstName, lastName }) => `${firstName} ${lastName}`
        )
      : [],
    linkedInUrl: values.linkedInUrl,
    twitterUrl: values.twitterUrl
  };
};

export const mapCompanyPrefValues = (values, type, fromAM = false) => {
  const entityShares = values.entityShares ? values.entityShares : 100000;
  const founderShares = values.founderShares ? values.founderShares / 100 : 0;

  const payload = {
    commonName: values.commonName,
    investmentType: values.investmentType,
    name: values.name,
    address: {
      street: values.address || "-",
      city: values.city,
      country: values.country || "-",
      postalCode: values.postalCode || "-",
      canton: values.country === "Switzerland" ? values.canton : "-"
    },
    phoneNumber: values.phoneNumber || "+4179000000",
    vat: values.vat,
    idNumber: values.idNumber,
    industries: values.industries,
    description: values.description,
    companyStatus: values.companyStatus,
    websiteUrl: values.websiteUrl,
    shares: entityShares,
    employeeParticipationPlan: {
      esopShares: values.esopShares || 0,
      psopShares: values.psopShares || 0
    },
    entityType: "-",
    fromAM,
    governingLaw: values.governingLaw || "-"
  };

  return payload;
};

export const mapCompanyPrefUpdateValues = (payload, entityId) => {
  const updatedPayload = {
    cuid: entityId,
    ...payload
  };

  delete updatedPayload.fromAM;
  delete updatedPayload.type;
  delete updatedPayload.legalType;

  return updatedPayload;
};

export const mapCompanyUpdateValues = values => {
  return {
    ...values,
    address: values.address.street,
    city: values.address.city,
    country: values.address.country,
    postalCode: values.address.postalCode,
    canton: values.address.canton,
    previewImage: !!values.previewImage
  };
};

export const mapEntityUpdateValues = values => {
  const payload = {
    name: values.name,
    address: {
      city: values.city,
      country: values.country,
      postalCode: values.postalCode,
      street: values.address,
      canton: values.canton
    },
    governingLaw: values.governingLaw,
    phoneNumber: values.phoneNumber || "+4179000000",
    idNumber:
      values.idNumber &&
      `${
        !values.idNumber.includes("CHE")
          ? "CHE-"
          : values.idNumber.includes("CHE") && !values.idNumber.includes("-")
          ? `CHE-${values.idNumber.split("CHE")[0]}`
          : ""
      }${
        values.idNumber.includes("CHE")
          ? `CHE${values.idNumber
              .split("CHE")[1]
              .replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`
          : values.idNumber.replace(/(\d)(?=(\d{3})+$)/g, "$1.")
      }`,
    industries: values.industries,
    description: values.description,
    companyStatus: values.companyStatus,
    websiteUrl: values.websiteUrl,
    purpose: values.purpose,
    birthDate: values.publicFoundingDate,
    sharesNominalValue: values.sharesNominalValue,
    preValue: values.preValue,
    shares: values.shares || 1,
    employeeParticipationPlan: {
      esopShares: values.esopShares || 0,
      psopShares: values.psopShares || 0
    },
    correspondenceAddress: {
      street: values.address,
      postalCode: values.postalCode,
      city: values.city,
      canton: values.canton,
      country: values.country
    },
    bank: {
      name: values.bank,
      street: values.bankAddress,
      city: values.bankCity,
      canton: values.bankCanton,
      country: values.bankCountry,
      blockedAccount: values.iban,
      accountNumber: values.accountNumber,
      clearingNumber: values.clearingNumber
    },
    capitalBank: {
      name: values.bank,
      street: values.bankAddress,
      city: values.bankCity,
      canton: values.bankCanton,
      country: values.bankCountry,
      blockedAccount: values.iban,
      accountNumber: values.accountNumber,
      clearingNumber: values.clearingNumber
    },
    aoaDate: values.aoaDate,
    shareCapital: values.publicShareCapital,
    translatedDescription: values.descriptionEnglish
  };
  return payload;
};

export const mapEntityAddressAndBank = entity => {
  let payload = {};
  if (entity.address) {
    payload = {
      ...payload,
      address: entity.address.street,
      canton: entity.address.canton,
      postalCode: entity.address.postalCode,
      city: entity.address.city,
      country: entity.address.country
    };
  }
  if (entity.bank) {
    payload = {
      ...payload,
      bank: entity.bank.name,
      bankAddress: entity.bank.street,
      bankCity: entity.bank.city,
      bankCanton: entity.bank.canton,
      bankCountry: entity.bank.country,
      accountNumber: entity.bank.accountNumber,
      iban: entity.bank.blockedAccount,
      clearingNumber: entity.bank.clearingNumber
    };
  }
  return payload;
};

export const mapMembershipValues = ({ redirectUrl, ...values }) => ({
  role: values.role,
  userEmail: values.userEmail,
  userFirstName: values.userFirstName,
  userLastName: values.userLastName,
  entityId: values.entityId,
  redirectUrl: redirectUrl || `${process.env.HOST}/login`
});

export const mapCompanyDetails = (values = {}) => {
  if (values) {
    return {
      name: values.name,
      address:
        values.street && values.houseNumber
          ? `${values.street} ${values.houseNumber}`
          : "",
      vat: values.uid,
      city: values.town,
      postalCode: values.zipCode,
      country: "Switzerland",
      governingLaw: values.legalSeat || "-"
    };
  }

  return {};
};

export const mapDetailsToEntity = (values = {}) => {
  const { uid = "" } = values || {};
  if (values) {
    return {
      name:
        values.legalSeat && values.canton
          ? `${values.name}, ${values.legalSeat}, ${values.canton}`
          : values.name,
      address: values.address
        ? values.address
        : `${values.street} ${values.houseNumber}`,
      governingLaw: values.legalSeat,
      idNumber: values.idNumber
        ? `${
            !values.idNumber.includes("CHE")
              ? "CHE-"
              : values.idNumber.includes("CHE") &&
                !values.idNumber.includes("-")
              ? `CHE-${values.idNumber.split("CHE")[0]}`
              : ""
          }${
            values.idNumber.includes("CHE")
              ? values.idNumber
                  .split("CHE")[1]
                  .replace(/(\d)(?=(\d{3})+$)/g, "$1.")
              : values.idNumber.replace(/(\d)(?=(\d{3})+$)/g, "$1.")
          }`
        : values.uid &&
          `${
            !values.uid.includes("CHE")
              ? "CHE-"
              : values.uid.includes("CHE") && !values.uid.includes("-")
              ? `CHE-${values.uid.split("CHE")[0]}`
              : ""
          }${
            values.uid.includes("CHE")
              ? values.uid.split("CHE")[1].replace(/(\d)(?=(\d{3})+$)/g, "$1.")
              : values.uid.replace(/(\d)(?=(\d{3})+$)/g, "$1.")
          }`,
      city: values.city ? values.city : values.town,
      employeeParticipationPlan: {
        esopShares: values.esopShares || 0,
        psopShares: values.psopShares || 0
      },
      postalCode: values.postalCode ? values.postalCode : values.zipCode,
      country: values.country
        ? values.country
        : values.canton
        ? "Switzerland"
        : null,
      canton: values.canton,
      shares: values.shares ? values.shares : values.totalShares,
      sharePrice: values.sharePrice,
      // clearingNumber: values.bank.clearingNumber || '-',
      // accountNumber: values.bank.clearingNumber || '-',
      iban: values.blockedAccount,
      // bank: values.bank.name || '-',
      // bankAddress: values.address || '-',
      companyStatus: values.companyStatus,
      websiteUrl: values.websiteUrl,
      sharesNominalValue: values.sharesNominalValue,
      preValue: values.preValue,
      publicFoundingDate: values.birthDate,
      industries: values.industries,
      description: values.description,
      purpose: values.purpose,
      publicShareCapital: values.shareCapital,
      bankCity: ""
    };
  }

  return {};
};
