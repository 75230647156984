import { NavLink } from 'react-router-dom';
import { generalInformationsCols } from './tableCols';

const generalInformations = (values, handlers) => [
  {
    id: 1,
    title: 'General Informations',
    input: {
      name: 'signingDirector',
      type: 'table_list',
      cols: generalInformationsCols(handlers),
    }
  },
  {
    id: 3,
    showId: false,
    title: (
      <span>
        If you want to add a board member{' '}
        <NavLink
          to="/stakeholders"
        >
          click here
        </NavLink>
      </span>
    ),
    className: '_hidden',
    input: {}
  },
  {
    id: 2,
    title:
      'Which article number of the Article of association regulates the adoption and issuing of the Board Regulations.',
    details:
      'Please leave this field empty if you have no articles to add, else add them separated by comma.',
    input: {
      type: 'text',
      name: 'articleNumber'
    }
  }
];

export default generalInformations;
