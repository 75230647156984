import { Checkbox } from "antd";
import Tooltip from "../Tooltip";
import { InformationIcon } from "../Icons";

const iconStyle = { width: "20px", height: "20px" };

export default ({
  name,
  value,
  label,
  checked,
  disabled,
  onChange,
  toolTip,
  changedDisabled,
  ...props
}) => {
  return (
    <div className="checkbox-input">
      <Checkbox
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={!changedDisabled ? onChange : null}
        {...props}
      >
        {label}
      </Checkbox>
      {toolTip && (
        <Tooltip title={toolTip}>
          <i>
            <InformationIcon style={iconStyle} />
          </i>
        </Tooltip>
      )}
    </div>
  );
};
