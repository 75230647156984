import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};
export default (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.ACCEPT_INVITE: {
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            inviteObject: action.payload.data
          };
        }
      });
    }
    case types.GENERATE_INVITE_LINK: {
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const invitesList =
            state._data && state._data.invitesList
              ? state._data.invitesList
              : [];
          return {
            ...state._data,
            invitesList: [...invitesList, ...action.payload.data]
          };
        }
      });
    }
    case types.FETCH_ALL_INVITES: {
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            invitesList: action.payload.data
          };
        }
      });
    }
    case types.IS_VALID: {
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            inviteObject: action.payload.data
          };
        }
      });
    }
    default:
      return state;
  }
};
