import Radio from 'antd/es/radio';
import Icon from 'antd/es/icon';
import { withTooltip } from '../hocs';
import Avatar from '../Avatar';

export default ({
  name,
  type,
  value,
  label,
  checked,
  onChange,
  disabled,
  showLabel = true,
  indicator,
  tooltip
}) => {
  const labelToShow = showLabel ? label || value : null;
  const Wrapper = type === 'button' ? Radio.Button : Radio;
  const finalClassname = `radio-input ${type === 'button' ? 'button' : ''} ${
    checked ? 'checked' : ''
  }`;
  const IconWrapper = () => (
    <Icon type="info-circle" className="tooltip-icon" />
  );
  const Tooltip = withTooltip('Testing')(() => <span>test</span>);

  return (
    <Wrapper
      name={name}
      checked={checked}
      onChange={onChange}
      value={value}
      className={finalClassname}
      disabled={disabled}
    >
      {indicator && (
        <Avatar size="small" color="green">
          {indicator}
        </Avatar>
      )}
      <span>{labelToShow}</span>
    </Wrapper>
  );
};
