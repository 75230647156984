import { globalChangeHandler } from "#helpers/inputHandlers";
import moment from "moment";

const returnHandler = (type, setFieldValue, handleChange, values) => {
  if (type === "select" || type === "checkbox" || type === "switch") {
    return globalChangeHandler(setFieldValue, values, type);
  }

  if (type === "autocomplete") {
    return name => value => setFieldValue(name, value);
  }

  if (type === "date") {
    return name => value => {
      setFieldValue(name, moment(value).format("YYYY-MM-DD"));
    };
  }
  return handleChange;
};

export default returnHandler;
