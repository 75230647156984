import { roles } from "#constants/roles";
import { handleColor } from "#helpers/general";
import TagWrapper from "../../../../../Shared/Tag";
import Icon from "antd/es/icon";
import { Popconfirm } from "antd";
import Tooltip from "../../../../../Shared/Tooltip";

export const stakeholdersTableCols = (
  handleDelete,
  handleEdit,
  closedIos = []
) => {
  return [
    {
      title: "First & last name",
      dataIndex: "name",
      key: "name"
      // width: 200,
    },
    {
      title: "Category",
      dataIndex: "role",
      key: "role",
      render: (_, record) => {
        const tags = [];
        if (record.shares && record.shares.length > 0) {
          tags.push(<TagWrapper color="custom-green">Shareholder</TagWrapper>);
        }
        if (record.convertibles && record.convertibles.length > 0) {
          tags.push(<TagWrapper color="blue">Lender</TagWrapper>);
        }
        if (record.isDirector && record.representativeOf !== "board_observer") {
          tags.push(<TagWrapper color="teal">Board Member</TagWrapper>);
        }
        if (record.representativeOf === "board_observer") {
          tags.push(<TagWrapper color="dark-green">Board Observer</TagWrapper>);
        }
        if (record.presidentOfTheBoard) {
          tags.push(<TagWrapper color="light-grey">Chairman</TagWrapper>);
        }
        return (
          <div>
            {record.role === roles.DIRECTOR &&
            record.representativeOf === "board_observer" ? null : (
              <TagWrapper color={handleColor(record.role)}>
                {record.role === roles.DIRECTOR ? "Board Member" : record.role}
              </TagWrapper>
            )}
            {tags.map(tag => tag)}
          </div>
        );
      }
      // width: 400,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          {/* {closedIos.length === 0 ? ( */}
          <Icon
            type="edit"
            className="icon stakeholder-management-icon"
            onClick={handleEdit(record)}
          />
          {/* ) : (
            <Tooltip title="You cannot edit stakeholders at this point">
              <Icon type="edit" className="icon stakeholder-management-icon" />
            </Tooltip>
          )} */}
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={handleDelete(record)}
          >
            <Icon type="delete" className="icon stakeholder-management-icon" />
          </Popconfirm>
        </div>
      ),
      width: 200
    }
  ];
};
