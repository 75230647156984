import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './action_types';
import actions from '../../constants/action_type';
import claTypes from '../convertible/action_types';

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.GET_SHARE_CLASSES:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            list: action.payload.data
          };
        }
      });

    case types.GET_ENTITY_CAPTABLE:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            captable: action.payload.data
          };
        }
      });

    case types.GET_SHARE_CLASSES_BY_ID:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            share: action.payload.data
          };
        }
      });

    case types.GET_ENTITY_SHAREHOLDERS:
      return baseReducer(action, state, actions.FETCH, {
        start: () => ({
          ...state._data,
          shareholdersFetched: false
        }),
        success: () => {
          return {
            ...state._data,
            shareholders: action.payload.data,
            shareholdersFetched: true
          };
        },
        fail: () => ({
          ...state._data,
          shareholdersFetched: false
        })
      });

    case types.POST_SHARE_CLASSES:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const currList = (state._data && state._data.list) || [];

          return {
            ...state._data,
            list: [...currList, action.payload.data]
          };
        }
      });

    case types.POST_SHAREHOLDERS:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const currShareholders =
            (state._data && state._data.shareholders) || [];

          return {
            ...state._data,
            shareholders: [...currShareholders, action.payload.data]
          };
        }
      });

    case types.UPDATE_SHARE_CLASSES_BY_ID:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const currList = (state._data && state._data.list) || [];
          const objIndex = currList.findIndex(
            (share) => share.cuid === action.payload.data.cuid
          );
          currList[objIndex] = action.payload.data;
          return {
            ...state._data,
            list: currList
          };
        }
      });

    case types.DELETE_SHARE_CLASSES:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          const currList = (state._data && state._data.list) || [];
          const currCapTableShares =
            (state._data && state._data.captable.shares) || [];
          return {
            ...state._data,
            list: currList.filter(({ cuid }) => cuid !== action.payload.data),
            captable: {
              ...state._data.captable,
              shares: currCapTableShares.filter(
                ({ cuid }) => cuid !== action.payload.data
              )
            }
          };
        }
      });

    case claTypes.DELETE_CLA:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          const currCapTableConvertibles =
            (state._data && state._data.captable.convertibles) || [];
          return {
            ...state._data,
            captable: {
              ...state._data.captable,
              convertibles: currCapTableConvertibles.filter(
                ({ cuid }) => cuid !== action.payload.data
              )
            }
          };
        }
      });

    case types.UPDATE_SHAREHOLDER_BY_ID:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const currList = (state._data && state._data.shareholders) || [];
          const objIndex = currList.findIndex(
            (shareholder) => shareholder.cuid === action.payload.data.cuid
          );
          currList[objIndex] = action.payload.data;
          return {
            ...state._data,
            shareholders: currList
          };
        }
      });

    case types.DELETE_SHAREHOLDER:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          const currList = (state._data && state._data.shareholders) || [];
          const { shareholderId } = action.payload.data || {};
          return {
            ...state._data,
            shareholders: currList.filter(({ cuid }) => cuid !== shareholderId)
          };
        }
      });

    default:
      return state;
  }
};

export default reducer;
