const TaxReturns = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8831 7.12453H21.7661V4.43774C21.7661 3.92451 21.3736 3.5321 20.8604 3.5321H19.0793V0.905633C19.0793 0.392462 18.6869 0 18.1736 0H5.79627C5.28304 0 4.89064 0.392462 4.89064 0.905633V3.53204H3.10947C2.59625 3.53204 2.20384 3.92451 2.20384 4.43768V7.12447H1.11706C0.60383 7.12447 0.211426 7.51693 0.211426 8.03011V23.0943C0.211426 23.6075 0.603888 23.9999 1.11706 23.9999H22.8831C23.3963 23.9999 23.7887 23.6074 23.7887 23.0943V8.03016C23.7887 7.51699 23.3963 7.12453 22.8831 7.12453ZM6.70196 1.81132H17.268V3.5321H6.70196V1.81132ZM4.01517 5.34337H19.9548V7.12453H4.01517V5.34337ZM21.9774 22.1887H2.02269V8.93585H21.9774V22.1887Z"
        fill="#18DC9B"
      />
      <path
        d="M15.4868 14.9434H8.48303C7.9698 14.9434 7.57739 14.551 7.57739 14.0378C7.57739 13.5246 7.96986 13.1321 8.48303 13.1321H15.4868C16 13.1321 16.3924 13.5246 16.3924 14.0378C16.3924 14.551 16 14.9434 15.4868 14.9434Z"
        fill="#597EF7"
      />
    </svg>
  );
};

export default TaxReturns;
