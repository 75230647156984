import * as shareService from "../../../../api/shareService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const deleteShareClasses = params => {
  return async dispatch => {
    try {
      start(dispatch, types.DELETE_SHARE_CLASSES);
      await shareService.deleteShareClasses(params);
      success(dispatch, types.DELETE_SHARE_CLASSES, {
        data: params
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.DELETE_SHARE_CLASSES, {
        error: errorData
      });
    }
  };
};

export const deleteShareholder = (shareholder, params, optData = {}) => {
  return async dispatch => {
    try {
      start(dispatch, types.DELETE_SHAREHOLDER);
      await shareService.deleteShareholder(shareholder, params);
      success(dispatch, types.DELETE_SHAREHOLDER, {
        data: { shareholderId: shareholder, ...optData }
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.DELETE_SHAREHOLDER, {
        error: errorData
      });
    }
  };
};
