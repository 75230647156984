import { baseSelector } from "./utils";

const selectNdaDataFunc = state => {
  const { list = [], nda, ndaFetched, ndaGenerated } = state._data || {};

  return { list, nda, ndaFetched, ndaGenerated };
};

export const selectNdaCuid = (ndas, amId) => {
  const { cuid } =
    (ndas &&
      ndas.find(
        ({ assetManagerId, userSignatureTimestamp }) =>
          userSignatureTimestamp && amId === assetManagerId
      )) ||
    {};

  return cuid;
};

const selectNdaData = baseSelector(selectNdaDataFunc);

export { selectNdaData };
