import types from "../action_types";
import * as activityService from "../../../../api/activityService";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const postPlatformActivity = visit => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_ACTIVITY);
      let payload = visit;
      let country = {};

      try {
        country = await activityService.getCountry();
      } catch (e) {
        country = {
          data: "Not defined"
        };
      }

      payload = { ...payload, country: country.data };

      const response = await activityService.postPlatformActivity(payload);

      success(dispatch, types.POST_ACTIVITY, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_ACTIVITY, {
        error: errorData
      });
    }
  };
};

export const postDocVisit = data => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_DOC_VISIT);
      const response = await activityService.postDocVisit(data);
      success(dispatch, types.POST_DOC_VISIT, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_DOC_VISIT, {
        error: errorData
      });
    }
  };
};
