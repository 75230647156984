import PropTypes from 'prop-types';
import { equityTs } from '#constants/io/equityPool';
import { formatNumber } from '#helpers/number_format';
import TermSection from '../../../common/Terms';

const EquityTerms = ({ io }) => {
  const {
    termSheet: {
      [equityTs.SERIES_ROUND]: financingRound = '',
      [equityTs.PRE_VALUE]: preValue = 0,
      [equityTs.ISSUE_PRICE]: issuePrice = 0,
      [equityTs.DRAG_ALONG_PERCENTAGE]: dragAlong,
      [equityTs.LIQUIDATION_PREFERENTIAL]: liqudidation,
      [equityTs.DIVIDEND_PREFERENTIAL]: dividend,
      [equityTs.ANTI_DILUTION]: antiDilution,
      [equityTs.ANTI_DILUTION_PROTECTION_FORMULA]: antiDilutionFormula
    } = {}
  } = io;

  const { currency: { shortName: currency = '' } = {} } = io;
  const finalAntiDilution = antiDilution ? antiDilutionFormula : 'No';

  const terms = {
    'Financing round': financingRound,
    'Funding goal': `${currency} ${formatNumber(io.fundingGoal)}`,
    'Pre money valuation': `${currency} ${preValue}`,
    'Issue price of new shares': `${currency} ${Number(issuePrice).toFixed(2)}`,
    'Drag along': dragAlong,
    'Tag along': 'Yes',
    'Liquidation preference': liqudidation ? 'Yes' : 'No',
    'Dividend preference': dividend ? 'Yes' : 'No',
    'Anti dilution': finalAntiDilution
  };

  return <TermSection terms={terms} />;
};

EquityTerms.propTypes = {
  io: PropTypes.object
};

export default EquityTerms;
