import { Upload, Icon } from "antd";
import TooltipWrapper from "../../../Tooltip";

const AvatarUploader = ({
  onUpload,
  label = "Profile photo",
  loading,
  profilePic,
  tooltip
}) => {
  const uploadButton = (
    <div>
      <Icon type={loading ? "loading" : "plus"} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const dummyRequest = ({ file, onSuccess }) => {
    // WORKAROUND TO DISABLE UPLOADER'S DEFAULT ACTION ON FILE UPLOAD WHICH WILL FAIL SINCE WE HAVENT SET IT UP THE WAY IT EXPECTS
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const MainWrapper = (
    <div className="avatar-uploader">
      {label && <span>{label}</span>}
      <Upload
        listType="picture-card"
        showUploadList={false}
        onChange={onUpload}
        customRequest={dummyRequest}
      >
        {profilePic ? (
          <img
            src={profilePic}
            alt="avatar"
            style={{ width: "100%", borderRadius: "4px" }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );

  if (tooltip) {
    return <TooltipWrapper title={tooltip}>{MainWrapper}</TooltipWrapper>;
  }
  return MainWrapper;
};

export default AvatarUploader;
