import { NavLink } from "react-router-dom";
import i18n from "../../../../i18n";

export const formList = [
  {
    title: i18n.t("home.login_panel.email"),
    input: {
      name: "email",
      type: "text"
    }
  },
  {
    title: (
      <div className="psw-label">
        <span>{i18n.t("home.login_panel.password")}</span>
        <span className="forgot-psw-ref">
          <NavLink to="/reset_password">Forgot password?</NavLink>
        </span>
      </div>
    ),

    input: {
      name: "password",
      type: "password"
    }
  },
  {
    title: i18n.t("home.login_panel.2fa"),
    twoStepAuthActive: true,
    input: {
      name: "token",
      type: "text"
    }
  }
];
