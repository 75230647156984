import { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { mapEntityShareholdersToStakeholder } from "#helpers/mappers/shares";
import Title from "../../../../../Shared/Title";
import SharedTable from "../../../../../Shared/SharedTable/Presentational";
import { stakeholdersTableCols } from "./cols";
import { Button } from "../../../../../Shared/Button";

const StakeholderManagement = ({
  getEntityShareholders,
  getEntityMemberships,
  entityId,
  shareholders,
  deleteShareholder,
  clearState,
  getEntityCapTable,
  deleteEntityMembership,
  fetchAllIos,
  closedIos = [],
  creator
}) => {
  useEffect(() => {
    clearState("shares");
    clearState("entities");
    getEntityShareholders({ entity_id: entityId });
    getEntityMemberships({ id: entityId });
    fetchAllIos(entityId);
  }, []);
  const history = useHistory();

  const addStakeholdersAction = (
    <Button
      className="plus-btn"
      icon="plus"
      href="/stakeholders"
      type="circle"
    />
  );

  const handleEdit = data => () => {
    const shareholder = shareholders.find(sh => sh.cuid === data.cuid);
    history.push("/stakeholders", {
      data: { isOriginalFounder: data.userId === creator, ...shareholder }
    });
  };

  const handleDelete = ({ shareholderId, cuid }) => () => {
    if (shareholderId) {
      deleteShareholder(
        shareholderId,
        { delete_user_and_member: true },
        { membershipId: cuid }
      );
    } else {
      deleteEntityMembership(cuid);
    }

    setTimeout(() => {
      getEntityCapTable({ entity_id: entityId });
    }, 1000);
  };
  const formattedData = shareholders.map(item =>
    mapEntityShareholdersToStakeholder(item)
  );

  return (
    <div className="stakeholder-management">
      <Title title="Stakeholder management" action={addStakeholdersAction} />
      <SharedTable
        columns={stakeholdersTableCols(handleDelete, handleEdit, closedIos)}
        dataSource={formattedData}
      />
    </div>
  );
};

StakeholderManagement.propTypes = {
  getEntityShareholders: PropTypes.func,
  shareholders: PropTypes.array,
  getEntityMemberships: PropTypes.func,
  deleteShareholder: PropTypes.func,
  clearState: PropTypes.func,
  getEntityCapTable: PropTypes.func,
  deleteEntityMembership: PropTypes.func,
  entityId: PropTypes.string,
  fetchAllIos: PropTypes.func,
  closedIos: PropTypes.array
};

export default StakeholderManagement;
