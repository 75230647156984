import SharesListInput from "../ListInput";
import InputRenderer from "../../../Shared/InputRenderer";

const CustomInputRenderer = (
  shares,
  triggerSnackbar,
  entityShares,
  formattedConvertibles,
  isEditingUser,
  existingShares
) => props => {
  const { type } = props;

  if (type === "list") {
    return (
      <SharesListInput
        triggerSnackbar={triggerSnackbar}
        shares={shares}
        entityShares={entityShares}
        formattedConvertibles={formattedConvertibles}
        isEditingUser={isEditingUser}
        existingShares={existingShares}
        {...props}
      />
    );
  }

  return <InputRenderer {...props} />;
};

export default CustomInputRenderer;
