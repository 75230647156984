import Avatar from '../..';

/* SUPPORTS STACKING OF UP TO 10 ELEMENTS. FOR MORE, MODIFY THE SASS MIXIN */

const StackedAvatar = ({ wrapperClassname = '', list }) => {
  const finalClassname = `stacked-avatars-wrapper ${wrapperClassname}`;

  return (
    <div className={finalClassname}>
      {list.map(({ label = 'T', ...item }, index) => (
        <Avatar size="large" bordered {...item} key={label + index}>
          {label}
        </Avatar>
      ))}
    </div>
  );
};

export default StackedAvatar;
