import { formatArrayKeys } from "#helpers/arrayFuncs";
import { basicOption } from "#helpers/general";
import { formatNumber } from "#helpers/number_format";
import { confirmationPrompt } from "../../Forms/common/prompts";

export const sharesClassFields = (list, currencies) => ({
  values: { contractualRights = [], ...values } = {}
}) => {
  let selectedCurrency = "";
  selectedCurrency =
    values.currency &&
    currencies.find(({ cuid }) => values.currency === cuid).shortName;

  return [
    {
      title: "1. What name did you choose for this share category?",
      className: "_fullwidth",
      input: {
        type: "text",
        name: "shareCategoryName",
        placeholder: "Common Shares Type A"
      }
    },
    {
      title: "2. What type of shares do you want to add?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: "shareType",

        options: [
          basicOption("common", "Common Shares"),
          basicOption(
            "common_with_preferential_rights",
            "Preferential Common Shares"
          ),
          basicOption("preferred", "Preferred Shares")
        ]
      }
    },
    {
      title:
        "3. Do your Article of Association restrict the transfer of the shares?",
      className: "_fullwidth",
      input: {
        name: "restrictSharesTransfer",
        ...confirmationPrompt
      }
    },
    {
      title: "4. In which currency the shares have been issued?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        options: formatArrayKeys(currencies, "cuid", "shortName"),
        name: "currency"
      }
    },
    {
      title: "5. What is the nominal value of this share?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: "subjectToVesting",
        options: [
          basicOption("1", `${selectedCurrency || ""} 1`),
          basicOption("Other", "Other")
        ]
      }
    },
    values.subjectToVesting === "Other" && {
      title: "Nominal value of this share",
      className: "_fullwidth",
      input: {
        type: "number",
        name: "nominalValuePrice",
        placeholder: `${selectedCurrency}`,
        formatter: value => {
          if (value.indexOf(",") !== -1) {
            value = value.replace(/,/g, ".");
          }
          return value
            ? `${selectedCurrency} ${value}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                "'"
              )
            : value;
        },
        parser: value =>
          value.replace(new RegExp(`${selectedCurrency} \\s?|(\\'*)`, "g"), "")
      }
    },
    {
      title: "6. How many votes are atributed to this share category?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: "votesNumber",
        options: [
          basicOption("1", "1", {
            selected: true,
            tooltip:
              "We currently do not provide the option for multiple voting rights"
          })
        ]
      }
    },
    values.shareType !== "common" && {
      title: `7. What  ${
        values && values.shareType === "preferred" ? "" : "contractual"
      } 
            preferential rights are associated with the shares?`,
      className: "_fullwidth",
      input: {
        type: "checkbox",
        name: "contractualRights",

        options: [
          basicOption("liquidation", "Liquidation preference", {
            disabled: values.shareType === "common"
          }),
          basicOption("dividend", "Dividend preference", {
            disabled: values.shareType === "common"
          }),
          basicOption("anti-dilution", "Anti-dilution protection", {
            disabled: values.shareType === "common"
          })
        ]
      }
    },
    contractualRights.includes("liquidation") && {
      title: "Liquidation preference",
      className: "_fullwidth _bold-15 _hidden",
      input: {}
    },
    contractualRights.includes("liquidation")
      ? {
          className: "_fullwidth _border-bottom _hidden",
          input: {}
        }
      : null,
    contractualRights.includes("liquidation")
      ? {
          title:
            "Do you have a participating or a non-participating liquidation preference?",
          className: "_fullwidth",
          input: {
            type: "select_btn",
            name: "liquidationPreferentialParticipation",
            options: [
              basicOption("participating", "Participating"),
              basicOption("non-participating", "Non-Participating")
            ]
          }
        }
      : null,
    contractualRights.includes("liquidation") &&
    values.liquidationPreferentialParticipation === "participating"
      ? {
          className: "_fullwidth",
          title:
            "Do you have a common catch up clause or a liquidation preference cap?",
          input: {
            type: "select_btn",
            name: "liquidationPreferentialClause",
            options: [
              basicOption("catch_up", "Catch Up"),
              basicOption("cap", "Cap")
            ]
          }
        }
      : null,
    contractualRights.includes("liquidation") &&
    values.liquidationPreferentialClause === "cap" &&
    values.liquidationPreferentialParticipation === "participating"
      ? {
          className: "_fullwidth",
          title: "How high should the liquidation cap be?",
          input: {
            type: "number",
            name: "liquidationPreferentialCap",
            formatter: value =>
              isNaN(value) ? formatNumber(parseInt(value)) : formatNumber(value)
          }
        }
      : null,
    //dividend
    contractualRights.includes("dividend") && {
      title: "Dividend preference",
      className: "_fullwidth _bold-15 _hidden",
      input: {}
    },
    contractualRights.includes("dividend")
      ? {
          className: "_fullwidth _border-bottom _hidden",
          input: {}
        }
      : null,
    contractualRights.includes("dividend")
      ? {
          title:
            "Do you have a participating or a non-participating dividend preference?",
          className: "_fullwidth",
          input: {
            type: "select_btn",
            name: "dividendPreferentialParticipation",
            options: [
              basicOption("participating", "Participating", {
                tooltip:
                  contractualRights.includes("liquidation") &&
                  "In Pre-Seed and Seed financing rounds it is common that the dividend preference follows the liquidation preference. If you selected different dividend preference then liquidation preference please consult your lawyer."
              }),
              basicOption("non-participating", "Non-Participating", {
                tooltip:
                  contractualRights.includes("liquidation") &&
                  "In Pre-Seed and Seed financing rounds it is common that the dividend preference follows the liquidation preference. If you selected different dividend preference then liquidation preference please consult your lawyer."
              })
            ]
          }
        }
      : null,
    contractualRights.includes("dividend") &&
    values.dividendPreferentialParticipation === "participating"
      ? {
          className: "_fullwidth",
          title:
            "Do you have a common catch up clause or a dividend preference cap?",
          input: {
            type: "select_btn",
            name: "dividendPreferentialClause",
            options: [
              basicOption("catch_up", "Catch Up", {
                tooltip:
                  contractualRights.includes("liquidation") &&
                  "In Pre-Seed and Seed financing rounds it is common that the dividend preference follows the liquidation preference. If you selected different dividend preference then liquidation preference please consult your lawyer."
              }),
              basicOption("cap", "Cap", {
                tooltip:
                  contractualRights.includes("liquidation") &&
                  "In Pre-Seed and Seed financing rounds it is common that the dividend preference follows the liquidation preference. If you selected different dividend preference then liquidation preference please consult your lawyer."
              })
            ]
          }
        }
      : null,
    contractualRights.includes("dividend") &&
    values.dividendPreferentialClause === "cap" &&
    values.dividendPreferentialParticipation === "participating"
      ? {
          className: "_fullwidth",
          title: "How high should the dividend cap be?",
          input: {
            type: "number",
            name: "dividendPreferentialCap",
            formatter: value =>
              isNaN(value) ? formatNumber(parseInt(value)) : formatNumber(value)
          }
        }
      : null,
    //anti-dilution

    contractualRights.includes("anti-dilution") && {
      title: "Anti-dilution protection",
      className: "_fullwidth _bold-15 _hidden",
      input: {}
    },
    contractualRights.includes("anti-dilution") && {
      title:
        "What type of anti-dilution protection are you granting investors?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: "antiDilutionOptions",
        options: [
          basicOption("narrow_based", "Narrow based", {
            tooltip:
              "This anti-dilution provision takes into account only the total number of outstanding preferred shares when calculating the new weighted average price for existing shares."
          }),
          basicOption("broad_based", "Broad based", {
            tooltip:
              "This anti-dilution provision takes into account all equity previously issued and currently undergoing issuing, including convertible securities such as options and warrants."
          }),
          basicOption("full_ratched", "Full ratchet", {
            tooltip:
              "This anti-dilution provision takes into account any shares of common stock sold by a company after the issuing of an option (or convertible security), applies the lowest sale price as being the adjusted option price or conversion ratio for existing shareholders"
          })
        ]
      }
    }
  ];
};
