import { clearState, clearStateDataByKey } from "#core/utils/helper_actions";
import { getTaxes } from "#core/modules/taxes/actions";
import { connect } from "react-redux";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { isFetching, didFetch, _data: { taxes = [] } = {} } = state.taxes;
  const {
    _data: { entity: { cuid: entityId, userRole } = {} } = {}
  } = state.entities;

  return {
    loading: isFetching,
    success: didFetch,
    taxes,
    entityId,
    userRole
  };
};

const mapDispatchToProps = dispatch => ({
  getTaxes: params => dispatch(getTaxes(params)),
  clearState: key => clearState(dispatch, key),
  clearStateDataByKey: (state, ...keys) =>
    clearStateDataByKey(dispatch, state, ...keys)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
