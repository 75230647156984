export const mapFounderInviteValues = (values, entityCuid) => {
  const payload = {
    inviteeEmail: values.inviteeEmail,
    redirectUrl: `${process.env.HOST}/founder_invite`
  };

  if (entityCuid) {
    payload.inviteeFirstName = values.inviteeFirstName;
    payload.inviteeLastName = values.inviteeLastName;
    payload.entityCuid = entityCuid;
  } else {
    payload.entityName = values.entityName;
  }

  return payload;
};

export const mapUpdateFounderInvitation = (values, type) => {
  return {
    userEmail: values.email,
    userPassword: values.password,
    userFirstName: values.firstName,
    userLastName: values.lastName,
    userSex: values.sex,
    entityName: values.name,
    [type]: {
      street: values.address !== "undefined undefined" ? values.address : "-",
      city: values.city || "-",
      country: values.idNumber ? "Switzerland" : values.country,
      postalCode: values.postalCode || "-",
      canton: values.canton || "-"
    },
    entityPhoneNumber: "-",
    // entityVat: values.vat,
    entityIdNumber: values.idNumber,
    entityGoverningLaw: values.governingLaw,
    entitySocialSecurityNumber: values.socialSecurityNumber,
    entityBirthdate: values.birthdate,
    entityIndustries: values.industries,
    entityDescription: values.description,
    entityTeaser: values.teaser,
    entityCompanyStatus: values.companyStatus,
    entityEmployees: values.employees,
    entityWebsiteUrl: values.websiteUrl,
    entityFundraisingActive: true,
    entityShares: values.entityShares || 100000,
    entityEmployeeParticipationPlan: {
      esopShares: values.esopShares || 0,
      psopShares: values.psopShares || 0
    },
    entityBankAccounts: values.bankAcc
      ? values.bankAcc.map(item => {
          return {
            beneficialOwner: item.beneficialOwner,
            name: item.bank,
            street: item.bankAddress,
            city: item.bankCity,
            postalCode: item.bankZip,
            country: item.bankCountry,
            accountNumber: item.bankAccNo,
            iban: item.iban,
            swift: item.bankSwift,
            currency: item.bankAccCurrency
          };
        })
      : [],
    entityLegalRepresentatives: values.legalReps
      ? values.legalReps.map(
          ({ firstName, lastName }) => `${firstName} ${lastName}`
        )
      : [],
    entityLinkedInUrl: values.linkedInUrl,
    entityTwitterUrl: values.twitterUrl
  };
};

export const mapCompanyDetails = (values, type) => {
  if (!values || (values && Object.keys(values).length === 0)) {
    return {};
  }
  // const finalChid = values.chid && values.chid.replace("CH", "");
  // add entityCommonName
  return {
    entityName: values.name,
    [type]: {
      street: values.address,
      city: values.city,
      country: values.idNumber ? "Switzerland" : values.country,
      postalCode: values.postalCode,
      canton: values.canton
    },
    entityIdNumber: values.idNumber,
    entityShares: values.totalShares || 100000,
    // entityVat: values.uid || "",
    entityDescription: values.description,
    entityWebsiteUrl: values.websiteUrl
  };
};

export const mapFounderInviteToMemberItem = item => {
  const returnStatus = () => {
    if (item.isUsed) {
      return "Used";
    }

    if (item.isExpired) {
      return "Expired";
    }

    return "Pending";
  };

  return {
    cuid: item.cuid,
    email: item.inviteeEmail,
    name: item.entityName,
    role: returnStatus(),
    indicator: "orange"
  };
};

export const mapFounderInviteToUser = values => ({
  email: values.inviteeEmail,
  name: values.entityName,
  firstName: values.inviteeFirstName,
  lastName: values.inviteeLastName
});
