import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};

const reducers = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.LOGIN:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            ...action.payload.data
          };
        }
      });

    case types.GET_SMS_VERIFICATION:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          smsSent: true
        })
      });

    case types.GET_SMS_VERIFICATION_NUMBER:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          smsSent: true
        })
      });

    case types.FETCH_USER_DETAILS:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            ...action.payload.data
          };
        }
      });

    case types.UPLOAD_PROFILE_PIC:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });

    case types.UPDATE_USER:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { profilePictureBase64 } = action.payload.data;
          const toUpdate = profilePictureBase64
            ? { profilePicture: profilePictureBase64, ...action.payload.data }
            : action.payload.data;

          return {
            ...state._data,
            ...toUpdate
          };
        }
      });

    case types.UPDATE_KYC_STATUS:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const kyc = (action.payload.data && action.payload.data.kyc) || {};
          const aml = (action.payload.data && action.payload.data.aml) || {};
          const stateAml = (state._data && state._data.aml) || {};
          const stateKyc = (state._data && state._data.kyc) || {};

          return {
            ...state._data,
            aml: {
              ...stateAml,
              ...aml
            },
            kyc: {
              ...stateKyc,
              ...kyc
            }
          };
        }
      });

    case types.ONBOARD_USER:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          return {
            ...state._data,
            ...action.payload.data
          };
        }
      });

    case types.POST_USER_APPLICATION:
      return baseReducer(action, state, actions.CREATE, {
        success: () => ({ ...state._data })
      });

    case types.SYNC_USER_DETAILS:
      return {
        ...state,
        _data: {
          ...state._data,
          ...action.payload
        }
      };
      
    default:
      return state;
  }
};
export default reducers;
