import { handleColor } from '#helpers/general';
import { notariesCols } from './shareholder';
import Radio from '../../../../Shared/Radio';
import Checkbox from '../../../../Shared/Checkbox';
import { notariesList } from '.';
import TagWrapper from '../../../../Shared/Tag';


const checkIfSelected = (directors, cuid) => {
  const selectedDirector = directors.filter(({ cuid: directorCuid, present = false }) => cuid === directorCuid && present);
  if (selectedDirector.length > 0) return true;
  return false;
};


const boardMeetingCols = ({
  handlers: { radioHandler, checkboxHandler },
  values
}) => [
    {
      title: 'Opener',
      dataIndex: 'opener',
      key: 'opener',
      render: (_, { cuid }) => (
        <Radio
          name="opener"
          value={cuid}
          checked={values.opener === cuid && checkIfSelected(values.boardOfDirectors, cuid)}
          onChange={radioHandler}
          disabled={!checkIfSelected(values.boardOfDirectors, cuid)}
          label=" "
        />
      )
    },
    {
      title: 'Present',
      dataIndex: 'present',
      key: 'present',
      render: (value, { cuid }) => (
        <Checkbox
          name="present"
          checked={value}
          onChange={checkboxHandler('boardOfDirectors', cuid)}
        />
      )
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'President of the board',
      dataIndex: 'presidentOfBoard',
      key: 'presidentOfBoard',
      render: (value) => (value ? 'Yes' : 'No')
    },
    {
      title: 'Representing',
      dataIndex: 'representativeOf',
      key: 'representativeOf',
      render: (text) => (
        <TagWrapper color={handleColor(text)}>
          {text}
        </TagWrapper>
      )
    },
    {
      title: 'Signature',
      dataIndex: 'signingAuthority',
      key: 'signingAuthority',
      render: (text) => (
        <TagWrapper color={handleColor(text)}>
          {text}
        </TagWrapper>
      )
    },
  ];

const boardFields = (formPropsBag) => [
  {
    title: 'Board of directors overview',
    details:
      'This are your current members of the board of directors. Please choose one board member, who  will open / opened the board of directors’ meeting, took the chair and will act / acted as recording secretary and scrutineer (it can be the current president or any other board member). Furthermore, please select who of the board members was / is present at the meeting?',
    input: {
      type: 'table_list',
      name: 'boardOfDirectors',
      cols: boardMeetingCols(formPropsBag)
    }
  },
  {
    title:
      'Which article number of the article of association has to be changes regarding the share capital increase',
    input: {
      type: 'number',
      name: 'articleNumber'
    }
  },
  {
    title: 'Please insert the new wording of the amended article',
    input: {
      type: 'textarea',
      rows: 4,
      name: 'amendedArticle'
    }
  },
  {
    title:
      'Which notary of our partner law firm X  shall notarize the public deed?',
    input: {
      type: 'table_list',
      name: 'bodMeetingNotary',
      cols: notariesCols('bodMeetingNotary', formPropsBag),
      data: notariesList
    }
  },
  {
    title: 'When did / will the board meeting take place / be held ?',
    input: {
      type: 'date',
      name: 'publicDeedBodDate'
    }
  }
];

export default boardFields;
