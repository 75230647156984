import * as fields from './fields';
import { indexItem } from '../../common/prompts';
import { NavLink } from 'react-router-dom';

const {
  meetings,
  management,
  compensation: [income, costs],
  generalInformation,
} = fields;

const getList = ({ values = {}, inputHandlers = {} } = {}) => {
  const indexedGeneralInformations = generalInformation(
    values,
    inputHandlers
  ).map(indexItem);
  const indexedMeetings = meetings.map(indexItem);
  const indexedManagement = management.map(indexItem);
  const indexedIncome = income(values)
    .filter(Boolean)
    .map(indexItem);
  const indexedCosts = costs(values)
    .filter(Boolean)
    .map(indexItem);

  const finalList = [
    {
      sectionId: 1,
      items: indexedGeneralInformations,
      stepTitle: (
        <span>
          This is the composition of your board of directors after the financing round according to your previous answers and the stakeholder management. If a board member is missing, please add him/her by visiting the <NavLink to='/stakeholders'> Stakeholder's </NavLink> page. Furthermore, please select which board member will leave the board, if any.
        </span>
      )
    },
    {
      sectionId: 2,
      items: indexedMeetings,
    },
    {
      sectionId: 3,
      subSectionId: 3,
      items: indexedIncome,
    },
    {
      sectionId: 3,
      subSectionId: 4,
      items: indexedCosts,
    },
    {
      sectionId: 5,
      items: indexedManagement,
    },
  ];
  return finalList;
};

export default getList;
