import SectionTitle from "../SectionTitle";

export const Description = ({ desc, ...props }) => {
  return (
    <div className="description" {...props}>
      {/* <SectionTitle title="Description" /> */}
      <div className="description-text">{desc}</div>
    </div>
  );
};
