import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { zefixHandlers } from "#helpers/zefix";
import { BasicForm } from "../../../Shared/Form";
import {
  existingShareholderAdditionFields,
  boardMemberAdditionFields,
  stakeholderAdditionTypes,
  lenderAdditionFields
} from "./list";
import { SelectButtons } from "../../../Shared/SelectInput";
import InputRenderer from "./InputRenderer";

const StakeholdersForm = ({
  handleUpload,
  shares,
  convertibles,
  handleSubmit,
  buttonStates,
  formValues = {},
  triggerSnackbar,
  directors,
  entityShares,
  companyDetails,
  logedUserCuid,
  zefix,
  onCancel,
  redirect,
  entityName,
  isOriginalFounder
}) => {
  const [state, setState] = useState({ type: "1" });
  const handleStateChange = nextState => setState({ ...state, ...nextState });
  const history = useHistory();

  const handleCancel = () => {
    if (redirect) {
      history.push(redirect);
    } else {
      history.goBack();
    }
  };

  const handleSelectionChange = name => ({ target: { value } }) =>
    handleStateChange({ [name]: value });

  useEffect(() => {
    if (formValues) {
      const {
        founderShares = [],
        beneficialOwners = [],
        representatives = []
      } = formValues;

      if (
        formValues.isFounder === "no" &&
        formValues.isDirector === "No" &&
        beneficialOwners.length === 0 &&
        founderShares.length === 0 &&
        formValues.role === "investor" &&
        representatives.length === 0
      ) {
        setState({ type: "2" });
      }

      if (formValues.role === "director") {
        setState({ type: "3" });
      }
    }
  }, []);

  const presidentExists = !!directors.find(
    ({ presidentOfTheBoard }) => presidentOfTheBoard
  );

  const { cuid: formValuesCuid = " " } = formValues || {};

  const isTargetEntity = logedUserCuid === formValuesCuid;

  const formattedConvertibles = convertibles.reduce(
    (acc, { cuid, ...item }) => ({ ...acc, [cuid]: item }),
    {}
  );

  const finalList = () => {
    if (state.type === "1") {
      const formattedShares = shares.reduce(
        (acc, { name, cuid }) => ({ ...acc, [cuid]: name }),
        {}
      );
      let totalSharesDistributed = 0;
      shares.forEach(({ total }) => {
        totalSharesDistributed += total;
      });

      entityShares = entityShares - totalSharesDistributed;

      return existingShareholderAdditionFields(
        formattedShares,
        formattedConvertibles,
        entityShares,
        presidentExists,
        zefix,
        isTargetEntity,
        formValues.founderShares,
        entityName,
        isOriginalFounder
      );
    } else if (state.type === "2") {
      return lenderAdditionFields(
        formattedConvertibles,
        presidentExists,
        zefix,
        isTargetEntity,
        entityName
      );
    }

    return boardMemberAdditionFields({
      presidentExists
    });
  };

  const customHook = ({ values, setFieldValue, setValues }) => {
    useEffect(() => {
      let valuesToUpdate = {};

      if (formValues) {
        valuesToUpdate = {
          ...valuesToUpdate,
          ...formValues
        };
      }

      if (companyDetails && values.type === "legal") {
        valuesToUpdate = {
          ...valuesToUpdate,
          firstName: companyDetails.name,
          lastName: companyDetails.uid,
          street: `${companyDetails.street} ${companyDetails.houseNumber}`,
          postalCode: companyDetails.zipCode,
          city: companyDetails.town,
          canton: companyDetails.canton,
          country: companyDetails.canton ? "Switzerland" : null
        };
      }

      if (Object.keys(valuesToUpdate).length > 0) {
        valuesToUpdate = {
          ...valuesToUpdate,
          type:
            formValues.lastName && formValues.lastName.match(/\d+/g)
              ? "legal"
              : "natural"
        };
        if (presidentExists) {
          valuesToUpdate = {
            ...valuesToUpdate,
            presidentOfTheBoard:
              valuesToUpdate.presidentOfTheBoard === "Yes" ? "Yes" : "No"
          };
        }

        setValues(valuesToUpdate);
      }
    }, [formValues, companyDetails, presidentExists]);
  };

  const formArgs = {
    fieldList: finalList(),
    CustomInputRenderer: InputRenderer(
      shares,
      triggerSnackbar,
      entityShares,
      formattedConvertibles,
      !!formValues,
      formValues.founderShares
    ),
    handleSubmit: handleSubmit(state.type),
    handleUpload,
    handleCancel,
    buttonStates,
    argsToBePassed: {
      mode: state.type,
      zefix
    },
    useCustomHook: customHook
  };

  return (
    <div className="stakeholders-form">
      <SelectButtons
        list={stakeholderAdditionTypes}
        value={state.type}
        name="type"
        onChange={handleSelectionChange}
        className="type-selection"
        disabled={isOriginalFounder}
      />
      <BasicForm {...formArgs} />
    </div>
  );
};

StakeholdersForm.propTypes = {
  shares: PropTypes.array,
  convertibles: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleUpload: PropTypes.func,
  buttonStates: PropTypes.object,
  formValues: PropTypes.object,
  directors: PropTypes.array
};

export default StakeholdersForm;
