import { connect } from "react-redux";
import { entityTypes } from "#constants/entity";
import Presentational from "./Presentational";
import {
  postCommit,
  deleteCommit,
  patchCommit,
  putCommit,
  updateCommitDataStatus
} from "#core/modules/commitment/actions";
import {
  generateContracts,
  getPooledFundTemplates
} from "#core/modules/contracts/actions";
import { clearState } from "#core/utils/helper_actions";

const mapStateToProps = state => {
  const io = (state.io._data && state.io._data.io) || {};

  const contracts =
    (state.contracts._data && state.contracts._data.contracts) || [];

  const { firstName = "", lastName = "" } = state.users._data || {};
  const { entitiesData = [] } =
    (state.entities._data && state.entities._data) || {};

  const { name: entityName = "" } =
    entitiesData.find(
      ({ type, creator: { cuid: creatorCuid } = {} }) =>
        type === entityTypes.ASSET_MANAGER_ENTITY &&
        creatorCuid === io.createdBy
    ) || {};

  const {
    didDelete,
    didCreate,
    didUpdate,
    isCreating,
    isUpdating,
    isDeleting,
    _data: { commit = {} } = {}
  } = state.commits || {};

  return {
    io,
    contracts,
    commit,
    isDeleting,
    commitDidDelete: didDelete,
    contractsAreFetching: state.contracts.isFetching,
    contractsDidFetch: state.contracts.didFetch,
    commitIsCreating: isCreating,
    commitDidCreate: didCreate,
    success: didCreate || didUpdate || didDelete,
    loading: isCreating || isUpdating || state.contracts.isFetching,
    name: `${firstName} ${lastName}`,
    entityName
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postCommit: values => dispatch(postCommit(values)),
    patchCommit: values => dispatch(patchCommit(values)),
    putCommit: values => dispatch(putCommit(values)),
    updateCommitDataStatus: values => dispatch(updateCommitDataStatus(values)),
    deleteCommit: data => dispatch(deleteCommit(data)),
    generateContracts: values => dispatch(generateContracts(values)),
    getPooledFundTemplates: values => dispatch(getPooledFundTemplates(values)),

    clearState: givenState => clearState(dispatch, givenState)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
