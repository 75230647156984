import PropTypes from 'prop-types';
import ModalWrapper from '../../../../../Shared/Modal';
import { BasicForm } from '../../../../../Shared/Form';

const AddItemsPresentational = ({
  fields,
  setFieldValue,
  name,
  value = [],
  onCancel,
  modalTitle,
  ...modalProps
}) => {
  const handleSubmit = (item, resetForm) => {
    setFieldValue(name, [...value, { id: value.length, ...item }]);
    onCancel();
    resetForm();
  };

  return (
    <ModalWrapper
      title={modalTitle}
      onCancel={onCancel}
      {...modalProps}
      destryOnClose
      footer={null}
    >
      <BasicForm fieldList={fields} handleSubmit={handleSubmit} />
    </ModalWrapper>
  );
};

AddItemsPresentational.propTypes = {
  modalTitle: PropTypes.string,
  fields: PropTypes.array,
  setFieldValue: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  onCancel: PropTypes.func
};

export default AddItemsPresentational;
