import types from "../action_types";
import storageKeys from "../../../../src/constants/storage";
import { saveItem, getItem } from "../../../../src/utils/storage";
import { syncUserDetails } from "../../users/actions/update";
import { triggerSnackbar } from "#core/modules/snackbar/actions";
import * as entitiesServices from "../../../../api/entitiesService";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import axios from "../../../../api/apiConfig";

export const updateEntity = data => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_ENTITY);
      const response = await entitiesServices.putEntity(data);
      const payload = response.data;
      if (data.previewImageBase64) {
        payload.previewImage = data.previewImageBase64;
      }
      success(dispatch, types.UPDATE_ENTITY, {
        data: payload
      });
      triggerSnackbar(dispatch, {
        variant: "success",
        message: "Successfully updated entity account information",
        placement: "bottomLeft",
        open: true
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_ENTITY, {
        error: errorData
      });
    }
  };
};

export const setEntity = id => {
  return dispatch => {
    try {
      start(dispatch, types.SET_ENTITY);
      success(dispatch, types.SET_ENTITY, {
        id
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.SET_ENTITY, {
        error: errorData
      });
    }
  };
};

export const updateFounderInvitation = data => {
  return async dispatch => {
    try {
      start(dispatch, types.PUT_FOUNDER_INVITATION);
      const response = await entitiesServices.putFounderInvitation(data);
      const token = response.data.token;

      /* REINSTATE THIS FOR AUTOMATIC LOGIN
       */
      saveItem(storageKeys.JWT, token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      success(dispatch, types.PUT_FOUNDER_INVITATION, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.PUT_FOUNDER_INVITATION, {
        error: errorData
      });
    }
  };
};

export const expireFounderInvitation = data => {
  return async dispatch => {
    try {
      start(dispatch, types.EXPIRE_FOUNDER_INVITATION);

      await entitiesServices.expireFounderInvitation(data);

      success(dispatch, types.EXPIRE_FOUNDER_INVITATION, {
        data: data.cuid
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.EXPIRE_FOUNDER_INVITATION, {
        error: errorData
      });
    }
  };
};

export const updateEntityMembership = (cuid, data) => {
  return async dispatch => {
    try {
      start(dispatch, types.UPDATE_ENTITY_MEMBERSHIP);
      const response = await entitiesServices.patchEntityMembership(cuid, data);
      success(dispatch, types.UPDATE_ENTITY_MEMBERSHIP, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.UPDATE_ENTITY_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};
