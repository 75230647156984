import { initialState, baseReducer } from '../../utils/helper_reducers';
import types from './action_types';
import actions from '../../constants/action_type';

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.READ_CURRENCIES:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return { ...state._data, currencies: action.payload.data };
        }
      });
    default:
      return state;
  }
};

export default reducer;
