import { connect } from "react-redux";
import { bulkDeleteEntityMemberships } from "#core/modules/entities/actions";
import {
  getPortfolioIoMemberships,
  bulkCreateIoMemberships
} from "#core/modules/iomemberships/actions";
import { fetchAllIos } from "#core/modules/io/actions";
import { getNdas, getNdaDoc } from "#core/modules/nda/actions";
import { clearState } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    _data: { entity: { cuid: entityId, userRole } = {} } = {},
    didCreate,
    isFetching
  } = state.entities;

  const { cuid: userCuid } = state.users._data || {};

  const {
    _data: { portfolioMemberships: ioMemberships = [] } = {},
    isFetching: membershipIsFetching,
    didFetch: membershipDidFetch,
    isCreating: membershipIsCreating,
    didCreate: membershipDidCreate
  } = state.ioMemberships;

  const ios = (state.io._data && state.io._data.ios) || [];

  const {
    _data: { list: ndasList = [], nda, ndaGenerated } = {},
    isFetching: ndaIsFetching,
    didFetch: ndaDidFetch,
    isUpdating: ndaIsUpdating,
    didUpdate: ndaDidUpdate
  } = state.nda;

  return {
    didCreate,
    membershipIsFetching,
    loading: isFetching || ndaIsFetching || membershipIsFetching,
    entityId,
    ios,
    userRole,
    membershipColl: {
      isCreating: membershipIsCreating,
      didCreate: membershipDidCreate,
      didFetch: membershipDidFetch,
      data: ioMemberships
    },
    ndaColl: {
      data: ndasList,
      loading: ndaIsFetching || ndaIsUpdating,
      success: ndaDidFetch,
      nda,
      ndaGenerated
    },
    userCuid
  };
};

const mapDispatchToProps = dispatch => ({
  getPortfolioIoMemberships: params =>
    dispatch(getPortfolioIoMemberships(params)),
  bulkDeleteEntityMemberships: data =>
    dispatch(bulkDeleteEntityMemberships(data)),
  bulkCreateIoMemberships: data => dispatch(bulkCreateIoMemberships(data)),
  fetchAllIos: id => dispatch(fetchAllIos(id)),
  getNdas: params => dispatch(getNdas(params)),
  getNdaDoc: params => dispatch(getNdaDoc(params)),
  clearState: key => clearState(dispatch, key)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
