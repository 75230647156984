import * as Yup from "yup";
import { returnError } from "./common";

const getLoginSchema = (token, twoStepAuthActive) => {
  const schema = {
    email: Yup.string()
      .email(returnError("invalid_email"))
      .required(returnError("required")),
    password: Yup.string()
      .min(8)
      .required(returnError("required"))
  };
  if (token && twoStepAuthActive) {
    schema.token = Yup.string().required(returnError("required"));
  }

  return Yup.object().shape(schema);
};

export default getLoginSchema;
