import { useEffect } from "react";
import PropTypes from "prop-types";
import claSchema from "#validators/cla/cla";
import { formatArrayKeys } from "#helpers/arrayFuncs";
import { claTsKeys } from "#constants/cla";
import { globalChangeHandler } from "#helpers/inputHandlers";
import { FormStepper, BasicFormWrapper } from "../../../Shared/Form";
import generateList from "./list";
import sections from "./sections";
import {
  handleInputChange,
  handleCheckboxInArray,
  handleInputInArray
} from "../../common/formHandler";
import CustomInputRenderer from "../../common/Inputs/CustomInputRenderer";
import CLAQuestionnaireSuccessView from "../../common/successViews/Cla";
import { setClaFormValues, setConvertibleNameExistValues } from "./handlers";

const ClaFormContainer = ({
  values,
  handleChange,
  setFieldValue,
  setFieldError,
  handleUpload,
  currencies,
  shareholdersColl,
  convertibles,
  buttonStates,
  handleSubmit,
  esopShares,
  psopShares,
  errors,
  setValues,
  ioColl,
  shares,
  governingLaw,
  name,
  allMemberships,
  ioMemberships,
  id,
  commits = [],
  saveFormData,
  formData,
  step,
  currPath
}) => {
  useEffect(
    setClaFormValues({
      convertibles,
      shareholdersColl,
      ioColl,
      allMemberships,
      step,
      ioMemberships,
      commits,
      formData,
      id,
      setValues,
      esopShares,
      psopShares
    }),
    [shareholdersColl.didFetch, allMemberships, esopShares, ioColl.didFetch]
  );
  useEffect(
    setConvertibleNameExistValues({
      convertibles,
      setFieldValue,
      name: values.name
    }),
    [values.name]
  );

  const handleFormSave = values => () => saveFormData(values);

  const { data: { cuid: createdIoCuid = "" } = {} } = ioColl;
  const {
    interestRate: interestRate = 0,
    maximumInterest: maximumInterest = 0,
    [claTsKeys.MONEY_RAISED]: moneyRaised = 0,
    safeHarborRule,
    [claTsKeys.INVESTORS_TABLE]: shareholders = [],
    [claTsKeys.INVESTOR_HAS_COMMITTED]: investorHasCommited = "no"
  } = values;
  let investingShareholders = [];
  if (shareholders) {
    investingShareholders = shareholders.filter(
      ({ select = false, amount = 0 }) => select && amount > 0
    );
  }

  const finalInterestRate =
    safeHarborRule === "yes" ? maximumInterest : interestRate;

  let annualInterestRate = (moneyRaised * finalInterestRate) / 100;

  if (investingShareholders.length > 0 && investorHasCommited === "yes") {
    let investorsAmount = 0;
    investingShareholders.forEach(({ amount = 0 }) => {
      investorsAmount += amount;
    });
    annualInterestRate = (investorsAmount * finalInterestRate) / 100;
  }

  const formikProps = { values, setFieldValue };
  const readableConvertibleTypes = formatArrayKeys(
    convertibles,
    "total",
    "name"
  );

  const inputHandlers = {
    checkboxHandler: globalChangeHandler(setFieldValue, values, "checkbox"),
    inputInArray: handleInputInArray(setFieldValue, values),
    checkboxInArray: handleCheckboxInArray(setFieldValue, values)
  };

  const fieldsList = generateList({
    values,
    currencies,
    convertibleTypes: readableConvertibleTypes,
    inputHandlers,
    formikProps,
    esopShares,
    name,
    shares,
    annualInterestRate,
    governingLaw,
    currPath,
    handleFormSave,
    psopShares
  });

  const formArgs = {
    list: fieldsList,
    sections,
    inputEvents: {
      onChange: type =>
        handleInputChange({ handleChange, setFieldValue, values })(type),
      onUpload: handleUpload(setFieldValue)
    },
    values,
    CustomInputRenderer,
    clickableSection: true,
    buttonStates,
    handleSubmit,
    customClassName: "cla-termsheet-container",
    success: buttonStates.success,
    CustomSuccess: () => CLAQuestionnaireSuccessView(createdIoCuid),
    validationSchema: claSchema,
    setFieldError,
    errors,
    step
  };

  return <FormStepper {...formArgs} />;
};

ClaFormContainer.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  currencies: PropTypes.array,
  shareholdersColl: PropTypes.object,
  convertibles: PropTypes.array,
  buttonStates: PropTypes.object,
  handleSubmit: PropTypes.func,
  ioColl: PropTypes.object,
  setValues: PropTypes.func
};

export default BasicFormWrapper(ClaFormContainer);
