import { Icon } from "antd";
import SharedTable from "../../../../../../../../Shared/SharedTable/Presentational";
import { Button } from "../../../../../../../../Shared/Button";
import { formatNumber } from "#helpers/number_format";

const sharesTable = [
  {
    title: "",
    dataIndex: "name"
  },
  {
    title: "Number of shares",
    dataIndex: "shares"
  },
  {
    title: "Total allocation",
    dataIndex: "allocation"
  }
];

export default function RemainingShares({
  currency,
  localSharesPrice,
  localNumberOfShares,
  handleRemainingSharesBtnClick,
  adjustedLocalFinalAllocation,
  allocationOnLeva,
  ioIsUpdating,
  originalSharesPrice,
  adjustAmount
}) {
  let sharesAllocatedOnLeva = Math.floor(allocationOnLeva / localSharesPrice);
  let remainingShares = localNumberOfShares - sharesAllocatedOnLeva;
  let initialAllocationOnLeva = Number(
    (sharesAllocatedOnLeva * localSharesPrice).toFixed(2)
  );
  let missingAllocation =
    adjustedLocalFinalAllocation - initialAllocationOnLeva;

  if (localSharesPrice !== originalSharesPrice) {
    initialAllocationOnLeva = adjustAmount({
      amount: allocationOnLeva,
      sharesPrice: localSharesPrice
    });

    sharesAllocatedOnLeva = Math.floor(
      initialAllocationOnLeva / localSharesPrice
    );
    remainingShares = localNumberOfShares - sharesAllocatedOnLeva;
    missingAllocation = adjustedLocalFinalAllocation - initialAllocationOnLeva;
  }

  const remainingSharesData = [
    {
      name: "Subscription form",
      shares: formatNumber(Number(localNumberOfShares)),
      allocation: formatNumber(
        Number(adjustedLocalFinalAllocation).toFixed(2),
        currency
      )
    },
    {
      name: "On Leva",
      shares: formatNumber(sharesAllocatedOnLeva),
      allocation: formatNumber(
        Number(initialAllocationOnLeva).toFixed(2),
        currency
      )
    },
    {
      name: "Missing amounts",
      shares: formatNumber(remainingShares),
      allocation: formatNumber(Number(missingAllocation).toFixed(2), currency)
    }
  ];

  const tableProps = {
    rowClassName: (_, index) =>
      index === remainingSharesData.length - 1 && "_red _bold"
  };

  return (
    <div className="remaining-shares-wrapper">
      <h3 className="_red">LAST IMPORTANT STEP!</h3>
      <p className="top-description">
        Due to the rounding of each commitment based on the share price, the
        total amount committed on Leva differs from the allocation in the
        subscription form.
      </p>
      <SharedTable
        tableProps={tableProps}
        columns={sharesTable}
        dataSource={remainingSharesData}
      />
      <p className="remaining-shares-warning-wrapper">
        <Icon type="warning" className="warning-icon" />
        <b>
          Ask one of the investors to increase the commitment by{" "}
          {`${currency} ${formatNumber(missingAllocation)} `}
          to match the difference in allocation and number of shares.
        </b>
      </p>
      <div className="remaining-shares-btn-wrapper">
        <Button
          size="large"
          type="secondary"
          label="Got it!"
          loading={ioIsUpdating}
          className="next-finish-btn"
          onClick={handleRemainingSharesBtnClick}
        />
      </div>
    </div>
  );
}
