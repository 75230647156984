import i18n from "../../../i18n";
import DealByDeal from "../../../assets/illustrations/deal manager page/DealByDeal.svg";
import AdministrationStreamlined from "../../../assets/illustrations/deal manager page/AdministrationStreamlined.svg";
import CommunityGrowth from "../../../assets/illustrations/deal manager page/ComunityGrowth.svg";
import NewReavenueStreams from "../../../assets/illustrations/deal manager page/NewReavenueStreams.svg";
import FundrisingLifeCycle from "../../../assets/illustrations/deal manager page/FundrisingLifeCycle.svg";
import ComplianceCovered from "../../../assets/illustrations/deal manager page/ComplianceCovered.svg";

import CompliantInvestorPooling from "../../../assets/illustrations/deal manager page/CompliantInvestorPooling.svg";
import ModularByDeal from "../../../assets/illustrations/deal manager page/modular-by-deal.svg";
import StreamlineYourWorkflow from "../../../assets/illustrations/deal manager page/streamline.svg";
import CreateShareClose from "../../../assets/illustrations/deal manager page/create-share.svg";
import ManageAndGrow from "../../../assets/illustrations/deal manager page/ManageAndGrow.svg";

export const features = [
  {
    header: i18n.t("landing.deal_manager.headers.complient_investor_pooling"),
    textLeft: window.innerWidth <= 768 ? true : false,
    text: i18n.t(
      "landing.deal_manager.features.complient_investor_pooling.text"
    ),
    items: [
      {
        content: i18n.t(
          "landing.deal_manager.features.complient_investor_pooling.items.pool_investors"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.complient_investor_pooling.items.collect_funds"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.complient_investor_pooling.items.choose_investmets"
        )
      }
    ],
    icon: CompliantInvestorPooling
  },
  {
    header: i18n.t("landing.deal_manager.headers.manage_and_grow"),
    textLeft: true,
    text: i18n.t("landing.deal_manager.features.manage_and_grow.text"),
    items: [
      {
        content: i18n.t(
          "landing.deal_manager.features.manage_and_grow.items.share_deals"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.manage_and_grow.items.show_investors_deals"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.manage_and_grow.items.track_progress"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.manage_and_grow.items.manage_investors"
        )
      }
    ],
    icon: ManageAndGrow
  },
  {
    header: i18n.t("landing.deal_manager.headers.create_share_close"),
    textLeft: window.innerWidth <= 768 ? true : false,
    text: i18n.t("landing.deal_manager.features.create_share_close.text"),
    items: [
      {
        content: i18n.t(
          "landing.deal_manager.features.create_share_close.items.share_content"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.create_share_close.items.follow_investors"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.create_share_close.items.track_deal_progress"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.create_share_close.items.report_easily"
        )
      }
    ],
    icon: CreateShareClose
  },
  {
    header: i18n.t("landing.deal_manager.headers.streamline_your_workflows"),
    textLeft: true,
    text: i18n.t(
      "landing.deal_manager.features.streamline_your_workflows.text"
    ),
    items: [
      {
        content: i18n.t(
          "landing.deal_manager.features.streamline_your_workflows.items.kyc_aml"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.streamline_your_workflows.items.collect_funds"
        )
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.streamline_your_workflows.items.sign_deals_online"
        )
      }
    ],
    icon: StreamlineYourWorkflow
  },
  {
    header: i18n.t("landing.deal_manager.headers.modular_deal_by_deal"),
    textLeft: window.innerWidth <= 768 ? true : false,
    text: i18n.t("landing.deal_manager.features.modular_deal_by_deal.text"),
    items: [
      {
        content: i18n.t(
          "landing.deal_manager.features.modular_deal_by_deal.items.fee_structure"
        ),
        insideItems: [
          {
            itemContent: i18n.t(
              "landing.deal_manager.features.modular_deal_by_deal.items.inside_items.no_fees"
            )
          },
          {
            itemContent: i18n.t(
              "landing.deal_manager.features.modular_deal_by_deal.items.inside_items.flat_fees"
            )
          },
          {
            itemContent: i18n.t(
              "landing.deal_manager.features.modular_deal_by_deal.items.inside_items.performance_fees"
            )
          },
          {
            itemContent: i18n.t(
              "landing.deal_manager.features.modular_deal_by_deal.items.inside_items.dynamic_fees"
            )
          }
        ]
      },
      {
        content: i18n.t(
          "landing.deal_manager.features.modular_deal_by_deal.items.minimum_tickets"
        )
      }
    ],
    icon: ModularByDeal
  }
];

export const dealBlocks = [
  {
    img: DealByDeal,
    header: i18n.t("landing.deal_manager.deal_blocks.headers.deal_by_deal"),
    text: i18n.t("landing.deal_manager.deal_blocks.features.deal_by_deal")
  },
  {
    img: AdministrationStreamlined,
    header: i18n.t(
      "landing.deal_manager.deal_blocks.headers.administration_streamlined"
    ),
    text: i18n.t(
      "landing.deal_manager.deal_blocks.features.administration_streamlined"
    )
  },
  {
    img: CommunityGrowth,
    header: i18n.t("landing.deal_manager.deal_blocks.headers.comunity_growth"),
    text: i18n.t("landing.deal_manager.deal_blocks.features.comunity_growth")
  },
  {
    img: NewReavenueStreams,
    header: i18n.t("landing.deal_manager.deal_blocks.headers.reavenue"),
    text: i18n.t("landing.deal_manager.deal_blocks.features.reavenue")
  },
  {
    img: FundrisingLifeCycle,
    header: i18n.t("landing.deal_manager.deal_blocks.headers.fundrising"),
    text: i18n.t("landing.deal_manager.deal_blocks.features.fundrising")
  },
  {
    img: ComplianceCovered,
    header: i18n.t("landing.deal_manager.deal_blocks.headers.compliance"),
    text: i18n.t("landing.deal_manager.deal_blocks.features.compliance")
  }
];
