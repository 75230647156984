import { basicOption } from '#helpers/general';
import { calculateSegmentationOutcomes } from '#helpers/mappers/onboarding';
import { renderOtherInput } from './common';

const occupation = {
  a:
    'a financial intermediary as defined in the Banking Act, the Financial Institutions Act (FinIA) and the CISA;',
  b: 'an insurance company as defined in the ISA;',
  c:
    'foreign clients subject to prudential supervision as the persons listed under a and b above;',
  d: 'a central bank;',
  e: 'a public entity with professional treasury operations;',
  f:
    'an occupational pension scheme with professional treasury operations and other occupational pension institutions providing professional treasury operations;',
  g: 'a company with professional treasury operations;',
  h: 'a large company',
  i:
    'a private investment structures with professional treasury operations created for high-net-worth retail clients'
};

const retailClientsProc = (values) => {
  const fields = [
    {
      id: 1,
      title:
        'You possess the necessary knowledge to understand the risks associated with investment through training, education or experience (professional or non-professional) in the financial sector. You also have assets of at least CHF 500’000 at your disposal and would like to be considered as a professional client (opting-out). ',
      input: {
        type: 'select_btn',
        name: 'retailClient',
        options: [
          basicOption('yes', 'Yes'),
          basicOption('no', 'No'),
          basicOption('other', 'Other')
        ]
      }
    }
  ];

  if (values.retailClient === 'No') {
    fields.push({
      title:
        'You have assets of at least CHF 2 million at your disposal and wish be treated as professional client (so-called opting out).',
      input: {
        type: 'select_btn',
        name: 'retailClientAssets',
        options: [basicOption('yes', 'Yes'), basicOption('no', 'No')]
      }
    });
  } else if (values.retailClient === 'other') {
    fields.push({
      title:
        'As a partner of the Simple Partnership, which is managed and represented by the Deal Manager, you want your assignment to a Segment to be based on the education and experience of this authorised person (Deal Manager).',
      input: {
        type: 'select_btn',
        name: 'authPersonBackground',
        options: [basicOption('yes', 'Yes'), basicOption('no', 'No')]
      }
    });
  }

  return fields;
};

const professionalClientsProc = (values) => {
  const options = () => {
    if (['f', 'g'].includes(values.occupation)) {
      return [basicOption('yes', 'Yes'), basicOption('c', 'No')];
    }

    return [basicOption('yes', 'Yes'), basicOption('no', 'No')];
  };

  const fields = [
    {
      id: 1,
      title:
        'I am a professional client and wish to be treated as professional client.',
      input: {
        type: 'select_btn',
        name: 'professionalClientBool',
        options: options()
      }
    },
    values.professionalClientBool === 'c'
      ? {
          id: 2,
          title:
            'I am a professional client and wish to be treated as an institutional client.',
          input: {
            type: 'select_btn',
            name: 'professionalClientExtra',
            options: [basicOption('yes', 'Yes'), basicOption('no', 'No')]
          }
        }
      : null
  ];

  return fields;
};

const institutionalClientsProc = (values) => [
  {
    id: 1,
    title:
      'I am an institutional client and wish to be treated as an institutional client.',
    input: {
      type: 'select_btn',
      name: 'institutionalClientBool',
      options: [basicOption('yes', 'Yes'), basicOption('no', 'No')]
    }
  },
  values.institutionalClientBool === 'no'
    ? {
        id: 2,
        title:
          'I am an institutional client and wish to be treated as a professional client.',
        input: {
          type: 'select_btn',
          name: 'institutionalClientExtra',
          options: [basicOption('yes', 'Yes'), basicOption('no', 'No')]
        }
      }
    : null
];

const firstStepItems = (values) => [
  {
    id: 1,
    title: 'Are you acting on behalf of someone?',
    input: {
      type: 'select_btn',
      name: 'actingOnBehalf',
      options: [basicOption('yes', 'Yes'), basicOption('no', 'No')]
    }
  },
  values.actingOnBehalf === 'yes'
    ? {
        id: 2,
        title: 'What describes you best?',
        input: {
          type: 'radio',
          name: 'occupation',
          options: Object.keys(occupation).map((key) =>
            basicOption(key, occupation[key])
          )
        }
      }
    : null,
  values.occupation
    ? {
        id: 3,
        title:
          'Please upload a current power of attorney, which is not older than three months',
        input: { type: 'upload', name: 'occupation_upload' }
      }
    : null
];

const secondStep = (values) => {
  let items = [];
  let stepTitle = '';

  if (values.actingOnBehalf === 'no') {
    stepTitle =
      'So far you are assigned to the segment of retail clients. \n\n Due to different levels of knowledge and experience, retail clients, professional clients and institutional clients have different levels of protection. Retail clients have the option of being assigned as professional clients (so-called opting out), provided they meet the legal requirements. By answering the following questions, you have the opportunity to be assigned as a professional client.';
    items = [...items, ...retailClientsProc(values)];
  } else if (
    values.actingOnBehalf === 'yes' &&
    ['a', 'b', 'c', 'd'].includes(values.occupation)
  ) {
    stepTitle =
      'So far you are assigned to the segment of institutional clients. \n\n Due to different levels of knowledge and experience, retail clients, professional clients and institutional clients have different levels of protection. Institutional clients have the option - provided they meet the legal requirements - to voluntarily classify themselves as professional clients (so- called opting out). They are then subject to the rules of conduct that a financial services provider must follow when dealing with a professional client. By answering the following questions, you have the opportunity to be placed in the next lower category (professional client).';
    items = [...items, ...institutionalClientsProc(values)];
  } else if (values.actingOnBehalf === 'yes' && ['e', 'f', 'g', 'h', 'i']) {
    stepTitle =
      'So far you are assigned to the segment of professional clients. \n \n Due to different levels of knowledge and experience, retail clients, professional clients and institutional clients have different levels of protection. Professional clients have the option - provided they meet the legal requirements - to classify themselves as institutional clients (so-called opting out). It is also possible for a professional client to be segmented as a retail client (so-called opting in). In the case of an opting out you are then subject to the rules of conduct that a financial services provider must follow when dealing with an institutional client, in the case of an opting in the rules of conduct for a retail client are applied. By answering the following questions, you have the opportunity to be segmented in the next higher (institutional client) or lower category (retail client).';
    items = [...items, ...professionalClientsProc(values)];
  }

  return {
    id: 2,
    stepTitle,
    items,
    sectiondId: 2
  };
};

const returnWaiverContent = (values) => {
  const {
    outcomeA,
    outcomeB,
    outcomeC,
    outcomeD,
    outcomeX,
    outcomeY
  } = calculateSegmentationOutcomes(values);

  const docsCollection = (
    <div>
      <span>
        I also agree to release LEVA from applying the code of conduct set out
        in Articles
      </span>
      <ul>
        <li>8 FinSA (content and form of information),</li>
        <li>9 FinSA (time and form of the information),</li>
        <li>15 FinSA (documentation) and</li>
        <li>16 FinSA (Rendering of account).</li>
      </ul>
    </div>
  );

  let content = '';
  if (values.waiverAccept === 'yes') {
    if (outcomeA) {
      // A1 variant

      content = (
        <div>
          <span>
            I hereby declare to act on behalf of {occupation[values.occupation]}{' '}
            and can present a corresponding power of representation.
          </span>
          {docsCollection}
        </div>
      );
    }

    if (outcomeB) {
      // B1 variant

      content = (
        <div>
          <span>
            I hereby declare on the basis of training, education and
            professional experience or on the basis of comparable experience in
            the financial sector, I possess the necessary knowledge to
            understand the risks associated with the investment and have assets
            of at least CHF 500’000 at my disposal.
          </span>
          <span>
            I therefore wish to be assigned as a professional client in the
            sense of the Federal Act on Financial Services and make use of the
            opting-out option mentioned in Art. 5 para. 1 FinSA.
          </span>
          {docsCollection}
        </div>
      );
    }

    if (outcomeC) {
      // C1 variant

      content = (
        <div>
          <span>
            I hereby declare, that I have assets of at least CHF 2 million at my
            disposal.
          </span>
          <span>
            I therefore wish to be assigned as a professional client in the
            sense of the Federal Act on Financial Services and make use of the
            opting-out option mentioned in Art. 5 para. 1 FinSA.
          </span>
          {docsCollection}
        </div>
      );
    }

    if (outcomeD) {
      // D1 variant

      content = (
        <div>
          <span>
            I hereby declare, that as a member of the Simple Partnership, which
            is managed and represented by the Deal Manager, I am assigned to the
            Deal Manager's segment. Based on the knowledge and experience of the
            Deal Manager, I am therefore also considered a professional client
            within the meaning of the Federal Act on Financial Services.
          </span>
          {docsCollection}
        </div>
      );
    }
  } else if (values.waiverAccept === 'no') {
    if (outcomeA) {
      // A2 variant

      content = (
        <div>
          <span>
            I hereby declaring acting on behalf of{' '}
            {occupation[values.occupation]} and can present a corresponding
            power of representation.
          </span>
        </div>
      );
    } else if (outcomeB) {
      // B2 variant

      content = (
        <div>
          <span>
            I hereby declare on the basis of training, education and
            professional experience or on the basis of comparable experience in
            the financial sector, I possess the necessary knowledge to
            understand the risks associated with the investment and have assets
            of at least CHF 500’000 at my disposal.
          </span>
          <span>
            I therefore wish to be assigned as a professional client in the
            sense of the Federal Act on Financial Services and make use of the
            opting-out option mentioned in Art. 5 para. 1 FinSA
          </span>
        </div>
      );
    } else if (outcomeC) {
      // C2 variant

      content = (
        <div>
          <span>
            I hereby declare, that I have assets of at least CHF 2 million at my
            disposal.
          </span>
          <span>
            I therefore wish to be assigned as a professional client in the
            sense of the Federal Act on Financial Services and make use of the
            opting-out option mentioned in Art. 5 para. 1 FinSA
          </span>
        </div>
      );
    } else if (outcomeD) {
      // D2 variant

      content = (
        <span>
          I hereby declare, that as a member of the Simple Partnership, which is
          managed and represented by the Deal Manager, I am assigned to the Deal
          Manager's segment. Based on the knowledge and experience of the Deal
          Manager, I am therefore also considered a professional client within
          the meaning of the Federal Act on Financial Services.
        </span>
      );
    }
  }

  if (outcomeX) {
    content = (
      <div>
        <span>
          I hereby declare acting on behalf of {occupation[values.occupation]}{' '}
          and can present a corresponding power of representation.
        </span>
        <span>I wish to be treated as an institutional client.</span>
      </div>
    );
  }

  if (outcomeY) {
    content = (
      <div>
        <span>
          I hereby declare acting on behalf of {occupation[values.occupation]}{' '}
          and can present a corresponding power of representation.
        </span>
      </div>
    );
  }

  const fields = [];

  if (!outcomeX && !outcomeY) {
    fields.push(returnWaiverPrompt());
  }

  fields.push({
    id: 4,
    sectionId: 1,
    items: [
      {
        input: { type: 'switch', label: content }
      }
    ]
  });

  return fields;
};

const returnWaiverPrompt = () => {
  const titleStyle = { display: 'flex', flexDirection: 'column' };

  const questionTitle = (
    <div style={titleStyle}>
      <span>
        I also agree to release LEVA from applying the code of conduct set out
        in Articles:{' '}
      </span>
      <ul>
        <li>8 FinSA (content and form of information), </li>
        <li>9 FinSA (time and form of the information), </li>
        <li>15 FinSA (documentation) and </li>
        <li>16 FinSA (Rendering of account)</li>
      </ul>
    </div>
  );

  const items = [
    {
      id: 1,
      title: questionTitle,
      input: {
        type: 'select_btn',
        name: 'waiverAccept',
        options: [basicOption('yes', 'Yes'), basicOption('no', 'No')]
      }
    }
  ];

  const stepTitle =
    'Professional clients have elementary duties to provide information about the nature of the service and financial instruments that they offer. This follows the belief that they possess a higher level of knowledge and/or experience and understand the associated risks of investments.';

  return { id: 3, sectionId: 1, stepTitle, items };
};

export default (values) => {
  const waiverContent = returnWaiverContent(values);

  return [
    {
      id: 1,
      sectionId: 1,
      stepTitle:
        'Following the meaning of the Federal Act on Financial Services (FinSA), Leva, as a financial services provider is obliged to segment all clients into retail, professional and/or institutional clients. The following questions will allow us to classify according to one of the named segments. ',
      items: firstStepItems(values)
    },
    {
      id: 2,
      ...secondStep(values)
    },
    ...waiverContent
  ];
};
