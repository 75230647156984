import { connect } from "react-redux";
import { clearState } from "#core/utils/helper_actions";
import {
  createEntityAndDealMembership,
  createEntityMembership
} from "#core/modules/entities/actions";
import { createIoMembership } from "#core/modules/iomemberships/actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { isCreating, didCreate } = state.ioMemberships;
  const {
    isCreating: entityCreating,
    didCreate: entityDidCreate,
    _data: { entity: { cuid: entityId, userRole } = {} } = {}
  } = state.entities;

  return {
    entityId,
    buttonStates: {
      loading: isCreating || entityCreating,
      success: didCreate || entityDidCreate
    },
    userRole
  };
};

const mapDispatchToProps = dispatch => ({
  createMembership: data => dispatch(createIoMembership(data)),
  createEntityAndDealMembership: data =>
    dispatch(createEntityAndDealMembership(data)),
  createEntityMembership: data => dispatch(createEntityMembership(data)),
  clearState: key => clearState(dispatch, key)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
