import { convertToBase64 } from "#helpers/convertBase64";
import { claTriggerEvents, claTsKeys } from "#constants/cla";
import { closedValues } from "#constants/io/closed";
import { generateRandomColor, checkDateString } from "#helpers/general";

export const mapClaArrayToReadable = (
  formattedConvertibles,
  convertibles = []
) => {
  return formattedConvertibles.map(ccc => {
    const existingCla = convertibles.find(
      ({ convertibleId = "" }) => convertibleId === ccc.cuid
    );
    if (existingCla) {
      return {
        ...ccc,
        select: true,
        fullyDilutedConvertible: existingCla.fullyDiluted
      };
    }
    return { ...ccc };
  });
};

const claMappingHelper = (cla = []) => {
  return cla
    .filter(({ select }) => select)
    .map(({ fullyDilutedConvertible = false, cuid = "" }) => ({
      convertibleId: cuid,
      fullyDiluted: fullyDilutedConvertible
    }));
};

export const mapClaMgmtValues = async ({
  values,
  file,
  entityId,
  esop,
  psop
}) => {
  let conversionPercentageST = 0;
  if (
    Array.isArray(values.triggerEvent) &&
    values.triggerEvent.includes("sale_transaction")
  ) {
    conversionPercentageST = values.saleTransactionLessPercentage;
  }
  const payload = {
    entityId,
    name: values.name,
    issueDate: values.issueDate,
    maturityDate: values.maturityDate,
    mandatoryConversion: values.mandatoryConversion === "mandatory",
    triggeringEvent: values.triggerEvent,
    minNextFinancingAmountNFR: values.min_amount || 0,
    shareTypeNFR: values.shareTypeConversion,
    shareTransferRestrictionNFR: values.aoaRestriction === "yes",
    claSubordinated: values.subordinated === "yes",
    fullyDilutedNFR: values.preMoneyValDiluted === "yes",
    outstandingClaEffectsIncludedNFR: values.oclEffectsInclusion === "yes",
    esopEffectsIncludedNFR: values.esopEffectsInclusion === "yes",
    valuationCapST: values.saleTransactionPreMoneyVal || 0,
    valuationCapMD: values.maturityDatePreMoneyVal || 0,
    shareTransferRestrictionMD: values.maturityDateAoaRestriction === "yes",
    // esopEffectsIncludedMD: values.maturityDateEsopEffectsInclusion === "yes",
    // outstandingClaEffectsIncludedMD:
    //   values.maturityDateOclEffectsInclusion === "yes",
    // fullyDilutedMD: values.maturityDatePreMoneyValDiluted === "yes",
    shareTypeMD: values.maturityDateShareTypeConversion,
    preMoneyValuation: values.minimumPreMoneyValuation,
    esopEffectsIncludedST: values.saleTransactionEsopEffectsInclusion === "yes",
    outstandingClaEffectsIncludedST:
      values.saleTransactionOclEffectsInclusion === "yes",
    fullyDilutedST: values.saleTransactionPreMoneyValDiluted === "yes",
    minValuationNFR: values.preMoneyValMinAmount || 0,
    minFullyDilutedNFR: values.minPreMoneyValDiluted === "yes",
    minOutstandingClaEffectsIncludedNFR:
      values.minOclEffectsInclusion === "yes",
    minEsopEffectsIncludedNFR: values.minEsopEffectsInclusion === "yes",
    raisedAmount: values.convertibleMaxAmount,
    conversionPercentageST: conversionPercentageST || 0,
    interestRateType: values.interestRateType,

    [claTsKeys.QUALIFY_EVENT_NFR]:
      values[claTsKeys.QUALIFY_EVENT_NFR] === "yes",
    [claTsKeys.REQUEST_LOAN_CONVERSION_NFR]:
      values[claTsKeys.REQUEST_LOAN_CONVERSION_NFR] === "yes",
    [claTsKeys.REQUEST_LOAN_DISCOUNT_NFR]:
      values[claTsKeys.REQUEST_LOAN_DISCOUNT_NFR],
    [claTsKeys.REQUEST_LOAN_SHARE_TYPE_NFR]:
      values[claTsKeys.REQUEST_LOAN_SHARE_TYPE_NFR],
    [claTsKeys.FOLLOW_CONVERSION_RULE_MD]:
      values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "yes",
    [claTsKeys.DEVIDE_VALUATION]: values[claTsKeys.DEVIDE_VALUATION] || 0,
    [claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED]:
      values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes",
    [claTsKeys.FULLY_DILUTED_POST_MONEY_MD]:
      values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes",
    [claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD]:
      values[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD] === "yes",
    [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR]:
      values[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR
      ] === "yes",
    [claTsKeys.CONVERSION_PRICE_BASIS_MD]:
      values[claTsKeys.CONVERSION_PRICE_BASIS_MD],
    [claTsKeys.MATURITY_DATE_FULLY_DILUTED]:
      values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes"
  };

  const {
    esopPsopEffectsIncludedFloorNFR = [],
    esopPsopEffectsIncludedFloorST = [],
    esopPsopFairMarketValue = [],
    esopPsopEffectsIncludedPostValuationMD = [],
    esopPsopEffectsIncludedMD = []
  } = values || {};

  if (
    Array.isArray(values.triggeringEvent) &&
    values.triggeringEvent.includes("sale_transaction")
  ) {
    payload[claTsKeys.SHARE_TYPE_CONVERSION_ST] =
      values[claTsKeys.SHARE_TYPE_CONVERSION_ST];
  }

  if (
    Array.isArray(values.conversionPriceBasisMD) &&
    values.conversionPriceBasisMD.includes("post_money_valuation")
  ) {
    payload[claTsKeys.POST_MONEY_VALUATION_LAST_ROUND] =
      values[claTsKeys.POST_MONEY_VALUATION_LAST_ROUND];
  }

  if (values.valuationFloorDefinedST === "yes") {
    payload[claTsKeys.VALUATION_FLOOR_ST] =
      values[claTsKeys.VALUATION_FLOOR_ST];
    payload[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] =
      values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes";
  }

  if (values.valuationFloorDefined === "yes") {
    payload[claTsKeys.VALUATION_FLOOR_NFR] =
      values[claTsKeys.VALUATION_FLOOR_NFR];
  }
  if (
    values.valuationCapBool === "yes" ||
    values.triggerEvent === claTriggerEvents.MATURITY_DATE
  ) {
    // payload.valuationCapBoolNFR = values.valuationCapBool;
    payload.valuationCapNFR = values.preMoneyVal;
  }

  if (values.valuationFloorDefined === "yes") {
    payload[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] =
      values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes";
  }

  if (
    values[
      claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR
    ] === "yes"
  ) {
    payload[
      claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR
    ] = claMappingHelper(
      values[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR
      ]
    );
  }

  if (
    values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR] === "yes"
  ) {
    payload[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR] = {
      esopShares: esopPsopEffectsIncludedFloorNFR.includes("esop") ? esop : 0,
      psopShares: esopPsopEffectsIncludedFloorNFR.includes("psop") ? psop : 0
    };
  }

  if (
    values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST] === "yes"
  ) {
    payload[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST] = {
      esopShares: esopPsopEffectsIncludedFloorST.includes("esop") ? esop : 0,
      psopShares: esopPsopEffectsIncludedFloorST.includes("psop") ? psop : 0
    };
  }

  if (
    values[claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP] === "yes"
  ) {
    payload[claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP] = {
      esopShares: esopPsopFairMarketValue.includes("esop") ? esop : 0,
      psopShares: esopPsopFairMarketValue.includes("psop") ? psop : 0
    };
  }

  if (values[claTsKeys.CHAMBER_OF_COMMERCE_MD]) {
    payload[claTsKeys.CHAMBER_OF_COMMERCE_MD] =
      values[claTsKeys.CHAMBER_OF_COMMERCE_MD] === "Other"
        ? values[claTsKeys.CHAMBER_OF_COMMERCE_MD_OTHER]
        : values[claTsKeys.CHAMBER_OF_COMMERCE_MD];
  }

  if (values[claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD] === "yes") {
    payload[claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD] = {
      esopShares: esopPsopEffectsIncludedPostValuationMD.includes("esop")
        ? esop
        : 0,
      psopShares: esopPsopEffectsIncludedPostValuationMD.includes("psop")
        ? psop
        : 0
    };
  }

  if (
    values[claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE] ===
    "yes"
  ) {
    payload[
      claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
    ] = claMappingHelper(
      values[
        claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
      ]
    );
  }

  if (values[claTsKeys.CL_EFFECTS_MD] === "yes") {
    payload[claTsKeys.CL_EFFECTS_MD_ARRAY] = claMappingHelper(
      values[claTsKeys.CL_EFFECTS_MD_ARRAY]
    );
  }

  if (
    values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ST] ===
    "yes"
  ) {
    payload[
      claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST
    ] = claMappingHelper(
      values[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST
      ]
    );
  }

  if (values[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD] === "yes") {
    payload[
      claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY
    ] = claMappingHelper(
      values[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]
    );
  }

  if (values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes") {
    payload[claTsKeys.CL_EFFECTS_MD] =
      values[claTsKeys.CL_EFFECTS_MD] === "yes";
    if (values[claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_EPP] === "yes") {
      payload[claTsKeys.EMPLOYE_PARTICIPATION_PLAN_MD] = {
        esopShares: esopPsopEffectsIncludedMD.includes("esop") ? esop : 0,
        psopShares: esopPsopEffectsIncludedMD.includes("psop") ? psop : 0
      };
    }
  }

  if (values.discountRateBool === "yes") {
    // payload.discountBoolNFR = values.discountRateBool === 'yes';
    payload.discountNFR = values.discountRate;
  }

  if (values.saleTransactionDiscountRateBool === "yes") {
    payload.discountST = values.saleTransactionDiscountRate;
  }

  if (values.underlyingSecurity === "yes") {
    payload.underlyingSecuritySharesType = values.underlyingSecurityType;
  }

  if (values.underlyingSecurityType === "common_shares") {
    payload.underlyingSecuritySharesNumber =
      values.underlyignSecurityAllocShares;
    payload.underlyingSecuritySharesNominalValue =
      values.underlyingSecuritySharesNominalValue;
  }

  if (values.interest === "yes") {
    payload.annualInterestRate = values.interestRate;
    payload.annualInterestCalculation = values.interestCalculation;
    payload.interestCompensationAtConversion =
      values.interestCompensation === "yes";
  }

  if (values.claAddition === "yes") {
    const claFileBase64 = await convertToBase64(file);

    payload.claFileBase64 = claFileBase64;
  }

  return payload;
};

export const mapClaMgmtEditValues = values => {
  const { issueDate = "", maturityDate = "" } = values || {};
  const mapBooleansToConfirmationOpts = dataToConvert =>
    Object.keys(dataToConvert)
      .filter(key => typeof dataToConvert[key] === "boolean")
      .reduce(
        (acc, key) => ({ ...acc, [key]: dataToConvert[key] ? "yes" : "no" }),
        {}
      );
  const readableBools = mapBooleansToConfirmationOpts(values);

  return {
    ...values,
    ...readableBools,
    name: values.name,
    issueDate: checkDateString(issueDate),
    maturityDate: checkDateString(maturityDate),
    mandatoryConversion:
      values.mandatoryConversion === true ? "mandatory" : "voluntary",
    triggerEvent: values.triggeringEvent,
    min_amount: values.minNextFinancingAmountNFR,
    shareTypeConversion: values.shareTypeNFR,
    aoaRestriction: values.shareTransferRestrictionNFR ? "yes" : "no",
    subordinated: values.claSubordinated ? "yes" : "no",
    preMoneyValDiluted: values.fullyDilutedNFR ? "yes" : "no",
    oclEffectsInclusion: values.outstandingClaEffectsIncludedNFR ? "yes" : "no",
    esopEffectsInclusion: values.esopEffectsIncludedNFR ? "yes" : "no",
    saleTransactionPreMoneyVal: values.valuationCapST,
    maturityDatePreMoneyVal: values.valuationCapMD,
    maturityDatePreMoneyValPrompt: values.valuationCapMD > 0 ? "yes" : "no",
    maturityDateAoaRestriction: values.shareTransferRestrictionMD
      ? "yes"
      : "no",
    // maturityDateEsopEffectsInclusion: values.outstandingClaEffectsIncludedPreMoneyMDArray
    //   ? "yes"
    //   : "no",
    maturityDateOclEffectsInclusion: values.outstandingClaEffectsIncludedPreMoneyMD
      ? "yes"
      : "no",
    maturityDatePreMoneyValDiluted: values.fullyDilutedPreMoneyMD
      ? "yes"
      : "no",
    maturityDateShareTypeConversion: values.shareTypeMD,
    saleTransactionEsopEffectsInclusion: values.esopEffectsIncludedST
      ? "yes"
      : "no",
    saleTransactionOclEffectsInclusion: values.outstandingClaEffectsIncludedST
      ? "yes"
      : "no",
    interestRateType: values.interestRateType,
    saleTransactionPreMoneyValDiluted: values.fullyDilutedST ? "yes" : "no",
    valuationCapBool: values.valuationCapNFR > 0 ? "yes" : "no",
    discountRateBool: values.discountNFR > 0 ? "yes" : "no",
    saleTransactionDiscountRateBool: values.discountST > 0 ? "yes" : "no",
    saleTransactionValuationCapBool: values.valuationCapST > 0 ? "yes" : "no",
    interest: values.annualInterestRate > 0 ? "yes" : "no",
    interestRate: values.annualInterestRate,
    interestCalculation:
      values.annualInterestCalculation &&
      values.annualInterestCalculation.toString(),
    interestCompensation: values.interestCompensationAtConversion
      ? "yes"
      : "no",
    currency: "CHF",
    valuationCap: values.preMoneyVal,
    discountRate: values.discountNFR,
    saleTransactionDiscountRate: values.discountST,
    shareType: values.shareTypeNFR,
    preMoneyVal: values.valuationCapNFR,
    underlyingSecurityType: values.underlyingSecurityType,
    underlyingSecuritySharesNumber: values.underlyignSecurityAllocShares,
    underlyingSecuritySharesNominalValue:
      values.underlyingSecuritySharesNominalValue,
    interestCompensationAtConversion: values.interestCompensation === "yes",
    claSubordinated: values.subordinated === "yes",
    preMoneyValMinAmount: values.minValuationNFR,
    minPreMoneyValDiluted: values.minFullyDilutedNFR ? "yes" : "no",
    minOclEffectsInclusion: values.minOutstandingClaEffectsIncludedNFR
      ? "yes"
      : "no",
    minEsopEffectsInclusion: values.minEsopEffectsIncludedNFR ? "yes" : "no",
    claAddition: "no",
    convertibleMaxAmount: values.raisedAmount,
    saleTransactionLessPercentage:
      values.conversionPercentageST > 0 ? values.conversionPercentageST : 0,
    min_amountPrompt: values.minNextFinancingAmountNFR > 0 ? "yes" : "no",
    preMoneyValMinAmountPrompt: values.minValuationNFR > 0 ? "yes" : "no",
    requestLoanDiscountNFRPrompt:
      values[claTsKeys.REQUEST_LOAN_DISCOUNT_NFR] > 0 ? "yes" : "no",
    valuationFloorDefined:
      values[claTsKeys.VALUATION_FLOOR_NFR] > 0 ? "yes" : "no",
    valuationFloorDefinedST:
      values[claTsKeys.VALUATION_FLOOR_ST] > 0 ? "yes" : "no"
  };
};

export const formatStakeholdersToConvertibleInvestors = ({
  data,
  convertibleTypes
}) => {
  return (
    data
      // .filter(({ convertibles }) => convertibles.length > 0)
      .filter(({ user: { role = " " } = {} }) => role !== "founder")
      .map(
        ({
          cuid,
          entityMembershipId,
          user,
          convertibles: convertiblesData,
          shares,
          entityIsShareholder
        }) => {
          const calculatedData = convertiblesData.reduce((acc, item) => {
            const { amount, type } = item;
            const {
              totalAmount: currentTotalAmount = 0,
              totalInterest: currentTotalInterest = 0
            } = acc;
            const { annualInterestRate = 0 } = convertibleTypes[type] || {};

            const currentInterest = amount * annualInterestRate;

            return {
              totalAmount: currentTotalAmount + amount,
              totalInterest: currentTotalInterest + currentInterest
            };
          }, {});

          const calculatedShares = shares.reduce(
            (acc, { number: sharesNumber }) => acc + sharesNumber,
            0
          );

          const { totalAmount = 0, totalInterest = 0 } = calculatedData;

          const type =
            calculatedShares > 0 && totalAmount > 0
              ? "Shareholder Lender"
              : calculatedShares > 0 && totalAmount === 0
              ? "Shareholder"
              : totalAmount > 0 && calculatedShares === 0
              ? "Lender"
              : null;
          return {
            cuid,
            membershipId: entityMembershipId,
            totalAmount,
            totalInterest,
            sharesNumber: calculatedShares,
            type,
            entityIsShareholder,
            ...user
          };
        }
      )
  );
};

export const formatStakeholdersToLenders = ({ data, convertibleTypes }) => {
  return data
    .filter(({ convertibles }) => convertibles.length > 0)
    .map(
      ({
        cuid,
        entityMembershipId,
        user,
        convertibles: convertiblesData,
        shares
      }) => {
        const calculatedData = convertiblesData.reduce((acc, item) => {
          const { amount, type } = item;
          const {
            totalAmount: currentTotalAmount = 0,
            totalInterest: currentTotalInterest = 0
          } = acc;
          const { annualInterestRate = 0 } = convertibleTypes[type] || {};

          const currentInterest = amount * annualInterestRate;

          return {
            totalAmount: currentTotalAmount + amount,
            totalInterest: currentTotalInterest + currentInterest
          };
        }, {});

        const calculatedShares = shares.reduce(
          (acc, { number: sharesNumber }) => acc + sharesNumber,
          0
        );

        const { totalAmount = 0, totalInterest = 0 } = calculatedData;

        const type =
          calculatedShares > 0 && totalAmount > 0
            ? "Shareholder Lender"
            : calculatedShares > 0 && totalAmount === 0
            ? "Shareholder"
            : totalAmount > 0 && calculatedShares === 0
            ? "Lender"
            : null;
        return {
          cuid,
          membershipId: entityMembershipId,
          totalAmount,
          totalInterest,
          sharesNumber: calculatedShares,
          type,
          ...user
        };
      }
    );
};

export const mapClTsValues = (
  values,
  updating = false,
  onlyCla = false,
  esop,
  psop
) => {
  const formattedDirectors =
    values.signingDirector &&
    values.signingDirector.length > 0 &&
    values.signingDirector
      .filter(({ signingDirector }) => signingDirector)
      .map(({ membershipId, presidentOfTheBoard: presidentOfBoard }) => ({
        membershipId,
        presidentOfBoard
      }));
  let payload = {
    [claTsKeys.QUALIFY_EVENT_NFR]:
      values[claTsKeys.QUALIFY_EVENT_NFR] === "yes",
    [claTsKeys.REPAY_LOAN_PRIOR_MATURITY_DATE]:
      values[claTsKeys.REPAY_LOAN_PRIOR_MATURITY_DATE],
    [claTsKeys.TS_SIGNED_BY]: values[claTsKeys.TS_SIGNED_BY],
    [claTsKeys.REDUCE_LOAN_PRIOR_MATURITY_DATE]:
      values[claTsKeys.REDUCE_LOAN_PRIOR_MATURITY_DATE] === "yes",
    [claTsKeys.AOA_RESTRICTION]: false,
    [claTsKeys.SALE_TRANSACTION_VALUATION_CAP]: 0,
    [claTsKeys.SALE_TRANSACTION_DISCOUNT_RATE]: 0,
    [claTsKeys.DISCOUNT_RATE]: 0,
    [claTsKeys.CL_EFFECTS]: false,
    [claTsKeys.CL_EFFECTS_MD]: false,
    // [claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_ESOP]: false,
    [claTsKeys.PRE_MONEY_VAL_DILUTED]: false,
    [claTsKeys.MATURITY_DATE_FULLY_DILUTED]: false,
    // [claTsKeys.ESOP_EFFECTS]: false,
    [claTsKeys.PRE_MONEY_VAL]: 0,
    [claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE]: false,
    // [claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_ESOP]: false,
    [claTsKeys.SERIES_ROUND]: values[claTsKeys.SERIES_ROUND],
    // [claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE]:
    //   values[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE] === "yes",
    [claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED]:
      values[claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED] === "yes",
    // [claTsKeys.GOVERNING_LAW]:
    //   values[claTsKeys.GOVERNING_LAW] === "Other"
    //     ? values[claTsKeys.GOVERNING_LAW_OTHER]
    //     : values[claTsKeys.GOVERNING_LAW],
    [claTsKeys.ANNUAL_INTEREST]: values[claTsKeys.INTEREST_RATE]
      ? values[claTsKeys.INTEREST_RATE]
      : values[claTsKeys.MAXIMUM_INTEREST],
    [claTsKeys.VALIDITY]: values[claTsKeys.VALIDITY],
    shareholdersInvestThroughCLA: true,
    [claTsKeys.CONVERSION_PERFORM]:
      values[claTsKeys.CONVERSION_PERFORM] === "mandatory",
    [claTsKeys.TRIGGER_EVENT]: values[claTsKeys.TRIGGER_EVENT],
    [claTsKeys.MIN_AMOUNT_TRIGGER_CONVERSION]:
      values[claTsKeys.MIN_AMOUNT_TRIGGER_CONVERSION] || 0,
    [claTsKeys.SHARE_TYPE_CONVERSION]: values[claTsKeys.SHARE_TYPE_CONVERSION],
    [claTsKeys.SHARE_TYPE_MD]: values[claTsKeys.SHARE_TYPE_MD],
    [claTsKeys.UNDERLYING_SECURITY]:
      values[claTsKeys.UNDERLYING_SECURITY] === "yes",
    [claTsKeys.UNDERLYING_SECURITY_SHARES]:
      values[claTsKeys.UNDERLYING_SECURITY_SHARES],
    [claTsKeys.UNDERLYING_SECURITY_SHARES_NOMINAL_VAL]:
      values[claTsKeys.UNDERLYING_SECURITY_SHARES_NOMINAL_VAL],
    [claTsKeys.SALE_TRANSACTION_FULLY_DILUTED]:
      values[claTsKeys.SALE_TRANSACTION_FULLY_DILUTED] === "yes",
    [claTsKeys.UNDERLYING_SECURITY_TYPE]:
      values[claTsKeys.UNDERLYING_SECURITY_TYPE],
    [claTsKeys.LIQUIDATION_PREFERENCE]:
      values[claTsKeys.LIQUIDATION_PREFERENCE],
    [claTsKeys.CL_SUBORDINATED]: values[claTsKeys.CL_SUBORDINATED] === "yes",
    [claTsKeys.ESOP]: values[claTsKeys.ESOP] === "yes",
    [claTsKeys.ESOP_SHARES]: values[claTsKeys.ESOP_SHARES],
    [claTsKeys.MIN_VALUATION_NFR]: values[claTsKeys.MIN_VALUATION_NFR] || 0,
    [claTsKeys.MATURITY_DATE_PRE_MONEY_VAL]:
      values[claTsKeys.MATURITY_DATE_PRE_MONEY_VAL] || 0,
    [claTsKeys.SAFE_HARBOR]: values[claTsKeys.SAFE_HARBOR] === "yes",
    [claTsKeys.TS_DIRECTORS]: formattedDirectors,
    [claTsKeys.REQUEST_LOAN_CONVERSION_NFR]:
      values[claTsKeys.REQUEST_LOAN_CONVERSION_NFR] === "yes",
    [claTsKeys.CONVERSION_PRICE_BASIS_MD]:
      values[claTsKeys.CONVERSION_PRICE_BASIS_MD],
    [claTsKeys.FULLY_DILUTED_POST_MONEY_MD]:
      values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes",
    [claTsKeys.FOLLOW_CONVERSION_RULE_MD]:
      values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "yes",
    [claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD]:
      values[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD] === "yes",

    [claTsKeys.REQUEST_LOAN_DISCOUNT_NFR]:
      values[claTsKeys.REQUEST_LOAN_DISCOUNT_NFR] || 0,

    [claTsKeys.REQUEST_LOAN_SHARE_TYPE_NFR]:
      values[claTsKeys.REQUEST_LOAN_SHARE_TYPE_NFR],

    [claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR]:
      values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes",
    [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR]:
      values[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR
      ] === "yes",
    [claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST]:
      values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes",
    [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ST]:
      values[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ST
      ] === "yes",
    [claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED]:
      values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes",
    [claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE]:
      values[
        claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE
      ] === "yes",
    [claTsKeys.DEVIDE_VALUATION]: values[claTsKeys.DEVIDE_VALUATION] || 0,

    onlyCLA: false
  };

  // const convertibles =
  //   (Array.isArray(values[claTsKeys.CONVERTIBLE_LOANS]) &&
  //     values[claTsKeys.CONVERTIBLE_LOANS].map(({ name, total }) => ({
  //       type: name || "",
  //       amount: total || 0
  //     }))) ||
  //   [];

  // payload[claTsKeys.CONVERTIBLE_LOANS] = convertibles;

  const {
    esopPsopEffectsIncludedFloorNFR = [],
    esopPsopEffectsIncludedFloorST = [],
    esopPsopFairMarketValue = [],
    esopPsopEffectsIncludedPostValuationMD = [],
    esopPsopEffectsIncludedNFR = [],
    esopPsopEffectsIncludedMD = [],
    minEffectsIncludedNFREsopPsop = [],
    esopPsopEffectsIncludedST = []
  } = values || {};

  if (
    Array.isArray(values.triggeringEvent) &&
    values.triggeringEvent.includes("sale_transaction")
  ) {
    payload[claTsKeys.SALE_TRANSACTION_SHAREHOLDERS_OWNING_LESS] =
      values[claTsKeys.SALE_TRANSACTION_SHAREHOLDERS_OWNING_LESS];

    payload[claTsKeys.SHARE_TYPE_CONVERSION_ST] =
      values[claTsKeys.SHARE_TYPE_CONVERSION_ST];
    if (values.valuationFloorDefinedST === "yes") {
      payload[claTsKeys.VALUATION_FLOOR_ST] =
        values[claTsKeys.VALUATION_FLOOR_ST];
    }
  }

  if (
    Array.isArray(values.conversionPriceBasisMD) &&
    values.conversionPriceBasisMD.includes("post_money_valuation")
  ) {
    payload[claTsKeys.POST_MONEY_VALUATION_LAST_ROUND] =
      values[claTsKeys.POST_MONEY_VALUATION_LAST_ROUND];
  }

  if (
    values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR] === "yes"
  ) {
    payload[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR] = {
      esopShares: esopPsopEffectsIncludedFloorNFR.includes("esop") ? esop : 0,
      psopShares: esopPsopEffectsIncludedFloorNFR.includes("psop") ? psop : 0
    };
  }

  if (
    values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST] === "yes"
  ) {
    payload[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST] = {
      esopShares: esopPsopEffectsIncludedFloorST.includes("esop") ? esop : 0,
      psopShares: esopPsopEffectsIncludedFloorST.includes("psop") ? psop : 0
    };
  }

  if (
    values[claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP] === "yes"
  ) {
    payload[claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP] = {
      esopShares: esopPsopFairMarketValue.includes("esop") ? esop : 0,
      psopShares: esopPsopFairMarketValue.includes("psop") ? psop : 0
    };
  }

  if (values[claTsKeys.CHAMBER_OF_COMMERCE_MD]) {
    payload[claTsKeys.CHAMBER_OF_COMMERCE_MD] =
      values[claTsKeys.CHAMBER_OF_COMMERCE_MD] === "Other"
        ? values[claTsKeys.CHAMBER_OF_COMMERCE_MD_OTHER]
        : values[claTsKeys.CHAMBER_OF_COMMERCE_MD];
  }

  if (values[claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD] === "yes") {
    payload[claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD] = {
      esopShares: esopPsopEffectsIncludedPostValuationMD.includes("esop")
        ? esop
        : 0,
      psopShares: esopPsopEffectsIncludedPostValuationMD.includes("psop")
        ? psop
        : 0
    };
  }

  if (
    values[
      claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR
    ] === "yes"
  ) {
    payload[
      claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR
    ] = claMappingHelper(
      values[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR
      ]
    );
  }

  if (
    values[claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE] ===
    "yes"
  ) {
    payload[
      claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
    ] = claMappingHelper(
      values[
        claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
      ]
    );
  }

  if (
    values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ST] ===
    "yes"
  ) {
    payload[
      claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST
    ] = claMappingHelper(
      values[
        claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST
      ]
    );
  }

  if (values.valuationFloorDefined === "yes") {
    payload[claTsKeys.VALUATION_FLOOR_NFR] =
      values[claTsKeys.VALUATION_FLOOR_NFR];
  }

  if (
    values[claTsKeys.ANNUAL_INTEREST] === "yes" ||
    values[claTsKeys.SAFE_HARBOR] === "yes"
  ) {
    payload = {
      ...payload,
      [claTsKeys.ANNUAL_INTEREST_CALCULATION]: Number(
        values[claTsKeys.ANNUAL_INTEREST_CALCULATION]
      ),
      [claTsKeys.YEARLY_INTEREST_RATE]: values[claTsKeys.YEARLY_INTEREST_RATE],
      [claTsKeys.INTEREST_CONVERSION]:
        values[claTsKeys.INTEREST_CONVERSION] === "yes"
    };
  }

  if (values[claTsKeys.CL_SUBORDINATED] === "yes") {
    payload[claTsKeys.EQUALLY_MANAGED] =
      values[claTsKeys.EQUALLY_MANAGED] === "yes";
  }

  if (values[claTsKeys.REPAY_LOAN_PRIOR_MATURITY_DATE] === "lenders_consent") {
    payload[claTsKeys.LENDER_REPAY_TERM] =
      values[claTsKeys.LENDER_REPAY_TERM] === "Other"
        ? values[claTsKeys.LENDER_REPAY_TERM_OTHER]
        : Number(values[claTsKeys.LENDER_REPAY_TERM]);
  }

  if (values[claTsKeys.VALUATION_CAP] === "yes") {
    payload[claTsKeys.PRE_MONEY_VAL] = values[claTsKeys.PRE_MONEY_VAL];
    payload[claTsKeys.PRE_MONEY_VAL_DILUTED] =
      values[claTsKeys.PRE_MONEY_VAL_DILUTED] === "yes";

    if (payload[claTsKeys.PRE_MONEY_VAL_DILUTED]) {
      // payload[claTsKeys.ESOP_EFFECTS] =
      //   values[claTsKeys.ESOP_EFFECTS] === "yes";
      if (values[claTsKeys.POTENTIALLY_OUTSTANDING_EPP] === "yes") {
        payload[claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_NFR] = {
          esopShares: esopPsopEffectsIncludedNFR.includes("esop") ? esop : 0,
          psopShares: esopPsopEffectsIncludedNFR.includes("psop") ? psop : 0
        };
      }
      payload[claTsKeys.CL_EFFECTS] = values[claTsKeys.CL_EFFECTS] === "yes";
    }
  }

  if (values[claTsKeys.CL_EFFECTS] === "yes") {
    payload[claTsKeys.CL_EFFECTS_ARRAY] = claMappingHelper(
      values[claTsKeys.CL_EFFECTS_ARRAY]
    );
  }

  if (values[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD === "yes"]) {
    payload[
      claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY
    ] = claMappingHelper(
      values[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY]
    );
  }
  if (values[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE] === "yes") {
    payload[
      claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
    ] = claMappingHelper(
      values[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY]
    );
  }

  if (values[claTsKeys.CL_EFFECTS_MD] === "yes") {
    payload[claTsKeys.CL_EFFECTS_MD_ARRAY] = claMappingHelper(
      values[claTsKeys.CL_EFFECTS_MD_ARRAY]
    );
  }

  if (
    values[claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE] ===
    "yes"
  ) {
    payload[
      claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
    ] = claMappingHelper(
      values[
        claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY
      ]
    );
  }

  if (values.valuationCapMDPrompt === "yes") {
    payload[claTsKeys.MATURITY_DATE_FULLY_DILUTED] =
      values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes";
    if (values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes") {
      payload[claTsKeys.CL_EFFECTS_MD] =
        values[claTsKeys.CL_EFFECTS_MD] === "yes";
      if (
        values[claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_EPP] === "yes"
      ) {
        payload[claTsKeys.EMPLOYE_PARTICIPATION_PLAN_MD] = {
          esopShares: esopPsopEffectsIncludedMD.includes("esop") ? esop : 0,
          psopShares: esopPsopEffectsIncludedMD.includes("psop") ? psop : 0
        };
      }
    }
  }
  if (values.minValuationNFRPrompt === "yes") {
    payload[claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED] =
      values[claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED] === "yes";
    if (values[claTsKeys.MIN_NEXT_FINANCING_FULLY_DILUTED] === "yes") {
      payload[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE] =
        values[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_CONVERTIBLE] === "yes";
      if (values[claTsKeys.MIN_POTENTIALLY_OUTSTANDING_EPP] === "yes") {
        payload[claTsKeys.EMPLOYEE_PARTICITION_PLAN_MIN_NFR] = {
          esopShares: minEffectsIncludedNFREsopPsop.includes("esop") ? esop : 0,
          psopShares: minEffectsIncludedNFREsopPsop.includes("psop") ? psop : 0
        };
      }
    }
  }
  if (values[claTsKeys.DISCOUNT_RATE_BOOL] === "yes") {
    payload[claTsKeys.DISCOUNT_RATE] = values[claTsKeys.DISCOUNT_RATE];
  }
  if (values[claTsKeys.SALE_TRANSACTION_DISCOUNT_BOOL] === "yes") {
    payload[claTsKeys.SALE_TRANSACTION_DISCOUNT_RATE] =
      values[claTsKeys.SALE_TRANSACTION_DISCOUNT_RATE];
  }
  if (values[claTsKeys.SALE_TRANSACTION_VALUATION_CAP_BOOL] === "yes") {
    payload[claTsKeys.SALE_TRANSACTION_VALUATION_CAP] =
      values[claTsKeys.SALE_TRANSACTION_VALUATION_CAP];
    if (values[claTsKeys.SALE_TRANSACTION_FULLY_DILUTED] === "yes") {
      payload[claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE] =
        values[
          claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_CONVERTIBLE
        ] === "yes";
      if (
        values[claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_EPP] === "yes"
      ) {
        payload[claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_ST] = {
          esopShares: esopPsopEffectsIncludedST.includes("esop") ? esop : 0,
          psopShares: esopPsopEffectsIncludedST.includes("psop") ? psop : 0
        };
      }
      // payload[claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_ESOP] =
      //   values[claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_ESOP] ===
      //   "yes";
    }

    if (values[claTsKeys.SHARE_TYPE_CONVERSION] === "common") {
      payload[claTsKeys.AOA_RESTRICTION] =
        values[claTsKeys.AOA_RESTRICTION] === "yes";
    }
  }
  if (onlyCla) {
    payload.preExistingSHA = values.preExistingAgreement === "yes";
  }
  if (updating) {
    if (!onlyCla) {
      payload.updatedContract = "term-sheet-cla";
    }
    return {
      [claTsKeys.NAME]: values[claTsKeys.NAME],
      [claTsKeys.CURRENCY]: values[claTsKeys.CURRENCY],
      cla: payload
    };
  }
  return {
    [claTsKeys.NAME]: values[claTsKeys.NAME],
    [claTsKeys.CURRENCY]: values[claTsKeys.CURRENCY],
    [claTsKeys.SHARES_OUTSTANDING]: values[claTsKeys.SHARES_OUTSTANDING],
    cla: payload,
    closed: closedValues.TS_DONE
  };
};

export const mapCLAQuestionnaireValues = (values, updating = false) => {
  // if (values.claPurposeArea) {
  //   const lastChar = values.claPurposeArea.charAt(
  //     values.claPurposeArea.length - 1
  //   );
  //   if (lastChar !== ".") {
  //     values.claPurposeArea += ".";
  //   }
  // }
  let formattedDirectors;
  if (values.signingDirector) {
    formattedDirectors = values.signingDirector
      .map(
        ({
          membershipId,
          presidentOfTheBoard: presidentOfBoard,
          signingDirector
        }) => {
          if (signingDirector) {
            return { membershipId, presidentOfBoard };
          }
        }
      )
      .filter(director => director !== undefined);
  }

  const cla = {
    [claTsKeys.CLA_DIRECTORS]: formattedDirectors,
    [claTsKeys.CLA_SIGNED_BY]: values[claTsKeys.CLA_SIGNED_BY],
    onlyCLA: false,
    claPurpose:
      values.claPurpose === "ordinary_business_activity"
        ? "Ordinary business activity"
        : values.claPurposeArea,
    claPaymentDeadlineInDays:
      values.investorLoanPayout !== "Other"
        ? values.investorLoanPayout
        : values.investorLoanPayoutOther,
    preExistingSHA: values.preExistingAgreement === "yes",
    [claTsKeys.LENDER_AFFILIATE_WITHOUT_CONSENT]:
      values[claTsKeys.LENDER_AFFILIATE_WITHOUT_CONSENT] === "yes",
    [claTsKeys.BOARD_DISCLOSURE_THIRD_PARTIES]:
      values[claTsKeys.BOARD_DISCLOSURE_THIRD_PARTIES] === "yes"
    // preMoneyValuation: values.preMoneyValuation
    // annualInterestCalculation: Number(values.annualInterestRateCalculated)
    // interestCompensationAtConversion: values.convertedInterest === "yes",
  };
  const finalShaDate =
    values.preExistingDate &&
    values.preExistingDate.toISOString().split("T")[0];

  if (values.preExistingAgreement === "yes") {
    cla.shaDate = finalShaDate;
  }

  if (updating) {
    cla.updatedContract = "cla";
    return {
      cla
    };
  }
  return {
    cla,
    closed: closedValues.CLA_DONE
  };
};

export const mapClaValues = (
  values,
  updating = false,
  esopShares,
  psopShares
) => {
  const onlyCla = true;
  const { cla: claTs = {}, ...claTsOtherData } = mapClTsValues(
    values,
    updating,
    onlyCla,
    esopShares,
    psopShares
  );
  const {
    cla: claQuestionnaire = {},
    ...claQuestionnaireOtherData
  } = mapCLAQuestionnaireValues(values, updating);

  const claPayload = {
    cla: {
      ...claTs,
      ...claQuestionnaire,
      onlyCLA: true,
      tsDirectors: []
    },
    ...claTsOtherData,
    ...claQuestionnaireOtherData
  };
  return {
    ...claPayload
  };
};

export const mapClaDataToReadableFormVals = (deal = {}, opts) => {
  const {
    cla = {},
    name = "",
    mandatoryConversion,
    fundingGoal = 0,
    currency: { cuid: currencyCuid = "" } = {}
  } = deal || {};
  const {
    [claTsKeys.EMPLOYEE_PARTICITION_PLAN_MIN_NFR]: {
      esopShares = 0,
      psopShares = 0
    } = {},
    [claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_NFR]: {
      esopShares: outstandingEsop = 0,
      psopShares: outStandingPsop = 0
    } = {},
    [claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_ST]: {
      esopShares: saleTransactionEsop = 0,
      psopShares: saleTransactionPsop = 0
    } = {},
    [claTsKeys.EMPLOYE_PARTICIPATION_PLAN_MD]: {
      esopShares: maturityDateEsop = 0,
      psopShares: maturityDatePsop = 0
    } = {},
    [claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD]: {
      esopShares: postMoneyEsop = 0,
      psopShares: postMoneyPsop = 0
    } = {},
    [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR]: {
      esopShares: floorNfrEsop = 0,
      psopShares: floorNfrPsop = 0
    } = {},
    [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST]: {
      esopShares: floorStEsop = 0,
      psopShares: floorStPsop = 0
    } = {},
    [claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP]: {
      esopShares: fmvEsop = 0,
      psopShares: fmvPsop = 0
    } = {}
  } = cla || {};
  const data = {};
  const mapBooleansToConfirmationOpts = dataToConvert =>
    Object.keys(dataToConvert)
      .filter(key => typeof dataToConvert[key] === "boolean")
      .reduce(
        (acc, key) => ({ ...acc, [key]: dataToConvert[key] ? "yes" : "no" }),
        {}
      );
  const saleTransactionPercentage = cla[
    claTsKeys.SALE_TRANSACTION_SHAREHOLDERS_OWNING_LESS
  ]
    ? cla[claTsKeys.SALE_TRANSACTION_SHAREHOLDERS_OWNING_LESS]
    : 0;
  const readableBools = mapBooleansToConfirmationOpts(cla);
  const readableCla = {
    ...cla,
    ...readableBools,
    preExistingAgreement: readableBools.preExistingSHA,
    preExistingDate: cla.shaDate && checkDateString(cla.shaDate),
    [claTsKeys.CURRENCY]: currencyCuid,
    [claTsKeys.NAME]: name,
    [claTsKeys.MONEY_RAISED]: fundingGoal,

    [claTsKeys.MIN_POTENTIALLY_OUTSTANDING_EPP]:
      esopShares > 0 || psopShares > 0 ? "yes" : "no",

    [claTsKeys.POTENTIALLY_OUTSTANDING_EPP]:
      outstandingEsop > 0 || outStandingPsop > 0 ? "yes" : "no",

    [claTsKeys.SALE_TRANSACTION_POTENTIALLY_OUTSTANDING_EPP]:
      saleTransactionEsop > 0 || saleTransactionPsop > 0 ? "yes" : "no",

    [claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_EPP]:
      maturityDateEsop > 0 || maturityDatePsop > 0 ? "yes" : "no",

    [claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD]:
      postMoneyEsop > 0 || postMoneyPsop > 0 ? "yes" : "no",

    [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR]:
      floorNfrEsop > 0 || floorNfrPsop > 0 ? "yes" : "no",

    [claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST]:
      floorStEsop > 0 || floorStPsop > 0 ? "yes" : "no",

    [claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP]:
      fmvEsop > 0 || fmvPsop > 0 ? "yes" : "no",

    annualInterestCalculation: cla.annualInterestCalculation,
    [claTsKeys.SALE_TRANSACTION_DISCOUNT_BOOL]:
      cla.discountST > 0 ? "yes" : "no",
    [claTsKeys.SALE_TRANSACTION_VALUATION_CAP_BOOL]:
      cla.valuationCapST > 0 ? "yes" : "no",
    minNextFinancingAmountNFRPrompt:
      cla.minNextFinancingAmountNFR > 0 ? "yes" : "no",
    [claTsKeys.DISCOUNT_RATE_BOOL]: cla.discountNFR > 0 ? "yes" : "no",
    [claTsKeys.VALUATION_CAP]: cla.valuationCapNFR > 0 ? "yes" : "no",
    minValuationNFRPrompt: cla.minValuationNFR > 0 ? "yes" : "no",
    valuationCapMDPrompt: cla.valuationCapMD > 0 ? "yes" : "no",
    mandatoryConversion: mandatoryConversion ? "mandatory" : "voluntary",
    [claTsKeys.SALE_TRANSACTION_SHAREHOLDERS_OWNING_LESS]: saleTransactionPercentage,
    claPurpose:
      cla.claPurpose === "ordinary_business_activity"
        ? "ordinary_business_activity"
        : "customize",
    lendersReplyToPrepaymentOfferInDays:
      cla.lendersReplyToPrepaymentOfferInDays &&
      (cla.lendersReplyToPrepaymentOfferInDays === 15
        ? cla.lendersReplyToPrepaymentOfferInDays.toString()
        : cla.lendersReplyToPrepaymentOfferInDays === 30
        ? cla.lendersReplyToPrepaymentOfferInDays.toString()
        : "Other"),
    valuationFloorDefinedST:
      cla[claTsKeys.VALUATION_FLOOR_ST] > 0 ? "yes" : "no",
    valuationFloorDefined:
      cla[claTsKeys.VALUATION_FLOOR_NFR] > 0 ? "yes" : "no",
    requestLoanDiscountNFRPrompt:
      cla[claTsKeys.REQUEST_LOAN_DISCOUNT_NFR] > 0 ? "yes" : "no"
  };

  if (cla.claPaymentDeadlineInDays === "5") {
    readableCla.investorLoanPayout = "5";
  } else {
    readableCla.investorLoanPayout = "Other";
    readableCla.investorLoanPayoutOther = cla.claPaymentDeadlineInDays;
  }

  if (cla.claPurpose === "Ordinary business activity") {
    readableCla.claPurpose = "ordinary_business_activity";
  } else {
    readableCla.claPurposeArea = cla.claPurpose;
  }

  if (cla[claTsKeys.ANNUAL_INTEREST]) {
    readableCla[claTsKeys.ANNUAL_INTEREST] = "yes";
    readableCla[claTsKeys.INTEREST_RATE] = cla[claTsKeys.ANNUAL_INTEREST];

    if (readableCla[claTsKeys.ANNUAL_INTEREST_CALCULATION]) {
      readableCla[claTsKeys.ANNUAL_INTEREST_CALCULATION] = cla[
        claTsKeys.ANNUAL_INTEREST_CALCULATION
      ].toString();
    }
  } else {
    readableCla[claTsKeys.ANNUAL_INTEREST] = "no";
  }

  data.cla = readableCla;

  return data;
};

export const readConvertiblesData = (convertibles, entitySharesNo = 1) => {
  return convertibles.reduce((acc, item) => {
    const [
      accLabels = [],
      accData = [],
      accColors = [],
      accMappedData = []
    ] = acc;
    const {
      name,
      fullyDilutedMD,
      fullyDilutedNFR,
      fullyDilutedST,
      discountNFR,
      discountST,
      valuationCapMD,
      valuationCapNFR,
      valuationCapST,
      annualInterestRate = 0,
      currentRaisedTotal: total
    } = item;

    const discount = discountNFR || discountST || 0;
    const valuationCap =
      valuationCapNFR || valuationCapST || valuationCapMD || 0;
    const sharesPrice = valuationCap / entitySharesNo;
    const sharesConverted = sharesPrice ? Math.floor(total / sharesPrice) : 0;

    const mappedItem = {
      name,
      total,
      discount,
      valuationCap,
      interestRate: annualInterestRate,
      sharesPrice,
      sharesConverted
    };

    return [
      [...accLabels, name],
      [...accData, total],
      [...accColors, generateRandomColor()],
      [...accMappedData, mappedItem]
    ];
  }, []);
};
