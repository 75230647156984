import { withFormik } from "formik";
import { initValues } from "#helpers/mappers/form/initValues";
import Presentational from "./Presentational";

const FormWrapper = (Target, config = {}) =>
  withFormik({
    mapPropsToValues: ({ fieldList, values = {}, rawInitValues }) => {
      const initVals = values || config.values;

      return initValues(fieldList, initVals, rawInitValues);
    },
    validationSchema: ({ validationSchema }) => validationSchema,
    handleSubmit: (values, { props: { handleSubmit }, resetForm }) => {
      handleSubmit(values, resetForm);
    },
    enableReinitialize: config.enableReinitialize
  })(Target);

export default FormWrapper(Presentational);

const CustomBasicForm = config => FormWrapper(Presentational, config);

export { FormWrapper, CustomBasicForm };
