import axios from "./apiConfig";

export const getEntities = params =>
  axios.get("entity-memberships", { params });

export const getEntitiesList = params => axios.get("entities", { params });

export const getSingleEntity = id => axios.get(`entities/${id}`);

export const getEntityMemberships = ({ id, role }) => {
  let path = `entities/${id}/memberships?`;

  if (role) {
    path += `role=${role}`;
  }

  return axios.get(path);
};

export const getAllEntityMemberships = params =>
  axios.get(`portfolio/entity-memberships`, { params });

export const getFounderInvitations = () => axios.get(`founder-invitations`);

export const getEntityShareholders = params =>
  axios.get("entity-shareholders", { params });

export const getCompanies = params =>
  axios.get(`entities/zefix-companies`, { params });

export const getCompanyDetails = params =>
  axios.get(`entities/zefix-company`, { params });

export const getSingleFounderInvitation = cuid =>
  axios.get(`founder-invitations/${cuid}`);

export const putEntity = ({ cuid, ...entity }) =>
  axios.patch(`entities/${cuid}`, entity);

export const putFounderInvitation = ({ cuid, ...data }) =>
  axios.put(`founder-invitations/${cuid}/confirmation`, data);

export const expireFounderInvitation = ({ cuid }) =>
  axios.put(`founder-invitations/${cuid}/expiration`);

export const postEntity = entity => axios.post("entities", entity);

export const postEntityMemberships = data =>
  axios.post("entity-memberships", data);

export const postFounderInvitation = values =>
  axios.post("founder-invitations", values);

export const postEntityAndFounder = values =>
  axios.post(`entities-and-founders`, values);

export const deleteEntityMembership = cuid =>
  axios.delete(`entity-memberships/${cuid}`);

export const deleteEntity = cuid => axios.delete(`entities/${cuid}`);

export const patchEntityMembership = (cuid, data) =>
  axios.patch(`entity-memberships/${cuid}`, data);

export const updateEntityMembershipStatus = (cuid, data) =>
  axios.put(`entity-memberships/${cuid}`, data);
