import { iaKeys } from "#constants/io/equityPool";
import { basicOption } from "#helpers/general";

const successors = [
  {
    title:
      "Shall the investors be entitled to assign or transfer their rights andobligations under this Investment & Subscription Agreement to any other party without the prior written consent of the founders and the existing shareholders?",
    input: {
      name: iaKeys.CONSENT_NEEDED_TRANSFER,
      type: "select_btn",
      options: [
        basicOption("yes", "Yes", {
          tooltip:
            "The investors will still need the prior written consent of each investors."
        }),
        basicOption("no", "No")
      ]
    }
  }
];

export default successors;
