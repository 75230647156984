import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Detector } from 'react-detect-offline';
import { triggerSnackbar } from '#core/modules/snackbar/actions';
import { clearStateData } from '#core/utils/helper_actions';
import ErrorBox from './components/ErrorBox';

class ErrorHandler extends Component {
  state = {
    hasError: false
  };

  async componentDidMount() {
    this.props.clearStateData('snackbar');
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { triggerSnackbar: triggerSnackbarFunc } = this.props;

    triggerSnackbarFunc({
      message: 'An error has occurred. Please check console!',
      variant: 'error',
      open: true
    });

    this.setState({ error, errorInfo });

    console.error(
      ` ---------------------- \n Error: ${error}, Error Info: ${errorInfo.componentStack} \n ------------------------`
    );
  }

  render() {
    const { hasError, error, errorInfo } = this.state;

    return hasError ? (
      <ErrorBox error={error} errorInfo={errorInfo} />
    ) : (
      <div>{this.props.children}</div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    triggerSnackbar: (snackbar) => triggerSnackbar(dispatch, snackbar),
    clearStateData: (key) => clearStateData(dispatch, key)
  };
};

ErrorHandler.propTypes = {
  children: PropTypes.any,
  triggerSnackbar: PropTypes.func
};

export default connect(
  null,
  mapDispatchToProps
)(ErrorHandler);
