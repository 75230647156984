import * as menuIcons from "./Menu";

export const InformationIcon = ({ width, height, marginBottom }) => {
  return (
    <svg
      style={{ isolation: "isolate", marginBottom: marginBottom || "0" }}
      viewBox="728.291 398.791 29.418 29.418"
      width={width || "25.418"}
      height={height || "25.418"}
    >
      <defs>
        <clipPath id="_clipPath_Yk4LMZX6ExY7XsH3ZjXyaZHiXfh7AgYT">
          <rect x="728.291" y="398.791" width="29.418" height="29.418" />
        </clipPath>
      </defs>
      <g clipPath="url(#_clipPath_Yk4LMZX6ExY7XsH3ZjXyaZHiXfh7AgYT)">
        <g>
          <circle
            vectorEffect="non-scaling-stroke"
            cx="743"
            cy="413.5"
            r="14.709090909090833"
            fill="rgb(137,218,156)"
          />
          <circle
            vectorEffect="non-scaling-stroke"
            cx="742.9999999999998"
            cy="413.5"
            r="11.120427163198201"
            fill="rgb(255,255,255)"
          />
          <path
            d=" M 740.678 411.624 Q 743.709 411.624 744.385 411.624 Q 745.06 411.624 745.042 412.333 Q 745.042 416.471 745.042 417.03 C 745.042 417.588 745.098 417.694 745.641 417.694 Q 746.183 417.694 746.261 418.314 Q 746.261 418.897 746.261 419.599 Q 746.261 420.301 745.552 420.308 L 740.723 420.308 Q 740.014 420.257 740.014 419.466 C 740.014 418.676 740.014 418.895 740.014 418.248 Q 740.014 417.601 740.856 417.65 Q 741.343 417.577 741.343 417.163 Q 741.343 416.748 741.343 414.792 Q 741.13 414.061 740.612 414.061 Q 740.094 414.061 740.058 413.507 Q 740.058 412.533 740.058 412.112 Q 740.058 411.691 740.678 411.624 Z "
            fill="rgb(137,218,156)"
          />
          <circle
            vectorEffect="non-scaling-stroke"
            cx="743.0132913472072"
            cy="408.46848667396864"
            r="1.8903249361080725"
            fill="rgb(137,218,156)"
          />
        </g>
      </g>
    </svg>
  );
};

export const InformationIconViolet = ({ width, height, marginBottom }) => {
  return (
    <svg
      style={{
        isolation: "isolate",
        marginBottom: marginBottom || "0",
        marginLeft: "20px"
      }}
      viewBox="728.291 398.791 29.418 29.418"
      width={width || "25.418"}
      height={height || "25.418"}
    >
      <defs>
        <clipPath id="_clipPath_Yk4LMZX6ExY7XsH3ZjXyaZHiXfh7AgYT">
          <rect x="728.291" y="398.791" width="29.418" height="29.418" />
        </clipPath>
      </defs>
      <g clipPath="url(#_clipPath_Yk4LMZX6ExY7XsH3ZjXyaZHiXfh7AgYT)">
        <g>
          <circle
            vectorEffect="non-scaling-stroke"
            cx="743"
            cy="413.5"
            r="14.709090909090833"
            fill="rgb(89,126,247)"
          />
          <circle
            vectorEffect="non-scaling-stroke"
            cx="742.9999999999998"
            cy="413.5"
            r="11.120427163198201"
            fill="rgb(255,255,255)"
          />
          <path
            d=" M 740.678 411.624 Q 743.709 411.624 744.385 411.624 Q 745.06 411.624 745.042 412.333 Q 745.042 416.471 745.042 417.03 C 745.042 417.588 745.098 417.694 745.641 417.694 Q 746.183 417.694 746.261 418.314 Q 746.261 418.897 746.261 419.599 Q 746.261 420.301 745.552 420.308 L 740.723 420.308 Q 740.014 420.257 740.014 419.466 C 740.014 418.676 740.014 418.895 740.014 418.248 Q 740.014 417.601 740.856 417.65 Q 741.343 417.577 741.343 417.163 Q 741.343 416.748 741.343 414.792 Q 741.13 414.061 740.612 414.061 Q 740.094 414.061 740.058 413.507 Q 740.058 412.533 740.058 412.112 Q 740.058 411.691 740.678 411.624 Z "
            fill="rgb(173,198,255)"
          />
          <circle
            vectorEffect="non-scaling-stroke"
            cx="743.0132913472072"
            cy="408.46848667396864"
            r="1.8903249361080725"
            fill="rgb(173,198,255)"
          />
        </g>
      </g>
    </svg>
  );
};

export const LinkedInIconNoBG = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="linkedin-in"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className="svg-inline--fa fa-linkedin-in fa-w-14 fa-2x"
    >
      <path
        fill="#0077B7"
        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
        className=""
      />
    </svg>
  );
};

export const MailIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="linkedin-in"
      role="img"
      enable-background="new 0 0 479.058 479.058"
      viewBox="0 0 479.058 479.058"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-inline--fa fa-linkedin-in fa-w-14 fa-2x"
    >
      <path d="m434.146 59.882h-389.234c-24.766 0-44.912 20.146-44.912 44.912v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159l-200.355 173.649-200.356-173.649c1.769-.736 3.704-1.159 5.738-1.159zm0 299.411h-389.234c-8.26 0-14.971-6.71-14.971-14.971v-251.648l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z" />
    </svg>
  );
};
export const PizzaIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="pizza-slice"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="svg-inline--fa fa-pizza-slice fa-w-16 fa-2x"
    >
      <path
        fill="currentColor"
        d="M158.87.15c-1.09-.1-2.18-.15-3.26-.15a32.85 32.85 0 0 0-32.07 24.27L.55 491.63A16.24 16.24 0 0 0 16.15 512a16.54 16.54 0 0 0 4.4-.61l467.6-129.66c15.72-4.35 25.49-19.67 23.62-35.89C490.89 165.08 340.78 17.32 158.87.15zM38.49 473.21L133 113.91c148.3 14 248.38 112.63 264.45 259.75zM428.65 365C409.38 206.37 301.13 99.81 141.26 82.67l13.24-50.31a1.54 1.54 0 0 1 1.11-.36h.25c165.67 15.63 305 152.13 323.74 318.89zM192 176a48 48 0 1 0 48 48 48.05 48.05 0 0 0-48-48zm0 64a16 16 0 1 1 16-16 16 16 0 0 1-16 16zm-48 80a48 48 0 1 0 48 48 48.05 48.05 0 0 0-48-48zm0 64a16 16 0 1 1 16-16 16 16 0 0 1-16 16zm96-64a48 48 0 1 0 48-48 48.05 48.05 0 0 0-48 48zm64 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16z"
        className=""
      />
    </svg>
  );
};

export const SLIcon_5 = () => {
  return (
    <svg
      style={{ isolation: "isolate" }}
      viewBox="560.539 527.1 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="591.5393939393938"
          cy="558.1000000000001"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <path
          d=" M 576.381 549.167 L 606.064 549.167 C 606.695 549.167 607.206 548.656 607.206 548.025 L 607.206 545.742 C 607.206 545.111 606.695 544.6 606.064 544.6 L 576.381 544.6 C 575.75 544.6 575.239 545.111 575.239 545.742 L 575.239 548.025 C 575.239 548.656 575.75 549.167 576.381 549.167 Z  M 610.631 556.017 L 580.948 556.017 C 580.317 556.017 579.806 556.528 579.806 557.158 L 579.806 559.442 C 579.806 560.072 580.317 560.583 580.948 560.583 L 610.631 560.583 C 611.262 560.583 611.773 560.072 611.773 559.442 L 611.773 557.158 C 611.773 556.528 611.262 556.017 610.631 556.017 Z  M 606.064 567.433 L 576.381 567.433 C 575.75 567.433 575.239 567.944 575.239 568.575 L 575.239 570.858 C 575.239 571.489 575.75 572 576.381 572 L 606.064 572 C 606.695 572 607.206 571.489 607.206 570.858 L 607.206 568.575 C 607.206 567.944 606.695 567.433 606.064 567.433 Z "
          fill="rgb(255,255,255)"
        />
      </g>
    </svg>
  );
};

export const SLIcon_4 = () => {
  return (
    <svg
      style={{ isolation: "isolate" }}
      viewBox="561.879 433.121 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="592.8787878787876"
          cy="464.1212121212121"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <circle
          vectorEffect="non-scaling-stroke"
          cx="580.9999999999997"
          cy="472.6999999999999"
          r="8.600000000000023"
          fill="rgb(255,255,255)"
        />
        <circle
          vectorEffect="non-scaling-stroke"
          cx="597.5500000000001"
          cy="458.94999999999993"
          r="14.350000000000023"
          fill="rgb(255,255,255)"
        />
      </g>
    </svg>
  );
};

export const SLIcon_3 = () => {
  return (
    <svg
      style={{ isolation: "isolate" }}
      viewBox="561.209 332.1 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="592.2090909090907"
          cy="363.1"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <path
          d=" M 607.904 348.359 L 576.515 348.359 C 574.782 348.359 573.376 349.765 573.376 351.498 L 573.376 372.424 C 573.376 374.157 574.782 375.563 576.515 375.563 L 589.07 375.563 L 588.024 378.702 L 583.316 378.702 C 582.446 378.702 581.746 379.402 581.746 380.271 C 581.746 381.141 582.446 381.841 583.316 381.841 L 601.103 381.841 C 601.972 381.841 602.672 381.141 602.672 380.271 C 602.672 379.402 601.972 378.702 601.103 378.702 L 596.394 378.702 L 595.348 375.563 L 607.904 375.563 C 609.636 375.563 611.042 374.157 611.042 372.424 L 611.042 351.498 C 611.042 349.765 609.636 348.359 607.904 348.359 Z  M 606.857 371.378 L 577.561 371.378 L 577.561 352.544 L 606.857 352.544 L 606.857 371.378 Z "
          fill="rgb(255,255,255)"
        />
      </g>
    </svg>
  );
};

export const SLIcon_2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ isolation: "isolate" }}
      viewBox="559.87 236.2 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="590.8696969696969"
          cy="267.20000000000005"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <rect
          x="587.771"
          y="264.385"
          width="7.006"
          height="6.952"
          transform="matrix(1,0,0,1,0,0)"
          fill="rgb(255,255,255)"
        />
        <path
          d=" M 584.609 255.466 L 591.283 248.783 L 597.902 255.43 L 594.669 255.43 L 594.669 264.412 L 587.843 264.412 L 587.843 255.43 L 584.609 255.466 Z "
          fill="rgb(255,255,255)"
        />
        <path
          d=" M 603.574 261.192 L 610.257 267.865 L 603.61 274.485 L 603.61 271.251 L 594.628 271.251 L 594.628 264.425 L 603.61 264.425 L 603.574 261.192 Z "
          fill="rgb(255,255,255)"
        />
        <path
          d=" M 597.88 280.251 L 591.206 286.933 L 584.587 280.287 L 587.82 280.287 L 587.82 271.305 L 594.646 271.305 L 594.646 280.287 L 597.88 280.251 Z "
          fill="rgb(255,255,255)"
        />
        <path
          d=" M 578.883 274.503 L 572.201 267.829 L 578.847 261.21 L 578.847 264.443 L 587.829 264.443 L 587.829 271.269 L 578.847 271.269 L 578.883 274.503 Z "
          fill="rgb(255,255,255)"
        />
      </g>
    </svg>
  );
};

export const SLIcon_1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ isolation: "isolate" }}
      viewBox="559.2 142 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="590.1999999999999"
          cy="173"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <path
          d=" M 572.733 184.953 L 580.835 184.618 C 580.512 174.287 583.711 164.112 589.179 158.071 C 592.825 157.736 595.445 164.727 598.658 178.241 L 594.607 178.241 L 601.899 184.282 L 610 177.57 L 605.544 177.906 C 604.051 163.422 598.392 156.608 588.531 157.434 C 577.745 158.965 572.485 168.143 572.733 184.953 Z "
          fill="rgb(255,255,255)"
          vectorEffect="non-scaling-stroke"
          strokeWidth="1"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
      </g>
    </svg>
  );
};

export const Inv_Icon_5 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ isolation: "isolate" }}
      viewBox="806.533 492.533 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="837.5333333333334"
          cy="523.5333333333333"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <path
          d=" M 852.508 511.277 L 838.849 505.586 C 838.009 505.237 837.065 505.237 836.224 505.586 L 822.566 511.277 C 821.293 511.803 820.461 513.048 820.461 514.428 C 820.461 528.548 828.606 538.308 836.217 541.481 C 837.057 541.83 838.003 541.83 838.842 541.481 C 844.939 538.941 854.606 530.17 854.606 514.428 C 854.606 513.048 853.774 511.803 852.508 511.277 Z  M 849.15 519.4 L 836.061 532.489 C 835.62 532.93 834.894 532.93 834.453 532.489 L 827.055 525.091 C 826.614 524.65 826.614 523.925 827.055 523.484 L 828.663 521.876 C 829.104 521.435 829.829 521.435 830.27 521.876 L 835.257 526.863 L 845.935 516.185 C 846.376 515.744 847.101 515.744 847.542 516.185 L 849.15 517.793 C 849.598 518.241 849.598 518.959 849.15 519.4 L 849.15 519.4 Z "
          fill="rgb(255,255,255)"
        />
      </g>
    </svg>
  );
};

export const Inv_Icon_4 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ isolation: "isolate" }}
      viewBox="806.5 404.3 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="837.5"
          cy="435.3"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <path
          d=" M 841.727 424.949 C 838.71 427.85 834.71 431.475 831.648 434.412 C 834.648 437.412 839.585 442.85 841.543 444.808 C 838.97 447.382 835.415 448.975 831.491 448.975 C 823.644 448.975 817.273 442.604 817.273 434.756 C 817.273 426.909 823.644 420.537 831.491 420.537 C 835.524 420.537 839.167 422.22 841.727 424.949"
          fill="rgb(255,255,255)"
        />
        <line
          x1="840.4"
          y1="434.65"
          x2="843.438"
          y2="434.65"
          vectorEffect="non-scaling-stroke"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <line
          x1="846.5"
          y1="434.65"
          x2="849.538"
          y2="434.65"
          vectorEffect="non-scaling-stroke"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <line
          x1="852.6"
          y1="434.65"
          x2="855.638"
          y2="434.65"
          vectorEffect="non-scaling-stroke"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <line
          x1="858.7"
          y1="434.65"
          x2="861.738"
          y2="434.65"
          vectorEffect="non-scaling-stroke"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
      </g>
    </svg>
  );
};

export const Inv_Icon_3 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ isolation: "isolate" }}
      viewBox="806.133 316.067 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="837.1333333333332"
          cy="347.06666666666666"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <path
          d=" M 832.925 335.862 L 841.008 335.862 L 844.002 331.371 C 844.209 331.061 844.228 330.662 844.052 330.334 C 843.876 330.005 843.534 329.8 843.161 329.8 L 830.774 329.8 C 830.402 329.8 830.059 330.005 829.883 330.334 C 829.707 330.662 829.727 331.061 829.934 331.371 L 832.925 335.862 Z  M 841.008 337.883 L 832.925 337.883 C 820.145 345.171 820.806 354.848 820.806 356.071 C 820.806 359.418 823.907 362.133 827.732 362.133 L 846.203 362.133 C 850.028 362.133 853.129 359.418 853.129 356.071 C 853.129 354.871 853.72 345.131 841.008 337.883 Z "
          fill="rgb(255,255,255)"
        />
      </g>
    </svg>
  );
};

export const Inv_Icon_2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ isolation: "isolate" }}
      viewBox="806.5 227.833 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="837.5000000000001"
          cy="258.8333333333333"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <circle
          vectorEffect="non-scaling-stroke"
          cx="823.8066115702479"
          cy="267.7267217630853"
          r="6.0066115702478555"
          fill="rgb(255,255,255)"
        />
        <circle
          vectorEffect="non-scaling-stroke"
          cx="851.2280991735538"
          cy="267.7267217630853"
          r="6.0066115702478555"
          fill="rgb(255,255,255)"
        />
        <circle
          vectorEffect="non-scaling-stroke"
          cx="837.517355371901"
          cy="248.13994490358124"
          r="6.0066115702478555"
          fill="rgb(255,255,255)"
        />
        <line
          x1="818.218"
          y1="258.325"
          x2="857"
          y2="258.325"
          vectorEffect="non-scaling-stroke"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <line
          x1="837.517"
          y1="252.651"
          x2="837.517"
          y2="258.136"
          vectorEffect="non-scaling-stroke"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <line
          x1="851.228"
          y1="258.811"
          x2="851.228"
          y2="264.296"
          vectorEffect="non-scaling-stroke"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <line
          x1="823.807"
          y1="258.714"
          x2="823.807"
          y2="264.198"
          vectorEffect="non-scaling-stroke"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
      </g>
    </svg>
  );
};

export const Inv_Icon_1 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ isolation: "isolate" }}
      viewBox="806.8 139.6 62 62"
      width="62px"
      height="62px"
    >
      <g>
        <circle
          vectorEffect="non-scaling-stroke"
          cx="837.8"
          cy="170.59999999999997"
          r="30"
          fill="none"
          strokeWidth="2"
          stroke="rgb(255,255,255)"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="3"
        />
        <path
          d=" M 855.6 164.225 C 855.6 170.756 850.306 176.05 843.775 176.05 C 843.021 176.05 842.284 175.979 841.569 175.844 L 839.956 177.659 C 839.65 178.003 839.212 178.2 838.751 178.2 L 836.25 178.2 L 836.25 180.888 C 836.25 181.778 835.528 182.5 834.637 182.5 L 831.95 182.5 L 831.95 185.188 C 831.95 186.078 831.228 186.8 830.337 186.8 L 822.812 186.8 C 821.922 186.8 821.2 186.078 821.2 185.188 L 821.2 179.943 C 821.2 179.515 821.37 179.105 821.672 178.803 L 832.543 167.932 C 832.159 166.766 831.95 165.52 831.95 164.225 C 831.95 157.694 837.244 152.4 843.775 152.4 C 850.325 152.4 855.6 157.675 855.6 164.225 Z  M 843.775 161 C 843.775 162.781 845.219 164.225 847 164.225 C 848.781 164.225 850.225 162.781 850.225 161 C 850.225 159.219 848.781 157.775 847 157.775 C 845.219 157.775 843.775 159.219 843.775 161 Z "
          fill="rgb(255,255,255)"
        />
      </g>
    </svg>
  );
};

export const LevaCircleLogo = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#15B982" />
      <path
        d="M4.95239 11.8095H8.37898L13.4254 21.4658L18.5964 11.8095H22.0853L13.4254 28.3809L4.95239 11.8095ZM34.8572 28.3809H31.4306L26.3841 18.7247L21.2131 28.3809H17.7242L26.3841 11.8095L34.8572 28.3809Z"
        fill="white"
      />
    </svg>
  );
};

export const Pdf = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00095 0H26.2421L37.5009 11.215V37.5C37.5009 38.8812 36.381 40 35.0009 40H5.00095C3.62091 40 2.50098 38.8812 2.50098 37.5V2.49998C2.50098 1.11877 3.62104 0 5.00095 0Z"
      fill="#E2574C"
    />
    <path
      d="M37.4642 11.25H28.7505C27.3704 11.25 26.2505 10.1301 26.2505 8.75004V0.0250244L37.4642 11.25Z"
      fill="#B53629"
    />
    <path
      d="M28.1227 18.9538C28.5415 18.9538 28.7465 18.5888 28.7465 18.235C28.7465 17.8688 28.5327 17.515 28.1227 17.515H25.7377C25.2714 17.515 25.0115 17.9012 25.0115 18.3275V24.1887C25.0115 24.7113 25.3089 25.0012 25.7114 25.0012C26.1115 25.0012 26.4103 24.7113 26.4103 24.1887V22.58H27.8528C28.3003 22.58 28.524 22.2137 28.524 21.85C28.524 21.4938 28.3003 21.14 27.8528 21.14H26.4103V18.9538H28.1227ZM20.0614 17.515H18.3164C17.8426 17.515 17.5064 17.84 17.5064 18.3225V24.1938C17.5064 24.7925 17.9901 24.98 18.3363 24.98H20.1676C22.3351 24.98 23.7663 23.5538 23.7663 21.3525C23.7652 19.025 22.4177 17.515 20.0614 17.515ZM20.1452 23.5326H19.0814V18.9626H20.0402C21.4915 18.9626 22.1227 19.9364 22.1227 21.28C22.1227 22.5376 21.5027 23.5326 20.1452 23.5326ZM13.7527 17.515H12.024C11.5352 17.515 11.2627 17.8375 11.2627 18.3275V24.1887C11.2627 24.7113 11.5752 25.0012 11.9951 25.0012C12.4151 25.0012 12.7276 24.7113 12.7276 24.1887V22.4775H13.8113C15.1488 22.4775 16.2526 21.5299 16.2526 20.0062C16.2527 18.5151 15.1877 17.515 13.7527 17.515ZM13.724 21.1026H12.7277V18.8913H13.724C14.339 18.8913 14.7302 19.3713 14.7302 19.9975C14.729 20.6226 14.339 21.1026 13.724 21.1026Z"
      fill="white"
    />
  </svg>
);

export const dmGuideline = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62.0928 62.082C61.9086 62.0784 61.725 62.0992 61.5527 62.1433C61.3804 62.1874 61.2228 62.2539 61.0887 62.3391C60.9547 62.4242 60.8469 62.5262 60.7716 62.6394C60.6962 62.7525 60.6548 62.8745 60.6497 62.9985C60.6171 63.7615 59.7484 64.3929 59.1013 64.6766C59.1006 64.665 59.0996 64.654 59.0989 64.6432C59.0516 64.0214 58.9865 63.1697 58.0111 62.6632C56.2678 61.7582 54.2235 62.9173 52.2478 64.0396C51.6113 64.4161 50.9428 64.7672 50.2456 65.0914C50.2901 64.6226 50.3697 64.1555 50.4842 63.6921C50.8121 62.1998 51.1514 60.6567 49.8645 59.6992C47.7743 58.1444 43.3986 59.2595 35.2345 63.428C34.938 63.5794 34.7431 63.8038 34.6926 64.0518C34.6422 64.2998 34.7403 64.5512 34.9655 64.7506C35.1906 64.9499 35.5243 65.081 35.8932 65.1149C36.262 65.1488 36.6358 65.0828 36.9323 64.9315C46.1153 60.2428 47.7688 60.9556 47.7819 60.9653C48.1804 61.2618 47.8723 62.6632 47.7068 63.416C47.3898 64.8572 47.0906 66.2185 48.5153 66.8801C48.8767 67.0533 49.3096 67.1462 49.7535 67.1458C51.1295 67.1458 52.6134 66.3031 54.0593 65.4817C54.7493 65.0682 55.4835 64.6892 56.2558 64.3477C56.2753 64.4802 56.2866 64.6295 56.2952 64.7399C56.3414 65.3451 56.4045 66.1742 57.4799 66.5542C58.297 66.8434 59.3463 66.7667 60.4331 66.3385C61.8927 65.7645 63.3934 64.5258 63.4558 63.0524C63.4611 62.9285 63.43 62.8051 63.3644 62.6893C63.2988 62.5734 63.1998 62.4674 63.0733 62.3773C62.9467 62.2872 62.795 62.2148 62.6267 62.1641C62.4585 62.1134 62.2771 62.0855 62.0928 62.082Z"
      fill="black"
    />
    <path
      d="M59.4423 14.0195H17.2955C16.2309 14.0195 15.2099 14.175 14.4572 14.4518C13.7044 14.7285 13.2815 15.1039 13.2815 15.4953C13.2815 15.8866 13.7044 16.262 14.4572 16.5388C15.2099 16.8155 16.2309 16.971 17.2955 16.971H59.4423C60.5069 16.971 61.5278 16.8155 62.2806 16.5388C63.0334 16.262 63.4563 15.8866 63.4563 15.4953C63.4563 15.1039 63.0334 14.7285 62.2806 14.4518C61.5278 14.175 60.5069 14.0195 59.4423 14.0195Z"
      fill="black"
    />
    <path
      d="M59.4423 28.7769H17.2955C16.2309 28.7769 15.2099 28.9323 14.4572 29.2091C13.7044 29.4858 13.2815 29.8612 13.2815 30.2526C13.2815 30.644 13.7044 31.0193 14.4572 31.2961C15.2099 31.5728 16.2309 31.7283 17.2955 31.7283H59.4423C60.5069 31.7283 61.5278 31.5728 62.2806 31.2961C63.0334 31.0193 63.4563 30.644 63.4563 30.2526C63.4563 29.8612 63.0334 29.4858 62.2806 29.2091C61.5278 28.9323 60.5069 28.7769 59.4423 28.7769Z"
      fill="black"
    />
    <path
      d="M59.4423 36.1553H17.2955C16.2309 36.1553 15.2099 36.3108 14.4572 36.5875C13.7044 36.8643 13.2815 37.2396 13.2815 37.631C13.2815 38.0224 13.7044 38.3977 14.4572 38.6745C15.2099 38.9513 16.2309 39.1067 17.2955 39.1067H59.4423C60.5069 39.1067 61.5278 38.9513 62.2806 38.6745C63.0334 38.3977 63.4563 38.0224 63.4563 37.631C63.4563 37.2396 63.0334 36.8643 62.2806 36.5875C61.5278 36.3108 60.5069 36.1553 59.4423 36.1553Z"
      fill="black"
    />
    <path
      d="M59.4423 21.3979H17.2955C16.2309 21.3979 15.2099 21.5534 14.4572 21.8302C13.7044 22.1069 13.2815 22.4823 13.2815 22.8737C13.2815 23.2651 13.7044 23.6404 14.4572 23.9172C15.2099 24.1939 16.2309 24.3494 17.2955 24.3494H59.4423C60.5069 24.3494 61.5278 24.1939 62.2806 23.9172C63.0334 23.6404 63.4563 23.2651 63.4563 22.8737C63.4563 22.4823 63.0334 22.1069 62.2806 21.8302C61.5278 21.5534 60.5069 21.3979 59.4423 21.3979Z"
      fill="black"
    />
    <path
      d="M40.431 43.5342H15.6388C15.0132 43.5348 14.4137 43.6906 13.9717 43.9673C13.5297 44.244 13.2815 44.6189 13.2815 45.0099C13.2815 45.4009 13.5297 45.7759 13.9717 46.0525C14.4137 46.3292 15.0132 46.485 15.6388 46.4856H40.431C40.7414 46.486 41.0489 46.448 41.3358 46.374C41.6227 46.3 41.8834 46.1913 42.1031 46.0543C42.3228 45.9172 42.4971 45.7544 42.616 45.5752C42.7349 45.396 42.7961 45.2039 42.7961 45.0099C42.7961 44.8159 42.7349 44.6238 42.616 44.4446C42.4971 44.2654 42.3228 44.1026 42.1031 43.9656C41.8834 43.8285 41.6227 43.7198 41.3358 43.6458C41.0489 43.5718 40.7414 43.5339 40.431 43.5342Z"
      fill="black"
    />
    <path
      d="M40.431 50.9126H15.6388C15.0132 50.9132 14.4137 51.069 13.9717 51.3457C13.5297 51.6224 13.2815 51.9974 13.2815 52.3883C13.2815 52.7793 13.5297 53.1543 13.9717 53.431C14.4137 53.7076 15.0132 53.8634 15.6388 53.8641H40.431C40.7414 53.8644 41.0489 53.8264 41.3358 53.7524C41.6227 53.6784 41.8834 53.5697 42.1031 53.4327C42.3228 53.2956 42.4971 53.1328 42.616 52.9536C42.7349 52.7744 42.7961 52.5823 42.7961 52.3883C42.7961 52.1943 42.7349 52.0022 42.616 51.823C42.4971 51.6438 42.3228 51.481 42.1031 51.344C41.8834 51.2069 41.6227 51.0983 41.3358 51.0242C41.0489 50.9502 40.7414 50.9123 40.431 50.9126Z"
      fill="black"
    />
    <rect x="0.5" y="0.5" width="75" height="75" rx="5.5" stroke="black" />
  </svg>
);

export const { ...icons } = menuIcons;
