const HR = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9.24684 10.854C10.8275 10.854 12.2765 11.3809 13.4883 12.382L14.5158 11.1701C13.8572 10.6169 13.1459 10.1954 12.3819 9.87924C13.7782 8.90446 14.7002 7.27114 14.7002 5.42698C14.7002 2.4237 12.2764 0 9.27316 0C6.26988 0 3.81981 2.4237 3.81981 5.42698C3.81981 7.27109 4.74189 8.90446 6.13817 9.87924C3.10851 11.1174 0.974609 14.068 0.974609 17.5456V21.4709C0.974609 21.9188 1.3171 22.2612 1.76493 22.2612H14.3577V20.6806H2.55529V17.5456C2.55529 13.8573 5.55858 10.854 9.24684 10.854ZM5.40049 5.42698C5.40049 3.29308 7.11289 1.58063 9.24684 1.58063C11.3808 1.58063 13.0932 3.29303 13.0932 5.42698C13.0932 7.56093 11.3808 9.27332 9.24684 9.27332C7.11289 9.27332 5.40049 7.56093 5.40049 5.42698Z"
          fill="#18DC9B"
        />
        <path
          d="M17.1768 24C17.0714 24 16.966 23.9737 16.8606 23.921L16.202 23.6312C13.3832 22.393 11.5391 19.6005 11.5391 16.5445V12.7508C11.5391 12.461 11.6971 12.1976 11.9606 12.0659L16.8343 9.37869C17.0714 9.24698 17.3612 9.24698 17.5983 9.37869L22.6038 12.0659C22.8672 12.1976 23.0253 12.461 23.0253 12.7508V16.4918C23.0253 19.6268 21.1548 22.4193 18.2832 23.6312L17.5192 23.9473C17.3875 23.9737 17.2822 24 17.1768 24ZM13.1197 13.225V16.5445C13.1197 18.9946 14.5687 21.2075 16.8343 22.2086L17.1768 22.3667L17.6246 22.1823C19.9166 21.2339 21.4183 18.9946 21.4183 16.5182V13.2251L17.2031 10.9594L13.1197 13.225Z"
          fill="#597EF7"
        />
        <path
          d="M16.4919 18.8892C16.2548 18.8892 16.044 18.7838 15.886 18.5994L14.5688 17.045C14.279 16.7025 14.3317 16.202 14.6478 15.9385C14.9639 15.6751 15.4908 15.7014 15.7543 16.0176L16.571 16.966L19.0211 14.9638C19.3636 14.7003 19.8641 14.7267 20.1275 15.0692C20.391 15.4116 20.3646 15.9122 20.0222 16.1756L16.9925 18.7047C16.8344 18.8365 16.6763 18.8892 16.4919 18.8892Z"
          fill="#597EF7"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HR;
