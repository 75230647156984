import { useState, useEffect } from "react";
import { convertToBase64 } from "#helpers/convertBase64";
import Modal from "../../Modal";
import { BasicForm } from "../../Form";
import confirmTransferFields from "./list";
import confirmTransferSchema from "#validators/confirmTransferSchema";
import { pickBy, identity } from "lodash";
import { Button } from "../../Button";
import { MAJOR_COUNTRIES } from "../../Settings/countries";

const ConfirmTransferModal = ({
  ioId,
  handleClose,
  visible,
  confirmIoTransfer,
  buttonStates,
  confirmTransferTimestamp,
  readCurrencies,
  currencies = [],
  entityName,
  entityAddress,
  currency,
  roundedMoneyInvested,
  ioName,
  ...props
}) => {
  const [base64Docs, setBase64Docs] = useState({});
  const [modalWidth, setModalWidth] = useState(1000);

  useEffect(() => {
    readCurrencies();
  }, []);

  useEffect(() => {
    if (confirmTransferTimestamp) {
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
  }, [confirmTransferTimestamp]);

  useEffect(() => {
    if (roundedMoneyInvested === 0) setModalWidth(500);
    else setModalWidth(1000);
  }, [roundedMoneyInvested]);

  const customHook = ({ setValues }) => {
    useEffect(() => {
      setValues({
        accountHolderName: entityName,
        accountHolderAddress: entityAddress,
        targetCompanyName: entityName,
        targetCompanyAddress: entityAddress,
        bankLocation: MAJOR_COUNTRIES.EU_SWISS,
        amount: roundedMoneyInvested
      });
    }, []);
  };

  const handleSubmit = async values => {
    const data = {
      ...pickBy(values, identity),
      confirmTransferDocs: base64Docs.confirmTransferDocs,
      currency,
      ioName
    };

    confirmIoTransfer({ ioId, data });
  };

  const CustomFooter = ({ onCancel, onClick }) => {
    return (
      <div className="confirm-transfer-custom-footer">
        <div className="form-btn-container">
          <Button
            label="Cancel"
            onClick={onCancel}
            size="large"
            type="secondary"
            className="cancel-btn"
          />

          <Button
            label={"Submit"}
            size="large"
            onClick={onClick}
            loading={buttonStates.loading}
            success={buttonStates.success}
            className="submit-btn"
          />
        </div>
      </div>
    );
  };

  const handleUpload = setFieldValue => async (name, info = {}, opts = {}) => {
    const { fileList } = info;

    const base64Array = await Promise.all(
      fileList.map(async item => {
        const base64Data = await convertToBase64(item.originFileObj);
        return { name: item.name, base64: base64Data };
      })
    );

    const newState = {
      [name]: base64Array
    };

    setBase64Docs({ ...base64Docs, ...newState });
    setFieldValue(name, true);
  };

  const formArgs = {
    fieldList: confirmTransferFields(currency),
    handleCancel: handleClose,
    handleSubmit,
    handleUpload,
    validationSchema: confirmTransferSchema({ roundedMoneyInvested, currency }),
    buttonStates,
    useCustomHook: customHook,
    CustomFooter,
    onEnter: false
  };

  return (
    <Modal
      title="Confirm transfer"
      visible={visible}
      footer={null}
      onCancel={handleClose}
      width={modalWidth}
      className="confirm-transfer-modal"
      {...props}
    >
      <BasicForm {...formArgs} />
    </Modal>
  );
};

export default ConfirmTransferModal;
