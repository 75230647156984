import { Row, Col, Typography } from "antd";
import Tick from "../../../../assets/illustrations/tick.svg";
import WengerVeli from "../../../../assets/illustrations/legal/wenger&veli.png";
import Check from "../../../../assets/illustrations/check.svg";
import Buttons from "./FeatureButtons/Presentational";
import useWindowSize from "#helpers/hooks/windowSize";

const { Title } = Typography;

const FeatureItems = ({ items }) => (
  <div className="feature-items">
    {items.map((item, index) => (
      <div key={index}>
        <div className="feature-item">
          <img className="feature-item-img" src={Tick} alt="tick" />
          <p className="feature-item-content">{item.content}</p>
        </div>
        {item.insideItems &&
          item.insideItems.map((insideItem, idx) => (
            <div className="feature-inside-item" key={idx}>
              <img
                style={{ margin: "6px 10px 0px 0px" }}
                width="12px"
                height="12px"
                src={Check}
                alt="check"
              />
              <p className="feature-inside-item-content">
                {insideItem.itemContent}
              </p>
            </div>
          ))}
      </div>
    ))}
  </div>
);

const FeatureContent = ({
  header,
  text,
  titleClassName,
  textClassName,
  items,
  showButtons,
  buttons,
  showWV
}) => (
  <>
    <Title level={2} className={titleClassName || "feature-l2-title"}>
      {header}
    </Title>
    <p className={textClassName || "feature-text"}>{text}</p>
    {showButtons && <Buttons buttons={buttons} />}
    {!!items && <FeatureItems items={items} />}
    {showWV && (
      <div
        style={{
          margin: "8% 0",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          flexWrap: "wrap"
        }}
      >
        <Title
          className="l4-title"
          style={{ fontWeight: "normal", color: "#42526e" }}
        >
          In cooperation with
        </Title>
        <img src={WengerVeli} alt="wenger&vieli" width="200" height="45" />
      </div>
    )}
  </>
);

const FeatureCol = ({ link, header, icon, rightContent, className }) => (
  <Col
    xs={24}
    sm={24}
    md={12}
    lg={12}
    xl={12}
    className={`feature-col ${className || ""}`}
  >
    {link ? (
      <a href={link} alt={header} target="_blank">
        <img className="feature-col-img" src={icon} alt="icon" />
      </a>
    ) : icon ? (
      <img className="feature-col-img" src={icon} alt="icon" />
    ) : (
      rightContent
    )}
  </Col>
);

export default function Presentational(props) {
  const { width } = useWindowSize();

  return (
    <div className="feature-col-wrapper">
      <Row className="feature-row">
        {props.textLeft ? (
          <>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="feature-col first-col"
            >
              <FeatureContent {...props} />
            </Col>
            <FeatureCol
              className={`${width > 768 ? "img-absolut" : ""}`}
              {...props}
            />
          </>
        ) : (
          <>
            <FeatureCol {...props} className="first-col" />
            <Col
              xs={24}
              sm={24}
              md={10}
              lg={10}
              xl={10}
              className="feature-col"
            >
              <FeatureContent {...props} />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

//import { Row, Col, Typography, Button } from "antd";
//import Tick from "../../../../assets/illustrations/tick.svg";
//import WengerVeli from "../../../../assets/illustrations/legal/wenger&veli.png";
//import Check from "../../../../assets/illustrations/check.svg";
//import Buttons from "./FeatureButtons/Presentational";

//const { Title } = Typography;

//export default function Presentational(props) {
//  return (
//    <div className="feature-col-wrapper">
//      {props.textLeft ? (
//        <Row className="feature-row">
//          <Col
//            xs={24}
//            sm={24}
//            md={10}
//            lg={10}
//            xl={10}
//            className="feature-col first-col"
//          >
//            <Title level={2} className={props.className || "feature-l2-title"}>
//              {props.header}
//            </Title>
//            <p
//              className={
//                props.textClassName ? props.textClassName : "feature-text"
//              }
//            >
//              {props.text}
//            </p>
//            {props.showButtons && <Buttons buttons={props.buttons} />}
//            {!!props.items && (
//              <div className="feature-items">
//                {props.items &&
//                  props.items.map(item => (
//                    <div>
//                      <div className="feature-item">
//                        <img
//                          className="feature-item-img"
//                          src={Tick}
//                          alt="tick"
//                        />
//                        <p className="feature-item-content">{item.content}</p>
//                      </div>
//                      {item.insideItems &&
//                        item.insideItems.map(insideItem => (
//                          <div className="feature-inside-item">
//                            <img
//                              // className="feature-inside-item-img"
//                              style={{ margin: "6px 10px 0px 0px" }}
//                              width="12px"
//                              height="12px"
//                              src={Check}
//                              alt="check"
//                            />
//                            <p className="feature-inside-item-content">
//                              {insideItem.itemContent}
//                            </p>
//                          </div>
//                        ))}
//                    </div>
//                  ))}
//              </div>
//            )}
//            {props.showWV && (
//              <div
//                style={{
//                  margin: "8% 0",
//                  display: "flex",
//                  flexDirection: "row",
//                  justifyContent: "space-evenly",
//                  flexWrap: "wrap"
//                }}
//              >
//                <Title
//                  className="l4-title"
//                  style={{ fontWeight: "normal", color: "#42526e" }}
//                >
//                  In cooperation with
//                </Title>
//                <img
//                  src={WengerVeli}
//                  alt="wenger&vieli"
//                  width="200"
//                  height="45"
//                />
//              </div>
//            )}
//          </Col>
//          <Col xs={24} sm={24} md={10} lg={10} xl={10} className="feature-col">
//            {props.link ? (
//              <a href={props.link} alt={props.header} target="_blank">
//                <img className="feature-col-img" src={props.icon} alt="icon" />
//              </a>
//            ) : props.icon ? (
//              <img className="feature-col-img" src={props.icon} alt="icon" />
//            ) : (
//              props.rightContent
//            )}
//          </Col>
//        </Row>
//      ) : (
//        <Row className="feature-row">
//          <Col xs={24} sm={24} md={10} lg={10} xl={10} className="feature-col">
//            {props.link ? (
//              <a href={props.link} alt={props.header} target="_blank">
//                <img className="feature-col-img" src={props.icon} alt="icon" />
//              </a>
//            ) : (
//              <img className="feature-col-img" src={props.icon} alt="icon" />
//            )}
//          </Col>
//          <Col xs={24} sm={24} md={10} lg={10} xl={10} className="feature-col">
//            <Title level={2} className="feature-l2-title">
//              {props.header}
//            </Title>
//            <p
//              className={
//                props.textClassName ? props.textClassName : "feature-text"
//              }
//            >
//              {props.text}
//            </p>
//            {!!props.items && (
//              <div className="feature-items">
//                {props.items &&
//                  props.items.map(item => (
//                    <div>
//                      <div className="feature-item">
//                        <img
//                          className="feature-item-img"
//                          src={Tick}
//                          alt="tick"
//                        />
//                        <p className="feature-item-content">{item.content}</p>
//                      </div>
//                      {item.insideItems &&
//                        item.insideItems.map(insideItem => (
//                          <div className="feature-inside-item">
//                            <img
//                              // className="feature-inside-item-img"
//                              style={{ margin: "6px 10px 0px 0px" }}
//                              width="12px"
//                              height="12px"
//                              src={Check}
//                              alt="check"
//                            />
//                            <p className="feature-inside-item-content">
//                              {insideItem.itemContent}
//                            </p>
//                          </div>
//                        ))}
//                    </div>
//                  ))}
//              </div>
//            )}
//            {props.showWV && (
//              <div
//                style={{
//                  margin: "8% 0",
//                  display: "flex",
//                  flexDirection: "row",
//                  justifyContent: "space-evenly",
//                  flexWrap: "wrap"
//                }}
//              >
//                <Title
//                  className="l4-title"
//                  style={{ fontWeight: "normal", color: "#42526e" }}
//                >
//                  In cooperation with
//                </Title>
//                <img
//                  src={WengerVeli}
//                  alt="wenger&vieli"
//                  width="200"
//                  height="45"
//                />
//              </div>
//            )}
//          </Col>
//        </Row>
//      )}
//    </div>
//  );
//}
