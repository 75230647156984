const formFields = [
  {
    id: 1,
    title: 'Email address',
    details: "Enter founder's email address",
    className: '_fullwidth',
    input: {
      type: 'text',
      name: 'inviteeEmail'
    }
  },
  {
    id: 2,
    title: 'First name',
    details: "Enter founder's first name",
    className: '_fullwidth',
    input: {
      type: 'text',
      name: 'inviteeFirstName'
    }
  },
  {
    id: 3,
    title: 'Last name',
    details: "Enter founder's last name",
    className: '_fullwidth',
    input: {
      type: 'text',
      name: 'inviteeLastName'
    }
  }
];

export const list = [
  {
    id: 1,
    sectionId: 1,
    items: formFields
  }
];

export const formSections = [
  {
    id: 1,
    title: 'Founder invitation',
    stepLabel: 'Please pick one of the following options'
  }
];
