import { useEffect, useState } from 'react';
import { formatNumber } from '#helpers/number_format';
import { mapDetailsToEntity } from '#helpers/mappers/entitySettings';
import { zefixHandlers } from '#helpers/zefix';
import { CustomBasicForm } from '../BasicForm/Wrapper';
import { companyFields } from './components/list';
import { AvatarUploader } from '../../Avatar';

const EntityFormPresentational = ({
  buttonStates,
  handleSubmit,
  handleCancel,
  values: { previewImage, ...values } = {},
  handleUpload,
  previewImageUpdated,
  type,
  argsToBePassed,
  companyDetails,
  detailsFetching,
  detailsFetched,
  clearStateDataByKey,
  ...props
}) => {
  const [selected, setSelected] = useState(false);

  const fields = companyFields();

  const customHook = ({ setValues, values }) => {
    useEffect(() => {
      // if (detailsFetched && selected) {
      if (detailsFetched) {
        setValues({ ...values, ...mapDetailsToEntity(companyDetails) });
      }
    }, [detailsFetching, selected]);
  };

  const args = {
    ...argsToBePassed,
    zefixHandlers: zefixHandlers({
      onSelectActions: () => {
        setSelected(true);
      }
    }),
    loading: detailsFetching
  };

  const BasicForm = CustomBasicForm({ enableReinitialize: true, values });

  return (
    <BasicForm
      fieldList={fields}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      buttonStates={buttonStates}
      extraContent={
        <AvatarUploader
          onUpload={handleUpload}
          profilePic={
            previewImageUpdated
              ? URL.createObjectURL(previewImageUpdated)
              : previewImage
          }
        />
      }
      {...props}
      argsToBePassed={args}
      useCustomHook={customHook}
    />
  );
};

export default EntityFormPresentational;
