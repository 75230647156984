import { useEffect } from 'react';

export const updateFormValues = ({ deps = [], values }) => ({
  setValues,
  values: prevValues
}) => {
  useEffect(() => {
    const [didFetch] = deps;

    if (didFetch) {
      setValues({ ...prevValues, ...values });
    }
  }, deps);
};
