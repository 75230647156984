const Document = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3124 24C20.1009 24 19.859 23.9093 19.7079 23.7582L17.592 21.7934L15.3855 23.7582C15.053 24.0604 14.5089 24.0604 14.1764 23.7582L12.0001 21.7934L9.8238 23.7582C9.4913 24.0604 8.94723 24.0604 8.61473 23.7582L6.40816 21.7934L4.29232 23.7582C4.02028 24 3.65758 24.0604 3.32508 23.9093C2.99258 23.7582 2.78101 23.4257 2.78101 23.0932V1.51131C2.78089 0.664995 3.44589 0 4.29226 0H19.7079C20.5543 0 21.2192 0.664995 21.2192 1.51131V23.0932C21.2192 23.4559 21.0076 23.7884 20.6751 23.9093C20.5542 23.9698 20.4334 24 20.3124 24ZM12 19.6776C12.2116 19.6776 12.4232 19.7682 12.6046 19.9194L14.7809 21.8842L16.9875 19.9194C17.3502 19.6172 17.864 19.6172 18.1965 19.9194L19.4056 21.0378V1.81361H4.5945V21.0378L5.80357 19.9194C6.13607 19.6172 6.68014 19.5869 7.01264 19.9194L9.21921 21.8842L11.3955 19.9194C11.5769 19.7683 11.7885 19.6776 12 19.6776Z"
        fill="#597EF7"
      />
      <path
        d="M15.748 10.9723H8.25175C7.73787 10.9723 7.34497 10.5793 7.34497 10.0655C7.34497 9.55171 7.73793 9.15875 8.25175 9.15875H15.748C16.2618 9.15875 16.6547 9.55171 16.6547 10.0655C16.6547 10.5793 16.2618 10.9723 15.748 10.9723Z"
        fill="#18DC9B"
      />
      <path
        d="M8.37284 7.31493C7.85896 7.31493 7.46606 6.92197 7.46606 6.40815V4.3527C7.46606 3.83882 7.85902 3.44592 8.37284 3.44592C8.88666 3.44592 9.27961 3.83888 9.27961 4.3527V6.40815C9.27967 6.92197 8.88672 7.31493 8.37284 7.31493Z"
        fill="#18DC9B"
      />
      <path
        d="M12 7.31493C11.4862 7.31493 11.0933 6.92197 11.0933 6.40815V4.3527C11.0933 3.83882 11.4862 3.44592 12 3.44592C12.5139 3.44592 12.9068 3.83888 12.9068 4.3527V6.40815C12.9069 6.92197 12.5139 7.31493 12 7.31493Z"
        fill="#18DC9B"
      />
      <path
        d="M15.6272 7.31493C15.1134 7.31493 14.7205 6.92197 14.7205 6.40815V4.3527C14.7205 3.83882 15.1134 3.44592 15.6272 3.44592C16.1411 3.44592 16.534 3.83888 16.534 4.3527V6.40815C16.5341 6.92197 16.1411 7.31493 15.6272 7.31493Z"
        fill="#18DC9B"
      />
      <path
        d="M15.748 14.2973H8.25175C7.73787 14.2973 7.34497 13.9043 7.34497 13.3905C7.34497 12.8767 7.73793 12.4837 8.25175 12.4837H15.748C16.2618 12.4837 16.6547 12.8767 16.6547 13.3905C16.6547 13.9043 16.2618 14.2973 15.748 14.2973Z"
        fill="#18DC9B"
      />
      <path
        d="M15.748 17.6223H8.25175C7.73787 17.6223 7.34497 17.2293 7.34497 16.7155C7.34497 16.2017 7.73793 15.8087 8.25175 15.8087H15.748C16.2618 15.8087 16.6547 16.2017 16.6547 16.7155C16.6547 17.2293 16.2618 17.6223 15.748 17.6223Z"
        fill="#18DC9B"
      />
    </svg>
  );
};

export default Document;
