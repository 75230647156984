import _ from 'lodash';

/* Update an object in state after field changes */

export const updateObjectInArray = (data, cuid, newObject) => {
  let objectExists = false;
  const newArray = data.map((item) => {
    if (item.cuid === cuid) {
      objectExists = true;
      return { ...item, ...newObject };
    }
    return item;
  });

  if (!objectExists) {
    return [...data, newObject];
  }

  return newArray;
};

/* Delete an object from state array */

export const deleteObjectFromArray = (data, cuid) => {
  const tmp = [];
  for (let i = 0; i < data.length; i += 1) {
    if (data[i].cuid !== cuid) {
      tmp.push(data[i]);
    }
  }

  return tmp;
};


/* Update an object */

export const updateObject = (oldObject, newObject) => {
  const filteredNewObject = _.omitBy(newObject, _.isNil);
  return { ...oldObject, ...filteredNewObject };
};
