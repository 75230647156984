import { Fragment, useState, useEffect } from "react";
import Icon from "antd/es/icon";
import { Popconfirm } from "antd";
import TooltipWrapper from "../Tooltip";
import InputRenderer from "../InputRenderer";
import { ActionDropdown } from "../Dropdown";
import { Button } from "../Button";

const EditableContent = ({
  Component,
  children,
  edit,
  initVal,
  name,
  onOk,
  onCancel,
  success,
  cleanup,
  inputConfig,
  componentProps,
  loading,
  canEdit,
  ...props
}) => {
  const initState = {
    value: initVal,
    editable: false
  };

  const [state, setState] = useState(initState);

  useEffect(() => {
    setState({ value: initVal });
  }, [initVal]);

  useEffect(() => {
    if (success) {
      setState(initState);
      if (typeof cleanup === "function") {
        cleanup();
      }
    }
  }, [success]);

  const handleKeydown = e => {
    if (e.key === "Escape") {
      resetEditable();
    }

    if (e.keyCode === 13 && !e.shiftKey) {
      onOk(name, value);
    }
  };

  const handleStateChange = (key, value) => () =>
    setState({ ...state, [key]: value });

  const handleChange = ({ target: { value } }) => {
    setState({ ...state, value });
  };

  const handleOk = () => onOk(name, value);

  const resetEditable = () =>
    setState({ ...state, editable: false, value: initVal });

  const { editable, value } = state;
  const settings = (
    <Popconfirm
      title="Do you want to save changes?"
      okText="Yes"
      cancelText="No"
      onConfirm={handleOk}
      onCancel={resetEditable}
      placement="right"
    >
      <Icon type="ellipsis" />
    </Popconfirm>
  );

  const helper = inputConfig.type === "textarea";
  const helperText = "Shift + Enter to enter a new line.";

  return (
    <Fragment>
      {editable && canEdit ? (
        <Fragment>
          <div className="edit-header">
            <Button label="Save changes" onClick={handleOk} loading={loading} />
          </div>
          <InputRenderer
            name={name}
            autofocus
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeydown}
            suffix={settings}
            {...inputConfig}
          />
          {helper && (
            <div className="helper">
              <span>{helperText}</span>
              {settings}
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Component {...componentProps} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditableContent;
