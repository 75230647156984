import { handleColor } from '#helpers/general';
import Checkbox from '../../../../Shared/Checkbox';
import TagWrapper from '../../../../Shared/Tag';

const generalInformationsCols = ({ checkboxInArray }) => [
  {
    title: 'Leaving Member',
    dataIndex: 'leavingMember',
    key: 'leavingMember',
    render: (value, { cuid, poa, new: newBoardMember }) => {
      return (
        <Checkbox
          checked={value}
          name="leavingMember"
          onChange={checkboxInArray('signingDirector', cuid)}
        />
      );
    }
  },
  {
    title: 'First and last name',
    dataIndex: 'firstName',
    key: 'firstName',
    render: (_, { firstName, lastName }) => `${firstName} ${lastName}`
  },
  {
    title: 'President of the board',
    dataIndex: 'presidentOfTheBoard',
    key: 'presidentOfTheBoard',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: 'New',
    dataIndex: 'new',
    key: 'new',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: 'PoA',
    dataIndex: 'poa',
    key: 'poa',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: 'Representing',
    dataIndex: 'representativeOf',
    key: 'representativeOf',
    render: (text) => (
      <TagWrapper color={handleColor(text)}>
        {text}
      </TagWrapper>
    )
  },
  {
    title: 'Signature',
    dataIndex: 'signingAuthority',
    key: 'signingAuthority',
    render: (text) => (
      <TagWrapper color={handleColor(text)}>
        {text}
      </TagWrapper>
    )
  },
];

export { generalInformationsCols };
