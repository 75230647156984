import { Icon } from "antd";
import { inputNumberFormatter } from "#helpers/general";
import { investmentOpportunityTypes } from "#constants/deal";
import { formatNumber } from "#helpers/number_format";
import { Button } from "../../../../../../../../Shared/Button";

export const formList = ({
  step,
  currency,
  handleShowMultiSyn,
  hasAllocationError,
  ioType,
  overSubscribedDeal
}) => ({ values = {} }) => {
  if (overSubscribedDeal) return overSubscribedDealFields({ currency });
  if (step === 0)
    return firstStep({
      values,
      currency,
      handleShowMultiSyn,
      hasAllocationError,
      ioType
    });
  else if (step === 1) return secondStep({ currency, ioType });
};

const firstStep = ({
  values,
  currency,
  handleShowMultiSyn,
  hasAllocationError,
  ioType
}) => [
  {
    title: "Final allocation (of the syndicate in the round)",
    colon: false,
    className: "_fullwidth",
    input: {
      name: "finalAllocation",
      type: "number",
      precision: 2,
      ...inputNumberFormatter(currency)
    }
  },
  ...(ioType === investmentOpportunityTypes.POOLED_EQUITY
    ? [
        {
          title: "Share price (of the round)",
          colon: false,
          className: "_fullwidth",
          input: {
            name: "preMoneySharePrice",
            type: "number",
            ...inputNumberFormatter(currency)
          }
        },
        {
          title: "Number of shares (allocated to the syndicate)",
          className: "_fullwidth",
          input: {
            name: "numberOfShares",
            type: "number",
            formatter: value => {
              if (isNaN(value)) return "";
              return value && formatNumber(value);
            }
          }
        }
      ]
    : []),
  !hasAllocationError && {
    title: (
      <div className="multiple-syn-text-wrapper">
        Did you set up multiple syndicates for the same deal?{" "}
        <Button
          type="link"
          onClick={handleShowMultiSyn(values)}
          label="Click here!"
        />
      </div>
    ),
    className: "_fullwidth _bold-15 _hidden _red",
    colon: false,
    input: {}
  },
  hasAllocationError && {
    title: (
      <p className="has-allocation-error-wrapper">
        <Icon type="warning" className="warning-icon" />
        The values don't match: the number of shares multiplied by the share
        price must be equal to the final allocation. Please make sure that all
        the values above are correct before confirming.
      </p>
    ),
    className: "_fullwidth _bold-15 _hidden _red",
    colon: false,
    input: {}
  }
];

const secondStep = ({ currency, ioType }) => [
  {
    title:
      "Don't start the investment phase unless the following details are final.",
    colon: false,
    className: "_fullwidth _hidden _bold-15",
    input: {}
  },
  {
    title: "Final allocation",
    className: "_fullwidth",
    input: {
      name: "finalAllocation",
      type: "number",
      disabled: true,
      ...inputNumberFormatter(currency)
    }
  },
  ...(ioType === investmentOpportunityTypes.POOLED_EQUITY
    ? [
        {
          title: "Share price (of the round)",
          colon: false,
          className: "_fullwidth",
          input: {
            name: "preMoneySharePrice",
            type: "number",
            ...inputNumberFormatter(currency),
            disabled: true
          }
        },
        {
          title: "Number of shares (allocated to the syndicate)",
          className: "_fullwidth",
          input: {
            name: "numberOfShares",
            type: "number",
            formatter: value => value && formatNumber(value),
            disabled: true
          }
        }
      ]
    : []),
  {
    title: "",
    className: "_fullwidth",
    input: {
      name: "preCommitmentConfirmation",
      type: "switch",
      label:
        "I confirm that I understand that I am about to close the pre-commitment phase and that from now on the investors will be able to invest in the deal. This step can't be reversed."
    }
  }
];

const overSubscribedDealFields = ({ currency }) => [
  {
    title:
      "All the commitments will be reduced pro-rata to ensure a fair participation in the deal.",
    className: "_fullwidth _bold-15 _hidden",
    colon: false,
    input: {}
  },
  {
    title: "Final allocation",
    className: "_fullwidth",
    input: {
      name: "finalAllocation",
      type: "number",
      disabled: true,
      ...inputNumberFormatter(currency)
    }
  },
  {
    title: "Soft commitments",
    className: "_fullwidth",
    input: {
      name: "roundedMoneySoftCommitted",
      type: "number",
      disabled: true,
      ...inputNumberFormatter(currency)
    }
  },
  {
    title: (
      <div className="over-subscribed-footer-label">
        <h4 className="_red">
          Do you want to assign the allocation differently?
        </h4>
        <h4>
          Click on "Cancel" and contact your account manager at LEVA to
          eliminate one or more commitments.
        </h4>
      </div>
    ),
    className: "_fullwidth _bold-15 _hidden",
    colon: false,
    input: {}
  }
];
