import { formatNumber } from "#helpers/number_format";
import { basicOption, inputNumberFormatter } from "#helpers/general";
import { format } from "date-fns";

const sharesAdditionFields = (
  shares = {},
  entityShares,
  { founderShares = [] } = {},
  existingShares
) => ({ values = {} }) => {
  let allLocalShares = 0;
  const mappedExistingShares = existingShares.map(({ _id }) => _id);
  const filteredFounderShares =
    founderShares &&
    founderShares.filter(({ _id }) => !mappedExistingShares.includes(_id));
  if (filteredFounderShares.length > 0) {
    filteredFounderShares.forEach(({ number }) => {
      allLocalShares += number;
    });
  }
  const editingValidator = value => {
    const finalValue = values.maxNumber + entityShares;
    if (value <= finalValue) {
      return formatNumber(value);
    }
    return 0;
  };
  const smpValidator = value => {
    if (!values.editing && value <= entityShares) {
      return formatNumber(value);
    } else if (value <= entityShares - allLocalShares) {
      return formatNumber(value);
    }
    return 0;
  };

  return [
    {
      title: "Select the share type the shareholder has:",
      className: "_fullwidth",
      input: {
        type: "select",
        name: "type",
        options: Object.keys(shares).map(key => basicOption(key, shares[key]))
      }
    },
    {
      title: "How many shares does the shareholder have?",

      details: `${
        entityShares === 0 && values.editing
          ? `All shares have been attributed to shareholders, the maximum is: ${values.maxNumber}`
          : entityShares === 0
          ? "All shares have been attributed to shareholders"
          : values.editing
          ? `The number of available outstanding shares is: ${formatNumber(
              entityShares + values.maxNumber
            )}`
          : `The number of available outstanding shares is: ${formatNumber(
              entityShares - allLocalShares
            )}`
      }`,
      className: "_fullwidth",
      input: {
        type: "number",
        name: "number",
        formatter: value =>
          values.editing ? editingValidator(value) : smpValidator(value)
      }
    },
    {
      title: "At which share price did the shareholder purchase these shares?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: "sharesPrice",
        options: [basicOption("Nominal value"), basicOption("Other")]
      }
    },
    values.sharesPrice === "Other"
      ? {
          title: "Please input the price here.",
          className: "_fullwidth",
          input: {
            type: "number",
            name: "sharesPriceOther",
            formatter: value =>
              value ? `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'") : "",
            parser: value =>
              value.replace(new RegExp(`CHF\\s?|(\\'*)`, "g"), "")
          }
        }
      : null,
    {
      title: "Have the shares been fully paid up?",
      className: "_fullwidth",
      input: {
        type: "select_btn",
        name: "paidUp",
        options: [basicOption("yes", "Yes"), basicOption("no", "No")]
      }
    },
    values.paidUp === "no"
      ? {
          title: "How much has been paid up?",
          className: "_fullwidth",
          input: {
            type: "number",
            name: "paidUpShares",
            formatter: value =>
              value ? `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'") : "",
            parser: value =>
              value.replace(new RegExp(`CHF\\s?|(\\'*)`, "g"), "")
          }
        }
      : null
  ];
};

const convertibleTypesAdditionFields = (types, { convertibles = [] } = {}) => ({
  values = {}
}) => {
  let updatingConvertible = {};
  let allLocalConvertibles = 0;

  const selected = types[values.type] || {};
  if (convertibles.length > 0) {
    convertibles.forEach(({ number }) => {
      allLocalConvertibles += number;
    });
    updatingConvertible = convertibles.find(({ type }) => type === values.type);
  }

  const { raisedAmount = 0, currentRaisedTotal: total = 0 } = selected;
  const availableAmount = raisedAmount - total;
  return [
    {
      title: "Select the convertible type:",
      className: "_fullwidth",
      input: {
        type: "select",
        name: "type",
        options: Object.keys(types).map(key =>
          basicOption(key, types[key].name)
        )
      }
    },
    {
      title: "What amount did the investor grant to the company?",
      className: "_fullwidth",
      details: `${
        Object.keys(selected).length === 0
          ? ""
          : Object.keys(selected).length > 0 && availableAmount === 0
          ? "The amount of this convertible loan is fully distributed to the investors"
          : values.editing && values._id
          ? `The available amount is:  ${formatNumber(
              availableAmount + updatingConvertible.number
            )}`
          : `The available amount is:  ${formatNumber(availableAmount)}`
      }`,
      input: {
        name: "number",
        type: "number",
        formatter: value => {
          return value
            ? `CHF ${
                selected && values.editing && value <= availableAmount
                  ? value
                  : value <= availableAmount
                  ? value
                  : ""
              }`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            : value;
        },
        parser: value => value.replace(new RegExp(`CHF\\s?|(\\'*)`, "g"), "")
      }
    },
    {
      title: "When was the amount paid into the company’s bank account?",
      className: "_fullwidth",
      input: {
        name: "paymentDate",
        type: "date",
        max: format(selected.maturityDate, "YYYY-MM-DD"),
        min: format(selected.issueDate, "YYYY-MM-DD")
      }
    }
  ];
};

export { sharesAdditionFields, convertibleTypesAdditionFields };
