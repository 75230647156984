import { NavLink } from "react-router-dom";

const AuthLayout = ({
  prompt: { link = "", title = "", linkLabel = "" } = {},
  customWrapperClassname = "",
  showLeftPane,
  children,
  graphic,
  leftPaneTitle,
  leftPaneSubtitle
}) => {
  return (
    <div className="auth-page-wrapper">
      {showLeftPane && (
        <div className="left-pane">
          <div className="container">
            <div className="content">
              <h1>{leftPaneTitle}</h1>
              <h3>{leftPaneSubtitle}</h3>
            </div>
            {graphic && (
              <div className="graphic-wrapper">
                <img src={graphic} alt="Graphic" />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="right-pane">
        <div className="prompt-container">
          <span>{title}</span>
          {link && (
            <span className="link-ref">
              <NavLink to={link}>{linkLabel}</NavLink>
            </span>
          )}
        </div>
        <div className={`auth-form-wrapper ${customWrapperClassname}`}>
          <div className="container">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
