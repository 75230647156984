import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { validateFieldHandler } from "#validators/validateField";

import InputRenderer from "../../InputRenderer";

import FormProgress from "./components/FormProgress";
import FormItem from "./components/FormItem";
import SuccessView from "./components/SuccessView";
import Preview from "./components/Preview";
import Sections from "./components/Sections";

const FormStepper = ({
  input,
  handleSubmit,
  handlePreview,
  inputEvents,
  sections = [],
  list = [],
  CustomInputRenderer,
  buttonStates = {},
  setFieldError,
  errors = {},
  validationSchema,
  values,
  success,
  successTitle,
  CustomSuccess,
  extraField: ExtraField,
  isAm,
  skippable = true,
  clickableSection,
  step = 0,
  customClassName = "",
  showButtons = true,
  helpers = [],
  finish = true,
  ...props
}) => {
  const initState = {
    selected: Number(step),
    didSubmit: false,
    preview: false
  };

  const [state, setState] = useState(initState);

  const { selected, didSubmit } = state;

  const currentStep = list[selected] || {};

  const validate = name =>
    validateFieldHandler(validationSchema, name, values, setFieldError);

  const validateStep = async () => {
    if (validationSchema) {
      setState({ ...state, isValidating: true });

      const testedValues = await Promise.all(
        currentStep.items
          .filter(Boolean)
          .map(({ input: { name } = {} }) => (name ? validate(name) : null))
      ).then(values => {
        setState({ ...state, isValidating: false });
        return values;
      });

      return testedValues.every(
        value =>
          !!value || value === undefined || value === null || value === ""
      );
    }

    return true;
  };

  const handleNext = step => async e => {
    if (step > 0) {
      const isClean = await validateStep();

      if (isClean) {
        window.scrollTo(0, 0);

        if (selected + step < list.length) {
          setState({ ...state, selected: selected + step, didSubmit: false });
        } else {
          handleSubmit();
        }
      } else {
        setState({ ...state, didSubmit: true });
      }
    } else if (step < 0 && selected + step >= 0) {
      setState({ ...state, selected: selected + step, didSubmit: false });
    }
  };

  const handleSkip = () => setState({ ...state, selected: selected + 1 });

  const handleDirectStep = step => () => {
    setState({ ...state, selected: step });
  };

  const renderSuccessView = () => {
    if (success && CustomSuccess) {
      return <CustomSuccess />;
    } else if (success && !CustomSuccess) {
      return <SuccessView title={successTitle} />;
    }

    return null;
  };
  const finalContentClassname = `inner-wrapper ${success ? "success" : ""}`;
  const canSkip =
    currentStep.items &&
    !currentStep.items.filter(Boolean).some(
      ({ input: { required } = {} } = {}) => required // CHECKS IF THERE ARE ANY REQUIRED FIELDS
    );

  const renderMainView = () => {
    if (success) {
      return renderSuccessView();
    }

    if (state.preview) {
      const togglePreview = () =>
        setState({ ...state, preview: !state.preview });

      return (
        <Preview
          isAm={isAm}
          onPreview={handlePreview(true, values)}
          onEdit={togglePreview}
          handleSubmit={handleSubmit}
          buttonStates={buttonStates}
        />
      );
    }

    return (
      <div className="form-content">
        {currentStep.stepTitle && (
          <h4 className="step-title">{currentStep.stepTitle}</h4>
        )}
        {currentStep.extraField && ExtraField && <ExtraField />}
        <CSSTransition
          key={currentStep.id}
          timeout={500}
          in
          appear
          classNames="fade"
        >
          <div className="items-wrapper">
            {Array.isArray(currentStep.items) &&
              currentStep.items.map(item => {
                if (item) {
                  const {
                    input: { name }
                  } = item;
                  const error = errors[name];
                  const value = values[name];

                  return (
                    <FormItem
                      key={`${currentStep.sectionId}${item.id}`}
                      {...item}
                      InputRenderer={CustomInputRenderer || InputRenderer}
                      inputEvents={inputEvents}
                      error={error}
                      didSubmit={didSubmit}
                      value={value}
                    />
                  );
                }
              })}
          </div>
        </CSSTransition>
        {showButtons && (
          <FormProgress
            skippable={skippable && canSkip}
            handleSkip={handleSkip}
            total={list.length}
            current={selected}
            disabledSubmit={currentStep.disableSubmit}
            onActionClick={handleNext}
            buttonStates={buttonStates}
            isValidating={state.isValidating}
            history={props.history}
            finish={finish}
          />
        )}
      </div>
    );
  };

  const finalWrapperClassname = `form-stepper ${customClassName}`;

  return (
    <div className={finalWrapperClassname}>
      <div className="left-pane">
        <Sections
          clickableSection={clickableSection}
          clickHandler={handleDirectStep}
          currentStep={currentStep}
          success={success}
          sections={sections}
          helpers={helpers}
        />
      </div>
      <div className="right-pane">
        <div className={finalContentClassname}>{renderMainView()}</div>
      </div>
    </div>
  );
};

export default FormStepper;
