import { useEffect } from 'react';
import { globalChangeHandler } from '#helpers/inputHandlers';
import { FormStepper, BasicFormWrapper } from '../../../Shared/Form';
import getFormList from './list';
import sections from './sections';
import { handleInputChange, handleCheckboxInArray } from '../../common/formHandler';
import CustomInputRenderer from '../../common/Inputs/CustomInputRenderer';
import BoardRegulationSucces from '../../common/successViews/BoardRegulationCreation';
import { mapEquityDataToReadableFormVals } from '#helpers/mappers/equity';

const IaFormPresentational = ({
  values = {},
  handleUpload,
  handleChange,
  setFieldValue,
  buttonStates,
  handleSubmit,
  memberships,
  ioColl,
  setValues,
}) => {
  useEffect(() => {
    const { didFetch, data } = ioColl;
    const { notary = {} } = mapEquityDataToReadableFormVals(data, {
      iaAndSha: true
    });

    if (didFetch) {
      setValues(notary);
    }
  }, [ioColl.didFetch]);

  useEffect(() => {
    const formattedMemberships = memberships.map((membership) => ({
      membershipId: membership.cuid,
      presidentOfTheBoard: membership.presidentOfTheBoard,
      representativeOf: membership.representativeOf,
      signingAuthority: membership.signingAuthority,
      ...membership.user
    })).filter(member => member.representativeOf === 'founder' || member.representativeOf === 'investor' || member.representativeOf === 'independent');
    setFieldValue('signingDirector', formattedMemberships);
  }, [memberships]);

  const composedInputHandlers = {
    switchHandler: globalChangeHandler(setFieldValue, values, 'switch'),
    checkboxHandler: globalChangeHandler(setFieldValue, values, 'checkbox'),
    checkboxInArray: handleCheckboxInArray(setFieldValue, values),
    radioHandler: handleChange
  };

  const formListArgs = {
    values,
    inputHandlers: composedInputHandlers
  };

  const formArgs = {
    list: getFormList(formListArgs),
    sections,
    values,
    inputEvents: {
      onChange: (type) =>
        handleInputChange({ handleChange, setFieldValue, values })(type),
      onUpload: handleUpload(setFieldValue)
    },
    clickableSection: true,
    handleSubmit,
    buttonStates,
    success: buttonStates.success,
    CustomInputRenderer,
    CustomSuccess: BoardRegulationSucces(),
  };

  return <FormStepper {...formArgs} />;
};

export default BasicFormWrapper(IaFormPresentational);
