import { Fragment } from 'react';
import { Button } from '../../../../Button';

const Preview = ({ isAm, onPreview, onEdit, handleSubmit, buttonStates }) => {
  return (
    <div className="preview-section">
      {isAm && (
        <Button
          label="Preview deal"
          onClick={onPreview}
          size="large"
          type="ghost"
        />
      )}
      {isAm && (
        <Fragment>
          <Button
            label="Edit deal"
            onClick={onEdit}
            size="large"
            type="ghost"
          />
          <span id="edit-deal">
            You can edit the details of your deal anytime.
          </span>
        </Fragment>
      )}
      <Button
        label="Create deal"
        onClick={handleSubmit}
        size="large"
        {...buttonStates}
      />
    </div>
  );
};

export default Preview;
