export const mapRegistrationValues = (values) => {
  return {
    email: values.email,
    password: values.password,
    firstName: values.firstName,
    lastName: values.lastName,
    redirectUrl: `${process.env.HOST}/verification`,
    tosAccepted: true
  };
};
