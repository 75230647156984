import { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import { resendInvitationEmail } from "#core/modules/iomemberships/actions";
import { membershipCols, commitmentCols } from "./cols";
import SharedTable from "../../../../../../../Shared/SharedTable/Presentational";
import { Button } from "../../../../../../../Shared/Button";

const Overview = ({
  rawCommitData = [],
  data,
  memberships,
  isInvestor,
  createdById,
  children,
  ioId,
  isPrivileged,
  dealIsClosed,
  handleStateChange,
  dealType
}) => {
  const [tabKey, setTabKey] = useState("0");
  const dispatch = useDispatch();

  const [filteredInfo, setFilteredInfo] = useState({ status: "" });
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: "",
    order: "ascend"
  });

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo({
      columnKey: sorter.columnKey,
      order: sorter.order
    });
  };

  const handleTabChange = activeKey => setTabKey(activeKey);

  const formattedMemberships = memberships
    .filter(({ userId }) => userId !== createdById)
    .map(({ investor = {}, status }) => {
      const usersCommits = rawCommitData.filter(
        ({ committerId }) => committerId === investor.cuid
      );

      const bestStatus = usersCommits.reduce(
        (acc, { status: commStatus }) =>
          commStatus + 2 > acc ? commStatus + 2 : acc,
        status
      );

      return { ...investor, status: bestStatus };
    });

  const handleRemainder = ({
    cuid: userId = "",
    firstName = "",
    lastName = ""
  }) => () => {
    const investor = `${firstName} ${lastName}`;
    const data = {
      ioId,
      userId,
      redirectUrlForNewUsers: `${process.env.HOST}/onboarding`,
      redirectUrlForExistingUsers: `${process.env.HOST}/login?redirectTo=invitations`
    };
    dispatch(resendInvitationEmail(data, investor));
  };

  const finalMembershipCols = membershipCols({
    filteredInfo,
    sortedInfo,
    handleRemainder
  });

  const finalCommitmentCols = commitmentCols({ dealType, dealIsClosed });

  const noCommitments = () => (
    <div className="no-data-wrapper">
      <h3>
        {isInvestor ? "No commitments made yet" : "No commitments received yet"}
      </h3>
      <p>
        {isInvestor
          ? "Come back after placing a commitment"
          : "Come back soon!"}
      </p>
    </div>
  );
  return (
    <div className="overview-container">
      {isInvestor ? (
        <Tabs onChange={handleTabChange} activeKey={tabKey} type="card">
          <Tabs.TabPane tab="Data room" key="0">
            {children}
          </Tabs.TabPane>
          <Tabs.TabPane tab="My investments" key="1">
            {Array.isArray(data) && data.length === 0 ? (
              noCommitments()
            ) : (
              <SharedTable columns={finalCommitmentCols} dataSource={data} />
            )}
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <Tabs onChange={handleTabChange} activeKey={tabKey} type="card">
          <Tabs.TabPane tab="Invited investors" key="0">
            {Array.isArray(formattedMemberships) &&
            formattedMemberships.length === 0 ? (
              <div className="no-data-wrapper">
                <h3>The investor CRM is currently empty</h3>
                <p>Invite your co-investors now</p>
                {isPrivileged && !dealIsClosed && (
                  <Button
                    size="large"
                    disabled={dealIsClosed}
                    onClick={handleStateChange("addMemberModalOpen", true)}
                    label="Add investor"
                  />
                )}
              </div>
            ) : (
              <SharedTable
                columns={finalMembershipCols}
                dataSource={formattedMemberships}
                tableProps={{
                  onChange: handleChange,
                  pagination: {
                    pageSizeOptions: ["10", "25", "50"],
                    showSizeChanger: true
                  }
                }}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Commitments" key="1">
            {Array.isArray(data) && data.length === 0 ? (
              noCommitments()
            ) : (
              <SharedTable
                columns={finalCommitmentCols}
                dataSource={data}
                tableProps={{
                  pagination: {
                    pageSizeOptions: ["10", "25", "50"],
                    showSizeChanger: true
                  }
                }}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data room" key="2">
            {children}
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  );
};

Overview.propTypes = {
  rawCommitData: PropTypes.array,
  data: PropTypes.array,
  memberships: PropTypes.array,
  isInvestor: PropTypes.bool,
  createdById: PropTypes.string
};

export default Overview;
