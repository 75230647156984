import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.GET_TAXES:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            taxes: action.payload.data
          };
        }
      });

    case types.GET_YEARLY_REPORT:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            yearlyReport: action.payload.data
          };
        }
      });

    case types.PATCH_TAXES:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          return {
            ...state._data
          };
        }
      });

    case types.POST_TAXES:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data,
            taxes: [...state?._data?.taxes, action.payload.data]
          };
        }
      });

    case types.DELETE_TAXES:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          return {
            ...state._data,
            taxes: {}
          };
        }
      });
    default:
      return state;
  }
};

export default reducer;
