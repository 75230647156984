import i18n from "../../../i18n";

export const industries = [
  {
    label: i18n.t("general.industry_labels.finance"),
    value: "finance"
  },
  {
    label: i18n.t("general.industry_labels.life_sciences"),
    value: "life_sciences"
  },
  {
    label: i18n.t("general.industry_labels.consumer_goods"),
    value: "consumer_goods"
  },
  {
    label: i18n.t("general.industry_labels.utilities"),
    value: "utilities"
  },
  {
    label: i18n.t("general.industry_labels.information_technology"),
    value: "information_technology"
  },
  {
    label: i18n.t("general.industry_labels.chemicals"),
    value: "chemicals"
  },
  {
    label: i18n.t("general.industry_labels.education"),
    value: "education"
  },
  {
    label: i18n.t("general.industry_labels.media"),
    value: "media"
  },
  {
    label: i18n.t("general.industry_labels.health"),
    value: "health"
  },
  {
    label: i18n.t("general.industry_labels.legal_services"),
    value: "legal_services"
  },
  {
    label: i18n.t("general.industry_labels.industrial_goods"),
    value: "industrial_goods"
  },
  {
    label: i18n.t("general.industry_labels.logistics"),
    value: "logistics"
  },
  {
    label: i18n.t("general.industry_labels.travel"),
    value: "travel"
  },
  {
    label: i18n.t("general.industry_labels.food_and_beverage"),
    value: "food_and_beverage"
  },
  {
    label: i18n.t("general.industry_labels.sustainability"),
    value: "sustainability"
  },
  {
    label: i18n.t("general.industry_labels.real_estate"),
    value: "real_estate"
  },
  {
    label: i18n.t("general.industry_labels.retail"),
    value: "retail"
  },
  {
    label: i18n.t("general.industry_labels.professional_services"),
    value: "professional_services"
  }
];

export default () => {
  return {
    investmentLevel: {
      name: i18n.t("general.investments"),
      array: [
        {
          value: "pre_revenue",
          label: i18n.t("general.investment_focus_labels.pre_revenue")
        },
        {
          value: "early_funding",
          label: i18n.t("general.investment_focus_labels.early_funding")
        },
        {
          value: "growth_funding",
          label: i18n.t("general.investment_focus_labels.growth_funding")
        },
        {
          value: "pre_ip",
          label: i18n.t("general.investment_focus_labels.pre_ip")
        },
        {
          value: "project_financing",
          label: i18n.t("general.investment_focus_labels.project_financing")
        },
        {
          value: "buy_out",
          label: i18n.t("general.investment_focus_labels.buy_out")
        },
        {
          value: "turnaround",
          label: i18n.t("general.investment_focus_labels.turnaround")
        }
      ]
    },
    ioPerYear: {
      name: i18n.t("general.ioPerYear"),
      array: [
        {
          value: "1-3"
        },
        {
          value: "4-6"
        },
        {
          value: "7-10"
        },
        {
          value: "11-19"
        },
        {
          value: "20+"
        }
      ]
    },
    industry: {
      name: i18n.t("general.industry"),
      array: industries
    }
  };
};
