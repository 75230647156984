import { Component } from "react";
import { Table } from "antd";
import PropTypes from "prop-types";

class SharedTable extends Component {
  render() {
    const {
      columns,
      dataSource,
      hasRowSelection,
      handleRowSelection,
      footer,
      tableProps
    } = this.props;
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        if (handleRowSelection) {
          handleRowSelection(selectedRowKeys, selectedRows);
        }
      }
    };

    return (
      <div className="table-wrapper">
        <Table
          id="table-wrap"
          pagination={{
            defaultPageSize: 5,
            className: "pagination-wrapper",
            scroll: { y: "max-content" }
          }}
          rowSelection={hasRowSelection ? rowSelection : null}
          columns={columns}
          dataSource={dataSource}
          footer={footer}
          {...tableProps}
        />
      </div>
    );
  }
}

SharedTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  hasRowSelection: PropTypes.bool
};

export default SharedTable;
