import { connect } from "react-redux";
import { getEntityShareholders } from "#core/modules/entities/actions";
import { clearState } from "#core/utils/helper_actions";
import {
  putIoInfo,
  createDealAndBulkMembers,
  updateDealAndBulkMembers
} from "#core/modules/io/actions";
import { bulkCreateIoMemberships } from "#core/modules/iomemberships/actions";
import { getIoMemberships } from "#core/modules/iomemberships/actions";

import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { shareholders = [] } = state.shares._data || {};
  const { entity: { cuid: entityId } = {} } = state.entities._data || {};
  const sharesFetched = state.shares.didFetch;
  const {
    isUpdating,
    didUpdate,
    _data: { io: { closed: ioClosed } = {} } = {}
  } = state.io;
  const {
    isCreating,
    didCreate,
    didUpdate: membershipCreated,
    isUpdating: membershipUpdating
  } = state.ioMemberships;
  const { memberships: ioMemberships = [] } = state.ioMemberships._data || {};

  return {
    shareholders,
    sharesFetched,
    entityId,
    buttonStates: {
      loading: isUpdating || isCreating || membershipUpdating,
      success: didUpdate || didCreate || membershipCreated
    },
    ioClosed,
    ioMemberships
  };
};

const mapDispatchToProps = dispatch => ({
  putIoInfo: data => dispatch(putIoInfo(data)),
  getEntityShareholders: data => dispatch(getEntityShareholders(data)),
  bulkCreateIoMemberships: data => dispatch(bulkCreateIoMemberships(data)),
  clearState: key => clearState(dispatch, key),
  getIoMemberships: data => dispatch(getIoMemberships(data)),
  updateDealAndBulkMembers: data => dispatch(updateDealAndBulkMembers(data)),
  createDealAndBulkMembers: data => dispatch(createDealAndBulkMembers(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
