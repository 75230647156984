import { Typography } from "antd";
import { mapDealMembershipStatus } from "#helpers/mappers/memberships";
import { formatNumber } from "#helpers/number_format";
import { useGetColumnSearchProps } from "#helpers/hooks/table";
import ioTypes from "#constants/io/ioTypes";
import { Button } from "../../../../../../../Shared/Button";

export const membershipCols = ({
  filteredInfo,
  sortedInfo,
  handleRemainder
}) => [
  {
    title: "First name",
    dataIndex: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    sortOrder: sortedInfo.columnKey === "firstName" && sortedInfo.order,
    key: "firstName",
    ...useGetColumnSearchProps({ dataIndex: "firstName" })
  },
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName",
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    sortOrder: sortedInfo.columnKey === "lastName" && sortedInfo.order,
    ...useGetColumnSearchProps({ dataIndex: "lastName" })
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    filters: [
      { text: "Invitation not accepted by investor", value: 0 },
      { text: "Invitation accepted by investor", value: 1 },
      { text: "Soft commited", value: 2 },
      { text: "Terms of Syndicate signed", value: 3 },
      { text: "Funds wired", value: 4 },
      { text: "Investor's funds have arrived at Leva", value: 6 }
    ],
    filteredValue: filteredInfo.status || null,
    onFilter: (value, record) => record.status === value,
    render: text => mapDealMembershipStatus(text)
  },
  {
    title: "Actions",
    dataIndex: "remainder",
    key: "remainder",
    render: (_, investor) =>
      investor.status < 1 && (
        <Button
          type="secondary"
          onClick={handleRemainder(investor)}
          label="Re-send invitation email"
        />
      )
  }
];

export const commitmentCols = ({ dealType, dealIsClosed }) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    ...useGetColumnSearchProps({ dataIndex: "name" })
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    ...useGetColumnSearchProps({ dataIndex: "status" })
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    ...useGetColumnSearchProps({ dataIndex: "date" })
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    ...useGetColumnSearchProps({ dataIndex: "amount" }),
    render: (val, { status }) => (
      <Typography.Text delete={status === "Rejected"}>{val}</Typography.Text>
    )
  },
  {
    title: dealIsClosed ? "Number of shares" : "Projected number of shares",
    dataIndex: "sharesNumber",
    key: "sharesNumber",
    ...useGetColumnSearchProps({ dataIndex: "sharesNumber" }),
    render: val => {
      return typeof val === "object" || dealType !== ioTypes.POOLED_EQUITY
        ? "N/A"
        : formatNumber(val);
    }
  },
  {
    title: dealIsClosed ? "Ownership" : "Projected ownership ",
    dataIndex: "ownership",
    key: "ownership",
    ...useGetColumnSearchProps({ dataIndex: "ownership" }),
    render: (val, { status }) => {
      if (status === "Rejected") return "N/A";
      if (Number(val) > 100) return "100.00 %";
      else return `${val} %`;
    }
  }
];
