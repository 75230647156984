import general from './generalInformation';
import shareholder from './shareholder';
import board from './board';

const notariesList = [
  {
    cuid: '1',
    notarySelected: false,
    firstName: 'Micheal',
    lastName: 'Baier'
  },
  {
    cuid: '2',
    notarySelected: false,
    firstName: 'Stefan',
    lastName: 'Müller'
  },
  {
    cuid: '3',
    notarySelected: false,
    firstName: 'Martin',
    lastName: 'Berweger'
  },
  {
    cuid: '4',
    notarySelected: false,
    firstName: 'Michele',
    lastName: 'Joho'
  }
];

export { general, shareholder, board, notariesList };
