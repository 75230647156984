import { useEffect } from "react";
import { FormBtnContainer } from "../../Button/";
import Field from "./Field";
import returnHandler from "./handlers";

const FormContainer = ({
  handleSubmit,
  handleCancel,
  handleChange,
  submitCount,
  disabledFields = [],
  extraContent,
  buttonStates = {},
  wrapperClassname = "",
  argsToBePassed,
  fieldList,
  CustomFooter,
  errors,
  useCustomHook = () => {},
  okButtonLabel,
  cancelButtonLabel,
  CustomInputRenderer,
  handleUpload,
  onEnter = true,
  ...formikProps
}) => {
  const { setFieldValue, values } = formikProps;

  const uploadHandler =
    typeof handleUpload === "function"
      ? handleUpload(setFieldValue)
      : handleUpload;

  useCustomHook(formikProps);
  const handleKeydown = e => {
    if (e.key === "Enter" && onEnter) {
      handleSubmit(e);
    }
  };

  const generateField = item => {
    const {
      title,
      className,
      details,
      input: { name, type },
      input,
      colon = true
    } = item;
    const didSubmit = submitCount > 0;
    const error = errors[name];
    const changeHandler = returnHandler(
      type,
      setFieldValue,
      handleChange,
      values
    );
    const value = values[name];
    const disabled = disabledFields.includes(name);

    return (
      <Field
        CustomInputRenderer={CustomInputRenderer}
        key={name}
        wrapperProps={{
          className,
          label: title,
          help: (didSubmit && error) || details,
          validateStatus: didSubmit && !!error ? "error" : "success",
          colon
        }}
        inputProps={{
          disabled,
          ...input,
          onChange: changeHandler,
          onUpload: uploadHandler,
          value,
          onKeyDown: handleKeydown
        }}
        formikProps={formikProps}
      />
    );
  };

  const list =
    typeof fieldList === "function"
      ? fieldList({ ...argsToBePassed, values })
      : fieldList;
  const finalClassname = `form-wrapper ${wrapperClassname}`;

  return (
    <div className={finalClassname}>
      <div className="form-container">
        <div className="fields">
          {list.filter(Boolean).map(item => {
            // boolean is used for conditional fields
            return generateField(item);
          })}
        </div>
        {extraContent}
      </div>
      {CustomFooter ? (
        <CustomFooter
          values={values}
          buttonStates={buttonStates}
          onClick={handleSubmit}
          onCancel={handleCancel}
        />
      ) : (
        <FormBtnContainer
          onCancel={handleCancel}
          onOk={handleSubmit}
          buttonStates={buttonStates}
          okButtonLabel={okButtonLabel}
          cancelButtonLabel={cancelButtonLabel}
        />
      )}
    </div>
  );
};

export default FormContainer;
