import { connect } from "react-redux";

import { submitLogin, verifyToken } from "#core/modules/auth/actions/";
import { clearState, clearStateData } from "#core/utils/helper_actions";

import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    isFetching: userIsFetching,
    didFetch: userFetched,
    _data: { confirmationLevel, token, twoStepAuthActive, tokenAuth } = {}
  } = state.users;

  const isAuthComplete =
    confirmationLevel !== 0 &&
    ((token && !twoStepAuthActive) ||
      (token && twoStepAuthActive && tokenAuth));

  return {
    userIsFetching,
    isAuthComplete,
    token,
    twoStepAuthActive,
    buttonStates: { loading: userIsFetching }
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: login => dispatch(submitLogin(login)),
  verifyToken: token => dispatch(verifyToken(token)),
  clearState: state => clearState(dispatch, state),
  clearStateData: state => clearStateData(dispatch, state),
  logout: () => dispatch({ type: "LOGOUT" })
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
