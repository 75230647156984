import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { readForm, clearForm } from "#helpers/mappers/form/storage";
import { mapValuesToArgs } from "#helpers/mappers/dealCreation";
import { closedValues } from "#constants/io/closed";
import { roles } from "#constants/roles";
import ioTypes from "#constants/io/ioTypes";
import Form from "./form/Presentational";
import list from "./form/list";

const TermsheetGenerator = ({
  readCurrencies,
  getEntityMemberships,
  clearState,
  createIo,
  entity,
  createDealAndBulkMembers,
  getEntityShareholders,
  getConvertibleTypes,
  fetchSingleIo,
  putIoInfo,
  clearStateDataByKey,
  ioMemberships,
  updateDealAndBulkMembers,
  getIoMemberships,
  getCommits,
  ...props
}) => {
  const { id } = useParams();

  const { userId, buttonStates } = props;

  useEffect(() => {
    clearState("io");
    clearState("entities");
    clearStateDataByKey("io", "io");
    readCurrencies();
    getEntityMemberships({ id: entity.cuid });
    getEntityShareholders({ entity_id: entity.cuid });
    getConvertibleTypes({ entity_id: entity.cuid });

    if (id) {
      fetchSingleIo(id);
      getIoMemberships({ io_id: id });
      getCommits({ entity_id: entity.cuid });
    }
  }, []);

  useEffect(() => {
    if (buttonStates.success) {
      clearForm({ userId });
    }
  }, [buttonStates.success]);

  const values = readForm({ userId });
  const allFields = list(values).flatMap(section => section.items);

  const handleSubmit = values => {
    const { ioColl: { data: { closed } = {} } = {} } = props;

    const defaults = {
      name: "name",
      type: ioTypes.POOLED_EQUITY,
      entityId: entity.cuid,
      leadInvestor: userId,
      fundingGoal: 10000,
      minimal: 1000,
      closed:
        closed < closedValues.TS_DONE || !closed ? closedValues.TS_DONE : closed
    };

    const isUpdating = !!id;

    const mappedValues = mapValuesToArgs(
      values,
      true,
      roles.FOUNDER,
      isUpdating
    );

    const payload = {
      ...mappedValues,
      ...defaults
    };

    if (id) {
      updateDealAndBulkMembers({
        id,
        dealData: { ...mappedValues },
        memberships: values.investors,
        ioMemberships,
        closed:
          closed < closedValues.TS_DONE || !closed
            ? closedValues.TS_DONE
            : closed
      });
    } else {
      createDealAndBulkMembers({
        dealData: payload,
        memberships: values.investors
      });
    }
  };

  const finalDefaultValues = {
    entityCanton: entity.canton,
    ...values,
    hasCommittedInvestors: "no"
  };

  return (
    <Form
      {...props}
      entityId={entity.cuid}
      fieldList={allFields}
      values={finalDefaultValues}
      handleSubmit={handleSubmit}
      entity={entity}
      rawInitValues
      id={id}
      ioMemberships={ioMemberships}
    />
  );
};

TermsheetGenerator.propTypes = {
  readCurrencies: PropTypes.func,
  getEntityMemberships: PropTypes.func,
  clearState: PropTypes.func,
  createIo: PropTypes.func,
  userId: PropTypes.string,
  entity: PropTypes.object,
  buttonStates: PropTypes.object,
  createDealAndBulkMembers: PropTypes.func,
  getEntityShareholders: PropTypes.func,
  getConvertibleTypes: PropTypes.func,
  fetchSingleIo: PropTypes.func,
  putIoInfo: PropTypes.func,
  clearStateDataByKey: PropTypes.func,
  ioColl: PropTypes.object,
  updateDealAndBulkMembers: PropTypes.func,
  ioMemberships: PropTypes.array,
  getIoMemberships: PropTypes.func
};

export default TermsheetGenerator;
