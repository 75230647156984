const teamMemberFields = withNda => [
  {
    title: "First name",
    details: "Enter investor's first name",
    input: {
      type: "text",
      name: "userFirstName"
    }
  },
  {
    title: "Last name",
    details: "Enter investor's last name",
    input: {
      type: "text",
      name: "userLastName"
    }
  },
  {
    title: "Email address",
    details: "Enter investor's email address",
    className: "_fullwidth",
    input: {
      type: "text",
      name: "userEmail"
    }
  },
  withNda
    ? {
        title: (
          <span>
            To protect the confidential information of your deals, each investor
            will enter a Confidentiality & Non-Circumvent Agreement with you,
            which template can be found{" "}
            <a
              href="/doc_viewer?doc=/docs/nda.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
            . Please sign this agreement{" "}
          </span>
        ),
        className: "_fullwidth nda-label",
        input: {
          name: "amSignedNDA",
          type: "switch",
          label:
            "I have read, understood and accept the Confidentiality & Non-Circumvent Agreement."
        }
      }
    : null
];

export default teamMemberFields;
