import Form from "antd/es/form";

const FormItem = ({
  showId = true,
  id,
  title,
  details,
  input,
  value,
  InputRenderer,
  inputEvents: { onChange, ...inputEvents },
  didSubmit,
  error,
  className = "",
  redDetails = false
}) => {
  const { type, required } = input;
  const { Item } = Form;
  const Label = (
    <div className="label-wrapper">
      {showId && <span className="number">{id}</span>}
      <span className="text">{title}</span>
    </div>
  );

  const finalClassname = `form-item ${className}`;

  return (
    <Item
      className={finalClassname}
      label={title ? Label : null}
      help={didSubmit && error ? error : null}
      validateStatus={didSubmit && error ? "error" : "success"}
      colon={false}
      required={required}
    >
      <div className="item-container">
        <span className={redDetails ? "red-details" : "details"}>
          {details}
        </span>
        <InputRenderer
          value={value}
          onChange={onChange(type)}
          {...input}
          {...inputEvents}
        />
      </div>
    </Item>
  );
};

export default FormItem;
