import * as membershipsService from "../../../../api/ioMembershipsService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import { triggerSnackbar } from "#core/modules/snackbar/actions";

export const createIoMembership = data => {
  return async dispatch => {
    try {
      start(dispatch, types.CREATE_MEMBERSHIP);

      const response = await membershipsService.postMembership(data);

      success(dispatch, types.CREATE_MEMBERSHIP, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.CREATE_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};

export const bulkCreateIoMemberships = memberships => {
  return async dispatch => {
    try {
      start(dispatch, types.BULK_CREATE_MEMBERSHIP);

      const response = await Promise.all(
        memberships.map(item => membershipsService.postMembership(item))
      );

      success(dispatch, types.BULK_CREATE_MEMBERSHIP, {
        data: response // response is not formated
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.BULK_CREATE_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};

export const resendInvitationEmail = (data, investor) => {
  return async dispatch => {
    try {
      start(dispatch, types.RESEND_INVITATION_EMAIL);

      const response = await membershipsService.resendInvitationEmail(data);

      success(dispatch, types.RESEND_INVITATION_EMAIL, {
        data: response.data
      });
      triggerSnackbar(dispatch, {
        open: true,
        message: `Investor ${investor} has been successfully reminded.`,
        variant: "info"
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.RESEND_INVITATION_EMAIL, {
        error: errorData
      });
    }
  };
};
