import { Fragment } from 'react';
import { Icon as IconWrapper } from 'antd';
import { CardWrapper } from '../';
import Avatar from '../../Avatar';
import Button from '../../Button/components/BtnWrapper/Presentational';

const FundraisingCard = ({
  id,
  actionLabel = 'Create',
  title,
  content,
  actions,
  icon,
  available
}) => {
  const hasRibbon = !available;
  const finalClassname = `fundraising-card-wrapper ${
    hasRibbon ? 'with-ribbon' : ''
  }`;

  return (
    <CardWrapper
      className={finalClassname}
      id={id}
      onClick={actions && actions.onClick ? actions.onClick : null}
    >
      <div className="fundraising-card-container">
        <Avatar
          size="large"
          className={`avatar ${!icon ? '_custom-size' : ''}`}
        >
          <IconWrapper type="smile" className="icon" component={icon} />
        </Avatar>
        <div className="title">
          <span>{title}</span>
        </div>
        <div className="content">
          <span>{content}</span>
        </div>
        <div className="action">
          <Button
            type="normal"
            className="action-button"
            label={
              <Fragment>
                {actionLabel} <IconWrapper type="arrow-right" />
              </Fragment>
            }
            {...actions}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default FundraisingCard;
