import PropTypes from 'prop-types';
import { Select } from 'antd';

export default function SelectInput({
  name,
  list = [],
  onChange,
  value,
  onBlur,
  disabled,
  customListRenderer,
  placeholder,
  className,
  ...props
}) {
  const nativeRenderer = () => {
    return list.map(({ value, label }) => {
      return (
        <Select.Option key={value || label} value={value || label}>
          {label || value}
        </Select.Option>
      );
    });
  };

  return (
    <div className={`select-input-wrapper ${className || ''}`}>
      <Select
        name={name}
        value={value}
        onChange={onChange(name)}
        onBlur={onBlur}
        disabled={disabled}
        className="select-element"
        placeholder={placeholder}
        {...props}
      >
        {customListRenderer || nativeRenderer()}
      </Select>
    </div>
  );
}

SelectInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  customListRenderer: PropTypes.any
};
