import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { handleUpload } from "#helpers/inputHandlers";
import { mapDealUpdateValues } from "#helpers/mappers/dealCreation";
import { mapEntityUpdateValues } from "#helpers/mappers/entitySettings";
import { roles } from "#constants/roles";
import Form from "./form/Presentational";
import list from "./form/list";
import { closedValues } from "#constants/io/closed";

const InvestmentAgreementGenerator = ({
  getEntity,
  entityId,
  entityData,
  putIoInfo,
  createIo,
  clearState,
  updateEntity,
  getEntityMemberships,
  getEntityShareholders,
  bulkCreateIoMemberships,
  fetchSingleIo,
  ioMemberships,
  createDealAndBulkMembers,
  getIoMemberships,
  updateDealAndBulkMembers,
  getCommits,
  ...props
}) => {
  const { id } = useParams();

  useEffect(() => {
    clearState("io");
    clearState("entities");
    getEntity(entityId);
    getEntityMemberships({ id: entityId });
    getEntityShareholders({ entity_id: entityId, role: "investor" });
    if (id) {
      fetchSingleIo(id);
      getIoMemberships({ io_id: id });
      getCommits({ entity_id: entityId });
    }
  }, []);

  const [state, setState] = useState({});

  const uploadHandler = handleUpload(setState, state);
  const fieldList = list({}).flatMap(section => section.items);

  const handleSubmit = async values => {
    const ioPayload = mapDealUpdateValues(values, true);
    const { investorsOverview = [] } = values;
    const { ioColl: { data: { closed } = {} } = {} } = props;

    const finalClosed =
      closed < closedValues.IA_DONE ? closedValues.IA_DONE : closed;

    // const selectedInvestors = investorsOverview
    //   .filter(({ selected }) => selected)
    //   .map(({ firstName, lastName, email, amount }) => ({
    //     ioId: id,
    //     entityMembershipRole: roles.INVESTOR,
    //     userFirstName: firstName,
    //     userLastName: lastName,
    //     userEmail: email,
    //     redirectUrl: `${process.env.HOST}/login?user=${email}&redirectTo=/dashboard/deal_management/${id}`,
    //     amount
    //   }));

    const payload = {
      ...ioPayload,
      ...finalClosed
    };
    if (id) {
      ioPayload.closed && delete ioPayload["closed"];

      updateDealAndBulkMembers({
        id,
        dealData: { ...ioPayload },
        memberships: investorsOverview,
        ioMemberships,
        closed: finalClosed
      });
    } else {
      createDealAndBulkMembers({
        dealData: payload,
        memberships: investorsOverview
      });
    }
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      fieldList={fieldList}
      values={entityData}
      handleUpload={uploadHandler}
      entityId={entityId}
      ioMemberships={ioMemberships}
      {...props}
    />
  );
};

export default InvestmentAgreementGenerator;
