import { useHistory } from "react-router-dom";

export default function Presentational(props) {
  const history = useHistory();

  const handleItemClick = link => {
    history.push(link);
  };
  return (
    <div>
      <h1 className="l3-title" style={{ color: "white", textAlign: "left" }}>
        {props.header}
      </h1>
      {props.items.map(item =>
        item.target ? (
          <a
            style={{
              fontFamily: "Jost-Regular",
              color: "white",
              display: "block",
              marginBottom: "5%",
              cursor: "pointer"
            }}
            alt="link"
            target={item.target}
            href={item.link}
          >
            {item.name}
          </a>
        ) : (
          <p
            onClick={() => handleItemClick(item.link)}
            style={{
              color: "white",
              cursor: "pointer",
              fontFamily: "Jost-Regular"
            }}
          >
            {item.name}
          </p>
        )
      )}
    </div>
  );
}
