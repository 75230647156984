export const cantons = [
  { canton_name: "Aargau" },
  { canton_name: "Appenzell Ausserhoden" },
  { canton_name: "Appenzell Innerhoden" },
  { canton_name: "Basel-Land" },
  { canton_name: "Basel-Stadt" },
  { canton_name: "Bern" },
  { canton_name: "Fribourg" },
  { canton_name: "Geneva" },
  { canton_name: "Glarus" },
  { canton_name: "Graubünden" },
  { canton_name: "Jura" },
  { canton_name: "Lucerne" },
  { canton_name: "Neuchatel" },
  { canton_name: "Nidwalden" },
  { canton_name: "Obwalden" },
  { canton_name: "St. Gallen" },
  { canton_name: "Schaffhausen" },
  { canton_name: "Schwyz" },
  { canton_name: "Solothurn" },
  { canton_name: "Thurgau" },
  { canton_name: "Ticino" },
  { canton_name: "Uri" },
  { canton_name: "Vaud" },
  { canton_name: "Valais" },
  { canton_name: "Zug" },
  { canton_name: "Zurich" }
];

export const zurichCantons = [
  { canton_name: "Bezirksgericht Affoltern" },
  { canton_name: "Bezirksgericht Andelfingen" },
  { canton_name: "Bezirksgericht Dielsdorf" },
  { canton_name: "Bezirksgericht Bülach" },
  { canton_name: "Bezirksgericht Dietikon" },
  { canton_name: "Bezirksgericht Hinwil" },
  { canton_name: "Bezirksgericht Horgen" },
  { canton_name: "Bezirksgericht Meilen" },
  { canton_name: "Bezirksgericht Pfäffikon" },
  { canton_name: "Bezirksgericht Uster" },
  { canton_name: "Bezirksgericht Winterthur" },
  { canton_name: "Bezirksgericht Zürich" }
];

export const cantonsSortedByName = cantons.map(
  ({ canton_name: label }) => label
);

export const zurichCantonsSortedByName = zurichCantons.map(
  ({ canton_name: label }) => label
);
