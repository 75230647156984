import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { roles } from "#constants/roles";

const PublicRoute = ({ role, ...props }) => {
  if (!!role && (role === roles.INVESTOR || role === roles.AM_INVESTOR)) {
    return <Redirect to="/dashboard/investments" />;
  } else if (!!role) {
    return <Redirect to="/dashboard" />;
  } else {
    return <Route {...props} />;
  }
};

PublicRoute.propTypes = {
  role: PropTypes.string
};

export default PublicRoute;
