import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { handleUpload } from "#helpers/inputHandlers";
import { convertToBase64 } from "#helpers/convertBase64";
import queryString from "query-string";
import { mapClaValues } from "#helpers/mappers/convertibles";
import ioTypes from "#constants/io/ioTypes";
import { closedValues } from "#constants/io/closed";
import Form from "./form/Presentational";

const ClaTermsheetPresentational = ({
  entityId,
  getEntityShareholders,
  readCurrencies,
  userId,
  clearState,
  fetchSingleIo,
  getEntityMemberships,
  createDealAndBulkMembers,
  updateDealAndBulkMembers,
  getIoMemberships,
  ioMemberships,
  deleteMembership,
  getCommits,
  location,
  esopShares,
  psopShares,
  ...props
}) => {
  const { id } = useParams();
  const { step } = queryString.parse(location.search);

  const [state, setState] = useState({});
  const uploadHandler = handleUpload(setState, state);

  useEffect(() => {
    clearState("io");
    readCurrencies();
    getEntityShareholders({ entity_id: entityId });
    getEntityMemberships({ id: entityId });

    if (id) {
      fetchSingleIo(id);
      getIoMemberships({ io_id: id });
      getCommits({ entity_id: entityId });
    }
  }, []);

  const handleSubmit = async values => {
    let shaBase64 = "";
    if (Object.keys(state).length > 0) {
      shaBase64 = await convertToBase64(state.sha_upload);
    }
    const { ioColl: { data: { closed } = {} } = {} } = props;
    const finalClosed =
      closed < closedValues.CLA_DONE || !closed
        ? closedValues.CLA_DONE
        : closed;
    const defaults = {
      type: ioTypes.UNPOOLED_DEBT,
      entityId,
      leadInvestor: userId,
      fundingGoal: values.moneyRaised,
      minimal: 1000,
      acceptsCommitments: false
    };
    if (id) {
      const updating = true;
      const cla = mapClaValues(values, updating, esopShares, psopShares);

      if (shaBase64.length > 0) {
        cla.cla.shaBase64 = shaBase64;
      }

      const { investorsTable: memberships = [] } = values;
      updateDealAndBulkMembers({
        id,
        dealData: { ...cla, fundingGoal: values.moneyRaised },
        memberships,
        ioMemberships
      });
    } else {
      const cla = mapClaValues(values, false, esopShares, psopShares);

      if (shaBase64.length > 0) {
        cla.cla.shaBase64 = shaBase64;
      }

      createDealAndBulkMembers({
        dealData: { ...defaults, ...cla, closed: finalClosed },
        memberships: values.investorsTable
      });
    }
  };

  const { pathname: currPath } = location;

  return (
    <Form
      handleSubmit={handleSubmit}
      id={id}
      ioMemberships={ioMemberships}
      handleUpload={uploadHandler}
      step={step}
      currPath={currPath}
      esopShares={esopShares}
      psopShares={psopShares}
      {...props}
    />
  );
};

ClaTermsheetPresentational.propTypes = {
  entityId: PropTypes.string,
  getEntityShareholders: PropTypes.func,
  readCurrencies: PropTypes.func,
  userId: PropTypes.string,
  clearState: PropTypes.func,
  fetchSingleIo: PropTypes.func
};

export default ClaTermsheetPresentational;
