import Icon from 'antd/es/icon';
import FormSuccessView from '../../../Shared/Form/FormStepper/components/SuccessView';
import { Button } from '../../../Shared/Button';

const TsCreationSuccessView = () => {
  return (
    <div className="custom-success-view ts-creation">
      <FormSuccessView
        title="Congratulations, you just finished to set-up the term sheet for your next fundraising round."
        className="entity-creation"
        id="success-prompt"
      />
      <h3>You can come back and edit your term sheet at any time.</h3>
      <h3>
        If you wish to add further terms, which are not covered in the
        questionnaire we recommend to reach out to X's Startup
        Desk.
      </h3>
      <div className="btn-container">
        <Button
          label="View equity"
          className="main-btn"
          href="/equity/overview"
        />
      </div>
    </div>
  );
};

export default TsCreationSuccessView;
