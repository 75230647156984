import { Switch, List } from "antd";
import "antd/lib/list/style/css";
import PropTypes from "prop-types";
import { targetBlankPdf } from "#helpers/general";
import { VAT_NEW_VALUE, VAT_VALUE } from "#constants/deal";
import ioTypes from "#constants/io/ioTypes";
import { formatNumber } from "../../../../../../helpers/number_format";

const FourthStep = ({
  amount,
  currency,
  fees,
  contracts = [],
  adjustedAmount,
  vat,
  new_vat,
  type
}) => {
  const { adminFee, setupFee } = fees;
  const investedAmount = parseFloat(adjustedAmount, 10);
  let vatPay = 0;

  if (new_vat)
    vatPay = Number(
      investedAmount * ((adminFee + setupFee) * VAT_NEW_VALUE)
    ).toFixed(2);
  else if (vat)
    vatPay = Number(
      investedAmount * ((adminFee + setupFee) * VAT_VALUE)
    ).toFixed(2);

  const adminPay = Number(investedAmount * adminFee).toFixed(2);
  const setupPay = Number(investedAmount * setupFee).toFixed(2);
  const totalToPay =
    investedAmount + Number(adminPay) + Number(setupPay) + Number(vatPay);

  const listContent = [
    {
      label: "Investment amount:",
      value: adjustedAmount
    },
    {
      label: "Closing fee",
      value: setupPay
    },
    {
      label: "Administration fee:",
      value: adminPay
    },
    {
      label: "VAT: ",
      value: vatPay
    },
    {
      label: "Total to pay:",
      value: totalToPay
    }
  ];

  const { value: tosContract } =
    contracts.find(({ contractType }) => contractType === "syndicate-terms") ||
    {};

  const legalTermsLabel =
    type === ioTypes.POOLED_FUND
      ? "the terms of Simple Partnership Agreement and Subscription Agreement."
      : "the Terms of Syndicate.";

  return (
    <div className="confirmation">
      <div className="step-title">Committing</div>
      <List
        className="fees"
        dataSource={listContent}
        itemLayout="horizontal"
        renderItem={({ label, value }) => {
          return (
            <List.Item className="fee-item">
              <span className="fee-name">{label}</span>
              <span className="fee-amount">
                {amount ? formatNumber(value, currency) : 0}
              </span>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

FourthStep.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  fees: PropTypes.object,
  dealName: PropTypes.string,
  contracts: PropTypes.array,
  adjustedAmount: PropTypes.number,
  vat: PropTypes.bool,
  new_vat: PropTypes.bool
};

export default FourthStep;
