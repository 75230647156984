import { handleNav } from "#helpers/general";
import { Button } from "../../../Shared/Button";
import WelcomeGraphic from "../../../../assets/images/welcome.png";

const DmInviteSuccess = ({
  history,
  syncUserDetails,
  onboarding,
  inviteeFirstName
}) => {
  const handleClick = () => {
    syncUserDetails();
    handleNav(history, {
      pathname: "/create/entity"
    })();
  };

  return (
    <div className="success-view dm-invite">
      <div className="img-wrapper">
        <img src={WelcomeGraphic} alt="Welcome" />
      </div>
      <h1>Welcome {inviteeFirstName}</h1>
      <h3>
        Experience a new way of deal making! Leva brought fundraising to the
        digital age.
      </h3>
      <h3>Now it is your turn to source and fund the winners of tomorrow.</h3>
      <Button label="Set-up a syndicate" onClick={handleClick} />
    </div>
  );
};

export default DmInviteSuccess;
