const formSections = [
  {
    id: 1,
    title: 'A. General information',
  },
  {
    id: 2,
    title: 'B. Meetings',
  },
  {
    id: 3,
    title: 'C. Compensation',
    subSections: [
      {
        id: 3,
        title: 'Income',
      },
      {
        id: 4,
        title: 'Costs',
      },
    ],
  },
  {
    id: 5,
    title: 'D. Management',
  },
];

export default formSections;
