import InputContainer from "../../../../../Shared/Input";

export default function AccessCode({
  handleAccessCodeChange,
  showAccessCodeError,
  accessCodeInput
}) {
  return (
    <div className="reach-out-wrapper">
      <h3>Insert your code</h3>
      <InputContainer
        value={accessCodeInput}
        maxLength="4"
        type="text"
        autoFocus
        onChange={handleAccessCodeChange}
      />
      {showAccessCodeError && (
        <p className="error-message">This is not a valid code</p>
      )}
      <br />
      <p>
        You don't have one? Click{" "}
        <a target="_blank" href="https://askleva.typeform.com/to/yMSXOmbl">
          {" "}
          here{" "}
        </a>
        to request one
      </p>
    </div>
  );
}
