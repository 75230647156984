import Container from './Container';
import { withModalWrapper } from '../../hocs';
import BasicWrapper from '../../BasicWrapper';

export const ContainerWrapper = (props) => {
  return (
    <BasicWrapper>
      <Container {...props} />
    </BasicWrapper>
  );
};

const ModalWrapper = (props) => {
  const { visible, title, handleClose } = props;
  const Modal = withModalWrapper({ visible, title, handleClose })(Container);
  return <Modal />;
};

export default ModalWrapper;
