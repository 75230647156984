import { NavLink } from "react-router-dom";
import { Button } from "../../../../../../../../Shared/Button";
import ModalWrapper from "../../../../../../../../Shared/Modal";

const DealExecutedModal = ({ name = "", onCancel, ...modalConfig }) => {
  return (
    <ModalWrapper
      title={`Big news! The ${name} deal is moving forward: now it's time to invest!`}
      {...modalConfig}
      width={600}
      footer={null}
      className="tos-modal"
      closable={false}
      destroyOnClose
    >
      <div>
        We are happy to tell you that the deal manager has finalized the deal
        terms.
        <br />
        <br />
        Now you can confirm your subscription by clicking on the “Invest”
        button.
        <br />
        <br />
        <b>
          If you did not finalize your onboarding, please do so right now at{" "}
          <NavLink to="/kyc">Leva KYC</NavLink>
        </b>
      </div>
      <div className="btn-container" style={{ marginTop: "4%" }}>
        <Button label="Invest" onClick={onCancel} />
      </div>
    </ModalWrapper>
  );
};

export default DealExecutedModal;
