export const saveForm = ({ userId, entityId, values }) => {
  const savedValues = readForm({ userId });

  const parsedData = JSON.stringify({
    entityId,
    values: { ...savedValues, ...values }
  });

  try {
    localStorage.setItem(userId, parsedData);
  } catch (e) {
    return e;
  }
};

export const clearForm = ({ userId }) => {
  try {
    localStorage.removeItem(userId);
  } catch (e) {
    return e;
  }
};

export const readForm = ({ userId }) => {
  try {
    const data = localStorage.getItem(userId);

    if (data) {
      const { values } = JSON.parse(data);
      return values;
    }

    return {};
  } catch (e) {
    return {};
  }
};
