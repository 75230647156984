import i18n from "../../../i18n";
import bulb from "../../../assets/illustrations/venture syndicate/bulb.svg";
import star from "../../../assets/illustrations/venture syndicate/star.svg";
import winner from "../../../assets/illustrations/venture syndicate/winner.svg";
import benefits from "../../../assets/illustrations/venture syndicate/benefits.svg";

export const ventureFeatures = [
  {
    title: i18n.t("landing.venture_syndicate.veture_features.compliance.title"),
    content: i18n.t(
      "landing.venture_syndicate.veture_features.compliance.content"
    )
  },
  {
    title: i18n.t("landing.venture_syndicate.veture_features.deal_flow.title"),
    content: i18n.t(
      "landing.venture_syndicate.veture_features.deal_flow.content"
    )
  },
  {
    title: i18n.t("landing.venture_syndicate.veture_features.governance.title"),
    content: i18n.t(
      "landing.venture_syndicate.veture_features.governance.content"
    )
  },
  {
    title: i18n.t("landing.venture_syndicate.veture_features.speed.title"),
    content: i18n.t("landing.venture_syndicate.veture_features.speed.content")
  },

  {
    title: i18n.t("landing.venture_syndicate.veture_features.expertise.title"),
    content: i18n.t(
      "landing.venture_syndicate.veture_features.expertise.content"
    )
  },
  {
    title: i18n.t(
      "landing.venture_syndicate.veture_features.higher_returns.title"
    ),
    content: i18n.t(
      "landing.venture_syndicate.veture_features.higher_returns.content"
    )
  }
];

export const expertiseFeatures = [
  {
    title: i18n.t("landing.venture_syndicate.expertise_features.vc_fund.title"),
    content: i18n.t(
      "landing.venture_syndicate.expertise_features.vc_fund.content"
    )
  },
  {
    title: i18n.t("landing.venture_syndicate.expertise_features.angel.title"),
    content: i18n.t(
      "landing.venture_syndicate.expertise_features.angel.content"
    )
  },
  {
    title: i18n.t("landing.venture_syndicate.expertise_features.founder.title"),
    content: i18n.t(
      "landing.venture_syndicate.expertise_features.founder.content"
    )
  },
  {
    title: i18n.t("landing.venture_syndicate.expertise_features.expert.title"),
    content: i18n.t(
      "landing.venture_syndicate.expertise_features.expert.content"
    )
  },
  {
    title: i18n.t(
      "landing.venture_syndicate.expertise_features.networker.title"
    ),
    content: i18n.t(
      "landing.venture_syndicate.expertise_features.networker.content"
    )
  },
  {
    title: i18n.t(
      "landing.venture_syndicate.expertise_features.raising_start.title"
    ),
    content: i18n.t(
      "landing.venture_syndicate.expertise_features.raising_start.content"
    )
  }
];

export const ventureFeaturesTexts = {
  header: i18n.t("landing.venture_syndicate.venture_features_texts.header"),
  content: i18n.t("landing.venture_syndicate.venture_features_texts.content"),
  icon: benefits
};

export const ventureExpertiseTexts = {
  header: i18n.t("landing.venture_syndicate.venture_expertise_texts.header"),
  content: i18n.t("landing.venture_syndicate.venture_expertise_texts.content"),
  icon: star
};

export const ventureLists = [
  {
    title: i18n.t(
      "landing.venture_syndicate.venture_lists.technology.header_title"
    ),
    items: [
      i18n.t("landing.venture_syndicate.venture_lists.technology.deal_setup"),
      i18n.t(
        "landing.venture_syndicate.venture_lists.technology.deal_management"
      ),
      i18n.t("landing.venture_syndicate.venture_lists.technology.deal_closing"),
      i18n.t(
        "landing.venture_syndicate.venture_lists.technology.investor_onboarding"
      )
    ]
  },
  {
    title: i18n.t(
      "landing.venture_syndicate.venture_lists.compliance.header_title"
    ),
    items: [
      i18n.t(
        "landing.venture_syndicate.venture_lists.compliance.syndicate_agreement"
      ),
      i18n.t(
        "landing.venture_syndicate.venture_lists.compliance.banking_wiring"
      ),
      i18n.t("landing.venture_syndicate.venture_lists.compliance.tax_status"),
      i18n.t(
        "landing.venture_syndicate.venture_lists.compliance.investor_kyc_aml"
      )
    ]
  }
];

export const yourVentureText = {
  header: i18n.t("landing.venture_syndicate.your_venture_text.header"),
  listTexts: [
    {
      content: i18n.t(
        "landing.venture_syndicate.your_venture_text.list_texts.help_investors"
      )
    }
    // {
    //   content:
    //     'The venture industry has experienced almost no innovation over the past 30 years. It’s time to give a new breed of investors the tools they need to fund the winners of tomorrow.',
    // },
  ]
};

export const syndicateText = {
  header: i18n.t("landing.venture_syndicate.syndicate_text.header"),
  listTexts: [
    {
      content: i18n.t(
        "landing.venture_syndicate.syndicate_text.list_texts.what_is_first"
      )
    },
    {
      content: i18n.t(
        "landing.venture_syndicate.syndicate_text.list_texts.what_is_second"
      )
    }
  ],
  icon: bulb
};

export const levaBetterText = {
  header: i18n.t("landing.venture_syndicate.leva_better.header"),
  listTexts: [
    {
      content: i18n.t(
        "landing.venture_syndicate.leva_better.list_texts.investor_experience"
      )
    }
  ],
  icon: winner
};
