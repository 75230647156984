import { connect } from 'react-redux';
import {
  getEntityMemberships,
  getEntity
} from '#core/modules/entities/actions';
import {
  getEntityShareholders,
  getShareClasses
} from '#core/modules/shares/actions';
import { getAllMembers } from '#helpers/general';
import { clearState, clearStateDataByKey } from '#core/utils/helper_actions';
import { putIoInfo } from '#core/modules/io/actions/update';
import { fetchSingleIo } from '#core/modules/io/actions';
import { getCommits } from '#core/modules/commitment/actions';

import Presentational from './Presentational';

const mapStateToProps = (state) => {
  const {
    _data: {
      entity: { cuid: entityId, ...entity } = {},
      memberships = [],
      membershipFetched
    } = {}
  } = state.entities;
  const {
    _data: {
      shareholders = [],
      list: shareTypes = [],
      shareholdersFetched
    } = {},
    didFetch: sharesDidFetch
  } = state.shares;

  const {
    _data: { io },
    didFetch: ioDidFetch
  } = state.io || {};
  const {
    _data: { io: { termSheet: { fullyDiluted, preValue } = {} } },
  } = state.io || {};
  const { _data: { commits } = {}, didFetch: commitsDidFetch } =
    state.commits || {};

  const allMemberships = getAllMembers({ memberships, shareholders });
  return {
    entityId,
    shareholdersColl: {
      didFetch: shareholdersFetched,
      data: shareholders
    },
    membershipsColl: {
      didFetch: membershipFetched,
      data: allMemberships
    },
    entity,
    shareTypes,
    sharesDidFetch,
    buttonStates: {
      loading: state.io.isUpdating,
      success: state.io.didUpdate
    },
    ioColl: {
      data: io,
      didFetch: ioDidFetch
    },
    commitsColl: {
      data: commits,
      didFetch: commitsDidFetch
    },
    fullyDiluted,
    preValue
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEntityMemberships: (data) => dispatch(getEntityMemberships(data)),
  getEntityShareholders: (data) => dispatch(getEntityShareholders(data)),
  getEntity: (data) => dispatch(getEntity(data)),
  getShareClasses: (data) => dispatch(getShareClasses(data)),
  fetchSingleIo: (data) => dispatch(fetchSingleIo(data)),
  putIoInfo: (data) => dispatch(putIoInfo(data)),
  getCommits: (data) => dispatch(getCommits(data)),
  clearState: (key) => clearState(dispatch, key),
  clearStateKeys: (state, ...keys) =>
    clearStateDataByKey(dispatch, state, ...keys)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
