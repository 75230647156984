import * as ndaService from "../../../../api/ndaService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const getNdas = params => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_NDAS);
      const response = await ndaService.getNdas(params);
      success(dispatch, types.GET_NDAS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_NDAS, {
        error: errorData
      });
    }
  };
};

export const getNdaDoc = data => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_NDA_PREVIEW);

      const response = await ndaService.getNdaDoc(data);

      success(dispatch, types.GET_NDA_PREVIEW, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_NDA_PREVIEW, {
        error: errorData
      });
    }
  };
};
