import { Col } from "antd";

export default function Presentational() {
  const year = new Date().getFullYear();
  return (
    <Col className="copy-col-wrapper" span={24}>
      <p className="copy-text">
        LEVA <span dangerouslySetInnerHTML={{ __html: "&copy;" }} /> {year} -
        Made in Switzerland
      </p>
    </Col>
  );
}
