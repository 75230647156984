import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotFound from '../Shared/NotFound/GenericNotFound';

import i18n from '../../i18n';

const userShouldAccess = (rolesAllowed, role) => {
  // If rolesAllowed is not defined allow all roles to access the route
  if (!rolesAllowed) {
    return true;
  }
  // If rolesAllowed is defined it should go to route only if it matches the role
  if (rolesAllowed.includes(role)) {
    return true;
  }
  return false;
};

const AuthRoute = ({
  userConfirmationLevel,
  twoStepAuthActive,
  tokenAuth,
  confirmationLevel,
  rolesAllowed,
  role,
  ...props
}) => {
  if (userConfirmationLevel === 0) {
    return <Redirect to="/logout" />;
  }

  if (userConfirmationLevel && (!twoStepAuthActive || tokenAuth)) {
    if (
      (!confirmationLevel ||
        (confirmationLevel && userConfirmationLevel >= confirmationLevel)) &&
      userShouldAccess(rolesAllowed, role)
    ) {
      return <Route {...props} />;
    }
    return <NotFound message={i18n.t('auth_route.no_access')} />;
  }
  return <Redirect to="/" />;
};

AuthRoute.propTypes = {
  auth: PropTypes.object,
  level: PropTypes.number,
  userConfirmationLevel: PropTypes.number,
  twoStepAuthActive: PropTypes.bool,
  tokenAuth: PropTypes.string,
  confirmationLevel: PropTypes.number,
  rolesAllowed: PropTypes.array,
  role: PropTypes.string
};

export default AuthRoute;
