import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";
import { updateObject, updateObjectInArray } from "../../utils/mutators";

const initState = {
  ...initialState
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.ADD_IO:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          return {
            ...state._data,
            io: action.payload.data
          };
        }
      });

    case types.FETCH_ALL_IOS:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            ios: action.payload.data
          };
        }
      });

    case types.FETCH_SINGLE_IO:
      return baseReducer(action, state, actions.FETCH, {
        start: () => ({
          ...state._data,
          ioIsFetching: true,
          singleIoDidFetch: false
        }),
        success: () => {
          return {
            ...state._data,
            io: action.payload.data,
            ioIsFetching: false,
            singleIoDidFetch: true
          };
        },
        fail: () => ({
          ...state._data,
          ioIsFetching: false,
          singleIoDidFetch: false
        })
      });

    case types.FETCH_PORTFOLIO:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          deals: action.payload.data
        })
      });

    case types.FETCH_CAPTABLE:
      return baseReducer(action, state, actions.FETCH, {
        success: () => ({
          ...state._data,
          captable: action.payload.data
        })
      });

    case types.POST_IO_DOCS:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const { _data: { io = {} } = {} } = state;
          const attachments = [...io.attachments, ...action.payload.data];
          return {
            ...state._data,
            io: {
              ...io,
              attachments
            }
          };
        }
      });

    case types.UPDATE_IO:
      return baseReducer(action, state, actions.UPDATE, {
        start: () => {
          const { data } = action.payload;

          return {
            ...state._data,
            io: { ...(state._data?.io || {}), ...data }
          };
        },
        success: () => {
          const prevIos = (state._data && state._data.ios) || [];
          const { cuid } = action.payload.data;
          const { currency } = (state._data && state._data.io) || {};

          return {
            ...state._data,
            io: { ...state._data.io, ...action.payload.data, currency },
            ios: updateObjectInArray(prevIos, cuid, action.payload.data),
            ioIsUpdating: null
          };
        },
        fail: () => ({ ...state._data, ioIsUpdating: null })
      });

    case types.POST_DOC_VISIT:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const io = (state._data && state._data.io) || {};
          const attachments = io.attachments;
          const docIndex =
            attachments &&
            attachments.findIndex(
              el => el.cuid === action.payload.data.attachmentId
            );
          attachments[docIndex] = {
            ...attachments[docIndex],
            visits: attachments[docIndex].visits + 1
          }; // INCREMENT BY ONE AUTOMATICALLY
          return {
            ...state._data,
            io: {
              ...io,
              attachments: [...attachments]
            }
          };
        }
      });

    case types.POST_ATTACHMENT:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const currIo = (state._data && state._data.io) || {};
          const attachments = [
            ...(currIo.attachments || {}),
            action.payload.data
          ];

          return {
            ...state._data,
            io: {
              ...currIo,
              attachments
            }
          };
        }
      });

    case types.BULK_POST_ATTACHMENTS:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const currIo = (state._data && state._data.io) || {};
          const attachments = [
            ...(currIo.attachments || {}),
            ...action.payload.data
          ];
          return { ...state._data, io: { ...currIo, attachments } };
        }
      });

    case types.CREATE_DEAL_BULK_MEMBERS:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const prevIos = state._data && state._data.ios;
          return {
            ...state._data,
            io: action.payload.data,
            ios: [...prevIos, action.payload.data]
          };
        }
      });

    case types.UPDATE_DEAL_BULK_MEMBERS:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const prevIos = state._data && state._data.ios;
          const { cuid } = action.payload.data;
          return {
            ...state._data,
            io: action.payload.data,
            ios: updateObjectInArray(prevIos, cuid, action.payload.data)
          };
        }
      });

    case types.DELETE_IO_ATTACHMENT:
      return baseReducer(action, state, actions.DELETE, {
        success: () => {
          const currIo = (state._data && state._data.io) || {};
          const { cuid } = action.payload.data;
          const currAttachments = currIo.attachments || [];

          return {
            ...state._data,
            io: {
              ...currIo,
              attachments: currAttachments.filter(
                ({ cuid: localCuid }) => localCuid !== cuid
              )
            }
          };
        }
      });

    case types.UPDATE_ATTACHMENT:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { deletedAttachCuid, ...newAttach } = action.payload.data || {};

          const { attachments: currAttachments = [], ...currIo } =
            (state._data && state._data.io) || {};

          const attachments = [
            ...currAttachments.filter(({ cuid }) => cuid !== deletedAttachCuid),
            newAttach
          ];
          return { ...state._data, io: { ...currIo, attachments } };
        }
      });

    case types.UPDATE_IO_CLOSING_STATUS:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const currIo = (state._data && state._data.io) || {};
          const { cuid, closed } = action.payload.data;

          const currIos = (state._data && state._data.ios) || [];
          const { deals: { iosWhereUserIsAM = [], ...deals } = {} } =
            state._data || {};

          return {
            ...state._data,
            ios: currIos.map(item => {
              if (item.cuid === cuid) {
                return {
                  ...item,
                  closed
                };
              }

              return item;
            }),
            io: { ...currIo, closed, closedByFounder: true },
            deals: {
              ...deals,
              iosWhereUserIsAM: iosWhereUserIsAM.map(item => {
                if (item.cuid === cuid) {
                  return {
                    ...item,
                    closed
                  };
                }

                return item;
              })
            }
          };
        }
      });

    case types.UPDATE_AND_CLOSE_IO:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { data = {} } = action.payload;
          const { io = {} } = state._data || {};

          return {
            ...state._data,
            io: { ...io, ...data, currency: io.currency }
          };
        }
      });

    case types.PATCH_ATTACHMENT:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const currIo = (state._data && state._data.io) || {};
          const currAttachments = currIo.attachments || [];
          const { attachmentId, downloadable } = action.payload.data;

          return {
            ...state._data,
            io: {
              ...currIo,
              attachments: currAttachments.map(item => {
                const { cuid } = item;

                if (attachmentId === cuid) {
                  return {
                    ...item,
                    downloadable
                  };
                }

                return item;
              }),
              isAttachmentUpdate: true
            }
          };
        }
      });

    case types.RESET_IA_AND_SHA:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const currIo = (state._data && state._data.io) || {};

          return {
            ...state._data,
            io: {
              ...currIo,
              iaAndSha: {},
              subscriptionFinished: false,
              closed: 0.1
            }
          };
        }
      });
    case types.CONFIRM_TRANSFER:
      return baseReducer(action, state, actions.CREATE, {
        success: () => {
          const confirmTransferTimestamp = action.payload.data;
          return {
            ...state._data,
            io: {
              ...state._data.io,
              confirmTransferTimestamp
            }
          };
        }
      });
    default:
      return state;
  }
};

export default reducer;
