import axios from "./apiConfig";

export const getNotifsHistory = params =>
  axios.get("push-notifications/history", params);

export const postNotifsSubs = data =>
  axios.post("push-notifications/subscriptions", data);

export const deleteNotifsSubs = () =>
  axios.delete(`push-notifications/subscriptions`);

export const pushNotifsStatus = ({ cuid, ...data }) =>
  axios.put(`/push-notifications/${cuid}/status`, { ...data });
