import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    userRole:
      (state.entities._data &&
        state.entities._data.entity &&
        state.entities._data.entity.userRole) ||
      ''
  };
};

const withAccessRoles = (roles) => (Target) => {
  const accessHoc = ({ userRole, ...props }) => {
    if (roles.includes(userRole)) {
      return <Target {...props} />;
    }

    return null;
  };

  return connect(mapStateToProps)(accessHoc);
};

export default withAccessRoles;
