import { useGetColumnSearchProps } from "#helpers/hooks/table";

export const listViewColumns = ({ sortedInfo }) => [
  {
    title: "First name",
    dataIndex: "firstName",
    key: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    sortOrder: sortedInfo.columnKey === "firstName" && sortedInfo.order,
    ...useGetColumnSearchProps({ dataIndex: "firstName" })
  },
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName",
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    sortOrder: sortedInfo.columnKey === "lastName" && sortedInfo.order,
    ...useGetColumnSearchProps({ dataIndex: "lastName" })
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
    sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
    ...useGetColumnSearchProps({ dataIndex: "email" })
  }
];
