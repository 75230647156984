import PropTypes from "prop-types";
import { Input } from "antd";
import Tooltip from "../Tooltip";
import Fees from "./components/Fees/";
import DateInput from "./components/Date";
import InputNumber from "./components/InputNumber";

export default function InputContainer({
  error,
  name,
  onFocus,
  onBlur,
  onChange,
  value,
  details,
  toolTip,
  wrapperId,
  wrapperClassname = "",
  type = "text",
  ...props
}) {
  const args = {
    name,
    value,
    type,
    className: "input",
    onChange,
    onFocus,
    onBlur,
    details,
    placeholder: details,
    size: "large",
    ...props
  };

  const renderInput = () => {
    if (type === "search") {
      return <Input.Search placeholder={details} {...props} />;
    } else if (type === "number") {
      return <InputNumber {...args} />;
    } else if (type === "password") {
      return <Input.Password {...args} />;
    } else if (type === "textarea") {
      return (
        <div
          id={wrapperId}
          className={`leva-input-wrapper ${wrapperClassname}`}
        >
          <Input.TextArea {...args} />
        </div>
      );
    } else if (type === "custom_date_input") {
      return <DateInput {...args} />;
    }

    return (
      <div id={wrapperId} className={`leva-input-wrapper ${wrapperClassname}`}>
        <Input {...args} />
      </div>
    );
  };
  return toolTip ? (
    <Tooltip title={toolTip} placement="bottom-start">
      {renderInput()}
    </Tooltip>
  ) : (
    renderInput()
  );
}

export { Fees };

InputContainer.propTypes = {
  toolTip: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  details: PropTypes.string,
  multiline: PropTypes.bool,
  longDesc: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  type: PropTypes.string
};
