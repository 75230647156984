import InputRenderer from "../../../Shared/InputRenderer";
import { TableWrapper } from "./TableWrapper";

const CustomInputRenderer = handlers => props => {
  const { type } = props;
  if (type === "table_list") {
    return <TableWrapper handlers={handlers} {...props} />;
  }
  return <InputRenderer {...props} />;
};

export default CustomInputRenderer;
