import AlertWrapper from '../../Alert';

const ErrorBox = ({ error, errorInfo }) => {
  const message = (
    <a href="/dashboard">
      Something has gone wrong! We have reported this issue to our technical
      team and we will get back to you shortly. In the meantime, click this
      message to dismiss and go back to the dashboard.
    </a>
  );

  return <AlertWrapper message={message} type="error" showIcon visible />;
};

export default ErrorBox;
