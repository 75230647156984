import { AvatarUploader } from "../../Shared/Avatar";
import DraggerUploader from "../../Shared/Uploader/DraggerUploader";

import InputRenderer from "../../Shared/InputRenderer";

const CustomInputRenderer = props => {
  if (props.type === "avatar_uploader") {
    return <AvatarUploader {...props} />;
  } else if (props.type === "dragger_uploader") {
    return <DraggerUploader {...props} />;
  }

  return <InputRenderer {...props} />;
};

export default CustomInputRenderer;
