import { basicOption } from '#helpers/general';
import { formatNumber } from '#helpers/number_format';

const meetings = [
  {
    title: 'How often shall the Board meet?',
    input: {
      type: 'select_btn',
      name: 'boardMeeting',
      options: [
        basicOption('Monthly', 'Monthly'),
        basicOption('Quarterly', 'Quarterly')
      ]
    }
  },

  {
    title: 'What is the notice period for the board meeting?',
    input: {
      type: 'text',
      name: 'boardMeetingPeriod',
      addonAfter: 'days',
      formatter: (value) => formatNumber(value)
    }
  }
];

export default meetings;
