import * as Yup from "yup";
import { returnError } from "./common";

const ProfileSchema = {
  firstName: Yup.string().required(returnError("required")),
  lastName: Yup.string().required(returnError("required")),
  sex: Yup.string().required(returnError("required")),
  email: Yup.string().required(returnError("required")),
  street: Yup.string().required(returnError("required")),
  city: Yup.string().required(returnError("required")),
  country: Yup.string().required(returnError("required")),
  zipCode: Yup.string().required(returnError("required"))
};

export default Yup.object().shape(ProfileSchema);
