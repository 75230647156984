import { equityTs } from "#constants/io/equityPool";
import { inputNumberFormatter } from "#helpers/general";
import { convertibleCols } from "./tableCols";
import { confirmationPrompt } from "../../../common/prompts";

const esopFields = (values, handlers) => {
  const {
    [equityTs.ESOP_SHARES_NUMBER]: esopShares = 0,
    sharesNumber = 0
  } = values;

  const maxPercentage = Number((esopShares * 100) / sharesNumber).toFixed(2);
  values[equityTs.ESOP_PERCENT_CALCULATION] = maxPercentage;
  return [
    {
      title: "This is the number of shares you have allocated to the ESOP",
      input: {
        type: "number",
        name: equityTs.ESOP_SHARES_NUMBER,
        disabled: true,
        ...inputNumberFormatter("")
      }
    },
    {
      title:
        "Shall the relation ESOP / share capital of the company be calculated on a fully diluted share capital of the company?",
      input: {
        ...confirmationPrompt,
        name: "esopPercentCalculation"
      }
    },
    ...(values.esopPercentCalculation === "yes"
      ? [
          {
            title: "Do you want to include the effects of your ESOP?",
            input: {
              name: equityTs.INCLUDE_ESOP_EFFECTS,
              ...confirmationPrompt
            }
          },
          {
            title: "Do you want to include the effects of your convertibles?",
            input: {
              name: equityTs.INCLUDE_CLA_EFFECTS,
              ...confirmationPrompt
            }
          },
          values[equityTs.INCLUDE_CLA_EFFECTS] === "yes" && {
            input: {
              type: "table_list",
              name: "esopFormattedConvertibles",
              cols: convertibleCols(
                values,
                handlers,
                "esopFormattedConvertibles"
              )
            }
          }
        ]
      : []),
    {
      title:
        "This is the maximum percentage of shares you have allocated to the ESOP in comparison to your outstanding shares",
      input: {
        type: "number",
        name: equityTs.ESOP_PERCENT_CALCULATION,
        value: maxPercentage,
        formatter: value => (value ? `${value}%` : 0),
        disabled: true
      }
    }
  ];
};

export default esopFields;
