import { DatePicker } from "antd";
import moment from "moment";

export default function index({ name, onChange, value, ...props }) {
  const changeHandler = onChange(name);
  const finalValue = value && moment(value, "YYYY-MM-DD");
  return (
    <DatePicker
      style={{ width: "100%" }}
      size="large"
      onChange={changeHandler}
      value={finalValue}
      {...props}
    />
  );
}
