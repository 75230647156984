import Avatar from 'antd/es/avatar';
import StackedAvatar from './components/StackedAvatar';
import NameAvatar from './components/NameAvatar';
import AvatarUploader from './components/AvatarUploader';

const AvatarWrapper = ({ color = '', bordered, children, ...props }) => {
  const finalClassname = `root-avatar-wrapper ${color} ${
    bordered ? 'bordered' : ''
  }`;

  return (
    <Avatar className={finalClassname} {...props}>
      {children}
    </Avatar>
  );
};

export default AvatarWrapper;

export { StackedAvatar, NameAvatar, AvatarUploader };
