import { formatArrayKeys } from "#helpers/arrayFuncs";
import { basicOption, inputNumberFormatter } from "#helpers/general";
import { roles } from "#constants/roles";
import { dealRounds } from "#constants/deal";
import ioTypes from "#constants/io/ioTypes";
import { termsheetList } from "./ts";
import TooltipWrapper from "../../../../../Shared/Tooltip";
import { InformationIconViolet } from "../../../../../Shared/Icons";
import { confirmationPrompt } from "../../../../common/prompts";

/* QUESTIONNAIRE'S STEPS */

export const formItems = ({
  termsheetFile,
  currencies,
  investorsList,
  userRole,
  compliant = false,
  values: { founderInvite, ...values },
  editingMode,
  syndicateId = ""
}) => {
  const isAm = userRole === roles.ASSET_MANAGER;
  const isFounder = userRole === roles.FOUNDER;
  const { shortName: selectedCurrency } =
    currencies.find(({ cuid }) => cuid === values.currency) || {};

  const dealStructureOptions = [
    basicOption(ioTypes.POOLED_EQUITY, "Equity financing", {
      disabled: compliant
    }),
    basicOption(ioTypes.DEBT_FINANCING, "Convertible loan", {
      disabled: compliant
    }),
    basicOption(ioTypes.SAFE, "SAFE", {
      disabled: compliant
    }),
    basicOption(ioTypes.KISS, "KISS", {
      disabled: compliant
    }),
    basicOption(ioTypes.BSA_AIR, "BSA AIR", {
      disabled: compliant
    })
  ];

  if (compliant) {
    dealStructureOptions.push(
      basicOption(ioTypes.CONVERSION_POOL, "Conversion pool")
    );
  }

  const syndicateFields = [
    {
      id: 1,
      title: "Name of the syndicate",
      input: {
        type: "text",
        addonAfter: "syndicate",
        name: "syndicateName"
      }
    }
    // {
    //   id: 2,
    //   title: "Max numbers of investors",
    //   input: {
    //     type: "number",
    //     name: "syndicateCapped",
    //     placeholder: "0 means uncapped syndicate"
    //   }
    // },
    // {
    //   id: 3,
    //   title: "Public access",
    //   input: {
    //     type: "select_btn",
    //     name: "syndicatePublic",
    //     options: [basicOption("yes", "Yes"), basicOption("no", "No")]
    //   }
    // }
  ];

  const step1 = [
    isFounder && !termsheetFile
      ? {
          id: 2,
          sectionId: 1,
          disableSubmit: true,
          items: [
            {
              input: {
                type: "radio",
                name: "termsheetFile",
                options: [
                  {
                    value: "A",
                    label: "Upload the document",
                    type: "button"
                  },
                  {
                    value: "B",
                    label: "Set up the document with Leva",
                    type: "button"
                  }
                ]
              }
            }
          ]
        }
      : null,
    ...(termsheetFile === "B" ? termsheetList(values) : [])
  ];

  const dealInfo = [
    {
      id: 1,
      title: "Name of the deal",
      details: "This name will be displayed on the company overview.",
      input: {
        type: "text",
        name: "deal_name",
        details: "Super Growth – Series A",
        required: true
      }
    },
    {
      id: 2,
      title: "Deal Logo",
      input: {
        type: "upload",
        name: "previewImage",
        accept: ".jpg,.png,.jpeg",
        multiple: false
      }
    },
    {
      id: 3,
      title: "Is this investment only for qualified investors?",
      input: {
        name: "qualifiedInvestorsOnly",
        required: true,
        ...confirmationPrompt
      }
    }
  ];

  const dealStructure = [
    {
      id: 1,
      title: "Define the current financing round",
      input: {
        type: "select_btn",
        name: "investmentLevel",
        options: Object.keys(dealRounds).map(key =>
          basicOption(key, dealRounds[key])
        ),
        required: true
      }
    },
    values.investmentLevel === "other"
      ? {
          title: "Describe the round here",
          input: {
            type: "text",
            name: "investmentLevel_desc",
            required: true
          }
        }
      : null,
    {
      id: 2,
      title: "Define the deal structure",
      details:
        "Let your investors know how the deal is structured and what they will be investing in.",
      input: {
        type: "select_btn",
        name: "dealType",
        options: dealStructureOptions,
        required: true
      }
    },
    {
      id: 3,
      title: "Currency",
      input: {
        type: "select_btn",
        name: "currency",
        options: formatArrayKeys(currencies, "cuid", "shortName"),
        required: true
      }
    }
  ];

  const companyValuationOpts = [
    {
      label: "Valuation range",
      value: "val_range"
    },
    {
      label: "Fixed/final valuation",
      value: "fixed_val"
    },
    {
      label: "Valuation unclear",
      value: "valuation_unclear"
    }
  ];

  const valuation = [
    {
      title: "Share price",
      details: (
        <span>
          The share price is required to calculate the precise number of shares
          each co-investor will get. You may add the share price also at a later
          stage.
          <br />
          <strong>
            If you are investing in a convertible loan, please just input 1.00.
            If you don’t know the share price yet, you may insert it at a later
            stage.
          </strong>
        </span>
      ),
      input: {
        name: "sharesPrice",
        type: "number",
        min: 0,
        ...inputNumberFormatter(selectedCurrency),
        strict: false,
        disabled: values?.dealType !== ioTypes.POOLED_EQUITY
      }
    },
    {
      id: 1,
      title: "Pre-money company valuation",
      details: (
        <span>
          We know that the valuation might change over the course of the
          negotiations. You can always come back and edit this step in a second
          moment.
          <br />
          If you don't know the valuation yet or you are investing in a
          convertible loan, just select "Valuation unclear". If the valuation is
          not yet defined, you can also select a range. By doing so, you will
          help your investors to assess their potential ownership.
        </span>
      ),
      input: {
        type: "select_btn",
        name: "companyValuation",
        options: companyValuationOpts
      }
    },
    ...(values.companyValuation === companyValuationOpts[0].value
      ? [
          {
            title: "The minimum value of the pre-money valuation",
            input: {
              type: "number",
              name: "min_val",
              ...inputNumberFormatter(selectedCurrency),
              min: 0,
              strict: false
            }
          },
          {
            title: "The maximum value of the pre-money valuation",
            input: {
              type: "number",
              name: "max_val",
              ...inputNumberFormatter(selectedCurrency),
              min: 0,
              strict: false
            }
          }
        ]
      : []),
    values.companyValuation === companyValuationOpts[1].value
      ? {
          title: " Define the current pre money valuation",
          className: "_fullwidth",
          input: {
            type: "number",
            name: "companyValuation_num",
            ...inputNumberFormatter(selectedCurrency),
            min: 0,
            strict: false
          }
        }
      : null,
    {
      id: 3,
      title: "The amount that you wish to be raised",
      input: {
        type: "number",
        name: "fundingGoal",
        ...inputNumberFormatter(selectedCurrency),
        required: true,
        min: 0,
        strict: false
      }
    },
    {
      id: 4,
      title: "Minimum ticket per investor to co-invest",
      input: {
        type: "number",
        name: "minimal",
        ...inputNumberFormatter(selectedCurrency),
        required: true,
        min: 0,
        strict: false
      }
    }
  ];

  const termsDocs = {
    id: 5,
    sectionId: 4,
    items: [
      !isAm
        ? {
            id: 2,
            title: "Upload deal documents",
            details:
              "Share all documents which help your investors assess the investment opportunity (Word, PDF, Excel and Powerpoint).",
            input: {
              type: "drag_upload",
              name: "termsheet",
              accept: ".pdf, .xls, .doc, .docx"
            }
          }
        : null,
      {
        id: 3,
        input: {
          type: "switch",
          name: "tos_agreement",
          label: (
            <div className="term-container">
              I have read, understood and accept the{" "}
              <a
                href="/doc_viewer?doc=/docs/termsofsyndicate_annex 2.pdf"
                rel="noopener noreferrer"
                target="_blank"
                className="spaced"
              >
                Terms of Syndicate{" "}
              </a>
              .
              <TooltipWrapper title="The remuneration section is just for illustrational purposes. The final version will be generated after clicking “Finish” (or similar boxes) and will contain the fees you defined in the previous steps.">
                <div>
                  <InformationIconViolet />
                </div>
              </TooltipWrapper>
            </div>
          )
        }
      },
      {
        id: 4,
        input: {
          type: "switch",
          name: "guidelines_agreement",
          label: (
            <span className="guidelines-container">
              I have read, understood and accept the{" "}
              <a
                href="/doc_viewer?doc=/docs/managerGuidelines.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                Deal Manager Guidelines
              </a>
              .
            </span>
          )
        }
      },
      {
        id: 5,
        input: {
          type: "switch",
          name: "provider_agreement",
          label:
            "I agree to be a partner and the service provider of the syndicate."
        }
      }
    ].map((item, index) => (item ? { ...item, id: index + 1 } : null)) // indexed
  };
  const step2 = [
    editingMode || syndicateId
      ? null
      : { id: 4, sectionId: 1, items: syndicateFields },
    {
      id: 1,
      sectionId: 2,
      items: dealInfo
    },
    {
      id: 2,
      sectionId: 2,
      items: dealStructure
        .filter(Boolean)
        .map((item, index) => ({ ...item, id: index + 1 }))
    },
    {
      id: 3,
      sectionId: 2,
      items: valuation
        .filter(Boolean)
        .map((item, index) => ({ ...item, id: index + 1 }))
    },
    userRole === roles.FOUNDER
      ? {
          id: 4,
          sectionId: 1,
          items: [
            {
              id: 5,
              title: "Deal Manager",
              input: {
                type: "select",
                name: "leadInvestor",
                options: investorsList
              }
            }
          ]
        }
      : null,
    {
      id: 4,
      sectionId: 3,
      stepTitle:
        'This step is all about financials and fees. With Leva you can decide how much you get paid by your investors for sourcing and managing deals on a deal-by-deal basis. Decimals can be added with a "." (example 2.5%).',
      items: [
        {
          input: {
            type: "fees"
          }
        }
      ]
    },
    termsDocs
  ];

  return [...step1, ...step2];
};
