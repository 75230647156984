export default () => {
  return (
    <svg
      width="346"
      height="447"
      viewBox="0 0 346 447"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 447H322C327.523 447 332 442.523 332 437V80.3263C332 77.5658 330.859 74.928 328.847 73.0379L268.886 16.7115C267.032 14.9697 264.584 14 262.04 14H10C4.47715 14 0 18.4772 0 24V437C0 442.523 4.47716 447 10 447Z"
        fill="#2B5797"
      />
      <path
        d="M269 88H322.052C330.933 88 335.407 77.2854 329.163 70.9694L276.111 17.308C269.828 10.9529 259 15.402 259 24.3386V78C259 83.5229 263.477 88 269 88Z"
        fill="#203F6E"
      />
      <path
        d="M51.834 261V197.016H72.4443C78.0986 197.016 83.1816 198.305 87.6934 200.883C92.2051 203.432 95.7207 207.05 98.2402 211.737C100.789 216.396 102.078 221.625 102.107 227.426V230.37C102.107 236.229 100.862 241.488 98.3721 246.146C95.9111 250.775 92.4248 254.408 87.9131 257.045C83.4307 259.652 78.4209 260.971 72.8838 261H51.834ZM67.2588 208.925V249.135H72.6201C77.0439 249.135 80.4424 247.567 82.8154 244.433C85.1885 241.269 86.375 236.581 86.375 230.37V227.602C86.375 221.42 85.1885 216.762 82.8154 213.627C80.4424 210.492 76.9854 208.925 72.4443 208.925H67.2588Z"
        fill="white"
      />
      <path
        d="M164.202 230.326C164.202 236.566 163.045 242.089 160.73 246.894C158.416 251.698 155.12 255.404 150.843 258.012C146.595 260.59 141.746 261.879 136.297 261.879C130.848 261.879 126.014 260.634 121.795 258.144C117.576 255.624 114.28 252.035 111.907 247.377C109.563 242.719 108.333 237.372 108.216 231.337V227.733C108.216 221.464 109.358 215.941 111.644 211.166C113.958 206.361 117.254 202.655 121.531 200.048C125.838 197.44 130.73 196.137 136.209 196.137C141.629 196.137 146.463 197.426 150.711 200.004C154.959 202.582 158.255 206.259 160.599 211.034C162.972 215.78 164.173 221.229 164.202 227.382V230.326ZM148.514 227.646C148.514 221.288 147.444 216.469 145.306 213.188C143.196 209.877 140.164 208.222 136.209 208.222C128.475 208.222 124.388 214.022 123.948 225.624L123.904 230.326C123.904 236.596 124.944 241.415 127.024 244.784C129.104 248.153 132.195 249.838 136.297 249.838C140.193 249.838 143.196 248.183 145.306 244.872C147.415 241.562 148.484 236.801 148.514 230.59V227.646Z"
        fill="white"
      />
      <path
        d="M223.924 239.335C223.719 243.788 222.518 247.729 220.32 251.156C218.123 254.555 215.032 257.191 211.048 259.066C207.093 260.941 202.566 261.879 197.469 261.879C189.061 261.879 182.439 259.14 177.605 253.661C172.771 248.183 170.354 240.448 170.354 230.458V227.294C170.354 221.024 171.438 215.546 173.606 210.858C175.804 206.142 178.953 202.509 183.055 199.96C187.156 197.382 191.902 196.093 197.293 196.093C205.057 196.093 211.297 198.144 216.014 202.245C220.73 206.317 223.411 211.942 224.056 219.12H208.675C208.558 215.224 207.576 212.426 205.73 210.727C203.885 209.027 201.072 208.178 197.293 208.178C193.455 208.178 190.643 209.613 188.855 212.484C187.068 215.355 186.131 219.94 186.043 226.239V230.766C186.043 237.592 186.893 242.47 188.592 245.399C190.32 248.329 193.279 249.794 197.469 249.794C201.014 249.794 203.724 248.959 205.599 247.289C207.474 245.619 208.47 242.968 208.587 239.335H223.924Z"
        fill="white"
      />
      <path
        d="M255.081 218.285L265.54 197.016H283.162L265.276 228.744L283.646 261H265.848L255.081 239.335L244.314 261H226.561L244.886 228.744L227.044 197.016H244.622L255.081 218.285Z"
        fill="white"
      />
    </svg>
  );
};
