import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import AddItemsModal from "../AddItems/Presentational";
import { Button } from "../../../Shared/Button";

const SharesListInput = ({
  modalTitle,
  fields,
  formikProps,
  name,
  value,
  actionButtonTitle,
  itemRenderer,
  triggerSnackbar,
  isEditingUser,
  entityShares,
  formattedConvertibles,
  existingShares = []
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setFieldValue } = formikProps;
  const [editingType, setEditingType] = useState();

  const handleModalVisibility = open => () => {
    setModalOpen(open);
    setEditingType(null);
  };

  const handleEdit = type => () => {
    const editingValues = type;
    if (editingValues.paidUpAmount) {
      editingValues.sharesPrice = editingValues.sharesPriceOther
        ? "Other"
        : "Nominal value";
      editingValues.paidUp = editingValues.paidUpShares ? "no" : "yes";
      editingValues.editing = true;
      editingValues.maxNumber = editingValues.number;
    }
    // check if it is a convertible. only convertibles have the paymentDate field
    if (editingValues.paymentDate) {
      editingValues.editing = true;
    }

    setEditingType(editingValues);

    setModalOpen(true);
  };

  const handleAdd = () => () => {
    setModalOpen(true);
    setEditingType(null);
  };

  const handleDelete = (idx, _idx) => () => {
    if (idx !== undefined) {
      setFieldValue(
        name,
        value.filter(({ id }) => id !== idx)
      );
    } else {
      setFieldValue(
        name,
        value.filter(({ _id }) => _id !== _idx)
      );
    }
  };

  const handleNotification = (title, isEditing) => {
    if (!isEditingUser) {
      const getTitle = () => {
        if (title === "founderShares") {
          return "share";
        } else if (title === "convertibles") {
          return "convertible";
        } else if (title === "beneficialOwners") {
          return "beneficial owner";
        } else if (title === "representatives") {
          return "representative";
        }

        return title;
      };

      const finalTitle = getTitle();

      triggerSnackbar({
        variant: "success",
        message: `Successfully ${
          isEditing ? "updated " : "added "
        } a ${finalTitle}`,
        placement: "bottomLeft",
        open: true
      });
    }
  };
  return (
    <Fragment>
      {!!modalOpen && (
        <AddItemsModal
          onCancel={handleModalVisibility(false)}
          visible={!!modalOpen}
          name={name}
          setFieldValue={setFieldValue}
          value={value}
          fields={fields}
          modalTitle={modalTitle}
          triggerSnackbar={triggerSnackbar}
          isEditing={!!editingType}
          entityShares={entityShares}
          formattedConvertibles={formattedConvertibles}
          handleNotification={handleNotification}
          initValues={editingType}
          existingShares={existingShares}
        />
      )}
      <List
        dataSource={value}
        renderItem={({ _id, id, ...item }) => (
          <div>
            <List.Item
              key={id}
              actions={[
                <Button
                  icon="edit"
                  onClick={handleEdit({ _id, id, ...item })}
                />,
                <Button icon="close" onClick={handleDelete(id, _id)} />
              ]}
            >
              {itemRenderer(item)}
            </List.Item>
          </div>
        )}
      />
      <Button label={actionButtonTitle} icon="plus" onClick={handleAdd()} />
    </Fragment>
  );
};

SharesListInput.propTypes = {
  modalTitle: PropTypes.string,
  fields: PropTypes.array,
  formikProps: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  actionButtonTitle: PropTypes.string,
  itemRenderer: PropTypes.any,
  triggerSnackbar: PropTypes.func,
  isEditingUser: PropTypes.bool,
  entityShares: PropTypes.number,
  formattedConvertibles: PropTypes.array,
  existingShares: PropTypes.array
};

export default SharesListInput;
