import TableList from "../../../../Forms/common/Inputs/TableList";
import { convertibleCols } from "../../../../Forms/CLA/form/fields/tableCols";

export const TableWrapper = ({ name, handlers, formikProps, ...props }) => {
  const { setFieldValue, values } = formikProps;

  const finalHandlers = handlers({ setFieldValue, values });
  const columns = convertibleCols(values, finalHandlers, name);

  return <TableList cols={columns} {...props} />;
};
