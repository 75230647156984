import { Empty } from "antd";
import SpinContainer from "../../SpinContainer";

const withContentFallback = ({
  empty,
  success,
  loading,
  customizedEmptyMsg,
  customizedIcon
}) => Target => props => {
  if (loading) {
    return <SpinContainer />;
  }

  if (empty || success) {
    return (
      <Empty
        className="custom-empty-wrapper"
        image={customizedIcon}
        description={customizedEmptyMsg || "No content to display right now"}
      />
    );
  }

  return <Target {...props} />;
};

export default withContentFallback;
