import Axios from 'axios';

import storageKeys from '../src/constants/storage';
import { saveItem, getItem } from '../src/utils/storage';
import { syncUserDetails } from '../core/modules/users/actions/update';

const axios = Axios.create({
  baseURL: `${process.env.API_HOST}api/v2/`,
  headers: {
    common: {
      Authorization: `Bearer ${getItem(storageKeys.JWT)}`
    }
  },

  transformResponse: Axios.defaults.transformResponse.concat((data) => {
    if (data && data.code === 25001 && data.payload && data.payload.token) {
      const {
        payload: { token, confirmationLevel }
      } = data;

      saveItem(storageKeys.JWT, token);
      syncUserDetails({ token, confirmationLevel });
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    return data;
  })
});

export default axios;
