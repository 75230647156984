import i18next from 'i18next';
import { reactI18nextModule } from 'react-i18next';

// Import language.json files here
import en from './locales/en/translations.json';
import it from './locales/it/translations.json';
import de from './locales/de/translations.json';
import fr from './locales/fr/translations.json';

const resources = {
  en,
  it,
  de,
  fr
};

i18next.languages = ['en', 'it', 'de', 'fr'];

i18next.use(reactI18nextModule).init({
  interpolation: {
    // React already does escaping
    escapeValue: false
  },
  lng: 'en',
  resources
});

export default i18next;
