import { Tag } from "antd";
import avatarColors from "#constants/avatarColors";
import Avatar from "../../../../Shared/Avatar";
import ioTypes from "#constants/io/ioTypes";

const renderTagData = status => {
  if (status === "progress") {
    return { indicator: "blue", text: "In progress" };
  } else if (status === "success") {
    return { indicator: "green", text: "Success" };
  }

  return { indicator: "red", text: "Failed" };
};

const renderAvatar = (text, fullWidth) => (
  <div className={`name-wrapper ${fullWidth ? "_fullWidth" : ""}`}>
    <Avatar color={avatarColors[Math.floor(Math.random() * 4)]}>
      {text && text[0]}
    </Avatar>
    <span className="name">{text}</span>
  </div>
);
const overviewTable = [
  {
    title: "Shareholder",
    dataIndex: "shareholder",
    render: text => renderAvatar(text)
  },
  {
    title: "Shares",
    dataIndex: "shares"
  },
  {
    title: "Shareholding",
    dataIndex: "shareholding"
  }
];

const sharesTable = [
  {
    title: "Name",
    dataIndex: "name"
  },
  {
    title: "Date",
    dataIndex: "date"
  },
  {
    title: "Capital invested",
    dataIndex: "amount"
  },
  {
    title: "Number of shares",
    dataIndex: "sharesNumber"
  },
  {
    title: "Ownership",
    dataIndex: "ownership"
  },
  {
    title: "Status",
    dataIndex: "status",
    render: text => {
      const data = renderTagData(text);
      return (
        <Tag className="tag-wrapper" color={data.indicator}>
          {data.text}
        </Tag>
      );
    }
  }
];

const ioShares = dealType => [
  {
    title: "Name",
    dataIndex: "name"
  },
  {
    title: "Capital invested",
    dataIndex: "amount"
  },
  {
    title: "Number of shares",
    dataIndex: "sharesNumber",
    render: val => {
      if (dealType === ioTypes.POOLED_EQUITY) return val;
      return "N/A";
    }
  },
  {
    title: "Ownership",
    dataIndex: "ownership",
    render: val => {
      if (dealType === ioTypes.POOLED_EQUITY) return val;
      return "N/A";
    }
  }
];

export { overviewTable, sharesTable, ioShares };
