import { start, success, fail } from "../../../utils/helper_actions";
import types from "../action_types";
import * as votingService from "../../../../api/votingService";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const postVotingAssembly = data => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_VOTING_ASSEMBLY);
      const response = await votingService.postVoting(data);
      success(dispatch, types.POST_VOTING_ASSEMBLY, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_VOTING_ASSEMBLY, {
        error: errorData
      });
    }
  };
};

export const postVotingResult = ({ cuid, data }) => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_VOTING_RESULT);
      const response = await votingService.postVotingResult(cuid, data);
      success(dispatch, types.POST_VOTING_RESULT, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_VOTING_RESULT, {
        error: errorData
      });
    }
  };
};

export const postVotingAttachment = data => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_VOTING_ATTACHMENT);
      const response = await votingService.postVotingAttachment(data);
      success(dispatch, types.POST_VOTING_ATTACHMENT, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_VOTING_ATTACHMENT, {
        error: errorData
      });
    }
  };
};

export const postPublicVoting = data => {
  return async dispatch => {
    try {
      start(dispatch, types.POST_PUBLIC_VOTING);
      const response = await votingService.postPublicVoting(data);
      success(dispatch, types.POST_PUBLIC_VOTING, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.POST_PUBLIC_VOTING, {
        error: errorData
      });
    }
  };
};
