export const closedValues = {
  PENDING: 0,
  TS_DONE: 0.1,
  SUBSCRIPTION_DONE: 0.2,
  IA_DONE: 0.3,
  SHA_DONE: 0.4,
  BOARD_REGULATIONS_DONE: 0.5,
  NOTARY_DONE: 0.6,
  CLA_DONE: 0.7,
  EXECUTED: 0.8,
  MONEY_WIRED_TO_TARGET: 0.9,
  CLOSED_BY_FOUNDER: 1,
  APPROVED_BY_LAWYERS: 2
};

export const closedLabels = {
  PENDING: "PENDING",
  EXECUTED: "EXECUTED",
  CLOSED_BY_FOUNDER: "CLOSED_BY_FOUNDER",
  APPROVED_BY_LAWYERS: "APPROVED_BY_LAWYERS"
};
