import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { withContentFallback } from "../../Shared/hocs";
import Form from "./Form/Presentational";
import InfoDocs from "./InfoDocs";
const VotingRightsWrapper = ({
  clearState,
  getSingleVoting,
  postVotingResult,
  buttonStates,
  history,
  assemblyColl,
  fetchSingleIo,
  syndicateName,
  getCurrentUser,
  phoneNumber,
  ioIsFetching,
  putGenerateVotingCodeInv,
  ...props
}) => {
  const { id } = useParams();
  const [step, setStep] = useState(1);

  useEffect(() => {
    clearState("voting");
    clearState("io");
    getCurrentUser();
    if (id) getSingleVoting({ cuid: id });
  }, []);

  useEffect(() => {
    if (assemblyColl?.data?.ioId) fetchSingleIo(assemblyColl?.data?.ioId);
  }, [assemblyColl?.data?.ioId]);

  useEffect(() => {
    if (buttonStates.success) {
      setTimeout(() => {
        history.push("/dashboard/voting");
      }, 3000);
    }
  }, [buttonStates.success]);

  const handleOkClick = () => {
    setStep(2);
  };

  const formWrapperArgs = {
    buttonStates,
    assemblyColl,
    phoneNumber,
    votingId: id,
    putGenerateVotingCodeInv,
    postVotingResult,
    ...props
  };
  const mainContent = () => {
    if (step === 1)
      return (
        <InfoDocs
          handleOkClick={handleOkClick}
          assemblyColl={assemblyColl}
          syndicateName={syndicateName}
        />
      );

    return <Form {...formWrapperArgs} />;
  };
  return withContentFallback({
    loading: ioIsFetching
  })(mainContent)();
};

export default VotingRightsWrapper;
