import { basicOption } from "#helpers/general";

export const sections = [
  {
    id: 1,
    title: "Please fulfill the questionnaire"
  },
  {
    id: 2,
    title: "M-Tan verification"
  }
];

export const mapFieldsList = (fields = [], values) => {
  const mapped = fields.map(({ question, cuid }, idx) => ({
    id: idx + 1,
    title: question,
    input: {
      name: cuid,
      type: "radio",
      options: [
        basicOption(1, "In favour"),
        basicOption(2, "Against"),
        basicOption(0, "Abstention")
      ]
    }
  }));
  const smsCode = [
    {
      id: 6,
      title: "To sign the voting digitally, click below to receive an SMS code on your phone",
      details: "We have your number on file from the KYC to make the process easy",
      className: "_width-30",
      input: {
        name: "send_sms_code",
        type: "btn"
      }
    },
    values?.send_sms_code === "sent" && {
      id: 7,
      title: "Please confirm the code you just received via SMS",
      details: "The M-IAN process will act as a signature on your side",
      className: "_width-30",
      input: {
        name: "verify_sms_code",
        type: "verify-input-btn"
      }
    }
  ];
  return [
    {
      sectionId: 1,
      items: mapped
    },
    {
      sectionId: 2,
      items: smsCode
    }
  ];
};
