export const normalizeCollection = (collection, pairs) =>
  collection.map(item => {
    const finalPairs = typeof pairs === "function" ? pairs(item) : pairs;
    return {
      ...item,
      ...Object.keys(finalPairs).reduce(
        (acc, key) => ({
          ...acc,
          [key]: item[finalPairs[key]] || finalPairs[key]
        }),
        {}
      )
    };
  });
