import i18n from "../../../../i18n";
import { roles } from "../../../../constants/roles";
import {
  CapTableIcon,
  CashControlIcon,
  DocumentIcon,
  FundraisingIcon,
  HrIcon,
  InvestmentIcon,
  MinorContractIcon,
  ModelingIcon,
  NewsIcon,
  TaxReturnsIcon,
  TimelineIcon,
  FoundersCaptableIcon,
  Invitations,
  InvestorInvestments,
  Voting
} from "../../Icons/Menu";
import { LevaCircleLogo } from "../../Icons";
import Settings from "../../../../assets/illustrations/settings.svg";
import {
  EquityIcon,
  ConvertibleIcon,
  SimulationIcon
} from "../../Icons/Fundraising";

const {
  INVESTOR,
  ASSET_MANAGER,
  AM_INVESTOR,
  FOUNDER,
  DIRECTOR,
  EMPLOYEE,
  LAWYER
} = roles;

export const userPanels = [
  {
    title: "Dashboard",
    roles: [ASSET_MANAGER],
    id: "/dashboard",
    link: "/dashboard"
  },
  {
    title: "Dashboard",
    roles: [FOUNDER],
    id: "/dashboard/generator",
    link: "/dashboard/generator"
  },
  {
    title: "Portfolio",
    roles: [ASSET_MANAGER],
    id: "/portfolio",
    link: "/portfolio"
  },
  {
    title: "Companies",
    roles: [ASSET_MANAGER],
    id: "entities",
    submenuCollection: "entities",
    submenuActions: [
      { label: "New company", link: "/create/entity", className: "new-company" }
      // { label: "New deal", link: "/create/deal", display: "hasEntities" }
    ]
  },
  {
    title: "Convertible",
    roles: [FOUNDER],
    id: "convertibles",
    submenuActions: [
      {
        label: "New TS and CLA",
        link: "/cla-ts/overview",
        tooltip: `If you wish to create a term sheet and a convertible loan agreement , please select "New TS and CLA"`
      },
      {
        label: "New CLA",
        link: "/cla/overview",
        tooltip: `If you wish to generate only a convertible loan agreement, please select "New CLA"`
      }
    ],
    submenuCollection: "opened_ios"
  },
  {
    title: "Closed convertibles",
    roles: [FOUNDER],
    id: "closed_convertibles",
    submenuCollection: "closed_ios"
  },
  {
    title: "Investors",
    link: "/dashboard/investors",
    roles: [FOUNDER, ASSET_MANAGER],
    icon: HrIcon,
    id: "/dashboard/investors",
    showIcon: true
  },
  {
    title: i18n.t("menu.investments"),
    link: "/dashboard/investments",
    icon: InvestorInvestments,
    roles: [INVESTOR, AM_INVESTOR],
    id: "/dashboard/investments",
    showIcon: true
  },
  {
    title: "Invitations",
    link: "/invitations",
    roles: [roles.AM_INVESTOR, roles.INVESTOR],
    id: "/invitations",
    icon: Invitations,
    showIcon: true
  },
  {
    title: "Tax reports",
    link: "/tax_reports",
    roles: [roles.AM_INVESTOR, roles.INVESTOR],
    id: "/tax_reports",
    icon: TimelineIcon,
    showIcon: true
  },
  {
    title: "Vote",
    roles: [INVESTOR, AM_INVESTOR],
    id: "/dashboard/voting",
    icon: Voting,
    showIcon: true,
    link: "/dashboard/voting"
  },
  {
    title: "Reports",
    roles: [FOUNDER, ASSET_MANAGER, LAWYER],
    id: "management",
    submenuCollection: {
      data: [
        {
          title: "Shares ",
          link: "/shares-management",
          roles: [roles.FOUNDER],
          id: "/shares-management",
          icon: CashControlIcon,
          showIcon: true
        },
        {
          title: "Convertibles ",
          link: "/cla/management",
          roles: [roles.FOUNDER],
          id: "/cla/management",
          icon: TimelineIcon,
          showIcon: true
        },
        {
          title: "Stakeholders ",
          link: "/stakeholders",
          roles: [roles.FOUNDER],
          id: "/stakeholders",
          icon: ModelingIcon,
          showIcon: true
        },
        {
          title: "Captable",
          link: "/founder_captable",
          roles: [],
          id: "/founder_captable",
          icon: FoundersCaptableIcon,
          showIcon: true
        },
        {
          title: "Entity Settings ",
          link: "/settings",
          roles: [roles.FOUNDER],
          id: "/settings",
          icon: Settings,
          showIcon: true
        },
        {
          title: i18n.t("menu.capTable"),
          link: "/dashboard/cap-table",
          icon: CapTableIcon,
          roles: [],
          id: "/dashboard/cap-table"
        },
        {
          title: "Voting reports",
          roles: [ASSET_MANAGER],
          id: "/dashboard/voting",
          icon: Voting,
          showIcon: true,
          link: "/dashboard/voting"
        },
        {
          title: "Tax reports",
          link: "/tax_reports",
          roles: [roles.ASSET_MANAGER],
          id: "/tax_reports",
          icon: TimelineIcon,
          showIcon: true
        }
      ],
      filter: role => ({ roles: collRoles }) => collRoles.includes(role)
    }
  },
  {
    title: "Need help?",
    roles: [FOUNDER, ASSET_MANAGER, INVESTOR, AM_INVESTOR, LAWYER],
    id: "assistance",
    submenuCollection: {
      data: [
        {
          title: "FAQs",
          link:
            "https://levafundraising.notion.site/Frequent-Asked-Questions-Leva-540ccaf75e934decbac7faaef47316e4",
          roles: [FOUNDER, ASSET_MANAGER, INVESTOR, AM_INVESTOR, LAWYER],
          id: "faq",
          showIcon: false,
          absoluteLink: true
        },
        {
          title: "Deal manager guide",
          link:
            "https://levafundraising.notion.site/Getting-Started-on-Leva-97d9ebbb7fa94ac68928f1a162ab40ff",
          roles: [ASSET_MANAGER],
          id: "dm_guideline",
          showIcon: false,
          absoluteLink: true
        },
        {
          title: "Investor guide",
          link:
            "https://levafundraising.notion.site/Getting-Started-on-Leva-8c610ed95f0944259cc00ecc0ab9616b",
          roles: [INVESTOR, AM_INVESTOR],
          id: "investors_guideline",
          showIcon: false,
          absoluteLink: true
        }
      ],
      filter: role => ({ roles: collRoles }) => collRoles.includes(role)
    }
  }
];

export const entitySubmenu = ({ toggleModal }) => [
  {
    title: i18n.t("menu.addEntity"),
    icon: "file-add",
    id: "addEntity",
    onClick: toggleModal("newEntityModal"),
    roles: [FOUNDER]
  },
  {
    title: "Entity settings",
    icon: "setting",
    id: "entitySettings",
    onClick: toggleModal("entitySettingsModal"),
    roles: [FOUNDER, ASSET_MANAGER]
  },
  {
    title: "Invite founder",
    icon: "user-add",
    id: "inviteFounders",
    onClick: toggleModal("inviteFounderModal"),
    roles: [ASSET_MANAGER]
  }
];

export const sharedItems = [
  // {
  //   title: i18n.t('menu.help'),
  //   icon: 'message',
  //   classname: 'help',
  //   action: '',
  //   link: '/help'
  // },
  {
    title: i18n.t("menu.logout"),
    icon: "logout",
    classname: "logout",
    action: "",
    link: "/logout"
  }
];
