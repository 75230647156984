import "babel-polyfill";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { hot } from "react-hot-loader";
import { store, persistor } from "./store/configureStore";
import "./styles/vendors/antd.less";
import "./styles/main.scss";
import i18next from "./i18n";
import App from "./components/App/Container";
import { register } from "./serviceWorker";

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter className="test">
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
