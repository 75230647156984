import * as fields from "./fields";
import { indexItem } from "../../common/prompts";

const {
  stakeholders: [shareholders, boardOfDirectors, auditor],
  warranties,
  confidentiality,
  successors,
  capTable,
  appendices: [schedules, appendices]
} = fields;

const getList = ({
  values = {},
  selectedConversionInvestor,
  inputHandlers = {}
} = {}) => {
  const indexedWarranties = warranties(values)
    .filter(Boolean)
    .map(indexItem);
  const indexedConfidentiality = confidentiality(values, inputHandlers)
    .filter(Boolean)
    .map(indexItem);
  const indexedSuccessors = successors.map(indexItem);
  const indexedSchedules = schedules.map(indexItem);
  const indexedAppendices = appendices(values)
    .filter(Boolean)
    .map(indexItem);
  const indexedAuditor = auditor(values).map(indexItem);
  const indexedShareholders = shareholders(
    values,
    inputHandlers,
    selectedConversionInvestor
  ).map(indexItem);
  const indexedBod = boardOfDirectors(values, inputHandlers).map(indexItem);
  const indexedCaptable = capTable(values)
    .filter(Boolean)
    .map(indexItem);

  const finalList = [
    {
      sectionId: 1,
      subSectionId: 1,
      items: indexedShareholders,
      stepTitle: (
        <span>
          These are the parties, who will sign the Investment and Subscription
          Agreement according to their role. Have you created all new Investor
          profiles? If not, please do so, by visiting the{" "}
          <a href="/stakeholders" target="_blank" rel="noopener noreferrer">
            Stakeholder's
          </a>{" "}
          page
        </span>
      )
    },
    {
      sectionId: 1,
      subSectionId: 2,
      items: indexedBod,
      stepTitle: (
        <span>
          These are your Board Members. Have you created all new Director
          profiles? If not, please do so, by visiting the{" "}
          <a href="/stakeholders" target="_blank" rel="noopener noreferrer">
            Stakeholder's
          </a>{" "}
          page.
        </span>
      )
    },
    {
      sectionId: 1,
      subSectionId: 3,
      items: indexedAuditor
    },
    {
      sectionId: 4,
      items: indexedWarranties,
      stepTitle: `Please consult the lawyer regarding the following questions.`
    },
    {
      sectionId: 5,
      items: indexedConfidentiality
    },
    {
      sectionId: 6,
      items: indexedSuccessors
    },
    {
      sectionId: 7,
      subSectionId: 7,
      items: indexedSchedules,
      stepTitle:
        "By uploading the documents you agree that we might share those documents and the data therein with third parties, especially with the Notary to provide our services in a compliant way."
    },
    {
      sectionId: 7,
      subSectionId: 8,
      items: indexedAppendices
    }
  ];

  return finalList;
};

export default getList;
