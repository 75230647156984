import { useEffect } from "react";
import { roles } from "#constants/roles";
import { withContentFallback } from "../../../../Shared/hocs";
import Title from "../../../../Shared/Title";
import { Button } from "../../../../Shared/Button";
import SharedTable from "../../../../Shared/SharedTable/Presentational";

const TaxReportPresentational = ({
  clearState,
  clearStateDataByKey,
  entityId,
  taxes = [],
  getTaxes,
  loading,
  userRole
}) => {
  const isInvestor =
    userRole === roles.INVESTOR || userRole === roles.AM_INVESTOR;

  useEffect(() => {
    clearState("taxes");
    clearStateDataByKey("taxes", "yearlyReport");
    getTaxes({ entity_id: entityId });
  }, []);

  const noDataContent = () => {
    if (isInvestor)
      return (
        <>
          <h3> Here you will find your tax reports</h3>
          <p>No tax report is available yet, please come back later</p>
          <span>
            The deal managers on Leva can insert the necessary data to generate
            your tax report from their account on Leva. If you need to prepare
            your tax declaration soon, please contact the deal managers of the
            syndicates you have invested in and ask them to update the tax data
            of the different target companies.
          </span>
        </>
      );
    else
      return (
        <>
          <h3>Here you will find your tax reports</h3>
          <p>
            You haven't filled any tax information yet, please come back later
          </p>
          <span>
            You can update the tax relevant data of each company by clicking on
            "Tax report" in the company page.
            <br /> Once you insert the data for the past fiscal year, a report
            will be automatically generated for you and your co-investors.
          </span>
        </>
      );
  };

  const columns = [
    {
      title: "Last Capital Increase",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "View tax report",
      dataIndex: "taxReport",
      key: "taxReport",
      render: (_, { year }) => (
        <>
          <Button
            label="View tax report"
            href={`/tax_reports/${year}?${entityId}&${userRole}`}
            hrefProps={{ target: "_blank", rel: "noopener noreferrer" }}
          />
          <Button
            buttonStyle={{ marginLeft: "5px" }}
            type="secondary"
            label="Download tax report"
            href={`/tax_reports/${year}?${entityId}&${userRole}&download`}
          />
        </>
      )
    }
  ];

  const data = taxes
    .map(({ year }) => ({ year }))
    .sort((a, b) => a.year - b.year);

  const mainContent = () => (
    <div className="tax-report-wrapper">
      <Title title="Tax reports" />
      <div className="tax-report-container">
        <SharedTable columns={columns} dataSource={data} />
      </div>
    </div>
  );
  return withContentFallback({
    customizedIcon: <div></div>,
    loading,
    empty: taxes.length === 0,
    customizedEmptyMsg: noDataContent()
  })(mainContent)();
};

export default TaxReportPresentational;
