import { useState } from "react";
import { List } from "antd";
import { Button } from "../../../../Shared/Button";
import InputContainer from "../../../../Shared/Input";

const QuestionsAdder = ({
  name,
  values: { assembly_questions: value = [] },
  setFieldValue,
  ...props
}) => {
  const [state, setState] = useState({ editMode: false });
  const handleEditMode = () =>
    setState({ editMode: !state.editMode, addingQuestion: true });
  const handleInputChange = ({ target: { name, value } }) =>
    setState({ ...state, [name]: value });

  const handleSubmit = () => {
    if (state.question) {
      if (state.idx + 1) {
        const newVal = value.map(item => {
          if (item.index === state.idx) {
            return { question: state.question, index: item.idx };
          }
          return item;
        });

        setState({ editMode: false });
        setFieldValue(name, newVal);
      } else {
        const newVal = [
          ...value,
          {
            question: state.question,
            index: value.length
          }
        ];

        setState({ editMode: false, idx: null });
        setFieldValue(name, newVal);
      }
    }
  };
  const handleEdit = (question, idx) => () => {
    setState({ ...state, editMode: true, question, idx });
  };

  const handleDelete = idx => () => {
    const newValue = value.filter(({ index }) => index !== idx);
    setFieldValue(name, newValue);
  };

  const InputBox = (
    <div className="txt-inpt-wrapper">
      <InputContainer
        type="text"
        name="question"
        placeholder="New question goes here.."
        onChange={handleInputChange}
        value={state.question}
      />
      <div className="btn-container">
        <div className="inner-container">
          <Button label="Cancel" type="danger" onClick={handleEditMode} />
          <Button
            label={
              state.editMode && !state.addingQuestion
                ? "Edit question"
                : "Add question"
            }
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );

  return (
    <List className="questions-list">
      {value.map(({ question, index }) => {
        return (
          <List.Item
            actions={[
              <Button icon="edit" onClick={handleEdit(question, index)} />,
              <Button
                icon="delete"
                type="danger"
                onClick={handleDelete(index)}
              />
            ]}
            key={index}
          >
            <List.Item.Meta title={question} className="question-content" />
          </List.Item>
        );
      })}
      {!state.editMode ? (
        <div className="btn-container">
          <Button
            label={value.length > 0 ? "Add another question" : "Add a question"}
            icon="plus"
            onClick={handleEditMode}
          />
        </div>
      ) : (
        InputBox
      )}
    </List>
  );
};

export default QuestionsAdder;
