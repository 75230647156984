import { basicOption, inputNumberFormatter } from "#helpers/general";
import { claTriggerEvents, claTsKeys } from "#constants/cla";
import { cantonsSortedByName } from "../../../Shared/Settings/cantons";

const confirmationPrompt = {
  type: "select_btn",
  options: [basicOption("yes", "Yes"), basicOption("no", "No")]
};

const preMoneyValFields = (values, handlers, esopShares, psopShares) => [
  {
    title: "Is the pre-money valuation fully diluted?",
    className: "_fullwidth",
    input: {
      name: "preMoneyValDiluted",
      ...confirmationPrompt
    }
  },
  ...(values.preMoneyValDiluted === "yes"
    ? [
        {
          title:
            "Are the effects of potentially outstanding convertible loans included?",
          className: "_fullwidth",
          input: {
            name: "oclEffectsInclusion",
            ...confirmationPrompt
          }
        },

        values.oclEffectsInclusion === "yes" && {
          className: "_fullwidth",
          title:
            "Please select the convertibles you included in the fully diluted valuation.",
          input: {
            name: "oclEffectsInclusionArray",
            type: "table_list",
            handlers
          }
        },
        {
          title: "Are the effects of a potentially outstanding EPP included? ",
          className: "_fullwidth",
          input: {
            name: "esopEffectsInclusion",
            ...confirmationPrompt
          }
        },

        values.esopEffectsInclusion === "yes" && {
          title:
            "Select which option plan is included in the fully diluted valuation:",
          className: "_fullwidth",
          input: {
            type: "checkbox",
            name: "esopPsopEffectsInclusion",
            options: [
              basicOption(`esop`, `ESOP shares ${esopShares}`, {
                disabled: esopShares === 0
              }),
              basicOption(`psop`, `PSOP shares ${psopShares}`, {
                disabled: psopShares === 0
              })
            ]
          }
        }
      ]
    : [])
];

const minPreMoneyValFields = (values, handlers, esopShares, psopShares) => [
  ...(values.preMoneyValMinAmountPrompt === "yes"
    ? [
        {
          title: "Is the pre-money valuation fully diluted?",
          className: "_fullwidth",
          input: {
            name: "minPreMoneyValDiluted",
            ...confirmationPrompt
          }
        }
      ]
    : []),
  ...(values.minPreMoneyValDiluted === "yes"
    ? [
        {
          title:
            "Are the effects of potentially outstanding convertible loans included?",
          className: "_fullwidth",
          input: {
            name: "minOclEffectsInclusion",
            ...confirmationPrompt
          }
        },
        values.minOclEffectsInclusion === "yes" && {
          className: "_fullwidth",
          title:
            "Please select the convertibles you included in the fully diluted valuation.",
          input: {
            name: "minOclEffectsInclusionArray",
            type: "table_list",
            handlers
          }
        },
        {
          title: "Are the effects of a potentially outstanding EPP included? ",
          className: "_fullwidth",
          input: {
            name: "minEsopEffectsInclusion",
            ...confirmationPrompt
          }
        },
        values.minEsopEffectsInclusion === "yes" && {
          title:
            "Select which option plan is included in the fully diluted valuation:",
          className: "_fullwidth",
          input: {
            type: "checkbox",
            name: "minEffectsIncludedNFREsopPsop",
            options: [
              basicOption(`esop`, `ESOP shares ${esopShares}`, {
                disabled: esopShares === 0
              }),
              basicOption(`psop`, `PSOP shares ${psopShares}`, {
                disabled: psopShares === 0
              })
            ]
          }
        }
      ]
    : [])
];

const maturityDatePreMoneyValFields = (
  values,
  handlers,
  esopShares,
  psopShares
) => [
  ...(values.maturityDatePreMoneyValPrompt === "yes"
    ? [
        {
          title: "Is the pre-money valuation fully diluted?",
          className: "_fullwidth",
          input: {
            name: "maturityDatePreMoneyValDiluted",
            ...confirmationPrompt
          }
        }
      ]
    : []),
  ...(values.maturityDatePreMoneyValDiluted === "yes"
    ? [
        {
          title:
            "Are the effects of potentially outstanding convertible loans included?",
          className: "_fullwidth",
          input: {
            name: "maturityDateOclEffectsInclusion",
            ...confirmationPrompt
          }
        },

        values.maturityDateOclEffectsInclusion === "yes" && {
          className: "_fullwidth",
          title:
            "Please select the convertibles you included in the fully diluted valuation.",
          input: {
            name: "maturityDateOclEffectsInclusionArray",
            type: "table_list",
            handlers
          }
        },

        {
          title: "Are the effects of a potentially outstanding EPP included? ",
          className: "_fullwidth",
          input: {
            name: "maturityDateEsopEffectsInclusion",
            ...confirmationPrompt
          }
        },

        values.maturityDateEsopEffectsInclusion === "yes" && {
          title:
            "Select which option plan is included in the fully diluted valuation:",
          className: "_fullwidth",
          input: {
            type: "checkbox",
            name: "maturityDateIncludedNFREsopPsop",
            options: [
              basicOption(`esop`, `ESOP shares ${esopShares}`, {
                disabled: esopShares === 0
              }),
              basicOption(`psop`, `PSOP shares ${psopShares}`, {
                disabled: psopShares === 0
              })
            ]
          }
        }
      ]
    : [])
];

const saleTransactionPreMoneyValFields = (
  values,
  handlers,
  esopShares,
  psopShares
) => [
  {
    title: "Is the pre money valuation fully diluted",
    className: "_fullwidth",
    input: {
      name: "saleTransactionPreMoneyValDiluted",
      ...confirmationPrompt
    }
  },
  ...(values.saleTransactionPreMoneyValDiluted === "yes"
    ? [
        {
          title:
            "Are the effects of potentially outstanding convertible loans included?",
          className: "_fullwidth",
          input: {
            name: "saleTransactionOclEffectsInclusion",
            ...confirmationPrompt
          }
        },

        values.saleTransactionOclEffectsInclusion === "yes" && {
          className: "_fullwidth",
          title:
            "Please select the convertibles you included in the fully diluted valuation.",
          input: {
            name: "saleTransactionOclEffectsInclusionArray",
            type: "table_list",
            handlers
          }
        },

        {
          title: "Are the effects of a potentially outstanding EPP included? ",
          className: "_fullwidth",
          input: {
            name: "saleTransactionEsopEffectsInclusion",
            ...confirmationPrompt
          }
        },

        values.saleTransactionEsopEffectsInclusion === "yes" && {
          title:
            "Select which option plan is included in the fully diluted valuation:",
          className: "_fullwidth",
          input: {
            type: "checkbox",
            name: "esopPsopSaleTransactionEffectsInclusion",
            options: [
              basicOption(`esop`, `ESOP shares ${esopShares}`, {
                disabled: esopShares === 0
              }),
              basicOption(`psop`, `PSOP shares ${psopShares}`, {
                disabled: psopShares === 0
              })
            ]
          }
        }
      ]
    : [])
];

const optionalFields = (
  { triggerEvent = [], ...values },
  handlers,
  esopShares,
  psopShares,
  registeredSeat
) => {
  let fields = [];
  const { conversionPriceBasisMD = [] } = values;
  const basicConversionPriceCalcDisabled = name =>
    Array.isArray(conversionPriceBasisMD) &&
    !conversionPriceBasisMD.includes(name) &&
    conversionPriceBasisMD.length >= 2;

  if (values.currency === "" || values.currency === undefined) {
    values.currency = "CHF";
  }

  if (triggerEvent.includes(claTriggerEvents.NEXT_FINANCING_ROUND)) {
    fields = [
      ...fields,
      {
        title: "Next financing round",
        className: "_fullwidth _bold-15 _hidden",
        input: {}
      },
      {
        title: "Is your next financing round event qualified?",
        className: "_fullwidth",
        input: {
          name: claTsKeys.QUALIFY_EVENT_NFR,
          ...confirmationPrompt
        }
      },

      values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" && {
        title:
          "Is there a minimum investment amount required in the next equity financing round in order for the conversion to be triggered?",
        className: "_fullwidth",
        input: {
          name: "min_amountPrompt",
          ...confirmationPrompt
        }
      },
      values.min_amountPrompt === "yes" &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" && {
          title:
            "What is the minimum amount for the next equity financing round that will trigger a conversion?",
          className: "_fullwidth",
          input: {
            type: "number",
            name: "min_amount",
            formatter: value =>
              value
                ? `${values.currency} ${value}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "'"
                  )
                : "",
            parser: value =>
              value.replace(
                new RegExp(`${values.currency}\\s?|(\\'*)`, "g"),
                ""
              )
          }
        },
      values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" && {
        title:
          "Is there a minimum pre-money valuation for the next equity financing round required in order for the conversion to be triggered?",
        className: "_fullwidth",
        input: {
          name: "preMoneyValMinAmountPrompt",
          ...confirmationPrompt
        }
      },
      values.preMoneyValMinAmountPrompt === "yes" &&
        values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" && {
          title:
            "What is the minimum pre-money valuation of the next equity financing round that will trigger a conversion?",
          className: "_fullwidth",
          input: {
            type: "number",
            name: "preMoneyValMinAmount",
            formatter: value =>
              value
                ? `${values.currency} ${value}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "'"
                  )
                : "",
            parser: value =>
              value.replace(
                new RegExp(`${values.currency}\\s?|(\\'*)`, "g"),
                ""
              )
          }
        },
      ...minPreMoneyValFields(values, handlers, esopShares, psopShares),
      values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" && {
        title:
          "Are the Investor allowed to request a conversion of the loan, if the qualification(s) of the next financing are not met?",
        className: "_fullwidth",
        input: {
          name: claTsKeys.REQUEST_LOAN_CONVERSION_NFR,
          ...confirmationPrompt
        }
      },
      values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" &&
        values[claTsKeys.REQUEST_LOAN_CONVERSION_NFR] === "yes" && {
          title:
            "Shall there be a discount rate defined in the convertible loan agreement upon conversion in case the Investor request a conversion?",
          className: "_fullwidth",
          input: {
            name: "requestLoanDiscountNFRPrompt",
            ...confirmationPrompt
          }
        },
      values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" &&
        values[claTsKeys.REQUEST_LOAN_CONVERSION_NFR] === "yes" &&
        values.requestLoanDiscountNFRPrompt === "yes" && {
          title: "What is the discount",
          className: "_fullwidth",
          input: {
            name: claTsKeys.REQUEST_LOAN_DISCOUNT_NFR,
            type: "number",
            formatter: value => (value && value <= 100 ? `${value}%` : ""),
            parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), "")
          }
        },
      values[claTsKeys.QUALIFY_EVENT_NFR] === "yes" &&
        values[claTsKeys.REQUEST_LOAN_CONVERSION_NFR] === "yes" && {
          title:
            "Into which share class will the convertible loan be converted upon Conversion in case the Investor request a conversion?",
          className: "_fullwidth",
          input: {
            name: claTsKeys.REQUEST_LOAN_SHARE_TYPE_NFR,
            type: "select_btn",
            options: [
              basicOption("common", "Common Shares"),
              basicOption("preferential_common", "Preferential Common Shares"),
              basicOption("preferred", "Preferred Shares"),
              basicOption(
                "next_financing_round",
                `According to next ${
                  values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
                    ? "qualified"
                    : values[claTsKeys.QUALIFY_EVENT_NFR] === "no"
                    ? "non-qualified"
                    : ""
                } financing round`
              )
            ]
          }
        },
      {
        title:
          "Is there a valuation cap defined in the convertible loan agreement for the purpose of the conversion?",
        className: "_fullwidth",
        input: {
          name: "valuationCapBool",
          ...confirmationPrompt
        }
      }
    ];

    if (values.valuationCapBool === "yes") {
      fields = [
        ...fields,
        {
          title: "What is the pre-money valuation cap?",
          className: "_fullwidth",
          input: {
            type: "number",
            name: "preMoneyVal",
            formatter: value =>
              value
                ? `${values.currency} ${value}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "'"
                  )
                : "",
            parser: value =>
              value.replace(
                new RegExp(`${values.currency}\\s?|(\\'*)`, "g"),
                ""
              )
          }
        },
        ...preMoneyValFields(values, handlers, esopShares, psopShares)
      ];
    }

    fields.push({
      title:
        "Is there a discount rate defined in the convertible loan agreement upon conversion?",
      className: "_fullwidth",
      input: {
        name: "discountRateBool",
        ...confirmationPrompt
      }
    });

    if (values.discountRateBool === "yes") {
      fields.push({
        title: "What is the discount rate?",
        className: "_fullwidth",
        details: "Discount should not be bigger than 100%",
        input: {
          type: "number",
          name: "discountRate",
          formatter: value => (value && value <= 100 ? `${value}%` : ""),
          parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), ""),
          min: 0,
          max: 100
        }
      });
    }

    fields.push(
      {
        title:
          "Into which share class will the convertible loan be converted upon conversion?",
        className: "_fullwidth",
        input: {
          type: "select_btn",
          name: "shareTypeConversion",
          options: [
            basicOption("common", "Common Shares", {
              tooltip:
                "If investors will receive common shares with preferential rights or preferred shares in the next financing round, the investors allocated to the convertible loans will receive the same category."
            }),
            basicOption(
              // "most_senior_preferential_common",
              "common_with_preferential_rights",
              "Most senior Preferential Common Shares",
              {
                tooltip:
                  "Contractual preferential rights are a frequent solution used in early financing rounds"
              }
            ),
            basicOption(
              "preferred",
              // "most_senior_preferred",
              "Most senior Preferred Shares"
            ),
            // basicOption(
            //   "next_financing_round",
            //   "According to next financing round"
            // )
            basicOption(
              "next_financing_round",
              `According to next ${
                values[claTsKeys.QUALIFY_EVENT_NFR] === "yes"
                  ? "qualified"
                  : values[claTsKeys.QUALIFY_EVENT_NFR] === "no"
                  ? "non-qualified"
                  : ""
              } financing round`
            )
          ]
        }
      },
      {
        title:
          "Is there a valuation floor defined in the convertible loan agreement for the purpose of the conversion in Next Financing Round?",
        showId: false,
        className: "_fullwidth",
        input: {
          name: "valuationFloorDefined",
          ...confirmationPrompt
        }
      },

      values.valuationFloorDefined === "yes" && {
        title: "What is the valuation floor?",
        className: "_fullwidth",
        input: {
          name: claTsKeys.VALUATION_FLOOR_NFR,
          type: "number",
          ...inputNumberFormatter(values.currency)
        }
      },
      // fully diluted - esop
      values.valuationFloorDefined === "yes" && {
        title: "Is the floor valuation fully diluted?",
        className: "_fullwidth",
        input: {
          name: claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR,
          ...confirmationPrompt
        }
      },
      values.valuationFloorDefined === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes" && {
          title:
            "Are the effects of potentially outstanding convertible loans included?",
          className: "_fullwidth",
          input: {
            name:
              claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR,
            ...confirmationPrompt
          }
        },
      values.valuationFloorDefined === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes" &&
        values[
          claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_NFR
        ] === "yes" && {
          className: "_fullwidth",
          title:
            "Please select the convertibles you included in the fully diluted valuation.",
          input: {
            name:
              claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_NFR,
            type: "table_list",
            handlers
          }
        },
      values.valuationFloorDefined === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes" &&
        (esopShares > 0 || psopShares > 0) && {
          title: "Are the effects of a potentially outstanding EPP included? ",
          className: "_fullwidth",
          input: {
            name: claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR,
            ...confirmationPrompt
          }
        },

      values.valuationFloorDefined === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_NFR] === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_NFR] ===
          "yes" && {
          title:
            "Select which option plan is included in the fully diluted valuation:",
          className: "_fullwidth",
          input: {
            type: "checkbox",
            name: "esopPsopEffectsIncludedFloorNFR",
            options: [
              basicOption(`esop`, `ESOP shares ${esopShares}`, {
                disabled: esopShares === 0
              }),
              basicOption(`psop`, `PSOP shares ${psopShares}`, {
                disabled: psopShares === 0
              })
            ]
          }
        }
    );

    fields.push({
      className: "_fullwidth _border-bottom _hidden",
      input: {}
    });
  }

  if (triggerEvent.includes(claTriggerEvents.SALE_TRANSACTION)) {
    fields.push(
      {
        title: "Sale transaction",
        className: "_fullwidth _bold-15 _hidden",
        input: {}
      },
      {
        title: "Is there a discount rate in case of a Sale Transaction?",
        className: "_fullwidth",
        input: {
          name: "saleTransactionDiscountRateBool",
          ...confirmationPrompt
        }
      }
    );
    if (values.saleTransactionDiscountRateBool === "yes") {
      fields.push({
        title: "What is the discount rate for the Sale Transaction?",
        className: "_fullwidth",
        details: "Discount should not be bigger than 100%",
        input: {
          type: "number",
          name: "saleTransactionDiscountRate",
          formatter: value => (value && value <= 100 ? `${value}%` : ""),
          parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), ""),
          min: 0,
          max: 100
        }
      });
    }
    fields.push({
      title:
        "Is there a valuation cap defined in the convertible loan agreement for the purpose of the conversion?",
      className: "_fullwidth",
      input: {
        name: "saleTransactionValuationCapBool",
        ...confirmationPrompt
      }
    });
    if (values.saleTransactionValuationCapBool === "yes") {
      fields = [
        ...fields,
        {
          title: "What is the pre-money valuation cap?",
          className: "_fullwidth",
          input: {
            type: "number",
            name: "saleTransactionPreMoneyVal",
            formatter: value =>
              value
                ? `${values.currency} ${value}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "'"
                  )
                : "",
            parser: value =>
              value.replace(
                new RegExp(`${values.currency}\\s?|(\\'*)`, "g"),
                ""
              )
          }
        },
        ...saleTransactionPreMoneyValFields(
          values,
          handlers,
          esopShares,
          psopShares
        )
      ];
    }
    fields.push(
      {
        title:
          "Is there a valuation floor defined in the convertible loan agreement for the purpose of the conversion in Sale Transaction",
        showId: false,
        className: "_fullwidth",
        input: {
          name: "valuationFloorDefinedST",
          ...confirmationPrompt
        }
      },

      values.valuationFloorDefinedST === "yes" && {
        title: "What is the valuation floor?",
        className: "_fullwidth",
        input: {
          name: claTsKeys.VALUATION_FLOOR_ST,
          type: "number",
          ...inputNumberFormatter(values.currency)
        }
      },

      // fully diluted - esop
      values.valuationFloorDefinedST === "yes" && {
        title: "Is the floor valuation fully diluted?",
        className: "_fullwidth",
        input: {
          name: claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST,
          ...confirmationPrompt
        }
      },

      values.valuationFloorDefinedST === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes" && {
          title:
            "Are the effects of potentially outstanding convertible loans included?",
          className: "_fullwidth",
          input: {
            name:
              claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ST,
            ...confirmationPrompt
          }
        },
      values.valuationFloorDefinedST === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes" &&
        values[
          claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ST
        ] === "yes" && {
          className: "_fullwidth",
          title:
            "Please select the convertibles you included in the fully diluted valuation.",
          input: {
            name:
              claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY_ST,
            type: "table_list",
            handlers
          }
        },

      values.valuationFloorDefinedST === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes" &&
        (esopShares > 0 || psopShares > 0) && {
          title: "Are the effects of a potentially outstanding EPP included? ",
          className: "_fullwidth",
          input: {
            name: claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST,
            ...confirmationPrompt
          }
        },

      values.valuationFloorDefinedST === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_FULLY_DILUTED_ST] === "yes" &&
        values[claTsKeys.FLOOR_VALUATION_POTENTIALLY_OUTSTANDING_EPP_ST] ===
          "yes" && {
          title:
            "Select which option plan is included in the fully diluted valuation:",
          className: "_fullwidth",
          input: {
            type: "checkbox",
            name: "esopPsopEffectsIncludedFloorST",
            options: [
              basicOption(`esop`, `ESOP shares ${esopShares}`, {
                disabled: esopShares === 0
              }),
              basicOption(`psop`, `PSOP shares ${psopShares}`, {
                disabled: psopShares === 0
              })
            ]
          }
        },
      {
        title:
          "Into which share class will the convertible loan be converted upon Conversion?",
        className: "_fullwidth",
        input: {
          type: "select_btn",
          name: claTsKeys.SHARE_TYPE_CONVERSION_ST,
          options: [
            basicOption("common", "Common Shares"),
            basicOption(
              "most_senior_preferential_common",
              "Most senior Preferential Common Shares"
            ),
            basicOption("most_senior_preferred", "Most senior Preferred Shares")
          ]
        }
      }
    );
    fields.push({
      title: (
        <span className="span-left">
          The sale transaction triggers the conversion when the shareholders end
          up owning less than{" "}
          <b>
            {" "}
            <i>
              {values.saleTransactionLessPercentage
                ? `${values.saleTransactionLessPercentage}%`
                : "___%"}
            </i>
          </b>{" "}
          of the voting power due to an exit, merger or sale event. Define the
          percentage:
        </span>
      ),
      className: "_fullwidth",
      input: {
        type: "number",
        name: "saleTransactionLessPercentage",
        formatter: value => (value && value <= 100 ? `${value}%` : ""),
        parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), "")
      }
    });
    fields.push({
      className: "_fullwidth _border-bottom _hidden",
      input: {}
    });
  }

  if (triggerEvent.includes(claTriggerEvents.MATURITY_DATE)) {
    fields = [
      ...fields,
      {
        title: "Maturity date",
        className: "_fullwidth _bold-15 _hidden",
        input: {}
      },
      triggerEvent.includes(claTriggerEvents.MATURITY_DATE) &&
        (triggerEvent.includes(claTriggerEvents.NEXT_FINANCING_ROUND) ||
          triggerEvent.includes(claTriggerEvents.SALE_TRANSACTION)) && {
          title: (
            <span className="span-left">
              You selected a {values.mandatoryConversion || ""} conversion as
              general conversion rule for your convertible loan agreement.
              Should the conversion on Maturity Date follow the same conversion
              rule?
            </span>
          ),
          className: "_fullwidth",
          input: {
            name: claTsKeys.FOLLOW_CONVERSION_RULE_MD,
            ...confirmationPrompt
          }
        },
      values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] && {
        title: `Upon the Maturity Date the loan shall be converted ${
          values.mandatoryConversion === "voluntary" &&
          values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "yes"
            ? "voluntary"
            : values.mandatoryConversion === "voluntary" &&
              values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "no"
            ? "mandatory"
            : values.mandatoryConversion === "mandatory" &&
              values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "yes"
            ? "mandatory"
            : values.mandatoryConversion === "mandatory" &&
              values[claTsKeys.FOLLOW_CONVERSION_RULE_MD] === "no"
            ? "voluntary"
            : ""
        }`,
        className: "_fullwidth _hidden",
        showId: false,
        input: {}
      },
      {
        title:
          "What shall be the basis for the conversion price calculation on Maturity Date?",
        className: "_fullwidth",
        input: {
          type: "checkbox",
          name: "conversionPriceBasisMD",
          options: [
            basicOption("fix_valuation", "Fix pre-money valuation", {
              disabled: basicConversionPriceCalcDisabled("fix_valuation")
            }),
            basicOption("fair_market_value", "Fair Market Value", {
              disabled: basicConversionPriceCalcDisabled("fair_market_value")
            }),
            basicOption(
              "post_money_valuation",
              "Post-money valuation of the last financing round",
              {
                disabled: basicConversionPriceCalcDisabled(
                  "post_money_valuation"
                )
              }
            )
          ]
        }
      },
      //

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation") && {
          showId: false,
          title: "Fix pre-money valuation:",
          className: "_fullwidth _hidden _bold-15",
          input: {}
        },

      conversionPriceBasisMD.includes("fix_valuation") && {
        title:
          "What is the pre-money valuation cap for a conversion at the maturity date?",
        className: "_fullwidth",
        input: {
          type: "number",
          name: claTsKeys.MATURITY_DATE_PRE_MONEY_VAL,
          formatter: value =>
            value ? `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'") : "",
          parser: value => value.replace(new RegExp(`CHF\\s?|(\\'*)`, "g"), "")
        }
      },

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation") && {
          title: "Is the pre money valuation fully diluted?",
          className: "_fullwidth",
          input: {
            name: claTsKeys.MATURITY_DATE_FULLY_DILUTED,
            ...confirmationPrompt
          }
        },
      values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes" &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation") && {
          title:
            "Are the effects of potentially outstanding convertible loans included?",
          className: "_fullwidth",
          input: {
            name: claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_CONVERTIBLE,
            ...confirmationPrompt
          }
        },

      values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_CONVERTIBLE] ===
          "yes" &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation") && {
          className: "_fullwidth",
          title:
            "Please select the convertibles you included in the fully diluted valuation.",
          input: {
            name: claTsKeys.CL_EFFECTS_MD_ARRAY,
            type: "table_list",
            handlers
          }
        },
      values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes" &&
        (esopShares > 0 || psopShares > 0) &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation") && {
          title: "Are the effects of a potentially outstanding EPP included? ",
          className: "_fullwidth",
          input: {
            name: claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_EPP,
            ...confirmationPrompt
          }
        },

      values[claTsKeys.MATURITY_DATE_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.MATURITY_DATE_POTENTIALLY_OUTSTANDING_EPP] === "yes" &&
        Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation") && {
          title:
            "Select which option plan is included in the fully diluted valuation:",
          className: "_fullwidth",
          input: {
            type: "checkbox",
            name: "esopPsopEffectsIncludedMD",
            options: [
              basicOption(`esop`, `ESOP shares ${esopShares}`, {
                disabled: esopShares === 0
              }),
              basicOption(`psop`, `PSOP shares ${psopShares}`, {
                disabled: psopShares === 0
              })
            ]
          }
        },
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fix_valuation") && {
          className: "_fullwidth _border-bottom _hidden",
          input: {}
        },
      //includes "fair_market_value"
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") && {
          showId: false,
          title: "Fair Market Value:",
          className: "_fullwidth _hidden _bold-15",
          input: {}
        },
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") && {
          title: (
            <span className="span-left">
              You selected a fair market value for the basis of the conversion
              price calculation. Sometimes a fair market value of the conversion
              price is hard to determine. Therefore, an independent expert or if
              such is missing / or not able to act, or the parties cannot agree
              on the fair market value, an experiences accounting firm is being
              appointed by the president of a cantonal chamber of commerce.
              Please define which chamber of commerce shall be referred to.
            </span>
          ),
          className: "_fullwidth",
          input: {
            name: claTsKeys.CHAMBER_OF_COMMERCE_MD,
            type: "select_btn",
            options: [
              basicOption(registeredSeat, "Registered seat"),
              basicOption("Other")
            ]
          }
        },
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.CHAMBER_OF_COMMERCE_MD] === "Other" && {
          className: "_fullwidth",
          input: {
            type: "autocomplete",
            name: claTsKeys.CHAMBER_OF_COMMERCE_MD_OTHER,
            dataSource: cantonsSortedByName,
            filterOption: true
          }
        },
      // fully diluted - esop

      // Array.isArray(conversionPriceBasisMD) &&
      //   conversionPriceBasisMD.includes("fair_market_value") && {
      //     title:
      //       "By what pre-valuation shall the Fair Market Value be divided?",
      //     className: "_fullwidth",
      //     input: {
      //       type: "number",
      //       name: claTsKeys.DEVIDE_VALUATION,
      //       ...inputNumberFormatter(values.currency)
      //     }
      //   },

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") && {
          title: "Shall the Fair Market Value be fully diluted?",
          className: "_fullwidth",
          input: {
            name: claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED,
            ...confirmationPrompt
          }
        },
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes" && {
          title:
            "Shall the effects of potentially outstanding convertible loans be included?",
          className: "_fullwidth",
          input: {
            name:
              claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE,
            ...confirmationPrompt
          }
        },
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes" &&
        values[
          claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE
        ] === "yes" && {
          className: "_fullwidth",
          title:
            "Please select the convertibles you included in the fully diluted valuation.",
          input: {
            name:
              claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_CONVERTIBLE_ARRAY,
            type: "table_list",
            handlers
          }
        },
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes" &&
        (esopShares > 0 || psopShares > 0) && {
          title:
            "Shall the effects of a potentially outstanding EPP be included? ",
          className: "_fullwidth",
          input: {
            name: claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP,
            ...confirmationPrompt
          }
        },

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") &&
        values[claTsKeys.FAIR_MARKET_VALUE_FULLY_DILUTED] === "yes" &&
        values[claTsKeys.FAIR_MARKET_VALUE_POTENTIALLY_OUTSTANDING_EPP] ===
          "yes" && {
          title:
            "Select which option plan is included in the fully diluted valuation:",
          className: "_fullwidth",
          input: {
            type: "checkbox",
            name: "esopPsopFairMarketValue",
            options: [
              basicOption(`esop`, `ESOP shares ${esopShares}`, {
                disabled: esopShares === 0
              }),
              basicOption(`psop`, `PSOP shares ${psopShares}`, {
                disabled: psopShares === 0
              })
            ]
          }
        },
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("fair_market_value") && {
          className: "_fullwidth _border-bottom _hidden",
          input: {}
        },
      //

      //includes "post_money_valuation"
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") && {
          showId: false,
          title: "Post-money valuation of the last financing round:",
          className: "_fullwidth _hidden _bold-15",
          input: {}
        },

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") && {
          title:
            "What was the post-money valuation of the last financing round?",
          className: "_fullwidth",
          input: {
            type: "number",
            name: claTsKeys.POST_MONEY_VALUATION_LAST_ROUND,
            ...inputNumberFormatter(values.currency)
          }
        },

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") && {
          title: "Was the post money valuation fully diluted?",
          className: "_fullwidth",
          input: {
            name: claTsKeys.FULLY_DILUTED_POST_MONEY_MD,
            ...confirmationPrompt
          }
        },

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") &&
        values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes" && {
          title:
            "Are the effects of potentially outstanding convertible loans included?",
          className: "_fullwidth",
          input: {
            name: claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD,
            ...confirmationPrompt
          }
        },

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") &&
        values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes" &&
        values[claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD] === "yes" && {
          className: "_fullwidth",
          title:
            "Please select the convertibles you included in the fully diluted valuation.",
          input: {
            name: claTsKeys.OUTSTANDING_CLA_EFFECTS_POST_MONEY_MD_ARRAY,
            type: "table_list",
            handlers
          }
        },

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") &&
        values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes" &&
        (esopShares > 0 || psopShares > 0) && {
          title: "Are the effects of a potentially outstanding EPP included? ",
          className: "_fullwidth",
          input: {
            name: claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD,
            ...confirmationPrompt
          }
        },

      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") &&
        values[claTsKeys.FULLY_DILUTED_POST_MONEY_MD] === "yes" &&
        values[claTsKeys.EMPLOYEE_PARTICIPATION_PLAN_POST_MONEY_MD] ===
          "yes" && {
          title:
            "Select which option plan is included in the fully diluted valuation:",
          className: "_fullwidth",
          input: {
            type: "checkbox",
            name: "esopPsopEffectsIncludedPostValuationMD",
            options: [
              basicOption(`esop`, `ESOP shares ${esopShares}`, {
                disabled: esopShares === 0
              }),
              basicOption(`psop`, `PSOP shares ${psopShares}`, {
                disabled: psopShares === 0
              })
            ]
          }
        },
      Array.isArray(conversionPriceBasisMD) &&
        conversionPriceBasisMD.includes("post_money_valuation") && {
          className: "_fullwidth _border-bottom _hidden",
          input: {}
        },
      // ...maturityDatePreMoneyValFields(values,handlers,esopShares,psopShares),
      {
        title:
          "Into which share class will the convertible loan be converted upon conversion?",
        className: "_fullwidth",
        input: {
          type: "select_btn",
          name: "maturityDateShareTypeConversion",
          options: [
            basicOption("common", "Common Shares", {
              tooltip:
                "If investors will receive common shares with preferential rights or preferred shares in the next financing round, the investors allocated to the convertible loans will receive the same category."
            }),
            basicOption(
              "most_senior_preferential_common",
              // "common_with_preferential_rights",
              "Most senior Preferential Common Shares",
              {
                tooltip:
                  "Contractual preferential rights are a frequent solution used in early financing rounds"
              }
            ),
            basicOption(
              // "preferred",
              "most_senior_preferred",
              "Most senior Preferred Shares"
            )
            // basicOption(
            //   "next_financing_round",
            //   "According to next financing round"
            // )
          ]
        }
      }
    ];
    fields.push({
      className: "_fullwidth _border-bottom _hidden",
      input: {}
    });
  }

  return fields;
};

const claMgmtFields = (handlers, esopShares, psopShares, registeredSeat) => ({
  values = {}
}) => [
  {
    title: "What name has the convertible loan",
    className: "_fullwidth",
    input: {
      type: "text",
      name: "name",
      placeholder: "Convertible Loan ABC"
    }
  },
  {
    title: "Define the issue date:",
    input: {
      type: "date",
      name: "issueDate"
    }
  },
  {
    title: "Define the maturity date:",
    input: {
      type: "date",
      name: "maturityDate",
      disabled: !values.issueDate
    }
  },
  {
    title: "In which currency was the convertible loan issued?",
    className: "_fullwidth",
    input: {
      type: "select_btn",
      name: "currency",
      options: [
        basicOption("EUR", "EUR"),
        basicOption("USD", "USD"),
        basicOption("CHF", "CHF"),
        basicOption("GBP", "GBP")
      ]
    }
  },
  {
    title: "What amount has been raised under this convertible loan?",
    className: "_fullwidth",
    input: {
      type: "number",
      name: "convertibleMaxAmount",
      formatter: value =>
        value
          ? `${values.currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
          : "",
      parser: value =>
        value.replace(new RegExp(`${values.currency}\\s?|(\\'*)`, "g"), "")
    }
  },
  {
    title: "How will the conversion be performed?",
    className: "_fullwidth",
    input: {
      type: "select_btn",
      name: "mandatoryConversion",
      options: [
        basicOption("voluntary", "Voluntary conversion"),
        basicOption("mandatory", "Mandatory conversion")
      ]
    }
  },
  {
    title:
      "Please select which event will trigger the conversion of the convertible loan agreement:",
    className: "_fullwidth",
    input: {
      type: "checkbox",
      name: "triggerEvent",
      options: [
        basicOption(
          claTriggerEvents.NEXT_FINANCING_ROUND,
          "Next financing round"
        ),
        basicOption(claTriggerEvents.SALE_TRANSACTION, "Sale transaction"),
        basicOption(claTriggerEvents.MATURITY_DATE, "Maturity date", {
          changeDisabled: true
        })
      ]
    }
  },
  ...optionalFields(values, handlers, esopShares, psopShares, registeredSeat),
  {
    title:
      "Is there any annual interest to pay according the convertible loan agreement?",
    className: "_fullwidth",
    input: {
      name: "interest",
      ...confirmationPrompt
    }
  },
  values.interest === "yes" && {
    title: "What is the interest rate",
    className: "_fullwidth",
    input: {
      type: "number",
      name: "interestRate",
      formatter: value => (value && value <= 100 ? `${value}%` : ""),
      parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), ""),
      min: 0,
      max: 100
    }
  },
  values.interest === "yes"
    ? {
        title: "How is the annual interest calculated?",
        className: "_fullwidth",
        input: {
          type: "select_btn",
          name: "interestCalculation",
          options: [
            basicOption("365", "365 days"),
            basicOption("360", "360 days")
          ]
        }
      }
    : null,
  // values.interest === 'yes'
  //   ? {
  //     title: 'Will the interests be converted at conversion?',
  //     className: '_fullwidth',
  //     input: {
  //       name: 'interestCompensation',
  //       ...confirmationPrompt,
  //     },
  //   }
  //   : null,
  values.interest === "yes" && {
    title: "Is the interest rate accrued or compound?",
    className: "_fullwidth",
    input: {
      type: "select_btn",
      name: "interestRateType",
      options: [
        basicOption("accrued", "Accrued"),
        basicOption("compound", "Compound")
      ]
    }
  },
  {
    title: "Is the convertible loan irrevocably subordinated?",
    className: "_fullwidth",
    input: {
      name: "subordinated",
      ...confirmationPrompt
    }
  }
  // {
  //   title: "Do you want to add the convertible loan agreement",
  //   className: "_fullwidth",
  //   input: {
  //     name: "claAddition",
  //     ...confirmationPrompt
  //   }
  // },
  // values.claAddition === "yes"
  //   ? {
  //       title: "Please upload your convertible loan agreement",
  //       input: {
  //         type: "upload",
  //         name: "cla_upload"
  //       }
  //     }
  //   : null
];

// No need to filter / index those fields. That is done within the BasicForm component, where they are integrated.

export default claMgmtFields;
