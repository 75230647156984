import { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "antd/es/icon";
import Input from "../../";
import Tooltip from "../../../Tooltip";
import { InformationIconViolet } from "../../../Icons";

import i18n from "../../../../../i18n";
import SwitchWrapper from "../../../Switch";
import { ActionDropdown } from "../../../Dropdown";

export default function Fees({
  native,
  onChange,
  values: {
    fees = {},
    multiple,
    multipliers,
    vat,
    administrationFeeBool,
    closingFeeBool,
    performanceFeeBool
  } = {}
}) {
  const handleStateChange = key => value => () =>
    onChange({ target: { name: key, value } });

  const dropdownList = [
    {
      label: "Add another multiplier",
      onClick: handleStateChange("multipliers")(multipliers + 1),
      display: multipliers !== 3
    },
    {
      label: "Delete this multiplier",
      customStyle: "danger",
      onClick: handleStateChange("multipliers")(multipliers - 1),
      display: multipliers !== 1
    }
  ];

  const renderMultipliers = ({ index }) => {
    const multiplierKey = `fees.performance.multiple[${index}]`;

    const settingsIcon = (
      <ActionDropdown
        actionList={dropdownList.filter(({ display }) => display)}
      >
        <Icon type="ellipsis" />
      </ActionDropdown>
    );
    const showDropdown = index === multipliers - 1;
    const { breakpoint, value } = fees.performance.multiple[index] || {};

    return (
      <div className="multiplier" key={index}>
        <div className="inline-block">
          <span>
            if the investment return is{" "}
            {index === 0 ? "equal or below " : "above"}
          </span>
          <Input
            size="large"
            name={`${multiplierKey}.breakpoint`}
            onChange={onChange}
            addonAfter="X"
            value={
              Array.isArray(fees?.performance?.multiple) &&
              fees?.performance?.multiple[0]?.breakpoint
            }
            disabled={index === 1}
          />
          <span>then</span>
          {renderCarriedFee({
            keys: [`${multiplierKey}.value`, `${multiplierKey}.hurdle`],
            values: [value]
          })}
        </div>
        <div className="inline-block _centered"></div>
      </div>
    );
  };

  const renderYearlyManagFee = () => {
    return (
      <Fragment>
        <div className="column-block">
          <span className="_bold">Administration fee </span>
          <span>Yearly recurring administration fee of</span>
        </div>
        <Input
          size="large"
          name="fees.administration.value"
          type="number"
          min="0"
          formatter={value => (value ? `${value}%` : value)}
          parser={value => value.replace("%", "")}
          onChange={onChange}
          value={
            fees.administration.value !== 0 ? fees.administration.value : ""
          }
          disabled={!administrationFeeBool}
        />
        <span>for</span>
        <Input
          size="large"
          name="fees.administration.years"
          type="number"
          min="0"
          onChange={onChange}
          value={
            fees.administration.years !== 0 ? fees.administration.years : ""
          }
          disabled={!administrationFeeBool}
        />
        <span>years</span>
      </Fragment>
    );
  };

  const renderCarriedFee = ({
    keys = [],
    values = [],
    hurdle,
    customInputClass
  }) => {
    return (
      <Fragment>
        {hurdle && (
          <div className="column-block">
            <span className="_bold">Performance fee </span>
            <span>One-time performance fee of</span>
          </div>
        )}
        <Input
          size="large"
          name={keys[0]}
          type="number"
          min="0"
          formatter={value => (value ? `${value}%` : value)}
          parser={value => value.replace("%", "")}
          onChange={onChange}
          value={values[0]}
          disabled={!performanceFeeBool && !multiple}
          wrapperClassname={customInputClass}
        />
        {hurdle ? (
          <Fragment>
            <span>after hurdle rate of</span>
            <Input
              size="large"
              name={keys[1]}
              type="number"
              min="0"
              onChange={onChange}
              formatter={value => (value ? `${value}%` : value)}
              parser={value => value.replace("%", "")}
              value={values[1]}
              disabled={!performanceFeeBool && !multiple}
              wrapperClassname={customInputClass}
            />
            <span>per year</span>
          </Fragment>
        ) : (
          <span>performance fee</span>
        )}
      </Fragment>
    );
  };

  const feesClassname = `fees-container ${native ? "native" : ""}`;

  return (
    <div className={feesClassname}>
      <div className="option">
        <SwitchWrapper
          onChange={handleStateChange("closingFeeBool")(!closingFeeBool)}
          checked={closingFeeBool}
        />
        <div className="opt-container">
          <div className="column-block">
            <span className="_bold">Closing fee</span>
            <span>One time closing fee of</span>
          </div>
          <Input
            size="large"
            name="fees.setup"
            type="number"
            min="0"
            formatter={value => (value ? `${value}%` : value)}
            parser={value => value.replace("%", "")}
            onChange={onChange}
            value={fees.setup !== 0 ? fees.setup : ""}
            disabled={!closingFeeBool}
          />
        </div>
        <Tooltip title={i18n.t("io.fees.one_time.info")}>
          <i>
            <InformationIconViolet />
          </i>
        </Tooltip>
      </div>
      <div className="option">
        <SwitchWrapper
          onChange={handleStateChange("administrationFeeBool")(
            !administrationFeeBool
          )}
          checked={administrationFeeBool}
        />
        <div className="opt-container">{renderYearlyManagFee()}</div>
        <Tooltip title={i18n.t("io.fees.yearly_management.info")}>
          <i>
            <InformationIconViolet />
          </i>
        </Tooltip>
      </div>
      {!multiple && (
        <div className="option">
          <SwitchWrapper
            onChange={handleStateChange("performanceFeeBool")(
              !performanceFeeBool
            )}
            checked={performanceFeeBool}
          />
          <div className="opt-container">
            {renderCarriedFee({
              keys: [
                "fees.performance.simple.value",
                "fees.performance.simple.hurdle"
              ],
              values: [
                fees.performance.simple.value,
                fees.performance.simple.hurdle
              ],
              hurdle: true,
              customInputClass: "_width-25"
            })}
          </div>
          <Tooltip title={i18n.t("io.fees.carry_performance.info")}>
            <i>
              <InformationIconViolet />
            </i>
          </Tooltip>
        </div>
      )}
      <div className="option">
        <SwitchWrapper
          onChange={handleStateChange("multiple")(!multiple)}
          checked={multiple}
        />
        <div className="opt-container">Dynamic performance fee?</div>
        <Tooltip title="You can apply dynamic performance fees. For example you may charge a performance fee of 10% if the performance is below 5x and 30% if the performance is above 5x.">
          <i>
            <InformationIconViolet />
          </i>
        </Tooltip>
      </div>
      {multiple && (
        <div className="option _column">
          {Array(multipliers)
            .fill(1)
            .map((item, index) => renderMultipliers({ index }))}
        </div>
      )}
      <div className="option withoout-tooltip">
        <SwitchWrapper
          name="vat"
          onChange={handleStateChange("vat")(!vat)}
          checked={vat}
        />
        <div className="opt-container">
          <span className="_bold">Value-Added Tax (VAT / MwSt. / T.V.A.)</span>
          <span className="subject-to-vat">My fees are subject to VAT</span>
        </div>
      </div>
    </div>
  );
}

Fees.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.object
};
