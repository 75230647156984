const InReview = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="16" stroke="#2B5797" stroke-width="2" />
    <line
      x1="16.5"
      y1="14"
      x2="16.5"
      y2="27"
      stroke="#2B5797"
      stroke-width="3"
    />
    <line
      x1="24.147"
      y1="14"
      x2="24.147"
      y2="27"
      stroke="#2B5797"
      stroke-width="3"
    />
  </svg>
);

export default InReview;
