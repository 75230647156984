import { Empty } from "antd";
import Card from "./";
import { Button } from "../../Button";

const HistoryCardsWrapper = ({
  isDashboard,
  data = [],
  itemFormatter,
  key,
  label,
  id,
  inactive = false
  // isPublic,
  // capped
}) => {
  if (isDashboard && data.length === 0) {
    return null;
  }
  return (
    <div className="timeline-cards-container" key={id}>
      <div className="cards-section-title">
        <span>
          <b>Syndicate:</b> {label}
        </span>
        {/* <span>
          <b>Max Investors:</b> {capped} - {isPublic ? "Public" : "Private"}
        </span> */}
      </div>
      <div className="history-cards">
        {data.map(item => (
          <div className="card" key={item.cuid}>
            <Card {...itemFormatter(item)} />
            {/* {inactive && (
              <div className="follow-up-wrapper">
                <Button
                  type="secondary"
                  label="Create a follow-up deal"
                  href={`/create/deal?syndicateId=${id}`}
                  disabled
                />
              </div>
            )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoryCardsWrapper;
