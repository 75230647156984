import { Card, Icon } from "antd";
import DocumentCard from "./Document";
import MinorContract from "./MinorContract";
import FundraisingCard from "./Fundraising";
import HistoryCard from "./History";
import HistoryCardWrapper from "./History/Wrapper";
import NewsCard from "./News";
import AddNewsCard from "./AddNewsCard";
import PopularNewsCard from "./PopularNewsCard";
import CashControlCard from "./CashControlCard";
import InvestmentCard from "./InvestmentCard";
import { ActionDropdown } from "../../Shared/Dropdown/";

export const InfoSection = ({
  children,
  className = "",
  sub,
  settings,
  settingsList = []
}) => (
  <div className={`info-section ${className}`}>
    <div className="sub-info">
      <div className="name">
        <span>{sub}</span>
      </div>
      {settings && (
        <ActionDropdown actionList={settingsList}>
          <div className="settings">
            <Icon type="ellipsis" />
          </div>
        </ActionDropdown>
      )}
    </div>
    <div className="content">{children}</div>
  </div>
);

export const CardWrapper = ({ className = "", children, ...props }) => {
  return (
    <Card
      className={`card-wrapper ${className}`}
      bordered={false}
      {...props}
      hoverable={false}
    >
      {children}
    </Card>
  );
};

export {
  DocumentCard,
  MinorContract,
  FundraisingCard,
  HistoryCard,
  HistoryCardWrapper,
  NewsCard,
  AddNewsCard,
  PopularNewsCard,
  InvestmentCard,
  CashControlCard
};
