import axios from "./apiConfig";

export const getCurrentUser = cuid => axios.get(`users/${cuid}`);

export const getUserProfile = () => axios.get(`/users/profile`);

export const logout = () => axios.post(`users/logout`);

export const postUserProfilePic = profile =>
  axios.post(`/users/profile_picture`, profile);

export const putSmsVerification = () =>
  axios.put("users/kycaml/sms-verification");

export const updateUser = settings => axios.patch(`/users/profile`, settings);

export const updateUserSettings = settings =>
  axios.put(`/users/settings`, settings);

export const onboardUser = data => axios.patch("users/onboarding", data);

export const updateKycData = values => axios.patch(`/users/kycaml`, values);

export const generateKycToken = params =>
  axios.post(`/users/kyc/generate-access-token?level_name=${params.levelName}`);
