import { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Icon from "antd/es/icon";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import Divider from "antd/es/divider";
import { roles } from "#constants/roles";
import Avatar from "../../Avatar";
import Button from "../../Button/components/BtnWrapper/Presentational";

const UserDropdown = ({ userRole, userDetails }) => {
  const menuList = [
    {
      label: "Settings",
      link: "/account-settings",
      roles: [
        roles.FOUNDER,
        roles.ASSET_MANAGER,
        roles.INVESTOR,
        roles.AM_INVESTOR
      ]
    },
    {
      label: "divider"
    },
    {
      label: "Logout",
      link: "/logout"
    }
  ];

  const filteredMenuList = menuList.filter(
    ({ roles: rolesAllowed }) =>
      !rolesAllowed || (rolesAllowed && rolesAllowed.includes(userRole))
  );

  const menu = (
    <Menu id="user-dropdown-content">
      {filteredMenuList.map(({ label, link }) => {
        const isDivider = label === "divider";

        if (isDivider) {
          return <Divider />;
        }

        return (
          <Menu.Item>
            <Link to={link}>{label}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const { firstName, lastName, profilePicture } = userDetails;

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        className="dropdown-btn"
        type="default"
        label={
          <div className="user-dropdown">
            <div className="user-info">
              <Avatar
                size="medium"
                shape="square"
                className="avatar"
                src={profilePicture}
              >
                {firstName && firstName.charAt(0)}
              </Avatar>
              <span>
                {firstName} {lastName}
              </span>
            </div>
            <div className="dropdown-icon">
              <Icon type="down" />
            </div>
          </div>
        }
        size="large"
      />
    </Dropdown>
  );
};

UserDropdown.propTypes = {
  userRole: PropTypes.string,
  userDetails: PropTypes.object
};

export default UserDropdown;
