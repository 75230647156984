import { iaKeys } from "#constants/io/equityPool";
import { basicOption, inputNumberFormatter } from "#helpers/general";
import { confirmationPrompt } from "../../../common/prompts";

const warranties = values => [
  {
    title:
      "Does the company have a valid unaudited statutory financial statement?",
    input: {
      name: "unAuditedFsDatePrompt",
      ...confirmationPrompt
    }
  },
  values.unAuditedFsDatePrompt === "yes" && {
    title:
      "What is the date of the last unaudited statutory  financial statement of the company?",
    input: {
      type: "date",
      name: iaKeys.UNAUDITED_FS_DATE
    }
  },
  {
    title:
      "Does the company have a valid management profit and loss statement and management balance sheet?",
    input: {
      name: "managementPlDatePrompt",
      ...confirmationPrompt
    }
  },
  values.managementPlDatePrompt === "yes" && {
    title:
      "What is the date of the last management profitand loss statement and management balance sheet?",
    input: {
      type: "date",
      name: iaKeys.MANAGEMENT_PL_DATE
    }
  },
  {
    title:
      "Does the company have a current equity amount according to the last valid balance sheet?",
    input: {
      name: "entityEquityAmountPrompt",
      ...confirmationPrompt
    }
  },
  values.entityEquityAmountPrompt === "yes" && {
    title:
      "What is the current equity amount according to the last valid balance sheet?",
    input: {
      type: "number",
      name: iaKeys.ENTITY_EQUITY_AMOUNT,
      formatter: value => {
        return value
          ? `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
          : value;
      },
      parser: value => value.replace(new RegExp(`CHF \\s?|(\\'*)`, "g"), "")
    }
  },
  {
    showId: false,
    title: "Warranties",
    className: "_fullwidth _bold-15 _hidden",
    input: {}
  },
  {
    title:
      "All contracts, agreements or commitments relating to the business providing for the payment by the Company in one or several installments with an amount equal or above have been shown to the investors",
    input: {
      name: iaKeys.ENTITY_BUSINESS_AGREEMENT_AMOUNT,
      type: "select_btn",
      options: [
        basicOption("30000", "30k"),
        basicOption("50000", "50k"),
        basicOption("75000", "75k"),
        basicOption("Other")
      ]
    }
  },
  values[iaKeys.ENTITY_BUSINESS_AGREEMENT_AMOUNT] === "Other" && {
    input: {
      name: iaKeys.ENTITY_BUSINESS_AGREEMENT_AMOUNT_OTHER,
      type: "number",
      ...inputNumberFormatter("CHF")
    }
  },

  {
    title:
      "No offer and / or tender which is capable of being converted into an obligation of the Company by acceptance of a third party is outstanding (offers and / or tenders in the ordinary course of business are excluded)",
    input: {
      type: "number",
      name: iaKeys.ENTITY_CONVERTIBLE_OBLIGATION_AMOUNT,
      formatter: value => {
        return value
          ? `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
          : value;
      },
      parser: value => value.replace(new RegExp(`CHF \\s?|(\\'*)`, "g"), "")
    }
  },
  {
    title:
      "There is no outstanding guarantee, indemnity, surety or comfort letter in excess of",
    input: {
      name: iaKeys.GUARANTEE_EXCESS,
      type: "select_btn",
      options: [
        basicOption("10000", "10k"),
        basicOption("30000", "30k"),
        basicOption("Other")
      ]
    }
  },
  values[iaKeys.GUARANTEE_EXCESS] === "Other" && {
    input: {
      name: iaKeys.GUARANTEE_EXCESS_OTHER,
      type: "number",
      ...inputNumberFormatter("CHF")
    }
  },
  {
    showId: false,
    title: "Damages",
    className: "_fullwidth _bold-15 _hidden",
    input: {}
  },
  {
    title:
      "What is the aggregate amount of damage after which the existing shareholders become liable to it's investors due to a breach of the representations and warranties?",
    input: {
      name: iaKeys.ENTITY_OBLIGATION_AMOUNT,
      type: "select_btn",
      options: [
        basicOption("100000", "100k"),
        basicOption("150000", "150k"),
        basicOption("200000", "200k"),
        basicOption("Other")
      ]
    }
  },
  values[iaKeys.ENTITY_OBLIGATION_AMOUNT] === "Other" && {
    input: {
      type: "number",
      name: iaKeys.ENTITY_OBLIGATION_AMOUNT_OTHER,
      ...inputNumberFormatter("CHF")
    }
  }
];

export default warranties;
