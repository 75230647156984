import { updateUserSettings } from "#core/modules/users/actions/update";
import { connect } from "react-redux";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    _data: { isActive },
    isUpdating,
    didUpdate
  } = state.users;

  return {
    isActive,
    isUpdating,
    didUpdate
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserSettings: data => dispatch(updateUserSettings(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
