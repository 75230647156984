import { entityTypes } from "#constants/entity";
import { roles } from "#constants/roles";
import { connect } from "react-redux";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    _data: {
      confirmationLevel: userConfirmationLevel,
      twoStepAuthActive,
      tokenAuth
    } = {}
  } = state.users;

  const { _data: { entity: { userRole, type } = {} } = {} } = state.entities;

  const role =
    type === entityTypes.ASSET_MANAGER_ENTITY && userRole === "founder" // AM PRIVILEGES FOR AM_FOUNDERS
      ? roles.ASSET_MANAGER
      : userRole;

  return {
    userConfirmationLevel,
    twoStepAuthActive,
    tokenAuth,
    role
  };
};

export default connect(mapStateToProps)(Presentational);
