import { roles } from "#constants/roles";
import SpinContainer from "../../../../Shared/SpinContainer";

const { useEffect, useRef } = require("react");
const { useParams, useLocation } = require("react-router");

const TaxReportGenerator = ({
  history,
  loading,
  yearlyReport,
  success,
  getYearlyReport,
}) => {
  const { year } = useParams();
  const { search } = useLocation();
  const [entityId, userRole] = search.slice(1).split('&');
  const downloadRef = useRef();
  const download = history?.location?.search?.includes("download");
  const isInvestor =
    userRole === roles.AM_INVESTOR || userRole === roles.INVESTOR;

  useEffect(() => {
    getYearlyReport(userRole, { year, entityId });
  }, []);

  useEffect(() => {
    if (success) {
      if (download && yearlyReport) {
        downloadRef.current?.click();
        history.push("/tax_reports");
      } else if (yearlyReport)
        history.push("/doc_viewer", { data: yearlyReport });
    }
  }, [loading, success, download, yearlyReport]);

  return (
    <>
      {download && yearlyReport && (
        <a
          ref={downloadRef}
          href={yearlyReport}
          download={`Yearly report ${year}`}
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
      <SpinContainer />
    </>
  );
};

export default TaxReportGenerator;
