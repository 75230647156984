import React from "react";
import { Col } from "antd";
import LinkedIn from "../../../../assets/illustrations/linked-in.png";

export default function Presentational({
  member: {
    profilePic,
    secondaryProfilePic,
    name,
    role,
    description,
    linkedIn,
    width
  } = {}
}) {
  console.log(linkedIn);
  return (
    <Col xs={22} sm={22} md={10} lg={8} xl={8} className="team-block-wrapper">
      <div className="team-block">
        <img className="team-block-img" src={profilePic} alt="member" />
        {secondaryProfilePic && (
          <img
            className="team-block-secondary-img"
            src={secondaryProfilePic}
            alt="secondary member"
          />
        )}
        <div className="team-block-info">
          {description && (
            <p className="team-block-description text">{description}</p>
          )}
          {linkedIn && (
            <a href={linkedIn} target="_blank" className="team-block-linkedin">
              <img src={LinkedIn} className="linked-in-logo" />
            </a>
          )}
        </div>
      </div>
      <div className="team-block-name-role">
        <h1 className="l3-title">{name}</h1>
        <p className="l4-title">
          <b>{role}</b>
        </p>
      </div>
    </Col>
  );
}
