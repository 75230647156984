import { connect } from 'react-redux';
import { getEntities } from '#core/modules/entities/actions';
import {
  getIoMemberships,
  putIoMembershipStatus
} from '#core/modules/iomemberships/actions';
import { clearState } from '#core/utils/helper_actions';
import Presentational from './Presentational';

const mapStateToProps = (state) => {
  const { _data: { entitiesList = [] } = {}, didFetch: entityDidFetch } =
    state.entities || {};
  const {
    _data: { memberships = [], objectRef } = {},
    didFetch: ioDidFetch,
    isUpdating,
    didUpdate
  } = state.ioMemberships || {};

  return {
    entitiesData: {
      list: entitiesList,
      didFetch: entityDidFetch
    },
    iosData: {
      isUpdating,
      didUpdate,
      list: memberships,
      didFetch: ioDidFetch,
      objectRef
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEntities: (params) => dispatch(getEntities(params)),
  getIoMemberships: (params) => dispatch(getIoMemberships(params)),
  putIoMembershipStatus: (params) => dispatch(putIoMembershipStatus(params)),
  clearState: (key) => clearState(dispatch, key)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
