import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// You can remove the logout screen and use the action in the logout button
// after that you can redirect the screen with a router method in componentWillReceiveProps

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <Redirect from="/logout" to="/" />;
  }
}

Logout.propTypes = {
  logout: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: 'LOGOUT' })
  };
}

export default connect(
  null,
  mapDispatchToProps
)(Logout);
