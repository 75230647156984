import invitationAccepted from "../../../../assets/invited-investors/invitation-accepted.png";

const InvitationAccepted = () => (
  <img
    src={invitationAccepted}
    alt="invitation-accepted"
    width="30"
    height="30"
  />
);

export default InvitationAccepted;
