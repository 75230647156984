import { Menu, Dropdown } from "antd";
import { NavLink } from "react-router-dom";
import { roles } from "#constants/roles";
import { mapClvl } from "#helpers/mappers/memberships";
import { useGetColumnSearchProps } from "#helpers/hooks/table";
import { Button } from "../../../../Shared/Button";
import { dmGuideline as DmGuideline } from "../../../../Shared/Icons";

export const investorListColumns = ({
  sortedInfo,
  filteredInfo,
  getNdaDoc
}) => [
  {
    title: "Status",
    dataIndex: "confirmationLevel",
    key: "status",
    filters: [
      { text: "Pending", value: 0 },
      { text: "Confirmed", value: 1 },
      { text: "Onboarded", value: 2 },
      { text: "Kyc completed", value: 3 },
      { text: "Aml completed", value: 4 }
    ],
    filteredValue: filteredInfo.status || null,
    onFilter: (value, record) => record.confirmationLevel === value,
    render: cLvl => mapClvl(cLvl),
    role: roles.ASSET_MANAGER
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
    render: (_, { firstName, lastName }) => `${firstName} ${lastName}`,
    width: 200,
    ...useGetColumnSearchProps({ dataIndex: "name" })
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
    sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
    key: "email",
    width: 300,
    ...useGetColumnSearchProps({ dataIndex: "email" })
  },
  {
    title: "Deal name",
    dataIndex: "deals",
    key: "deals",
    width: 200,
    sorter: (a, b) => a.deals.localeCompare(b.deals),
    sortOrder: sortedInfo.columnKey === "deals" && sortedInfo.order,
    ...useGetColumnSearchProps({
      dataIndex: "deals",
      render: value => {
        const getLabel = () => {
          const len = value.length;
          if (len === 0) {
            return "No deals";
          } else if (len === 1) {
            return `1 deal`;
          }
          return `${len} deals`;
        };
        return (
          value.length > 0 && (
            <Dropdown.Button
              overlay={
                <Menu className="investor-deals-menu">
                  {value.map(({ cuid, name }) => (
                    <Menu.Item key={cuid}>
                      <NavLink to={`/dashboard/deal_management/${cuid}`}>
                        {name}
                      </NavLink>
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              {getLabel()}
            </Dropdown.Button>
          )
        );
      },
      onFilter: (value, record) => {
        if (record["deals"]) {
          return record["deals"].some(({ name }) =>
            name.toLowerCase().includes(value.toLowerCase())
          );
        }
      }
    })
  },
  {
    title: "NDA",
    key: "nda",
    role: roles.ASSET_MANAGER,
    render: (_, { userId, nda }) => (
      <div className="nda-col-buttons-wrapper">
        <Button
          icon="download"
          className="download-btn nda-download-btn"
          disabled={!nda}
          onClick={() => getNdaDoc({ cuid: nda })}
          tooltip={!Boolean(nda) && "Investor didn't sign the NDA yet"}
        />
        <Button
          href={`/generate/contract/${nda}`}
          hrefProps={{ target: "_blank", rel: "noopener noreferrer" }}
          disabled={!nda}
          label={<DmGuideline />}
          type="link"
        />
      </div>
    )
  }
];

export const filteringOpts = [
  {
    value: "entity",
    label: "Entity name"
  },
  {
    value: "deal",
    label: "Deal name"
  }
];

export const mockData = [
  {
    name: "John Doe",
    confirmationLevel: 2,
    deals: ["Ventures Capital", "Leva"],
    email: "johndoe@gmail.com"
  },
  {
    name: "Jane Doe",
    confirmationLevel: 0,
    deals: [],
    email: "johndoe@gmail.com"
  },
  {
    name: "Filan Fisteku",
    confirmationLevel: 2,
    deals: ["Ventures Capital", "Leva", "Inv II"],
    email: "johndoe@gmail.com"
  }
];
