/**
 * @param {array} list Array or a function that returns an array to derive the input keys from
 * @param {object} values Object to derive the values from
 * @returns {object}
 */

export const initValues = (list = [], values = {}, raw) => {
  const array = typeof list === "function" ? list({}) : list;

  if (raw) {
    return values;
  }

  return array.filter(Boolean).reduce((acc, item) => {
    const key = item.input && item.input.name;

    return {
      ...acc,
      [key]: values[key] || ""
    };
  }, {});
};
