const CapTable = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 15.9249C9.84441 15.9249 8.07495 14.1554 8.07495 12C8.07495 9.84447 9.84441 8.07501 11.9999 8.07501C14.1554 8.07501 15.9248 9.84447 15.9248 12C15.9248 14.1554 14.1554 15.9249 11.9999 15.9249ZM11.9999 10.0054C10.9061 10.0054 10.0052 10.9062 10.0052 12C10.0052 13.0938 10.9061 13.9947 11.9999 13.9947C13.0937 13.9947 13.9945 13.0938 13.9945 12C13.9945 10.9062 13.0938 10.0054 11.9999 10.0054Z"
        fill="#18DC9B"
      />
      <path
        d="M11.9999 20.1716C7.49592 20.1716 3.82837 16.5041 3.82837 12.0001C3.82837 7.49604 7.49598 3.82843 11.9999 3.82843C16.5039 3.82843 20.1715 7.49598 20.1715 12C20.1715 16.504 16.504 20.1716 11.9999 20.1716ZM11.9999 5.75873C8.55759 5.75873 5.75867 8.55765 5.75867 12C5.75867 15.4424 8.55759 18.2413 11.9999 18.2413C15.4423 18.2413 18.2412 15.4424 18.2412 12C18.2412 8.55765 15.4423 5.75873 11.9999 5.75873Z"
        fill="#597EF7"
      />
      <path
        d="M21.1367 19.7855C22.9062 17.6944 24 14.9919 24 12C24 9.008 22.9062 6.33777 21.1367 4.21443L23.1635 2.18763C23.5496 1.8016 23.5496 1.19031 23.1635 0.83642C22.7775 0.450384 22.1662 0.450384 21.8123 0.83642L19.7855 2.86321C17.6944 1.09382 14.9919 0 12 0C9.0402 0 6.33777 1.09382 4.21443 2.86327L2.1877 0.836481C1.80166 0.450446 1.19037 0.450446 0.836481 0.836481C0.450446 1.22252 0.450446 1.8338 0.836481 2.1877L2.86327 4.21449C1.09382 6.30563 0 9.00806 0 12C0 14.9597 1.09382 17.6622 2.86327 19.7855L0.836481 21.8123C0.450446 22.1983 0.450446 22.8096 0.836481 23.1635C1.02953 23.3566 1.28687 23.4531 1.51206 23.4531C1.76946 23.4531 1.99465 23.3566 2.18763 23.1635L4.21443 21.1367C6.30557 22.9062 9.008 24 12 24C14.9919 24 17.6622 22.9062 19.7855 21.1367L21.8123 23.1635C22.0054 23.3566 22.2627 23.4531 22.4879 23.4531C22.7131 23.4531 22.9705 23.3566 23.1635 23.1635C23.5495 22.7775 23.5495 22.1662 23.1635 21.8123L21.1367 19.7855ZM1.9303 12C1.9303 6.43427 6.43433 1.93024 12 1.93024C17.5657 1.93024 22.0697 6.43433 22.0697 12C22.0697 17.5656 17.5657 22.0697 12 22.0697C6.43427 22.0697 1.9303 17.5657 1.9303 12Z"
        fill="#18DC9B"
      />
    </svg>
  );
};

const FoundersCaptable = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15.9249C9.84447 15.9249 8.07501 14.1554 8.07501 11.9999C8.07501 9.84445 9.84447 8.075 12 8.075C14.1554 8.075 15.9249 9.84445 15.9249 11.9999C15.9249 14.1554 14.1555 15.9249 12 15.9249ZM12 10.0054C10.9061 10.0054 10.0053 10.9062 10.0053 12C10.0053 13.0938 10.9061 13.9947 12 13.9947C13.0938 13.9947 13.9946 13.0938 13.9946 12C13.9946 10.9062 13.0938 10.0054 12 10.0054Z"
      fill="#18DC9B"
    />
    <path
      d="M12.0001 20.1716C7.49604 20.1716 3.82849 16.5041 3.82849 12C3.82849 7.49601 7.4961 3.8284 12.0001 3.8284C16.504 3.8284 20.1717 7.49595 20.1717 12C20.1717 16.504 16.5041 20.1716 12.0001 20.1716ZM12.0001 5.7587C8.55771 5.7587 5.75879 8.55762 5.75879 12C5.75879 15.4423 8.55771 18.2413 12.0001 18.2413C15.4424 18.2413 18.2413 15.4423 18.2413 12C18.2413 8.55762 15.4424 5.7587 12.0001 5.7587Z"
      fill="#597EF7"
    />
    <path
      d="M21.1367 19.7855C22.9062 17.6944 24 14.9919 24 12C24 9.008 22.9062 6.33777 21.1367 4.21443L23.1635 2.18763C23.5496 1.8016 23.5496 1.19031 23.1635 0.83642C22.7775 0.450384 22.1662 0.450384 21.8123 0.83642L19.7855 2.86321C17.6944 1.09382 14.9919 0 12 0C9.0402 0 6.33777 1.09382 4.21443 2.86327L2.1877 0.836481C1.80166 0.450446 1.19037 0.450446 0.836481 0.836481C0.450446 1.22252 0.450446 1.8338 0.836481 2.1877L2.86327 4.21449C1.09382 6.30563 0 9.00806 0 12C0 14.9597 1.09382 17.6622 2.86327 19.7855L0.836481 21.8123C0.450446 22.1983 0.450446 22.8096 0.836481 23.1635C1.02953 23.3566 1.28687 23.4531 1.51206 23.4531C1.76946 23.4531 1.99465 23.3566 2.18763 23.1635L4.21443 21.1367C6.30557 22.9062 9.008 24 12 24C14.9919 24 17.6622 22.9062 19.7855 21.1367L21.8123 23.1635C22.0054 23.3566 22.2627 23.4531 22.4879 23.4531C22.7131 23.4531 22.9705 23.3566 23.1635 23.1635C23.5495 22.7775 23.5495 22.1662 23.1635 21.8123L21.1367 19.7855ZM1.9303 12C1.9303 6.43427 6.43433 1.93024 12 1.93024C17.5657 1.93024 22.0697 6.43433 22.0697 12C22.0697 17.5656 17.5657 22.0697 12 22.0697C6.43427 22.0697 1.9303 17.5657 1.9303 12Z"
      fill="#18DC9B"
    />
  </svg>
);

export default CapTable;

export { FoundersCaptable };
