import { handleNav } from '#helpers/general';

const FounderInviteSuccess = ({ history, onboarding }) => {
  const handleClick = handleNav(history, {
    pathname: '/login',
    state: { onboarding: false }
  });

  return (
    <div className="success-view">
      <h1>
        Congratulations! You have succesfully set up your account. Click{' '}
        <a onClick={handleClick}>here</a> to log in.
      </h1>
    </div>
  );
};

export default FounderInviteSuccess;
