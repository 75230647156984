const formSections = [
  {
    id: 1,
    title: "A. Stakeholders",
    subSections: [
      {
        id: 1,
        title: "Shareholders"
      },
      {
        id: 2,
        title: "Board of Director"
      },
      {
        id: 3,
        title: "Auditor"
      }
    ]
  },
  {
    id: 4,
    title: "B. Warranties"
  },
  {
    id: 5,
    title: "C. Confidentialty"
  },
  {
    id: 6,
    title: "D. Successors"
  },
  {
    id: 7,
    title: "E. Appendices",
    subSections: [
      {
        id: 7,
        title: "Schedules"
      },
      {
        id: 8,
        title: "Appendices"
      }
    ]
  }
];

export default formSections;
