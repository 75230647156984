import { connect } from 'react-redux';
import { createFounderInvitation } from '#core/modules/entities/actions';
import Presentational from './Presentational';

const mapStateToProps = ({ entities }) => ({
  buttonStates: {
    success: entities.didCreate,
    loading: entities.isCreating
  }
});

const mapDispatchToProps = (dispatch) => ({
  createFounderInvitation: (data) => dispatch(createFounderInvitation(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
