import actionStatus from '../constants/action_status';
import actionType from '../constants/action_type';

/* Basic initial state */

// initialState with lifecycle properties for the async calls for each method
// _data: save data comming from backend

export const initialState = {
  isCreating: false,
  didCreate: false,

  isFetching: false,
  didFetch: false,

  isUpdating: false,
  didUpdate: false,

  isDeleting: false,
  didDelete: false,
  error: '',

  _data: undefined
};


/* Function for updating state based on action status */

// Create

/**
 * @param {object} cb object that takes three kinds of callbacks, start, success, fail
 * in order to modify the data in each phase of the async call.
 */

const createHandler = (action, state, cb) => {
  switch (action.status) {
    case actionStatus.ACTION_START:
      return {
        ...state,
        isCreating: true,
        didCreate: false,
        error: '',
        _data: cb.start ? cb.start() : state._data
      };
    case actionStatus.ACTION_SUCCESS:
      return {
        ...state,
        isCreating: false,
        didCreate: true,
        error: '',
        _data: cb.success ? cb.success() : state._data,
      };
    case actionStatus.ACTION_FAIL:
      return {
        ...state,
        isCreating: false,
        didCreate: false,
        error: action.payload.error,
        _data: cb.fail ? cb.fail() : state._data,
      };
    default:
      return state;
  }
};

// Read

const fetchHandler = (action, state, cb) => {
  switch (action.status) {
    case actionStatus.ACTION_START:
      return {
        ...state,
        isFetching: true,
        didFetch: false,
        error: '',
        _data: cb.start ? cb.start() : state._data
      };
    case actionStatus.ACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        didFetch: true,
        error: '',
        _data: cb.success ? cb.success() : state._data
      };
    case actionStatus.ACTION_FAIL:
      return {
        ...state,
        isFetching: false,
        didFetch: false,
        error: action.payload.error,
        _data: cb.fail ? cb.fail() : state._data
      };
    default:
      return state;
  }
};

// Update

const updateHandler = (action, state, cb) => {
  switch (action.status) {
    case actionStatus.ACTION_START:
      return {
        ...state,
        isUpdating: true,
        didUpdate: false,
        error: '',
        _data: cb.start ? cb.start() : state._data,
      };
    case actionStatus.ACTION_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        didUpdate: true,
        error: '',
        _data: cb.success ? cb.success() : state._data,
      };
    case actionStatus.ACTION_FAIL:
      return {
        ...state,
        isUpdating: false,
        didUpdate: false,
        _data: cb.fail ? cb.fail() : state._data,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

// Delete

const deleteHandler = (action, state, cb) => {
  switch (action.status) {
    case actionStatus.ACTION_START:
      return {
        ...state,
        isDeleting: true,
        didDelete: false,
        error: '',
        _data: cb.start ? cb.start() : state._data,
      };
    case actionStatus.ACTION_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        didDelete: true,
        error: '',
        _data: cb.success ? cb.success() : state._data,
      };
    case actionStatus.ACTION_FAIL:
      return {
        ...state,
        isDeleting: false,
        didDelete: false,
        error: action.payload.error,
        _data: cb.fail ? cb.fail() : state._data,
      };
    default:
      return state;
  }
};

/**
 * @param {object} action
 * @param {object} state
 * @param {string} type type of the action happening FETCH, UPDATE, DELETE.
 * @param {object} cb object with callbacks to modify the _data property.
 */
export const baseReducer = (action, state, type, cb) => {
  switch (type) {
    case actionType.CREATE:
      return createHandler(action, state, cb);
    case actionType.FETCH:
      return fetchHandler(action, state, cb);
    case actionType.UPDATE:
      return updateHandler(action, state, cb);
    case actionType.DELETE:
      return deleteHandler(action, state, cb);
    default:
      return null;
  }
};
