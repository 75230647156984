export const dmUpdates = [
  "New UX and UI – We redesigned the navigation and simplified the setup of new deals.",
  "Investor management – Filter and sort your investors and monitor their status in a deal.",
  "Updates for your investors -  They can now download the Terms of Syndicate and even do the KYC on their mobile phone.",
  "Automated notifications – There are tons of new notifications. Activate the notifications by clicking the bell icon."
];

export const investorUpdates = [
  "The deal card and deal dashboard have been redesigned.",
  "Get instant notifications in your browser! Activate the notifications by clicking the bell icon.",
  "Download the terms of syndicate after the deal is closed."
];

export const privacyPolicyUpdates = [
  "To improve our product and your experience on Leva, we integrated services from: Calendly, Zapier, Typeform, Loom, Notion, WhatsApp, and Sumsub.",
  "To improve the onboarding process and make the anti-money laundering checks smoother for you, we integrated Sumsub."
];
