import { useEffect } from "react";
import { formatNumber } from "#helpers/number_format";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";
import { readShareItem } from "#helpers/mappers/shares";
import Title from "../../../../../Shared/Title";
import SharedTable from "../../../../../Shared/SharedTable/Presentational";
import { Button } from "../../../../../Shared/Button";
import { shareTableCols } from "./cols";
import Tick from "../../../../../../assets/illustrations/tick.svg";
import Tooltip from "../../../../../Shared/Tooltip";

const SharesManagement = ({
  entityId,
  getEntityCapTable,
  sharesList,
  loading,
  clearState,
  deleteShareClasses,
  getShareClasses,
  entityShares
}) => {
  useEffect(() => {
    clearState("shares");
    getShareClasses({ entity_id: entityId });
    getEntityCapTable({ entity_id: entityId });
  }, []);
  const history = useHistory();

  const handleSharesAdditionNav = () => {
    history.push("/shares-management");
  };

  const handleDelete = id => () => {
    deleteShareClasses(id);
  };

  const handleEdit = id => () => {
    history.push("/shares-management/" + id);
  };
  const addSharesCatAction = (
    <Button icon="plus" onClick={handleSharesAdditionNav} shape="circle" />
  );

  const totalShares = sharesList.reduce((acc, share) => {
    return acc + share.total;
  }, 0);

  const formattedData = sharesList.map(item => readShareItem(item));

  return (
    <div id="shares-management" className="shares-management">
      <Title title="Share management" action={addSharesCatAction} />
      <SharedTable
        columns={shareTableCols(handleDelete, handleEdit)}
        dataSource={formattedData}
        tableProps={{ loading }}
        footer={() => (
          <span>
            Total shares: {` ${formatNumber(totalShares)}`}{" "}
            {totalShares === entityShares && (
              <Tooltip title="The total shares equal your total outstanding shares">
                <img
                  style={{ margin: "0 0 6px 1%" }}
                  src={Tick}
                  alt="tick"
                  height="20px"
                />
              </Tooltip>
            )}
          </span>
        )}
      />
    </div>
  );
};

SharesManagement.propTypes = {
  entityId: propTypes.string,
  getEntityCapTable: propTypes.func,
  sharesList: propTypes.array,
  loading: propTypes.bool,
  clearState: propTypes.func
};

export default SharesManagement;
