import { PureComponent } from "react";
import PropTypes from "prop-types";
import { notification } from "antd";
import { Button } from "../Button";

export class NotificationToast extends PureComponent {
  componentDidUpdate(prevProps) {
    const {
      snackbar: { open: currOpen }
    } = this.props;
    const {
      snackbar: { open: prevOpen }
    } = prevProps;

    if (currOpen && currOpen !== prevOpen) {
      this.openNotifs();
    }
  }

  onClose = () => {
    const { triggerSnackbar } = this.props;
    const {
      message = " ",
      variant = "info",
      placement = "bottomLeft"
    } = this.props.snackbar;

    if (triggerSnackbar) {
      triggerSnackbar({
        variant,
        message,
        placement,
        open: false,
        duration: 4.5
      });
    }
  };

  openNotifs = () => {
    const {
      message = "",
      description: outerDescription,
      variant = "info",
      action,
      duration = 4.5
    } = this.props.snackbar;

    const customDescription = ({ description, label, handlers }) => (
      <div className="notifs-action-container">
        <span>{description}</span>
        <Button label={label} {...handlers} type="ghost" />
      </div>
    );

    notification[variant]({
      message,
      description: action ? customDescription(action) : outerDescription,
      placement: "bottomLeft",
      onClose: this.onClose,
      duration
    });
  };

  render() {
    return null;
  }
}

NotificationToast.propTypes = {
  triggerSnackbar: PropTypes.func,
  snackbar: PropTypes.object,
  resendEmailConfirmation: PropTypes.func
};
