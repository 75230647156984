import { basicOption } from '#helpers/general';

const management = [
  {
    title:
      'How ofen shall the management inform the board regarding business, financial and development issues of the company?',
    input: {
      type: 'select_btn',
      name: 'informBoard',
      options: [
        basicOption('Monthly', 'Monthly'),
        basicOption('Quarterly', 'Quarterly'),
      ],
    },
  },

  {
    title:
      'In the event of which development or occurrence that has the potential to affect the Company, the management shall immediately inform the board in writing?',
    input: {
      type: 'select_btn',
      name: 'writingInform',
      options: [
        basicOption('Materially Influence', 'Materially Influence'),
        basicOption(
          'Materially  and Negatively Influence',
          'Materially  and Negatively Influence'
        ),
      ],
    },
  },
];

export default management;
