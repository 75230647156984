import { Component } from "react";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import storageKeys from "#constants/storage";
import getLoginSchema from "#validators/loginSchema";
import { checkIfMobileOrTablet } from "#helpers/general";
import { submitDefaultLogin } from "#helpers/mappers/login";
import { deleteItem } from "../../../utils/storage";
import { BasicForm } from "../../Shared/Form";
import BtnContainer from "../common/btnContainer";
import Layout from "../common/Layout";
import { formList } from "./components/list";
import Maintenance from "./components/maintenance";
import SignInGraphic from "../../../assets/images/signin.png";
class LoginPage extends Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(location.search);
    this.state = {
      parsedUser: parsed.user
    };
  }
  componentDidMount() {
    const {
      twoStepAuthActive,
      tokenAuth,
      clearStateData,
      location,
      logout
    } = this.props;

    const { parsedUser } = this.state;

    if (twoStepAuthActive && !tokenAuth) {
      // RESET STATE IF 2FA HASN'T GONE THROUGH COMPLETELY
      deleteItem(storageKeys.JWT);
      clearStateData("users");
    }

    if (parsedUser) {
      this.props.submitLogin(submitDefaultLogin({ email: parsedUser }));
    }
  }

  handleSubmit = values => {
    const mobileOrTablet = checkIfMobileOrTablet();
    const {
      twoStepAuthActive,
      tokenAuth,
      verifyToken,
      submitLogin
    } = this.props;
    if (mobileOrTablet) {
      this.props.history.push("/phone");
    } else if (twoStepAuthActive && !tokenAuth) {
      verifyToken(values.token);
    } else {
      const { email, password } = values;

      submitLogin({ email, password });
    }
  };

  render() {
    const {
      isAuthComplete,
      token,
      twoStepAuthActive,
      buttonStates,
      location
    } = this.props;
    const { parsedUser } = this.state;
    const { ENV } = process.env;

    const directOnboarding = location.state && location.state.onboarding;
    const { redirectTo } = queryString.parse(location.search) || {};

    if (isAuthComplete && directOnboarding) {
      return <Redirect to="/onboarding/questionnaire" />;
    }

    if (isAuthComplete && parsedUser === undefined) {
      return <Redirect to={redirectTo} />;
    }

    if (isAuthComplete && parsedUser) {
      return <Redirect to="/onboarding" />;
    }
    const twoFaEnabled = token && twoStepAuthActive;
    const formArgs = {
      wrapperClassname: "login-form",
      handleSubmit: this.handleSubmit,
      fieldList: formList.filter(
        item =>
          !item.twoStepAuthActive || (item.twoStepAuthActive && twoFaEnabled)
      ),
      CustomFooter: BtnContainer({ label: "Sign in " }),
      validationSchema: getLoginSchema(token, twoStepAuthActive),
      buttonStates
    };

    return (
      <Layout
        showLeftPane
        graphic={SignInGraphic}
        leftPaneTitle="Welcome back!"
        leftPaneSubtitle="Pick up where you left off"
        customWrapperClassname="login-context"
      >
        <div className="login-container">
          <h1>Sign in to Leva</h1>
          {ENV === "MAINTENANCE" ? (
            <Maintenance />
          ) : (
            <BasicForm {...formArgs} />
          )}
        </div>
      </Layout>
    );
  }
}

export default LoginPage;
