import { useEffect, useState } from "react";
import { Row, Col, Tabs } from "antd";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { convertToBase64 } from "#helpers/convertBase64";
import { zefixHandlers } from "#helpers/zefix";
import { entityTypes } from "#constants/entity";
import profileSchema from "#validators/profileSchema";
import { roles } from "#constants/roles";
import {
  mapUpdateEntity,
  mapBankAccountsFromBE,
  mapLegalRepresentativesFromBE,
  mapUserFromBE
} from "#helpers/mappers/entitySettings";
import { removeUndefinedFromObject } from "#helpers/general";
import { BasicForm } from "../Shared/Form";
import { profileFields } from "./components/fieldList";
import { companyFields as legelEntityList } from "../Authorization/FounderInvitation/components/entityFieldsList";
import CustomInputRenderer from "../Authorization/FounderInvitation/components/CustomInputRenderer";
import MyAccount from "../../components/MyAccount/Container";
import TwoFactorAuth from "../Authorization/TwoFactorAuth/Container";

const EntityAccount = ({
  actions,
  buttonStates,
  user,
  previewImage,
  currenciesFetched,
  currencies,
  entity,
  entityId
}) => {
  const [frmtdCurrencies, setFrmtedCurrencies] = useState({});
  const [tabKey, setTabKey] = useState("0");
  const zefixHandlersWrapper = zefixHandlers();
  const [updatedPreviewImage, setUpdatedPreviewImage] = useState(null);
  const history = useHistory();
  const isLegalEntity = entity?.legalType === entityTypes.LEGAL_ENTITY;

  useEffect(() => {
    actions.clearState("entities");
    actions.clearState("currencies");
    actions.getCurrentUser();
    actions.readCurrencies();

    // We only need to getEntity if the user is a dm (it's making a strange bug on investor side), this is a workaround because the dm's dont have the onboardingDate field
    if (entityId && !user?.onboardingDate) actions.getEntity(entityId);
  }, []);

  const customHook = ({ setValues }) => {
    useEffect(() => {
      if (isLegalEntity) {
        const { address = {}, bankAccounts, legalRepresentatives } = entity;
        const bankAcc = mapBankAccountsFromBE(bankAccounts);
        const legalReps = mapLegalRepresentativesFromBE(legalRepresentatives);
        setValues({
          ...entity,
          address: address.street,
          ...address,
          bankAcc,
          legalReps
        });
      } else {
        setValues({
          ...mapUserFromBE(user)
        });
      }
    }, [buttonStates.didFetch, entity]);
  };

  useEffect(() => {
    if (currenciesFetched) {
      const frmtdCurrencies = currencies.reduce(
        (acc, { cuid, shortName }) => ({
          ...acc,
          [cuid]: shortName
        }),
        {}
      );
      setFrmtedCurrencies(frmtdCurrencies);
    }
  }, [currenciesFetched]);

  const handleUpload = () => info => {
    setUpdatedPreviewImage(info.file.originFileObj);
  };

  const handleTabChange = activeKey => setTabKey(activeKey);

  const handleSubmit = async values => {
    let payload = {};
    const { email, profilePicture, ...localValues } = values;
    if (updatedPreviewImage) {
      payload.previewImageBase64 = await convertToBase64(updatedPreviewImage);
    }
    if (entity?.legalType === entityTypes.LEGAL_ENTITY) {
      payload = {
        ...payload,
        ...removeUndefinedFromObject(mapUpdateEntity(values))
      };
      actions.updateEntity({ cuid: entityId, ...payload });
    } else {
      payload = { ...payload, ...localValues };
      actions.updateUserSettings(payload);
    }
    setTimeout(() => {
      history.push("/dashboard");
    }, 3000);
  };

  const profilePic = updatedPreviewImage
    ? URL.createObjectURL(updatedPreviewImage)
    : previewImage;

  const fieldList =
    entity?.legalType === entityTypes.LEGAL_ENTITY
      ? legelEntityList({
          zefixHandlers: zefixHandlersWrapper,
          loading: buttonStates.loading,
          previewImage: profilePic,
          currencies: frmtdCurrencies
        })
      : profileFields();

  const args = {
    handleSubmit,
    handleCancel: () => history.goBack(),
    buttonStates,
    wrapperClassname: "my-account-form",
    values: user,
    fieldList,
    validationSchema:
      entity?.legalType === entityTypes.LEGAL_ENTITY ? null : profileSchema,
    useCustomHook: customHook,
    CustomInputRenderer,
    handleUpload
  };

  return (
    <div className="settings-wrapper">
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <Tabs onChange={handleTabChange} activeKey={tabKey} type="card">
            <Tabs.TabPane tab="Your personal account" key="0">
              <MyAccount history={history} />
            </Tabs.TabPane>
            {/* {isLegalEntity && (
              <Tabs.TabPane tab="Your entity account" key="1">
                <Col span={12}>
                  <BasicForm {...args} />
                </Col>
              </Tabs.TabPane>
            )} */}
            {/* <Tabs.TabPane tab="2FA" key="2">
              <TwoFactorAuth />
            </Tabs.TabPane> */}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

EntityAccount.propTypes = {
  getCurrentUser: PropTypes.func,
  user: PropTypes.object,
  buttonStates: PropTypes.object,
  history: PropTypes.object,
  updateUserSettings: PropTypes.func,
  triggerSnackbar: PropTypes.func
};
export default EntityAccount;
