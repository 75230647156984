import axios from "./apiConfig";

export const getPlatformActivity = () =>
  axios.get("activity/platform-visits-per-user/count");

export const postPlatformActivity = visit =>
  axios.post("activity/platform-visits", visit);

export const getDocsVisit = attachmentId => {
  let route = "activity/investment_doc_visits";

  if (attachmentId) {
    route += `?attachment_id=${attachmentId}`;
  }
  return axios.get(route);
};

export const postDocVisit = data =>
  axios.post("activity/investment-doc-visits", data);

export const getCountry = () => axios.get(`https://ipapi.co/country_name`);
