import { Button } from "../../../../../Shared/Button";

const ActionContainer = ({
  children,
  buttonProps,
  actionVisible,
  buttons = []
}) => {
  const filteredButtons = buttons.filter(Boolean);

  return (
    <div className="action-container">
      <div className="main-content">{children}</div>
      {actionVisible && (
        <div className="btn-container">
          {filteredButtons.length > 0 &&
            filteredButtons.map(button => <Button {...button} />)}
        </div>
      )}
    </div>
  );
};

export default ActionContainer;
