import { basicOption, inputNumberFormatter } from "#helpers/general";
import { formatNumber } from "#helpers/number_format";
import { InformationIcon } from "../../Icons";
import TooltipWrapper from "../../Tooltip";

export const formList = ({ currencies }) => ({ values = {} }) => {
  const withTooltip = (label, tooltip) => (
    <div className="title-container">
      {label}
      <TooltipWrapper title={tooltip}>
        <span>
          <InformationIcon />
        </span>
      </TooltipWrapper>
    </div>
  );
  return [
    {
      className: "_fullwidth",
      title: withTooltip(
        `Date of the official capital increase or issuance of the shares`,
        "If this deal is a convertible loan, which has not yet been converted, insert the date when the convertible loan agreement was signed and executed."
      ),
      details: " (In Switzerland you can check it out here: www.zefix.ch)",
      input: {
        type: "date",
        name: "transactionDate"
      }
    },
    {
      className: "_fullwidth",
      title: withTooltip(
        `Please specify the currency the amounts are defined in`,
        "If this deal is a convertible loan, which has not yet been converted, insert the currency in which you raised funds through LEVA."
      ),
      input: {
        type: "select",
        name: "currency",
        options: Object.keys(currencies).map(value => ({
          value,
          label: currencies[value]
        }))
      }
    },
    {
      className: "_fullwidth",
      title: withTooltip(
        `Total shares outstanding of the target company`,
        "If this deal is a convertible loan, which has not yet been converted, you may also leave this field empty."
      ),
      input: {
        type: "number",
        name: "issuedShares",
        formatter: value => {
          if (isNaN(value)) return "";
          return value && formatNumber(value);
        }
      }
    },
    {
      className: "_fullwidth",
      title: withTooltip(
        "Tax value per share",
        "If this deal is a convertible loan, which has not yet been converted, insert 0 (zero)."
      ),
      details:
        "(in Switzerland you can request this info directly with the startup)",
      input: {
        type: "number",
        name: "taxShareValue",
        ...inputNumberFormatter(currencies[values.currency] || "")
      }
    },
    {
      className: "_fullwidth",
      title: withTooltip(
        `Nominal value per share`,
        "If this deal is a convertible loan, which has not yet been converted, insert 0 (zero)."
      ),
      input: {
        type: "number",
        name: "shareNominalValue",
        ...inputNumberFormatter(currencies[values.currency] || "")
      }
    },
    {
      className: "_fullwidth",
      title: "Are the outstanding shares diluted or non-diluted",
      input: {
        type: "select_btn",
        name: "sharesAreDiluted",
        options: [
          basicOption("diluted", "Diluted"),
          basicOption("non-diluted", "Non-diluted")
        ]
      }
    },
    {
      className: "_fullwidth",
      title: withTooltip(
        `Last available share price`,
        "If this deal is a convertible loan, which has not yet been converted, insert 0 (zero)."
      ),
      input: {
        type: "number",
        name: "lastSharePrice",
        ...inputNumberFormatter(currencies[values.currency] || "")
      }
    }
  ];
};
