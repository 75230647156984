import { useEffect } from "react";
import ioTypes from "#constants/io/ioTypes";
import {
  catchNotifications,
  NOTIFICATION_ACTIONS
} from "#helpers/notifications";
import { commitStatus } from "#constants/commit";

export const useCleanupEffect = (
  actions,
  dealId,
  status,
  executeDealModalOpen,
  isInvestor,
  isAttachmentUpdate
) => {
  useEffect(() => {
    actions.clearState("io");
    actions.clearState("entities");
    actions.clearState("commits");
    actions.clearState("nda");
    actions.clearStateDataByKey("nda", "ndaFetched");
    actions.clearStateDataByKey("commits", "commits");
    actions.clearStateDataByKey(
      "contracts",
      "transConf",
      "dealConf",
      "invConf",
      "invSummary"
    );
  }, []);

  useEffect(() => {
    if (dealId) {
      actions.fetchSingleIo(dealId);
      actions.readCurrencies();
    }
  }, [dealId]);

  useEffect(() => {
    if (
      status?.ioDidUpdate &&
      !executeDealModalOpen &&
      !isInvestor &&
      !isAttachmentUpdate
    ) {
      actions.fetchSingleIo(dealId);
      actions.readCurrencies();
    }
  }, [status?.ioDidUpdate, executeDealModalOpen]);
};

export const useNotificationCatcher = (actions, entity, dealId, userId) => {
  useEffect(() => {
    catchNotifications(
      event => {
        const { data: { msg = {} } = {} } = event;

        const { metadata: { action, targetId } = {} } = msg;

        if (
          targetId === io.cuid &&
          action === NOTIFICATION_ACTIONS.COMMITMENTS_REVIEWED
        ) {
          actions.getCommits({ entity_id: entity.cuid, investment_id: dealId });
        }
      },
      { userId, strict: true }
    );
  }, []);
};

export const useFetchSyndicates = (actions, entityId) => {
  useEffect(() => {
    actions.clearState("syndicate");

    if (entityId) {
      actions.getSyndicates({ entityId: entityId });
    }
  }, [entityId]);
};

export const useFinancialContractGenerator = (
  actions,
  status,
  dealId,
  io,
  entity,
  baseEntity,
  ioMembership,
  isInvestor,
  amCuid,
  isPrivileged,
  dealIsClosed,
  moneyWired,
  setState,
  state
) => {
  useEffect(() => {
    if (status.ioDidFetch && dealId === io.cuid) {
      if (baseEntity.cuid !== entity.cuid) {
        actions.setEntity(entity.cuid);
      }

      if (entity.cuid) {
        actions.getCommits({ entity_id: entity.cuid, investment_id: dealId });

        if (ioMembership.cuid && !ioMembership.tosAcceptedTimestamp) {
          setState({ ...state, tosModalOpen: true });
        }
      }

      if (isInvestor) {
        if (amCuid) {
          actions.getNdas({ asset_manager_id: amCuid });
        }

        if (dealIsClosed) {
          actions.getInvestmentSummary({ io_id: dealId });
        }
      } else {
        actions.getIoMemberships({ io_id: dealId });
      }

      if (isPrivileged) {
        if (moneyWired) {
          actions.contracts.getInvConfirmation({ io_id: dealId });
        }

        if (dealIsClosed) {
          actions.contracts.getDealConfirmation({ io_id: dealId });
        }
      }

      if (io.type === ioTypes.POOLED_FUND) {
        if (!dealIsClosed) {
          actions.getPooledFundTemplates({ amount: 1, io_id: dealId });
        }
      } else {
        actions.generateContracts({ amount: 1, investmentId: dealId });
      }
    }
  }, [status.ioDidFetch, dealId, io.cuid, entity.cuid]);
};

export const useInvestmentConfirmationGenerator = (
  actions,
  dealId,
  io,
  commitState,
  status,
  commits,
  isInvestor,
  moneyWired
) => {
  useEffect(() => {
    if (dealId === io.cuid && commitState.commitsDidFetch) {
      // check if investor has confirmed commits to generate transaction and investment confirmation docs
      const hasInvestments = commits.find(
        ({ status }) => status === commitStatus.COMPLETED
      );

      if (hasInvestments) {
        const params = { io_id: dealId };

        if (moneyWired) {
          actions.contracts.getInvConfirmation(params);
        }

        if (isInvestor) {
          actions.contracts.getTransConfirmation(params);
        }
      }
    }
  }, [status.ioDidFetch, commitState.commitsDidFetch]);
};

export const useNdaPreview = (ndaColl, actions, isInvestor) => {
  useEffect(() => {
    if (ndaColl.didFetch && isInvestor) {
      if (ndaColl.ndaCuid) {
        actions.getNdaDoc({ cuid: ndaColl.ndaCuid });
      }
    }
  }, [ndaColl.didFetch]);
};

export const useUpdatedIoEffect = (
  status,
  dealIsClosed,
  io,
  actions,
  dealId
) => {
  useEffect(() => {
    if (status.ioDidUpdate) {
      if (io.type === ioTypes.POOLED_FUND) {
        if (!dealIsClosed) {
          actions.getPooledFundTemplates({ amount: 1, io_id: dealId });
        }
      } else {
        actions.generateContracts({ amount: 1, investmentId: dealId });
      }
    }
  }, [status.ioDidUpdate]);
};
