import { connect } from "react-redux";
import { triggerSnackbar } from "#core/modules/snackbar/actions";
import { resendEmailConfirmation } from "#core/modules/auth/actions/";
import { clearStateData } from "#core/utils/helper_actions";
import { NotificationToast } from "./Presentational";

const mapStateToProps = state => {
  return {
    snackbar: state.snackbar._data || {}
  };
};
const mapDispatchToProps = dispatch => {
  return {
    triggerSnackbar: snackbar => triggerSnackbar(dispatch, snackbar),
    resendEmailConfirmation: email => dispatch(resendEmailConfirmation(email)),
    clearStateData: key => clearStateData(dispatch, key)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationToast);
