import format from "date-fns/format";
import { convertToBase64 } from "#helpers/convertBase64";

export const mapVotingAssemblyCreationValues = (values, entityId, ioId) => {
  const questions = values.assembly_questions || [];

  return {
    name: "Questionnaire",
    ioId,
    type: values.voting_objective,
    questionnaire: questions.map(({ question, index }) => ({
      question
    })),
    expirationDateTime: format(values.voting_date, "YYYY-MM-DD") + " 00:00:00"
  };
};

export const mapVotingSessionValues = values => {
  return Object.keys(values).map(key => ({
    questionId: key,
    answer: values[key]
  }));
};

export const mapVotingAttachment = async file => {
  const votingAttchBase64 = await convertToBase64(file.originFileObj);
  return {
    name: file?.name,
    category: "voting",
    subCategory: "reports",
    downloadable: true,
    base64: votingAttchBase64
  };
};
