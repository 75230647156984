/**
 * Function to remove a value from an array.
 * @param {*} array  Array to splice.
 * @param {*} value Value to add or remove
 */

const addRemoveFromArraySingle = (array, value) => {
  if (array.includes(value)) {
    return array.filter((el) => {
      return el !== value;
    });
  }
  return [...array, value];
};

/**
 * @param {Array} array Array
 * @param {String} key Property key to compare by
 * @param {String} compare Value to compare by
 * @param {Object} object Object to attach
 *
 */
const addRemoveFromArrayObject = (array, key, compare, object) => {
  if (
    array.some((el) => {
      return el[key] === compare;
    })
  ) {
    return array.filter((el) => {
      return el[key] !== compare;
    });
  }
  return [...array, object];
};

/**
 * Function to sort array by name. Name is a value passed by arguments' label property.
 * @param {Object} a First object whose value we need to compare.
 * @param {Object} b Second object whose value we need to compare.
 */
const sortArrayByName = (a, b) => {
  const aLabel = a.label.toUpperCase();
  const bLabel = b.label.toUpperCase();
  if (aLabel < bLabel) {
    return -1;
  }
  if (aLabel > bLabel) {
    return 1;
  }
  return 0;
};

const formatArrayKeys = (array, valueKey, labelKey) =>
  array.map(({ [valueKey]: value, [labelKey]: label }) => ({ value, label }));

const getMaxArray = (array) => {
  if (array.length === 0) {
    return -1;
  }

  let [max, maxIndex] = [array[0], 0];

  for (let i = 1; i < array.length; i = i + 1) {
    if (array[i] > max) {
      maxIndex = i;
      max = array[i];
    }
  }

  return maxIndex;
};
export {
  addRemoveFromArraySingle,
  addRemoveFromArrayObject,
  sortArrayByName,
  formatArrayKeys,
  getMaxArray
};
