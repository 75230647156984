export const mapSyndicateFormToPayload = (values, entityId) => {
  return {
    entityId,
    name: values.syndicateName,
    // capped: values.syndicateCapped,
    // public: values.syndicatePublic === "yes"
    capped: 0,
    public: false
  };
};
