import { useEffect } from "react";
import { getAggregatedCommits } from "#helpers/mappers/commitments";
import { formatNumber } from "#helpers/number_format";
import { commitStatus } from "#constants/commit";
import { withContentFallback } from "../hocs";
import { StatsSection, Progress, Table } from "./";
import ioTypes from "#constants/io/ioTypes";

// STYLE CLASSNAMES REFLECT THE CLASSNAMES ON THE ORIGINAL CAPTABLE PAGE

const CapTable = ({
  getCommits,
  commitsDidFetch,
  commitsFetching,
  clearState,
  entityId,
  commits,
  ioId,
  ioMoneyInvested,
  sharesPrice = 1,
  fetchCaptable,
  fundingGoal,
  roundedMoney,
  dealType
}) => {
  useEffect(() => {
    clearState("commits");
    getCommits({ entity_id: entityId, investment_id: ioId });
    // fetchCaptable({ io_id: ioId });
  }, []);

  const [_, __, { invested: aggregatedData = [] }] = getAggregatedCommits({
    commits,
    sharesPrice,
    moneyInvested: ioMoneyInvested,
    fundingGoal,
    groupByUser: true,
    groupByCommitStatus: true,
    roundedMoney
  });

  const formattedData = aggregatedData.reduce((acc, item) => {
    const { name, amount, currency, sharesNumber, ownership } = item;
    const currTableData = acc.tableData || [];
    const currLabels = acc.labels || [];
    const currData = acc.data || [];
    const prevShares = acc.totalShares || 0;

    const calcSharesNumber = Math.floor(amount / sharesPrice);
    const finalSharesNumber = sharesNumber || calcSharesNumber;
    const newObj = {
      name,
      ownership: `${ownership}%`,
      amount: `${amount}`,
      sharesNumber: formatNumber(finalSharesNumber)
    };

    return {
      totalShares: prevShares + sharesNumber,
      labels: [...currLabels, name],
      data: [...currData, ownership],
      tableData: [...currTableData, newObj]
    };
  }, {});

  const MainContent = () => (
    <div className="single-cap-table-wrapper">
      {dealType === ioTypes.POOLED_EQUITY && (
        <div className="statistics">
          <StatsSection
            labels={formattedData.labels}
            dataInfo={formattedData.data}
          />
        </div>
      )}
      <Table
        type="io_shares"
        data={formattedData.tableData}
        dealType={dealType}
      />
    </div>
  );

  const MainWrapper = withContentFallback({
    empty: aggregatedData.length === 0 && commitsDidFetch,
    loading: commitsFetching,
    customizedEmptyMsg: "The deal has no investments."
  })(MainContent);

  return <MainWrapper />;
};

export default CapTable;
