import { connect } from "react-redux";
import {
  createIo,
  createDealAndBulkMembers,
  updateDealAndBulkMembers,
  fetchSingleIo,
  putIoInfo
} from "#core/modules/io/actions";
import { getCommits } from "#core/modules/commitment/actions/";
import { clearState, clearStateDataByKey } from "#core/utils/helper_actions";
import { readCurrencies } from "#core/modules/currency/actions/read";
import { getEntityMemberships } from "#core/modules/entities/actions";
import { getEntityShareholders } from "#core/modules/shares/actions";
import { getConvertibleTypes } from "#core/modules/convertible/actions";
import { getAllMembers } from "#helpers/general";
import { getIoMemberships } from "#core/modules/iomemberships/actions";

import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { entity, memberships = [] } = state.entities._data || {};
  const { cuid: userId } = state.users._data || {};
  const {
    isCreating,
    isUpdating,
    didUpdate,
    didCreate,
    _data: { io = {} } = {},
    didFetch: ioDidFetch
  } = state.io;
  const { _data: { currencies = [] } = {} } = state.currency;
  const {
    _data: { shareholders = [], list = [] } = {},
    didFetch
  } = state.shares;
  const { convertibles = [] } = state.convertible._data || {};
  const {
    _data: { ios = [] }
  } = state.io;
  const equityIos = ios.filter(({ type }) => type === "pooled_equity");
  const lastEquity = equityIos[equityIos.length - 1];
  const allMemberships = getAllMembers({ memberships, shareholders });
  const { memberships: ioMemberships = [] } = state.ioMemberships._data || {};
  const { commits = [] } = state.commits._data || {};

  return {
    entity,
    userId,
    currencies,
    buttonStates: {
      loading: isCreating || isUpdating,
      success: didUpdate || didCreate
    },
    memberships,
    entityFetched: state.entities.didFetch,
    shareholdersColl: {
      data: shareholders,
      didFetch
    },
    convertibles,
    ioColl: {
      didFetch: ioDidFetch,
      data: io
    },
    shares: list,
    lastEquity,
    allMemberships,
    ioMemberships,
    commits
  };
};

const mapDispatchToProps = dispatch => ({
  createIo: data => dispatch(createIo(data)),
  createDealAndBulkMembers: data => dispatch(createDealAndBulkMembers(data)),
  getEntityMemberships: data => dispatch(getEntityMemberships(data)),
  getEntityShareholders: data => dispatch(getEntityShareholders(data)),
  getConvertibleTypes: data => dispatch(getConvertibleTypes(data)),
  readCurrencies: () => dispatch(readCurrencies()),
  fetchSingleIo: data => dispatch(fetchSingleIo(data)),
  putIoInfo: data => dispatch(putIoInfo(data)),
  clearState: key => clearState(dispatch, key),
  getIoMemberships: data => dispatch(getIoMemberships(data)),
  getCommits: entityId => dispatch(getCommits(entityId)),
  updateDealAndBulkMembers: data => dispatch(updateDealAndBulkMembers(data)),
  clearStateDataByKey: (state, ...keys) =>
    clearStateDataByKey(dispatch, state, ...keys)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
