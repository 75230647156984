import { basicOption } from "#helpers/general";
import { equityTs, shaKeys, iaKeys } from "#constants/io/equityPool";
import { confirmationPrompt } from "../../../common/prompts";
import { boardOfDirectorsColumns } from "../../../InvestmentAgreementGenerator/form/fields/tableCols";
import { countriesSortedByName } from "../../../../Shared/Settings/countries";
import {
  cantonsSortedByName,
  zurichCantonsSortedByName
} from "../../../../Shared/Settings/cantons";

const fundraiser = (
  values,
  currency,
  subscriptionCompleted,
  agreedAmount,
  subscriptionFinished,
  severalTranches
) => {
  // values[shaKeys.MAX_INVESTMENT_AMOUNT_IF_SEVERAL_TRANCHES] = agreedAmount
  return [
    subscriptionFinished && {
      title: "Total amount raised",
      input: {
        type: "number",
        name: equityTs.AGREED_AMOUNT,
        formatter: value => {
          return value
            ? `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            : value;
        },
        parser: value =>
          value.replace(new RegExp(`${currency}\\s?|(\\'*)`, "g"), ""),
        value: agreedAmount,
        disabled: true
      }
    },
    severalTranches && {
      title:
        "What is the maximum total investment amount that the Company will accept, if several tranches during this financing round are allowed?",
      input: {
        type: "number",
        name: shaKeys.MAX_INVESTMENT_AMOUNT_IF_SEVERAL_TRANCHES,
        disabled: true,
        formatter: value => {
          return value
            ? `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            : value;
        },
        parser: value =>
          value.replace(new RegExp(`${currency}\\s?|(\\'*)`, "g"), "")
      }
    },
    subscriptionFinished && {
      title:
        "The courts of which place shall be competent to resolve any disputes arising out of the Shareholders' Agreement?",
      input: {
        type: "select_btn",
        name: iaKeys.ORDINARY_COURT,
        options: [
          basicOption("Registered seat"),

          basicOption("Zürich"),
          basicOption("Other")
        ]
      }
    },
    (values[iaKeys.ORDINARY_COURT] === "Other" ||
      values[iaKeys.ORDINARY_COURT] === "Zürich") && {
      input: {
        type: "autocomplete",
        name: iaKeys.ORDINARY_COURT_OTHER,
        dataSource:
          values[iaKeys.ORDINARY_COURT] === "Zürich"
            ? zurichCantonsSortedByName
            : cantonsSortedByName,
        filterOption: true
      }
    },
    subscriptionFinished && {
      title: "Is a new company auditor appointed with this financing round?",
      input: {
        name: "newCompanyAuditor",
        ...confirmationPrompt
      }
    },
    ...(values.newCompanyAuditor === "yes" &&
      auditorVal(values).filter(auditorField => auditorField)),
    subscriptionCompleted
      ? {
          title:
            "What jurisdiction of the ordinary courts shall provide for the Investment and Subscription Agreement?",
          input: {
            type: "select_btn",
            name: iaKeys.ORDINARY_COURT,
            options: [basicOption("Registered seat"), basicOption("Other")]
          }
        }
      : null
  ];
};

const board = (
  values,
  handlers,
  subscriptionCompleted,
  subscriptionFinished,
  investorDirectors,
  independentDirectors
) => {
  const boardQuorumHalfOptions = [
    basicOption("Half of all board members"),
    basicOption("Two third of board members")
  ];
  if (+investorDirectors > 0 && +independentDirectors > 0) {
    boardQuorumHalfOptions.push(
      basicOption(
        "Half of all board members and at least one of the investor director(s) or the independent director"
      )
    );
  } else if (+investorDirectors > 0) {
    boardQuorumHalfOptions.push(
      basicOption(
        "Half of all board members and at least one of the investor director(s)"
      )
    );
  } else if (+independentDirectors > 0) {
    boardQuorumHalfOptions.push(
      basicOption("Half of all board members and the independent director")
    );
  }
  return [
    {
      title:
        "What is the required attendance so that the Board is validly constituted to take decisions?",
      input: {
        type: "select_btn",
        name: shaKeys.BOARD_QUORUM_HALF,
        options: boardQuorumHalfOptions
      }
    },
    subscriptionFinished
      ? {
          title:
            "Who of the board members has the power of attorney to vote on behalf of the existing shareholders and founders, to render all necessary declarations and documents as well as to execute all acts in connection with the closing of the financing round? Please select a founder representant",
          input: {
            name: "signingDirector",
            type: "table_list",
            cols: boardOfDirectorsColumns(values, handlers)
          }
        }
      : null
  ];
};

const auditorVal = values => [
  {
    title: "Company name",
    className: "_width-50",
    input: {
      type: "text",
      name: "auditorName"
    }
  },
  {
    title: "UID",
    className: "_width-50",
    input: {
      type: "text",
      name: "auditorLastName"
    }
  },
  {
    title: "Street",
    input: {
      type: "text",
      name: "auditorStreet"
    }
  },
  {
    title: "Postal code",
    className: "_width-50",
    input: {
      type: "text",
      name: "auditorPostalCode"
    }
  },
  {
    title: "Place",
    className: "_width-50",
    input: {
      type: "text",
      name: "auditorPlace"
    }
  },
  {
    title: "Canton",
    className: "_width-50",
    input: {
      type: "autocomplete",
      name: "auditorCanton",
      dataSource: cantonsSortedByName,
      filterOption: true
    }
  },
  {
    title: "Country",
    className: "_width-50",
    input: {
      type: "autocomplete",
      name: "auditorCountry",
      dataSource: countriesSortedByName,
      filterOption: true
    }
  },
  {
    title: "Email",
    input: {
      type: "email",
      name: "auditorEmail"
    }
  }
];
export default [fundraiser, board];
