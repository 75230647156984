import { connect } from "react-redux";
import {
  getConvertibleTypes,
  deleteCLA
} from "#core/modules/convertible/actions";
import { getEntityCapTable } from "#core/modules/shares/actions";
import { clearState } from "#core/utils/helper_actions";

import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { convertibles = [] } = state.convertible._data || {};
  const { entity: { cuid: entityId } = {} } = state.entities._data || {};

  return {
    convertibles,
    entityId
  };
};

const mapDispatchToProps = dispatch => ({
  clearState: key => clearState(dispatch, key),
  deleteCLA: id => dispatch(deleteCLA(id)),
  getConvertibleTypes: data => dispatch(getConvertibleTypes(data)),
  getEntityCapTable: params => dispatch(getEntityCapTable(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
