import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useParams } from "react-router-dom";
import { Modal, Divider } from "antd";
import { convertToBase64 } from "#helpers/convertBase64";
import { getAggregatedCommits } from "#helpers/mappers/commitments";
import { roles } from "#constants/roles";
import privileges from "#constants/privileges";
import { closedValues } from "#constants/io/closed";
import { formatNumber } from "#helpers/number_format";
import { dealAttachmentCategories } from "#constants/io/ioTypes";
import { commitStatus } from "#constants/commit";
import ioTypes from "#constants/io/ioTypes";
import {
  mapContractToAttach,
  mapFinancialContractsToAttach
} from "#helpers/mappers/attachments";
import BasicWrapper from "../../../../../Shared/BasicWrapper";
import {
  Terms,
  Overview,
  Guidelines,
  InlineStats,
  TosModal
} from "./components/";
import ActionContainer from "../../common/ActionContainer";
import Title from "../../common/Title";
import DocumentsDrawer from "../../common/DocumentsSection";
import {
  withBackNavigation,
  withDealClosureConstraints
} from "../../../../../Shared/hocs";
import {
  AddAttachModal,
  CapTableModal,
  AddMemberModal,
  InvestmentProcessModal,
  PostDealClosureModal,
  ConfirmTransferModal
} from "../../../../../Shared/Modals";
import { Description } from "./components/Description";
import { ContentFallbackScreen } from "../../../../../Shared/Screens";
import EditableContent from "../../../../../Shared/EditableContent";
import SectionTitle from "./components/SectionTitle";
import ExecuteDealModal from "./components/ExecuteDeal";
import ExecutedDealModal from "./components/ExecuteDeal/investor";
import Industries from "./components/Industries";
import {
  catchNotifications,
  NOTIFICATION_ACTIONS
} from "#helpers/notifications";
import { btn_actions } from "./components/buttons";
import {
  useCleanupEffect,
  useFetchSyndicates,
  useFinancialContractGenerator,
  useInvestmentConfirmationGenerator,
  useNdaPreview,
  useNotificationCatcher,
  useUpdatedIoEffect
} from "./hooks";
import RoundType from "./components/RoundType";

const IndividualDealPresentational = ({
  actions,
  io = {},
  status,
  commits,
  userRole,
  entityStates,
  baseEntity,
  contractsColl,
  cLvl,
  amCuid,
  ndaColl,
  membershipsColl,
  history,
  industries = [],
  kycFinished,
  userId,
  userData,
  commitState,
  syndicates = [],
  docConstraints,
  assetManager
}) => {
  const { dealId } = useParams();
  const {
    currency = {},
    attachments = [],
    entity = {},
    ioMembership = {}
  } = io;

  const initialState = {
    modalOpen: false
  };

  const [state, setState] = useState(initialState);

  const entityId = entity.cuid || baseEntity.cuid;
  const isInvestor =
    userRole === roles.INVESTOR || userRole === roles.AM_INVESTOR;
  const executed = io.closed >= closedValues.EXECUTED;

  const pendingSoftCommit = commits.find(
    ({ status }) => status === commitStatus.SOFT
  );
  const pendingConfirmation = executed && pendingSoftCommit;

  const isPrivileged =
    userRole === roles.FOUNDER || userRole === roles.ASSET_MANAGER;

  const canEditFees = !executed || (commits.length === 0 && !executed);
  const canEdit = isPrivileged && !io.closed;

  const dealIsClosed = io.closed >= closedValues.CLOSED_BY_FOUNDER;
  const moneyWired = io.closed >= closedValues.MONEY_WIRED_TO_TARGET;

  const canCloseDeal =
    !commits.find(
      ({ status }) =>
        status !== commitStatus.SOFT && status < commitStatus.COMPLETED
    ) &&
    isPrivileged &&
    executed &&
    !dealIsClosed;

  const dealCurrency = currency && currency.shortName;

  const { street = "", zipCode = "", city = "", country = "" } = userData || {};

  const canInvest =
    street !== "" && zipCode !== "" && city !== "" && country !== "";

  const [, , hasMandatoryMissingDocs, ,] = docConstraints;

  useEffect(() => {
    if (
      isInvestor &&
      (ioMembership.status === 0 || baseEntity?.membership?.status === 0)
    ) {
      history.push("/invitations");
    }
  }, [ioMembership?.status, baseEntity?.membership?.status]);

  useFetchSyndicates(actions, entityId);
  useNotificationCatcher(actions, entity, dealId, userId);
  useCleanupEffect(
    actions,
    dealId,
    status,
    state?.executeDealModalOpen,
    isInvestor,
    io?.isAttachmentUpdate
  );
  useFinancialContractGenerator(
    actions,
    status,
    dealId,
    io,
    entity,
    baseEntity,
    ioMembership,
    isInvestor,
    amCuid,
    isPrivileged,
    dealIsClosed,
    moneyWired,
    setState,
    state
  );
  useInvestmentConfirmationGenerator(
    actions,
    dealId,
    io,
    commitState,
    status,
    commits,
    isInvestor,
    moneyWired
  );

  useUpdatedIoEffect(status, dealIsClosed, io, actions, dealId);
  useNdaPreview(ndaColl, actions, isInvestor);

  useEffect(() => {
    if (isInvestor && pendingConfirmation && executed && canInvest) {
      setState({ ...state, dealExecutedModal: true });
    }
  }, [isInvestor, pendingConfirmation, executed]);

  useEffect(() => {
    if (membershipsColl.states.didCreate) {
      actions.getIoMemberships({ io_id: io.cuid });
    }
  }, [membershipsColl.states.didCreate]);
  useEffect(() => {
    if (
      membershipsColl.states.didUpdate ||
      !!ioMembership.tosAcceptedTimestamp
    ) {
      setState({ ...state, tosModalOpen: false });
    }
  }, [membershipsColl.states.didUpdate, !!ioMembership.tosAcceptedTimestamp]);

  const { confirm, warning } = Modal;

  const handleStateChange = (...newState) => () => {
    const [key, value] = newState;

    if (typeof key === "object") {
      setState({ ...state, ...key });
    } else {
      setState({ ...state, [key]: value });
    }
  };

  const handleCloseDeal = () =>
    actions.updateIoClosingStatus({
      cuid: dealId,
      closed:
        closed === closedValues.CLOSED_BY_FOUNDER
          ? closedValues.APPROVED_BY_LAWYERS
          : closedValues.CLOSED_BY_FOUNDER
    });

  const showCloseConfirm = () =>
    confirm({
      title:
        "Are you sure you want to close the deal? Read carefully the instructions below",
      content: (
        <span>
          <br />
          You should close the deal only when the investment has been finalized.
          <br /> <br /> In the case of a priced round, you shall close the round
          only once the capital increase has been officially registered in the
          commercial register (Handelsregister / Registre de Commerce). <br />
          <br />
          In the case of a convertible loan / SAFE, you can close the deal once
          the funds arrive in the account of the target.
        </span>
      ),
      onOk: handleCloseDeal,
      okButtonProps: { loading: status.ioIsUpdating },
      icon: <div />,
      okText: "Confirm",
      width: 660
    });

  const handlePreviewUpload = async ({ file }) =>
    actions.updateEntity({
      cuid: entityId,
      previewImageBase64: await convertToBase64(file.originFileObj)
    });

  const handleDescEdit = (name, value) =>
    actions.updateEntity({ cuid: entityId, [name]: value });

  const handleTermsAcceptance = () =>
    actions.patchMembershipStatus({
      cuid: ioMembership.cuid,
      tosAccepted: true
    });

  const handleAttachmentUpdate = attachmentId => checked =>
    actions.patchAttachment({
      ioId: dealId,
      attachmentId,
      downloadable: checked
    });

  const showConfirm = attachCuid => () =>
    confirm({
      title: "Are you sure you want to delete this attachment?",
      okCancel: !status.isDeleting,
      onOk: () => {
        actions.deleteIoAttachment({ ioId: io.cuid, cuid: attachCuid });
      },
      okButtonProps: { loading: status.attachLoading }
    });

  const actionList = (attachCuid, item) => [
    {
      label: "Update",
      onClick: () =>
        setState({ ...state, modalOpen: "update", selectedAttach: item })
    },
    {
      label: "Delete",
      customStyle: "danger",
      onClick: showConfirm(attachCuid)
    }
  ];

  const handleIoDelete = () => {
    confirm({
      title: "Are you sure you want to delete this deal?",
      maskClosable: true,
      okType: "danger",
      okText: "Yes",
      onOk() {
        if (io.cuid) {
          actions.deleteIo(io.cuid);
          history.goBack();
        }
      }
    });
  };

  const handleCreateVoting = () => {
    history.push(`/create/voting_assembly/${io.cuid}`);
  };

  const handleDealEdit = () => {
    history.push(`/edit/deal/${io.cuid}`);
  };

  const handleConfirmTransfer = () =>
    io.roundedMoneyInvested === 0
      ? warning({
          title: "The request cannot be processed at this time",
          content:
            "In order to request a wire transfer, you must collect the funds from your co-investors first",
          onOk: handleStateChange("confirmTransferOpen", false),
          width: 460
        })
      : handleStateChange("confirmTransferOpen", true)();

  const [, , data] = getAggregatedCommits({
    commits,
    sharesPrice: io.sharesPrice,
    investmentLevel: io.investmentLevel,
    sharesNumber: io.sharesNumber,
    moneyInvested: io.moneyInvested,
    moneyCommitted: io.moneyCommitted,
    fundingGoal: io.fundingGoal,
    groupByUser: dealIsClosed,
    groupByCommitStatus: true,
    roundedMoney: {
      invested: io.roundedMoneyInvested,
      committed: io.roundedMoneyCommitted,
      softCommited: io.roundedMoneySoftCommitted
    }
  });

  const formattedData =
    io.closed < closedValues.CLOSED_BY_FOUNDER ? data : data.invested;

  let totalAmount = 0;
  let totalSharesNumber = 0;
  let totalOwnership = 0;

  if (!isInvestor && Array.isArray(formattedData) && formattedData.length > 0) {
    Array.isArray(formattedData) &&
      formattedData.length > 0 &&
      formattedData?.forEach(
        ({ status, amount = "", sharesNumber = "", ownership }) => {
          let localSharesNumber = 0;
          let localOwnership = 0;
          if (sharesNumber && typeof sharesNumber === "string")
            localSharesNumber = sharesNumber?.replace(/[’]+/g, "");
          else if (typeof sharesNumber === "object") localSharesNumber = 0;
          else localSharesNumber = sharesNumber;

          if (typeof ownership === "object") localOwnership = 0;
          else localOwnership = ownership;
          if (status !== "Rejected") {
            totalAmount += Number(amount?.split(" ")[1]?.replace(/[’]+/g, ""));
            totalSharesNumber += Number(localSharesNumber);
            totalOwnership += parseFloat(localOwnership);
          }
        }
      );

    if (totalOwnership > 100 || totalOwnership < 100) totalOwnership = 100;

    if (io.closed < closedValues.EXECUTED && io.roundedMoneySoftCommitted)
      totalAmount = io.roundedMoneySoftCommitted;

    const totalRow = {
      name: <h4>Total</h4>,
      status: "",
      data: "",
      amount: `${formatNumber(
        Number(totalAmount).toFixed(2),
        io?.currency?.shortName
      )}`,
      sharesNumber: io?.sharesNumber ?? totalSharesNumber,
      ownership: totalOwnership.toFixed(2)
    };
    formattedData?.push(totalRow);
  }
  const formatDocuments = () => {
    let initList = [
      ...(!dealIsClosed
        ? contractsColl.data.map(item => mapContractToAttach(item))
        : []), // on deal close, the server generates contracts and adds them to the deal attachment list
      ...attachments
    ];

    initList = [
      ...initList,
      ...contractsColl.financialContracts.map(item =>
        mapFinancialContractsToAttach(item)
      )
    ];
    if (isInvestor) {
      if (ndaColl.ndaGenerated) {
        initList.push({
          type: "contract",
          name: "Confidentiality & Non-Circumvent Agreement",
          url: ndaColl?.nda?.value,
          category: dealAttachmentCategories.LEGAL,
          downloadable: true,
          createdAt: ndaColl?.nda?.createdAt
        });
      }
    }

    return initList.reduce((acc, item) => {
      const { category, subCategory } = item || {};
      let queryKey = category;

      if (
        category === dealAttachmentCategories["SYNDICATE-TERMS"] ||
        category === dealAttachmentCategories.SPA ||
        category === dealAttachmentCategories["SPA-SUBSCRIPTION-AGREEMENT"]
      ) {
        queryKey = dealAttachmentCategories.LEGAL;
      }

      if (
        category === "io-doc" ||
        !dealAttachmentCategories[category.toUpperCase()]
      ) {
        // SUPPORT FOR OLD ATTACHMENTS
        if (
          subCategory &&
          dealAttachmentCategories[subCategory.toUpperCase()]
        ) {
          queryKey = subCategory;
        } else {
          queryKey = "general";
        }
      }

      const currItems = acc[queryKey] || [];

      return {
        ...acc,
        [queryKey]: [...currItems, item]
      };
    }, {});
  };

  const actionBtns = btn_actions({
    isPrivileged,
    executed,
    canEdit,
    canEditFees,
    handleDealEdit,
    handleIoDelete,
    handleCreateVoting,
    handleStateChange,
    pendingSoftCommit,
    showCloseConfirm,
    canInvest,
    dealIsClosed,
    canCloseDeal,
    loading: status.loading,
    closed: io.closed,
    hasMandatoryMissingDocs,
    confirmTransfer: io.confirmTransferTimestamp,
    handleConfirmTransfer
  });

  const filteredSyndicates = syndicates.find(
    ({ investments = [] }) => !!investments.find(inv => inv.cuid === io.cuid)
  );

  const actionButtons = [
    actionBtns.collect_funds,
    actionBtns.confirm_transfer,
    actionBtns.captable,
    actionBtns.add_investor,
    actionBtns.close_deal,
    actionBtns.edit_deal,
    actionBtns.invest,
    actionBtns.soft_commit,
    actionBtns.cta_btn,
    actionBtns.create_voting,
    actionBtns.round_up_down
  ];

  if (filteredSyndicates?.investments.length === 1) {
    actionButtons.push(actionBtns.delete_deal);
  }

  return (
    <ContentFallbackScreen loading={status.loading}>
      <BasicWrapper className="custom-deal-wrapper">
        <PostDealClosureModal
          visible={state?.fundDealModal}
          ioId={io?.cuid}
          dealType={io?.dealType}
          currency={io?.currency?.shortName}
          sharesPrice={io?.sharesPrice}
          sharesNumber={io?.sharesNumber}
          handleClose={handleStateChange("fundDealModal", false)}
        />
        <InvestmentProcessModal
          visibility={state.invProcessModalOpen}
          closeModal={handleStateChange("invProcessModalOpen", false)}
          executed={executed}
          pendingSoftCommit={pendingSoftCommit}
          pendingConfirmation={pendingConfirmation}
          canInvest={!executed || cLvl >= privileges.INVEST}
          kycFinished={kycFinished}
          history={history}
          myInvestments={formattedData}
        />
        <AddMemberModal
          visible={state.addMemberModalOpen}
          handleClose={handleStateChange("addMemberModalOpen", false)}
          ioId={dealId}
          entityId={entity.cuid}
          withNda
        />
        <ConfirmTransferModal
          visible={state.confirmTransferOpen}
          handleClose={handleStateChange("confirmTransferOpen", false)}
          ioId={io.cuid}
        />
        <CapTableModal
          visible={state.captableModalOpen}
          handleClose={handleStateChange("captableModalOpen", false)}
          ioMoneyInvested={io.moneyInvested}
          ioId={dealId}
          sharesPrice={io.sharesPrice}
          fundingGoal={io.fundingGoal}
          dealType={io.dealType}
          roundedMoney={{
            invested: io.roundedMoneyInvested,
            committed: io.roundedMoneyCommitted
          }}
        />
        <AddAttachModal
          modalOpen={state.modalOpen}
          toggleModal={handleStateChange("modalOpen", !state.modalOpen)}
          selectedAttach={state.selectedAttach}
          ioId={dealId}
        />
        {!ioMembership.tosAcceptedTimestamp && (
          <TosModal
            visible={state.tosModalOpen}
            contractsColl={contractsColl}
            handleAccept={handleTermsAcceptance}
            ioLoading={membershipsColl.states.loading}
          />
        )}
        <ExecuteDealModal
          visible={state.executeDealModalOpen}
          ioLoading={status.loading}
          ioDidUpdate={status.ioDidUpdate}
          ioIsUpdating={status.ioIsUpdating}
          onCancel={handleStateChange("executeDealModalOpen", false)}
          updateAndCloseDeal={actions.updateAndCloseDeal}
          updateIoClosingStatus={actions.updateIoClosingStatus}
          ioId={io.cuid}
          ioType={io.dealType}
          commits={commits}
          fundingGoal={io.fundingGoal}
          sharesPrice={io.sharesPrice}
          currency={dealCurrency}
          roundedMoneySoftCommitted={io.roundedMoneySoftCommitted}
          moneyCommitted={io.moneyCommitted}
          totalAmount={totalAmount}
        />
        {isInvestor && pendingConfirmation && executed && (
          <ExecutedDealModal
            name={io.name}
            visible={state.dealExecutedModal}
            onCancel={handleStateChange({
              dealExecutedModal: false,
              invProcessModalOpen: true
            })}
          />
        )}
        <div className="individual-deal">
          <ActionContainer buttons={actionButtons} actionVisible>
            <Title
              closed={io.closed}
              title={baseEntity?.commonName || io?.entity?.name}
              syndicateName={io?.syndicate?.name}
              putIoInfo={actions.putIoInfo}
              ioId={io.cuid}
              handlePreviewUpload={handlePreviewUpload}
              handleAvatarDblClick={handleStateChange(
                "editableImage",
                !state.editableImage
              )}
              canEdit={canEdit}
              editable={state.editableImage}
              {...entity}
              previewImage={io.previewImage || entity.previewImage}
              type={io.dealType}
              createdAt={io.createdAt}
              entityIndustries={industries}
              isPrivileged={isPrivileged}
              assetManager={assetManager}
              isInvestor={isInvestor}
            />
          </ActionContainer>
          <Terms io={io} />
          {/* <RoundType ioId={io.cuid} getRoundType={actions.getRoundType} /> */}
          <Divider />
          <InlineStats
            minimal={io.minimal}
            fundingGoal={io.fundingGoal}
            moneyInvested={io.roundedMoneyInvested}
            moneyCommitted={io.roundedMoneyCommitted}
            moneySoftCommitted={io.roundedMoneySoftCommitted}
            showRaw={!isPrivileged}
            currency={currency.shortName}
            closed={io.closed}
          />

          <Divider />

          <EditableContent
            name="description"
            initVal={entity.description}
            inputConfig={{ type: "textarea", rows: 4 }}
            Component={Description}
            componentProps={{ desc: entity.description }}
            onOk={handleDescEdit}
            success={entityStates.didUpdate}
            loading={entityStates.isUpdating}
            canEdit={canEdit}
          />
          <Overview
            rawCommitData={commits}
            data={formattedData}
            memberships={membershipsColl.data}
            isInvestor={isInvestor}
            createdById={io.createdBy}
            ioId={io.cuid}
            dealType={io?.dealType}
            isPrivileged={isPrivileged}
            dealIsClosed={dealIsClosed}
            handleStateChange={handleStateChange}
          >
            <DocumentsDrawer
              title="Add new document"
              titleAction={
                isPrivileged ? handleStateChange("modalOpen", "add") : null
              }
              list={formatDocuments()}
              actionList={isPrivileged ? actionList : null}
              handleAttachmentUpdate={handleAttachmentUpdate}
              isPrivileged={isPrivileged}
              dealName={io.name}
              docConstraints={docConstraints}
            />
          </Overview>
        </div>
      </BasicWrapper>
    </ContentFallbackScreen>
  );
};

IndividualDealPresentational.propTypes = {
  io: PropTypes.object,
  entityId: PropTypes.string,
  status: PropTypes.object,
  currencies: PropTypes.array,
  commits: PropTypes.array,
  userRole: PropTypes.string,
  entityStates: PropTypes.object,
  baseEntity: PropTypes.object,
  contracts: PropTypes.array,
  cLvl: PropTypes.string,
  amCuid: PropTypes.string,
  ndaColl: PropTypes.object,
  contractsColl: PropTypes.object,
  history: PropTypes.object,
  membershipsColl: PropTypes.object,
  actions: PropTypes.object,
  userData: PropTypes.object
};

export default withBackNavigation({})(
  withDealClosureConstraints(IndividualDealPresentational)
);
