import { Typography } from "antd";

const { Title } = Typography;

export default function index() {
  return (
    <div>
      <div className="maintenance-title">
        <Title level={1}>Maintenance</Title>
      </div>
      <div className="maintenance-content">
        <span>
          We will do maintenance work on <br />
          <b>{process.env.MAINTENANCE_TIME}</b> <br />
          to improve the platform for you. <br /> <br />
          Login to Leva during this period is not possible. <br /> <br />
          Thank you for your understanding.
        </span>
      </div>
    </div>
  );
}
