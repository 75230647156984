import { Form } from "antd";
import InputRenderer from "../../InputRenderer";

const FieldWrapper = ({
  wrapperProps: { className, ...wrapperProps },
  inputProps,
  CustomInputRenderer,
  formikProps
}) => {
  const { Item: FormItem } = Form;
  const finalClassname = `form-item ${className || ""} ${
    !wrapperProps.colon ? "no-colon" : ""
  }`;
  const Target = CustomInputRenderer || InputRenderer;

  return (
    <FormItem className={finalClassname} {...wrapperProps}>
      <Target {...inputProps} formikProps={formikProps} />
    </FormItem>
  );
};

export default FieldWrapper;
