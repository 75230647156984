import { useState, useEffect, Fragment } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import { roles } from "#constants/roles";
import ModalWrapper from "../../Modal";
import { Button } from "../../Button";
import Checkbox from "../../Checkbox";
import Switch from "../../Switch";

const TermsAgreement = ({
  updateUserSettings,
  buttonStates,
  clearState,
  entityId,
  getIoMemberships,
  membershipsColl,
  userStates,
  userRole,
  getPortfolio,
  dealsColl,
  tosAccepted,
  firstName,
  ...modalProps
}) => {
  const [initAccepted, setInitAccepted] = useState(tosAccepted);
  const [state, setState] = useState({});
  const isPrivileged =
    userRole === roles.ASSET_MANAGER || userRole === roles.FOUNDER;
  const isFounder = userRole === roles.FOUNDER;

  const docsList = isPrivileged
    ? [
        {
          title: "Deal manager guidelines",
          href: "/doc_viewer?doc=/docs/managerGuidelines.pdf"
        },
        {
          title: "Terms of services",
          href: "/doc_viewer?doc=/docs/tos.pdf"
        },
        {
          title: "Privacy policy",
          href: "/doc_viewer?doc=/docs/privacypolicy.pdf"
        }
      ]
    : [
        {
          title: "Terms of services",
          href: "/doc_viewer?doc=/docs/tos.pdf"
        },
        {
          title: "Privacy policy",
          href: "/doc_viewer?doc=/docs/privacypolicy.pdf"
        }
      ];

  useEffect(() => {
    if (modalProps.visible) {
      clearState("users");
      clearState("iomemberships");
    }
  }, [modalProps.visible]);

  useEffect(() => {
    if (modalProps.visible) {
      if (userRole === roles.ASSET_MANAGER || userRole === roles.FOUNDER) {
        getPortfolio({ closed: false });
      } else {
        getIoMemberships();
      }
    }
  }, [modalProps.visible, userRole]);

  useEffect(() => {
    if (!initAccepted && userStates.success) {
      modalProps.onCancel();
      setInitAccepted(true);
    }
  }, [userStates.success]);

  const handleTermsUpdate = () => updateUserSettings({ tosAccepted: true });
  const handleSwitches = name => checked =>
    setState({ ...state, [name]: checked });

  const signaturesValid =
    (isFounder && state.legalAccepted) ||
    (isPrivileged
      ? state.docsAccepted && state.ndaAccepted
      : state.docsAccepted);

  const listConfig = isPrivileged
    ? {
        ...dealsColl,
        renderItem: ({ cuid, name }) => (
          <List.Item key={cuid}>
            <List.Item.Meta
              avatar={<Checkbox name={cuid} checked />}
              title={name}
            />
          </List.Item>
        )
      }
    : {
        ...membershipsColl,
        renderItem: ({ io: { cuid, name } = {} }) => (
          <List.Item key={cuid}>
            <List.Item.Meta
              avatar={<Checkbox name={cuid} checked />}
              title={name}
            />
          </List.Item>
        )
      };

  return (
    <ModalWrapper
      className="terms-modal"
      title={isFounder ? "Legal Disclaimer" : "Updated terms"}
      footer={null}
      width={isFounder ? 400 : 1000}
      closable={false}
      maskClosable={false}
      keyboard={false}
      {...modalProps}
    >
      <div className="terms-modal-container">
        {isFounder ? (
          <span>
            Leva provides a self-service solution at users' discretion. We are
            not a law firm or a substitute for an attorney or law firm. We
            cannot provide any kind of advice, explanation, opinion, or
            recommendation about possible legal rights, remedies, defenses,
            options, tax implications, selection of forms, or strategies.
            <Switch
              label="I have read, understood and accept this Legal Disclaimer."
              onChange={handleSwitches("legalAccepted")}
              name="legalAccepted"
              value={state.legalAccepted}
            />
          </span>
        ) : (
          <Fragment>
            <h2>
              Dear {firstName}, we have updated our legal framework for you.
            </h2>
            <div className="panes-container">
              <div className="pane left">
                <h2>
                  We have great news for you! We have updated the terms of your
                  syndicate(s) listed down below. Please go to your portfolio
                  and access your deals to accept the updated terms of
                  syndicate.
                </h2>
                <List className="membership-list" {...listConfig} />
              </div>
              <div className="pane right">
                {isPrivileged ? (
                  <React.Fragment>
                    <h2>
                      We build, what you desire! To better protect the
                      information of the previous and future deals, we let you
                      sign a Confidentiality & Non-Circumvent Agreement with
                      your Investors
                    </h2>
                    <ul>
                      <li>
                        <a
                          href="/doc_viewer?doc=/docs/nda.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          Confidentiality & Non-Circumvent Agreement
                        </a>
                      </li>
                    </ul>
                    <Switch
                      label="I have read, understood and accept the Confidentiality & Non-Circumvent Agreement"
                      onChange={handleSwitches("ndaAccepted")}
                      name="ndaAccepted"
                      value={state.ndaAccepted}
                    />
                    <h2>
                      We updated our legal documents too. Please read and accept
                      our updated legal documents listed down below:
                    </h2>
                    <ul>
                      {docsList.map(({ title, href }) => (
                        <li>
                          <a
                            href={href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {title}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <Switch
                      label="I have read, understood and accept the Terms of Services, Privacy Policy and Deal Manager Guidelines"
                      onChange={handleSwitches("docsAccepted")}
                      name="docsAccepted"
                      value={state.docsAccepted}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h2>
                      Please read and accept our updated legal documents listed
                      down below:
                    </h2>
                    <ul>
                      {docsList.map(({ title, href }) => (
                        <li>
                          <a
                            href={href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {title}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <Switch
                      label="I have read, understood and accept the Terms of Services, Privacy Policy."
                      name="docsAccepted"
                      onChange={handleSwitches("docsAccepted")}
                      value={state.docsAccepted}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </Fragment>
        )}
        <div className="btn-container">
          <Button
            label="I accept"
            onClick={handleTermsUpdate}
            disabled={!signaturesValid}
            {...userStates}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

TermsAgreement.propTypes = {
  updateUserSettings: PropTypes.func,
  buttonStates: PropTypes.object,
  clearState: PropTypes.func,
  entityId: PropTypes.string,
  getIoMemberships: PropTypes.func,
  membershipsColl: PropTypes.object,
  userStates: PropTypes.object,
  userRole: PropTypes.string,
  getPortfolio: PropTypes.func,
  dealsColl: PropTypes.object,
  tosAccepted: PropTypes.bool,
  firstName: PropTypes.string
};

export default TermsAgreement;
