import { globalChangeHandler } from '#helpers/inputHandlers';

export const handleInputChange = ({ handleChange, setFieldValue, values }) => (
  type
) => {
  return ['select', 'checkbox', 'switch'].includes(type)
    ? globalChangeHandler(setFieldValue, values, type)
    : handleChange;
};

export const handleCheckboxInArray = (setFieldValue, values) => (
  name,
  cuid
) => ({ target: { name: key } }) => {
  const currVal = values[name] || [];
  const exists = currVal.find(({ cuid: localCuid }) => localCuid === cuid);
  let updatedVal = [];

  if (exists) {
    updatedVal = currVal.map((item) => {
      const { cuid: localCuid } = item;
      const newValue = !item[key];

      return findValAndReplace(localCuid, cuid, key, newValue, item);
    });
  } else {
    updatedVal = [...currVal, { cuid, [key]: true }];
  }

  setFieldValue(name, updatedVal);
};

export const handleInputInArray = (setFieldValue, values) => (name, cuid) => ({
  target: { name: key, value }
}) => {
  const currVal = values[name] || [];

  const exists = currVal.find(({ cuid: localCuid }) => localCuid === cuid);
  let updatedVal = [];

  if (exists) {
    updatedVal = currVal.map((item) => {
      const { cuid: localCuid } = item;

      return findValAndReplace(localCuid, cuid, key, value, item);
    });
  } else {
    updatedVal = [...currVal, { cuid, [key]: value }];
  }

  setFieldValue(name, updatedVal);
};

const findValAndReplace = (idx, compareIdx, key, updatedVal, item) => {
  if (idx === compareIdx) {
    return {
      ...item,
      [key]: updatedVal
    };
  }

  return item;
};
