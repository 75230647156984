const sections = [
  {
    id: 1,
    title: "A. General information"
  },
  {
    id: 2,
    title: "B. Financials",
    subSections: [
      {
        id: 2,
        title: "Information"
      },
      {
        id: 3,
        title: "Investor"
      },
      {
        id: 4,
        title: "Interest"
      }
    ]
  },
  {
    id: 5,
    title: "C. Conversion"
  }
];

const helpers = ({ toggleModalState }) => [
  {
    id: 1,
    title: "Check our Convertible Loan Guide",
    onClick: toggleModalState
  }
];

export { sections, helpers };
