import { List } from "antd";
import { Button } from "../../../../../../../Shared/Button";

const Controls = ({ list }) => {
  return (
    <div className="controls-container">
      <List>
        {list.map(
          ({
            label,
            description,
            href,
            menu,
            disabled,
            tooltip,
            onClick,
            customButtonTitle
          }) => {
            const renderAction = () => {
              if (menu) {
                const centeredText = <h4 className="centered-text">OR</h4>;

                return (
                  <div className="btns-container">
                    <Button
                      label={menu[0].label}
                      href={menu[0].href}
                      disabled={menu[0].disabled}
                      size="large"
                      tooltip={menu[0].disabled ? menu[0].tooltip : false}
                    />
                    {centeredText}
                    <Button
                      label={menu[1].label}
                      href={menu[1].href}
                      disabled={menu[1].disabled}
                      size="large"
                      tooltip={menu[1].disabled ? menu[1].tooltip : false}
                    />
                  </div>
                );
              }

              return (
                <Button
                  label={customButtonTitle || label}
                  href={href}
                  size="large"
                  disabled={disabled}
                  tooltip={disabled ? tooltip : false}
                  onClick={onClick}
                />
              );
            };

            return (
              <List.Item actions={[renderAction()]}>
                <List.Item.Meta title={label} description={description} />
              </List.Item>
            );
          }
        )}
      </List>
    </div>
  );
};

export default Controls;
