import { NavLink } from "react-router-dom";
import { iaKeys, shaKeys } from "#constants/io/equityPool";
import { formatNumber } from "#helpers/number_format";
import { confirmationPrompt } from "../../../common/prompts";
import {
  shareholdersInvestingCols,
  boardOfDirectorsColumns
} from "./tableCols";
import { cantonsSortedByName } from "../../../../Shared/Settings/cantons";
import { countriesSortedByName } from "../../../../Shared/Settings/countries";

const shareholders = (values, handlers, selectedConversionInvestor) => {
  let amountTobeInvested = 0;
  let newSharesReceiving = 0;

  values &&
    values.investorsOverview &&
    values.investorsOverview.forEach(
      ({ amount = 0, newShares = 0, selected }) => {
        if (selected) {
          amountTobeInvested += amount;
          newSharesReceiving += newShares;
        }
      }
    );

  return [
    {
      title: "Shareholder overview",
      input: {
        type: "table_list",
        name: "investorsOverview",
        cols: shareholdersInvestingCols(
          values,
          handlers,
          selectedConversionInvestor
        ),
        footer: () => (
          <span>
            Total amount to be invested: {formatNumber(amountTobeInvested)}
            <br />
            Total new shares: {newSharesReceiving}
          </span>
        )
      }
    }
  ];
};

const bod = (values, handlers) => [
  {
    title: `Who of the board members are new and who has the power of attorney (PoA) to vote on behalf of the existing shareholders and existing board members (PoA column may be selected only for for each stakeholder unless those new)`,
    input: {
      name: "signingDirector",
      type: "table_list",
      cols: boardOfDirectorsColumns(values, handlers)
    }
  },
  {
    showId: false,
    title: (
      <span>
        If you want to add a board member{" "}
        <NavLink to="/stakeholders">click here</NavLink>
      </span>
    ),
    className: "_hidden",
    input: {}
  }
];

const auditor = values => [
  {
    title: "Is a new company auditor appointed with this financing round?",
    input: {
      name: iaKeys.NEW_COMPANY_AUDITOR,
      ...confirmationPrompt
    }
  },
  ...(values[iaKeys.NEW_COMPANY_AUDITOR] === "yes"
    ? [
        {
          title: "Company",
          className: "_width-50",
          input: {
            type: "text",
            name: shaKeys.INDEPENDENT_EXPERT_NAME
          }
        },
        {
          title: "UID",
          className: "_width-50",
          input: {
            type: "text",
            name: shaKeys.INDEPENDENT_EXPERT_LAST_NAME
          }
        },
        {
          title: "Street",
          input: {
            type: "text",
            name: shaKeys.INDEPENDENT_EXPERT_STREET
          }
        },
        {
          title: "Postal code",
          className: "_width-50",
          input: {
            type: "text",
            name: shaKeys.INDEPENDENT_EXPERT_ZIP
          }
        },
        {
          title: "Place",
          className: "_width-50",
          input: {
            type: "text",
            name: shaKeys.INDEPENDENT_EXPERT_PLACE
          }
        },
        {
          title: "Canton",
          className: "_width-50",
          input: {
            type: "autocomplete",
            name: shaKeys.INDEPENDENT_EXPERT_CANTON,
            dataSource: cantonsSortedByName,
            filterOption: true
          }
        },
        {
          title: "Country",
          className: "_width-50",
          input: {
            type: "autocomplete",
            name: shaKeys.INDEPENDENT_EXPERT_COUNTRY,
            dataSource: countriesSortedByName,
            filterOption: true
          }
        },
        {
          title: "Email",
          input: {
            type: "email",
            name: shaKeys.INDEPENDENT_EXPERT_EMAIL
          }
        }
      ]
    : [])
];

export default [shareholders, bod, auditor];
