import Button from "../BtnWrapper/Presentational";

const BtnContainer = ({
  showOk = true,
  showCancel = true,
  onCancel,
  onOk,
  buttonStates,
  okButtonLabel,
  cancelButtonLabel,
  okButtonProps,
  cancelButtonProps,
  wrapperCls = "btn-container form-btn-container"
}) => {
  return (
    <div className={wrapperCls}>
      {showCancel && (
        <Button
          label={cancelButtonLabel || "Cancel"}
          onClick={onCancel}
          type="normal"
        />
      )}
      {showOk && (
        <Button
          label={okButtonLabel || "Save"}
          onClick={onOk}
          {...buttonStates}
          {...okButtonProps}
        />
      )}
    </div>
  );
};

export default BtnContainer;
