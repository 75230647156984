import types from "../action_types";
import { getCurrencies } from "../../../../api/currency";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData } from "../../../utils/helper_func";

export const readCurrencies = () => {
  return async dispatch => {
    try {
      start(dispatch, types.READ_CURRENCIES);
      const response = await getCurrencies();
      success(dispatch, types.READ_CURRENCIES, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      fail(dispatch, types.READ_CURRENCIES, {
        error: errorData
      });
    }
  };
};
