import { Button } from '../../Shared/Button';

const DocNavigation = ({ onNext, onPrev, current, total }) => {
  return (
    <div className="navigation-btn-container">
      <Button icon="arrow-left" onClick={onPrev} disabled={current === 1} />
      <span>
        Page {current} of {total}
      </span>
      <Button
        icon="arrow-right"
        onClick={onNext}
        disabled={current === total}
      />
    </div>
  );
};

export default DocNavigation;
