import * as fields from "./fields";
import { indexItem } from "../../common/prompts";
import { closedValues } from "#constants/io/closed";

const generateList = (
  values = {},
  currency,
  reverseVesting,
  inputHandlers,
  closed,
  foundersMinusStakeholders,
  sharesMinusCommon,
  agreedAmount,
  subscriptionFinished,
  severalTranches = false,
  investorDirectors = "",
  independentDirectors = ""
) => {
  const {
    companyDetails: [fundraising, board],
    generalInformation,
    shares: [conversion, independentVal, vesting],
    appendices
  } = fields;
  const subscriptionCompleted = closed === closedValues.SUBSCRIPTION_APPROVED;
  const indexedFundraising = fundraising(
    values,
    currency,
    subscriptionCompleted,
    agreedAmount,
    subscriptionFinished,
    severalTranches
  )
    .filter(Boolean)
    .map(indexItem);

  const indexedBoard = board(
    values,
    inputHandlers,
    subscriptionCompleted,
    subscriptionFinished,
    investorDirectors,
    independentDirectors
  )
    .filter(Boolean)
    .map(indexItem);
  const indexedGeneralInformation = generalInformation(
    values,
    currency,
    subscriptionCompleted,
    subscriptionFinished
  )
    .filter(Boolean)
    .map(indexItem);
  const indexedConversion = conversion(
    sharesMinusCommon,
    subscriptionFinished
  ).map(indexItem);
  const indexedIndependentVal = independentVal(values).map(indexItem);
  const indexedVesting = vesting(values)
    .filter(Boolean)
    .map(indexItem);
  const indexedAppendices = appendices.map(indexItem);

  return [
    {
      sectionId: 1,
      subSectionId: 1,
      items: indexedFundraising
    },
    {
      sectionId: 1,
      subSectionId: 2,
      items: indexedBoard
    },
    {
      sectionId: 3,
      subSectionId: 3,
      items: indexedConversion
    },
    {
      sectionId: 3,
      subSectionId: 4,
      items: indexedIndependentVal,
      stepTitle:
        "In some cases, the parties to the shareholders’ agreement have an option to purchase the shares of another party. Sometimes a fair market value of the purchase price is hard to determine. Therefore, an independent expert or if such is missing / or not able to act, an experiences accounting firm is being appointed by the president of a cantonal chamber of commerce. Please define the independent expert and which chamber of commerce shall be referred to."
    },
    {
      sectionId: 3,
      subSectionId: 5,
      items: reverseVesting ? indexedVesting : [],
      stepTitle: !reverseVesting ? "Vesting is not applicable." : ""
    },
    {
      sectionId: 6,
      items: indexedGeneralInformation
    },
    {
      sectionId: 7,
      items: indexedAppendices,
      stepTitle:
        "The following document has to be uploaded for the Shareholders Agreement"
    }
  ];
};

export default generateList;
