import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { mapVotingAssemblyCreationValues } from "#helpers/mappers/voting";
import Form from "./Form/Presentational";

const VotingRightsWrapper = ({
  entityId,
  buttonStates,
  postVotingAssembly,
  clearState,
  history,
  getCurrentUser,
  phoneNumber,
  putGenerateVotingCodeDM,
  assembly,
  postPublicVoting,
  didVerify,
  clearStateData,
  postVotingAttachment
}) => {
  const { ioId } = useParams();

  useEffect(() => {
    clearState("voting");
    clearStateData("voting");
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (didVerify) {
      setTimeout(() => {
        history.push("/dashboard/voting");
        clearState("voting");
        clearStateData("voting");
      }, 3000);
    }
  }, [didVerify]);

  const formArgs = {
    buttonStates,
    phoneNumber,
    entityId,
    ioId,
    assembly,
    didVerify,
    postVotingAssembly,
    putGenerateVotingCodeDM,
    postPublicVoting,
    postVotingAttachment
  };

  return <Form {...formArgs} />;
};

export default VotingRightsWrapper;
