import { convertToBase64 } from "#helpers/convertBase64";
import { generateRandomColor, checkDateString } from "#helpers/general";

export const mapSharesFormValues = ({ entityId, ...values }) => {
  return {
    entityId,
    currencyId: values.currency,
    name: values.shareCategoryName,
    nominalValue:
      values.subjectToVesting === "1" ? 1 : values.nominalValuePrice,
    type: values.shareType,
    transferRestriction: values.restrictSharesTransfer === "yes",
    liquidationPreferentialClause: values.liquidationPreferentialClause,
    liquidationPreferentialParticipation:
      values.liquidationPreferentialParticipation,
    liquidationPreferentialCap: values.liquidationPreferentialCap,
    dividendPreferentialClause: values.dividendPreferentialClause,
    dividendPreferentialParticipation: values.dividendPreferentialParticipation,
    dividendPreferentialCap: values.dividendPreferentialCap,
    contractualPreferentialRights:
      {
        none: values.contractualRights
          ? values.contractualRights.includes("none")
          : false,
        liqudationPreference: values.contractualRights
          ? values.contractualRights.includes("liquidation")
          : false,
        dividentPreference: values.contractualRights
          ? values.contractualRights.includes("dividend")
          : false,
        antiDilutionProtection:
          values.contractualRights &&
          values.contractualRights.includes("anti-dilution")
            ? values.antiDilutionOptions
            : null
      } || {}
    // votesNumber: '1',
  };
};

export const mapEditSharesFromValues = ({
  contractualPreferentialRights = {},
  ...share
}) => {
  return {
    currency: share.currencyId,
    shareCategoryName: share.name,
    subjectToVesting: share.nominalValue === 1 ? "1" : "Other",
    nominalValuePrice: share.nominalValue,
    shareType: share.type,
    restrictSharesTransfer: share.transferRestriction ? "yes" : "no",
    contractualRights: [
      contractualPreferentialRights.none && "none",
      contractualPreferentialRights.liqudationPreference && "liquidation",
      contractualPreferentialRights.dividentPreference && "dividend",
      contractualPreferentialRights.antiDilutionProtection && "anti-dilution"
    ],
    antiDilutionOptions: contractualPreferentialRights.antiDilutionProtection,
    // votesNumber: '1',
    liquidationPreferentialClause: share.liquidationPreferentialClause,
    liquidationPreferentialParticipation:
      share.liquidationPreferentialParticipation,
    liquidationPreferentialCap: share.liquidationPreferentialCap,
    dividendPreferentialClause: share.dividendPreferentialClause,
    dividendPreferentialParticipation: share.dividendPreferentialParticipation,
    dividendPreferentialCap: share.dividendPreferentialCap,
    liquiditionPreferenceParticipating:
      share.liquidationPreferentialParticipation,
    liquiditionPreferenceClause: share.liquidationPreferentialClause
  };
};

export const mapUpdateSharesFormValues = ({ ...values }) => {
  return {
    name: values.shareCategoryName,
    nominalValue:
      values.subjectToVesting === "1" ? 1 : values.nominalValuePrice,
    type: values.shareType,
    transferRestriction: values.restrictSharesTransfer === "yes",
    contractualPreferentialRights: {
      none: values.contractualRights.includes("none"),
      liqudationPreference: values.contractualRights.includes("liquidation"),
      dividentPreference: values.contractualRights.includes("dividend"),
      antiDilutionProtection: values.contractualRights.includes("anti-dilution")
        ? values.antiDilutionOptions
        : null
    },
    liquidationPreferentialClause: values.liquidationPreferentialClause,
    liquidationPreferentialParticipation:
      values.liquidationPreferentialParticipation,
    liquidationPreferentialCap: values.liquidationPreferentialCap,
    dividendPreferentialClause: values.dividendPreferentialClause,
    dividendPreferentialParticipation: values.dividendPreferentialParticipation,
    dividendPreferentialCap: values.dividendPreferentialCap
    // votesNumber: '1',
  };
};

export const readShareItem = ({
  cuid,
  _id,
  name,
  nominalValue,
  total,
  type,
  contractualPreferentialRights,
  currency = {}
}) => {
  return {
    cuid,
    _id,
    name,
    type,
    nominalValue: nominalValue || 0,
    total: total || 0,
    contractualPreferentialRights,
    currency
  };
};

export const mapStakeholdersValues = async ({
  values = {},
  role,
  entityId,
  state,
  shares = [],
  isUpdating
}) => {
  let finalRole = "director";
  if (role === "1" && values.isFounder === "yes") {
    finalRole = "founder";
  } else if (role === "2" || (role === "1" && values.isFounder === "no")) {
    finalRole = "investor";
  }
  let payload = {};
  const directorSpecificFields = {
    representativeOf: values.representativeOf,
    signingAuthority: values.signingAuthority,
    presidentOfTheBoard: values.presidentOfTheBoard === "Yes"
  };

  if (finalRole === "founder" || finalRole === "investor") {
    payload = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      street: values.street,
      zipCode: values.postalCode,
      city: values.city,
      canton: values.canton,
      country: values.country,
      isDirector: values.isDirector === "Yes",
      role: finalRole
    };

    if (values.type === "legal") {
      const { representatives = [] } = values;
      payload.lastName = `${
        !values.lastName.includes("CHE")
          ? "CHE-"
          : values.lastName.includes("CHE") && !values.lastName.includes("-")
          ? `CHE-${values.lastName.split("CHE")[0]}`
          : ""
      }${
        values.lastName.includes("CHE")
          ? `CHE${values.lastName
              .split("CHE")[1]
              .replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`
          : values.lastName.replace(/(\d)(?=(\d{3})+$)/g, "$1.")
      }`;
      payload.entityIsShareholder = values.entityIsShareholder === "yes";
      if (representatives.length > 0) {
        const formattedRepresentatives = representatives.map(
          ({ firstName, lastName, signingAuthority, title }) => {
            return {
              firstName,
              lastName,
              signingAuthority,
              title
            };
          }
        );
        payload.representatives = formattedRepresentatives;
      }
    }

    if (isUpdating === false) {
      payload = {
        entityId,
        ...payload
      };
    }

    if (payload.isDirector) {
      payload = {
        ...payload,
        ...directorSpecificFields,
        dateOfBirth: values.birthdate,
        placeOfOrigin: values.placeOfOrigin
      };
    }

    if (finalRole === "founder" || finalRole === "investor") {
      if (values.founderShares) {
        const formattedShares =
          values.founderShares &&
          values.founderShares.map(
            ({
              number,
              type,
              paidUp,
              paidUpShares,
              sharesPrice,
              sharesPriceOther
            }) => {
              const { nominalValue = 0 } =
                shares.find(({ cuid }) => cuid === type) || {};

              const finalPaidUp =
                paidUp === "no" ? paidUpShares : number * nominalValue;

              const finalPrice =
                sharesPrice === "Other" ? sharesPriceOther : nominalValue;

              return {
                number,
                type,
                price: finalPrice,
                paidUpAmount: finalPaidUp
              };
            }
          );
        payload.shares = formattedShares || [];
      }
    }
    const formattedBeneficialOwners =
      values.beneficialOwners &&
      values.beneficialOwners.map(owner => ({
        firstName: owner.beneficialOwnerfirstName,
        lastName: owner.beneficialOwnerlastName,
        street: owner.beneficialOwnerstreet,
        postalCode: owner.beneficialOwnerpostalCode,
        place: owner.beneficialOwnercity,
        country: owner.beneficialOwnercountry,
        email: owner.beneficialOwneremail
      }));
    if (values.beneficialOwners) {
      payload.beneficialOwners = formattedBeneficialOwners || [];
    }
    const formattedConvertibles =
      values.convertibles &&
      values.convertibles.map(({ number, type, paymentDate }) => ({
        amount: number,
        type,
        paymentDate
      }));

    if (values.convertibles) {
      payload.convertibles = formattedConvertibles || [];
    }
  } else {
    payload = {
      userEmail: values.email,
      userFirstName: values.firstName,
      userLastName: values.lastName,
      userDateOfBirth: values.birthdate,
      userPlaceOfOrigin: values.placeOfOrigin,
      userStreet: values.street,
      userCity: values.city,
      userCountry: values.country,
      userZipCode: values.postalCode,
      userCanton: values.canton,
      isDirector: values.isDirector === "Yes",
      ...directorSpecificFields
    };

    if (values.type === "legal") {
      payload.userLastName = `${
        !values.lastName.includes("CHE")
          ? "CHE-"
          : values.lastName.includes("CHE") && !values.lastName.includes("-")
          ? `CHE-${values.lastName.split("CHE")[0]}`
          : ""
      }${
        values.lastName.includes("CHE")
          ? `CHE${values.lastName
              .split("CHE")[1]
              .replace(/(\d)(?=(\d{3})+$)/g, "$1.")}`
          : values.lastName.replace(/(\d)(?=(\d{3})+$)/g, "$1.")
      }`;
      // payload.entityIsShareholder = values.entityIsShareholder === "yes";
    }
    if (isUpdating === false) {
      payload = {
        entityId,
        ...payload,
        role: finalRole,
        redirectUrl: `${process.env.HOST}/login`,
        avoidNotification: true
      };
    }

    const { frontSide, backSide } = state;
    let frontBase64 = null;
    let backBase64 = null;

    if (frontSide) {
      frontBase64 = await convertToBase64(frontSide);
    }

    if (frontBase64) {
      payload.userIdDocument = { frontBase64 };
    }

    if (values.id_type === "ID") {
      if (backSide) {
        backBase64 = await convertToBase64(backSide);

        if (backBase64) {
          payload.userIdDocument = {
            ...(payload.userIdDocument || {}),
            backBase64
          };
        }
      }
    }
  }

  return payload;
};

export const mapEntityShareholdersEdit = values => {
  let beneficialOwners = [];
  let convertibles = [];
  const {
    convertibles: convertiblesV = [],
    beneficialOwners: beneficialOwnersV = []
  } = values;
  if (convertiblesV.length > 0) {
    convertibles = convertiblesV.map(
      ({ paymentDate = "", amount, ...otherFields }) => ({
        ...otherFields,
        number: amount,
        paymentDate: checkDateString(paymentDate)
      })
    );
  }
  if (beneficialOwnersV.length > 0) {
    beneficialOwners = beneficialOwnersV.map(bc => ({
      beneficialOwnerfirstName: bc.firstName,
      beneficialOwnerlastName: bc.lastName,
      beneficialOwnercity: bc.place,
      beneficialOwnercountry: bc.country,
      beneficialOwneremail: bc.email,
      beneficialOwnerpostalCode: bc.postalCode,
      beneficialOwnerstreet: bc.street
    }));
  }
  return {
    cuid: values.user.cuid,
    firstName: values.user.firstName,
    lastName: values.user.lastName,
    founderShares: values.shares,
    convertibles,
    representatives: values.representatives,
    beneficialOwners,
    isFounder: values.role === "founder" ? "yes" : "no",
    isDirector: values.isDirector ? "Yes" : "No",
    representativeOf: values.representativeOf,
    signingAuthority: values.signingAuthority,
    presidentOfTheBoard: values.presidentOfTheBoard ? "Yes" : "No",
    street: values.user.street,
    postalCode: values.user.zipCode,
    city: values.user.city,
    country: values.user.country,
    email: values.user.email,
    placeOfOrigin: values.user.placeOfOrigin,
    birthdate:
      values.user.dateOfBirth && checkDateString(values.user.dateOfBirth),
    role: values.role,
    canton: values.user.canton,
    entityIsShareholder: values.entityIsShareholder ? "yes" : "no"
  };
};

export const mapEntityShareholdersToStakeholder = ({
  cuid,
  shares,
  role,
  user: { firstName = "", lastName = "", isDirector } = {},
  convertibles,
  shareholderId,
  representativeOf,
  beneficialOwners,
  representatives,
  presidentOfTheBoard,
  entityIsShareholder,
  userId
} = {}) => ({
  cuid,
  name: `${firstName} ${lastName.match(/\d+/g) ? " " : lastName}`,
  role,
  shares,
  isDirector,
  convertibles,
  shareholderId,
  representativeOf,
  beneficialOwners,
  representatives,
  presidentOfTheBoard,
  entityIsShareholder,
  userId
});

/**
 * Translates share type to one of the following: C, C+, P
 * @param {object} share
 * @returns {string} Returns the readable type
 */
export const getShareType = share => {
  const { type, contractualPreferentialRights = {} } = share;

  if (type === "common") {
    if (!contractualPreferentialRights.none) {
      return "c1";
    }

    return "c";
  }

  return "p";
};

/**
 *
 * @param {array} shareClasses List of shares to be read
 * @returns {object} An object with key value pairs where key is the cuid and value the name of share class
 */
export const readShareClasses = shareClasses => {
  return shareClasses.reduce((acc, item) => {
    const { cuid: key } = item;

    return {
      ...acc,
      [key]: getShareType(item)
    };
  }, {});
};

export const readEntityShareholders = (shareholders, shares) => {
  const readableShares = readShareClasses(shares);

  const { sharesNumber, labels, colors, data } = shareholders.reduce(
    (acc, item) => {
      const {
        user: { firstName, lastName },
        shares
      } = item;

      const totalShares = shares.reduce(
        (total, { number }) => total + number,
        0
      );

      const {
        sharesNumber: currSharesNum = 0,
        labels: accLabels = [],
        data: accData = [],
        colors: accColors = []
      } = acc;

      return {
        labels: [...accLabels, `${firstName} ${lastName}`],
        data: [...accData, totalShares],
        colors: [...accColors, generateRandomColor()],
        sharesNumber: currSharesNum + (totalShares || 0)
      };
    },
    {}
  );

  const formattedShareholders = shareholders.map(item => {
    const {
      user: { firstName, lastName },
      shares = [],
      convertibles = []
    } = item;
    const { c = 0, c1 = 0, p = 0, totalShares = 0 } = shares.reduce(
      (acc, item) => {
        const { number, type } = item;
        const key = readableShares[type];
        const { [key]: accKey = 0, totalShares: accTotalShares = 0 } = acc;

        return {
          ...acc,
          [key]: accKey + number,
          totalShares: accTotalShares + number
        };
      },
      {}
    );

    const totalSharesPercen =
      sharesNumber !== 0
        ? Number((totalShares / sharesNumber) * 100).toFixed(2)
        : 0;

    return {
      name: `${firstName} ${lastName}`,
      sharesNumber: totalShares,
      c,
      c1,
      p,
      totalSharesPercen
    };
  });

  return [labels, data, colors, formattedShareholders];
};
