const InProgressIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="16" stroke="#2B5797" stroke-width="2" />
    <circle cx="12.5" cy="19.5" r="2.5" fill="#2B5797" />
    <circle cx="26.7856" cy="19.5" r="2.5" fill="#2B5797" />
    <circle cx="19.6428" cy="19.5" r="2.5" fill="#2B5797" />
  </svg>
);

const InProgressOpaqueIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="16" stroke="#9C9C9C" stroke-width="2" />
    <circle cx="12.5" cy="19.5" r="2.5" fill="#9C9C9C" />
    <circle cx="26.7856" cy="19.5" r="2.5" fill="#9C9C9C" />
    <circle cx="19.6428" cy="19.5" r="2.5" fill="#9C9C9C" />
  </svg>
);

export { InProgressIcon, InProgressOpaqueIcon };
