import axios from "./apiConfig";

export const getCompanyinfo = ({ name }) =>
  axios.get(`contracts/companyInfo/${name}`);

export const getDocumentTypes = () => axios.get(`document-types`);

export const generateContractService = ({
  investmentId,
  amount,
  investorPhone
}) => {
  return axios.get(`contracts/syndicate-terms-template`, {
    params: {
      io_id: investmentId,
      amount,
      investor_phone: investorPhone
    }
  });
};

export const getContracts = (data = {}) =>
  axios.get("/contracts", {
    params: {
      investment_id: data.investment_id,
      investor_id: data.investor_id,
      type: data.type
    }
  });

export const getPooledFundTemplates = params =>
  axios.get("contracts/pooled-fund-templates", { params });

export const getContractsPreview = params =>
  axios.get("contracts/founder-previews", { params });

export const getInvestmentCompliance = params =>
  axios.get("contracts/investment-compliance-template", { params });
export const generateCla = data => axios.post(`contracts/cla`, data);

export const getInvConfirmation = params =>
  axios.get(`contracts/investment-confirmation`, { params });

export const getTransConfirmation = params =>
  axios.get("contracts/transaction-confirmation", { params });

export const getInvestmentSummary = params =>
  axios.get("contracts/investment-summary", { params });

export const getDealConfirmation = params =>
  axios.get(`contracts/deal-confirmation`, { params });

export const putUnitCost = ({ cuid, ...data }) =>
  axios.put(`document-types/${cuid}/unit_cost`, data);

export const postContracts = data => axios.post(`contracts`, data);
