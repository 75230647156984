import { basicOption } from "#helpers/general";

export const votingFormList = values => [
  {
    sectionId: 1,
    items: [
      {
        id: 1,
        title: "What is the assembly voting objective?",
        details: "This will create 2 different content bucket for our archive",
        input: {
          name: "voting_objective",
          type: "select_btn",
          options: [
            basicOption("target_company", "Target company"),
            basicOption(
              "internal_syndicate_decision",
              "Internal syndicate decision"
            )
          ]
        }
      },
      {
        id: 2,
        title: "By when does the voting need to be completed?",
        details: "Please pick a date from this calendar",
        input: {
          name: "voting_date",
          type: "date"
        }
      }
    ]
  },
  {
    sectionId: 2,
    items: [
      {
        id: 3,
        title: "Please add a support document to the voting",
        details: "This will help the stakeholders understand the voting topics",
        input: {
          name: "voting_docs",
          type: "drag_upload",
          accept: ".pdf, .xls, .doc, .docx"
        }
      }
    ]
  },
  {
    sectionId: 3,
    items: [
      {
        id: 4,
        title:
          "Feel free to pick on pre-compiled questionnaire for the specific case",
        details: "Or Custom, if you want to add vour own questions",
        input: {
          name: "pre_compiled_questionnaire",
          type: "select_btn",
          options: [
            basicOption("dm_replacement", "DM Replacement")
            // basicOption("secondary_selling", "Secondary selling"),
            // basicOption("call_for_a_meeting", "Call for a meeting")
          ]
        }
      }
    ]
  },
  {
    sectionId: 4,
    items: [
      {
        id: 5,
        title: "Please add your assembly session questions",
        details:
          "Feel free to add as many questions you like and then hit next",
        input: {
          name: "assembly_questions",
          type: "questions_adder"
        }
      }
    ]
  },
  {
    sectionId: 5,
    items: [
      {
        id: 6,
        title: "Please control the question correctness",
        details: "Is the questionnaire complete and the questions correct?",
        input: {
          name: "assembly_questions_prev",
          type: "questions_preview"
        }
      }
    ]
  },
  {
    sectionId: 6,
    items: [
      {
        id: 7,
        className: "_hidden",
        input: {
          name: "phone_number",
          type: "text"
        }
      },
      {
        id: 8,
        title:
          "Please input your number and click this button to receive an SMS code from LEVA",
        details:
          "The code will be required to sign vour votes digitally online",
        className: "_width-30",
        input: {
          name: "send_sms_code",
          type: "send-code-btn"
        }
      },
      ...(values?.send_sms_code === "sent"
        ? [
            {
              id: 9,
              title: "Please confirm the code you just received via SMS",
              details: "The M-IAN process will act as a signature on your side",
              className: "_width-30",
              input: {
                name: "verify_sms_code",
                type: "verify-input-btn"
              }
            }
          ]
        : [])
    ]
  }
];

export const dmReplacement = [
  {
    index: 0,
    question:
      "Do you agree with a change of Service Provider from [your name/entity here] to [the new Service Provider name] starting from [official date]?"
  }
];

export const secondarySelling = [
  {
    index: 0,
    question: "Do you want to sell your syndicate units?"
  }
];

export const callForMeeting = [
  {
    index: 0,
    question: "Which is the meeting main topic?"
  }
];
