import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { mapItemToCapTable } from '#helpers/mappers/capTable';
import { CardWrapper } from '../../../../../Shared/Cards';
import Dropdown from '../../../../../Shared/Dropdown';
import Button from '../../../../../Shared/Button/components/BtnWrapper/Presentational';
import { Table, Progress, StatsSection } from '../../../../../Shared/CapTable';

const Overview = ({
  entityShares,
  entityId,
  shareholders,
  getEntity,
  getEntityShareholders
}) => {
  useEffect(() => {
    getEntity(entityId);
    getEntityShareholders({ entity_id: entityId });
  }, []);

  const calculateShare = (share) =>
    Number((share * 100) / entityShares).toFixed(2);
  const shareholdersData = shareholders.reduce((acc, item) => {
    const prevLabels = acc.labels || [];
    const prevItems = acc.items || [];
    const prevDataInfo = acc.dataInfo || [];
    const prevTotalShares = acc.totalShares || 0;
    const { user: { firstName, lastName } = {}, sharesNumber } = item;

    return {
      totalShares: prevTotalShares + sharesNumber,
      labels: [...prevLabels, `${firstName} ${lastName}`],
      items: [
        ...prevItems,
        mapItemToCapTable({
          ...item,
          shareholding: `${calculateShare(sharesNumber)}%`
        })
      ],
      dataInfo: [...prevDataInfo, sharesNumber]
    };
  }, {});

  return (
    <div className="overview-wrapper">
      <CardWrapper className="overview">
        <div className="dropdown-container">
          <Dropdown disabled trigger={['click']}>
            <div>
              <Button
                label={
                  <span>
                    By Shareholding <Icon type="down" />
                  </span>
                }
                type="normal"
              />
            </div>
          </Dropdown>
        </div>
        <div className="statistics">
          <StatsSection
            title="Shareholding"
            labels={shareholdersData.labels}
            dataInfo={shareholdersData.dataInfo}
          />
        </div>
        <div className="progress-section">
          <Progress
            title="Total Shares"
            percentage={(shareholdersData.totalShares * 100) / entityShares}
            value={entityShares}
            indicator="blue"
          />
        </div>
      </CardWrapper>
      <Table data={shareholdersData.items} />
    </div>
  );
};

Overview.propTypes = {
  entityShares: PropTypes.any,
  entityId: PropTypes.string,
  shareholders: PropTypes.array,
  getEntity: PropTypes.func,
  getEntityShareholders: PropTypes.func
};

export default Overview;
