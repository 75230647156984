import { connect } from "react-redux";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { _data: { entity: { userRole } = {} } = {} } = state.entities;

  return {
    role: userRole
  };
};

export default connect(mapStateToProps)(Presentational);
