import { handleColor } from '#helpers/general';
import Checkbox from '../../../../Shared/Checkbox';
import InputContainer from '../../../../Shared/Input';
import TagWrapper from '../../../../Shared/Tag';

const generalInformationsCols = (values, { checkboxInArray }) => [
  {
    title: 'Select',
    dataIndex: 'signingDirector',
    key: 'signingDirector',
    render: (value, { cuid, signingAuthority }) => {
      return (
        <Checkbox
          checked={value}
          name='signingDirector'
          onChange={checkboxInArray('signingDirector', cuid)}
          disabled={signingAuthority === 'none'}
        />
      );
    },
  },
  {
    title: 'First name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'President of the board',
    dataIndex: 'presidentOfTheBoard',
    key: 'presidentOfTheBoard',
    render: (presidentOfTheBoard) => (presidentOfTheBoard ? 'Yes' : 'No')

  },
  {
    title: 'Representing',
    dataIndex: 'representativeOf',
    key: 'representativeOf',
    render: (text) =>
      <TagWrapper color={handleColor(text)}>
        {text}
      </TagWrapper>
  },
  {
    title: 'Signature',
    dataIndex: 'signingAuthority',
    key: 'signingAuthority',
    render: (text) => (
      <TagWrapper color={handleColor(text)}>
        {text}
      </TagWrapper>
    )
  },
];

const investorCols = ({ checkboxInArray, inputInArray }) => [
  {
    title: 'Select',
    dataIndex: 'signingInvestor',
    key: 'signingInvestor',
    render: (value, { cuid }) => {
      return (
        <Checkbox
          checked={value}
          name='signingInvestor'
          onChange={checkboxInArray('signingInvestor', cuid)}
        />
      );
    },
  },
  {
    title: 'First name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
    key: 'lastName',
    render: (val) => (val.match(/\d+/g) ? ' ' : val)

  },
  {
    title: 'Amount to be invested',
    dataIndex: 'amount',
    key: 'amountForInvestment',
    render: (value, { cuid }) => (
      <InputContainer
        type='number'
        name='amount'
        value={value}
        onChange={inputInArray('signingInvestor', cuid)}
        formatter={(val) => val && `CHF ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
        parser={(val) => val.replace(new RegExp(`CHF\\s?|(\\'*)`, 'g'), '')}
      />
    ),
  },
];

export { generalInformationsCols, investorCols };
