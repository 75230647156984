import axios from "./apiConfig";

export const getCommitments = params =>
  axios.get(`commitments`, {
    params
  });

export const getEntityCaptable = params =>
  axios.get("cap-tables/entity", { params });

export const postCommitment = values => axios.post(`commitments`, values);

export const patchCommitment = ({ cuid, ...values }) =>
  axios.patch(`commitments/${cuid}`, values);

export const putCommitment = ({ cuid, ...data }) =>
  axios.put(`commitments/${cuid}/status`, data);

export const getPartnershipContractService = (io, amount) =>
  axios.get(`/partnership-contract-template/${io}?amount=${amount}`);

export const deleteCommitment = cuid => axios.delete(`commitments/${cuid}`);
