import axios from "./apiConfig";

export const getVotings = params => axios.get("voting", { params });

export const getVotingDoc = params => axios.get("voting/generate", { params });

export const getSingleVoting = ({ cuid, ...params }) =>
  axios.get(`voting/${cuid}`, { params });

export const postVoting = data => axios.post("voting", data);

export const postVotingResult = (cuid, ...data) =>
  axios.post(`voting/${cuid}/result`, ...data);

export const postVotingAttachment = ({ cuid, ...data }) =>
  axios.post(`voting/${cuid}/attachments`, data);

export const postPublicVoting = ({ cuid, ...data }) =>
  axios.post(`voting/${cuid}/public-voting`, data);

export const patchVoting = ({ cuid, ...data }) =>
  axios.patch(`voting/${cuid}`, data);

export const putVoting = ({ cuid }) =>
  axios.put(`voting/${cuid}/result/sms-verification`);

export const putGenerateVotingCodeDM = ({ cuid, phoneNumber }) =>
  axios.put(`voting/${cuid}/sms-verification-DM`, { phoneNumber });

export const putGenerateVotingCodeInv = ({ cuid }) =>
  axios.put(`voting/${cuid}/result/sms-verification`);

export const deleteVoting = cuid => axios.delete(`voting/${cuid}`);

export const deleteVotingAttachment = ({ cuid, attachmentId }) =>
  axios.delete(`voting/${cuid}/attachments/${attachmentId}`);
