import { useState, Fragment } from "react";
import FormSuccessView from "../../../Shared/Form/FormStepper/components/SuccessView";
import { Button } from "../../../Shared/Button";
import { InviteInvestorsModal } from "../../../Shared/Modals";

const DealCreationSuccess = ({ createdIoCuid, update, dealType }) => () => {
  const [state, setState] = useState({});

  const handleModalVisible = () =>
    setState({ ...state, modalOpen: !state.modalOpen });

  return (
    <Fragment>
      {/* <InviteInvestorsModal
        visible={state.modalOpen}
        handleClose={handleModalVisible}
        ioId={createdIoCuid}
      /> */}
      <div className="custom-success-view">
        <FormSuccessView
          title={`Congratulations! You have successfully ${
            update ? "updated your deal." : `set-up a new ${dealType}`
          } `}
          id="success-prompt"
        />
        {/* <div className="btn-container">
          <Button
            label="Invite investors"
            className="main-btn"
            onClick={handleModalVisible}
          />
          <span className="helper-text">You can invite investors anytime.</span>
        </div> */}
        <div className="btn-container">
          <Button
            label="Preview and edit deal"
            // className="main-btn"
            type="secondary"
            size="large"
            onClick={handleModalVisible}
            href={{
              pathname: `/dashboard/deal_management/${createdIoCuid}`,
              state: {
                fromForm: true
              }
            }}
          />
          <span className="helper-text">
            You can access the list of your deals on open deals page.
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default DealCreationSuccess;
