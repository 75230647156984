import { initialState, baseReducer } from "../../utils/helper_reducers";
import types from "./action_types";
import actions from "../../constants/action_type";

const initState = {
  ...initialState
};
const reducer = (state = initState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case types.GET_NOTIFS_HISTORY:
      return baseReducer(action, state, actions.FETCH, {
        success: () => {
          return {
            ...state._data,
            history: action.payload.data
          };
        }
      });

    case types.BULK_UPDATE_NOTIFS_STATUS:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { data = [] } = action.payload;
          const { history = [] } = state._data || {};

          return {
            ...state._data,
            history: history.map(item => {
              const updatedItm = data.find(({ cuid }) => cuid === item.cuid);

              if (updatedItm) {
                return {
                  ...item,
                  viewed: true
                };
              }

              return item;
            })
          };
        }
      });

    case types.UPDATE_NOTIFS_LIST:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { data } = action.payload;
          const { history = [] } = state._data || {};

          return {
            ...state._data,
            history: [data, ...history]
          };
        }
      });

    case types.POST_NOTIFS_SUBS:
      return baseReducer(action, state, actions.CREATE, {
        success: () => ({
          ...state._data
        })
      });

    case types.DELETE_NOTIFS_SUBS:
      return baseReducer(action, state, actions.DELETE, {
        success: () => ({
          ...state._data
        })
      });

    case types.UPDATE_NOTIFS_STATUS:
      return baseReducer(action, state, actions.UPDATE, {
        success: () => {
          const { history = [] } = state._data || {};

          const updatedHistory = history.map(item => {
            if (item.cuid === action.payload.data) {
              return { ...item, read: true };
            }
            return item;
          });

          return {
            ...state._data,
            history: updatedHistory
          };
        }
      });

    default:
      return state;
  }
};
export default reducer;
