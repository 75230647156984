import { useState } from "react";
import { contractTypes } from "#constants/deal";
import { Button } from "../../../../../../../Shared/Button";
import SpinContainer from "../../../../../../../Shared/SpinContainer";
import { PdfViewer } from "../../../../../../../PDFViewer/Presentational";
import ModalWrapper from "../../../../../../../Shared/Modal";
import Switch from "../../../../../../../Shared/Switch";

const TosModal = ({
  contractsColl,
  handleAccept,
  ioLoading,
  ...modalConfig
}) => {
  const { loading, data } = contractsColl;
  const [docsAccepted, setDocsAccepted] = useState(false);
  const { value: tos = null } =
    data.find(({ contractType }) => contractType === "syndicate-terms") || {};
  const handleSwitch = () => setDocsAccepted(!docsAccepted);
  return (
    <ModalWrapper
      title="Update of the Terms of Syndicate"
      {...modalConfig}
      width={850}
      footer={null}
      className="tos-modal"
      closable={false}
    >
      <div>
        <span>
          To consistently be compliant with the Swiss regulator, we updated your
          terms of syndicate. Once all members of the syndicate have accepted
          the new terms, you will find the new version in your deal dashboard.{" "}
        </span>
        {loading && <SpinContainer />}
        {!!tos && <PdfViewer data={tos} />}
      </div>
      <Switch
        label="I have read, understood and accept the Terms of Syndicate"
        name="docsAccepted"
        onChange={handleSwitch}
        value={docsAccepted}
      />
      <div className="btn-container">
        <Button
          label="Sign"
          size="large"
          onClick={handleAccept}
          loading={ioLoading}
          disabled={!docsAccepted}
        />
      </div>
    </ModalWrapper>
  );
};

export default TosModal;
