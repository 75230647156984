import { format } from "date-fns";
import { commitStatus } from "#constants/commit";
import { dealRounds } from "#constants/deal";
import { formatNumber } from "#helpers/number_format";
import TooltipWrapper from "../../components/Shared/Tooltip";

export const mapCommitData = ({
  item = {},
  investment,
  committer = {},
  sharesPrice,
  investmentLevel,
  fundingGoal,
  ioMoneyCommitted,
  ioMoneyInvested,
  roundedMoney
}) => {
  const { type } = investment || {};
  const { name } = committer;
  const { shortName } = item.currency || {};
  const rawSharesNumber = item.sharesNumber;
  const sharesNumber =
    rawSharesNumber ||
    (sharesPrice ? Math.floor(item.amount / sharesPrice) : 0);

  const TooltipData = ({ tooltip, children }) => {
    return <TooltipWrapper title={tooltip}>{children}</TooltipWrapper>;
  };

  const title = dataType =>
    `${dataType} data isn't available for soft or pending commitments.`;
  const hideCalc = item.status === commitStatus.REJECTED || !sharesPrice;

  const finalAmount = adjustAmount({
    ...item,
    sharesPrice: sharesPrice || 1,
    fundingGoal,
    moneyCommitted: ioMoneyCommitted,
    moneyInvested: ioMoneyInvested
  });
  const totalMoney =
    (roundedMoney.committed || 0) +
    (roundedMoney.invested || 0) +
    (roundedMoney.softCommited || 0);

  const totalShares = Math.floor(totalMoney / sharesPrice);

  const ownership = !totalMoney ? 0 : (sharesNumber / totalShares) * 100;

  return {
    entity: item.entity && item.entity.name,
    name,
    type,
    status: returnCommitStatus(item.status),
    currency: item.currency && item.currency.shortName,
    date: format(item.createdAt, "DD/MM/YYYY"),
    finalAmount,
    amount: `${shortName} ${formatNumber(finalAmount)}`,
    shares: `${(item.amount / ioMoneyInvested) * 100} %`,
    sharesNumber: hideCalc ? (
      <TooltipData tooltip={title("Share numbers")}>
        <span>N/A</span>
      </TooltipData>
    ) : (
      sharesNumber
    ),
    ownership: Number(ownership).toFixed(2),
    rawOwnership: ownership,
    sharesPrice: sharesPrice ? `${shortName} ${sharesPrice}` : "N/A",
    round: dealRounds[investmentLevel],
    rawSharesNumber: sharesNumber,
    rawAmount: item.amount,
    hasRealCommitTimestamp: !!item.realCommitTimestamp
  };
};

export const returnCommitStatus = status => {
  if (status === commitStatus.SOFT) {
    return "Soft commitment";
  } else if (status === commitStatus.COMPLETED) {
    return "Invested (funds approved)";
  } else if (status === commitStatus.REJECTED) {
    return "Rejected";
  } else if (status === commitStatus.ARRIVED) {
    return "Funds arrived";
  }

  return "Subscribed (signed TOS)";
};

export const getAggregatedCommits = ({
  commits,
  sharesPrice,
  investmentLevel,
  sharesNumber,
  moneyInvested,
  moneyCommitted,
  groupByUser,
  groupByCommitStatus,
  fundingGoal,
  roundedMoney
}) => {
  if (groupByUser) {
    const [
      commitsVal,
      invVal,
      { investedList = [], committedList = [] }
    ] = commits.reduce(
      (acc, item) => {
        let [
          committed,
          invested,
          { investedList = [], committedList = [] }
        ] = acc;

        const {
          amount,
          committerId,
          currency: { shortName: currency } = {},
          committer: { name } = {},
          status,
          rawSharesNumber
        } = item;

        if (status === commitStatus.REJECTED) {
          return [committed, invested, { investedList, committedList }];
        }
        const { [committerId]: curr = { amount: 0, sharesNumber: 0 } } =
          status === commitStatus.PENDING || status === commitStatus.SOFT
            ? committedList
            : investedList;

        const finalAmount = adjustAmount({
          ...item,
          sharesPrice: sharesPrice || 1,
          fundingGoal,
          moneyCommitted,
          moneyInvested
        });

        const finalSharesNumber =
          rawSharesNumber ||
          (sharesPrice ? Math.floor(amount / sharesPrice) : 0);

        if (status === commitStatus.PENDING || status === commitStatus.SOFT) {
          return [
            Number(committed) + Number(finalAmount),
            invested,
            {
              investedList,
              committedList: {
                ...committedList,
                [committerId]: {
                  name,
                  sharesNumber:
                    Number(finalSharesNumber) + Number(curr.sharesNumber),
                  amount: Number(finalAmount) + Number(curr.amount),
                  currency,
                  sharesPrice
                }
              }
            }
          ];
        }

        return [
          committed,
          Number(invested) + Number(finalAmount),
          {
            investedList: {
              ...investedList,
              [committerId]: {
                name,
                sharesNumber:
                  Number(finalSharesNumber) + Number(curr.sharesNumber),
                amount: Number(finalAmount) + Number(curr.amount),
                currency,
                sharesPrice
              }
            },
            committedList
          }
        ];
      },
      [0, 0, []]
    );

    const flattenCommits = (list, status) =>
      Object.keys(list).map(key => {
        const { currency, amount, fundingGoal } = list[key] || {};
        let ownership = 0;
        const totalMoney =
          (roundedMoney?.committed || 0) +
          (roundedMoney?.invested || 0) +
          (roundedMoney?.softCommited || 0);

        if (totalMoney > 0) {
          ownership = Number((amount / totalMoney) * 100).toFixed(2);
        }

        const finalAmount =
          Math.ceil(
            (Math.floor(amount / sharesPrice) * sharesPrice).toFixed(5) * 100
          ) / 100;

        return {
          ...list[key],
          amount: `${currency} ${formatNumber(finalAmount)}`,
          ownership,
          status,
          round: dealRounds[investmentLevel]
        };
      });

    if (groupByCommitStatus) {
      return [
        commitsVal,
        invVal,
        {
          committed: flattenCommits(committedList, "Committed"),
          invested: flattenCommits(investedList, "Invested")
        }
      ];
    }

    return [commitsVal, invVal, { committed: [], invested: {} }];
  }

  return commits.reduce(
    (acc, item) => {
      let [
        committed,
        invested,
        formattedCommits,
        rawCommitted,
        rawInvested
      ] = acc;

      const formattedItem = mapCommitData({
        item,
        sharesPrice,
        committer: item.committer,
        investmentLevel,
        dealSharesNumber: sharesNumber,
        ioMoneyInvested: moneyInvested,
        ioMoneyCommitted: moneyCommitted,
        roundedMoney,
        fundingGoal
      });
      const { status } = item;
      const { finalAmount, rawAmount } = formattedItem;

      if (status === commitStatus.PENDING || status === commitStatus.SOFT) {
        committed += Number(finalAmount);
        rawCommitted += Number(rawAmount);
      } else if (status === commitStatus.COMPLETED) {
        invested += Number(finalAmount);
        rawInvested += Number(rawInvested);
      }

      formattedCommits.push(formattedItem);

      return [committed, invested, formattedCommits, rawCommitted, rawInvested];
    },
    [0, 0, [], 0, 0]
  );
};

export const adjustAmount = ({
  amount,
  status,
  sharesPrice,
  fundingGoal,
  moneyCommitted = 0,
  moneyInvested = 0
}) => {
  if (true) {
    return (
      Math.ceil(
        (Math.floor(amount / sharesPrice) * sharesPrice).toFixed(5) * 100
      ) / 100
    );
  }

  // IGNORING THIS CALCULATION FOR A BIT

  return Number(
    Math.floor(
      ((fundingGoal / (moneyInvested + moneyCommitted)) * amount) / sharesPrice
    ) * sharesPrice
  ).toFixed(2);
};

export const calculateFinalAllocation = ({
  commits = [],
  totalAllocation = 0,
  sharesPrice = 0
}) => {
  if (totalAllocation % sharesPrice !== 0) {
    if (commits.length === 0) {
      return totalAllocation;
    }

    const totalCommitments = commits.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );

    const total = commits.reduce((acc, commit) => {
      return (
        acc +
        (Math.floor(
          ((totalAllocation / totalCommitments) * commit.amount) / sharesPrice
        ) * sharesPrice || 0)
      );
    }, 0);

    return Number(total).toFixed(2);
  }

  return (totalAllocation / sharesPrice) * sharesPrice;
};
