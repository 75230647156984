import * as commitService from "../../../../api/commitmentService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const getCommits = data => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_ALL_COMMITS);

      const response = await commitService.getCommitments(data);

      success(dispatch, types.FETCH_ALL_COMMITS, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_ALL_COMMITS, {
        error: errorData
      });
    }
  };
};

export const getEntityCaptable = params => {
  return async dispatch => {
    try {
      start(dispatch, types.FETCH_ENTITY_CAPTABLE);

      const response = await commitService.getEntityCaptable(params);

      success(dispatch, types.FETCH_ENTITY_CAPTABLE, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.FETCH_ENTITY_CAPTABLE, {
        error: errorData
      });
    }
  };
};

export const getPartnershipContract = (io, amount) => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_PARTNERSHIP);
      const response = await commitService.getPartnershipContractService(
        io,
        amount
      );
      success(dispatch, types.GET_PARTNERSHIP, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_PARTNERSHIP, {
        error: errorData
      });
    }
  };
};
