import SharedTable from "../../../SharedTable/Presentational";
import { overviewTable, sharesTable, ioShares } from "./tableColumns";

const TableWrapper = ({ data, type, dealType }) => {
  const cols = () => {
    if (type === "shares") {
      return sharesTable;
    } else if (type === "io_shares") {
      return ioShares(dealType);
    }
    return overviewTable;
  };

  return (
    <SharedTable
      className="captable-wrapper"
      columns={cols()}
      dataSource={data}
    />
  );
};

export default TableWrapper;
