import InputRenderer from '../../../Shared/InputRenderer';
import TableList from './TableList';
import SharesList from './SharesList';

// FOR USE IN TS AND IA FORMS

const CustomInputRenderer = (props) => {
  const { type } = props;

  if (type === 'table_list') {
    return <TableList {...props} />;
  }

  if (type === 'shares_list') {
    return <SharesList {...props} />;
  }

  return <InputRenderer {...props} />;
};

export default CustomInputRenderer;
