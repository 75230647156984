export default {
  GET_CONTRACTS: "GET_CONTRACTS",
  GET_DOCUMENT_TYPES: "GET_DOCUMENT_TYPES",
  GENERATE_CLA: "GENERATE_CLA",
  FETCH_COMPANY_INFO: "FETCH_COMPANY_INFO",
  GET_CONTRACTS_PREVIEW: "GET_CONTRACTS_PREVIEW",
  FETCH_INVESTMENT_COMPLIANCE: "FETCH_INVESTMENT_COMPLIANCE",
  FETCH_INVESTMENT_CONFIRMATION: "FETCH_INVESTMENT_CONFIRMATION",
  FETCH_TRANS_CONFIRMATION: "FETCH_TRANS_CONFIRMATION",
  FETCH_DEAL_CONFIRMATION: "FETCH_DEAL_CONFIRMATION",
  FETCH_INV_SUMMARY: "FETCH_INV_SUMMARY",
  GENERATE_CONTRACT: "GENERATE_CONTRACT",
  FETCH_POOLED_FUND_TEMPLATES: "FETCH_POOLED_FUND_TEMPLATES",
  UPDATE_UNIT_COST: "UPDATE_UNIT_COST",
  POST_CONTRACTS: "POST_CONTRACTS"
};
