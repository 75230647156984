import { NavLink } from "react-router-dom";
import { formatArrayKeys } from "#helpers/arrayFuncs";
import { inputNumberFormatter, basicOption } from "#helpers/general";
import { maximumInterestCurrencyPercentige } from "#helpers/general";
import { claTsKeys } from "#constants/cla";
import {
  confirmationPrompt,
  renderOtherInputWithoutId
} from "../../../common/prompts";
import Checkbox from "../../../../Shared/Checkbox";
import InputContainer from "../../../../Shared/Input";
import TagWrapper from "../../../../Shared/Tag";

const getInterestRate = (currency, moneyRaised, investors = []) => {
  let moneys = 0;
  let selectedInvestors = [];
  if (investors) {
    selectedInvestors = investors.filter(
      ({ select = false, amount = 0 }) => select && amount > 0
    );
  }

  if (selectedInvestors.length > 0) {
    selectedInvestors.forEach(({ amount = 0 }) => {
      moneys += amount;
    });
  } else {
    moneys = moneyRaised;
  }
  if (moneys <= 1000000) {
    return maximumInterestCurrencyPercentige.chf1;
  } else if (currency === "CHF") {
    return maximumInterestCurrencyPercentige.chf2;
  } else if (currency === "GBP") {
    return maximumInterestCurrencyPercentige.gbp;
  } else if (currency === "USD") {
    return maximumInterestCurrencyPercentige.usd;
  }
  return maximumInterestCurrencyPercentige.eur;
};

const information = (currencies, currency, values) => [
  {
    title: "In which currency are you raising funds?",
    input: {
      type: "select_btn",
      options: formatArrayKeys(currencies, "cuid", "shortName"),
      name: claTsKeys.CURRENCY
    }
  },
  {
    title:
      "What is the total amount of the funds raised by the company with this convertible loan?",
    input: {
      type: "number",
      ...inputNumberFormatter(currency),
      name: claTsKeys.MONEY_RAISED
    }
  },
  {
    title:
      "Shall the Company have the right, to reduce the loan amount in case of an over-subscription in this financing round?",
    input: {
      ...confirmationPrompt,
      name: claTsKeys.REDUCE_LOAN_PRIOR_MATURITY_DATE
    }
  },
  {
    title:
      "Shall the Company be entitled to repay the loan prior the Maturity Date?",
    input: {
      name: claTsKeys.REPAY_LOAN_PRIOR_MATURITY_DATE,
      type: "select_btn",
      options: [
        basicOption("own_discretion", "Yes, at its own discretion"),
        basicOption("lenders_consent", "Yes, with consent of the lender(s)", {
          tooltip:
            "The lender(s) will need to approve the prepayment of the loan. Without the lender’s approval the company will not be entitled to repay the loan prior the Maturity Date and the borrowed amount will convert into shares"
        }),
        basicOption("none", "No")
      ]
    }
  },
  values[claTsKeys.REPAY_LOAN_PRIOR_MATURITY_DATE] === "lenders_consent" && {
    title: "Within what term must the lender(s) reply to the prepayment offer?",
    input: {
      name: claTsKeys.LENDER_REPAY_TERM,
      type: "select_btn",
      options: [
        basicOption("15", "15 days"),
        basicOption("30", "30 days"),
        basicOption("Other")
      ]
    }
  },
  values[claTsKeys.LENDER_REPAY_TERM] === "Other" &&
    renderOtherInputWithoutId(claTsKeys.LENDER_REPAY_TERM_OTHER, "number"),
  {
    title:
      "Shall the loan be converted mandatorily (no discretion of the lender) or voluntarily (at the discretion of the lender)?",
    input: {
      type: "select_btn",
      options: [
        basicOption("voluntary", "Voluntary conversion"),
        basicOption("mandatory", "Mandatory conversion")
      ],
      name: claTsKeys.CONVERSION_PERFORM
    }
  }
];

const investorsColumns = (
  values,
  { currency, inputHandlers: { checkboxInArray, inputInArray }, name }
) => {
  const hasShareholder =
    values.investorsTable &&
    values.investorsTable.length > 0 &&
    !!values.investorsTable.find(
      ({ select, type }) =>
        select && (type === "Shareholder" || type === "Shareholder Lender")
    );
  const hasLender =
    values.investorsTable &&
    values.investorsTable.length > 0 &&
    !!values.investorsTable.find(
      ({ select, type }) => select && (type === "Lender" || type === null)
    );

  const hasEntityShareholdersSelected =
    values.investorsTable &&
    values.investorsTable.length > 0 &&
    !!values.investorsTable.find(
      ({ entityIsShareholder, select }) => select && entityIsShareholder
    );
  return [
    {
      title: "Select",
      dataIndex: "select",
      key: "select",
      render: (value, { cuid, type, entityIsShareholder }) => (
        <Checkbox
          name="select"
          checked={value}
          disabled={
            hasEntityShareholdersSelected && !entityIsShareholder
              ? true
              : hasShareholder && (type === "Lender" || type === null)
              ? true
              : hasLender &&
                (type === "Shareholder" || type === "Shareholder Lender")
              ? true
              : false
          }
          onChange={checkboxInArray(claTsKeys.INVESTORS_TABLE, cuid)}
        />
      )
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
      render: val => (val.match(/\d+/g) ? " " : val)
    },
    // {
    //   title: 'Amount to be invested',
    //   dataIndex: 'totalAmount',
    //   key: 'totalAmount',
    //   render: (val) => `${currency} ${formatNumber(val)}`
    // },
    {
      title: "Amount to be invested",
      dataIndex: "amount",
      key: "amountForInvestment",
      render: (value, { cuid, select }) => (
        <InputContainer
          type="number"
          name="amount"
          value={select ? value : ""}
          disabled={!select}
          onChange={inputInArray(claTsKeys.INVESTORS_TABLE, cuid)}
          formatter={val =>
            val && `${currency} ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
          }
          parser={val =>
            val.replace(new RegExp(`${currency}\\s?|(\\'*)`, "g"), "")
          }
        />
      )
    },

    {
      title: "Investor type",
      dataIndex: "role",
      key: "role",
      render: (
        _,
        { totalAmount = 0, sharesNumber = 0, representativeOf, role, type }
      ) => {
        return totalAmount === 0 && sharesNumber === 0 ? (
          <TagWrapper color="green">{representativeOf || role}</TagWrapper>
        ) : type !== null && type === "Shareholder Lender" ? (
          <div>
            <TagWrapper color="custom-green">Shareholder</TagWrapper>
            <br />
            <TagWrapper color="blue">Lender</TagWrapper>
          </div>
        ) : type !== null ? (
          <TagWrapper color={type === "Shareholder" ? "custom-green" : "blue"}>
            {type}
          </TagWrapper>
        ) : null;
      }
    },
    {
      title: () => (
        <p>
          <b>{name}</b> is a shareholder of this investor
        </p>
      ),
      dataIndex: "shareholderSelect",
      key: "shareholderSelect",
      render: (_, { entityIsShareholder }) =>
        entityIsShareholder && (
          <Checkbox name="shareholderSelect" checked={entityIsShareholder} />
        )
    }
  ];
};
const investor = (values, handlers, handleFormSave, currPath) => [
  {
    title:
      "Have you already an Investor, who has committed a certain amount for a convertible loan?",
    input: {
      ...confirmationPrompt,
      name: claTsKeys.INVESTOR_HAS_COMMITTED
    }
  },
  values[claTsKeys.INVESTOR_HAS_COMMITTED] === "yes"
    ? {
        title:
          "Please select only those investors which have exactly the same information in column 5 (Investor Type) and column 6 (if displayed). If the Company should be a shareholder of one of the investors listed below, the information will be displayed in column 6. The investors that do not meet the mentioned requirements will currently need separate term sheets, because of different interest impact per investor group.",
        input: {
          type: "table_list",
          name: claTsKeys.INVESTORS_TABLE,
          cols: investorsColumns(values, handlers)
        }
      }
    : null,
  {
    showId: false,
    title: (
      <span>
        If you want to add investors{" "}
        <NavLink
          to={`/stakeholders?redirect=${currPath}?step=2`}
          onClick={handleFormSave(values)}
        >
          click here
        </NavLink>
      </span>
    ),
    className: "_hidden",
    input: {}
  }
];

const leftInteresRepeated = (
  annualInterestRate,
  currency,
  values,
  middleOrRight
) => {
  const interestRate = getInterestRate(
    currency,
    values[claTsKeys.MONEY_RAISED],
    values.investorsTable
  );
  // values[claTsKeys.MAXIMUM_INTEREST] = interestRate;
  values[claTsKeys.YEARLY_INTEREST_RATE] = annualInterestRate;
  return [
    middleOrRight
      ? {
          title: "What is the annual interest rate?",
          details:
            values[claTsKeys.INTEREST_RATE] < interestRate ||
            values[claTsKeys.INTEREST_RATE] > interestRate
              ? `Attention, the Safe Harbor Rules states that the loan shouldn’t bear an interest rate higher (or lower when min) than ${interestRate}%. Please consult a tax expert if your interest rate differs from the safe harbour rule limit.`
              : "",
          redDetails: true,
          input: {
            name: claTsKeys.INTEREST_RATE,
            type: "number",
            formatter: value => (value && value <= 100 ? `${value}%` : ""),
            parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), "")
          }
        }
      : {
          title: "What is the annual interest rate?",
          input: {
            name: claTsKeys.INTEREST_RATE,
            type: "number",
            formatter: value => (value && value <= 100 ? `${value}%` : ""),
            parser: value => value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), "")
          }
        },
    {
      title: "How is the daily interest rate calculated?",
      input: {
        name: claTsKeys.ANNUAL_INTEREST_CALCULATION,
        type: "select_btn",
        options: [
          basicOption("365", "365 days"),
          basicOption("360", "360 days")
        ]
      }
    },
    {
      title: "Yearly interest rate",
      input: {
        name: claTsKeys.YEARLY_INTEREST_RATE,
        type: "number",
        value: annualInterestRate,
        ...inputNumberFormatter(currency),
        disabled: true
      }
    }
  ];
};

const leftInterest = (
  values,
  annualInterestRate,
  currency,
  middleOrRight = false
) => {
  values[claTsKeys.YEARLY_INTEREST_RATE] = annualInterestRate;
  return [
    {
      title:
        "Shall the Company pay a fixed annual interest rate on the loan received?",
      input: {
        name: claTsKeys.ANNUAL_INTEREST,
        ...confirmationPrompt
      }
    },
    ...(values[claTsKeys.ANNUAL_INTEREST] === "yes"
      ? leftInteresRepeated(annualInterestRate, currency, values, middleOrRight)
      : [])
  ];
};

const middleInterest = (values, currency, annualInterestRate) => {
  const interestRate = getInterestRate(
    currency,
    values[claTsKeys.MONEY_RAISED],
    values.investorsTable
  );
  values[claTsKeys.MAXIMUM_INTEREST] = interestRate;
  values[claTsKeys.YEARLY_INTEREST_RATE] = annualInterestRate;
  const middleOrRight = true;
  return [
    {
      showId: false,
      title: (
        <span>
          You selected a shareholder of yours, which will invest in this
          convertible loan. The Loan will therefore, bear from the date of
          reception of the Loan by the company the maximum interest rate
          according to the safe harbour rules, annually published by the Swiss
          federal tax authority (Eidgenössische Steuerverwaltung ESTV) in the
          circular letter "interest rates for loans in{" "}
          {currency === "CHF" ? "Swiss francs" : "foreign currencies"}{" "}
          <NavLink
            to={
              currency === "CHF"
                ? "/doc_viewer?doc=/docs/2-178-DV-2021-d.pdf"
                : "/doc_viewer?doc=/docs/2-179-DV-2021-d.pdf"
            }
          >
            (Rundschreiben: "Steuerlich anerkannte Zinssätze für Vorschüsse oder{" "}
            {currency === "CHF"
              ? "Darlehen in Schweizer Franken"
              : "Darlehen in Fremdwährungen"}
            ").
          </NavLink>
        </span>
      ),
      className: "_hidden",
      input: {}
    },
    {
      title: "Do you want to continue with the safe harbor rules?",
      input: {
        name: claTsKeys.SAFE_HARBOR,
        ...confirmationPrompt
      }
    },
    ...(values[claTsKeys.SAFE_HARBOR] === "yes"
      ? [
          {
            title: "How is the daily interest rate calculated?",
            input: {
              name: claTsKeys.ANNUAL_INTEREST_CALCULATION,
              type: "select_btn",
              options: [
                basicOption("365", "365 days"),
                basicOption("360", "360 days")
              ]
            }
          },
          {
            title:
              "This is the maximum interest you have to apply to lenders, who are shareholders",
            input: {
              name: claTsKeys.MAXIMUM_INTEREST,
              type: "number",
              formatter: value => (value && value <= 100 ? `${value}%` : ""),
              parser: value =>
                value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), ""),
              value: interestRate,
              disabled: true
            }
          },
          {
            title: "Yearly interest rate",
            input: {
              name: claTsKeys.YEARLY_INTEREST_RATE,
              type: "number",
              value: annualInterestRate,
              ...inputNumberFormatter(currency),
              disabled: true
            }
          }
        ]
      : values[claTsKeys.SAFE_HARBOR] === "no"
      ? leftInterest(values, annualInterestRate, currency, middleOrRight)
      : [])
  ];
};

const rightInterest = (values, currency, annualInterestRate) => {
  const interestRate = getInterestRate(
    currency,
    values[claTsKeys.MONEY_RAISED],
    values.investorsTable
  );
  values[claTsKeys.MAXIMUM_INTEREST] = interestRate;
  const middleOrRight = true;
  values[claTsKeys.YEARLY_INTEREST_RATE] = annualInterestRate;
  const { investorsTable = [] } = values;
  const minimumInterestLender = investorsTable.filter(
    ({ select, entityIsShareholder, type }) =>
      select &&
      entityIsShareholder &&
      (type !== "Shareholder" || type !== "Shareholder Lender")
  );
  return [
    {
      showId: false,
      title: (
        <span>
          You selected an investor, of which you are a shareholder. The Loan
          will therefore, bear from the date of reception of the Loan by the
          company the{" "}
          {minimumInterestLender.length === 0 ? "maximum" : "minimum"} interest
          rate according to the safe harbour rules, annually published by the
          Swiss federal tax authority (Eidgenössische Steuerverwaltung ESTV) in
          the circular letter "interest rates for loans in{" "}
          {currency === "CHF" ? "Swiss francs" : "foreign currencies"}{" "}
          <NavLink to="/doc_viewer?doc=/docs/2-179-DV-2021-d.pdf">
            (Rundschreiben: "Steuerlich anerkannte Zinssätze für Vorschüsse oder{" "}
            {currency === "CHF"
              ? "Darlehen in Schweizer Franken"
              : "Darlehen in Fremdwährungen"}
            ").
          </NavLink>
        </span>
      ),
      className: "_hidden",
      input: {}
    },
    {
      title: "Do you want to continue with the safe harbor rules?",
      input: {
        name: claTsKeys.SAFE_HARBOR,
        ...confirmationPrompt
      }
    },
    ...(values[claTsKeys.SAFE_HARBOR] === "yes"
      ? [
          {
            title: "How is the annual interest rate calculated?",
            input: {
              name: claTsKeys.ANNUAL_INTEREST_CALCULATION,
              type: "select_btn",
              options: [
                basicOption("365", "365 days"),
                basicOption("360", "360 days")
              ]
            }
          },
          {
            title: `This is the ${
              minimumInterestLender.length === 0 ? "maximum" : "minimum"
            } interest you have to apply to lenders, who are shareholders`,
            input: {
              name: claTsKeys.MAXIMUM_INTEREST,
              type: "number",
              formatter: value => (value && value <= 100 ? `${value}%` : ""),
              parser: value =>
                value.replace(new RegExp(`%\\s?|(\\'*)`, "g"), ""),
              value: interestRate,
              disabled: true
            }
          },
          {
            title: "Yearly interest rate",
            input: {
              name: claTsKeys.YEARLY_INTEREST_RATE,
              type: "number",
              value: annualInterestRate,
              ...inputNumberFormatter(currency),
              disabled: true
            }
          }
        ]
      : values[claTsKeys.SAFE_HARBOR] === "no"
      ? leftInterest(values, annualInterestRate, currency, middleOrRight)
      : [])
  ];
};

const interest = (
  values,
  currency,
  annualInterestRate,
  minimumInterestLender
) => {
  const hasShareholder =
    values.investorsTable &&
    values.investorsTable.length > 0 &&
    !!values.investorsTable.find(
      ({ select, type }) =>
        select && (type === "Shareholder" || type === "Shareholder Lender")
    );
  const hasLender =
    values.investorsTable &&
    values.investorsTable.length > 0 &&
    !!values.investorsTable.find(
      ({ select, type }) => select && (type === "Lender" || type === null)
    );
  const hasShareholderSelect =
    values.investorsTable &&
    values.investorsTable.length > 0 &&
    !!values.investorsTable.find(
      ({ entityIsShareholder, type, select }) =>
        select &&
        entityIsShareholder &&
        (type === "Shareholder" || type === "Shareholder Lender")
    );
  const hasLenderSelect =
    values.investorsTable &&
    values.investorsTable.length > 0 &&
    !!values.investorsTable.find(
      ({ entityIsShareholder, type, select }) =>
        select && entityIsShareholder && (type === "Lender" || type === null)
    );

  const hasComitted = values[claTsKeys.INVESTOR_HAS_COMMITTED];

  let arrayToReturn = [];
  if (hasComitted === "no") {
    arrayToReturn = leftInterest(values, annualInterestRate, currency);
  } else if (hasLender && hasLenderSelect) {
    arrayToReturn = rightInterest(values, currency, annualInterestRate);
  } else if (hasShareholder && hasShareholderSelect) {
    arrayToReturn = leftInterest(values, annualInterestRate, currency);
  } else if (hasLender) {
    arrayToReturn = leftInterest(values, annualInterestRate, currency);
  } else if (hasShareholder) {
    arrayToReturn = middleInterest(values, currency, annualInterestRate);
  } else {
    arrayToReturn = leftInterest(values, annualInterestRate, currency);
  }
  return arrayToReturn;
};

export default [information, investor, interest];
