export default {
  INVESTOR: 'investor',
  MANAGER: 'am',
  ADMIN: 'admin'
};

export const roles = {
  INVESTOR: 'investor',
  FOUNDER: 'founder',
  DIRECTOR: 'director',
  EMPLOYEE: 'employee',
  ASSET_MANAGER: 'asset_manager',
  AM_INVESTOR: 'asset_manager_investor',
  LAWYER: 'lawyer'
};

export const rolesList = [
  {
    label: 'Founder',
    value: roles.FOUNDER,
    rolesList: [roles.FOUNDER]
  },
  {
    label: 'Employee',
    value: roles.EMPLOYEE,
    rolesList: [roles.FOUNDER]
  },
  {
    label: 'Investor',
    value: roles.INVESTOR,
    rolesList: [roles.FOUNDER]
  },
  {
    label: 'Asset manager',
    value: roles.ASSET_MANAGER,
    rolesList: [roles.FOUNDER]
  },
  {
    label: 'Investor',
    value: roles.AM_INVESTOR,
    rolesList: [roles.ASSET_MANAGER]
  },
  {
    label: 'Lawyer',
    value: roles.LAWYER,
    rolesList: [roles.FOUNDER]
  }
];
