import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { handleUpload } from '#helpers/inputHandlers';
import { mapBoardRegulations } from '#helpers/mappers/dealCreation';
import Form from './form/Presentational';
import list from './form/list';
import { closedValues } from '#constants/io/closed';

const BoardRegulations = ({
  getEntity,
  entityId,
  entityData,
  putIoInfo,
  createIo,
  clearState,
  updateEntity,
  getEntityMemberships,
  getEntityGeneralInformations,
  fetchSingleIo,
  ...props
}) => {
  const { id } = useParams();

  useEffect(() => {
    clearState('io');
    clearState('entities');
    getEntity(entityId);
    getEntityMemberships({ id: entityId, role: 'director' });

    if (id) {
      fetchSingleIo(id);
    }
  }, []);

  const [state, setState] = useState({});

  const uploadHandler = handleUpload(setState, state);
  const fieldList = list({}).flatMap((section) => section.items);

  const handleSubmit = async (values) => {
    const boardRegulationsPayload = mapBoardRegulations(values);
    const { ioColl: { data: { closed } = {} } = {} } = props;
    const finalClosed =
      closed < closedValues.BOARD_REGULATIONS_DONE
        ? closedValues.BOARD_REGULATIONS_DONE
        : closed;

    putIoInfo({ ioId: id, ...boardRegulationsPayload, closed: finalClosed });
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      fieldList={fieldList}
      values={entityData}
      handleUpload={uploadHandler}
      {...props}
    />
  );
};

export default BoardRegulations;
