import ModularByDeal from "../../../assets/illustrations/deal manager page/modular-by-deal.svg";
import ManageAndGrow from "../../../assets/illustrations/deal manager page/ManageAndGrow.svg";
import CreateShareClose from "../../../assets/illustrations/deal manager page/create-share.svg";
import CloseTheDeal from "../../../assets/illustrations/founder/close-the-deal.png";
import ManageYourPortfolio from "../../../assets/illustrations/founder/manage-your-portfolio.png";
import benefits from "../../../assets/illustrations/venture syndicate/benefits.svg";
import CareFree from "../../../assets/illustrations/deal manager page/carefree.png";
import DanielKoppelkamm from "../../../assets/illustrations/deal manager page/carousel/daniel-koppelkamm.jpg";
import Convergence from "../../../assets/illustrations/deal manager page/carousel/convergence.jpg";
import Tugboat from "../../../assets/illustrations/customers/tuugboat-large.jpg";
import Stephan from "../../../assets/illustrations/customers/Stephan.jpg";
import Daniel from "../../../assets/illustrations/customers/Daniel.jpg";
import DeeptechLarge from "../../../assets/illustrations/customers/deeptech-large.jpg";
import Invite from "../../../assets/illustrations/startup page/invite.png";
import Upload from "../../../assets/illustrations/startup page/upload.png";
import Collect from "../../../assets/illustrations/startup page/collect.png";
import Manage from "../../../assets/illustrations/startup page/manage.png";

export const steps = [
  {
    title: "Set up your deal",
    description: "Share the deal key info and choose your fee structure",
    icon: ModularByDeal
  },
  {
    title: "Invite your network",
    description: "Invite your co-investors to the new opportunity",
    icon: ManageAndGrow
  },
  {
    title: "Track the fundraising",
    description: "Co-investors invest into your deal online in a few clicks",
    icon: CreateShareClose
  },
  {
    title: "Close the deal",
    description: "We'll collect the funds and send them to the target entity",
    icon: CloseTheDeal
  },
  {
    title: "Manage your portfolio",
    description: "Use the platform to manage co-investors relations",
    icon: ManageYourPortfolio
  }
];

export const expertiseTexts = {
  header: "Benefits of digitizing your round with LEVA",
  content:
    "Learn how you can take advantage of LEVA's digital fundraising process to close your round with no headaches",
  icon: benefits
};

export const expertiseFeatures = [
  {
    title: "Save time",
    content:
      "Automated workflows save you tons of time and money when raising funds"
  },
  {
    title: "Cost effective",
    content: "Reduce your syndicate structuring costs by up to 90%"
  },
  {
    title: "Scale your business",
    content: "Set up an syndicate and share your deal flow with a few clicks"
  },
  {
    title: "Earn carry",
    content:
      "Monetize your deal flow by earning carry and other deal-by-deal fees"
  },
  {
    title: "Close deals faster",
    content:
      "Focus on deal sourcing, LEVA takes care of the heavy admin paperwork, compliance and KYC checks"
  },
  {
    title: "Manage your portfolio",
    content:
      "Never manage your deals in spreadsheets anymore. LEVA's dashboard handles everything in real time"
  }
];

export const careFreeSolution = {
  header: "A care-free solution to scale your investment activity",
  textLeft: true,
  // text: i18n.t("landing.deal_manager.features.manage_and_grow.text"),
  items: [
    {
      content: "Automated legal structuring"
    },
    {
      content: "Online co-investor management"
    },
    {
      content: "Compliance (KYC & AML)"
    },
    {
      content: "Data room"
    },
    {
      content: "Follow on rounds"
    },
    {
      content: "Banking & Capital flow management"
    },
    {
      content: "Digital signatures"
    },
    {
      content: "Co-investor relations and reporting"
    },
    {
      content: "Exit distributions"
    },
    {
      content: "Tax reports"
    },
    {
      content: "Voting system"
    },
    {
      content: "Dissolution"
    }
  ],
  icon: CareFree
};

export const carouselItems = [
  {
    img: Stephan,
    content:
      "Most of my co-investors are busy people. With LEVA they have a user-friendly platform to easily review a deal and invest online.",
    name: "Stephan Habegger",
    position: "CEO",
    firm: "Tugboat",
    logo: Tugboat
  },
  {
    img: Daniel,
    content:
      "With LEVA we have a user-friendly tool to compliantly syndicate deals with our co-investor.",
    name: "Daniel Curiger",
    position: "Investment manager ",
    firm: "Deeptech Ventures",
    logo: DeeptechLarge
  },
  {
    img: DanielKoppelkamm,
    content:
      "Running a syndicate with LEVA is a great experience. LEVA makes it super easy for us to raise funds from our co-investors. Now we have more time for deal making.",
    name: "Daniel Koppelkamm",
    position: "Founder & Managing Partner",
    firm: "Convergence Partners",
    logo: Convergence
  }
];

export const howItWorks = [
  {
    title: "Setup your terms and upload deal docs",
    content:
      "Setup the deal terms, choose the funding goal, upload relevant documents to your private data room",
    icon: Upload
  },
  {
    title: "Collect commitments and investments",
    content:
      "Co-investors can commit and send funds online. We make investing in your deals easier than ever",
    icon: Collect
  },
  {
    title: "Manage your co-investors and portfolio",
    content:
      "Report to your co-investors in just two clicks and administer your syndicates online",
    icon: Manage
  }
];
