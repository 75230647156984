import { dealRounds } from "#constants/deal";
import ioTypes from "#constants/io/ioTypes";
import { renderType } from "#helpers/general";
import { formatNumber, beautifyNumbers } from "#helpers/number_format";
import Terms from "../../../../common/Terms";

const TermsWrapper = ({ io }) => {
  const currency = io.currency && io.currency.shortName;
  const fees = io.fees || {};
  const preMoneyVal = io.preMoneyValuation;

  const returnCarryFee = () => {
    if (fees.performance) {
      if (Array.isArray(fees.performance)) {
        return fees.performance.reduce(
          (acc, { breakpoint, value }, index) => ({
            ...acc,
            [index === 0
              ? `Performance fee below ${breakpoint}X`
              : `Performance fee above ${breakpoint}X`]: `${Number(
              value * 100
            ).toFixed(2)}%`
          }),
          {}
        );
      } else if (typeof fees.performance === "object") {
        if (fees.performance.value) {
          return {
            "Performance fee": `${Number(fees.performance.value * 100).toFixed(
              2
            )}%`,
            "Hurdle rate p.a": `${Number(fees.performance.hurdle * 100).toFixed(
              2
            )}%`
          };
        }
      }

      return {};
    }

    return {};
  };

  const returnPreMoneyVal = () => {
    if (preMoneyVal) {
      if (typeof preMoneyVal === "object") {
        const { min = 0, max = 0 } = preMoneyVal;

        return `${currency} ${beautifyNumbers(min)} - ${beautifyNumbers(max)}`;
      }

      return `${currency} ${beautifyNumbers(preMoneyVal)}`;
    }

    return 0;
  };

  let vat = "No";

  if (io.new_vat) vat = "Yes";
  else if (io.vat) vat = "Yes";

  const organisedData = {
    "Financing round": dealRounds[io.investmentLevel] || io.investmentLevel,
    "Funding goal": formatNumber(io.fundingGoal, currency),
    "Upfront closing fee": fees.setup
      ? `${fees.setup && Number(fees.setup.value * 100).toFixed(2)}%`
      : 0,
    "Deal type": renderType(io.dealType),
    "Min ticket": formatNumber(io.minimal, currency),
    "Yearly admin fee": fees.administration
      ? `${fees.administration &&
          Number(fees.administration.value * 100).toFixed(2)}%`
      : 0,
    "Pre-money valuation": returnPreMoneyVal(),
    ...returnCarryFee(),
    "Are the fees subject to VAT": vat,
    "Share price":
      io.dealType !== ioTypes.POOLED_EQUITY
        ? "N/A"
        : formatNumber(io.sharesPrice, currency)
  };

  return <Terms terms={organisedData} />;
};

export default TermsWrapper;
