import { Icon, Spin } from "antd";
import { formatNumber } from "#helpers/number_format";
import Avatar from "../../Avatar";
import { ActionDropdown } from "../../Dropdown";
import Progress from "../../Progress";
import { CardWrapper } from "../";
import { closedValues } from "#constants/io/closed";

const HistoryCard = ({
  name,
  entity,
  value,
  valCurrency,
  date,
  percentage,
  stage,
  settingsList,
  status,
  settingsDisabled,
  loading,
  handleTitleClick,
  endValue,
  avatar,
  successPercent,
  closed
}) => {
  const returnStrokeColor = () => {
    if (status === "success") {
      return "green";
    } else if (status === "inprogress") {
      return "blue";
    }

    return "orange";
  };

  const isExecuted = closed >= closedValues.EXECUTED;

  return (
    <CardWrapper className="history-card-wrapper">
      <div className="history-card-container">
        <div className="clickable-section" onClick={handleTitleClick || null}>
          <div className="avatar-wrapper">
            <Avatar
              size="large"
              className="history-avatar"
              src={avatar}
              shape="square"
            >
              {name && name[0]}
            </Avatar>
          </div>
          <div className="user-info">
            <span className="name">{name}</span>
            <span>{entity}</span>
          </div>
          <div className="amount">
            <span className="currency">{valCurrency}</span>
            <span className="value">{formatNumber(value)}</span>
          </div>
          <div className="progress">
            <Progress
              percent={Number(percentage).toFixed(2)}
              successPercent={successPercent}
            />
          </div>
          {endValue && (
            <div className="amount">
              <span className="currency">{valCurrency}</span>
              <span className="value">{formatNumber(endValue)}</span>
            </div>
          )}
          {date && (
            <div className="date">
              <Icon type="clock-circle" className="clock-icon" />
              <span>{date}</span>
            </div>
          )}
        </div>
        {!settingsDisabled && !loading && (
          <div className="settings">
            {isExecuted && (
              <ActionDropdown actionList={settingsList}>
                <Icon type="ellipsis" />
              </ActionDropdown>
            )}
          </div>
        )}
        {loading && <Spin />}
      </div>
    </CardWrapper>
  );
};

export default HistoryCard;
