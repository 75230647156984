import CustomerBlock from "./CustomerBlock/Presentational";
import { Col, Row, Typography } from "antd";
import Janic from "../../../../assets/illustrations/customers/Janic.jpg";
import Daniel from "../../../../assets/illustrations/customers/Daniel.jpg";
import Stephan from "../../../../assets/illustrations/customers/Stephan.jpg";
import BackboneLarge from "../../../../assets/illustrations/customers/backbone-large.jpg";
import DeeptechLarge from "../../../../assets/illustrations/customers/deeptech-large.jpg";
import TugboatLarge from "../../../../assets/illustrations/customers/tuugboat-large.jpg";

const { Title } = Typography;
export default function Presentational() {
  const customers = [
    {
      img: Janic,
      content:
        "Syndicating deals is time consuming and drains valuable resources. With Leva we streamlined our workflows and can close a deal with just a few clicks.",
      name: "Janic Frölicher",
      position: "Founding partner",
      firm: "Backbone Ventures",
      logo: BackboneLarge
    },
    {
      img: Daniel,
      content:
        "With Leva we have a user-friendly tool to compliantly syndicate deals with our co-investor.",
      name: "Daniel Curiger",
      position: "Investment manager ",
      firm: "Deeptech Ventures",
      logo: DeeptechLarge
    },
    {
      img: Stephan,
      content:
        "Most of my co-investors are busy people. With Leva they have a user-friendly platform to easily review a deal and commit funds online",
      name: "Stephan Habegger",
      position: "CEO",
      firm: "Tugboat",
      logo: TugboatLarge
    }
  ];

  return (
    <Col className="customer-col-wrapper" span={24}>
      <Row type="flex" justify="center">
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <div className="customer-customers">
            <Title level={2} className="l1-title">
              Our customers love our product
            </Title>
            <Row>
              {customers.map(customer => {
                return <CustomerBlock customer={customer} />;
              })}
            </Row>
          </div>
        </Col>
      </Row>
    </Col>
  );
}
