import useWindowSize from "#helpers/hooks/windowSize";
import { Menu } from "antd";
import { submenuItems, firstMenuItems, lastMenuItems } from "./items/menuItems";

export default function DesktopMenu() {
  const { width } = useWindowSize();
  const submenuDropdown = submenuItems();
  const firstMenu = firstMenuItems(width <= 940);
  const lastMenu = lastMenuItems(width <= 940);

  return (
    <>
      {firstMenu}
      {/* <Menu className="navbar-menu-w-b" mode="horizontal">
        {submenuDropdown}
      </Menu> */}
      {lastMenu}
    </>
  );
}
