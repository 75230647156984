import { Button } from '../../Shared/Button';

const RegBtnContainer = ({ label }) => ({ onClick, buttonStates }) => {
  return (
    <div className="btn-container">
      <Button {...buttonStates} onClick={onClick} label={label} />
    </div>
  );
};

export default RegBtnContainer;
