import { Dropdown } from 'antd';
import ActionDropdown from './ActionDropdown';
import SorterDropdown from './SorterDropdown';

const DropdownWrapper = ({ children, disabled, props }) => {
  return (
    <Dropdown {...props} disabled={disabled}>
      {children}
    </Dropdown>
  );
};

export { ActionDropdown, SorterDropdown };

export default DropdownWrapper;
