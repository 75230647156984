import { basicOption } from '#helpers/general';
import { keys } from '#constants/io/equityPool';
import { formatNumber } from '#helpers/number_format';

const entity = [
  {
    id: 1,
    title: 'Company name',
    input: { type: 'text', name: keys.COMPANY_NAME }
  },
  {
    id: 2,
    title: 'Registered seat',
    input: { type: 'text', name: keys.REGISTERED_SEAT }
  },
  {
    id: 3,
    title: 'Current Number of Shares',
    input: { type: 'text', name: keys.CURR_NUM_SHARES }
  },
  {
    id: 4,
    title: 'Shareholders structure',
    input: {
      type: 'radio',
      name: keys.SHAREHOLDER_STRUCTURE,
      options: [
        basicOption('pre_closing', 'Pre-closing'),
        basicOption('post_closing', 'Post closing')
      ]
    }
  }
];

const step1 = [
  {
    id: 1,
    title: 'Which Series Round are you raising for?',
    input: {
      type: 'radio',
      name: keys.SERIES_ROUND,
      options: [
        basicOption('Seed', 'Seed'),
        basicOption('A', 'A'),
        basicOption('B', 'B'),
        basicOption('C', 'C'),
        basicOption('D', 'D'),
        basicOption('E', 'E')
      ]
    }
  },
  {
    id: 2,
    title: 'Intended use of proceeds.',
    input: {
      type: 'radio',
      customClassname: '_column',
      name: keys.USE_PROCEEDS,
      options: [
        basicOption(
          'All activities consistent with the business plan',
          'All activities consistent with the business plan'
        ),
        basicOption(
          'All activities in the ordinary course of business within the purpose of Company',
          'All activities in the ordinary course of business within the purpose of Company'
        )
      ]
    }
  }
];

const step2 = (args) => [
  {
    id: 1,
    title: 'What is your Pre Money-Valuation?',
    input: {
      type: 'number',
      name: keys.PRE_MONEY_VAL,
      formatter: (value) => formatNumber(value)
    }
  },
  {
    id: 2,
    title: 'Is your Pre Money-Valuation fully diluted?',
    input: {
      type: 'radio',
      name: keys.PRE_MONEY_VAL_DILUTED,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  {
    id: 3,
    title: 'What is the Nominal Value of the new Shares?',
    input: {
      type: 'number',
      name: keys.NOMINAL_VAL,
      formatter: (value) => formatNumber(value)
    }
  },
  {
    id: 4,
    title: 'What is the Issue Price of the new Shares?',
    input: {
      type: 'number',
      name: keys.ISSUE_PRICE,
      formatter: (value) => formatNumber(value)
    }
  },
  {
    id: 5,
    title: 'What are the voting rights of the new Shares? ',
    input: {
      type: 'radio',
      name: keys.VOTING_RIGHTS,
      options: [
        basicOption('One share', 'One share = one vote'),
        basicOption('Other', 'other')
      ]
    }
  },
  {
    id: 6,
    title: 'What should the interest be on the preference amount?',
    input: {
      type: 'number',
      name: keys.AMOUNT_INTEREST,
      formatter: (value) => formatNumber(value)
    }
  },
  {
    id: 7,
    title: 'Have you agreed on a investment amount with the Investors?',
    input: {
      type: 'radio',
      name: keys.AGREED_AMOUNT_BOOL,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  args[keys.AGREED_AMOUNT_BOOL]
    ? {
        id: 8,
        title: 'What is the amount',
        input: {
          type: 'number',
          name: keys.AGREED_AMOUNT,
          formatter: (value) => formatNumber(value)
        }
      }
    : null
];

const step3 = (args) => [
  {
    id: 1,
    title:
      'Do you have an outstanding Employee participation / Option Pool Plan?',
    input: {
      type: 'radio',
      name: keys.EMPLOYEE_PART_PLAN_BOOL,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  args[keys.EMPLOYEE_PART_PLAN_BOOL]
    ? {
        id: 2,
        title: 'What is the amount',
        input: {
          type: 'text',
          name: keys.EMPLOYEE_PART_PLAN
        }
      }
    : null
];

const step4 = (args) => [
  {
    id: 1,
    title: 'Do the new issued shares have preference Rights?',
    input: {
      type: 'radio',
      name: keys.PREFERENCE_RIGHTS,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  ...(args[keys.PREFERENCE_RIGHTS]
    ? [
        {
          id: 2,
          title: 'Dividen Preference?',
          input: {
            type: 'radio',
            name: keys.DIVIDEND_PREFERENCE,
            options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
          }
        },
        {
          id: 3,
          title: 'Liqudiation Preference?',
          input: {
            type: 'radio',
            name: keys.LIQUDIATION_PREFERENCE,
            options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
          }
        }
      ]
    : []),
  args[keys.LIQUDIATION_PREFERENCE]
    ? {
        id: 4,
        title: 'Does the liquidation preference have a catch-up amount?',
        input: {
          type: 'radio',
          name: keys.LIQUDIATION_PREFERENCE_AMOUNT_BOOL,
          options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
        }
      }
    : null,
  args[keys.LIQUDIATION_PREFERENCE_AMOUNT_BOOL]
    ? {
        id: 5,
        title: 'What is the amount',
        input: {
          type: 'number',
          name: keys.LIQUDIATION_PREFERENCE_AMOUNT,
          formatter: (value) => formatNumber(value)
        }
      }
    : null
];

const step5 = (args) => [
  {
    id: 1,
    title: 'Is there an Anti-Dilution Protection for the Investors?',
    input: {
      type: 'radio',
      name: keys.ANTI_DILUTION_PROTECTION,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  args[keys.ANTI_DILUTION_PROTECTION]
    ? {
        id: 2,
        title: 'What formula do you use?',
        input: {
          type: 'radio',
          name: keys.ANTI_DILUTION_PROTECTION_FORMULA,
          options: [
            basicOption('Weighted Average', 'Weighted Average'),
            basicOption('Full ratchet', 'Full Ratchet')
          ]
        }
      }
    : null
];

const step7 = (args) => [
  {
    id: 1,
    title:
      'What should be the maximum amount of members of the Board of Directors?',
    input: {
      type: 'number',
      name: keys.MAX_BOARD_DIRECTORS
    }
  },
  {
    id: 2,
    title: 'Who has the right to appoint a board of Director?',
    input: {
      type: 'radio',
      name: keys.DIRECTOR_APPOINT_RIGHTS,
      options: [
        basicOption('Investor', 'Investor'),
        basicOption('Founder', 'Founder'),
        basicOption('Shareholder', 'Shareholder')
      ]
    }
  },
  args[keys.DIRECTOR_APPOINT_RIGHTS]
    ? {
        id: 3,
        title: 'How many?',
        input: {
          type: 'number',
          name: keys.DIRECTOR_APPOINT_NUM
        }
      }
    : null,
  {
    id: 4,
    title: 'Should there be an Independent Director?',
    input: {
      type: 'radio',
      name: keys.INDEPENDENT_DIRECTOR,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  }
];

const step8 = [
  {
    id: 1,
    title:
      'What is the minimum percentage an investor has to have, to receive financial information from your company?',
    input: {
      type: 'number',
      name: keys.MINIMUM_PERCENTAGE
    }
  }
];

const step9 = (args) => [
  {
    id: 1,
    title: 'Do you have Share Transfer Restriction?',
    input: {
      type: 'radio',
      name: keys.SHARE_TRANSFER_RESTRICTION,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  {
    id: 2,
    title:
      'Do you have a prioritizing Right of First Refusal for the Investors?',
    input: {
      type: 'radio',
      name: keys.RIGHT_OF_REFUSAL,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  {
    id: 3,
    title: 'Do you have a Tag-Along Right?',
    input: {
      type: 'radio',
      name: keys.TAG_ALONG_RIGHT,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  {
    id: 4,
    title: 'Do you have a Drag-Along Right?',
    input: {
      type: 'radio',
      name: keys.DRAG_ALONG_RIGHT,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  ...(args[keys.DRAG_ALONG_RIGHT]
    ? [
        {
          id: 5,
          title: 'What is your Drag-Along Percentage?',
          input: {
            type: 'number',
            name: keys.DRAG_ALONG_PERCENTAGE
          }
        },
        {
          id: 6,
          title: 'What is your Drag-Along Valuation?',
          input: {
            type: 'number',
            name: keys.DRAG_ALONG_VALUATION,
            formatter: (value) => formatNumber(value)
          }
        }
      ]
    : []),
  {
    id: 7,
    title: 'Does every Shareholder hold a Purchase Option?',
    input: {
      type: 'radio',
      name: keys.PURCHASING_OPTION_SHAREHOLDER,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  }
];

const step10 = (args) => [
  {
    id: 1,
    title: 'Should this Term Sheet have an Exclusivity?',
    input: {
      type: 'radio',
      name: keys.TERM_SHEET_EXCLUSIVITY,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  },
  args[keys.TERM_SHEET_EXCLUSIVITY]
    ? {
        id: 2,
        title: 'How long should this protection endure?',
        input: {
          type: 'text',
          name: keys.TERM_SHEET_EXCLUSIVITY_TERM
        }
      }
    : null,
  {
    id: 3,
    title: 'What Documents shall be pursuant to an Investment?',
    input: {
      type: 'checkbox',
      name: keys.PURSUANT_DOCUMENTS,
      options: [
        basicOption('Investment Agreement', 'Investment Agreement'),
        basicOption('Shareholders Agreement', 'Shareholders Agreement'),
        basicOption('Articles', 'Articles'),
        basicOption('Board regulations', 'Board regulations'),
        basicOption('Others', 'Others')
      ]
    }
  },
  {
    id: 6,
    title: 'What should the Governing Law be?',
    input: {
      type: 'text',
      name: keys.GOVERNING_LAW
    }
  },
  {
    id: 7,
    title:
      'Would you like to add any additional documents (excl. current cap table, list of shareholders and Board Matters)?',
    input: {
      type: 'radio',
      name: keys.APPENDICES,
      options: [basicOption(true, 'Yes'), basicOption(false, 'No')]
    }
  }
];

export const termsheetList = (values) => {
  return [
    // { id: 1, sectionId: 1, items: entity },
    { id: 2, sectionId: 1, items: step1 },
    { id: 3, sectionId: 1, items: step2(values) },
    { id: 4, sectionId: 1, items: step3(values) },
    { id: 5, sectionId: 1, items: step4(values) },
    { id: 6, sectionId: 1, items: step5(values) },
    { id: 7, sectionId: 1, items: step7(values) },
    { id: 8, sectionId: 1, items: step8 },
    { id: 9, sectionId: 1, items: step9(values) },
    { id: 10, sectionId: 1, items: step10(values) }
  ];
};
