import { connect } from 'react-redux';

import { register } from '#core/modules/auth/actions';
import { clearState } from '#core/utils/helper_actions';
import Presentational from './Presentational';

const mapStateToProps = (state) => {
  const { isCreating, didCreate } = state.auth;

  return {
    buttonStates: {
      loading: isCreating,
      success: didCreate,
      disabled: isCreating || didCreate
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  register: (data) => dispatch(register(data)),
  clearState: (key) => clearState(dispatch, key)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
