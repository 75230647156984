import * as Yup from "yup";
import { returnError } from "./common";
import { formatNumber } from "#helpers/number_format";

const confirmTransferSchema = ({ roundedMoneyInvested, currency }) => {
  const schema = {
    accountHolderName: Yup.string().required(returnError("required")),
    accountHolderAddress: Yup.string().required(returnError("required")),
    targetCompanyName: Yup.string().required(returnError("required")),
    targetCompanyAddress: Yup.string().required(returnError("required")),
    bankLocation: Yup.string().required(returnError("required")),
    bankName: Yup.string().required(returnError("required")),
    bankAccountNumber: Yup.string().required(returnError("required")),
    bankAccountIban: Yup.string(),
    bankAccountBicSwift: Yup.string(),
    bankAccountRoutingNumber: Yup.string(),
    bankAccountBranch: Yup.string(),
    bankAccountCNAPS: Yup.string(),
    bankAccountStateBranchNumber: Yup.string(),
    bankAccountBranchSortCode: Yup.string(),
    bankAccountIFSC: Yup.string(),
    bankAccountMICR: Yup.string(),
    bankAccountBranchNumber: Yup.string(),
    bankAccountType: Yup.string(),
    bankAccountBranchCode: Yup.string(),
    referencePaymentReason: Yup.string().required(returnError("required")),
    amount: Yup.number()
      .required(returnError("required"))
      .test(
        "check-funds",
        `The amount to be wired must be less or equal to the total amount invested (${formatNumber(
          roundedMoneyInvested,
          currency
        )})`,
        function(value) {
          if (value > roundedMoneyInvested) {
            return false;
          }

          return true;
        }
      ),
    targetRepresentativeFullName: Yup.string().required(
      returnError("required")
    ),
    targetRepresentativeEmail: Yup.string()
      .email(returnError("invalid_email"))
      .required(returnError("required")),
    targetRepresentativePhoneNumber: Yup.string().required(
      returnError("required")
    ),
    confirmTransferDocs: Yup.boolean().required(returnError("required")),
    confirmTransferConfirmation: Yup.boolean()
      .oneOf([true], "Please accept the terms.")
      .required("Please accept the terms."),
    commentsOrInfo: Yup.string().optional()
  };
  return Yup.object().shape(schema);
};

export default confirmTransferSchema;
