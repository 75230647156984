import { connect } from "react-redux";
import { cLvl as levels } from "#constants/confirmationLevel";
import { entityTypes } from "#constants/entity";
import Presentational from "./Presentational";
import { selectIoData } from "#selectors/io";
import { selectUserData } from "#selectors/users";
import { selectCommitData } from "#selectors/commits";
import { actionSelector } from "./Container.selectors";
import { selectContractsData } from "#selectors/contracts";
import { selectNdaData, selectNdaCuid } from "#selectors/nda";
import { selectMembershipData } from "#selectors/memberships";
import { selectEntityData } from "#selectors/entity";
import { selectCurrenciesData } from "#selectors/currencies";

const mapStateToProps = state => {
  const [entityData, entityStates] = selectEntityData(state.entities, {
    requestStatesKeys: ["didUpdate", "isUpdating"]
  });

  const [contractsColl, contractsRequestStates] = selectContractsData(
    state.contracts,
    {
      requestStatesKeys: ["isFetching"],
      requestStatesTransform: { isFetching: "contractsFetching" }
    }
  );

  const [userData, userRequestStates] = selectUserData(state.users, {
    dataKeysTransform: { confirmationLevel: "cLvl", cuid: "userId" }
  });
  const [{ io, ioIsFetching }, ioRequestStates] = selectIoData(state.io, {
    requestStatesKeysTransform: {
      didFetch: "ioDidFetch",
      isUpdating: "ioIsUpdating",
      didUpdate: "ioDidUpdate"
    },
    requestStatesTransformer: keys => {
      return {
        ...keys,
        attachLoading: keys.isCreating || keys.ioIsUpdating || keys.isDeleting
      };
    }
  });

  const [commits, commitState] = selectCommitData(state.commits, {
    requestStatesKeysTransform: {
      didCreate: "createdCommit",
      didUpdate: "updatedCommit",
      didFetch: "commitsDidFetch"
    }
  });

  const [ndaColl] = selectNdaData(state.nda, {
    dataKeysTransform: { ndaFetched: "didFetch" }
  });

  const [memberships, membershipsStates] = selectMembershipData(
    state.ioMemberships,
    {
      requestStatesKeys: ["isUpdating", "didUpdate", "didCreate"],
      requestStatesTransform: { isUpdating: "loading", didUpdate: "success" }
    }
  );

  const [currencies] = selectCurrenciesData(state.currency);
  const { assetManagers = [], userRole, industries = [] } = entityData;

  const { entitiesData = [] } =
    (state.entities._data && state.entities._data) || {};
  const am = assetManagers.length > 0 ? assetManagers[0] : {};

  const ndaCuid = selectNdaCuid(ndaColl.list, am.cuid);

  const { cLvl, userId } = userData;

  const financialContracts = [
    contractsColl.invConf,
    contractsColl.transConf,
    contractsColl.dealConf,
    contractsColl.invSummary
  ].filter((item = {}) => !!item.value);

  const item =
    entitiesData.find(
      ({ type, creator: { cuid: creatorCuid } }) =>
        type === entityTypes.ASSET_MANAGER_ENTITY &&
        creatorCuid === entityData.createdBy
    ) || {};

  return {
    assetManager: {
      cuid: entityData.createdBy,
      previewImage: item?.previewImage,
      name: item?.name
    },
    entityStates,
    baseEntity: entityData,
    status: {
      loading: ioIsFetching, // multiple fetches in the io module are done in the component. As such, the loading indicator was affected by those. This way, we keep the single io data fetching in a separate boolean
      ...ioRequestStates
    },
    userRole,
    io,
    currencies,
    commits,
    commitState,
    cLvl,
    ndaColl: {
      ...ndaColl,
      ndaCuid
    },
    contractsColl: {
      data: contractsColl.contracts,
      financialContracts,
      ...contractsRequestStates
    },
    amCuid: am.cuid,
    membershipsColl: {
      data: memberships,
      states: membershipsStates
    },
    industries,
    userId,
    userData,
    syndicates: state?.syndicate?._data?.list
  };
};

const mapDispatchToProps = dispatch => ({
  actions: actionSelector(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
