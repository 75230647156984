import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const TooltipWrapper = ({ title, children, ...props }) => {
  return (
    <Tooltip title={title} className="tooltip-component-wrapper" {...props}>
      {children}
    </Tooltip>
  );
};

TooltipWrapper.propTypes = {
  title: PropTypes.string
};

export default TooltipWrapper;
