import { formatNumber } from '#helpers/number_format';
import { inputNumberFormatter, basicOption } from '#helpers/general';
import { confirmationPrompt } from '../../../common/prompts';

const compensationIncome = (values) => [
  {
    title:
      'Shall the member(s) of the board of directors receive any compensation for their function as a member of the board?',
    input: {
      name: 'functionCompensation',
      ...confirmationPrompt
    }
  },
  values.functionCompensation === 'yes' && {
    title: 'Who shall receive the compensation for their function as a member of the board?',
    input: {
      type: 'select_btn',
      name: 'compensationReceiver',
      options: [
        basicOption('All directors'),
        basicOption('Only independent director(s)')
      ]
    }
  },
  values.functionCompensation === 'yes'
    ? {
      title:
        'What is the net amount (net of social security contributions, if any, payable by the relevant director) of the remuneration for the director as a member of the board?',
      details: 'Value is per year.',
      input: {
        type: 'number',
        name: 'remunerationAmount',
        formatter: (value) => formatNumber(value),
        ...inputNumberFormatter('CHF')
      }
    }
    : null
];

const compensationCosts = () => [
  {
    title:
      'Will the company reimburse each director for all business expenses (including travel costs and hotel accommodation) reasonably incurred by such director in connection with his/her function as a member of the board?',
    input: {
      name: 'reimburseDirectors',
      ...confirmationPrompt
    }
  }
];

export default [compensationIncome, compensationCosts];
