import { mapMembershipValues } from "#helpers/mappers/entitySettings";
import types from "../action_types";
import * as entitiesServices from "../../../../api/entitiesService";
import { postMembership } from "../../../../api/ioMembershipsService";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import { mapDealMembershipData } from "#helpers/mappers/memberships";

export const createEntity = entity => {
  return async dispatch => {
    try {
      start(dispatch, types.CREATE_ENTITY);
      const response = await entitiesServices.postEntity(entity);
      success(dispatch, types.CREATE_ENTITY, {
        data: { ...response.data, fromAM: entity.fromAM }
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.CREATE_ENTITY, {
        error: errorData
      });
    }
  };
};

export const createEntityMembership = data => {
  return async dispatch => {
    try {
      start(dispatch, types.CREATE_ENTITY_MEMBERSHIP);
      const response = await entitiesServices.postEntityMemberships(data);
      success(dispatch, types.CREATE_ENTITY_MEMBERSHIP, {
        data: { ...response.data, user: { email: data.userEmail } } // composing a payload of our own since the backend doesnt return it in the exact way we want
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.CREATE_ENTITY_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};

export const bulkCreateEntityMembership = data => async dispatch => {
  try {
    start(dispatch, types.BULK_CREATE_ENTITY_MEMBERSHIP);
    const response = await entitiesServices.postEntityMemberships(data);
    await Promise.all(
      data.map(async item =>
        entitiesServices.postEntityMemberships(mapMembershipValues(item))
      )
    );
    success(dispatch, types.BULK_CREATE_ENTITY_MEMBERSHIP, {
      data: { ...response.data } // composing a payload of our own since the backend doesnt return it in the exact way we want
    });
  } catch (error) {
    console.log(error);
    console.log(error);
    const errorData = getErrorData(error);
    const { message } = errorData;
    catchError(dispatch, message);
    fail(dispatch, types.BULK_CREATE_ENTITY_MEMBERSHIP, {
      error: errorData
    });
  }
};

export const createFounderInvitation = data => {
  return async dispatch => {
    try {
      start(dispatch, types.CREATE_FOUNDER_INVITATION);
      const response = await entitiesServices.postFounderInvitation(data);

      success(dispatch, types.CREATE_FOUNDER_INVITATION, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.CREATE_FOUNDER_INVITATION, {
        error: errorData
      });
    }
  };
};

export const createEntityAndFounder = data => {
  return async dispatch => {
    try {
      start(dispatch, types.CREATE_ENTITY_FOUNDER);
      const response = await entitiesServices.postEntityAndFounder(data);

      success(dispatch, types.CREATE_ENTITY_FOUNDER, {
        data: response.data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.CREATE_ENTITY_FOUNDER, {
        error: errorData
      });
    }
  };
};

export const createEntityAndDealMembership = ({
  ioId,
  redirectUrl,
  ...data
}) => {
  return async dispatch => {
    try {
      start(dispatch, types.CREATE_ENTITY_DEAL_MEMBERSHIP);

      const response = await entitiesServices.postEntityMemberships(
        mapMembershipValues(data)
      );

      await postMembership({
        ioId,
        userId: response.data.userId,
        permissionLevel: 3,
        redirectUrl
      });

      success(dispatch, types.CREATE_ENTITY_DEAL_MEMBERSHIP);
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.CREATE_ENTITY_DEAL_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};

export const bulkCreateEntityAndDealMembership = data => {
  return async dispatch => {
    try {
      start(dispatch, types.BULK_CREATE_ENTITY_DEAL_MEMBERSHIP);

      await Promise.all(
        data.map(async ({ ioId, userId, joinedEntity, ...item }) => {
          if (joinedEntity) {
            await postMembership(mapDealMembershipData({ ioId, userId }));
          } else {
            const response = await entitiesServices.postEntityMemberships(
              mapMembershipValues(item)
            );

            await postMembership(
              mapDealMembershipData({ ioId, userId: response.data.userId })
            );
          }
        })
      );

      success(dispatch, types.BULK_CREATE_ENTITY_DEAL_MEMBERSHIP);
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.BULK_CREATE_ENTITY_DEAL_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};
