import types from "../action_types";
import * as entitiesServices from "../../../../api/entitiesService";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import { setEntity } from "./put";

export const deleteEntityMembership = id => {
  return async dispatch => {
    try {
      start(dispatch, types.DELETE_ENTITY_MEMBERSHIP, { data: { id } });
      await entitiesServices.deleteEntityMembership(id);
      success(dispatch, types.DELETE_ENTITY_MEMBERSHIP, {
        data: { id }
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.DELETE_ENTITY_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};

export const bulkDeleteEntityMemberships = data => {
  return async dispatch => {
    try {
      const acc = [];

      await Promise.all(
        data.map(async (cuid, idx) => {
          start(dispatch, types.BULK_DELETE_ENTITY_MEMBERSHIP, {
            data: { cuid, idx }
          });

          await entitiesServices.deleteEntityMembership(cuid);
          acc.push(cuid);
        })
      );

      success(dispatch, types.BULK_DELETE_ENTITY_MEMBERSHIP, {
        data: acc
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.BULK_DELETE_ENTITY_MEMBERSHIP, {
        error: errorData
      });
    }
  };
};

export const deleteEntity = ({ cuid, entitiesList }) => {
  return async dispatch => {
    try {
      start(dispatch, types.DELETE_ENTITY, { data: { entitiesUpdatedList } });
      await entitiesServices.deleteEntity(cuid);
      const entitiesUpdatedList = entitiesList.filter(
        ({ cuid: localCuid }) => localCuid !== cuid
      );
      success(dispatch, types.DELETE_ENTITY, {
        data: { entitiesUpdatedList }
      });
      if (entitiesUpdatedList.length > 0) {
        dispatch(setEntity(entitiesUpdatedList[0].cuid));
      }
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.DELETE_ENTITY, {
        error: errorData
      });
    }
  };
};
