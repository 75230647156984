import PropTypes from "prop-types";
import SectionTitle from "../../Views/IndividualDeal/components/SectionTitle";

const TermSection = ({ terms = {} }) => {
  return (
    <div className="deal-view-terms-section">
      {/* <SectionTitle title="Investment terms" /> */}
      <div className="content">
        {Object.keys(terms)
          .filter(key => terms[key])
          .map(key => (
            <div className="item">
              <span className="_bold">{key}</span>
              <span className="data">{terms[key]}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

TermSection.propTypes = {
  terms: PropTypes.object
};

export default TermSection;
