import { Component } from "react";
import PropTypes from "prop-types";
import queryStrings from "query-string";
import { legalDocsType } from "#constants/deal";
import { convertToBase64 } from "#helpers/convertBase64";
import { mapValuesToArgs, mapLegalDocs } from "#helpers/mappers/dealCreation";
import { mapSyndicateFormToPayload } from "#helpers/mappers/syndicate";
import ioTypes from "#constants/io/ioTypes";

import Form from "./components/Form/Wrapper";
import Cropper from "../../Shared/Cropper/";
import { withModalWrapper } from "../../Shared/hocs";
import MethodCard from "./components/MethodCard";
import InvestNewDeal from "../../../assets/illustrations/invest-new-deal.png";
import ConvertPools from "../../../assets/illustrations/convert-pools.png";
import CaptableCleanUp from "../../../assets/illustrations/captable-clean-up.png";

class InvestmentDealCreation extends Component {
  state = {
    createdIoCuid: null,
    selectedType: ""
  };

  componentDidMount() {
    const {
      readCurrencies,
      getEntityMemberships,
      entityId,
      clearState,
      clearStateDataByKey,
      location,
      match,
      fetchSingleIo
    } = this.props;

    const query = queryStrings.parse(location.search);
    const parentEntity = query.entityId;
    const { ioId } = match.params || {};

    if (parentEntity) {
      this.setState({ parentEntity });
    }

    clearStateDataByKey("io", "io");
    readCurrencies();
    getEntityMemberships({ id: parentEntity || entityId });
    clearState("io");
    clearState("entities");
    clearState("ioMemberships");
    clearStateDataByKey("entities", "companyDetails", "companiesList");

    if (ioId) {
      fetchSingleIo(ioId);
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (
      props.ioDidCreate &&
      props.ioColl.data.cuid !== prevProps.ioColl.data.cuid
    ) {
      //      props.clearStateDataByKey("io", "io");

      this.setState({
        createdIoCuid: props.ioColl.data.cuid
      });
    }
  }

  handlePreview = (open, values) => () => {
    this.setState({
      dealModalOpen: open,
      ioValues: values
    });
  };

  handleUpload = setFieldValue => async (name, info = {}, opts = {}) => {
    const { file, fileList } = info;
    let newState = {};

    if (opts.multiple) {
      newState = {
        [name]: fileList.map(item => item.originFileObj)
      };
    } else if (info?.file?.status === "done") {
      newState = {
        [name]: file.originFileObj,
        modalVisible: true
      };
    }

    this.setState(newState);
    setFieldValue(name, true);
  };

  handleSubmit = async values => {
    const {
      createIo,
      userRole,
      userId,
      entityId: selectedEntityId,
      match: { params: { ioId } = {} } = {},
      putIoInfo,
      createSyndicateAndIo
    } = this.props;
    const { termsheet, parentEntity, previewImageBase64 } = this.state;

    if (!ioId) {
      let payload = mapValuesToArgs(values, !!termsheet, userRole);

      let termsheetBase64 = null;

      if (termsheet) {
        termsheetBase64 = await convertToBase64(termsheet);
      }

      const legalAttachment = await mapLegalDocs(values.dealType, {
        [legalDocsType.CLA]: this.state[legalDocsType.CLA],
        [legalDocsType.LOAN]: this.state[legalDocsType.LOAN],
        [legalDocsType.SHAREHOLDER]: this.state[legalDocsType.SHAREHOLDER]
      });

      let attachmentsArr = [];

      if (termsheetBase64) {
        attachmentsArr = [
          ...attachmentsArr,
          {
            name: "Term sheet",
            category: "legal",
            subCategory: "legal",
            base64: termsheetBase64
          }
        ];
      }

      if (legalAttachment) {
        attachmentsArr = [...attachmentsArr, ...legalAttachment];
      }

      payload = {
        ...payload,
        type: ioTypes.POOLED_EQUITY,
        previewImageBase64,
        attachments: attachmentsArr,
        leadInvestor: userId
      };

      const args = [
        mapSyndicateFormToPayload(values, selectedEntityId),
        args => {
          return {
            syndicateId: args.cuid,
            ...payload
          };
        }
      ];

      const { syndicateId } = queryStrings.parse(location.search);

      if (syndicateId) {
        createIo({
          ...payload,
          syndicateId
        });
      } else {
        createSyndicateAndIo(args);
      }
    } else {
      const payload = mapValuesToArgs(values, false, userRole, true);

      putIoInfo({ ioId, ...payload, previewImageBase64 });
    }
  };

  onOk = blob => {
    this.setState({
      previewImageBase64: blob,
      modalVisible: false
    });
  };

  handleCropperModalClose = () => {
    this.setState({
      modalVisible: false
    });
  };

  onSelectHandler = type => () => {
    this.setState({ selectedType: type });
  };

  render() {
    const { createdIoCuid } = this.state;
    const { syndicateId } = queryStrings.parse(location.search);

    const {
      currencies,
      currenciesDidFetch,
      buttonStates,
      membershipStates,
      ioColl,
      memberships,
      userRole,
      match,
      location: { pathname = "" } = {}
    } = this.props;
    const { ioId } = match.params || {};

    const methods = [
      {
        title: "Invest in a new deal",
        type: "new_financing_round",
        description:
          "Pool your co-investors in an upcoming financing round and invest in a new deal.",
        icon: InvestNewDeal
      },
      {
        title: "Convert your pools",
        type: "existing_pool",
        description:
          "Bring your existing syndicates on Leva to manage your entire portfolio in one platform.",
        icon: ConvertPools,
        secondScreen: true
      },
      {
        title: "Clean up the cap table",
        type: "existing_shareholder",
        description:
          "Pool existing shareholders to clean up the share ledger and consolidate the cap table.",
        icon: CaptableCleanUp,
        secondScreen: true
      }
    ];

    const formWrapperProps = {
      handleUpload: this.handleUpload,
      handlePreview: this.handlePreview,
      handleSubmit: this.handleSubmit,
      currencies,
      buttonStates,
      membershipStates,
      leadInvestor: ioColl.data.leadInvestor,
      userRole,
      memberships,
      currenciesDidFetch,
      createdIoCuid,
      ioColl,
      ioId,
      history: this.props.history,
      compliant: this.state.selectedType === "existing_pool",
      syndicateId
    };

    const CropperModal = withModalWrapper({
      title: "Customize your logo",
      visible: this.state.modalVisible,
      closable: true,
      handleClose: this.handleCropperModalClose
    })(Cropper);

    return (
      <div className="investment-deal-creation">
        {this.state.selectedType === "" &&
        location.pathname === "/create/deal" ? (
          <div className="methods-container">
            <h1>Welcome to your venture syndicate kit.</h1>
            <h2>Please select the relevant use case.</h2>
            <div className="methods-wrapper">
              {methods.map(
                ({ title, description, type, icon, secondScreen }) => (
                  <MethodCard
                    title={title}
                    description={description}
                    type={type}
                    onClick={this.onSelectHandler}
                    icon={icon}
                    secondScreen={secondScreen}
                  />
                )
              )}
            </div>
          </div>
        ) : (
          <>
            <CropperModal
              image={
                this.state.previewImage
                  ? URL.createObjectURL(this.state.previewImage)
                  : null
              }
              onOk={this.onOk}
            />
            <Form {...formWrapperProps} />
          </>
        )}
      </div>
    );
  }
}

InvestmentDealCreation.propTypes = {
  readCurrencies: PropTypes.func,
  getEntityMemberships: PropTypes.func,
  entityId: PropTypes.string,
  clearState: PropTypes.func,
  clearStateDataByKey: PropTypes.func,
  location: PropTypes.object,
  ioDidCreate: PropTypes.bool,
  ioColl: PropTypes.object,
  currencies: PropTypes.array,
  currenciesDidFetch: PropTypes.bool,
  buttonStates: PropTypes.object,
  membershipStates: PropTypes.object,
  leadInvestor: PropTypes.string,
  memberships: PropTypes.any,
  userRole: PropTypes.string,
  ioId: PropTypes.string,
  createIo: PropTypes.func,
  userId: PropTypes.string,
  match: PropTypes.object,
  putIoInfo: PropTypes.func
};

export default InvestmentDealCreation;
