import { Menu, Dropdown, Icon } from "antd";
import PropTypes from "prop-types";

const SorterDropdown = ({
  dropdownItems = [],
  name,
  onChange,
  selected = {}
}) => {
  const menu = () => (
    <Menu id="dropdown-items" selectedKeys={[selected.value]}>
      {dropdownItems.map(({ value, label }) => {
        return (
          <Menu.Item key={value} onClick={onChange({ name, value })}>
            <div className="dropdown-element">{label}</div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const readableFilter =
    selected.label || (dropdownItems[0] && dropdownItems[0].label);

  const label = (
    <span>
      Sort by: <span className="selected-filter">{readableFilter}</span>
    </span>
  );

  return (
    <div className="sorter-dropdown-container">
      <Dropdown.Button
        overlay={menu}
        icon={<Icon type="caret-down" />}
        style={{ width: 250 }}
        onChange={onChange}
      >
        {label}
      </Dropdown.Button>
    </div>
  );
};

SorterDropdown.propTypes = {
  dropdownItems: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.func
};

export default SorterDropdown;
