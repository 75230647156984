import { useEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Spin } from "antd";
import { useParams } from "react-router";
import BasicWrapper from "../../../../../Shared/BasicWrapper";

const ContractGeneratingScreen = ({
  history,
  clearStateDataByKey,
  getNdaDoc,
  ndaColl
}) => {
  const { cuid } = useParams();

  useEffect(() => {
    clearStateDataByKey("nda", "ndaGenerated", "nda");
    getNdaDoc({ cuid });
  }, [cuid]);

  useEffect(() => {
    if (ndaColl.success && ndaColl.contract) {
      history.push("/doc_viewer", { data: ndaColl?.contract?.value });
      clearStateDataByKey("nda", "ndaGenerated");
    }
  }, [ndaColl.success]);

  return (
    <BasicWrapper className="contract-generating">
      <h1>Please wait while your contract is being generated</h1>
      <div className="status-wrapper">
        {ndaColl.loading ? <Spin /> : <Icon type="check" />}
      </div>
    </BasicWrapper>
  );
};

export default ContractGeneratingScreen;

ContractGeneratingScreen.propTypes = {
  history: PropTypes.object,
  ndaColl: PropTypes.object,
  clearStateDataByKey: PropTypes.func,
  getNdaDoc: PropTypes.func
};
