import { connect } from "react-redux";
import { getEntity, deleteEntity } from "#core/modules/entities/actions";
import { fetchAllIos, getPortfolio } from "#core/modules/io/actions";
import {
  getSyndicates,
  getPortfolioSyndicates
} from "#core/modules/syndicates/actions";
import {
  putIoInfo,
  updateIoClosingStatus
} from "#core/modules/io/actions/update";
import { clearState, clearStateData } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    entitiesList,
    entity: { userRole, ...selectedEntity }
  } = state.entities._data || {};
  const { ios = [], deals: { iosWhereUserIsAM = [] } = {}, ioIsUpdating } =
    state.io._data || {};
  const { syndicatesWhereUserIsAM, list } = state?.syndicate?._data || {};

  return {
    ios,
    iosWhereUserIsAM,
    ioIsUpdating,
    ioIsFetching: state.io.isFetching,
    ioDidFetch: state.io.didFetch,
    selectedEntity,
    entityDidUpdate: state.entities.didUpdate,
    userRole,
    syndicates: list,
    syndicatesWhereUserIsAM,
    syndicatesFetching: state.syndicate.isFetching,
    syndicatesDidFetch: state.syndicate.didFetch,
    entitiesList
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAllIos: id => dispatch(fetchAllIos(id)),
  putIoInfo: data => dispatch(putIoInfo(data)),
  getPortfolio: data => dispatch(getPortfolio(data)),
  getEntity: data => dispatch(getEntity(data)),
  clearState: key => clearState(dispatch, key),
  clearStateData: data => clearStateData(dispatch, data),
  updateIoClosingStatus: data => dispatch(updateIoClosingStatus(data)),
  deleteEntity: cuid => dispatch(deleteEntity(cuid)),
  getSyndicates: params => dispatch(getSyndicates(params)),
  getPortfolioSyndicates: params => dispatch(getPortfolioSyndicates(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
