import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const saveFormData = data => {
  return async dispatch => {
    try {
      start(dispatch, types.SAVE_FORM_DATA);
      success(dispatch, types.SAVE_FORM_DATA, {
        data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.SAVE_FORM_DATA, {
        error: errorData
      });
    }
  };
};

export const getFormData = () => {
  return async dispatch => {
    try {
      start(dispatch, types.GET_FORM_DATA);
      success(dispatch, types.GET_FORM_DATA);
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.GET_FORM_DATA, {
        error: errorData
      });
    }
  };
};
