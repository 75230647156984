import { List } from "antd";
import { basicOption } from "#helpers/general";
import { formatNumber } from "#helpers/number_format";
import {
  confirmationPrompt,
  disabledConfirmationPrompt
} from "../../../Forms/common/prompts";
import {
  sharesAdditionFields,
  convertibleTypesAdditionFields
} from "../AddItems/list";
import { countriesSortedByName } from "../../../Shared/Settings/countries";
import { cantonsSortedByName } from "../../../Shared/Settings/cantons";

const stakeholderAdditionTypes = [
  basicOption("1", "Add shareholder"),
  basicOption("2", "Add lender"),
  basicOption("3", "Add board member")
];

const boardMemberFields = (values, presidentExists) => [
  {
    title: "Place of origin",
    input: {
      type: "text",
      name: "placeOfOrigin",
      placeholder: "Zurich"
    }
  },
  {
    title: "Date of birth",
    input: {
      type: "date",
      name: "birthdate"
    }
  },

  {
    title: "Who is the board member representing?",
    className: "_fullwidth",
    input: {
      type: "select_btn",
      name: "representativeOf",
      options: [
        basicOption("founder", "Founders"),
        basicOption("investor", "Investors"),
        basicOption("independent", "Independent"),
        basicOption("board_observer", "Board observer")
      ]
    }
  },
  values.representativeOf !== "board_observer"
    ? {
        title: "What is the signing authority of the board of director?",
        className: "_fullwidth",
        input: {
          type: "select_btn",
          name: "signingAuthority",
          options: [
            basicOption("single", "Single"),
            basicOption("collective", "Collective"),
            basicOption("none", "None")
          ]
        }
      }
    : null,
  values.representativeOf !== "board_observer"
    ? {
        title: "Is he / she the president of the board?",
        className: "_fullwidth",
        input: {
          type: "select_btn",
          name: "presidentOfTheBoard",
          options: [
            basicOption("Yes", "Yes", {
              disabled: presidentExists,
              tooltip: presidentExists
                ? "A president of the board has already been selected."
                : null
            }),
            basicOption("No", "No", { selected: presidentExists })
          ]
        }
      }
    : null
];

const beneficialOwnerFields = (values = {}, mode) => [
  {
    title: "First name",
    input: {
      type: "text",
      name: "firstName",
      placeholder: "Johann"
    }
  },
  {
    title: "Last name",
    input: {
      type: "text",
      name: "lastName",
      placeholder: "Reiner"
    }
  },
  {
    title: "Street & number",
    className: "_fullwidth",
    input: {
      type: "text",
      name: "street",
      placeholder: "Bahnhofstrasse 10"
    }
  },
  {
    title: "Postal code",
    input: {
      type: "text",
      name: "postalCode",
      placeholder: "8000"
    }
  },
  {
    title: "City",
    input: {
      type: "text",
      name: "city",
      placeholder: "Zurich"
    }
  },
  {
    title: "Country",
    input: {
      type: "autocomplete",
      name: "country",
      required: true,
      dataSource: countriesSortedByName,
      filterOption: true
    }
  },
  {
    title: "Canton",
    input: {
      type: "autocomplete",
      name: "canton",
      dataSource: cantonsSortedByName,
      filterOption: true
    }
  },
  {
    title: "Email",
    className: "_fullwidth",
    input: {
      type: "text",
      name: "email",
      placeholder: "johannreiner@mail.com"
    }
  }
];
const representativeFields = (values = {}, mode) => [
  {
    title: "First name",
    input: {
      type: "text",
      name: "firstName",
      placeholder: "Johann"
    }
  },
  {
    title: "Last name",
    input: {
      type: "text",
      name: "lastName",
      placeholder: "Reiner"
    }
  },
  {
    title: "Title",
    className: "_fullwidth",
    input: {
      type: "text",
      name: "title",
      placeholder: "Founder, Director"
    }
  },
  {
    title: "What is the signing authority of this representative?",
    input: {
      name: "signingAuthority",
      type: "select_btn",
      options: [
        basicOption("single", "Single"),
        basicOption("collective", "Collective")
      ]
    }
  }
];

const basicInfoFields = (
  values = {},
  mode,
  zefixHandlers,
  isTargetEntity,
  entityName
) => {
  if (mode === "3") {
    delete values.type;
    delete values.isFounder;
    delete values.entityIsShareholder;
  }
  return [
    values.type === "legal" &&
      (mode === "1" || mode !== "3") && {
        className: "_fullwidth",
        title: `Is ${entityName} a shareholder of this ${
          mode === "1" ? "shareholder" : "lender"
        }?`,
        input: {
          name: "entityIsShareholder",
          ...confirmationPrompt
        }
      },
    values.type !== "legal"
      ? {
          title: "First name",
          input: {
            type: "text",
            name: "firstName",
            placeholder: "Johann"
          }
        }
      : {
          title: "Company name",
          input: {
            type: "text",
            name: "firstName",
            placeholder: "My Office AG"
          }
        },
    values.type !== "legal"
      ? {
          title: "Last name",
          input: {
            type: "text",
            name: "lastName",
            placeholder: "Reiner"
          }
        }
      : {
          title: "UID",
          input: {
            type: "text",
            name: "lastName",
            placeholder: "CHE-111.222.333"
          }
        },
    {
      title: "Street & number",
      className: "_fullwidth",
      input: {
        type: "text",
        name: "street",
        placeholder: "Bahnhofstrasse 10"
      }
    },
    {
      title: "Postal code",
      input: {
        type: "text",
        name: "postalCode",
        placeholder: "8000"
      }
    },
    {
      title: "City",
      input: {
        type: "text",
        name: "city",
        placeholder: "Zurich"
      }
    },
    {
      title: "Canton",
      input: {
        type: "autocomplete",
        name: "canton",
        dataSource: cantonsSortedByName,
        filterOption: true
      }
    },
    {
      title: "Country",
      input: {
        type: "autocomplete",
        name: "country",
        required: true,
        dataSource: countriesSortedByName,
        filterOption: true
      }
    },
    {
      title: "Email",
      className: "_fullwidth",
      input: {
        type: "text",
        name: "email",
        disabled: isTargetEntity,
        placeholder: "johannreiner@mail.com"
      }
    }
  ];
};

const existingShareholderAdditionFields = (
  types,
  convertibles,
  entityShares,
  presidentExists,
  zefixHandlers,
  isTargetEntity,
  existingShares = [],
  entityName,
  isOriginalFounder
) => ({ values = {}, mode }) => [
  {
    className: "_fullwidth _border-bottom _hidden",
    input: {}
  },
  {
    className: "_fullwidth",
    title: "Please pick a type",
    input: {
      type: "select_btn",
      name: "type",
      options: [
        basicOption("legal", "Legal entity", { disabled: mode === "3" }),
        basicOption("natural", "Natural person")
      ]
    }
  },
  isTargetEntity
    ? {
        title: "Is the shareholder a founder?",
        className: "_fullwidth",
        input: {
          name: "isFounder",
          ...disabledConfirmationPrompt,
          disabled: isOriginalFounder
        }
      }
    : {
        title: "Is the shareholder a founder?",
        className: "_fullwidth",
        input: {
          name: "isFounder",
          ...confirmationPrompt,
          disabled: isOriginalFounder
        }
      },
  ...basicInfoFields(values, mode, zefixHandlers, isTargetEntity, entityName),
  values.type === "legal" && {
    title: "Add representative:",
    className: "_fullwidth",
    input: {
      type: "list",
      name: "representatives",
      fields: representativeFields(values).map(({ input, ...item }) => ({
        ...item,
        input: { ...input, name: `${input.name}` }
      })),
      actionButtonTitle: "Add another representative",
      itemRenderer: ({ firstName = "", lastName = "" }) => (
        <List.Item.Meta title={`${firstName} ${lastName}`} />
      )
    }
  },
  {
    title: "Shares:",
    className: "_fullwidth",
    input: {
      type: "list",
      name: "founderShares",
      fields: sharesAdditionFields(types, entityShares, values, existingShares),
      modalTitle: "Add shares",
      actionButtonTitle: "Add another share category",
      itemRenderer: ({ type, number }) => (
        <List.Item.Meta
          title={types[type]}
          description={formatNumber(number)}
        />
      )
    }
  },
  {
    title: "Convertible types",
    className: "_fullwidth",
    input: {
      type: "list",
      name: "convertibles",
      fields: convertibleTypesAdditionFields(convertibles, values),
      modalTitle: "Add convertible types",
      actionButtonTitle: "Add another convertible type",
      itemRenderer: ({ type, number }) => (
        <List.Item.Meta
          title={convertibles[type] && convertibles[type].name}
          description={formatNumber(number)}
        />
      )
    }
  },
  // {
  //   title: "Beneficial owners:",
  //   className: "_fullwidth",
  //   input: {
  //     type: "list",
  //     name: "beneficialOwners",
  //     fields: beneficialOwnerFields(values).map(({ input, ...item }) => ({
  //       ...item,
  //       input: { ...input, name: `beneficialOwner${input.name}` }
  //     })),
  //     actionButtonTitle: "Add another beneficial owner",
  //     itemRenderer: ({ beneficialOwnerfirstName, beneficialOwnerlastName }) => (
  //       <List.Item.Meta
  //         title={`${beneficialOwnerfirstName} ${beneficialOwnerlastName}`}
  //       />
  //     )
  //   }
  // },
  {
    title: "Is the shareholder also a board member?",
    className: "_fullwidth",
    input: {
      type: "select_btn",
      name: "isDirector",
      options: [
        basicOption("Yes", "Yes", { disabled: values.type === "legal" }),
        basicOption("No", "No", { selected: values.type === "legal" })
      ]
    }
  },
  ...(values.isDirector === "Yes"
    ? boardMemberFields(values, presidentExists)
    : [])
];

const boardMemberAdditionFields = ({ presidentExists }) => ({
  values = {},
  mode
}) => [
  {
    className: "_fullwidth _border-bottom _hidden",
    input: {}
  },
  ...basicInfoFields(values, mode),
  ...boardMemberFields(values, presidentExists)
];

const lenderAdditionFields = (
  types,
  presidentExists,
  zefixHandlers,
  isTargetEntity,
  entityName
) => ({ values = {}, mode }) => {
  return [
    {
      className: "_fullwidth _border-bottom _hidden",
      input: {}
    },
    {
      className: "_fullwidth",
      title: "Please pick a type",
      input: {
        type: "select_btn",
        name: "type",
        options: [
          basicOption("legal", "Legal entity"),
          basicOption("natural", "Natural person")
        ]
      }
    },
    ...basicInfoFields(values, mode, zefixHandlers, isTargetEntity, entityName),
    {
      className: "_fullwidth",
      input: {
        type: "list",
        name: "convertibles",
        fields: convertibleTypesAdditionFields(types, values),
        modalTitle: "Add convertible types",
        actionButtonTitle: "Add another convertible type",
        itemRenderer: ({ type, number }) => {
          return (
            <List.Item.Meta
              title={types[type] && types[type].name}
              description={formatNumber(number)}
            />
          );
        }
      }
    },
    {
      title: "Is the lender also a board member?",
      className: "_fullwidth",
      input: {
        name: "isDirector",
        type: "select_btn",
        options: [
          basicOption("Yes", "Yes", { disabled: values.type === "legal" }),
          basicOption("No", "No", { selected: values.type === "legal" })
        ]
      }
    },
    ...(values.isDirector === "Yes"
      ? boardMemberFields(values, presidentExists)
      : [])
  ];
};

export {
  stakeholderAdditionTypes,
  existingShareholderAdditionFields,
  lenderAdditionFields,
  boardMemberAdditionFields
};
