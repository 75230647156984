import { Button } from "../../Shared/Button";
import { Divider } from "antd";

export default function InfoDocs({
  handleOkClick,
  assemblyColl,
  syndicateName
}) {
  const handleAttchDownload = () => {
    if (assemblyColl?.data?.attachments?.length > 0)
      assemblyColl?.data?.attachments.forEach(({ url }) => window.open(url));
  };

  return (
    <div className="info-docs-page-wrapper">
      <h1>You are about to start your voting session</h1>
      <Divider />

      <div className="info-docs-page">
        <h3>
          This voting session is an{" "}
          {assemblyColl?.data?.type === "internal_syndicate_decision"
            ? "internal"
            : "target company"}{" "}
          voting regarding decisions about the {syndicateName} syndicate. Here's
          the info available for you to read:
        </h3>
        <ul>
          <li>
            <h3>
              This decisions list regards the internal functions of the
              syndicate
            </h3>
          </li>
          <li>
            <h3>
              {assemblyColl?.data?.questionnaire?.length} questions to be
              answered until{" "}
              {assemblyColl?.data?.expirationDateTime?.split("T")[0]}
            </h3>
          </li>
        </ul>
        {assemblyColl?.data?.attachments?.length > 0 && (
          <div className="info-docs-attch">
            <h3>
              Note: your Deal Manager attached the following document to help
              you understand the topics around this voting session.
            </h3>
            <Button
              size="large"
              label="Download"
              type="secondary"
              onClick={handleAttchDownload}
            />
          </div>
        )}

        <Button size="large" label="Ok, let's go" onClick={handleOkClick} />
      </div>
    </div>
  );
}
