import { connect } from "react-redux";
import {
  postShareClasses,
  getShareClassesById,
  updateShareClassesById
} from "#core/modules/shares/actions";
import { readCurrencies } from "#core/modules/currency/actions/read";
import { triggerSnackbar } from "#core/modules/snackbar/actions";
import { clearStateDataByKey } from "#core/utils/helper_actions";
import Presentational from "./Presentational";

const mapStateToProps = ({ shares, entities, currency }) => {
  const { _data: { currencies = [] } = {} } = currency;
  const { entity: { cuid: entityId } = {} } = entities._data || {};
  const { list = [] } = shares._data || {};

  return {
    loading: shares.isCreating || shares.isUpdating,
    success: shares.didCreate || shares.didUpdate,
    didCreate: shares.didCreate,
    didUpdate: shares.didUpdate,
    entityId,
    list,
    currencies
  };
};

const mapDispatchToProps = dispatch => ({
  updateShareClassesById: (cuid, data) =>
    dispatch(updateShareClassesById(cuid, data)),
  postShareClasses: data => dispatch(postShareClasses(data)),
  getShareClassesById: params => dispatch(getShareClassesById(params)),
  clearStateKeys: (state, ...keys) =>
    clearStateDataByKey(dispatch, state, ...keys),
  triggerSnackbar: snackbar => triggerSnackbar(dispatch, snackbar),
  readCurrencies: () => dispatch(readCurrencies())
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
