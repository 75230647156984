export default {
  CREATE_ENTITY: "CREATE_ENTITY",
  CREATE_ENTITY_MEMBERSHIP: "CREATE_ENTITY_MEMBERSHIP",
  BULK_CREATE_ENTITY_MEMBERSHIP: "BULK_CREATE_ENTITY_MEMBERSHIP",
  CREATE_ENTITY_DEAL_MEMBERSHIP: "CREATE_ENTITY_DEAL_MEMBERSHIP",
  BULK_CREATE_ENTITY_DEAL_MEMBERSHIP: "BULK_CREATE_ENTITY_DEAL_MEMBERSHIP",
  GET_SINGLE_ENTITY: "GET_SINGLE_ENTITY",
  GET_ENTITIES: "GET_ENTITIES",
  GET_ENTITIES_LIST: "GET_ENTITIES_LIST",
  GET_ENTITY_MEMBERSHIPS: "GET_ENTITY_MEMBERSHIPS",
  GET_ALL_ENTITY_MEMBERSHIPS: "GET_ALL_ENTITY_MEMBERSHIPS",
  GET_ENTITY_SHAREHOLDERS: "GET_ENTITY_SHAREHOLDERS",
  GET_COMPANY_DETAILS: "GET_COMPANY_DETAILS",
  GET_FOUNDER_INVITATIONS: "GET_FOUNDER_INVITATIONS",
  GET_COMPANIES_LIST: "GET_COMPANIES_LIST",
  GET_SINGLE_FOUNDER_INVITATION: "GET_SINGLE_FOUNDER_INVITATION",
  UPDATE_ENTITY: "UPDATE_ENTITY",
  SET_ENTITY: "SET_ENTITY",
  DELETE_ENTITY_MEMBERSHIP: "DELETE_ENTITY_MEMBERSHIP",
  CREATE_FOUNDER_INVITATION: "CREATE_FOUNDER_INVITATION",
  CREATE_ENTITY_FOUNDER: "CREATE_ENTITY_FOUNDER",
  PUT_FOUNDER_INVITATION: "PUT_FOUNDER_INVITATION",
  EXPIRE_FOUNDER_INVITATION: "EXPIRE_FOUNDER_INVITATION",
  DELETE_SHAREHOLDER: "DELETE_SHAREHOLDER",
  UPDATE_ENTITY_MEMBERSHIP: "UPDATE_ENTITY_MEMBERSHIP",
  PUT_IO_MEMBERSHIP_STATUS: "PUT_IO_MEMBERSHIP_STATUS",
  BULK_DELETE_ENTITY_MEMBERSHIP: "BULK_DELETE_ENTITY_MEMBERSHIP",
  DELETE_ENTITY: "DELETE_ENTITY",
  LOGIN: "LOGIN"
};
