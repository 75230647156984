import { useState } from "react";
import { List } from "antd";
import { Button } from "../../../../Shared/Button";
import InputContainer from "../../../../Shared/Input";

const SendCode = ({
  handleSendCodeClick,
  success,
  values: { phone_number }
}) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const localPhoneNumber = phoneNumber ? phoneNumber : phone_number;
  return (
    <div className="send-code-btn-wrapper">
      <InputContainer
        type="text"
        name="phoneNumber"
        placeholder="Your phone number"
        onChange={e => setPhoneNumber(e.target.value)}
        value={localPhoneNumber}
        disabled={success}
      />
      <Button
        type="secondary"
        label="Send code"
        onClick={handleSendCodeClick(phoneNumber)}
        size="large"
        disabled={success}
      />
    </div>
  );
};

export default SendCode;
