import { inputNumberFormatter } from "#helpers/general";
import { formatArrayKeys } from "#helpers/arrayFuncs";
import { majorCountriesSortedByName } from "../../Settings/countries";

const confirmTransferFields = currency => ({ values = {} }) => {
  return [
    {
      title: "Transfer details",
      className: "_fullwidth _bold-15 _hidden",
      input: {}
    },
    {
      title: "Name of the account holder",
      input: {
        type: "text",
        name: "accountHolderName"
      }
    },
    {
      title: "Address of the account holder",
      input: {
        type: "text",
        name: "accountHolderAddress"
      }
    },
    {
      title: "Please confirm the name of the target company",
      input: {
        type: "text",
        name: "targetCompanyName"
      }
    },
    {
      title: "Please confirm the address of the target company",
      input: {
        type: "text",
        name: "targetCompanyAddress"
      }
    },
    {
      title: "Bank location",
      input: {
        type: "autocomplete",
        name: "bankLocation",
        required: true,
        dataSource: majorCountriesSortedByName
      }
    },
    {
      title: "Name of the Bank",
      input: {
        type: "text",
        name: "bankName"
      }
    },
    ...(values?.bankLocation ? renderByCountry(values.bankLocation) : []),
    {
      title: "Reference / Payment reason",
      input: {
        type: "text",
        name: "referencePaymentReason"
      }
    },
    {
      title: "Amount to be wired",
      input: {
        type: "number",
        name: "amount",
        ...inputNumberFormatter(currency)
      }
    },
    {
      title: "Founder's details",
      className: "_fullwidth _bold-15 _hidden",
      input: {}
    },
    {
      title: "Name and surname of the target's representative",
      input: {
        type: "text",
        name: "targetRepresentativeFullName"
      }
    },
    {
      title: "Email of the target's representative",
      input: {
        type: "text",
        name: "targetRepresentativeEmail"
      }
    },
    {
      title: "Phone number of the target's representative",
      input: {
        type: "text",
        name: "targetRepresentativePhoneNumber",
        placeholder: "+41270554152"
      }
    },
    {
      title: (
        <div className="confirm-transfer-doc-wrapper">
          <p>
            Please upload the final Subscription Form or Investment Agreement:{" "}
          </p>
          <ul>
            <li>Signed by both you and the company's representatives</li>
            <li>Matching the current invested amount/shares visible on LEVA</li>
            <li>The agreement must be complete with all its pages</li>
          </ul>
        </div>
      ),
      colon: false,
      input: {
        type: "upload",
        name: "confirmTransferDocs",
        accept: ".pdf,.docx"
      }
    },
    {
      title: "Comments or important information for LEVA's team",
      input: {
        type: "textarea",
        name: "commentsOrInfo",
        placeholder: "Payment of agio/nominal value or similar"
      }
    },
    {
      title: "",
      className: "_fullwidth",
      input: {
        name: "confirmTransferConfirmation",
        type: "switch",
        label:
          "I hereby acknowledge, declare and confirm that the above information corresponds to the ultimate truth, and I am aware that is a criminal offence to deliberately provide false information on this form (article 251 of the Swiss Criminal Code, document forgery)."
      }
    }
  ];
};

const euSwitzerland = [
  {
    title: "Account number",
    input: {
      type: "text",
      name: "bankAccountNumber",
      placeholder: "If not available, please write N/A"
    }
  },
  {
    title: "IBAN",
    input: {
      type: "text",
      name: "bankAccountIban"
    }
  },
  {
    title: "BIC / Swift Code",
    input: {
      type: "text",
      name: "bankAccountBicSwift"
    }
  }
];

const uk = [
  {
    title: "Account number",
    input: {
      type: "text",
      name: "bankAccountNumber"
    }
  },
  {
    title: "IBAN",
    input: {
      type: "text",
      name: "bankAccountIban"
    }
  },
  {
    title: "BIC / Swift Code",
    input: {
      type: "text",
      name: "bankAccountBicSwift"
    }
  }
];

const us = [
  {
    title: "Account number",
    input: {
      type: "text",
      name: "bankAccountNumber"
    }
  },
  {
    title: "Routing number",
    input: {
      type: "text",
      name: "bankAccountRoutingNumber"
    }
  },
  {
    title: "BIC / Swift Code",
    input: {
      type: "text",
      name: "bankAccountBicSwift"
    }
  }
];

const china = [
  {
    title: "Bank branch",
    input: {
      type: "text",
      name: "bankAccountBranch"
    }
  },
  {
    title: "Account name",
    input: {
      type: "text",
      name: "bankAccountName"
    }
  },
  {
    title: "Account number",
    input: {
      type: "text",
      name: "bankAccountNumber"
    }
  },
  {
    title: "CNAPS (China National Advanced Payment System) Code",
    input: {
      type: "text",
      name: "bankAccountCNAPS"
    }
  }
];

const australia = [
  {
    title: "BSB (Bank-State-Branch) Number",
    input: {
      type: "text",
      name: "bankAccountStateBranchNumber"
    }
  },
  {
    title: "Account number",
    input: {
      type: "text",
      name: "bankAccountNumber"
    }
  }
];

const africa = [
  {
    title: "Branch Code or Sort Code",
    input: {
      type: "text",
      name: "bankAccountBranchSortCode"
    }
  },
  {
    title: "Account number",
    input: {
      type: "text",
      name: "bankAccountNumber"
    }
  }
];

const india = [
  {
    title: "IFSC (Indian Financial Systems Code)",
    input: {
      type: "text",
      name: "bankAccountIFSC"
    }
  },
  {
    title: "MICR (Magnetic Ink Character Recognition)",
    input: {
      type: "text",
      name: "bankAccountMICR"
    }
  },
  {
    title: "Account number",
    input: {
      type: "text",
      name: "bankAccountNumber"
    }
  }
];

const brazil = [
  {
    title: "Bank account number",
    input: {
      type: "text",
      name: "bankAccountNumber"
    }
  }
];

const japan = [
  {
    title: "Bank branch",
    input: {
      type: "text",
      name: "bankAccountBranch"
    }
  },
  {
    title: "Bank branch number",
    input: {
      type: "text",
      name: "bankAccountBranchNumber"
    }
  },

  {
    title: "Bank account type",
    input: {
      type: "text",
      name: "bankAccountType"
    }
  },
  {
    title: "Bank account number",
    input: {
      type: "text",
      name: "bankAccountNumber"
    }
  }
];

const me = [
  {
    title: "IBAN",
    input: {
      type: "text",
      name: "bankAccountIban"
    }
  },
  {
    title: "Bank account branch Name/Code",
    input: {
      type: "text",
      name: "bankAccountBranchCode"
    }
  },

  {
    title: "Bank account number",
    input: {
      type: "text",
      name: "bankAccountNumber"
    }
  }
];

const renderByCountry = country => {
  if (country === "EU / Switzerland") return euSwitzerland;
  if (country === "United Kingdom") return uk;
  if (country === "Australia") return australia;
  if (country === "United States") return us;
  if (country === "China") return china;
  if (country === "Africa") return africa;
  if (country === "India") return india;
  if (country === "Brazil") return brazil;
  if (country === "Japan") return japan;
  if (country === "Middle East") return me;
  return [];
};

export default confirmTransferFields;
