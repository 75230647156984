import axios from "./apiConfig";

export const getConvertibleTypes = params =>
  axios.get(`convertibles`, { params });

export const postConvertibleTypes = data => axios.post(`convertibles`, data);

export const deleteConvertibleTypes = params =>
  axios.delete(`convertibles`, { params });

export const deleteCla = id => axios.delete(`convertibles/${id}`);

export const updateCla = (cuid, data) =>
  axios.patch(`convertibles/${cuid}`, data);
