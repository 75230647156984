import { connect } from 'react-redux';
import {
  createEntityMembership,
  getEntityMemberships,
  deleteEntityMembership,
  updateEntity,
  getEntity
} from '#core/modules/entities/actions';
import { triggerSnackbar } from '#core/modules/snackbar/actions';
import { clearState } from '#core/utils/helper_actions';
import Presentational from './Presentational';

const mapStateToProps = (state) => {
  const {
    didUpdate,
    isUpdating,
    didCreate,
    _data: { memberships = [], entitiesList = [], entity = {}, isDeleting } = {}
  } = state.entities;

  return {
    memberships,
    entitiesList,
    entity,
    isDeleting,
    didCreate,
    buttonStates: { loading: isUpdating, success: didUpdate },
    didUpdate
  };
};

const mapDispatchToProps = (dispatch) => ({
  createEntityMembership: (data) => dispatch(createEntityMembership(data)),
  getEntityMemberships: (data) => dispatch(getEntityMemberships(data)),
  getEntity: (data) => dispatch(getEntity(data)),
  deleteEntityMembership: (data) => dispatch(deleteEntityMembership(data)),
  updateEntity: (data) => dispatch(updateEntity(data)),
  clearState: (key) => clearState(dispatch, key),
  triggerSnackbar: (snackbar) => triggerSnackbar(dispatch, snackbar)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentational);
