import axios from "./apiConfig";

export const getTaxes = params => axios.get("taxes", { params });

export const postTaxes = data => axios.post("taxes", data);

export const patchTaxes = ({ cuid, ...data }) =>
  axios.patch(`taxes/${cuid}`, data);

export const deleteTaxes = ({ cuid }) => axios.delete(`taxes/${cuid}`);

export const getYearlyReport = params =>
  axios.get("taxes/yearly-report", { params });
