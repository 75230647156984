import * as commitService from "../../../../api/commitmentService";
import { getSingleIos } from "../../../../api/ioService";
import types from "../action_types";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";

export const deleteCommit = ({ ioId, cuid }) => {
  return async dispatch => {
    try {
      start(dispatch, types.DELETE_COMMIT);
      await commitService.deleteCommitment(cuid);
      success(dispatch, types.DELETE_COMMIT, {
        data: cuid
      });

      const { data = {} } = (await getSingleIos(ioId)) || {};

      success(dispatch, types.UPDATE_IO, {
        data
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.DELETE_COMMIT, {
        error: errorData
      });
    }
  };
};
