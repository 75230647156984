const sectionList = [
  {
    id: 1,
    title: 'A. General information'
  },
  {
    id: 2,
    title: 'B. Financials',
    subSections: [
      {
        id: 2,
        title: 'Information'
      },
      {
        id: 3,
        title: 'Investor'
      },
      {
        id: 4,
        title: 'Interest'
      }
    ]
  },
  {
    id: 5,
    title: 'C. Conversion',
  },
];

export default sectionList;
