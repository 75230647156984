export const mapFeesToCreation = values => {
  const fees = {};

  if (values.closingFeeBool) {
    fees.setup = values.fees.setup / 100;
  } else {
    fees.setup = 0;
  }

  if (
    values.administrationFeeBool &&
    values?.fees?.administration?.value &&
    values?.fees?.administration?.years
  ) {
    fees.administration = {
      value: values.fees.administration.value / 100,
      years: values.fees.administration.years
    };
  } else {
    fees.administration = {
      value: 0,
      years: 0
    };
  }

  if (
    values.multiple &&
    Array.isArray(values?.fees?.performance?.multiple) &&
    values?.fees?.performance?.multiple?.length > 0
  ) {
    const multiple = values.fees.performance.multiple
      .slice(0, values.multipliers)
      .map(item => {
        return {
          breakpoint: values.fees?.performance?.multiple[0]?.breakpoint,
          value: item.value / 100
        };
      });

    fees.performance = multiple;
  } else if (values.performanceFeeBool) {
    fees.performance = {
      value: values.fees.performance.simple.value / 100,
      hurdle: values.fees.performance.simple.hurdle / 100
    };
  } else {
    fees.performance = {
      value: 0,
      hurdle: 0
    };
  }

  return fees;
};
