import { connect } from "react-redux";
import { getConvertibleTypes } from "#core/modules/convertible/actions";
import { readCurrencies } from "#core/modules/currency/actions/read";
import { getEntityShareholders } from "#core/modules/shares/actions";
import { getEntityMemberships } from "#core/modules/entities/actions";
import { getCommits } from "#core/modules/commitment/actions/";
import {
  getIoMemberships,
  deleteMembership
} from "#core/modules/iomemberships/actions";
import { saveFormData, getFormData } from "#core/modules/form/actions";
import { getAllMembers } from "#helpers/general";
import { clearState } from "#core/utils/helper_actions";
import {
  fetchSingleIo,
  createDealAndBulkMembers,
  updateDealAndBulkMembers
} from "#core/modules/io/actions";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { _data: { currencies = [] } = {} } = state.currency;
  const { convertibles = [] } = state.convertible._data || {};
  const { _data: { shareholders = [] } = {}, didFetch } = state.shares;
  const { entity: { cuid: entityId, shares, name, governingLaw } = {} } =
    state.entities._data || {};
  const {
    isCreating,
    didCreate,
    didFetch: ioDidFetch,
    isUpdating,
    didUpdate,
    _data: { io } = {}
  } = state.io;
  const { cuid: userId } = state.users._data || {};
  const { memberships = [] } = state.entities._data || {};
  const {
    entity: {
      employeeParticipationPlan: { esopShares = 0, psopShares = 0 } = {}
    }
  } = state.entities._data || {};
  const { memberships: ioMemberships = [] } = state.ioMemberships._data || {};
  const { commits = [] } = state.commits._data || {};
  const { _data: formData = {} } = state.form || {};

  const allMemberships = getAllMembers({ memberships, shareholders });

  return {
    currencies,
    shareholdersColl: {
      data: shareholders,
      didFetch
    },
    name,
    esopShares,
    psopShares,
    convertibles,
    entityId,
    buttonStates: {
      loading: isCreating || isUpdating,
      success: didCreate || didUpdate
    },
    userId,
    shares,
    governingLaw,
    ioColl: {
      didFetch: ioDidFetch,
      data: io
    },
    commits,
    allMemberships,
    ioMemberships,
    form: formData
  };
};

const mapDispatchToProps = dispatch => ({
  readCurrencies: () => dispatch(readCurrencies()),
  getEntityShareholders: data => dispatch(getEntityShareholders(data)),
  getEntityMemberships: data => dispatch(getEntityMemberships(data)),
  getConvertibleTypes: data => dispatch(getConvertibleTypes(data)),
  fetchSingleIo: data => dispatch(fetchSingleIo(data)),
  clearState: key => clearState(dispatch, key),
  createDealAndBulkMembers: data => dispatch(createDealAndBulkMembers(data)),
  updateDealAndBulkMembers: data => dispatch(updateDealAndBulkMembers(data)),
  getIoMemberships: data => dispatch(getIoMemberships(data)),
  deleteMembership: cuid => dispatch(deleteMembership(cuid)),
  getCommits: entityId => dispatch(getCommits(entityId)),
  saveFormData: data => dispatch(saveFormData(data)),
  getFormData: () => dispatch(getFormData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
