import { Icon } from 'antd';

const SuccessView = ({ title, className = '', id }) => {
  const finalClassname = `success-view ${className}`;

  return (
    <div className={finalClassname} id={id}>
      <Icon type="check-circle" />
      <h1>{title}</h1>
    </div>
  );
};

export default SuccessView;
