import SharedTable from "../../../../Shared/SharedTable/Presentational";

const TableList = ({ footer, label, data, cols, value, ...tableProps }) => {
  return (
    <div>
      <SharedTable
        dataSource={data || value}
        columns={cols}
        tableProps={tableProps}
        footer={footer}
      />
    </div>
  );
};

export default TableList;
