export const validateFieldHandler = async (
  validationSchema,
  key,
  values,
  setFieldError
) => {
  try {
    const validation = await validationSchema.validateAt(key, values);
    setFieldError(key, null);
    return validation;
  } catch (e) {
    setFieldError(key, e.message);
    return false;
  }
};
