import types from "../action_types";
import * as authService from "../../../../api/authService";
import { start, success, fail } from "../../../utils/helper_actions";
import { getErrorData, catchError } from "../../../utils/helper_func";
import { triggerSnackbar } from "../../snackbar/actions";

export const getNewPassword = data => {
  return async dispatch => {
    try {
      start(dispatch, types.NEW_PASSWORD);
      await authService.getNewPassService(data);
      success(dispatch, types.NEW_PASSWORD);
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.NEW_PASSWORD, {
        error: errorData
      });
    }
  };
};

export const resendEmailConfirmation = email => {
  return async dispatch => {
    try {
      start(dispatch, types.RESEND_EMAIL_VERIFICATION);
      await authService.resendConfirmService(email);

      triggerSnackbar(dispatch, {
        open: true,
        message: "Email verification resent successfully.",
        variant: "success"
      });
      // No need to pass data, because it returns the actual state of user
      success(dispatch, types.RESEND_EMAIL_VERIFICATION);
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.RESEND_EMAIL_VERIFICATION, {
        error: errorData
      });
    }
  };
};

export const verifyToken = token => {
  return async dispatch => {
    try {
      start(dispatch, types.VERIFY_TOKEN);
      await authService.verifyTokenService(token);
      success(dispatch, types.VERIFY_TOKEN, {
        tokenAuth: true
      });
    } catch (error) {
      const errorData = getErrorData(error);
      const { message } = errorData;
      catchError(dispatch, message);
      fail(dispatch, types.VERIFY_TOKEN, {
        error: errorData
      });
    }
  };
};
