import { Icon } from "antd";
import InputContainer from "../../../../../../../../Shared/Input";
import { Button } from "../../../../../../../../Shared/Button";

export default function MultipleSyndicates({ handleShowMultiSyn }) {
  return (
    <div className="multi-syn-wrapper">
      <p>
        If you have set up two or more syndicates for this deal, you must decide
        how to divide the total allocation among the different syndicates{" "}
      </p>
      <h3>Example</h3>
      <div className="multi-syn-inputs-wrapper">
        <div className="multi-syn-input">
          Revolut Seed - Syndicate 1
          <InputContainer value="CHF 500'000.00" disabled />
          <br />
          Revolut Seed - Syndicate 2
          <InputContainer value="CHF 1'000'000.00" disabled />
        </div>
        <Icon type="caret-right" className="multi-syn-icon" />
        <div className="multi-syn-input single">
          Revolut investment agreement
          <InputContainer value="CHF 1'500'000.00" disabled />
        </div>
      </div>
      <p className="multi-syn-warning-wrapper">
        <Icon type="warning" className="warning-icon" />
        The sum of the allocations assigned to each syndicate must match with
        the total allocation reported in the investment agreements.
      </p>
      <div className="multi-syn-btn-wrapper">
        <Button
          className="next-finish-btn"
          type="secondary"
          size="large"
          label="Got it!"
          onClick={handleShowMultiSyn()}
        />
      </div>
    </div>
  );
}
