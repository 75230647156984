import { confirmationPrompt } from '../../../common/prompts';
import { capTableCols } from './tableCols';

const capTable = (values, shareholderData) => [
  {
    title:
      'Your Cap Table has to be updated with the new Investors. Do you want to generate it with Leva or do you want to upload an updated post-closure Cap Table? ',
    input: {
      ...confirmationPrompt,
      name: 'captableGeneration'
    }
  },
  values.captableGeneration === 'yes'
    ? {
        input: {
          type: 'table_list',
          cols: capTableCols,
          data: shareholderData
        }
      }
    : null
];

export default capTable;
