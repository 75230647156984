import { useState, useEffect, Fragment } from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { generateUUID } from "#helpers/general";
import { entityTypes } from "#constants/entity";
import {
  mapCompanyPrefValues,
  mapCompanyUpdateValues,
  mapCompanyPrefUpdateValues
} from "#helpers/mappers/entitySettings";
import { convertToBase64 } from "#helpers/convertBase64";
import { roles } from "#constants/roles";
import EntityForm from "./form/Presentational";
import Cropper from "../../Shared/Cropper/";
import { withModalWrapper } from "../../Shared/hocs";

const EntityCreationWrapper = props => {
  const [entityCuid, setEntityCuid] = useState(null);
  const [init, setInit] = useState(false);
  const [localFileList, setLocalFileList] = useState([]);
  const [state, setState] = useState({});
  const { entityId = "" } = useParams();

  useEffect(() => {
    props.clearState("entities");
    props.clearStateDataByKey(
      "entities",
      "companyDetails",
      "companyDetailsFetched"
    );

    if (entityId) {
      props.getEntity(entityId);
    }

    setInit(true);
  }, []);

  useEffect(() => {
    if (entityId && props?.entity?.previewImage) {
      fetch(props?.entity?.previewImage).then(async response => {
        const contentType = response.headers.get("content-type");
        const blob = await response.blob();
        const file = new File([blob], "Company logo", { contentType });
        const uid = generateUUID();
        setLocalFileList([
          {
            name: file.name,
            lastModified: file.lastModified,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath,
            originFileObj: file,
            status: "done",
            response: "ok",
            lastModifiedDate: new Date(file.lastModified * 1000),
            uid
          }
        ]);
      });
    }
  }, []);

  useEffect(() => {
    if (init && props.buttonStates.success) {
      message.success(
        `Company has been ${
          props.buttonStates.created ? "created" : "updated"
        } successfully!`,
        5
      );
      setEntityCuid(props.lastEntityCuid);
      props.history.push("/dashboard/deal_management/");
    }
  }, [props.buttonStates.success]);

  const handleUpload = setFieldValue => async (name, info = {}, opts = {}) => {
    const { file, fileList } = info;
    let newState = {};

    if (opts.multiple) {
      newState = {
        [name]: fileList.map(item => item.originFileObj)
      };
    } else if (info?.file?.status === "done") {
      newState = {
        [name]: file.originFileObj,
        modalVisible: true
      };
    } else if (file?.status === "uploading") {
      setLocalFileList([file]);
    }
    setState({ ...state, ...newState });
    setFieldValue(name, true);
  };

  const handleSubmit = async values => {
    const { previewImageBase64 } = state;

    const isAm =
      props.userRole === roles.ASSET_MANAGER ||
      props.entityType === entityTypes.ASSET_MANAGER_ENTITY;

    const payload = {
      ...mapCompanyPrefValues(values, "create", isAm),
      type: entityTypes.TARGET_ENTITY,
      fundraisingActive: true,
      legalType: entityTypes.LEGAL_ENTITY
    };

    if (previewImageBase64) {
      payload.previewImageBase64 = previewImageBase64;
    }

    if (entityId) {
      const updatedPayload = mapCompanyPrefUpdateValues(payload, entityId);
      props.updateEntity(updatedPayload);
    } else {
      props.createEntity(payload);
    }
  };

  const onFileRemove = file => {
    setLocalFileList([]); // we set it to an empty array because we only accept one file.
  };

  const wrapperArgs = {
    handleSubmit,
    handleUpload,
    buttonStates: props.buttonStates,
    companyDetails: props.companyDetails,
    entityCuid,
    userRole: props.userRole,
    entity: entityId ? mapCompanyUpdateValues(props.entity) : null,
    entityId,
    history: props.history,
    localFileList,
    onFileRemove
  };

  const onOk = blob => {
    setState({
      previewImageBase64: blob,
      modalVisible: false
    });
  };

  const handleCropperModalClose = () => {
    setState({
      modalVisible: false
    });
    setLocalFileList([]);
  };

  const CropperModal = withModalWrapper({
    title: "Customize your logo",
    visible: state.modalVisible,
    closable: true,
    handleClose: handleCropperModalClose
  })(Cropper);

  return (
    <Fragment>
      <CropperModal
        image={
          state.previewImage ? URL.createObjectURL(state.previewImage) : null
        }
        onOk={onOk}
      />
      <EntityForm {...wrapperArgs} />
    </Fragment>
  );
};

EntityCreationWrapper.propTypes = {
  clearState: PropTypes.func,
  clearStateDataByKey: PropTypes.func,
  buttonStates: PropTypes.object,
  createEntity: PropTypes.func,
  companyDetails: PropTypes.object,
  lastEntityCuid: PropTypes.string,
  userRole: PropTypes.string,
  getEntity: PropTypes.func,
  updateEntity: PropTypes.func,
  entity: PropTypes.object
};

export default EntityCreationWrapper;
