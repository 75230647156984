
import { connect } from "react-redux";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const { email, phoneNumber } = state.users._data;
  
  return {
    user: {
      email,
      phoneNumber
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentational);
